import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar } from "components/index";
import { Form, Button, Col, Row, Table, Image, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { POST, ip } from "../../../../../api/api";
import XlsExport from "xlsexport";
import dayjs from "dayjs";

export default class ManageItemModel extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: false,
               series: {},
               items: [],
               modalShow: false,
               acc_image: null,
               status_item: "เปิด",
          };
     }
     componentDidMount = async () => {
          this.getItemsAll();
     };
     getItemsAll = async () => {
          try {
               this.setState({ loading: true });
               let { status_item } = this.state;
               let res = await POST("/aos/get_item_model", { series_id: this.props.match.params.id, status_item });
               console.log("res", res);
               this.setState({ loading: false, series: res.series[0] ? res.series[0] : {}, items: res.items });
          } catch (error) {
               this.setState({ loading: false });
          }
     };
     handleClickRemove = async (items_id, status) => {
          try {
               swal({
                    icon: "warning",
                    text: `ยืนยันการ${status ? "เปิด" : "ปิด"}ใช้งาน`,
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         await POST(`/item/${status ? "open" : "delete"}`, { items_id });
                         swal({
                              text: `${status ? "เปิด" : "ปิด"}ใช้งานเรียบร้อบแล้ว`,
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => this.componentDidMount());
                    }
               });
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     render() {
          let { loading, series, items, modalShow, acc_image, status_item } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: "รุ่นรถ",
                                   onClick: () => window.location.assign("/admin/item"),
                              },
                              {
                                   title: "ข้อมูล",
                              },
                         ]}
                         active={1}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-header">
                                   <div>รายละเอียดรุ่นรถ</div>
                              </div>
                              <div className="-body">
                                   {!loading && (
                                        <div className="content">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series Code : ",
                                                            detail: series.series_code,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series Name :",
                                                            detail: series.series_name,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                   )}
                              </div>
                              <div className="-body">
                                   <div className="content">
                                        {/* Show Room Field */}
                                        <div>
                                             <Form.Row>
                                                  <LabelBar
                                                       type={"radio"}
                                                       title={"สถานะ Item"}
                                                       name={"status_item"}
                                                       label={["เปิด", "ปิด"]}
                                                       value={status_item}
                                                       handleChangeText={(e) =>
                                                            this.setState({ status_item: e.target.value }, () => this.getItemsAll())
                                                       }
                                                       // disabled={previous_path === "edit" && true}
                                                  />
                                             </Form.Row>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="input-field">
                              <div className="-header">
                                   <div>อุปกรณ์</div>
                              </div>
                              <div className="-body">
                                   {!loading && (
                                        <Table striped bordered hover responsive>
                                             <thead>
                                                  <tr>
                                                       <th></th>
                                                       <th>Items Code</th>
                                                       <th>Items Name</th>
                                                       <th>Supplier</th>
                                                       <th>ประเภท</th>
                                                       <th>Series Name</th>
                                                       <th>Model Name</th>
                                                       <th>Color Name</th>
                                                       <th>ต้นทุน</th>
                                                       <th>ราคาแถม</th>
                                                       <th>ราคาขาย</th>
                                                       <th>รูปภาพ</th>
                                                       <th></th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {items.map((el, i) => (
                                                       <tr key={i + 1}>
                                                            <td>
                                                                 <a
                                                                      onClick={() => window.location.assign(`/admin/item/detail/edit/${el.items_id}`)}
                                                                      style={{ textDecoration: "underline", color: "blue" }}
                                                                 >
                                                                      แก้ไข
                                                                 </a>
                                                            </td>
                                                            <td>{el.items_code || "-"}</td>
                                                            <td>{el.items_name || "-"}</td>
                                                            <td>{el.supplier_name || "-"}</td>
                                                            <td>{el.items_type || "-"}</td>
                                                            <td>{el.series_name || "-"}</td>
                                                            <td>{el.model_name || "-"}</td>
                                                            <td>{el.color_name || "-"}</td>
                                                            <td>
                                                                 {el.price_list
                                                                      ? Number(el.price_list.cost).toLocaleString(undefined, {
                                                                             minimumFractionDigits: 2,
                                                                             maximumFractionDigits: 2,
                                                                        })
                                                                      : "0.00"}
                                                            </td>
                                                            <td>
                                                                 {el.price_list
                                                                      ? Number(el.price_list.price_add_new).toLocaleString(undefined, {
                                                                             minimumFractionDigits: 2,
                                                                             maximumFractionDigits: 2,
                                                                        })
                                                                      : "0.00"}
                                                            </td>
                                                            <td>
                                                                 {el.price_list
                                                                      ? Number(el.price_list.price).toLocaleString(undefined, {
                                                                             minimumFractionDigits: 2,
                                                                             maximumFractionDigits: 2,
                                                                        })
                                                                      : "0.00"}
                                                            </td>
                                                            <td>
                                                                 {el.acc_image ? (
                                                                      <Image
                                                                           onClick={() => this.setState({ modalShow: true, acc_image: el.acc_image })}
                                                                           style={{ cursor: "pointer" }}
                                                                           width={80}
                                                                           height={80}
                                                                           src={ip + el.acc_image + `?time=${new Date()}`}
                                                                      />
                                                                 ) : null}
                                                            </td>
                                                            <td>
                                                                 <a
                                                                      onClick={() => this.handleClickRemove(el.items_id, el.delete)}
                                                                      style={{ textDecoration: "underline", color: el.delete ? "green" : "red" }}
                                                                 >
                                                                      {el.delete ? "เปิด" : "ปิด"}
                                                                 </a>
                                                            </td>
                                                       </tr>
                                                  ))}
                                             </tbody>
                                        </Table>
                                   )}
                                   <Modal
                                        show={modalShow}
                                        onHide={() => this.setState({ modalShow: false })}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                   >
                                        <Modal.Header closeButton>
                                             <Modal.Title id="contained-modal-title-vcenter">รูปภาพประกอบ</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ textAlign: "center" }}>
                                             <Image width={450} height={450} src={ip + acc_image + `?time=${new Date()}`} />
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
