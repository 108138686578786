import { Breadcrumbs, Field, LabelBar, ModalPanel, Search, Table } from "components";
import React, { Component } from "react";
import { Row, Tabs, Tab, Button, Col } from "react-bootstrap";
import dayjs from "dayjs";
import { GET, POST } from "api";
import swal from "sweetalert";
import { getToken } from "../../../../../static/function";

export default class GenerateInstallReport extends Component {
     constructor(props) {
          super(props);
          this.state = {
               // match_type: this.props.match.params.match_type,
               // id: this.props.match.params.id,
               supplier_code: this.props.match.params.supplier_code,
               key: "list",
               order_item: [],
               order_notinstall: [],
               order_install: [],
               get_send_order: [],
               supplier: {},
               showmodal_bookbank: false,
               items: {
                    date_end: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
               },
          };
     }

     componentDidMount = () => {
          this.getSupplier();
          this.getOrderItem();
          this.getOrderNotInstall();
          this.getOrderInstall();
          this.getSendOrder();
     };

     getSupplier = async () => {
          try {
               this.setState({ supplier: { ...(await POST("/ps/this_supplier", { supplier_id: this.props.match.params.supplier_code })) } });
          } catch (error) {}
     };

     getOrderItem = async () => {
          let item = await POST("/item/order_item", { supplier_id: this.props.match.params.supplier_code });
          // console.log(item);
          this.setState({
               order_item: item,
          });
     };

     getOrderNotInstall = async () => {
          let item = await POST("/item/order_notinstall", { supplier_id: this.props.match.params.supplier_code });
          // console.log(item);
          this.setState({
               order_notinstall: item,
          });
     };

     getOrderInstall = async () => {
          let item = await POST("/item/order_installed", { supplier_id: this.props.match.params.supplier_code });
          this.setState({
               order_install: item,
          });
     };

     getSendOrder = async () => {
          let item = await POST("/item/get_send_order", { supplier_id: this.props.match.params.supplier_code });
          // console.log(item);
          this.setState({
               get_send_order: item,
          });
     };

     /* -------------------------- Set ---------------------------- */
     setKey = (k) => {
          this.setState({ key: k });
     };

     /* -------------------- onClick ----------------------- */
     onClickFile = (url) => {
          window.open(`${url}`);
     };

     closeReport = async (item) => {
          try {
               let user = getToken();
               let { supplier } = this.state;
               if (item) {
                    let { date_start, date_end } = this.state.items;
                    if (date_start && date_end) {
                         let res = await POST("/aos/report_acc_one", {
                              ...this.state.items,
                              supplier_id: supplier.supplier_id,
                              branch_id: user.branch_id,
                              sendMail: false,
                         });
                         window.open(res);
                    }
               } else {
                    if (supplier && supplier.supplier_mail) {
                         await POST("/aos/report_acc_one", {
                              ...this.state.items,
                              supplier_id: supplier.supplier_id,
                              branch_id: user.branch_id,
                              sendMail: true,
                         });
                         swal("ส่งเมลล์สำเร็จ", "", "success");
                    } else {
                         swal("Supplier ยังไม่มีอีเมลล์", "", "warning");
                    }
               }
          } catch (err) {
               swal(err, "", "warning");
          }
     };

     showBookBank = () => {
          this.setState({ showmodal_bookbank: true });
     };

     /* --------------- formatter ---------------- */
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          // console.log(cell, row);
          switch (formatExtraData) {
               case "view-item":
                    if (!row.file) {
                         return (
                              <a onClick={() => window.location.assign(`/admin/install/report/item/${row.items_id}/${this.state.supplier_code}`)}>
                                   ดูรายการ
                              </a>
                         );
                    } else {
                         return <span className="doc">-</span>;
                    }
               case "view-ppending":
                    if (!row.file) {
                         return (
                              <a
                                   onClick={() =>
                                        window.location.assign(`/admin/install/report/preemption/pending/${row.order_id}/${this.state.supplier_code}`)
                                   }
                              >
                                   ดูรายการ
                              </a>
                         );
                    } else {
                         return <span className="doc">-</span>;
                    }
               case "view-pinstalled":
                    if (!row.file) {
                         return (
                              <a
                                   onClick={() =>
                                        window.location.assign(
                                             `/admin/install/report/preemption/installed/${row.order_id}/${this.state.supplier_code}`
                                        )
                                   }
                              >
                                   ดูรายการ
                              </a>
                         );
                    } else {
                         return <span className="doc">-</span>;
                    }
               case "show-pending":
                    if (!row.file) {
                         return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{row.order_code_id}</a>;
                    } else {
                         return <span className="doc">-</span>;
                    }
               case "show":
                    if (!row.file) {
                         return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{row.order_code_id}</a>;
                    } else {
                         return <span className="doc">-</span>;
                    }
               case "download":
                    if (!row.file) {
                         return <a onClick={() => this.onClickFile(row.sacorder_file)}>Download</a>;
                    } else {
                         return <span className="doc">-</span>;
                    }
               case "edit_history":
                    if (row.log_do === "เพิ่ม") {
                         return null;
                    } else {
                         return <Button>ดูการแก้ไข</Button>;
                    }
               case "log_status":
                    if (row.log_status === "ดำเนินการ") {
                         return <span className="success">{cell}</span>;
                    } else {
                         return <span>{cell}</span>;
                    }
          }
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };
     handleChangeDate = (date, dateString, name) => {
          let { items } = this.state;
          items[name] = dayjs(dateString).format("YYYY-MM-DD HH:mm:ss");
          this.setState({ items });
     };
     render() {
          let { key, order_item, order_install, order_notinstall, get_send_order, supplier, items } = this.state;
          const item = {
               column: [
                    {
                         dataField: "items_code",
                         text: "Item Code",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "items_name",
                         text: "Item Name",
                    },
                    {
                         dataField: "count",
                         text: "ใบจอง/ใบสั่งขาย รอติดตั้ง",
                    },
                    {
                         dataField: "view",
                         text: "ดูรายการ",
                         formatter: this.detailFormatter,
                         formatExtraData: "view-item",
                    },
               ],
               data: order_item,
               keyField: "item_code",
          };
          const preemption_pending = {
               column: [
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatter,
                         formatExtraData: "show-pending",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่สั่งซื้อ",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "view",
                         text: "ดูรายการ",
                         formatter: this.detailFormatter,
                         formatExtraData: "view-ppending",
                    },
               ],
               data: order_notinstall,
               keyField: "preemption_id",
          };
          const preemption_installed = {
               column: [
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatter,
                         formatExtraData: "show",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่สั่งซื้อ",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "view",
                         text: "ดูรายการ",
                         formatter: this.detailFormatter,
                         formatExtraData: "view-pinstalled",
                    },
               ],
               data: order_install,
               keyField: "preemption_id",
          };
          const report = {
               column: [
                    {
                         dataField: "items_code",
                         text: "Item Code",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "items_name",
                         text: "Item Name",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่ออกรายงาน",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "order_date_start",
                         text: "วันจอง/สั่งซื้อ เริ่มต้น",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "order_date_end",
                         text: "วันจอง/สั่งซื้อ สิ้นสุด",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "user_order_name",
                         text: "ชื่อผู้ขาย",
                    },
                    {
                         dataField: "sacorder_file",
                         text: "DownLoad",
                         formatter: this.detailFormatter,
                         formatExtraData: "download",
                    },
               ],
               data: get_send_order,
               keyField: "item_code",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รายงานติดตั้งอุปกรณ์`,
                                   onClick: () => window.location.assign("/admin/install/report"),
                              },
                              { title: `${supplier?.supplier_name || "-"}` },
                         ]}
                         active={1}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูล Supplier"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Code : ",
                                                            detail: supplier?.supplier_code || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Name : ",
                                                            detail: supplier?.supplier_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: supplier?.supplier_tel || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Email : ",
                                                            detail: supplier?.supplier_mail || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: supplier?.supplier_address || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สถานะ : ",
                                                            detail: supplier?.status || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"Payment Term"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อบัญชี : ",
                                                            detail: supplier.supplier_accountname
                                                                 ? supplier.supplier_accountname === "null"
                                                                      ? "-"
                                                                      : supplier.supplier_accountname
                                                                 : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ธนาคาร : ",
                                                            detail: supplier.supplier_bank
                                                                 ? supplier.supplier_bank === "null"
                                                                      ? "-"
                                                                      : supplier.supplier_bank
                                                                 : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขบัญชีธนาคาร : ",
                                                            detail: supplier.supplier_bookbank
                                                                 ? supplier.supplier_bookbank === "null"
                                                                      ? "-"
                                                                      : supplier.supplier_bookbank
                                                                 : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Book Bank : ",
                                                            detail: "File Book Bank.Png",
                                                       }}
                                                       md={6}
                                                       textHeader_style={"underline"}
                                                       onClickLabelBar={this.showBookBank}
                                                  />
                                             </Row>
                                        </div>
                                        <ModalPanel
                                             show={this.state.showmodal_bookbank}
                                             type={"image"}
                                             onHide={() => this.setState({ showmodal_bookbank: false })}
                                             image={supplier?.supplier_file}
                                        />
                                   </div>
                              </div>
                         </div>

                         <Tabs activeKey={key} onSelect={(k) => this.setKey(k)}>
                              <Tab eventKey="list" title="รายการอุปกรณ์">
                                   <div className="input-field __tab-body">
                                        <div className="-body">
                                             <div className="content">
                                                  {/* <Row>
                                                       <Col md={4}>
                                                            <Field type={"text"} placeholder={"ค้นหาจาก Item Code / Item Name"} require={false} md={12} />
                                                       </Col>
                                                       <Col md={2}>
                                                            <Button className="-small">ค้นหา</Button>
                                                       </Col>
                                                  </Row> */}
                                                  <Table type="document" column={item.column} data={item.data} keyField={item.keyField} />
                                             </div>
                                        </div>
                                   </div>
                              </Tab>
                              <Tab eventKey="pending" title="ใบจองรอติดตั้ง">
                                   <div className="input-field __tab-body">
                                        <div className="-body">
                                             <div className="content">
                                                  {/* <Row>
                                                       <Col md={4}>
                                                            <Field type={"text"} placeholder={"ค้นหาจากเลขที่ใบจอง"} require={false} md={12} />
                                                       </Col>
                                                       <Col md={2}>
                                                            <Button className="-small">ค้นหา</Button>
                                                       </Col>
                                                  </Row> */}
                                                  <Table
                                                       type="document"
                                                       column={preemption_pending.column}
                                                       data={preemption_pending.data}
                                                       keyField={preemption_pending.keyField}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </Tab>
                              <Tab eventKey="installed" title="ใบจองติดตั้งแล้ว">
                                   <div className="input-field __tab-body">
                                        <div className="-body">
                                             <div className="content">
                                                  {/* <Row>
                                                       <Col md={4}>
                                                            <Field type={"text"} placeholder={"ค้นหาจากเลขที่ใบจอง"} require={false} md={12} />
                                                       </Col>
                                                       <Col md={2}>
                                                            <Button className="-small">ค้นหา</Button>
                                                       </Col>
                                                  </Row> */}
                                                  <Table
                                                       type="document"
                                                       column={preemption_installed.column}
                                                       data={preemption_installed.data}
                                                       keyField={preemption_installed.keyField}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </Tab>
                              <Tab eventKey="print-report" title="ออกรายงาน">
                                   <div className="input-field __tab-body">
                                        <div className="-body">
                                             <div className="content">
                                                  <Row>
                                                       <Field
                                                            type={"date"}
                                                            placeholder={"เลือกวันที่"}
                                                            title={"ประจำวันที่"}
                                                            name={"date_start"}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            placeholder={"เลือกวันที่"}
                                                            title={"ถึงวันที่"}
                                                            name={"date_end"}
                                                            value={items.date_end ? dayjs(items.date_end) : null}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       {/* <LabelBar
                                                            name={"check1"}
                                                            type={"checkbox"}
                                                            title={"สาขา"}
                                                            label={[
                                                                 { id: 0, name: "สาขา 1" },
                                                                 { id: 1, name: "สาขา 2" },
                                                            ]}
                                                            md={3}
                                                       /> */}
                                                  </Row>
                                                  <Row className="button-group __submit mt-5">
                                                       <Col lg={6}>
                                                            <Button onClick={() => this.closeReport(1)}>ดาวน์โหลด</Button>
                                                       </Col>
                                                       <Col lg={6}>
                                                            <Button
                                                                 onClick={() => this.closeReport(0)}
                                                                 style={{ background: "lightblue", color: "blue" }}
                                                            >
                                                                 ส่งเมลล์
                                                            </Button>
                                                       </Col>
                                                  </Row>
                                             </div>
                                        </div>
                                   </div>
                              </Tab>
                              <Tab eventKey="report" title="รายงานสั่งซื้อ">
                                   <div className="input-field __tab-body">
                                        <div className="-body">
                                             <div className="content">
                                                  {/* <Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"ค้นหาจาก Item Code / Item Name"}
                                                            placeholder={"ค้นหาจากเลขที่ใบจอง"}
                                                            require={false}
                                                            lg={3}
                                                            md={3}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"ประเภทใบสั่งซื้อ"}
                                                            placeholder={"เลือก"}
                                                            require={false}
                                                            lg={3}
                                                            md={3}
                                                            option={[{ value: "0", name: "เลือก 0" }]}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"ปี"}
                                                            placeholder={"เลือก"}
                                                            require={false}
                                                            lg={3}
                                                            md={3}
                                                            option={[{ value: "0", name: "เลือกปี 0" }]}
                                                       />
                                                       <Col md={2} className="align-self-flex-end">
                                                            <Button className="-small mb-3">ค้นหา</Button>
                                                       </Col>
                                                  </Row> */}
                                                  <Table type="document" column={report.column} data={report.data} keyField={report.keyField} />
                                             </div>
                                        </div>
                                   </div>
                              </Tab>
                         </Tabs>
                    </div>
               </div>
          );
     }
}
