import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FieldControl from "../field-control";

export default class InputPanel extends Component {
     render() {
          let { type, title, rows, value, name, placeholder, handleChange, handleChangeInputFile, button, id, classes } = this.props;

          switch (type) {
               case "textarea":
                    return (
                         <Form.Group controlId="textarea">
                              {title ? <Form.Label>{title}</Form.Label> : null}
                              <Form.Control as="textarea" rows={rows} placeholder={placeholder} name={name} disabled={false} onChange={handleChange} value={value} />
                         </Form.Group>
                    );
               case "file":
                    return (
                         <Form.Group controlId="textarea">
                              {/* <Row>
                                   <Col md={10}>
                                        <Form.Label>
                                             <span className="upload open">{title}</span>
                                        </Form.Label>
                                   </Col>
                                   <Col md={2}>
                                        <Form.File custom>
                                             <Form.File.Input name={name} onChange={handleChangeInputFile} />
                                        </Form.File>
                                   </Col>
                              </Row> */}
                              <Form.File id="formcheck-api-regular">
                                   <Form.File.Input name={name} onChange={handleChangeInputFile} />
                              </Form.File>
                              <label for="formcheck-api-regular">{title}</label>
                         </Form.Group>
                    );
          }
     }
}
