import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Loader } from "components/index";
import { Form, Button, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import { POST, GET } from "api/index";
import { useEffect } from "react";

function ScrollToTopOnMount() {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

export default class ACardList extends Component {
     constructor(props) {
          super(props);
          this.state = {
               title: [],
               search: this.props.location.state,
               loading: false,
          };
     }
     componentDidMount = async () => {
          this.setPath();
          this.getData();
     };

     /* ------------------------- Set/Get -------------------------- */
     setPath = () => {
          let { title } = this.state;
          title = [
               {
                    title: "A-Card",
                    onClick: () => window.location.assign("/admin/a_card"),
               },
               {
                    title: "รายการ A-Card",
               },
          ];
          this.setState({
               title: title,
          });
     };

     /*--------------------------------API-----------------------------------*/
     getData = async () => {
          this.setState({ loading: true });
          try {
               let res = await GET("/aos/get_acard");
               res.sort((end, start) => parseFloat(start.acard_id) - parseFloat(end.acard_id));
               // console.log(res)
               this.setState({
                    acardList: res,
                    loading: false,
               });
               this.searchData(res);
          } catch (error) {
               this.setState({ loading: false });

               // console.log(error);
          }
     };

     /*----------------------------------Search data-------------------------------------- */
     searchData(searchList) {
          let { search } = this.state;
          let newSearch = searchList
               .filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.acard_customer_type ? String(el.acard_customer_type) === String(search.acard_customer_type) : el) &&
                         (search.acard_zipcode ? Number(el.acard_zipcode) === Number(search.acard_zipcode) : el) &&
                         (search.status ? String(el.status) === String(search.status) : el) &&
                         (search.acard_province ? String(el.acard_province) === String(search.acard_province) : el) &&
                         (search.acard_create ? dayjs(el.acard_create).format("DD/MM/YYYY") === dayjs(search.acard_create).format("DD/MM/YYYY") : el)
               )
               .filter(
                    (ele) =>
                         (search.acard_no ? String(ele.acard_no).indexOf(String(search.acard_no)) > -1 : ele) &&
                         (search.acard_sales ? String(ele.acard_saler_name).indexOf(String(search.acard_sales)) > -1 : ele) &&
                         (search.acard_name ? String(ele.acard_fullname).indexOf(String(search.acard_name)) > -1 : ele) &&
                         (search.acard_tel ? String(ele.acard_tel).indexOf(String(search.acard_tel)) > -1 : ele) &&
                         (search.line_id ? String(ele.acard_line).indexOf(String(search.line_id)) > -1 : ele)
               );
          this.setState({
               acardList: newSearch,
          });
     }

     /*----------------------------------Formatter-----------------------------------*/

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <i
                    className="fas fa-search"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.props.history.push({ pathname: `/admin/a_card/manage/edit`, state: { acard_id: row.acard_id } })}
               />
          );
     };

     detailName = (cell, row, rowIndex, formatExtraData) => {
          if (row.acard_customer_type === "นิติบุคคล") {
               return row.acard_firstname;
          } else {
               return cell;
          }
     };

     /*------------------------------------Handle---------------------------------------- */
     handleClick = () => {
          window.location.assign("/admin/a_card/manage/add");
     };

     render() {
          let { title, acardList } = this.state;
          const table = {
               column: [
                    {
                         dataField: "info",
                         text: "ข้อมูล",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "acard_no",
                         text: "A-Card No.",
                         // headerClasses: "header-custom __left",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "acard_fullname",
                         text: "ชื่อลูกค้า",
                         formatter: this.detailName,
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "acard_customer_type",
                         text: "ประเภทลูกค้า",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "status",
                         text: "สถาณะ A-Card",
                         headerClasses: "header-custom __btn-s",
                    },
                    // {
                    //      dataField: "acard_saler",
                    //      text: "ชื่อผู้ขาย (Sales)",
                    // },
                    {
                         dataField: "acard_saler_name",
                         text: "ชื่อผู้ขาย (Sales)",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "branch_name",
                         text: "สขา Sales",
                         headerClasses: "header-custom __right __btn-ml",
                    },
               ],
               data: acardList ? acardList : [],
               keyField: "order_id",
          };
          return (
               <div className="panel">
                    {title ? (
                         <Breadcrumbs
                              title={title}
                              active={title.length - 1}
                              button={[
                                   {
                                        icon: "far fa-plus-square -icon",
                                        name: "เพิ่ม A-Card",
                                        handleClick: this.handleClick,
                                   },
                              ]}
                         />
                    ) : null}
                    <div className="content __main-panel acard">
                         {this.state.loading ? (
                              <Loader />
                         ) : table.data.length ? (
                              <Table type={"full-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         ) : (
                              <h5>----------ไม่พบข้อมูล-----------</h5>
                         )}
                    </div>
               </div>
          );
     }
}
