import { Search, Breadcrumbs, Skeleton, Table } from "components/index";
import React, { Component } from "react";
import dayjs from "dayjs";
import { GET, POST } from "api";
import { getToken } from "../../../../../static/function";

export default class ListPreemtionReady extends Component {
     constructor(props) {
          super(props);
          this.state = {
               search: {
                    ac_install: "รอการติดตั้ง",
               },
               order: [],
               order_search: [],
               branch: [],
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getOrder();
     };

     getOrder = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/to_install");
               let branch = await GET("/ps/get_branchs");

               branch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });

               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));

               for (let i in res) {
                    let arr = res[i].order_code_id.split("")[0];
                    if (arr === "S") {
                         res[i].branch_id = branch.find((el) => el.branch_name === res[i].branch_name)?.branch_id;
                         if (res[i].branch_id === Number(user.branch_id)) {
                              order_search.push(res[i]);
                         }
                    }
               }

               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search.filter((el) => el.ac_install === "รอการติดตั้ง");
               this.setState({ order, order_search, branch, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log("error", error);
          }
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
          // console.log(search);
     };

     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          // console.log("search", search);
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.getcar_date ? dayjs(el.getcar_date).format("DD/MM/YYYY") === search.getcar_date : el) &&
                         (search.ac_install ? (search.ac_install === "ทั้งหมด" ? el : String(el.ac_install) === String(search.ac_install)) : el) &&
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el)
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         // (search.orp_status !== "ทั้งหมด" ? String(el.orp_status).indexOf(String(search.orp_status)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.userinfo_a_card ? String(el.userinfo_a_card).indexOf(String(search.userinfo_a_card)) > -1 : el) &&
                         (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     /* ---------------------------------- Formatter ---------------------------------- */
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          let arr = row.order_code_id.split("");
          switch (formatExtraData) {
               case "preemtion":
                    // return (
                    //      <a
                    //      // onClick={() =>
                    //      //     window.location.assign(
                    //      //         `/admin/payment/install/list/${row.preemtion_id}`
                    //      //     )
                    //      // }
                    //      >
                    //           {row.order_code_id}
                    //      </a>
                    // );
                    if (arr[0] === "B") {
                         return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{row.order_code_id}</a>;
                    } else {
                         return <a onClick={() => window.location.assign(`/admin/order/acc/detail${row.acorder_id}`)}>{row.order_code_id}</a>;
                    }
               case "generate":
                    if (arr[0] === "B") {
                         return <a onClick={() => window.location.assign(`/admin/install/list/car/${row.order_id}`)}>ทำรายการ</a>;
                    } else {
                         return <a onClick={() => window.location.assign(`/admin/install/list/acc/${row.acorder_id}`)}>ทำรายการ</a>;
                    }
               // return <a onClick={() => window.location.assign(`/admin/install/list/${row.order_id}`)}>ทำรายการ</a>;
          }
     };
     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     render() {
          let { order, search, newSearch, branch } = this.state;
          //   console.log(order);
          let table = {
               column: [
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         formatExtraData: "generate",
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preemtion",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "ac_install",
                         text: "สถานะการติดตั้ง",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                         // headerClasses: "header-custom __right",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "branch_name",
                         text: "สาขา/โชว์รูม",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "ac_install_note",
                         text: "หมายเหตุ",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    // {
                    //      dataField: "getcar_date",
                    //      text: "วันที่ส่งมอบ",
                    //      formatter: this.dateFormatter,
                    //      headerClasses: "header-custom __btn-ss",
                    // },
                    {
                         dataField: "send_mail_acc_date",
                         text: "วันที่สั่งซื้อการติดตั้งอุปกรณ์",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "mail_acc_install_date",
                         text: "วันที่นัดหมายการติดตั้ง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "userinfo_a_card",
                         text: "A-Card No.",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "order_code_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "รายการใบจองที่พร้อมติดตั้ง" }]} active={0} button={""} />
                    <div className="content __main-panel listinstall">
                         <Search
                              type={"install-list"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: search,
                                   branchs: branch,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
