import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Skeleton } from "components/index";
import { GET, POST } from "../../../../../api/api";
import swal from "sweetalert";

export default class itemModel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               series: [],
               series_backup: [],
               search: {},
               fileTextHolder: "",
               loading: false,
               items: [],
               item: [],
          };
     }

     componentDidMount = () => {
          this.getSeries();
          this.getItem();
     };

     getSeries = async () => {
          try {
               this.setState({ loading: true });
               let series = await GET("/aos/get_series");
               series
                    .sort((end, start) => parseFloat(start.series_id) - parseFloat(end.series_id))
                    .sort((end, start) => parseFloat(start.series_id) - parseFloat(end.series_id));
               this.setState({ series: [...series], series_backup: [...series], loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     getItem = async () => {
          try {
               let items = await GET("/aos/get_item_sup");
               this.setState({ items });
          } catch (error) {
               console.log(error);
          }
     };
     /* ----------------------------- Handle --------------------------- */
     // handleClick = () => {
     //      window.location.assign("/admin/car-model/detail/add");
     // };
     handleClick = () => {
          window.location.assign("/admin/item/detail/add");
     };
     /* --------------------------- Formatter -------------------------- */
     editFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-pen-square"
                    onClick={() => window.location.assign(`/admin/item-model/detail/${row.series_id}`)}
               ></i>
          );
     }
     editFormatterItem(cell, row, rowIndex, formatExtraData) {
          return (
               <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-pen-square"
                    onClick={() => window.location.assign(`/admin/item-model/detail/edit/${row.items_id}/${row.supplier_id}`)}
               ></i>
          );
     }

     handleChange = (event) => {
          // console.log(event);
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };

     handleUploadFile = async () => {
          const { file } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    formData.append("type", "series_master");
                    formData.append("file", file);

                    this.setState({ loading: true });
                    await POST("/aos/series_master", formData, true);
                    await this.getSeries();
                    this.setState({ loading: false, fileTextHolder: "", file: null });
               }
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false });
          }
     };

     onSearch = () => {
          let { search, items } = this.state;
          if (search.items_code || search.items_name || search.supplier_name) {
               this.setState({ loading: true });
               let item = items.filter(
                    (e) =>
                         (search.items_code ? String(e.items_code).toLowerCase().includes(String(search.items_code).toLowerCase()) : e) &&
                         (search.items_name ? String(e.items_name).toLowerCase().includes(String(search.items_name).toLowerCase()) : e) &&
                         (search.supplier_name ? String(e.supplier_name).toLowerCase().includes(String(search.supplier_name).toLowerCase()) : e)
               );
               this.setState({ item, loading: false });
          }
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };

     downloadFile = async () => {
          try {
               window.open(await POST("/ps/download_excel", { download: "car" }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     render() {
          let { series, fileTextHolder, loading, search, series_backup, items, item } = this.state;
          let obj = {
               series: series_backup?.map((e) => ({
                    value: e.series_name,
                    name: e.series_name,
               })),
          };
          const table = {
               column: [
                    {
                         dataField: "edit",
                         text: "ข้อมูล",
                         formatter: this.editFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "series_code",
                         text: "Series Code",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "series_name",
                         text: "Series",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "type",
                         text: "ประเภทรถ",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "status",
                         text: "สถานะ",
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               keyField: "series_name",
          };
          const tableItem = {
               column: [
                    {
                         dataField: "edit",
                         text: "ข้อมูล",
                         formatter: this.editFormatterItem,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "items_code",
                         text: "Items Code",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "items_name",
                         text: "Items",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "supplier_name",
                         text: "ร้านค้า",
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               keyField: "items_name",
          };
          let sup_all = items
               .filter((el, i) => items.map((ele) => ele.supplier_name).indexOf(el.supplier_name) === i)
               .map((el) => ({ value: el.supplier_name, name: el.supplier_name }));
          // console.log("item", item);
          return (
               // <div className="panel hide-scroll">
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "รุ่นรถ" }]}
                         active={0}
                         button={[
                              {
                                   icon: "fas fa-user-plus -icon",
                                   name: "เพิ่ม Item",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __main-panel">
                         <div className="search-bar">
                              {/* <Search
                                   type={"car-model"}
                                   searchOptions={search}
                                   onSearch={this.onSearch}
                                   handleChangeText={this.handleChangeText}
                                   handleChange={this.handleChangeOption}
                                   obj={obj}
                              /> */}
                              <Search
                                   type={"item"}
                                   searchOptions={{ ...search, sup_all }}
                                   onSearch={this.onSearch}
                                   handleChangeText={this.handleChangeText}
                                   handleChange={this.handleChangeOption}
                              />
                              {/* <Search
                                   className="w-100 pr-5"
                                   type={"upload"}
                                   title={{
                                        name: "อัปโหลด Car Master",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __below-btn"}
                                   handleChange={this.handleChange}
                                   handleUploadFile={this.handleUploadFile}
                                   downloadFile={this.downloadFile}
                                   disabled={loading}
                              /> */}
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : item.length ? (
                              <Table type={"index-fixhead"} column={tableItem.column} data={item} keyField={tableItem.keyField} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={series} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
