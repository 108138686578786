import dayjs from "dayjs";
import React, { Component } from "react";
import { Button, Accordion, Col, Row } from "react-bootstrap";
import Insurance from "../../containers/admin/manage/insurance/insurance";
import Field from "../field-control";
import { getToken } from "../../static/function";

const months = [
     {
          value: 0,
          name: "เดือนทั้งหมด",
     },
     {
          value: 1,
          name: "มกราคม",
     },
     {
          value: 2,
          name: "กุมภาพันธ์",
     },
     {
          value: 3,
          name: "มีนาคม",
     },
     {
          value: 4,
          name: "เมษายน",
     },
     {
          value: 5,
          name: "พฤษภาคม",
     },
     {
          value: 6,
          name: "มิถุนายน",
     },
     {
          value: 7,
          name: "กรกฏาคม",
     },
     {
          value: 8,
          name: "สิงหาคม",
     },
     {
          value: 9,
          name: "กันยายน",
     },
     {
          value: 10,
          name: "ตุลาคม",
     },
     {
          value: 11,
          name: "พฤศจิกายน",
     },
     {
          value: 12,
          name: "ธันวาคม",
     },
];

let start = 2022;
let end = new Date().getFullYear();
const years = [];
for (var year = start; year <= end; year++) {
     years.push({ name: year + 543, value: String(year + 543).slice(2, 4) });
}

export default class Search extends Component {
     constructor(props) {
          super(props);
          this.state = {};
     }
     render() {
          let {
               type,
               title,
               placeholder,
               handleChange,
               button,
               style_id,
               onSearch,
               arr,
               className,
               onClick,
               handleChangeText,
               option,
               handleSelectDate,
               handleClickSearch,
               onChangePage,
               handleChangeCar,
               noData,
               searchOptions,
               typeSup,
               handleChangeOption,
               handleUploadFile,
               disabled,
               itemUp,
               style,
               obj,
               selectOption,
               downloadFile,
               downloadUser,
               textDownload,
               addBtn,
               handleEnter,
          } = this.props;
          // console.log(option);
          const approve_option = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "รออนุมัติ", name: "รออนุมัติ" },
               { value: "อนุมัติ", name: "อนุมัติ" },
               { value: "ไม่อนุมัติ", name: "ไม่อนุมัติ" },
          ];
          const status_acard = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "save", name: "save" },
               { value: "ใบจองรออนุมัติ", name: "ใบจองรออนุมัติ" },
               { value: "ใบจองอนุมัติแล้ว", name: "ใบจองอนุมัติแล้ว" },
               { value: "รับเงินจองแล้ว", name: "รับเงินจองแล้ว" },
               { value: "Leasing อนุมัติแล้ว", name: "Leasing อนุมัติแล้ว" },
               { value: "ยืนยันข้อมูล", name: "ยืนยันข้อมูล" },
               { value: "จับคู่รถแล้ว", name: "จับคู่รถแล้ว" },
               // { value: "กำลังติดตั้งอุปกรณ์", name: "กำลังติดตั้งอุปกรณ์" },
               { value: "ติดตั้งอุปกรณ์แล้ว", name: "ติดตั้งอุปกรณ์แล้ว" },
               { value: "ชำระเงินแล้ว", name: "ชำระเงินแล้ว" },
               // { value: "รอส่งมอบ", name: "รอส่งมอบ" },
               { value: "ส่งมอบรถแล้ว", name: "ส่งมอบรถแล้ว" },
               { value: "ปิดการขาย", name: "ปิดการขาย" },
          ];
          const status_option = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "ใช้งาน", name: "ใช้งาน" },
               { value: "ไม่ได้ใช้งาน", name: "ไม่ได้ใช้งาน" },
          ];
          const status = [
               { value: "Hot", name: "Hot" },
               { value: "Warm", name: "Warm" },
               { value: "Cool", name: "Cool" },
          ];
          const supplier_type = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               // { value: "TMT", name: "TMT" },
               { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง (TOYOTA)", name: "อุปกรณ์ตกแต่ง (TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง (Out Source Supplier)", name: "อุปกรณ์ตกแต่ง (Out Source Supplier)" },
               { value: "ประกัน", name: "ประกัน" },
               { value: "Leasing", name: "Leasing" },
               { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
               // { value: "อื่น ๆ", name: "อื่น ๆ" },
          ];
          const supplier_type_upload = [
               // { value: 1, name: "TMT" },
               { value: "sup_dealer", name: "Dealer ซื้อรถ" },
               { value: "sup_vendor_tmt_master", name: "อุปกรณ์ตกแต่ง(TOYOTA)" },
               { value: "sup_vendor_out_master", name: "อุปกรณ์ตกแต่ง(Out Source Supplier)" },
               { value: "sup_insurance_master", name: "ประกัน" },
               { value: "sup_leasing_master", name: "Leasing" },
               { value: "sup_register", name: "ตัวแทนจดทะเบียน" },
               // { value: 9, name: "อื่น ๆ" },
          ];
          const item_type = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               // { value: "TMT", name: "TMT" },
               // { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง (TOYOTA)", name: "อุปกรณ์ตกแต่ง (TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง (Out Source Supplier)", name: "อุปกรณ์ตกแต่ง (Out Source Supplier)" },
               // { value: "ประกัน", name: "ประกัน" },
               // { value: "Leasing", name: "Leasing" },
               // { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
               // { value: "อื่น ๆ", name: "อื่น ๆ" },
          ];
          const item_type_upload = [
               { value: "item_master_tmt", name: "อุปกรณ์ตกแต่ง(TOYOTA)" },
               { value: "item_master_out", name: "อุปกรณ์ตกแต่ง(Out Source Supplier)" },
          ];
          const manage_type = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "เพิ่ม", name: "เพิ่ม" },
               { value: "ลบ", name: "ลบ" },
               { value: "แก้ไข", name: "แก้ไข" },
          ];
          const series = option ? option.value.series : [];
          const model = option ? option.value.model : [];
          const color = option ? option.value.color : [];
          const payment_type = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "เงินสด", name: "เงินสด" },
               { value: "Leasing", name: "Leasing" },
          ];
          const payment_status = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "รอชำระเงิน", name: "รอชำระเงิน" },
               { value: "รอรับเงินจาก Leasing", name: "รอรับเงินจาก Leasing" },
               { value: "ชำระเงินแล้ว", name: "ชำระเงินแล้ว" },
               { value: "รับเงินป้ายแดง", name: "รับเงินป้ายแดง" },
               // { value: "รับเงินจาก Leasing แล้ว", name: "รับเงินจาก Leasing แล้ว" },
          ];
          const payment_status_leasing = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               // { value: "รอชำระเงิน", name: "รอชำระเงิน" },
               { value: "รอรับเงินจาก Leasing", name: "รอรับเงินจาก Leasing" },
               // { value: "ชำระเงินแล้ว", name: "ชำระเงินแล้ว" },
               { value: "ชำระเงินแล้ว", name: "ชำระเงินแล้ว" },
          ];
          const price = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "รอชำระเงิน", name: "รอชำระเงิน" },
               { value: "รับเงินค่าอุปกรณ์แล้ว", name: "รับเงินค่าอุปกรณ์แล้ว" },
          ];
          const price_status = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "รอรับเงินจอง", name: "รอรับเงินจอง" },
               { value: "รับเงินจองแล้ว", name: "รับเงินจองแล้ว" },
          ];
          const tax = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "ออกใบกำกับภาษีแล้ว", name: "ออกใบกำกับภาษีแล้ว" },
               { value: "ยังไม่ออกใบกำกับภาษี", name: "ยังไม่ออกใบกำกับภาษี" },
          ];
          const ls_app = [
               { value: 0, name: "ทั้งหมด" },
               { value: 1, name: "รออนุมัติ" },
               { value: 2, name: "เจ้าหน้าที่รับเรื่อง" },
               { value: 0, name: "นัดหมายเซ็นสัญญาขอกู้" },
               { value: 1, name: "ลูกค้าเซ็นสัญญา" },
               { value: 2, name: "สินเชื่ออนุมัติ" },
          ];
          const showroom_type = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "สำนักงานใหญ่ ธัญบุรี คลอง 2", name: "สำนักงานใหญ่ ธัญบุรี คลอง 2" },
               { value: "ลำลูกกา", name: "ลำลูกกา" },
          ];
          const status_install = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "รอการติดตั้ง", name: "รอการติดตั้ง" },
               { value: "ติดตั้งเรียบร้อยแล้ว", name: "ติดตั้งเรียบร้อยแล้ว" },
          ];
          const checklabel_status = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "รอดำเนินการ", name: "รอดำเนินการ" },
               { value: "จดทะเบียนแล้ว", name: "จดทะเบียนแล้ว" },
          ];
          const customer_type = [
               { value: "บุคคลทั่วไป", name: "บุคคลทั่วไป" },
               { value: "นิติบุคคล", name: "นิติบุคคล " },
               // { value: "Fleet", name: "Fleet" },
          ];
          const credit_status = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "ขอสินเชื่อ", name: "ขอสินเชื่อ" },
               { value: "เจ้าหน้าที่รับเรื่อง", name: "เจ้าหน้าที่รับเรื่อง" },
               { value: "นัดหมายเซ็นสัญญาขอกู้", name: "นัดหมายเซ็นสัญญาขอกู้" },
               { value: "ลูกค้าเซ็นสัญญา", name: "ลูกค้าเซ็นสัญญา" },
               { value: "สินเชื่ออนุมัติ", name: "สินเชื่ออนุมัติ" },
          ];
          const car_type = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "Pick-up", name: "Pick-up" },
               { value: "MPV", name: "MPV" },
               { value: "PPV", name: "PPV" },
               { value: "SUV", name: "SUV" },
               { value: "CUV", name: "CUV" },
               { value: "HEV", name: "HEV" },
               { value: "VAN", name: "VAN" },
               { value: "Eco Car", name: "Eco Car" },
               { value: "Compact Car", name: "Compact Car" },
               { value: "Mid-size Car", name: "Mid-size Car" },
               { value: "Full-size Car", name: "Full-size Car" },
          ];

          const acc_status = [
               { value: "ทั้งหมด", name: "ทั้งหมด" },
               { value: "สั่งซื้อ", name: "สั่งซื้อ" },
               { value: "ติดตั้งอุปกรณ์แล้ว", name: "ติดตั้ง" },
               { value: "ชำระแล้ว", name: "ชำระเงินแล้ว" },
               { value: "ส่งมอบรถแล้ว", name: "ส่งมอบ" },
               { value: "ปิดการขาย", name: "ปิดการขาย" },
          ];

          const field_text = {
               car_order: [
                    {
                         type: "select",
                         title: "สถานะใบจอง",
                         name: "acept_status",
                         placeholder: "เลือกสถานะ",
                         handleChangeText: handleChangeText,
                         option: status_acard,
                         value: option?.value?.acept_status && option?.value?.acept_status,
                    },
                    {
                         type: "text",
                         title: "เลขที่ใบจอง",
                         name: "order_code_id",
                         placeholder: "กรอกเลขที่ใบจอง",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "text",
                         title: "เลขตัวถัง",
                         name: "car_tank",
                         placeholder: "กรอกเลขตัวถัง",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "text",
                         title: "ชื่อผู้ขาย",
                         name: "saler_name_string",
                         placeholder: "กรอกชื่อผู้ขาย",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "text",
                         title: "ชื่อลูกค้า",
                         name: "userinfo_name",
                         placeholder: "กรอกชื่อลูกค้า",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "select",
                         title: "สาขา/โชว์รูม",
                         name: "branch_id",
                         value: option?.value?.branch_id && option?.value?.branch_id,
                         placeholder: "เลือกสาขา/โชว์รูม",
                         handleChangeText: handleChangeText,
                         option:
                              option && option.branchs
                                   ? option.branchs.map((e) => ({
                                          value: e.branch_id,
                                          name: e.branch_name,
                                     }))
                                   : [],
                    },
                    {
                         type: "text",
                         title: "A-Card No.",
                         name: "userinfo_a_card",
                         placeholder: "กรอก A-Card No.",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "date",
                         title: "วันที่จอง",
                         name: "created_at",
                         placeholder: "เลือกวันที่",
                         formatDate: "DD/MM/YYYY",
                         handleSelectDate: handleSelectDate,
                         require: false,
                    },
                    {
                         type: "date",
                         title: "วันที่ส่งมอบ",
                         name: "getcar_date",
                         placeholder: "เลือกวันที่",
                         formatDate: "DD/MM/YYYY",
                         handleSelectDate: handleSelectDate,
                         require: false,
                    },
                    // {
                    //      type: "select",
                    //      title: "สถานะการอนุมัติ",
                    //      name: "status",
                    //      placeholder: "เลือกสถานะ",
                    //      handleChangeText: handleChangeText,
                    //      option: approve_option,
                    //      value: option?.value?.status && option?.value?.status,
                    // },
               ],
               acc: [
                    {
                         type: "text",
                         title: "เลขที่ใบสั่งขายอุปกรณ์",
                         name: "acorder_id",
                         placeholder: "กรอกเลขที่ใบสั่งขายอุปกรณ์",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "select",
                         title: "สถานะใบสั่งขายอุปกรณ์",
                         name: "status",
                         placeholder: "เลือก",
                         handleChangeText: handleChangeText,
                         require: false,
                         option: acc_status,
                         value: option?.value.status,
                    },
                    {
                         type: "text",
                         title: "ชื่อผู้ขาย",
                         name: "acorder_saler_name",
                         placeholder: "กรอกชื่อผู้ขาย",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "text",
                         title: "ชื่อลูกค้า",
                         name: "acorder_name",
                         placeholder: "กรอกชื่อลูกค้า",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "date",
                         title: "วันที่ซื้อ",
                         name: "created_at",
                         placeholder: "เลือกวันที่",
                         formatDate: "DD/MM/YYYY",
                         handleSelectDate: handleSelectDate,
                         require: false,
                    },
               ],
               leasing_order: [
                    {
                         type: "text",
                         title: "เลขที่ใบจอง",
                         name: "order_code_id",
                         placeholder: "กรอกเลขที่ใบจอง",
                         handleChangeText: handleChangeText,
                         option: status_acard,
                         value: option?.value?.order_code_id && option?.value?.order_code_id,
                    },
                    {
                         type: "select",
                         name: "leasing_open_credit_status",
                         title: "สถานะสินเชื่อ",
                         placeholder: "เลือก",
                         value: option ? option.value.leasing_open_credit_status : undefined,
                         handleChangeText: handleChangeText,
                         option: credit_status,
                    },
                    {
                         type: "text",
                         name: "saler_name_string",
                         title: "ชื่อผู้ขาย",
                         placeholder: "กรอกชื่อผู้ขาย",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "select",
                         name: "branch_id",
                         title: "สาขา/โชว์รูม",
                         placeholder: "เลือกสาขา/โชว์รูม",
                         value: option?.value?.branch_id && option?.value?.branch_id,
                         handleChangeText: handleChangeText,
                         option: option && option.branch ? option?.branch.map((el) => ({ value: el.branch_id, name: el.branch_name })) : [],
                    },
                    {
                         type: "text",
                         name: "userinfo_a_card",
                         title: "A-Card No.",
                         placeholder: "กรอก A-Card No.",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "date",
                         title: "วันที่จอง",
                         name: "created_at",
                         placeholder: "เลือกวันที่",
                         // value: option.value?.created_at ? dayjs(option.value?.created_at) : '',
                         formatDate: "DD/MM/YYYY",
                         handleSelectDate: handleSelectDate,
                         require: false,
                    },
               ],
               discount: [
                    {
                         type: "select",
                         title: "การอนุมัติใบจอง",
                         placeholder: "เลือกสถานะใบจอง",
                         option: approve_option,
                         name: "status",
                         value: option?.value?.status && option?.value?.status,
                         handleChangeText: handleChangeText,
                    },
                    {
                         type: "text",
                         title: "เลขที่ใบจอง",
                         name: "order_code_id",
                         placeholder: "กรอกเลขที่ใบจอง",
                         handleChangeText: handleChangeText,
                         require: false,
                    },
                    {
                         type: "text",
                         title: "ชื่อผู้ขาย",
                         placeholder: "กรอกชื่อผู้ขาย",
                         require: false,
                         name: "saler_name_string",
                         handleChangeText: handleChangeText,
                    },
                    {
                         type: "select",
                         title: "สาขา/โชว์รูม",
                         placeholder: "เลือกสาขา/โชว์รูม",
                         option: option?.branchs && option?.branchs,
                         name: "branch_id",
                         handleChangeText: handleChangeText,
                         value: option?.value?.branch_id && option?.value?.branch_id,
                    },
                    {
                         type: "text",
                         title: "A-Card No.",
                         placeholder: "กรอก A-Card No.",
                         require: false,
                         name: "userinfo_a_card",
                         handleChangeText: handleChangeText,
                    },
                    {
                         type: "date",
                         title: "วันที่จอง",
                         placeholder: "เลือกวันที่",
                         require: false,
                         formatDate: "DD/MM/YYYY",
                         name: "created_at",
                         handleSelectDate: handleSelectDate,
                    },
               ],
               admin: [
                    {
                         type: "text",
                         title: "Username",
                         name: "username",
                         placeholder: "กรอก Username",
                         require: false,
                         handleChangeText: handleChange && handleChange,
                    },
                    {
                         type: "text",
                         title: "ชื่อผู้ใช้งาน",
                         name: "name",
                         placeholder: "กรอกชื่อ-นามสกุล",
                         require: false,
                         handleChangeText: handleChange && handleChange,
                    },
                    {
                         type: "select",
                         title: "สาขา/โชว์รูม",
                         name: "branch_name",
                         require: false,
                         value: arr && arr.value.branch_name,
                         option: arr && arr.branch,
                         handleChangeText: handleChange && handleChange,
                    },
                    {
                         type: "select",
                         title: "ทีม",
                         name: "team_name",
                         require: false,
                         value: arr && arr.value.team_name,
                         option: arr && arr.team,
                         handleChangeText: handleChange && handleChange,
                    },
                    {
                         type: "select",
                         title: "ตำแหน่ง",
                         name: "position_name",
                         require: false,
                         value: arr && arr.value.position_name,
                         option: arr && arr.position,
                         handleChangeText: handleChange && handleChange,
                    },
                    {
                         type: "select",
                         title: "สถานะ",
                         name: "status",
                         placeholder: "เลือกสถานะ",
                         require: false,
                         value: arr && arr.value.status,
                         option: status_option.slice(1, 3),
                         handleChangeText: handleChange && handleChange,
                    },
               ],
               leasing: [
                    {
                         type: "text",
                         name: "leasing_type",
                         title: "ประเภท Leasing",
                         placeholder: "กรอกประเภท Leasing",
                    },
                    {
                         type: "select",
                         name: "status",
                         title: "สถานะ",
                         placeholder: "เลือกสถานะ",
                         option: status_option,
                    },
               ],
               a_card: [
                    {
                         type: "text",
                         name: "acard_sales",
                         title: "ชื่อผู้ขาย (Sales)",
                         handleChangeText: handleChangeText,
                         placeholder: "กรอก ชื่อผู้ขาย (Sales)",
                         require: false,
                    },
                    {
                         type: "select",
                         name: "branch_id",
                         title: "สาขา Sales (Branch)",
                         value: option && option?.value?.branch_name,
                         handleChangeText: handleChangeText,
                         placeholder: "เลือกสาขา",
                         option:
                              option && option.branch
                                   ? option.branch.map((e) => ({
                                          value: e.branch_id,
                                          name: e.branch_name,
                                     }))
                                   : [],
                    },
                    {
                         type: "text",
                         name: "acard_name",
                         title: "ชื่อลูกค้า",
                         handleChangeText: handleChangeText,
                         placeholder: "กรอกชื่อลูกค้า - นามสกุล",
                         require: false,
                    },
                    {
                         type: "select",
                         name: "acard_customer_type",
                         title: "ประเภทลูกค้า",
                         value: option && option.value?.acard_customer_type,
                         handleChangeText: handleChangeText,
                         placeholder: "เลือกประเภทลูกค้า",
                         option: customer_type,
                    },
                    {
                         type: "text",
                         name: "acard_tel",
                         title: "หมายเลขโทรศัพท์",
                         handleChangeText: handleChangeText,
                         placeholder: "กรอกหมายเลขโทรศัพท์",
                         require: false,
                    },
                    {
                         type: "text",
                         name: "line_id",
                         title: "Line ID",
                         handleChangeText: handleChangeText,
                         placeholder: "กรอก Line ID",
                         require: false,
                    },
                    {
                         type: "select",
                         name: "acard_province",
                         title: "จังหวัด",
                         handleChangeText: handleChangeText,
                         value: option && option?.value?.acard_province,
                         placeholder: "เลือกจังหวัด",
                         option:
                              option && option.province
                                   ? option.province.map((e) => ({
                                          name: e.province,
                                          value: e.province,
                                     }))
                                   : [],
                    },
                    {
                         type: "text",
                         name: "acard_zipcode",
                         title: "รหัสไปรษณีย์",
                         handleChangeText: handleChangeText,
                         placeholder: "กรอกรหัสไปรษณีย์",
                         require: false,
                    },
                    {
                         type: "select",
                         name: "status",
                         title: "status",
                         value: option && option.value?.status,
                         handleChangeText: handleChangeText,
                         placeholder: "เลือก Status",
                         option: status,
                    },
                    {
                         type: "date",
                         name: "acard_create",
                         title: "วันที่สร้าง A-Card (Date)",
                         value: option && option.value?.acard_create ? dayjs(option.value.acard_create) : "",
                         formatDate: "DD/MM/YYYY",
                         handleSelectDate: handleSelectDate,
                         placeholder: "เลือกวันที่",
                         require: false,
                    },
               ],
               car: [
                    {
                         type: "text",
                         title: "เลขตัวถัง",
                         name: "car_tank",
                         placeholder: "กรอกเลขตัวถัง",
                         handleChangeText: handleChangeText,
                         // value: option?.value.status_preemtion,
                    },
                    {
                         type: "text",
                         title: "เลขเครื่องยนต์",
                         name: "car_engine",
                         placeholder: "กรอกเลขเครื่องยนต์",
                         handleChangeText: handleChangeText,
                         // value: option?.value.status_preemtion,
                    },
                    {
                         type: "date",
                         title: "วันที่รถเข้า",
                         name: "car_date",
                         placeholder: "เลือกวันที่",
                         formatDate: "DD/MM/YYYY",
                         handleSelectDate: handleSelectDate,
                         require: false,
                    },

                    // {
                    //      type: "select",
                    //      title: "ประเภทรถ",
                    //      name: "car_type",
                    //      placeholder: "เลือกประเภทรถ",
                    //      handleChangeText: handleChangeText,
                    //      option: car_type,
                    //      value: option?.value?.car_type,
                    // },
                    {
                         type: "select",
                         title: "Series",
                         name: "car_series",
                         placeholder: "เลือก Series",
                         handleChangeText: handleChangeCar,
                         // option: option.car_series,
                         option:
                              option && option.car_series
                                   ? option.car_series.map((e) => ({
                                          name: e.series_name,
                                          value: e.series_id,
                                     }))
                                   : [],
                         value: option?.value?.car_series,
                    },
                    {
                         type: "select",
                         title: "Model",
                         name: "car_model",
                         placeholder: "เลือก Model",
                         handleChangeText: handleChangeCar,

                         option:
                              option && option.car_model
                                   ? option.car_model.map((e) => ({
                                          name: e.model_name,
                                          value: e.model_id,
                                     }))
                                   : [],
                         value: option?.value?.car_model,
                    },
                    {
                         type: "select",
                         title: "Color",
                         name: "car_color",
                         placeholder: "เลือก Color",
                         handleChangeText: handleChangeCar,
                         option:
                              option && option.car_color
                                   ? option.car_color.map((e) => ({
                                          name: e.color_name,
                                          value: e.color_id,
                                     }))
                                   : [],
                         value: option?.value?.car_color,
                    },
               ],
               car_year: [
                    {
                         type: "text",
                         title: "Booking",
                         name: "order_code_id",
                         placeholder: "เลขที่ Booking",
                         handleChangeText: handleChangeText,
                         // value: option?.value.status_preemtion,
                    },
                    {
                         type: "text",
                         title: "เลขตัวถัง",
                         name: "car_tank_success",
                         placeholder: "กรอกเลขตัวถัง",
                         handleChangeText: handleChangeText,
                         // value: option?.value.status_preemtion,
                    },
                    {
                         type: "text",
                         title: "เลขเครื่องยนต์",
                         name: "car_engine_success",
                         placeholder: "กรอกเลขเครื่องยนต์",
                         handleChangeText: handleChangeText,
                         // value: option?.value.status_preemtion,
                    },

                    // {
                    //      type: "select",
                    //      title: "เดือน Booking",
                    //      name: "month",
                    //      // placeholder: "เลือกเดือน",
                    //      handleChangeText: handleChangeCar,
                    //      option: months,
                    //      value: option?.value?.month,
                    // },
                    // {
                    //      type: "select",
                    //      title: "ปี Booking",
                    //      name: "year",
                    //      // placeholder: "เลือกปี",
                    //      handleChangeText: handleChangeCar,
                    //      option: years,
                    //      value: option?.value?.year,
                    // },
               ],
          };
          // console.log(`getToken()`,)
          if (Number(getToken().branch_id) !== 99) {
               field_text.car_order.splice(
                    field_text.car_order.findIndex((el) => el.name === "branch_id"),
                    1
               );
               field_text.a_card.splice(
                    field_text.a_card.findIndex((el) => el.name === "branch_id"),
                    1
               );
               field_text.leasing_order.splice(
                    field_text.leasing_order.findIndex((el) => el.name === "branch_id"),
                    1
               );
               field_text.discount.splice(
                    field_text.discount.findIndex((el) => el.name === "branch_id"),
                    1
               );
          }
          switch (type) {
               case "car-order":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  {field_text.car_order.map((ele) => (
                                                       <Field
                                                            type={ele.type}
                                                            title={ele.title}
                                                            placeholder={ele.placeholder}
                                                            require={ele.require || null}
                                                            option={ele.option || null}
                                                            formatDate={ele.formatDate || null}
                                                            name={ele.name}
                                                            handleSelectDate={ele?.handleSelectDate}
                                                            handleChangeText={ele?.handleChangeText}
                                                            value={ele?.value}
                                                            handleEnter={handleEnter}
                                                       />
                                                  ))}
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "acc":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบสั่งขายอุปกรณ์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  {field_text.acc.map((ele) => (
                                                       <Field
                                                            type={ele.type}
                                                            title={ele.title}
                                                            placeholder={ele.placeholder}
                                                            require={ele.require}
                                                            name={ele.name}
                                                            formatDate={ele.formatDate}
                                                            handleSelectDate={ele?.handleSelectDate}
                                                            handleChangeText={ele?.handleChangeText}
                                                            option={ele.option}
                                                            value={ele.value}
                                                       />
                                                  ))}
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "leasing-order":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจอง <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  {field_text.leasing_order.map((ele) => (
                                                       <Field
                                                            type={ele.type}
                                                            title={ele.title}
                                                            name={ele.name}
                                                            placeholder={ele.placeholder}
                                                            value={ele?.value}
                                                            formatDate={ele.formatDate}
                                                            require={ele.require || null}
                                                            option={ele.option || null}
                                                            handleSelectDate={ele?.handleSelectDate}
                                                            handleChangeText={ele?.handleChangeText}
                                                       />
                                                  ))}
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "discount":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  {field_text.discount.map((ele) => (
                                                       <Field
                                                            type={ele.type}
                                                            title={ele.title}
                                                            placeholder={ele.placeholder}
                                                            name={ele.name}
                                                            value={ele.value}
                                                            formatDate={ele.formatDate}
                                                            require={ele.require || null}
                                                            option={
                                                                 ele.name === "branch_id"
                                                                      ? ele.option
                                                                           ? ele.option.map((el) => ({ value: el.branch_id, name: el.branch_name }))
                                                                           : null
                                                                      : ele.option
                                                                      ? ele.option.map((el) => ({ value: el.name, name: el.name }))
                                                                      : null
                                                            }
                                                            handleChangeText={ele.handleChangeText}
                                                            handleSelectDate={ele.handleSelectDate}
                                                       />
                                                  ))}
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "admin":
                    return (
                         <Accordion defaultActiveKey="0">
                              <div className="search">
                                   <Accordion.Toggle eventKey="0" className="-header">
                                        <div>
                                             ค้นหาผู้ใช้งาน <i className="fas fa-sort-down"></i>
                                        </div>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0" className="-body">
                                        <div className="content">
                                             {field_text.admin.map((ele) => (
                                                  <Field
                                                       type={ele.type}
                                                       title={ele.title}
                                                       name={ele.name}
                                                       value={ele.value || null}
                                                       placeholder={ele.placeholder || null}
                                                       option={ele.option || null}
                                                       handleChangeText={ele.handleChangeText}
                                                  />
                                             ))}
                                             <Button onClick={onSearch}>ค้นหา</Button>
                                             <Button onClick={downloadUser}>ดาวน์โหลด{textDownload ? textDownload : ""}</Button>
                                        </div>
                                   </Accordion.Collapse>
                              </div>
                         </Accordion>
                    );
               case "squard":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาทีม <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อทีม"}
                                                       placeholder={"กรอก ชื่อทีม"}
                                                       require={false}
                                                       name="team_name"
                                                       value={option.value.team_name ? option.value.team_name : undefined}
                                                       handleChangeText={handleChange && handleChange}
                                                  />
                                                  <Field
                                                       type={"select"}
                                                       title={"สาขา/โชว์รูม"}
                                                       placeholder={"เลือกสาขา/โชว์รูม"}
                                                       name="branch_id"
                                                       option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                       value={option.value.branch_id ? option.value.branch_id : undefined}
                                                       handleChangeText={handleChange}
                                                  />
                                                  <Button onClick={onSearch && onSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "branch_list":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาสาขา <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"select"}
                                                       title={"สาขา/โชว์รูม"}
                                                       placeholder={"เลือกสาขา/โชว์รูม"}
                                                       name="branch_id"
                                                       option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                       value={option.value.branch_id ? option.value.branch_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={onSearch && onSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "insurance":
                    return (
                         <Accordion defaultActiveKey="0">
                              <div className="search">
                                   <Accordion.Toggle eventKey="0" className="-header">
                                        <div>
                                             ค้นหาประกัน <i className="fas fa-sort-down"></i>
                                        </div>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0" className="-body">
                                        <div className="content">
                                             <Field
                                                  name={"insurance_type"}
                                                  type={"text"}
                                                  title={"ประเภทประกัน"}
                                                  placeholder={"กรอกประเภท ประกัน"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             />
                                             <Field
                                                  name={"status"}
                                                  type={"select"}
                                                  title={"สถานะ"}
                                                  option={status_option}
                                                  value={searchOptions.status ? searchOptions.status : ""}
                                                  placeholder={"เลือกสถานะ"}
                                                  handleChangeText={handleChange}
                                             />
                                             <Button onClick={onSearch}>ค้นหา</Button>
                                        </div>
                                   </Accordion.Collapse>
                              </div>
                         </Accordion>
                    );
               case "leasing":
                    return (
                         <Accordion defaultActiveKey="0">
                              <div className="search">
                                   <Accordion.Toggle eventKey="0" className="-header">
                                        <div>
                                             ค้นหาบริษัท Leasing <i className="fas fa-sort-down"></i>
                                        </div>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0" className="-body">
                                        <div className="content">
                                             <Field
                                                  name={"leasing_type"}
                                                  type={"text"}
                                                  title={"ประเภท Leasing"}
                                                  placeholder={"กรอกประเภท Leasing"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             />
                                             <Field
                                                  name={"series_name"}
                                                  type={"select"}
                                                  title={"Series"}
                                                  option={searchOptions.searchSeries}
                                                  value={searchOptions.series_name}
                                                  placeholder={"เลือก Series"}
                                                  handleChangeText={handleChange}
                                             />
                                             <Field
                                                  name={"status"}
                                                  type={"select"}
                                                  title={"สถานะ"}
                                                  option={status_option}
                                                  value={searchOptions.status ? searchOptions.status : ""}
                                                  placeholder={"เลือกสถานะ"}
                                                  handleChangeText={handleChange}
                                             />
                                             <Button onClick={onSearch}>ค้นหา</Button>
                                        </div>
                                   </Accordion.Collapse>
                              </div>
                         </Accordion>
                    );
               case "supplier-list":
                    return (
                         <Accordion defaultActiveKey="0">
                              <div className="search">
                                   <Accordion.Toggle eventKey="0" className="-header">
                                        <div>
                                             ค้นหา Supplier <i className="fas fa-sort-down"></i>
                                        </div>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0" className="-body">
                                        <div className="content">
                                             <Field
                                                  name={"supplier_code"}
                                                  type={"text"}
                                                  title={"Supplier Code"}
                                                  placeholder={"กรอก Supplier Code"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             />
                                             <Field
                                                  name={"supplier_name"}
                                                  type={"text"}
                                                  title={"Supplier Name"}
                                                  placeholder={"กรอก Supplier Name"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             />
                                             <Field
                                                  name={"status"}
                                                  type={"select"}
                                                  title={"สถานะ"}
                                                  option={status_option}
                                                  value={searchOptions.status ? searchOptions.status : ""}
                                                  placeholder={"เลือกสถานะ"}
                                                  handleChangeText={handleChange}
                                             />
                                             <Field
                                                  name={"supplier_type"}
                                                  type={"select"}
                                                  title={"ประเภท Supplier"}
                                                  value={searchOptions.supplier_type ? searchOptions.supplier_type : ""}
                                                  placeholder={"เลือกประเภท Supplier"}
                                                  option={supplier_type}
                                                  handleChangeText={handleChange}
                                             />
                                             {/* <Field
                                                  name={"acept_status"}
                                                  type={"select"}
                                                  title={"สถานะการอนุมัติ"}
                                                  value={searchOptions.acept_status ? searchOptions.acept_status : ""}
                                                  placeholder={"เลือกสถานะการอนุมัติ"}
                                                  option={approve_option}
                                                  handleChangeText={handleChange}
                                             /> */}
                                             <Button onClick={onSearch}>ค้นหา</Button>
                                        </div>
                                   </Accordion.Collapse>
                              </div>
                         </Accordion>
                    );
               case "supplier-setting":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารายการที่รอการอนุมัติ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name={"supplier_code"}
                                                       type={"text"}
                                                       title={"Supplier Code"}
                                                       placeholder={"กรอก Supplier Code"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name={"supplier_name"}
                                                       type={"text"}
                                                       title={"Supplier Name"}
                                                       placeholder={"กรอก Supplier Name"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name={"supplier_type"}
                                                       type={"select"}
                                                       title={"Supplier Type"}
                                                       option={supplier_type}
                                                       value={searchOptions.supplier_type ? searchOptions.supplier_type : ""}
                                                       placeholder={"เลือกประเภท Supplier"}
                                                       handleChangeText={handleChange}
                                                  />
                                                  <Field
                                                       name={"manage_type"}
                                                       type={"select"}
                                                       title={"ประเภทรายการ"}
                                                       option={manage_type}
                                                       value={searchOptions.manage_type ? searchOptions.manage_type : ""}
                                                       placeholder={"เลือกประเภทรายการ"}
                                                       handleChangeText={handleChange}
                                                  />
                                                  <Button onClick={onSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "upload":
                    return (
                         <div className="search mt-4">
                              <div className="-body">
                                   <div className="content">
                                        <Field
                                             type={"file"}
                                             title={title}
                                             placeholder={placeholder}
                                             handleChange={handleChange}
                                             button={button}
                                             style_id={style_id}
                                             require={false}
                                             accept={".csv"}
                                        />
                                        <Button onClick={handleUploadFile && handleUploadFile} disabled={disabled} style={style}>
                                             อัปโหลด
                                        </Button>
                                        <Button onClick={downloadFile} style={style}>
                                             ดาวน์โหลด{textDownload ? textDownload : ""}
                                        </Button>
                                   </div>
                              </div>
                         </div>
                    );
               case "upload-supplier":
                    return (
                         <div className="search mt-4">
                              <div className="-body">
                                   <div className="content">
                                        {itemUp ? (
                                             <Field
                                                  type={"select"}
                                                  title={"ประเภทอุปกรณ์"}
                                                  name={"item_type"}
                                                  placeholder={"เลือกประเภท item"}
                                                  option={item_type_upload}
                                                  value={typeSup?.item_type}
                                                  handleChangeText={handleChangeOption}
                                             />
                                        ) : (
                                             <Field
                                                  type={"select"}
                                                  title={"Supplier Type"}
                                                  name={"supplier_type"}
                                                  placeholder={"เลือกประเภท Supplier"}
                                                  option={supplier_type_upload}
                                                  value={typeSup?.supplier_type}
                                                  handleChangeText={handleChangeOption}
                                             />
                                        )}
                                        <Field
                                             type={"file"}
                                             title={title}
                                             placeholder={placeholder}
                                             handleChange={handleChange}
                                             button={button}
                                             style_id={style_id}
                                             require={false}
                                             accept={".csv"}
                                        />
                                        <Button onClick={handleUploadFile && handleUploadFile} disabled={disabled}>
                                             อัปโหลด
                                        </Button>
                                        <Button onClick={downloadFile} style={style}>
                                             ดาวน์โหลด
                                        </Button>
                                   </div>
                              </div>
                         </div>
                    );
               case "car":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  {field_text.car.map((ele) => (
                                                       <Field
                                                            type={ele.type}
                                                            title={ele.title}
                                                            placeholder={ele.placeholder}
                                                            require={ele.require || null}
                                                            option={ele.option || null}
                                                            formatDate={ele.formatDate || null}
                                                            name={ele.name}
                                                            handleSelectDate={ele?.handleSelectDate}
                                                            handleChangeText={ele?.handleChangeText}
                                                            value={ele?.value}
                                                       />
                                                  ))}
                                                  <Button onClick={() => handleClickSearch("sale")}>ค้นหา</Button>
                                                  {field_text.car_year.map((ele) => (
                                                       <Field
                                                            type={ele.type}
                                                            title={ele.title}
                                                            placeholder={ele.placeholder}
                                                            require={ele.require || null}
                                                            option={ele.option || null}
                                                            formatDate={ele.formatDate || null}
                                                            name={ele.name}
                                                            handleSelectDate={ele?.handleSelectDate}
                                                            handleChangeText={ele?.handleChangeText}
                                                            value={ele?.value}
                                                       />
                                                  ))}
                                                  {addBtn && <Button onClick={() => handleClickSearch("saled")}>สต็อกส่งมอบแล้ว</Button>}
                                                  <Button onClick={() => handleClickSearch("pending")}>รถกำลังอยู่ระหว่างนำเข้า</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "item":
                    return (
                         <Accordion defaultActiveKey="0">
                              <div className="search">
                                   <Accordion.Toggle eventKey="0" className="-header">
                                        <div>
                                             ค้นหาอุปกรณ์ <i className="fas fa-sort-down"></i>
                                        </div>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0" className="-body">
                                        <div className="content">
                                             <Field
                                                  type={"text"}
                                                  name={"items_code"}
                                                  title={"Item Code"}
                                                  placeholder={"กรอก Item Code"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             />
                                             <Field
                                                  type={"text"}
                                                  name={"items_name"}
                                                  title={"Item Name"}
                                                  placeholder={"กรอก Item Name"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             />
                                             {/* <Field
                                                  type={"select"}
                                                  name={"items_type"}
                                                  title={"ประเภท Item"}
                                                  placeholder={"เลือกประเภท Item"}
                                                  value={searchOptions.items_type ? searchOptions.items_type : ""}
                                                  option={item_type}
                                                  handleChangeText={handleChange}
                                             /> */}
                                             <Field
                                                  type={"select"}
                                                  name={"supplier_name"}
                                                  title={"Supplier"}
                                                  placeholder={"เลือก Supplier Name"}
                                                  value={searchOptions.supplier_name ? searchOptions.supplier_name : ""}
                                                  option={searchOptions.sup_all}
                                                  handleChangeText={handleChange}
                                             />
                                             {/* <Field
                                                  type={"text"}
                                                  name={"supplier_name"}
                                                  title={"Supplier"}
                                                  placeholder={"กรอก Supplier Name"}
                                                  require={false}
                                                  handleChangeText={handleChangeText}
                                             /> */}
                                             {/* <Field
                                                  type={"select"}
                                                  name={"series_name"}
                                                  title={"Series"}
                                                  placeholder={"เลือก Series"}
                                                  value={searchOptions.series_name}
                                                  option={searchOptions.searchSeries}
                                                  handleChangeText={handleChange}
                                             /> */}
                                             {/* <Field
                                                  type={"select"}
                                                  name={"status"}
                                                  title={"สถานะ"}
                                                  placeholder={"เลือกสถานะ"}
                                                  value={searchOptions.status ? searchOptions.status : ""}
                                                  option={status_option}
                                                  handleChangeText={handleChange}
                                             /> */}
                                             <Button onClick={onSearch}>ค้นหา</Button>
                                        </div>
                                   </Accordion.Collapse>
                              </div>
                         </Accordion>
                    );
               case "approve":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารายการที่รอการอนุมัติ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"text"}
                                                       name={"items_code"}
                                                       title={"Item Code"}
                                                       placeholder={"กรอก Item Code"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       name={"items_name"}
                                                       title={"Item Name"}
                                                       placeholder={"กรอก Item Name"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"select"}
                                                       name={"items_type"}
                                                       title={"ประเภท Item"}
                                                       placeholder={"เลือกประเภท Item"}
                                                       option={supplier_type}
                                                       handleChangeText={handleChange}
                                                       value={searchOptions.items_type ? searchOptions.items_type : ""}
                                                  />
                                                  {/* <Field 
                                                  type={"select"} 
                                                  title={"Supplier"} 
                                                  placeholder={"เลือก Supplier"} 
                                                  option={}
                                                  /> */}
                                                  <Field
                                                       type={"text"}
                                                       name={"supplier_name"}
                                                       title={"Supplier"}
                                                       placeholder={"กรอก Supplier Name"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"select"}
                                                       name={"manage_type"}
                                                       title={"ประเภทรายการ"}
                                                       placeholder={"เลือกรายการ"}
                                                       option={manage_type}
                                                       handleChangeText={handleChange}
                                                       value={searchOptions.manage_type ? searchOptions.manage_type : ""}
                                                  />
                                                  <Button onClick={onSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "car-model":
                    return (
                         <Accordion defaultActiveKey="0">
                              <div className="search">
                                   <Accordion.Toggle eventKey="0" className="-header">
                                        <div>
                                             ค้นหารถยนต์ <i className="fas fa-sort-down"></i>
                                        </div>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey="0" className="-body">
                                        <div className="content">
                                             {/* กรอก Series */}
                                             <Field
                                                  type={"text"}
                                                  name={"series_code"}
                                                  title={"Series Code"}
                                                  placeholder={"กรุณากรอก Series Code"}
                                                  handleChangeText={handleChangeText}
                                             />
                                             <Field
                                                  type={"text"}
                                                  name={"series_name"}
                                                  title={"Series"}
                                                  placeholder={"กรุณากรอก Series"}
                                                  handleChangeText={handleChangeText}
                                             />
                                             {/* เลือก Series */}
                                             {/* <Field
                      type={"select"}
                      name={"series_name"}
                      title={"Series"}
                      placeholder={"เลือก Series"}
                      option={obj.series}
                      handleChangeText={handleChange}
                      value={searchOptions.series_name ? searchOptions.series_name : ""}
                    /> */}
                                             {/* <Field
                                                  type={"select"}
                                                  name={"model"}
                                                  title={"Model"}
                                                  placeholder={"เลือก Model"}
                                                  option={model}
                                                  handleChangeText={handleChange}
                                                  value={searchOptions.model ? searchOptions.model : ""}
                                             /> */}
                                             {/* <Field
                      type={"select"}
                      name={"color"}
                      title={"Color"}
                      placeholder={"เลือก Color"}
                      option={color}
                      handleChangeText={handleChange}
                      value={searchOptions.color ? searchOptions.color : ""}
                    /> */}
                                             <Field
                                                  type={"select"}
                                                  name={"status"}
                                                  title={"สถานะ"}
                                                  placeholder={"เลือกสถานะ"}
                                                  option={status_option}
                                                  handleChangeText={handleChange}
                                                  value={searchOptions.status ? searchOptions.status : ""}
                                             />
                                             <Button onClick={onSearch}>ค้นหา</Button>
                                        </div>
                                   </Accordion.Collapse>
                              </div>
                         </Accordion>
                    );
               case "carpayment":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจอง <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name="orcp_status"
                                                       type={"select"}
                                                       title={"การชำระเงิน"}
                                                       placeholder={"เลือกการชำระเงิน"}
                                                       option={payment_status}
                                                       handleChangeText={handleChangeText}
                                                       value={option ? option.value.orcp_status : undefined}
                                                  />
                                                  <Field
                                                       name="order_code_id"
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {Number(getToken().branch_id) === 99 && (
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา/โชว์รูม"}
                                                            placeholder={"เลือกสาขา/โชว์รูม"}
                                                            name="branch_id"
                                                            option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            value={option.value.branch_id ? option.value.branch_id : undefined}
                                                            handleChangeText={handleChangeText}
                                                       />
                                                  )}
                                                  <Field
                                                       name="created_at"
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       name="userinfo_name"
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="saler_name_string"
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {/* <Field
                                                       name="pay_choice"
                                                       type={"select"}
                                                       title={"วิธีชำระเงิน"}
                                                       placeholder={"เลือกวิธีชำระเงิน"}
                                                       option={payment_type}
                                                       handleChangeText={handleChangeText}
                                                       value={option ? option.value.pay_choice : undefined}
                                                  /> */}
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "carpayment-leasing":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจอง <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name="orcp_status"
                                                       type={"select"}
                                                       title={"การชำระเงิน"}
                                                       placeholder={"เลือกการชำระเงิน"}
                                                       option={payment_status_leasing}
                                                       handleChangeText={handleChangeText}
                                                       value={option ? option.value.orcp_status : undefined}
                                                  />
                                                  <Field
                                                       name="order_code_id"
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {Number(getToken().branch_id) === 99 && (
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา/โชว์รูม"}
                                                            placeholder={"เลือกสาขา/โชว์รูม"}
                                                            name="branch_id"
                                                            option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            value={option.value.branch_id ? option.value.branch_id : undefined}
                                                            handleChangeText={handleChangeText}
                                                       />
                                                  )}
                                                  <Field
                                                       name="created_at"
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       name="userinfo_name"
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="saler_name_string"
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {/* <Field
                                                            name="pay_choice"
                                                            type={"select"}
                                                            title={"วิธีชำระเงิน"}
                                                            placeholder={"เลือกวิธีชำระเงิน"}
                                                            option={payment_type}
                                                            handleChangeText={handleChangeText}
                                                            value={option ? option.value.pay_choice : undefined}
                                                       /> */}
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "accpayment":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบสั่งขายอุปกรณ์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       value={option ? option.value.accp_status : undefined}
                                                       name="accp_status"
                                                       type={"select"}
                                                       title={"การชำระเงิน"}
                                                       placeholder={"เลือกวิธีชำระเงิน"}
                                                       option={price}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="acorder_code_id"
                                                       type={"text"}
                                                       title={"เลขที่ใบสั่งขายอุปกรณ์"}
                                                       placeholder={"กรอกเลขที่ใบสั่งขายอุปกรณ์"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="saler_name"
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="acorder_name"
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="created_at"
                                                       type={"date"}
                                                       title={"วันที่ซื้อ"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "oderpayment":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจอง <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"select"}
                                                       title={"สถานะเงินจอง"}
                                                       placeholder={"เลือกสถานะเงินจอง"}
                                                       option={price_status}
                                                       handleChangeText={handleChangeText}
                                                       name="orp_status"
                                                       value={option.value.orp_status ? option.value.orp_status : undefined}
                                                  />
                                                  <Field
                                                       handleChangeText={handleChangeText}
                                                       name={"order_code_id"}
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                  />
                                                  <Field
                                                       handleChangeText={handleChangeText}
                                                       name="saler_name_string"
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                  />
                                                  {Number(getToken().branch_id) === 99 && (
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา/โชว์รูม"}
                                                            placeholder={"เลือกสาขา/โชว์รูม"}
                                                            name="branch_id"
                                                            option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            value={option.value.branch_id ? option.value.branch_id : undefined}
                                                            handleChangeText={handleChangeText}
                                                       />
                                                  )}
                                                  <Field
                                                       handleChangeText={handleChangeText}
                                                       name="userinfo_a_card"
                                                       type={"text"}
                                                       title={"A-Card No."}
                                                       placeholder={"กรอก A-Card No."}
                                                       require={false}
                                                  />
                                                  <Field
                                                       handleSelectDate={handleSelectDate}
                                                       name="created_at"
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                  />

                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "tax":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       handleChangeText={handleChangeText}
                                                       name={"order_code_id"}
                                                       value={option?.value?.order_code_id && option.value.order_code_id}
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       handleSelectDate={handleSelectDate}
                                                       name="created_at"
                                                       value={option?.value?.booking_date && option.value.booking_date}
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                  />
                                                  <Field
                                                       handleChangeText={handleChangeText}
                                                       name="userinfo_name"
                                                       value={option?.value?.userinfo_name && option.value.userinfo_name}
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                  />
                                                  <Field
                                                       handleChangeText={handleChangeText}
                                                       name="saler_name_string"
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       value={option?.value?.saler_name_string && option.value.saler_name_string}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                  />
                                                  <Field
                                                       name="oder_turnpay_status"
                                                       type={"select"}
                                                       title={"สถานะ"}
                                                       placeholder={"เลือกสถานะ"}
                                                       option={tax}
                                                       handleChangeText={handleChangeText}
                                                       value={option?.value?.oder_turnpay_status && option.value.oder_turnpay_status}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "ls-app":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจอง <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field type={"text"} title={"ชื่อผู้ขาย"} placeholder={"กรอกชื่อผู้ขาย"} require={false} />
                                                  <Field
                                                       type={"select"}
                                                       title={"สาขา/โชว์รูม"}
                                                       placeholder={"เลือกสาขา/โชว์รูม"}
                                                       option={["ขอนแก่น", "กรุงเทพ"]}
                                                  />
                                                  <Field type={"text"} title={"A-Card No."} placeholder={"กรอก A-Card No."} require={false} />
                                                  <Field type={"date"} title={"วันที่จอง"} placeholder={"เลือกวันที่จอง"} require={false} />
                                                  <Field type={"select"} title={"สถานะสินเชื่อ"} placeholder={"เลือกสถานะสินเชื่อ"} option={ls_app} />
                                                  <Button>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "deliver-car":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"select"}
                                                       title={"สถานะใบจอง"}
                                                       name={"acept_status"}
                                                       placeholder={"เลือกสถานะ"}
                                                       handleChangeText={handleChangeText}
                                                       option={status_acard}
                                                       value={option?.acept_status && option?.acept_status}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       name="order_code_id"
                                                       value={option ? option.value.order_code_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {Number(getToken().branch_id) === 99 && (
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา/โชว์รูม"}
                                                            placeholder={"เลือกสาขา/โชว์รูม"}
                                                            name="branch_id"
                                                            option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            value={option.value.branch_id ? option.value.branch_id : undefined}
                                                            handleChangeText={handleChangeText}
                                                       />
                                                  )}
                                                  {/* <Field
                                                       type={"text"}
                                                       title={"A-Card No."}
                                                       placeholder={"กรอก A-Card No."}
                                                       require={false}
                                                       name="userinfo_a_card"
                                                       value={option ? option.value.userinfo_a_card : undefined}
                                                       handleChangeText={handleChangeText}
                                                  /> */}
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       name="userinfo_name"
                                                       value={option ? option.value.userinfo_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       name="saler_name_string"
                                                       value={option ? option.value.saler_name_string : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       name="created_at"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่ส่งมอบ"}
                                                       placeholder={"เลือกวันที่"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       name="delivery_date"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "match-car":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารุ่นรถ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name="series_name"
                                                       value={option ? option.value.series_name : undefined}
                                                       type={"select"}
                                                       title={"Serie"}
                                                       placeholder={"เลือก Serie"}
                                                       option={series}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="model_name"
                                                       value={option ? option.value.model_name : undefined}
                                                       type={"select"}
                                                       title={"Model"}
                                                       placeholder={"เลือก Model"}
                                                       option={model}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="color_name"
                                                       value={option ? option.value.color_name : undefined}
                                                       type={"select"}
                                                       title={"สี"}
                                                       placeholder={"เลือกสี"}
                                                       option={color}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "match-list":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารายการ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name="order_code_id"
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="series_name"
                                                       value={option ? option.value.series_name : undefined}
                                                       type={"select"}
                                                       title={"Serie"}
                                                       placeholder={"เลือก Serie"}
                                                       option={series}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="model_name"
                                                       value={option ? option.value.model_name : undefined}
                                                       type={"select"}
                                                       title={"Model"}
                                                       placeholder={"เลือก Model"}
                                                       option={model}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="color_name"
                                                       value={option ? option.value.color_name : undefined}
                                                       type={"select"}
                                                       title={"สี"}
                                                       placeholder={"เลือกสี"}
                                                       option={color}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );

               case "install-device":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารายการ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"text"}
                                                       title={"Supplier Code"}
                                                       placeholder={"กรอก Supplier Code"}
                                                       require={false}
                                                       name="supplier_code"
                                                       value={option ? option.value.supplier_code : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {/* <Field
                                                       type={"select"}
                                                       option={option ? option.value.supplier : []}
                                                       title={"Supplier Name"}
                                                       placeholder={"เลือก Supplier Name"}
                                                       require={false}
                                                       name="supplier_name"
                                                       value={option ? option.value.supplier_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  /> */}
                                                  <Field
                                                       type={"text"}
                                                       title={"Supplier Name"}
                                                       placeholder={"กรอก Supplier Name"}
                                                       require={false}
                                                       name="supplier_name"
                                                       value={option ? option.value.supplier_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "form":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name={"acept_status"}
                                                       type={"select"}
                                                       title={"สถานะใบจอง"}
                                                       value={option?.value?.acept_status ? option.value.acept_status : ""}
                                                       placeholder={"เลือกสถานะใบจอง"}
                                                       option={status_acard}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"เลือกเลขที่ใบจอง"}
                                                       require={false}
                                                       name={"order_code_id"}
                                                       value={option ? option.value.order_code_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name={"year_book"}
                                                       type={"date"}
                                                       title={"ปีที่สั่งจอง"}
                                                       placeholder={"เลือกปี"}
                                                       require={false}
                                                       handleSelectDate={handleSelectDate}
                                                       picker={"year"}
                                                  />
                                                  <Field
                                                       name={"month_book"}
                                                       type={"date"}
                                                       title={"เดือนที่สั่งจอง"}
                                                       placeholder={"เลือกเดือน"}
                                                       require={false}
                                                       handleSelectDate={handleSelectDate}
                                                       picker={"month"}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       name="userinfo_name"
                                                       value={option ? option.value.userinfo_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อพนักงานขาย"}
                                                       placeholder={"กรอกชื่อพนักงานขาย"}
                                                       require={false}
                                                       name="saler_name_string"
                                                       value={option ? option.value.saler_name_string : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );

               case "a-card":
                    return (
                         <div>
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <strong>ค้นหา A-Card</strong>
                                                  <Row>
                                                       <Col md={12}>
                                                            <Field
                                                                 type={"text"}
                                                                 title={"A-Card No."}
                                                                 name={"acard_no"}
                                                                 placeholder={"กรอก A-Card No."}
                                                                 handleChangeText={handleChangeText}
                                                                 require={false}
                                                            />
                                                       </Col>
                                                  </Row>
                                                  <Row>
                                                       {field_text.a_card.map((ele) => (
                                                            <Col md={6}>
                                                                 <Field
                                                                      type={ele.type}
                                                                      title={ele.title}
                                                                      name={ele.name}
                                                                      value={ele.value || null}
                                                                      placeholder={ele.placeholder}
                                                                      formatDate={ele.formatDate || null}
                                                                      handleChangeText={ele.handleChangeText}
                                                                      handleSelectDate={ele.handleSelectDate}
                                                                      require={ele.require || null}
                                                                      option={ele.option || null}
                                                                 />
                                                            </Col>
                                                       ))}
                                                  </Row>
                                                  <Col lg={{ span: 4, offset: 4 }}>
                                                       <Button onClick={onClick}>ค้นหา</Button>
                                                  </Col>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );

               case "promotion":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  {/* กรอก Series */}
                                                  <Field
                                                       type={"text"}
                                                       name={"series_name"}
                                                       title={"Series"}
                                                       placeholder={"กรุณากรอก Series"}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {/* เลือก Series */}
                                                  {/* <Field
                      type={"select"}
                      name={"series_name"}
                      title={"Series"}
                      placeholder={"เลือก Series"}
                      option={obj.series}
                      handleChangeText={handleChange}
                      value={searchOptions.series_name ? searchOptions.series_name : ""}
                    /> */}
                                                  {/* <Field
                                                       type={"select"}
                                                       name={"model"}
                                                       title={"Model"}
                                                       placeholder={"เลือก Model"}
                                                       option={model}
                                                       handleChangeText={handleChange}
                                                       value={searchOptions?.model ? searchOptions.model : ""}
                                                  /> */}
                                                  {/* <Field
                      type={"select"}
                      name={"color"}
                      title={"Color"}
                      placeholder={"เลือก Color"}
                      option={color}
                      handleChangeText={handleChange}
                      value={searchOptions.color ? searchOptions.color : ""}
                    /> */}
                                                  <Field
                                                       type={"select"}
                                                       name={"status"}
                                                       title={"สถานะ"}
                                                       placeholder={"เลือกสถานะ"}
                                                       option={status_option}
                                                       handleChangeText={handleChange}
                                                       value={searchOptions?.status ? searchOptions.status : ""}
                                                  />
                                                  <Button onClick={onSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "list-label":
                    return (
                         <div className={"search-bar " + className}>
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาป้ายแดง <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"select"}
                                                       title={"สถานะป้ายแดง"}
                                                       placeholder={"เลือกสถานะป้ายแดง"}
                                                       require={false}
                                                       option={[
                                                            { value: "ทั้งหมด", name: "ทั้งหมด" },
                                                            { value: "ว่าง", name: "ว่าง" },
                                                            { value: "ไม่ว่าง", name: "ไม่ว่าง" },
                                                       ]}
                                                       name={"free"}
                                                       value={option ? option.value.free : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขทะเบียน"}
                                                       placeholder={"กรอกเลขทะเบียน"}
                                                       require={false}
                                                       name={"label_no"}
                                                       value={option ? option.value.label_no : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"เลือกเลขที่ใบจอง"}
                                                       require={false}
                                                       name={"order_code_id"}
                                                       value={option ? option.value.order_code_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {Number(getToken().branch_id) === 99 && (
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา/โชว์รูม"}
                                                            placeholder={"เลือกสาขา/โชว์รูม"}
                                                            name="branch_id"
                                                            option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            value={option.value.branch_id ? option.value.branch_id : undefined}
                                                            handleChangeText={handleChangeText}
                                                       />
                                                  )}
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่ยืม"}
                                                       placeholder={"เลือกวันที่ยืม"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       name="label_brrow_date"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่เบิก"}
                                                       placeholder={"เลือกวันที่เบิก"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       name="labelhis_open_date"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "savelabel":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"select"}
                                                       title={"สถานะการจดทะเบียน"}
                                                       placeholder={"เลือก"}
                                                       option={checklabel_status}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                       name="checklabel_status"
                                                       value={option.value.checklabel_status ? option.value.checklabel_status : undefined}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       name="order_code_id"
                                                       value={option ? option.value.order_code_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       name="created_at"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       name="userinfo_name"
                                                       value={option ? option.value.userinfo_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       name="saler_name_string"
                                                       value={option ? option.value.saler_name_string : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "cancellabel":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       name="order_code_id"
                                                       value={option ? option.value.order_code_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       name="userinfo_name"
                                                       value={option ? option.value.userinfo_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       name="saler_name_string"
                                                       value={option ? option.value.saler_name_string : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {/* <Field
                                                       type={"text"}
                                                       title={"A-Card No."}
                                                       placeholder={"กรอก A-Card No."}
                                                       require={false}
                                                       name="userinfo_a_card"
                                                       value={option ? option.value.userinfo_a_card : undefined}
                                                       handleChangeText={handleChangeText}
                                                  /> */}
                                                  <Field
                                                       type={"select"}
                                                       title={"สาขา/โชว์รูม"}
                                                       placeholder={"เลือกสาขา/โชว์รูม"}
                                                       option={showroom_type}
                                                       require={false}
                                                       name={"branch_name"}
                                                       value={option ? option.value.branch_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                       formatDate={"DD/MM/YYYY"}
                                                       name="created_at"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่ส่งมอบ"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                       formatDate={"DD/MM/YYYY"}
                                                       name="getcar_date"
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "registerlabel":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหาใบจองรถยนต์ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       name="order_code_id"
                                                       value={option ? option.value.order_code_id : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                       name="created_at"
                                                       formatDate={"DD/MM/YYYY"}
                                                       handleSelectDate={handleSelectDate}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อลูกค้า"}
                                                       placeholder={"กรอกชื่อลูกค้า"}
                                                       require={false}
                                                       name="userinfo_name"
                                                       value={option ? option.value.userinfo_name : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       name="saler_name_string"
                                                       value={option ? option.value.saler_name_string : undefined}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "match-confirm":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารายการที่รอยืนยัน <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       name="order_code_id"
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="series_name"
                                                       value={option ? option.value.series_name : undefined}
                                                       type={"select"}
                                                       title={"Serie"}
                                                       placeholder={"เลือก Serie"}
                                                       option={series}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="model_name"
                                                       value={option ? option.value.model_name : undefined}
                                                       type={"select"}
                                                       title={"Model"}
                                                       placeholder={"เลือก Model"}
                                                       option={model}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Field
                                                       name="color_name"
                                                       value={option ? option.value.color_name : undefined}
                                                       type={"select"}
                                                       title={"สี"}
                                                       placeholder={"เลือกสี"}
                                                       option={color}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               case "install-list":
                    return (
                         <div className="search-bar">
                              <Accordion defaultActiveKey="0">
                                   <div className="search">
                                        <Accordion.Toggle eventKey="0" className="-header">
                                             <div>
                                                  ค้นหารายการ <i className="fas fa-sort-down"></i>
                                             </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className="-body">
                                             <div className="content">
                                                  <Field
                                                       type={"select"}
                                                       title={"สถานะในการติดตั้ง"}
                                                       placeholder={"เลือก"}
                                                       option={status_install}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                       name="ac_install"
                                                       value={option.value.ac_install ? option.value.ac_install : undefined}
                                                  />
                                                  <Field
                                                       type={"text"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                       name="order_code_id"
                                                       value={option.value.order_code_id ? option.value.order_code_id : undefined}
                                                  />
                                                  <Field
                                                       name="car_tank"
                                                       type={"text"}
                                                       title={"เลขตัวถัง"}
                                                       placeholder={"กรอกเลขตัวถัง"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                  />
                                                  {Number(getToken().branch_id) === 99 && (
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา/โชว์รูม"}
                                                            placeholder={"เลือกสาขา/โชว์รูม"}
                                                            name="branch_id"
                                                            option={option.branchs?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            value={option.value.branch_id ? option.value.branch_id : undefined}
                                                            handleChangeText={handleChangeText}
                                                       />
                                                  )}
                                                  <Field
                                                       type={"text"}
                                                       title={"ชื่อผู้ขาย"}
                                                       placeholder={"กรอกชื่อผู้ขาย"}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                       name="saler_name_string"
                                                       value={option.value.saler_name_string ? option.value.saler_name_string : undefined}
                                                  />
                                                  {/* <Field
                                                       type={"select"}
                                                       title={"สาขา/โชว์รูม"}
                                                       placeholder={"เลือกสาขา/โชว์รูม"}
                                                       option={showroom_type}
                                                       require={false}
                                                  /> */}
                                                  <Field
                                                       type={"text"}
                                                       title={"A-Card No."}
                                                       placeholder={"กรอก A-Card No."}
                                                       require={false}
                                                       handleChangeText={handleChangeText}
                                                       name="userinfo_a_card"
                                                       value={option.value.userinfo_a_card ? option.value.userinfo_a_card : undefined}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่จอง"}
                                                       placeholder={"เลือกวันที่"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       handleSelectDate={handleSelectDate}
                                                       name="created_at"
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่ส่งมอบ"}
                                                       placeholder={"เลือกวันที่"}
                                                       require={false}
                                                       formatDate={"DD/MM/YYYY"}
                                                       handleSelectDate={handleSelectDate}
                                                       name="getcar_date"
                                                  />
                                                  <Button onClick={handleClickSearch && handleClickSearch}>ค้นหา</Button>
                                             </div>
                                        </Accordion.Collapse>
                                   </div>
                              </Accordion>
                         </div>
                    );
               default:
                    return <React.Fragment></React.Fragment>;
          }
     }
}
