import React, { Component } from "react";
import "./loader.scss";

export default class Loader extends Component {
     render() {
          return (
               <div className="loader">
                    <div class="animated-title">
                         <div class="text-top">
                              <div>
                                   <span>
                                        TOYOTA <span> PS</span>
                                   </span>
                                   <span style={{ marginBottom: 20 }}>ENTERPRISE CO.,LTD.</span>
                              </div>
                         </div>
                         <div class="text-bottom">
                              <div>
                                   <p>Loading...</p>
                                   {/* <div className="table">
                                        <div className="cell">
                                             <div className="gmb-loader">
                                                  <div />
                                                  <div />
                                                  <div />
                                             </div>
                                        </div>
                                   </div> */}
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
