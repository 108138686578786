import React from "react";
import NavigationRouter from "../routes";

const App = () => {
     return (
          <React.Fragment>
               <NavigationRouter />
          </React.Fragment>
     );
};

export default App;
