const localhost = "http://localhost:4210";
// const localhost = "https://ps.co.th";
const path = window.location.origin.includes("localhost") ? localhost : window.location.origin;
// export const ip = "http://192.168.31.65:4210/api/v1";
// export const ip = process.env.REACT_APP_API_PATH;
export const ip = `${path}/api/v1`;

function response(json) {
     return json.success ? Promise.resolve(json.result) : Promise.reject(json.message);
}

export const POST = (path, obj, formData) => {
     let token = localStorage.getItem("token");
     return fetch(ip + path, {
          method: "POST",
          headers: formData
               ? { token }
               : {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      token,
                 },
          body: formData ? obj : JSON.stringify(obj),
          credentials: "include",
     })
          .then((res) => res.json())
          .then(response);
};

export const GET = (path) => {
     let token = localStorage.getItem("token");
     return fetch(ip + path, {
          method: "GET",
          headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               token,
          },
          credentials: "include",
     })
          .then((res) => res.json())
          .then(response);
};
