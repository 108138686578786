import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import './sign.scss'

export default class Sign extends Component {
     render() {
          let { type, color, button, onClick, save, clear, title, btn_name } = this.props;

          // const clear = () => this.sigCanvas.clear();
          // const save = () => {
          //     let base64 = this.sigCanvas.toDataURL('image/png')

          //     localStorage.setItem('signBase64', base64)
          //     console.log(localStorage.getItem('signBase64'))

          //     if (onClick) onClick()
          // }

          switch (type) {
               case "default":
                    return (
                         <div>
                              <div
                                   style={{
                                        display: "block",
                                        width: "auto",
                                        height: "200",
                                        border: "1px solid grey",
                                   }}
                              >
                                   <SignatureCanvas
                                        penColor={color}
                                        canvasProps={{ width: "600", height: "200", className: "sigCanvas" }}
                                        ref={(ref) => {
                                             this.sigCanvas = ref;
                                        }}
                                   />
                                   ,<div id="react-container"></div>
                              </div>
                              <div className="button-group __submit row">
                                   <Col xs={6}>
                                        <Button onClick={() => save(this.sigCanvas)}>ตกลง</Button>
                                   </Col>
                                   <Col xs={6}>
                                        <Button onClick={() => clear(this.sigCanvas)} className="btn btn-danger">
                                             Reset
                                        </Button>
                                   </Col>
                              </div>
                         </div>
                    );
               case "default-no-btn":
                    return (
                         <div>
                              <div className={type}>
                                   
                                   <SignatureCanvas
                                        onEnd={() => save(this.sigCanvas)}
                                        penColor={color}
                                        canvasProps={{ width: "600", height: "200", className: "sigCanvas" }}
                                        ref={(ref) => {
                                             this.sigCanvas = ref;
                                        }}
                                   />

                                   <div className='-placeholder'>
                                        <span className='sign-placeholder'>Make your Signature</span>
                                   </div>
                                   <div id="react-container"></div>
                              </div>
                         </div>
                    );
               case "savelabel":
                    return (
                         <div>
                              <div
                                   style={{
                                        width: "100%",
                                        height: "200px",
                                        border: "1px solid grey",
                                   }}
                              >
                                   <SignatureCanvas
                                        penColor={color}
                                        canvasProps={{ className: "sigCanvas" }}
                                        ref={(ref) => {
                                             this.sigCanvas = ref;
                                        }}
                                   />
                                   ,<div id="react-container"></div>
                              </div>
                              <div className="button-group __submit row">
                                   <Col xs={6}>
                                        <Button onClick={() => save(this.sigCanvas)}>Submit</Button>
                                   </Col>
                              </div>
                         </div>
                    );
               default:
                    return <React.Fragment></React.Fragment>;
          }
     }
}
