import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Img, Step } from "components/index";
import { Form, Button, Col, Row, Tabs, Tab, Image } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import { POST, ip } from "api/index";
import { useEffect } from "react";
import axios from "axios";

function ScrollToTopOnMount() {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class DetailAccOrder extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               title: [],
               key: "detail",
               order: {
                    item: [],
               },
               showDetail: false,
               step_status: {
                    a1: false,
                    a2: false,
                    a3: false,
                    a4: false,
                    a5: false,
                    payment: false,
                    install: false,
                    deliver: false,
               },
               doc_active: false,
               modal_reduce: false,
               save_reduce: {},
               modal_print: false,
          };
     }

     componentDidMount = () => {
          this.getOrderDetail();
          this.getLog();
     };

     getOrderDetail = async () => {
          let { id, step_status, doc_active, save_reduce } = this.state;
          try {
               let order = await POST("/order/this_acc_order", { acorder_id: id });
               Object.keys(step_status).forEach((v, i) => {
                    if (order.acept_status === "สั่งซื้อ") {
                         i < 1 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.install = true;
                    } else if (order.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
                         i < 2 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.install = false;
                         step_status.payment = true;
                         doc_active = true;
                    } else if (order.acept_status === "ชำระแล้ว") {
                         i < 3 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.install = false;
                         step_status.payment = false;
                         step_status.deliver = true;
                         doc_active = true;
                    } else if (order.acept_status === "ส่งมอบรถแล้ว") {
                         i < 4 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.payment = false;
                         step_status.install = false;
                         step_status.deliver = false;
                         doc_active = true;
                    } else if (order.acept_status === "ปิดการขาย") {
                         i < 5 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.payment = false;
                         step_status.install = false;
                         step_status.deliver = false;
                         doc_active = true;
                    }
               });
               save_reduce["branch_id"] = order.branch_id;
               save_reduce["accp_id"] = order.accp_id;
               if (order.accReduce.length) {
                    let data = order.accReduce[order.accReduce.length - 1];
                    save_reduce["reduce_price"] = data.reduce_price;
                    save_reduce["reduce_vat"] = data.reduce_vat;
                    save_reduce["reduce_note"] = data.reduce_note;
                    save_reduce["reduce_date"] = data.reduce_date;
                    save_reduce["reduce_time"] = data.reduce_time;
               }
               this.setState({ order: { ...order }, step_status, doc_active, save_reduce });
          } catch (error) {
               console.log(error);
          }
     };

     getLog = async () => {
          let { id } = this.state;
          try {
               let logs = await POST("/ps/this_log", { id_do: id, log_type: "car_order" });
               this.setState({ logs });
               // console.log("logs", this.state.logs);
          } catch (error) {
               console.log("error", error);
          }
     };
     search = (key, arr) => {
          for (var i = 0; i < arr.length; i++) {
               if (arr[i].acitem_id === key) {
                    return true;
               }
               if (arr[i].items_id === key) {
                    return true;
               }
          }
     };
     getLogDetail = async (log_id) => {
          // let { log_id }= this.state
          try {
               let log_info = await POST("/order/log_detail_order", { log_id: log_id });
               let setInfo = log_info.log_detail;
               let item = setInfo?.item;
               if (setInfo.deleteitem) {
                    setInfo.deleteitem.forEach((element) => {
                         if (this.search(element, item)) {
                              item.splice(
                                   item.findIndex((el) => el.acitem_id === element),
                                   1
                              );
                         }
                    });
               }
               if (setInfo.additem) {
                    setInfo.additem.forEach((element) => {
                         if (!this.search(element.items_id, item)) {
                              item.push(element);
                         }
                    });
               }
               this.setState({ log_info: setInfo });
          } catch (error) {
               console.log("error", error);
          }
     };

     /* -------------------------- Set ---------------------------- */
     setKey = (k) => {
          this.setState({ key: k });
     };
     /* ------------------------ Handle --------------------------- */
     handleClick = () => {
          window.location.assign(`/admin/order/acc/manage/edit/${this.state.id}`);
     };

     downloadPdf = async (row) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}${row.file}`,
                         { acorder_id: this.state.id, type: "ต้นฉบับ" },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {}
     };
     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { order } = this.state;
          order[name] = value;
          this.setState({ order });
     };
     showDetailHistory = async (row) => {
          await this.getLogDetail(row.log_id);
          this.setState({ showDetail: true });
     };
     detailFormatter = (cell, row) => {
          return row.log_do === "แก้ไข" ? <Button onClick={() => this.showDetailHistory(row)}>ดูการแก้ไข</Button> : null;
     };

     showButtonBack = () => {
          this.setState({ showDetail: false });
     };

     closeSell = async () => {
          try {
               let result = await POST("/order/acorder_close", { acorder_id: this.state.id });
               swal({
                    icon: "success",
                    title: "ปิดการขายแล้ว",
                    buttons: { Catch: { text: "ตกลง", value: "success" } },
                    closeOnClickOutside: true,
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               console.log("error", error);
          }
     };

     /* ----------------------- Formatter --------------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     totalPrice = (columnData) => {
          let total = columnData.reduce((acc, item) => acc + item, 0);
          // total = parseFloat(total).toFixed(2);
          return Number(total).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
     };

     parseFloatFormatter = (cell, row) => {
          let new_cell = Number(cell).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     };

     unitFormatter = () => {
          return <p>บาท</p>;
     };

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <p>{rowIndex + 1}</p>;
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          console.log("row", row);
          if (row.active) {
               if (row.title === "ใบลดหนี้ค่าอุปกรณ์") {
                    return <a onClick={() => this.setState({ modal_reduce: true })}>{"กรอกข้อมูล"}</a>;
               } else {
                    return <div style={{ color: "#000" }}>{dateFormatter(cell)}</div>;
               }
          } else {
               return "-";
          }
     };

     printFormatter = (cell, row) => {
          if (row.active) {
               if (row.title === "ใบลดหนี้ค่าอุปกรณ์") {
                    return <i className="fas fa-print icon __btn active" onClick={() => this.setState({ modal_print: true })}></i>;
               } else {
                    return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdf(row)}></i>;
               }
          } else {
               return <i className="fas fa-print icon"></i>;
          }
     };

     docIconFormatter = (cell, row) => {
          if (row.active) {
               return (
                    <div style={{ color: "#000" }}>
                         <Image className="icon" src={Img.files_active} />
                         {cell}
                    </div>
               );
          } else {
               return (
                    <>
                         <Image className="icon" src={Img.files} />
                         {cell}
                    </>
               );
          }
     };
     modalReduce = () => {
          let { save_reduce } = this.state;
          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_price"]}
                                   type="number"
                                   title="จำนวนลดหนี้"
                                   placeholder="0.00"
                                   handleChangeNumber={(e) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_price"] = e.floatValue;
                                        save_reduce["reduce_vat"] = e.floatValue * 0.07;
                                        this.setState({ save_reduce });
                                   }}
                                   decimalScale={2}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_vat"]}
                                   type="number"
                                   title="VAT"
                                   placeholder="0.00"
                                   handleChangeNumber={(e) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_vat"] = e.floatValue;
                                        this.setState({ save_reduce });
                                   }}
                                   decimalScale={2}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_note"]}
                                   type="text"
                                   title="สาเหตุ"
                                   placeholder="กรอกสาเหตุการลดหนี้"
                                   handleChangeText={(el) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_note"] = el.target.value;
                                        this.setState({ save_reduce });
                                   }}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   type="date"
                                   title="วันที่"
                                   placeholder={save_reduce["reduce_date"] ? dayjs(save_reduce["reduce_date"]).format("YYYY-MM-DD") : "เลือกวันที่"}
                                   handleSelectDate={(date) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_date"] = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                                        this.setState({ save_reduce });
                                   }}
                                   formatDate="YYYY-MM-DD"
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_time"]}
                                   type="number"
                                   title="ครั้งที่"
                                   placeholder="0"
                                   handleChangeNumber={(e) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_time"] = e.floatValue;
                                        this.setState({ save_reduce });
                                   }}
                              />
                         </Col>
                    </Row>
               </div>
          );
     };
     saveReduce = async () => {
          let { save_reduce } = this.state;
          if (save_reduce.reduce_price && save_reduce.reduce_vat && save_reduce.reduce_note && save_reduce.reduce_date && save_reduce.reduce_time) {
               try {
                    await POST("/order/save_reduce_acc", save_reduce);
                    // console.log(result);
                    this.setState({ modal_reduce: false });
                    swal({
                         icon: "success",
                         text: "ทำรายการสำเร็จ",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then(() => {
                         this.getOrderDetail();
                    });
               } catch (err) {
                    this.setState({ modal_reduce: false });
                    swal({
                         icon: "warning",
                         text: err,
                         buttons: {
                              submit: "ลองใหม่อีกครั้ง",
                         },
                    }).then(() => {
                         this.getOrderDetail();
                    });
                    console.log("error : ", err);
               }
          } else {
               swal({
                    icon: "warning",
                    text: "กรอกข้อมูลให้ครบทุกช่อง",
                    buttons: {
                         submit: "ลองใหม่อีกครั้ง",
                    },
               });
          }
     };
     modalReducePrint = () => {
          let arr_col_com = [
               {
                    dataField: "reduce_code",
                    text: "เลขที่ CT",
                    formatter: (e, r) => (
                         <i className="fas fa-print icon __btn active" onClick={() => this.printAccReduce(r)}>
                              {e}
                         </i>
                    ),
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_date",
                    text: "ประจำวันที่",
                    formatter: (e) => dayjs(e).format("DD/MM/") + (dayjs(e).year() + 543),
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_time",
                    text: "งวดที่",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_sum",
                    text: "จำนวนลดหนี้ (รวมภาษี)",
                    formatter: (e) =>
                         Number(e).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                    headerClasses: "header-custom __btn-m",
               },
          ];
          return (
               <div style={{ overflow: "auto" }}>
                    <Table type="document" column={arr_col_com} data={this.state.order.accReduce} keyField="comfinace_code" />
               </div>
          );
     };
     printAccReduce = async (e) => {
          let api = "reduce_acc_pdf";
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/${api}`,
                         { branch_id: this.state.order.branch_id, id: e.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               console.log("error", error);
          }
     };
     render() {
          let { id, key, order, logs, showDetail, log_info, step_status, doc_active } = this.state;
          // const Document = () => {
          const doc = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการเอกสาร",
                         formatter: this.docIconFormatter,
                         headerClasses: "header-custom __left __btn-ml",
                    },
                    {
                         dataField: "create_date",
                         text: "วันที่ออกเอกสาร",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "doc",
                         text: "พิมพ์",
                         formatter: this.printFormatter,
                         headerClasses: "header-custom __right __icon",
                    },
               ],
               data: [
                    {
                         file: "/order/acc_order_pdf",
                         title: "ดูใบสั่งขายอุปกรณ์",
                         create_date: order?.created_at,
                         active: true,
                    },
                    {
                         file: "/order/acc_order_install_pdf",
                         title: "ใบสั่งติดตั้งอุปกรณ์แยก",
                         create_date: order?.created_at,
                         active: true,
                    },
                    {
                         file: "/order/acc_order_payment",
                         title: "ใบกำกับภาษีอุปกรณ์",
                         create_date: order?.acc_order_payment_updated,
                         active: doc_active,
                    },
                    {
                         file: "/order/reduce_acc_pdf",
                         title: "ใบลดหนี้ค่าอุปกรณ์",
                         create_date: "-",
                         active: order?.acc_order_payment_code,
                    },
               ],
               keyField: "no",
          };
          //      return (
          //           <div className="input-field __tab-body">
          //                <div className="-body">
          //                     <div className="content">
          //                          <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
          //                     </div>
          //                </div>
          //           </div>
          //      );
          // };

          const Detail = ({ order, acorder_code_id, closeSell }) => {
               const conf_acc = {
                    column: [
                         {
                              dataField: "items_id",
                              text: "ลำดับ",
                              footer: "",
                              formatter: this.noFormatter,
                              align: "center",
                              footerClasses: "summary-custom",
                              headerClasses: "header-custom __left __no",
                         },
                         {
                              dataField: "items_code",
                              text: "รหัสสินค้า",
                              footer: "",
                              footerClasses: "summary-custom",
                         },
                         {
                              dataField: "items_name",
                              text: "รายการของแถม",
                              footer: "รวม",
                              footerClasses: "summary-custom",
                         },
                         {
                              dataField: "items_price",
                              text: "ราคาขาย",
                              footer: this.totalPrice,
                              headerClasses: "header-custom __btn",
                              formatter: this.parseFloatFormatter,
                              footerClasses: "summary-custom __number-blue",
                         },
                         {
                              dataField: "unit",
                              text: "หน่วย",
                              footer: "บาท",
                              formatter: this.unitFormatter,
                              footerClasses: "summary-custom",
                              headerClasses: "header-custom __right  __btn",
                         },
                    ],
                    data: order?.item ? order?.item : [],
                    keyField: "items_id",
               };
               return (
                    <>
                         <div className="input-field __tab-body">
                              <div className="-body">
                                   <div className="content">
                                        {/* ข้อมูลใบสั่งขายอุปกรณ์ */}
                                        <>
                                             <LabelBar type={"title"} title={"ข้อมูลใบสั่งขายอุปกรณ์"} />
                                             <div className="-padding">
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขที่ใบสั่งขายอุปกรณ์ : ",
                                                                 detail: order?.acorder_code_id || acorder_code_id,
                                                            }}
                                                            md={6}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "วันที่ออก :",
                                                                 detail: order?.created_at ? dateFormatter(order?.created_at) : "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "โชว์รูม : ",
                                                                 detail: order?.branch_name || "-",
                                                            }}
                                                            md={6}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "พนักงานขาย :",
                                                                 detail: order?.saler_name_string || "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                             </div>
                                        </>
                                        {/* รายละเอียดผู้ซื้อ */}
                                        <>
                                             <LabelBar type={"title"} title={"รายละเอียดผู้ซื้อ"} />
                                             <div className="-padding">
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "A-Card No. : ",
                                                                 detail: order?.acorder_acard ? order?.acorder_acard : "-",
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ชื่อ-นามสกุล ผู้สั่งจอง :",
                                                                 detail: order?.acorder_name,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "หมายเลขประจำตัว :",
                                                                 detail: order?.acorder_idcard,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "หมายเลขโทรศัพท์ :",
                                                                 detail: order?.acorder_tel ? this.telFormat(order?.acorder_tel) : "-",
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ที่อยู่ :",
                                                                 detail: `${order?.acorder_address}  ตำบล/แขวง ${order?.acorder_sub_district} อำเภอ/เขต ${order?.acorder_district} จังหวัด ${order?.acorder_province} ${order?.acorder_zipcode}`,
                                                            }}
                                                            md={12}
                                                       />
                                                  </Row>
                                             </div>
                                        </>
                                        {/* รายละเอียดรุ่นรถยนต์ */}
                                        <>
                                             <LabelBar type={"title"} title={"รายละเอียดรุ่นรถยนต์"} />
                                             <div className="-padding">
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "Serie : ",
                                                                 detail: order?.series_name,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "Model : ",
                                                                 detail: order?.model_name,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "สี : ",
                                                                 detail: order.color_code ? `${order.color_name} (${order.color_code})` : "-",
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขเครื่องยนต์ : ",
                                                                 detail: order?.engine,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขตัวถัง : ",
                                                                 detail: order?.body_no,
                                                            }}
                                                            md={12}
                                                       />
                                                  </Row>
                                             </div>
                                        </>
                                        {/* Car Accessories */}
                                        <>
                                             <LabelBar type={"title"} title={"Car Accessories ที่ลูกค้าจะซื้อ"} />
                                             <div className="index __field">
                                                  <Table
                                                       type={"summary"}
                                                       column={conf_acc.column}
                                                       data={conf_acc.data}
                                                       keyField={conf_acc.keyField}
                                                  />
                                             </div>
                                        </>
                                        {/* หมายเหตุ */}
                                        <>
                                             <LabelBar type={"title"} title={"หมายเหตุ"} />
                                             <div className="-padding">
                                                  <div className="-padding">
                                                       <Row>
                                                            <span>{order?.acorder_note ? order?.acorder_note : "-"}</span>
                                                       </Row>
                                                  </div>
                                                  <div style={{ marginBottom: "50px" }}></div>
                                             </div>
                                        </>
                                        <>
                                             {order?.acept_status == "ส่งมอบรถแล้ว" && (
                                                  <Row>
                                                       <Col md={5}></Col>
                                                       <Col>
                                                            <Button onClick={closeSell}>ปิดการขาย</Button>
                                                       </Col>
                                                       <Col md={5}></Col>
                                                  </Row>
                                             )}
                                        </>
                                   </div>
                              </div>
                         </div>
                    </>
               );
          };

          const History = ({ logs }) => {
               const history = {
                    column: [
                         {
                              dataField: "log_do",
                              text: "การดำเนินการ",
                              headerClasses: "header-custom __left __btn-s",
                         },
                         {
                              dataField: "create_date",
                              text: "วันที่ดำเนินการ",
                              formatter: this.dateFormatter,
                              headerClasses: "header-custom __left __btn-s",
                         },
                         {
                              dataField: "nameuser_update",
                              text: "ผู้ดำเนินการ",
                              headerClasses: "header-custom __left __btn-ml",
                         },
                         {
                              dataField: "history",
                              text: "ดูการแก้ไข",
                              formatter: this.detailFormatter,
                              headerClasses: "header-custom __right __btn-ss",
                         },
                    ],
                    data: logs ? logs : [],
                    keyField: "no",
               };
               return (
                    <div className="input-field __tab-body">
                         <div className="-body">
                              <div className="content" id="table-content">
                                   <div className="-padding">
                                        <Table type="document" column={history.column} data={history.data} keyField={history.keyField} />
                                   </div>
                              </div>
                         </div>
                    </div>
               );
          };

          const Cancel = (props) => {
               let { handleChangeText } = props;
               return (
                    <div className="input-field __tab-body">
                         <div className="-body">
                              <div className="content">
                                   <>
                                        <Field
                                             type={"text"}
                                             title={"หมายเหตุ"}
                                             name={"note"}
                                             as={"textarea"}
                                             textarea_rows={5}
                                             md={12}
                                             handleChangeText={handleChangeText}
                                        />
                                        <Col md={{ span: "3", offset: "9" }}>
                                             <Button variant={"danger"}>ยกเลิกใบสั่งขาย</Button>
                                        </Col>
                                   </>
                              </div>
                         </div>
                    </div>
               );
          };

          let step = [
               {
                    title: "สั่งซื้อ",
                    active: step_status.a1,
               },
               {
                    title: "ติดตั้ง",
                    active: step_status.a2,
                    activeBtn: step_status.install,
                    btn: () => window.location.assign(`/admin/install/list/acc/${order?.acorder_id}`),
               },
               {
                    title: "ชำระเงินแล้ว",
                    active: step_status.a3,
                    activeBtn: step_status.payment,
                    btn: () => window.location.assign(`/admin/payment/product/acc/${order?.acorder_id}`),
               },

               {
                    title: "ส่งมอบ",
                    active: step_status.a4,
                    activeBtn: step_status.deliver,
                    btn: () => window.location.assign(`/admin/deliver/car/acc/${order?.acorder_id}`),
               },
               {
                    title: "ปิดการขาย",
                    active: step_status.a5,
                    //  activeBtn: step_status.submit_info,
                    //  btn: () => window.location.assign(`/admin/order/leasing/${id}`),
               },
          ];

          return (
               <div className="panel">
                    <ScrollToTopOnMount />
                    <Breadcrumbs
                         title={[
                              {
                                   title: "การขายอุปกรณ์",
                                   onClick: () => window.location.assign("/admin/order/acc"),
                              },
                              {
                                   title: `ใบสั่งขายอุปกรณ์ (${order?.acorder_code_id})`,
                                   onClick: () => window.location.assign(`/admin/order/acc/detail${id}`),
                              },
                         ]}
                         active={1}
                         button={[
                              {
                                   icon: "fas fa-pen-square -icon",
                                   name: "แก้ไขข้อมูล",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"สถานะการขาย"} />
                                        <Step step={step} />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <ModalPanel
                         show={this.state.modal_reduce}
                         title={"กรอกลดหนี้ค่าอุปกรณ์"}
                         type={"input"}
                         body={this.modalReduce()}
                         onHide={() => this.setState({ modal_reduce: false })}
                         button={[{ onClick: this.saveReduce, name: "Submit", variant: "primary" }]}
                    />
                    <ModalPanel
                         show={this.state.modal_print}
                         title={"พิมพ์เอกสารใบลดหนี้ค่าอุปกรณ์"}
                         type={"input"}
                         body={this.modalReducePrint()}
                         onHide={() => this.setState({ modal_print: false })}
                         button={[]}
                         size="xl"
                    />
                    <div className="content __input-panel" style={{ marginTop: "-6em" }}>
                         <Tabs activeKey={key} onSelect={(k) => this.setKey(k)}>
                              <Tab eventKey="detail" title="ข้อมูลการขาย">
                                   <Detail order={order} closeSell={this.closeSell} />
                              </Tab>
                              <Tab eventKey="document" title="เอกสารการขาย">
                                   <div className="input-field __tab-body">
                                        <div className="-body">
                                             <div className="content" id="table-content">
                                                  <div className="-padding">
                                                       <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   {/* <Document /> */}
                              </Tab>
                              <Tab eventKey="history" title="ประวิตการแก้ไข">
                                   {!showDetail ? <History logs={logs} detailFormatter={this.detailFormatter} /> : null}
                                   {showDetail ? (
                                        <a>
                                             <Button style={{ marginTop: 10, marginBottom: 10 }} onClick={this.showButtonBack}>
                                                  กลับ
                                             </Button>
                                             <Detail order={log_info} acorder_code_id={order?.acorder_code_id} />
                                        </a>
                                   ) : null}
                              </Tab>
                              <Tab eventKey="cancel" title="ยกเลิกใบสั่งขาย">
                                   <Cancel handleChangeText={this.handleChangeText} />
                              </Tab>
                         </Tabs>
                    </div>
               </div>
          );
     }
}
