import { Button, Row, Col } from "react-bootstrap";
import { Breadcrumbs, LabelBar, Table, Field } from "components/index";
import React, { Component } from "react";
import dayjs from "dayjs";
import { POST, ip } from "api";
import axios from "axios";

export default class GenerateListLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               // empty_status: this.props.match.params.empty_status,
               label_id: this.props.match.params.registra_id,
               order: {
                    labelhis: [],
               },
          };
     }

     componentDidMount = () => {
          this.getDetailLabel();
     };

     getDetailLabel = async () => {
          let { label_id } = this.state;
          try {
               let order = await POST("/car/this_label", { label_id });
               this.setState({ order: { ...order } });
               // console.log("order", this.state.order);
          } catch (err) {
               console.log(err);
          }
     };

     handleClick = () => {
          let { registra_id, empty_status, label_id } = this.state;
          window.location.assign(`/admin/label/list/manage/edit/${label_id}`);
     };

     onClickLendLabel = () => {
          let { registra_id, label_id } = this.state;
          console.log(label_id);
          window.location.assign(`/admin/label/lend/${label_id}`);
     };

     onClickCancelLabel = async () => {
          try {
               await POST("/car/cancel_brrow", { label_id: this.state.order.label_id });
               // console.log(result);
               window.location.reload();
          } catch (err) {
               console.log(err);
          }
     };

     /* ------------ formatter ------------- */
     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          // if (row.empty_status === 'ว่าง') {
          //     return (
          //         <p>-</p>
          //     )
          // } else {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <span>-</span>;
          }
          // }
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { order } = this.state;
          switch (formatExtraData) {
               case "preemtion_id":
                    if (!row.order_code_id) {
                         return <div>{"-"}</div>;
                    } else {
                         return (
                              <a
                                   onClick={() => {
                                        window.location.assign(`/admin/order/car/detail${row.order_id}`);
                                   }}
                              >
                                   {cell}
                              </a>
                         );
                    }
               case "slip":
                    return <a onClick={() => this.downloadPdf(row)}>{"ใบเสร็จรับเงิน"}</a>;
               case "customer_detail":
                    return (
                         <a
                              onClick={() => {
                                   window.location.assign(`/admin/label/list/${row.labelhis_id}/detail`);
                              }}
                         >
                              {"ดูข้อมูล"}
                         </a>
                    );
               case "refund":
                    if (row.status === "คืนป้ายแล้ว") {
                         return <div style={{ color: "green" }}>{cell}</div>;
                    } else if (row.status === "รับแล้ว") {
                         return (
                              <a
                                   onClick={() => {
                                        window.location.assign(`/admin/label/list/${row.labelhis_id}/refund`);
                                   }}
                              >
                                   {"คืนป้าย"}
                              </a>
                         );
                    } else if (row.status === "ยกเลิกยืม") {
                         return <div style={{ color: "red" }}>{"ยกเลิกยืม"}</div>;
                    } else {
                         // console.log(`row`, row)
                         if (row.receipt_code) {
                              return (
                                   <a
                                        onClick={() => {
                                             window.location.assign(`/admin/label/list/${row.labelhis_id}/give`);
                                        }}
                                   >
                                        {"ให้ป้าย"}
                                   </a>
                              );
                         } else {
                              return <div style={{ color: "blue" }}>{"รอชำระเงิน"}</div>;
                         }
                    }
          }
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <Field type={"text"} name={row.deposit} placeholder={"0.00"} require={true} value={null} />;
     };

     parseFloatFormatter = (cell, row) => {
          // let new_cell = parseFloat(cell).toFixed(2);
          let new_cell = Number(cell).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     };

     downloadPdf = async (row) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}${"/order/tempory_label_pdf"}`,
                         { order_id: row.order_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {}
     };

     render() {
          let { registra_id, empty_status, order } = this.state;
          let table = {
               column: [
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preemtion_id",
                         headerClasses: "header-custom __left __btn-s",
                    },
                    {
                         dataField: "status",
                         text: "สถานะ",
                         formatter: this.detailFormatter,
                         formatExtraData: "refund",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "label_brrow_date",
                         text: "วันที่เบิกยืม",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "labelhis_open_date",
                         text: "วันที่เบิกป้าย",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "labelhis_name",
                         text: "ชื่อผู้ยืม",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "labelhis_price",
                         text: "เงินมัดจำ (บาท)",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "label_return_date",
                         text: "วันคืนป้าย",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    // {
                    //      dataField: "slip",
                    //      text: "ใบเสร็จรับเงิน",
                    //      formatter: this.detailFormatter,
                    //      formatExtraData: "slip",
                    // },
                    {
                         dataField: "customer_detail",
                         text: "ข้อมูลลูกค้า",
                         formatter: this.detailFormatter,
                         formatExtraData: "customer_detail",
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: order?.labelhis.sort((a, z) => z.labelhis_id - a.labelhis_id),
               keyField: "test",
          };
          // console.log(`order?.labelhis`, order?.labelhis);
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รายการป้ายแดง`,
                                   onClick: () => window.location.assign("/admin/label/list"),
                              },
                              { title: `ป้ายแดง (${order?.label_no})` },
                         ]}
                         active={1}
                         button={[
                              {
                                   icon: "far fa-edit -icon",
                                   name: "แก้ไขข้อมูล",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ป้ายแดง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขทะเบียน : ",
                                                            detail: order?.label_no,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "จังหวัด : ",
                                                            detail: order?.label_province,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบอนุญาต : ",
                                                            detail: order?.label_permit,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่เล่ม : ",
                                                            detail: order.label_book ? order.label_book : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สาขา : ",
                                                            detail: order?.branch_name,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ว่าง/ไม่ว่าง : ",
                                                            detail: order?.free,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ซื้อป้ายแดง : ",
                                                            detail: order.label_buy_date ? this.dateFormatter(order?.label_buy_date) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สถานะ : ",
                                                            detail: order?.status,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"ประวัติการยืมป้าย"} />
                                        <div className="-padding">
                                             {order?.free === "ว่าง" ? (
                                                  <Row>
                                                       <Col xl={2} md={4}>
                                                            <Button onClick={this.onClickLendLabel}>ยืมป้าย</Button>
                                                       </Col>
                                                  </Row>
                                             ) : (
                                                  <Row>
                                                       {table.data[0] && !table.data[0].receipt_code && (
                                                            <Col xl={2} md={4}>
                                                                 <Button onClick={this.onClickCancelLabel} variant="danger">
                                                                      ยกเลิกยืมป้าย
                                                                 </Button>
                                                            </Col>
                                                       )}
                                                  </Row>
                                             )}
                                        </div>
                                   </div>
                                   <div className="content" id="table-content">
                                        <div className="-padding">
                                             <Table
                                                  type={"summary-custom-padding"}
                                                  column={table.column}
                                                  data={table.data}
                                                  keyField={table.keyField}
                                             />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
