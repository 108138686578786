import { POST, GET } from "api";
import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { getToken } from "../../../../../static/function";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";

export default class ItemReport extends Component {
     constructor(props) {
          super(props);

          this.state = {
               title: "",
               show: false,
               items: { branch: [] },
               supplier: [],
               branch: [],
               error_data: "",
               loading: false,
          };
     }
     /*------------ api ------------------ */

     componentDidMount = async () => {
          try {
               let sup = await POST("/aos/get_sup_by", { name: "supplier_type", by: "อุปกรณ์ตกแต่ง (Out Source Supplier)" });
               let branch = await GET("/aos/get_branch");
               this.setState({
                    supplier: sup.map((el) => ({ value: el.supplier_id, name: el.supplier_name })),
                    branch: branch,
                    items: { branch: branch.map((el) => el.branch_id) },
               });
          } catch (error) {}
     };

     /*------------ onClick ------------------ */

     showModal = (title, id) => {
          this.setState({
               title: title,
               show: !this.state.show,
               id,
          });
     };

     closeReport = async (item) => {
          this.setState({ loading: true });
          try {
               // let user = getToken();
               let { id } = this.state;
               let { date_start, date_end, supplier_id, branch } = this.state.items;
               if (date_start && date_end && branch.length) {
                    let dataBranch = branch.map((el) => ({
                         branch_name: this.state.branch.find((e) => e.branch_id == el).branch_name,
                         branch_id: this.state.branch.find((e) => e.branch_id == el).branch_id,
                    }));
                    let res = null;
                    if (Number(id) === 1) {
                         res = await POST("/aos/report_acc_one", {
                              ...this.state.items,
                              branch_arr: dataBranch,
                              sendMail: false,
                         });
                    }
                    if (Number(id) === 2) {
                         res = await POST("/aos/report_acc_two", {
                              ...this.state.items,
                              branch_arr: dataBranch,
                              sendMail: false,
                         });
                    }
                    if (Number(id) === 3) {
                         res = await POST("/aos/report_acc_three", {
                              ...this.state.items,
                              branch_arr: dataBranch,
                              sendMail: false,
                         });
                    }
                    if (Number(id) === 4) {
                         res = await POST("/ps/report_excel_accessories", {
                              ...this.state.items,
                              branch_arr: dataBranch,
                              sendMail: false,
                              type_excel: "รายการขายอุปกรณ์ทั้งหมด",
                         });
                    }
                    if (res) {
                         window.open(res);
                    }
                    this.setState({ show: !this.state.show, error_data: "", loading: false });
               } else this.setState({ error_data: "*กรอกข้อมูลให้ครบ*", loading: false });
          } catch (err) {
               // swal(err, "", "warning");
               this.setState({ error_data: err, loading: false });
          }
     };

     /* ---------- formatter ----------------- */

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.showModal(row.report, row.id)}>ทำรายการ</a>;
     };

     handleChangeText = ({ target: { name, value, checked } }) => {
          let { items } = this.state;
          if (name === "branch") {
               // console.log(name + " " + value, checked);
               if (checked) {
                    items[name].push(Number(value));
                    items[name] = items[name].sort((a, z) => a - z);
                    this.setState({ items });
               } else {
                    let index = items[name].findIndex((el) => el === Number(value));
                    items[name].splice(index, 1);
                    items[name] = items[name].sort((a, z) => a - z);
                    this.setState({ items });
               }
          } else {
               items[name] = value;
               this.setState({ items });
          }
     };

     handleChangeDate = (date, dateString, name) => {
          let { items } = this.state;
          items[name] = dayjs(dateString).format("YYYY-MM-DD HH:mm:ss");
          this.setState({ items });
     };

     handleCloseModal = () => {
          let { items } = this.state;
          this.setState({
               show: false,
               // items: { ...items, branch: [] },
               error_data: "",
          });
     };

     // reportFormatter = (cell, row, rowIndex, formatExtraData) => {
     //      if (row.report === "รายงานใบเสร็จรับเงิน") {
     //           return (
     //                <div>
     //                     <span>{cell}</span>
     //                     <span style={{ color: "red", marginLeft: "40px" }}>ไม่มีรูปแบบรายงาน</span>
     //                </div>
     //           );
     //      } else {
     //           return <span>{cell}</span>;
     //      }
     // };

     render() {
          let { title, show, items, supplier, branch, error_data } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         formatter: this.reportFormatter,
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "list",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    { report: "รายงานใบจองที่ส่งมอบแล้วพร้อมรายการ Accessories", id: 1 },
                    { report: "รายงานการขายอุปกรณ์แยกที่ส่งมอบแล้วพร้อม Accessories", id: 2 },
                    { report: "รายการขายอุปกรณ์ที่ยังไม่มี TS", id: 3 },
                    { report: "รายการขายอุปกรณ์ทั้งหมด", id: 4 },
                    // { report: "รายการขายอุปกรณ์ที่ส่งมอบแล้วเพื่อทำจ่าย Supplier" },
               ],
               keyField: "on",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานอุปกรณ์" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                                   <Modal show={show} centered size="lg" onHide={this.handleCloseModal}>
                                        <Modal.Header>
                                             <Modal.Title className="text-center">
                                                  <strong>{title}</strong>
                                                  {error_data && (
                                                       <p className="text-center">
                                                            <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                                       </p>
                                                  )}
                                             </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                             {/* <Field
                                                  type="select"
                                                  title={"Suppier"}
                                                  name={"supplier_id"}
                                                  value={items?.supplier_id}
                                                  option={supplier}
                                                  handleChangeText={this.handleChangeText}
                                             /> */}
                                             <Row>
                                                  <Field
                                                       type={"date"}
                                                       placeholder={items.date_start ? dayjs(items.date_start).format("YYYY-MM-DD") : "เลือกวันที่"}
                                                       title={"ประจำวันที่"}
                                                       name={"date_start"}
                                                       md={6}
                                                       handleSelectDate={this.handleChangeDate}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       placeholder={items.date_end ? dayjs(items.date_end).format("YYYY-MM-DD") : "เลือกวันที่"}
                                                       title={"ถึงวันที่"}
                                                       name={"date_end"}
                                                       md={6}
                                                       handleSelectDate={this.handleChangeDate}
                                                  />
                                             </Row>
                                             {/* <LabelBar
                                                  type={"radio"}
                                                  title={"สาขา"}
                                                  name={"branch_name"}
                                                  md={12}
                                                  label={branch}
                                                  value={items?.branch_name}
                                                  handleChangeText={this.handleChangeText}
                                             /> */}
                                             <LabelBar
                                                  type={"checkbox"}
                                                  title={"สาขา"}
                                                  name={"branch"}
                                                  md={12}
                                                  label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                  // value={pay?.branch}
                                                  arrChecked={items?.branch}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       {this.state.loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button onClick={() => this.closeReport()}>ดาวน์โหลด</Button>
                                                       )}
                                                  </Col>
                                                  {/* <Col lg={6}>
                                                       <Button onClick={() => this.closeReport(0)} style={{ background: "lightblue", color: "blue" }}>
                                                            ส่งเมลล์
                                                       </Button>
                                                  </Col> */}
                                             </Row>
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
