import React, { Component } from "react";
import { Breadcrumbs, Table, Skeleton, Search } from "../../../../../../components";
import { GET } from "api/index";
import dayjs from "dayjs";
import { getToken } from "../../../../../../static/function";

export default class AccPayment extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {
                    accp_status: "รอชำระเงิน",
               },
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getAccPayment();
     };
     getAccPayment = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/acc_order");
               // console.log("res", res);
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search.filter((el) => el.accp_status === "รอชำระเงิน");
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.accp_status ? (search.accp_status === "ทั้งหมด" ? el : String(el.accp_status) === String(search.accp_status)) : el)
               )
               .filter(
                    (el) =>
                         (search.acorder_code_id ? String(el.acorder_code_id).indexOf(String(search.acorder_code_id)) > -1 : el) &&
                         (search.saler_name ? String(el.saler_name).indexOf(String(search.saler_name)) > -1 : el) &&
                         (search.acorder_name ? String(el.acorder_name).indexOf(String(search.acorder_name)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          // return <a onClick={() => window.location.assign(`/admin/payment/product/acc/${row.accp_status}/${row.acorder_id}`)}>ทำรายการ</a>;
          return <a onClick={() => window.location.assign(`/admin/payment/product/acc/${row.acorder_id}`)}>ทำรายการ</a>;
     }

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     };

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/acc/detail${row.acorder_id}`)}>{cell}</a>;
     };

     render() {
          let { order, newSearch, search } = this.state;
          const table = {
               column: [
                    {
                         dataField: "list_accpayment",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "acorder_code_id",
                         text: "เลขที่ใบสั่งขายอุปกรณ์",
                         headerClasses: "header-custom __btn-m",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่ซื้อ",
                         headerClasses: "header-custom __btn-ss",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "acorder_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "accp_status",
                         text: "การชำระเงิน",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "acorder_id",
          };

          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "รับชำระเงินค่าอุปกรณ์" }]} active={0} button={""} />
                    <div className="content __main-panel paymentacc">
                         <Search
                              type={"accpayment"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: {
                                        accp_status: search.accp_status,
                                   },
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
