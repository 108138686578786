import { Breadcrumbs, Field, LabelBar, Step, Table } from "components";
import React, { Component } from "react";
import { POST, ip } from "api/index";
import axios from "axios";
import { Col, Row, Button } from "react-bootstrap";
import dayjs from "dayjs";
import InputPanel from "components/input-panel/input-panel";
import swal from "sweetalert";
import { getToken } from "../../../../../static/function";
function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}
export default class GenerateOrderLeasing extends Component {
     constructor(props) {
          super(props);
          this.state = {
               preemtion_id: this.props.match.params.id,
               fileURL: "",
               step_active: {
                    active1: true,
                    active2: false,
                    active3: false,
                    active4: false,
                    active5: false,
               },
               obj: {},
               order: {},
               step_order: {
                    order_id: this.props.match.params.id,
               },
               files: new FormData(),
               detail: {},
          };
     }

     componentDidMount = () => {
          this.getOrder();
          this.getOrderLeasingPdf();
     };

     getOrder = async () => {
          try {
               this.setState({ detail: await POST("/order/this", { order_id: this.state.preemtion_id }) });
               let result = await POST("/order/this_acept_leasing", {
                    order_id: this.state.preemtion_id,
               });

               // console.log(result);
               let step_active = {
                    active1: true,
                    active2:
                         result.leasing_open_credit_status === "เจ้าหน้าที่รับเรื่อง" ||
                         result.leasing_open_credit_status === "นัดหมายเซ็นสัญญาขอกู้" ||
                         result.leasing_open_credit_status === "ลูกค้าเซ็นสัญญา" ||
                         result.leasing_open_credit_status === "สินเชื่ออนุมัติ"
                              ? true
                              : false,
                    active3:
                         result.leasing_open_credit_status === "นัดหมายเซ็นสัญญาขอกู้" ||
                         result.leasing_open_credit_status === "ลูกค้าเซ็นสัญญา" ||
                         result.leasing_open_credit_status === "สินเชื่ออนุมัติ"
                              ? true
                              : false,
                    active4:
                         result.leasing_open_credit_status === "ลูกค้าเซ็นสัญญา" || result.leasing_open_credit_status === "สินเชื่ออนุมัติ"
                              ? true
                              : false,
                    active5: result.leasing_open_credit_status === "สินเชื่ออนุมัติ" ? true : false,
               };
               this.setState({
                    step_active,
                    order: { ...result },
               });
               // console.log("active", this.state.step_active);
          } catch (error) {
               console.log(error);
          }
     };

     getOrderLeasingPdf = async () => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/order_leadsing_pdf`,
                         { order_id: this.state.preemtion_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              fileURL: fileURL,
                              // spinner: false,
                              // show_modal_pdf: true
                         });
                    });
          } catch (error) {}
     };
     download = async () => {
          let { fileURL, preemtion_id } = this.state;
          const data = fileURL;

          const link = document.createElement("a");
          link.href = data;
          link.download = `ใบรับจองรถยนต์ ${preemtion_id}`;

          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
               new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
               })
          );
     };

     getDateInCol = (date, dateString, name) => {
          let { obj } = this.state;
          obj["date"] = dateString;
          this.setState({ ...obj });
          // console.log(obj);
     };

     /* ------------ onClick ---------------- */

     handleClickPreviewFile = (file) => {
          window.open(file);
     };

     stepActive = async (e) => {
          let { step_active, step_order, obj, files, order } = this.state;

          if (step_active.active1 && !step_active.active2) {
               step_order.status = "รับเรื่องแล้ว";
               step_order.leasing_open_credit_status = "เจ้าหน้าที่รับเรื่อง";
               step_order.do_credit_date = obj.current_date;
               try {
                    await POST("/order/update_acept_leasing", step_order);
                    step_active[e.target.name] = !step_active[e.target.name];
                    this.setState({ order: await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id }) });
               } catch (err) {
                    console.log(err);
               }
          } else if (step_active.active2 && !step_active.active3) {
               let date = new Date();
               let current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
               delete step_order.do_credit_date;
               step_order.status = "ยืนยันวันนัดหมาย";
               step_order.leasing_open_credit_status = "นัดหมายเซ็นสัญญาขอกู้";
               step_order.credit_loan_date = obj.date ? obj.date : current_date;

               try {
                    await POST("/order/update_acept_leasing", step_order);
                    step_active[e.target.name] = !step_active[e.target.name];
                    this.setState({ order: await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id }) });
               } catch (err) {
                    console.log(err);
               }
          } else if (step_active.active3 && !step_active.active4) {
               delete step_order.credit_loan_date;
               step_order.status = "ยืนยันการเซ็นสัญญา";
               step_order.leasing_open_credit_status = "ลูกค้าเซ็นสัญญา";

               try {
                    await POST("/order/update_acept_leasing", step_order);
                    step_active[e.target.name] = !step_active[e.target.name];
                    this.setState({ order: await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id }) });
               } catch (err) {
                    console.log(err);
               }
          } else if (step_active.active4) {
               delete step_order.credit_loan_date;
               step_order.status = "ยืนยันผลการอนุมัติ";
               step_order.leasing_open_credit_status = "สินเชื่ออนุมัติ";

               try {
                    let file = files.get("file");
                    // if (file) {
                    if (file) {
                         await POST("/order/update_file_acept_leasing", files, true);
                         files = new FormData(); //clear form data
                    }
                    await POST("/order/update_acept_leasing", step_order);
                    step_active[e.target.name] = !step_active[e.target.name];
                    let result_order = await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id });
                    order.credit_acept_file = result_order.credit_acept_file;
                    order.credit_ex_file = result_order.credit_ex_file;
                    this.setState({ order: await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id }) });
                    // } else {
                    //      swal({
                    //           text: "กรุณาแนบไฟล์เอกสาร",
                    //           icon: "warning",
                    //           button: "ตกลง",
                    //           dangerMode: true,
                    //      });
                    //      // await POST("/order/update_acept_leasing", step_order);
                    //      // step_active[e.target.name] = !step_active[e.target.name];

                    //      // let result_order = await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id });
                    //      // order.credit_acept_file = result_order.credit_acept_file;
                    //      // order.credit_ex_file = result_order.credit_ex_file;
                    //      // this.setState({ order: await POST("/order/this_acept_leasing", { order_id: this.state.preemtion_id }) });
                    // }
               } catch (err) {
                    console.log(err);
               }
          }
          this.setState({ ...step_active, step_order, order, files });
     };

     openPDF = async (order_id) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/get_payment_leasing_pdf`,
                         { order_id: order_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {}
     };

     /* ------------ onChange ---------------- */
     handleChangeUploadFile = (e) => {
          let { files } = this.state;

          files = new FormData();

          files.append("order_id", this.state.preemtion_id);
          files.append("sendfile", e.target.name);
          files.append("file", e.target.files[0]);

          this.setState({ files });
     };

     /* ------------ Formatter ---------------- */
     col2Formatter = (cell, row, rowIndex, formatExtraData) => {
          let { obj, order } = this.state;
          switch (row.col2) {
               case "เจ้าหน้าที่ผู้ดำเนินการ":
                    if (!formatExtraData[0]) {
                         return (
                              <div className>
                                   <Row>
                                        <Col md={5}>{`เจ้าหน้าที่ผู้ดำเนินการ : `}</Col>
                                        <Col md={5}>-</Col>
                                        <Col md={1}></Col>
                                        <Col md={1}></Col>
                                   </Row>
                                   <Row className="mt-2">
                                        <Col md={5}>{`วันที่รับเรื่อง : `}</Col>
                                        <Col md={5}>-</Col>
                                        <Col md={1}></Col>
                                        <Col md={1}></Col>
                                   </Row>
                              </div>
                         );
                    } else {
                         return (
                              <div>
                                   <Row>
                                        <Col md={5}>{`เจ้าหน้าที่ผู้ดำเนินการ : `}</Col>
                                        <Col md={5}>{order?.user_do_name}</Col>
                                        <Col md={1}></Col>
                                        <Col md={1}></Col>
                                   </Row>
                                   <Row className="mt-2">
                                        <Col md={5}>{`วันที่รับเรื่อง : `}</Col>
                                        <Col md={5}>
                                             {order.do_credit_date ? dateFormatter(order?.do_credit_date) : dateFormatter(obj?.current_date)}
                                        </Col>
                                        <Col md={1}></Col>
                                        <Col md={1}></Col>
                                   </Row>
                              </div>
                         );
                    }

               case "date":
                    if (!formatExtraData[1]) {
                         return (
                              <div style={{ padding: "0px 25% 0px 0px" }} className="">
                                   <Field type={"date"} title={""} placeholder={"เลือกวันที่"} handleSelectDate={this.getDateInCol} />
                              </div>
                         );
                    } else {
                         return (
                              <div>
                                   <Row>
                                        <Col md={5}>{order.credit_loan_date ? dateFormatter(order.credit_loan_date) : dateFormatter(obj?.date)}</Col>
                                        <Col md={5}>
                                             <a
                                                  onClick={() => {
                                                       let { step_active } = this.state;
                                                       step_active.active3 = false;
                                                       step_active.active4 = false;
                                                       step_active.active5 = false;
                                                       order.credit_loan_date = obj.date;
                                                       this.setState({ ...step_active, ...order });
                                                  }}
                                             >{` เปลี่ยนวัน`}</a>
                                        </Col>
                                        <Col md={1}></Col>
                                        <Col md={1}></Col>
                                   </Row>
                              </div>
                         );
                    }
               case "upload":
                    if (!formatExtraData[3]) {
                         return (
                              <div>
                                   <Row>
                                        <Col md={5}>{`อัปโหลดใบอนุญาต Leasing : `}</Col>
                                        <Col md={5}>
                                             {this.state.step_active.active4 ? (
                                                  <>
                                                       {order.credit_acept_file ? (
                                                            <span className="upload noline">{` ไฟล์ใบอนุมัติชั่วคราว.pdf`}</span>
                                                       ) : (
                                                            <InputPanel
                                                                 handleChangeInputFile={(e) => this.handleChangeUploadFile(e)}
                                                                 name={"acept_file"}
                                                                 type={"file"}
                                                                 title={"แนบใบอนุมัติชั่วคราว"}
                                                            />
                                                       )}
                                                  </>
                                             ) : (
                                                  <>
                                                       {order.credit_acept_file ? (
                                                            <span className="upload noline">{` ไฟล์ใบอนุมัติชั่วคราว.pdf`}</span>
                                                       ) : (
                                                            <span className="upload">แนบใบอนุมัติชั่วคราว</span>
                                                       )}
                                                  </>
                                             )}
                                        </Col>
                                        {order.credit_acept_file ? (
                                             <>
                                                  <Col md={1}>
                                                       <span onClick={() => this.handleClickPreviewFile(order.credit_acept_file)}> {`ดู`}</span>
                                                  </Col>
                                                  <Col md={1}>
                                                       <span>{`แก้ไข`}</span>
                                                  </Col>
                                             </>
                                        ) : (
                                             <>
                                                  <Col md={1}>{``}</Col>
                                                  <Col md={1}>{``}</Col>
                                             </>
                                        )}
                                   </Row>
                                   <Row>
                                        {order.credit_ex_file ? (
                                             <>
                                                  <Col md={5}>{``}</Col>
                                                  <Col md={5}>
                                                       {this.state.step_active.active4 ? (
                                                            <span className="upload noline">{` ไฟล์ใบอนุมัติออกจากระบบ.pdf`}</span>
                                                       ) : (
                                                            <span className="upload noline">ไฟล์ใบอนุมัติออกจากระบบ.pdf</span>
                                                       )}
                                                  </Col>
                                                  <Col md={1}>
                                                       <span onClick={() => this.handleClickPreviewFile(order.credit_ex_file)}> {`ดู`}</span>
                                                  </Col>
                                                  <Col md={1}>
                                                       <span>{`แก้ไข`}</span>
                                                  </Col>
                                             </>
                                        ) : (
                                             <>
                                                  <Col md={5}>{``}</Col>
                                                  <Col md={5}>
                                                       {this.state.step_active.active4 ? (
                                                            <InputPanel
                                                                 handleChangeInputFile={(e) => this.handleChangeUploadFile(e)}
                                                                 name={"ex_file"}
                                                                 type={"file"}
                                                                 title={"แนบใบอนุมัติออกจากระบบ"}
                                                            />
                                                       ) : (
                                                            <span className="upload">แนบใบอนุมัติออกจากระบบ</span>
                                                       )}
                                                  </Col>
                                                  <Col md={1}>{``}</Col>
                                                  <Col md={1}>{``}</Col>
                                             </>
                                        )}
                                   </Row>
                              </div>
                         );
                    } else {
                         return (
                              <div>
                                   {order.credit_acept_file ? (
                                        <Row>
                                             <Col md={5}>{`อัปโหลดใบอนุญาต Leasing : `}</Col>
                                             <Col md={5}>{` ไฟล์ใบอนุมัติชั่วคราว.pdf`}</Col>
                                             <Col md={1}>
                                                  <a onClick={() => this.handleClickPreviewFile(order.credit_acept_file)}> {`ดู`}</a>
                                             </Col>
                                             <Col md={1}>
                                                  <a
                                                       onClick={() => {
                                                            let { step_active, order } = this.state;
                                                            step_active.active5 = false;
                                                            order.credit_acept_file = null;
                                                            this.setState({ step_active, order });
                                                       }}
                                                  >{`แก้ไข`}</a>
                                             </Col>
                                        </Row>
                                   ) : (
                                        <Row>
                                             <Col md={5}>{`อัปโหลดใบอนุญาต Leasing : `}</Col>
                                             <Col md={5}>
                                                  <span className="upload">{` แนบใบอนุมัติชั่วคราว`}</span>
                                             </Col>
                                             <Col md={1}>
                                                  <span> {``}</span>
                                             </Col>
                                             <Col md={1}>
                                                  <span>{``}</span>
                                             </Col>
                                        </Row>
                                   )}
                                   {order.credit_ex_file ? (
                                        <Row>
                                             <Col md={5}>{``}</Col>
                                             <Col md={5}>
                                                  <span className="upload noline">{` ไฟล์ใบอนุมัติออกจากระบบ.pdf`}</span>
                                             </Col>
                                             <Col md={1}>
                                                  <a onClick={() => this.handleClickPreviewFile(order.credit_ex_file)}> {`ดู`}</a>
                                             </Col>
                                             <Col md={1}>
                                                  <a
                                                       onClick={() => {
                                                            let { step_active, order, files } = this.state;
                                                            step_active.active5 = false;
                                                            order.credit_ex_file = null;
                                                            this.setState({ step_active, order, files });
                                                       }}
                                                  >
                                                       {`แก้ไข`}
                                                  </a>
                                             </Col>
                                        </Row>
                                   ) : (
                                        <Row>
                                             <Col md={5}>{``}</Col>
                                             <Col md={5}>
                                                  <span className="upload">{` แนบใบอนุมัติออกจากระบบ`}</span>
                                             </Col>
                                             <Col md={1}>
                                                  <span>{``}</span>
                                             </Col>
                                             <Col md={1}>
                                                  <span> {``}</span>
                                             </Col>
                                        </Row>
                                   )}
                              </div>
                         );
                    }
          }
     };

     col3Formatter = (cell, row, rowIndex, formatExtraData) => {
          let { obj, step_order, order } = this.state;
          let date = new Date();
          let current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          obj["current_date"] = current_date;
          // step_order.do_credit_date = current_date;
          this.setState({
               ...obj,
               // step_order,
          });
          // console.log("step order", this.state.step_order);
          switch (row.col3) {
               case "วันที่รับเรื่อง":
                    if (!formatExtraData[0]) {
                         return (
                              <Row>
                                   <Col md={4}>วันที่รับเรื่อง : </Col>
                                   <Col>-</Col>
                              </Row>
                         );
                    } else {
                         return (
                              <Row>
                                   <Col md={4}>วันที่รับเรื่อง : </Col>
                                   <Col>{order.do_credit_date ? dateFormatter(order?.do_credit_date) : dateFormatter(obj?.current_date)}</Col>
                              </Row>
                         );
                    }
               case "-":
                    return <p></p>;
          }
     };

     col4Formatter = (cell, row, rowIndex, formatExtraData) => {
          let id = getToken().position_id;
          switch (row.col4) {
               case "รับเรื่องแล้ว":
                    // console.log(formatExtraData);
                    if (!formatExtraData[0]) {
                         return (
                              <a name={`active${rowIndex + 2}`} onClick={this.stepActive} disabled={id === 19 ? true : false}>
                                   {row.col4}
                              </a>
                         );
                    } else {
                         return <p style={{ color: "green", margin: "0px" }}> {row.col4}</p>;
                    }
               case "ยืนยันวันนัดหมาย":
                    if (!formatExtraData[0]) {
                         return (
                              <a style={{ color: "grey" }} disabled={id === 19 ? true : false}>
                                   {row.col4}
                              </a>
                         );
                    } else if (formatExtraData[0] && formatExtraData[1]) {
                         return <p style={{ color: "green", margin: "0px" }}> {row.col4}</p>;
                    } else {
                         return (
                              <a name={`active${rowIndex + 2}`} onClick={this.stepActive}>
                                   {row.col4}
                              </a>
                         );
                    }

               case "ยืนยันการเซ็นสัญญา":
                    if (!formatExtraData[1]) {
                         return (
                              <a style={{ color: "grey" }} disabled={id === 19 ? true : false}>
                                   {row.col4}
                              </a>
                         );
                    } else if (formatExtraData[1] && formatExtraData[2]) {
                         return <p style={{ color: "green", margin: "0px" }}> {row.col4}</p>;
                    } else {
                         return (
                              <a name={`active${rowIndex + 2}`} onClick={this.stepActive} disabled={id === 19 ? true : false}>
                                   {row.col4}
                              </a>
                         );
                    }
               case "ยืนยันผลการอนุมัติ":
                    if (!formatExtraData[2]) {
                         return (
                              <a style={{ color: "grey" }} disabled={id === 19 ? true : false}>
                                   {row.col4}
                              </a>
                         );
                    } else if (formatExtraData[2] && formatExtraData[3]) {
                         return <p style={{ color: "green", margin: "0px" }}> {row.col4}</p>;
                    } else {
                         return (
                              <a name={`active${rowIndex + 2}`} onClick={this.stepActive} disabled={id === 19 ? true : false}>
                                   {row.col4}
                              </a>
                         );
                    }
          }
     };
     render() {
          let { preemtion_id, fileURL, step_active, step_order, detail } = this.state;
          let table = {
               column: [
                    {
                         dataField: "col4",
                         text: "",
                         formatter: this.col4Formatter,
                         headerClasses: "header-custom __btn-col4",
                         formatExtraData: [step_active.active2, step_active.active3, step_active.active4, step_active.active5],
                    },
                    {
                         dataField: "col1",
                         text: "",
                         headerClasses: "header-custom __btn-col1",
                    },
                    {
                         dataField: "col2",
                         text: "",
                         formatter: this.col2Formatter,
                         headerClasses: "header-custom __btn-col2",
                         formatExtraData: [step_active.active2, step_active.active3, step_active.active4, step_active.active5],
                    },
                    // {
                    //      dataField: "col3",
                    //      text: "",
                    //      formatter: this.col3Formatter,
                    //      headerClasses: "header-custom __btn-col3",
                    //      formatExtraData: [step_active.active2, step_active.active3, step_active.active4, step_active.active5],
                    // },
               ],
               data: [
                    {
                         col1: "เจ้าหน้าที่รับเรื่อง",
                         col2: "เจ้าหน้าที่ผู้ดำเนินการ",
                         col3: "วันที่รับเรื่อง",
                         col4: "รับเรื่องแล้ว",
                         left: "left",
                    },
                    {
                         col1: "นัดหมายเซ็นสัญญาขอกู้",
                         col2: "date",
                         col3: "-",
                         col4: "ยืนยันวันนัดหมาย",
                    },
                    {
                         col1: "ลูกค้าเซ็นสัญญา",
                         col2: "-",
                         col3: "-",
                         col4: "ยืนยันการเซ็นสัญญา",
                    },
                    {
                         col1: "สินเชื่ออนุมัติ",
                         col2: "upload",
                         col3: "-",
                         col4: "ยืนยันผลการอนุมัติ",
                    },
               ],
               keyField: "col1",
          };

          //   console.log(step_active);
          let step = [
               {
                    title: "ขอสินเชื่อ",
                    active: step_active.active1,
               },
               {
                    title: "เจ้าหน้าที่รับเรื่อง",
                    active: step_active.active2,
               },
               {
                    title: "นัดหมายเซ็นสัญญาขอกู้",
                    active: step_active.active3,
               },
               {
                    title: "ลูกค้าเซ็นสัญญา",
                    active: step_active.active4,
               },
               {
                    title: "สินเชื่ออนุมัติ",
                    active: step_active.active5,
               },
          ];
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `การอนุมัติ Leasing`,
                                   onClick: () => window.location.assign("/admin/order/leasing"),
                              },
                              {
                                   title: `ใบจองรถยนต์ (${detail.order_code_id ? detail.order_code_id : ""})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${detail.order_id && detail.order_id}`),
                              },
                         ]}
                         // active={1}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"สถานะสินเชื่อ"} />
                                        <div className="-padding">
                                             <Step step={step} />
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content" id="table-content">
                                        <LabelBar type={"title"} title={"การดำเนินการ"} />
                                        <Table type={"custom-column"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* <button onClick={this.download} >download</button> */}
                                        {fileURL && <embed src={fileURL} type="application/pdf" height="1150px" width="100%" />}
                                        {step_active.active5 ? (
                                             // Breadcrumbs Label
                                             <div>
                                                  <Breadcrumbs
                                                       under={true}
                                                       type={"success"}
                                                       title={"สินเชื่ออนุมัติ"}
                                                       button={{
                                                            onClick: () => this.openPDF(preemtion_id),
                                                            name: "พิมพ์ใบยืนยันลีสซิง",
                                                            variant: "outline-primary",
                                                       }}
                                                  />
                                                  <div style={{ height: "80px" }}></div>
                                             </div>
                                        ) : null}
                                   </div>

                                   {/* {this.state.test ? <span>list</span> : <Button onClick={() => this.setState({ test: true })}>click</Button>} */}
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
