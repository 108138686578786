import React, { Component } from "react";
import { GET } from "api/index";
import { Breadcrumbs, Table, Search, Image } from "components/index";

export default class promotion extends Component {
     constructor(props) {
          super(props);
          this.state = {
               promotion: [],
               promotion_backup: [],
               search: {},
          };
     }
     componentDidMount = () => {
          this.getpromotion();
     };
     getpromotion = async () => {
          try {
               let result = await GET("/car/series");
               result
                    .sort((end, start) => parseFloat(start.series_id) - parseFloat(end.series_id))
                    .sort((end, start) => parseFloat(start.series_id) - parseFloat(end.series_id));
               // console.log(result)
               this.setState({ promotion: result, promotion_backup: result });
          } catch (error) {
               console.log(error);
          }
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <a
                    onClick={() =>
                         this.props.history.push({
                              pathname: `/admin/promotion/detail/${row.series_id}`,
                              state: { series_id: row.series_id, series_name: row.series_name },
                         })
                    }
               >
                    ทำรายการ
               </a>
          );
     };
     onSearch = () => {
          let { promotion_backup, search } = this.state;
          console.log("promotion_backup", promotion_backup);
          // console.log(search)
          let promotion = promotion_backup.filter(
               (e) =>
                    (search.series_name ? String(e.series_name).toLowerCase().includes(String(search.series_name).toLowerCase()) : e) &&
                    (search.model
                         ? String(e.model).toLowerCase() === String(search.model).toLowerCase() || String(search.model).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.status
                         ? String(e.status).toLowerCase() === String(search.status).toLowerCase() || String(search.status).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ promotion });
          // console.log("promotion", promotion)
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     render() {
          let { promotion, search, promotion_backup } = this.state;
          let obj = {
               series: promotion_backup?.map((e) => ({
                    value: e.series_name,
                    name: e.series_name,
               })),
          };

          const table = {
               column: [
                    {
                         dataField: "promotiom_item",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "series_name",
                         text: "Series",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "type",
                         text: "ประเภทรถ.",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "status",
                         text: "สถานะ",
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: promotion,
               keyField: "series_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "โปรโมชั่น" }]} active={0} button={""} />
                    <div className="content __main-panel">
                         <Search
                              type={"promotion"}
                              searchOptions={search}
                              onSearch={this.onSearch}
                              handleChangeText={this.handleChangeText}
                              handleChange={this.handleChangeOption}
                              obj={obj}
                         />
                         <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                    </div>
               </div>
          );
     }
}
