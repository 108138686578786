const jwt = require("crypto-js");

export const getToken = () => {
     try {
          let get = localStorage.getItem("token");
          let decode = get && get.length > 100 ? jwt.RC4.decrypt(get, "]ncC[8]Ma8!WM'~Ik5xjo]1]nt9[+Rb0%Hk_cejgPYi(|l>(9-I-M*T1>*MTw`{") : null;
          let data = decode ? decode.toString(jwt.enc.Utf8) : null;
          return JSON.parse(data);
     } catch (error) {
          console.log("Decode Token Error : ", error);
     }
};
export const getToken_permission_page = () => {
     try {
          let get = localStorage.getItem("permission_page");
          let decode = get && get.length > 100 ? jwt.RC4.decrypt(get, "]ncC[8]Ma8!WM'~Ik5xjo]1]nt9[+Rb0%Hk_cejgPYi(|l>(9-I-M*T1>*MTw`{") : null;
          let data = decode ? decode.toString(jwt.enc.Utf8) : null;
          return JSON.parse(data);
     } catch (error) {
          console.log("Decode permission_page Error : ", error);
     }
};
export const tofieds = (num) =>
     Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
export const add = (key) => {
     return (a, b) => {
          let d = Number(b[key]) ? Number(b[key]) : b[key] ? Number(b[key].replace(/,/g, "")) : 0;
          return a + d;
     };
};

export const ThaiBaht = (n) => {
     var Number = CheckNumber(n);
     var NumberArray = ["ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ"];
     var DigitArray = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"];
     var BahtText = "";
     let check1 = false;
     if (isNaN(Number)) {
          return "ข้อมูลนำเข้าไม่ถูกต้อง";
     } else {
          if (Number === "0.00") {
               return "ศูนย์บาทถ้วน";
          }
          if (Number === "1.00") {
               return "หนึ่งบาทถ้วน";
          }
          if (parseInt(Number) === 0) {
               BahtText = "ศูนย์";
          }
          if (parseInt(Number) === 1) {
               BahtText = "หนึ่ง";
               check1 = true;
          }
          if (Number - 0 > 9999999.9999) {
               return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
          } else {
               Number = Number.split(".");
               if (Number[1].length > 0) {
                    Number[1] = Number[1].substring(0, 2);
               }
               let minus = Number[0].indexOf("-") >= 0 ? true : false;
               Number[0] = minus ? Number[0].split("-")[1] : Number[0];
               var NumberLen = Number[0].length - 0;
               for (var i = 0; i < NumberLen; i++) {
                    var tmp = Number[0].substring(i, i + 1) - 0;
                    if (tmp !== 0) {
                         if (i === NumberLen - 1 && tmp === 1) {
                              BahtText += check1 ? "" : "เอ็ด";
                         } else if (i === NumberLen - 2 && tmp === 2) {
                              BahtText += "ยี่";
                         } else if (i === NumberLen - 2 && tmp === 1) {
                              BahtText += "";
                         } else {
                              BahtText += NumberArray[tmp];
                         }
                         BahtText += DigitArray[NumberLen - i - 1];
                    }
               }
               BahtText += "บาท";
               if (Number[1] === "00") {
                    BahtText += "ถ้วน";
               } else if (Number[1] === "01") {
                    BahtText += "หนึ่งสตางค์";
               } else {
                    var DecimalLen = Number[1].length - 0;
                    for (var index = 0; index < DecimalLen; index++) {
                         var tmps = Number[1].substring(index, index + 1) - 0;
                         if (tmps !== 0) {
                              if (index === DecimalLen - 1 && tmps === 1) {
                                   BahtText += "เอ็ด";
                              } else if (index === DecimalLen - 2 && tmps === 2) {
                                   BahtText += "ยี่";
                              } else if (index === DecimalLen - 2 && tmps === 1) {
                                   BahtText += "";
                              } else {
                                   BahtText += NumberArray[tmps];
                              }
                              BahtText += DigitArray[DecimalLen - index - 1];
                         }
                    }
                    BahtText += "สตางค์";
               }
               let returnBahtText = minus ? "ลบ" + BahtText : BahtText;
               return returnBahtText;
          }
     }
};
function CheckNumber(n) {
     n = Number(n).toFixed(2);
     var decimal = false;
     n = n.toString();
     n = n.replace(/ |,|บาท|฿/gi, "");
     for (var i = 0; i < n.length; i++) {
          if (n[i] === ".") {
               decimal = true;
          }
     }
     if (decimal === false) {
          n = n + ".00";
     }
     return n;
}
