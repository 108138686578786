import React, { Component } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { Breadcrumbs, Table, Field, LabelBar } from "components/index";
import { POST, GET } from "../../../../api/api";
import swal from "sweetalert";

function ScrollToTopOnMount() {
     React.useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

export default class AddLeasing extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               previous_path: this.props.match.params.manage,
               title: "",
               leasing: {
                    status: "ใช้งาน",
               },
               data: [],
               file: [],
               deletePic: [],
               addPic: [],
               title: [],
               id: this.props.match.params.id,
               supplier: [],
               series: [],
          };
     }

     componentDidMount() {
          this.setPath();
          this.getData();
     }
     getData = async () => {
          try {
               let get_sup = await POST("/aos/get_sup_by", { name: "supplier_type", by: "Leasing" });
               let get_series = await GET("/aos/get_series");
               this.setState({ supplier: get_sup, series: get_series });
          } catch (error) {}
     };
     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { leasing } = this.state;
          leasing[name] = value;
          this.setState({ leasing });
     };

     handleChangeNumber = (e, name) => {
          let { leasing } = this.state;
          leasing[name] = e.floatValue;
          this.setState({ leasing });
     };

     /* Validate field on submit */
     handleSubmit = async (event) => {
          console.log("event", event);
          event.preventDefault();
          let { leasing, file } = this.state;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    icon: "error",
                    button: "เสร็จสิ้น",
               });
               event.stopPropagation();
          } else {
               let formData = new FormData();
               if (file) {
                    file.forEach((element) => {
                         formData.append("file", element.file);
                    });
               }

               for (const [key, value] of Object.entries(leasing)) {
                    formData.append(key, value);
               }

               try {
                    await POST("/ps/add_leasing", formData, true);

                    swal({
                         text: "เพิ่มประเภท Leasing ในรายการเรียบร้อยแล้ว",
                         icon: "success",
                         button: "เสร็จสิ้น",
                    }).then(() => this.props.history.push("/admin/leasing"));
                    event.preventDefault();
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }

          this.setState({
               validated: true,
          });
     };

     setPath = async () => {
          let { previous_path, title, id } = this.state;
          switch (previous_path) {
               case "add":
                    // title = ['Leasing', 'เพิ่ม Leasing'];
                    title = [
                         {
                              title: "Leasing",
                              onClick: () => window.location.assign("/admin/leasing"),
                         },
                         {
                              title: "เพิ่ม Leasing",
                              // onClick: () => this.handleNext(false),
                         },
                    ];
                    break;
               case "edit":
                    title = [
                         {
                              title: "Leasing",
                              onClick: () => window.location.assign("/admin/leasing"),
                         },
                         {
                              title: "ข้อมูล",
                              onClick: () => window.location.assign(`/admin/leasing/detail${id}`),
                         },
                         {
                              title: "แก้ไข",
                              // onClick: () => this.handleNext(false),
                         },
                    ];

                    this.getLeasing();
                    break;
               default:
                    break;
          }
          title = this.setState({ title: title });
     };

     getLeasing = async () => {
          try {
               let leasing = await POST("/ps/this_leasing", {
                    leasinge_id: this.props.match.params.id,
               });
               //  console.log("leasing", leasing);
               leasing.files.forEach((element, i) => {
                    element.no = i + 1;
                    element.download = element.file;
               });

               this.setState({ leasing: { ...leasing }, file: leasing.files });
          } catch (error) {
               console.log(error);
          }
     };

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          // console.log(cell);
          return (
               <a href={row.file} download>
                    <Button>ดูเอกสาร </Button>
               </a>
          );
     }

     removeBtnFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.removeFile(row, rowIndex)} variant={"danger"}>
                    Remove
               </Button>
          );
     };

     /* Upload file and set file name to table */
     handleChange = (event) => {
          let { file } = this.state;
          const fileUploaded = event.target.files[0];
          file.push({
               no: file.length + 1,
               name: fileUploaded.name,
               file: fileUploaded,
               download: URL.createObjectURL(fileUploaded),
          });
          this.setState({
               file,
          });
     };

     removeFile = (row, rowIndex) => {
          let { file, deletePic } = this.state;

          if (file.length > 0) {
               if (rowIndex < file[0].no) {
                    file.forEach((element) => {
                         element.no--;
                    });
               } else {
                    let indexOfStevie = file.findIndex((index) => index.no === rowIndex + 1);
                    for (let index = indexOfStevie; index < file.length; index++) {
                         file[index].no--;
                    }
               }
          }
          deletePic.push(row.leasinge_file_id);
          file.splice(rowIndex, 1);

          this.setState({ file: [...file] });
     };

     handleEdit = async () => {
          let { leasing, file, deletePic, id } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    file.forEach((element, i) => {
                         if (element.leasinge_file_id) {
                              file.splice(i, 1);
                         }
                    });
                    file.forEach((element) => {
                         formData.append("file", element.file);
                    });
               }
               for (const [key, value] of Object.entries(leasing)) {
                    formData.append(key, value);
               }

               formData.append("deletePic", JSON.stringify(deletePic));

               await POST("/ps/edit_leasing", formData, true);

               swal({
                    text: "แก้ไขประเภท Leasing ในรายการเรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => this.props.history.push(`/admin/leasing/detail${id}`));

               this.getLeasing();
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     render() {
          let { title, leasing, validated, previous_path, file, supplier, series } = this.state;
          const column = [
               {
                    dataField: "no",
                    text: "ลำดับ",
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "name",
                    text: "ไฟล์เอกสาร",
                    headerClasses: "header-custom btn-m",
               },
               {
                    dataField: "detail",
                    text: "",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom btn-s",
               },
               {
                    dataField: "remove",
                    text: "Remove",
                    formatter: this.removeBtnFormatter,
                    headerClasses: "header-custom __right __btn-s",
               },
          ];
          return (
               <div className="panel">
                    {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} onClick={() => this.props.history.goBack()} /> : null}
                    <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                         <div className="content __input-panel">
                              {/* ข้อมูลประเภท Leasing */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ข้อมูลประเภท Leasing</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"ระบุประเภท Leasing"}
                                                            name={"leasing_type"}
                                                            placeholder={"กรุณากรอกประเภท Leasing"}
                                                            require={true}
                                                            md={6}
                                                            value={leasing?.leasing_type}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"number"}
                                                            typeInput={"number"}
                                                            title={"จำนวนปีผ่อนชำระ"}
                                                            name={"amount_year"}
                                                            placeholder={"กรุณากรอกจำนวนปีผ่อนชำระ"}
                                                            require={true}
                                                            md={6}
                                                            value={leasing?.amount_year}
                                                            handleChangeNumber={(e) => this.handleChangeNumber(e, "amount_year")}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"บริษัทไฟแนนซ์"}
                                                            name={"supplier_id"}
                                                            placeholder={"กรุณาเลือกบริษัทไฟแนนซ์"}
                                                            option={supplier?.map((e) => ({
                                                                 value: e.supplier_id,
                                                                 name: e.supplier_name,
                                                            }))}
                                                            value={leasing?.supplier_id}
                                                            //  require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"Series"}
                                                            name={"series_id"}
                                                            placeholder={"กรุณาเลือกบริษัทไฟแนนซ์"}
                                                            option={series?.map((e) => ({
                                                                 value: e.series_id,
                                                                 name: e.series_name,
                                                            }))}
                                                            value={leasing?.series_id}
                                                            //  require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            as={"textarea"}
                                                            textarea_rows={5}
                                                            title={"รายละเอียด Leasing"}
                                                            name={"leasing_text"}
                                                            placeholder={"กรุณากรอกรายละเอียด Leasing"}
                                                            require={true}
                                                            md={12}
                                                            value={leasing?.leasing_text}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"สถานะ"}
                                                            name={"status"}
                                                            label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                            value={leasing?.status}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* อัตราดอกเบี้ย */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>อัตราดอกเบี้ย</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"number"}
                                                            title={"ระบุอัตราดอกเบี้ย"}
                                                            name={"leasing_interest"}
                                                            placeholder={"ระบุอัตราดอกเบี้ย (%)"}
                                                            require={true}
                                                            value={leasing?.leasing_interest}
                                                            md={6}
                                                            handleChangeNumber={(e) => this.handleChangeNumber(e, "leasing_interest")}
                                                            unit={"%"}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* แนบไฟล์เอกสารได้ */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>แนบไฟล์เอกสาร</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content" id="table-content">
                                             {/* <div> */}
                                                  {/* <div className="index __add row"> */}
                                                       <Table
                                                            type={"add"}
                                                            add={"file"}
                                                            column={column}
                                                            data={file}
                                                            keyField={"no"}
                                                            handleChange={this.handleChange}
                                                       />
                                                  {/* </div> */}
                                             {/* </div> */}
                                        </div>
                                   </div>
                              </div>
                              <div className="input-field">
                                   {previous_path === "add" && (
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button type="submit">Submit</Button>
                                             </Col>
                                             <Col xs={6}>
                                                  <Button variant="danger">Reset</Button>
                                             </Col>
                                        </div>
                                   )}
                                   {previous_path === "edit" && (
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button onClick={this.handleEdit}>บันทึกการแก้ไข</Button>
                                             </Col>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </Form>
               </div>
          );
     }
}
