import React, { Component } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { Breadcrumbs, Table, Field, LabelBar } from "components/index";
import { POST } from "api/index";

export default class PromotionDetail extends Component {
     constructor(props) {
          super(props);
          this.state = {
               detail: [],
               id: this.props.match.params.id,
               title: [],
          };
     }
     componentDidMount = () => {
          // console.log("asds", this.props.location.state);
          this.getPromotionDetail();
          this.setPath();
     };
     getPromotionDetail = async () => {
          try {
               let result = await POST("/ps/get_promotion", {
                    series_id: this.state.id,
               });
               this.setState({ detail: result });
          } catch (error) {
               console.log(error);
          }
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { series_name, series_id } = this.props.location.state;

          return (
               <a
                    onClick={() =>
                         this.props.history.push({
                              pathname: `/admin/promotion/detail/edit/${series_id}/${row.pro_id}`,
                              state: { series_id, series_name, pro_id: row.pro_id },
                         })
                    }
               >
                    แก้ไข
               </a>
          );
     };
     detailRemove = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.del_pro(row.pro_id)}>ลบ</a>;
     };
     del_pro = async (id) => {
          try {
               await POST("/aos/del_pro", { pro_id: id });
               this.getPromotionDetail();
          } catch (error) {}
     };
     setPath = async () => {
          let { title } = this.state;
          let { series_name } = this.props.location.state;
          title = [
               {
                    title: "โปรโมชั่น",
                    onClick: () => window.location.assign("/admin/promotion"),
               },
               {
                    title: `จัดการโปรโมชั่น (${series_name})`,
                    // onClick: () => this.handleNext(false),
               },
          ];

          this.setState({ title: title });
     };

     render() {
          let { detail, title } = this.state;
          const table = {
               column: [
                    {
                         dataField: "promotion_name",
                         text: "รายการโปรโมชั่น",
                         headerClasses: "header-custom __left __btn-m",
                    },
                    {
                         dataField: "promotion_code",
                         text: "โปรโมชัน Code",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "start_date",
                         text: "ระยะเวลา",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "edit",
                         text: "แก้ไข",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "delete",
                         text: "ลบ",
                         formatter: this.detailRemove,
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: detail,
               keyField: "promotion_list",
          };
          let { series_name, series_id } = this.props.location.state;

          return (
               <div className="panel">
                    <Breadcrumbs title={title} active={title.length - 1} button={""} />
                    <div className="content __input-panel">
                         {/* โปรโมชั่น */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: `Serie : ${series_name}`,
                                             }}
                                        />
                                        <LabelBar type={"title"} title={"โปรโมชั่น"} />
                                        <div className="button-group __onPanel row">
                                             <Col xl={3} md={4}>
                                                  <Button
                                                       onClick={() =>
                                                            this.props.history.push({
                                                                 pathname: `/admin/promotion/detail/add/${series_id}`,
                                                                 state: { series_id, series_name },
                                                            })
                                                       }
                                                  >
                                                       เพิ่มโปรโมชัน
                                                  </Button>
                                             </Col>
                                        </div>
                                        <div className="content" id="table-content">
                                             <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
