import { POST } from "api";
import { Breadcrumbs, LabelBar, ModalPanel, Table } from "components";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Row, Button, Col } from "react-bootstrap";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import swal from "sweetalert";

export default class GenerateMakeUp extends Component {
     constructor(props) {
          super(props);
          this.state = {
               car_id: this.props.match.params.car_id,
               order_id: this.props.match.params.order_id,
               show_confirm: false,
               match_status: false,
               confirm_order: [],
               order: {},
               showModalCancel: false,
               acced: [],
          };
     }

     componentDidMount = () => {
          this.getConfirmOrder();
          this.getOrder();
          this.getAcced();
     };

     getAcced = async () => {
          try {
               let car = await POST("/car/this", { car_id: this.props.match.params.car_id });
               let acc =
                    car.acc_installed && car.acc_installed.length > 2
                         ? JSON.parse(car.acc_installed).map((e, i) => ({ ...e, index: i + 1, items_type: "-" }))
                         : [];
               this.setState({ acced: acc });
          } catch (err) {
               console.log(err);
          }
     };
     getOrder = async () => {
          try {
               let result = await POST("/order/this", { order_id: this.state.order_id });
               this.setState({ order: { ...result } });
          } catch (err) {
               console.log(err);
          }
     };

     getConfirmOrder = async () => {
          let { car_id, order_id } = this.state;
          try {
               let order = await POST("/car/this_matched", { car_id: car_id, order_id: order_id });
               this.setState({ confirm_order: { ...order } });
          } catch (err) {
               console.log(err);
          }
     };

     onClickConfirmMatch = async () => {
          let { order_id } = this.state;
          try {
               this.setState({ showModalCancel: !this.state.showModalCancel });
               let result = await POST("/car/accept_match", { order_id: order_id });
               if (result === "accept success") {
                    swal({
                         icon: "success",
                         text: "ยืนยันการติดตั้งอุปกรณ์",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then((value) => {
                         // this.setState({ match_status: !this.state.match_status });
                         window.location.assign("/admin/match/makeup");
                    });
               }
          } catch (err) {
               swal({
                    icon: "warning",
                    text: `ไม่สามารถทำรายการได้ ${err}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
          }
     };
     onClickCancelMatch = async () => {
          let { car_id, order_id } = this.state;
          try {
               this.setState({ showModalCancel: !this.state.showModalCancel });
               let result = await POST("/car/cancel_makeup", { car_id: car_id, order_id: order_id });
               if (result === "cancel success") {
                    swal({
                         icon: "success",
                         text: "ยกเลิกการติดตั้งอุปกรณ์",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then((value) => {
                         // if (value === "submit") {
                         window.location.assign("/admin/match/makeup");
                         // }
                    });
               }
          } catch (err) {
               swal({
                    icon: "warning",
                    text: `ไม่สามารถทำรายการได้ ${err}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
          }
     };

     onClickCancelModal = () => {
          this.setState({ showModalCancel: !this.state.showModalCancel, titleModal: "ต้องการยกเลิกการติดตั้งหรือไม่" });
     };
     onClickConfirmModal = () => {
          this.setState({ showModalCancel: !this.state.showModalCancel, titleModal: "ต้องการยืนยันการติดตั้งหรือไม่" });
     };

     modalBeforeCanApp = () => {
          let onClick;
          if (this.state.titleModal === "ต้องการยกเลิกการติดตั้งหรือไม่") {
               onClick = this.onClickCancelMatch;
          } else {
               onClick = this.onClickConfirmMatch;
          }
          return (
               <ModalPanel
                    show={this.state.showModalCancel}
                    title={this.state.titleModal}
                    type={"default"}
                    onHide={() => this.setState({ showModalCancel: !this.state.showModalCancel })}
                    button={[
                         { onClick: onClick, name: "ตกลง", variant: "primary" },
                         {
                              onClick: () => this.setState({ showModalCancel: !this.state.showModalCancel }),
                              name: "ยกเลิก",
                              variant: "outline-primary",
                         },
                    ]}
               />
          );
     };

     /* ------------------------ Formatter ---------------------------- */
     dateFormatter = (cell) => {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     };

     detailFormatterBooking(cell, row) {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     }
     parseFloatFormatter = (cell, row) => {
          let new_cell = parseFloat(cell ? cell : 0).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     };

     noteFormatter = (cell, row) => {
          if (cell) {
               let acc = JSON.parse(cell);
               // let acc_filter = acc.filter((el, i) => acc.map((ele) => ele.items_name).indexOf(el.items_name) === i);
               let acc_filter = acc;
               return (
                    <div>
                         {/* <strong style={{ color: "#000" }}>รายการอุปกรณ์ที่เคยติดตั้ง</strong> */}
                         {/* <br></br> */}
                         {acc_filter?.map((el, i) => (
                              <>
                                   {i + 1}.{` ${el.items_name}`}
                                   <br></br>
                              </>
                         ))}
                         {/* - อุปกรณ์ 1<br></br>- อุปกรณ์ 2<br></br>- อุปกรณ์ 3<br></br>- อุปกรณ์ 4<br></br> */}
                    </div>
               );
          } else {
               return <span>-</span>;
          }
     };

     render() {
          let { confirm_order, order, acced } = this.state;
          const table_confirm = {
               column: [
                    {
                         dataField: "series_name",
                         text: "Serie",
                         headerClasses: "header-custom __left __btn-s",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         headerClasses: "header-custom __5em",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "car_engine",
                         text: "เลขเครื่องยนต์",
                         headerClasses: "header-custom __btn-s",
                    },

                    {
                         dataField: "car_fun",
                         text: "ราคาทุน",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "car_price",
                         text: "ราคาขาย",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    // {
                    //      dataField: 'car_vat',
                    //      text: 'ราคาขาย (รวม Vat)',
                    //      headerClasses: 'header-custom __right __btn-m',
                    //      formatter: this.parseFloatFormatter,
                    // },
                    {
                         dataField: "acc_installed",
                         text: "รายการอุปกรณ์ที่ติดตั้งแล้ว",
                         headerClasses: "header-custom __btn-match-car",
                         formatter: this.noteFormatter,
                    },
                    {
                         dataField: "car_note",
                         text: "หมายเหตุ",
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? e : "-"),
                    },
               ],
               data: confirm_order.car ? [confirm_order.car] : [],
               keyField: "car_id",
          };

          const table_preemption_confirm = {
               column: [
                    {
                         dataField: "payment_date",
                         text: "วันเวลาที่ชำระค่าจอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __left __btn-m",
                    },
                    {
                         dataField: "getcar_date",
                         text: "วันรับรถ",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatterBooking,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อพนักงานขาย",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "discount_price",
                         text: "ส่วนลด (บาท)",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "note",
                         text: "หมายเหตุ",
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? e : "-"),
                    },
               ],
               data: confirm_order.order ? [confirm_order.order] : [],
               keyField: "order_id",
          };

          const table_acc = {
               column: [
                    {
                         dataField: "index",
                         text: "ลำดับที่",
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "supplier_name",
                         text: "บริษัท",
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: confirm_order.acc ? confirm_order.acc : [],
               data2: acced.length ? acced : [],
               keyField: "index",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `ยืนยันการติดตั้งอุปกรณ์`,
                                   onClick: () => window.location.assign("/admin/match/makeup"),
                              },
                              {
                                   title: `การติดตั้งอุปกรณ์ (${order?.order_code_id || ""})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order.order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* <LabelBar type={"title"} title={"รายละเอียดของ Invoice"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบ Invoice : ",
                                                            detail: "20200000000",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Invoice Date : ",
                                                            detail: "วว/ดด/ปป",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div> */}
                                        <LabelBar type={"title"} title={"รายละเอียดเกี่ยวกับรถ"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series : ",
                                                            detail: order?.series_name || "",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model : ",
                                                            detail: order?.model_name || "",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สี : ",
                                                            detail: order?.color_name || "",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขถัง : ",
                                                            detail: order?.car_tank || "",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขเครื่อง : ",
                                                            detail: order?.car_engine || "",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Booking : ",
                                                            detail: order?.order_code_id || "",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันส่งมอบรถยนต์ : ",
                                                            detail: order.makeup_date
                                                                 ? dayjs(order.makeup_date).format("DD/MM/") + (dayjs(order.makeup_date).year() + 543)
                                                                 : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "พนักขาย : ",
                                                            detail: order.saler_name_string ? order.saler_name_string : "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ผู้สั่งจอง : ",
                                                            detail: order.userinfo_name ? order.userinfo_name : "",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายการอุปกรณ์ที่ติดมากับรถยนต์"} color="red" />
                                        <div className="content" id="table-content" style={{ margin: "0", width: "100%", padding: "0 12px" }}>
                                             <Table
                                                  type={"summary-no-padding"}
                                                  column={table_acc.column}
                                                  data={table_acc.data2}
                                                  keyField={table_acc.keyField}
                                             />
                                        </div>
                                        <LabelBar type={"title"} title={"รายการอุปกรณ์ที่ต้องการติดตั้ง"} />
                                        <div className="content" id="table-content" style={{ margin: "0", width: "100%", padding: "0 12px" }}>
                                             <Table
                                                  type={"summary-no-padding"}
                                                  column={table_acc.column}
                                                  data={table_acc.data}
                                                  keyField={table_acc.keyField}
                                             />
                                        </div>
                                        <br></br>
                                        {/* <LabelBar type={"title"} title={"รถที่จับคู่"} />
                                        <div className="content" id="table-content" style={{ margin: "0", width: "100%", padding: "0 12px" }}>
                                             <Table
                                                  type={"summary-no-padding"}
                                                  column={table_confirm.column}
                                                  data={table_confirm.data}
                                                  keyField={table_confirm.keyField}
                                             />
                                        </div>
                                        <br></br>
                                        <LabelBar type={"title"} title={"ใบจองที่จับคู่"} />
                                        <div className="content" id="table-content" style={{ margin: "0", width: "100%", padding: "0 12px" }}>
                                             <Table
                                                  type={"summary-no-padding"}
                                                  column={table_preemption_confirm.column}
                                                  data={table_preemption_confirm.data}
                                                  keyField={table_preemption_confirm.keyField}
                                             />
                                        </div> */}
                                        {!this.state.match_status ? (
                                             <div className="button-group __submit row">
                                                  <Col xs={6}>
                                                       <Button variant="primary" onClick={this.onClickConfirmModal}>
                                                            {/* ยืนยันการจับคู่ */}
                                                            ยืนยันการติดตั้งอุปกรณ์
                                                       </Button>
                                                  </Col>
                                                  <Col xs={6}>
                                                       <Button variant="danger" onClick={this.onClickCancelModal}>
                                                            {/* ยกเลิกการจับคู่ */}
                                                            ยกเลิกการติดตั้งอุปกรณ์
                                                       </Button>
                                                  </Col>
                                             </div>
                                        ) : null}

                                        {this.state.match_status ? (
                                             <div>
                                                  <Breadcrumbs under={true} type={"success"} title={"จับคู่รถเรียบร้อย"} />
                                                  <div style={{ height: "80px" }}></div>
                                             </div>
                                        ) : null}
                                        {this.modalBeforeCanApp()}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
