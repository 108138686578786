import {
     ACard,
     ACardManage,
     ACardList,
     CarOrder,
     AddCarOrder,
     DetailCarOrder,
     AccOrder,
     AddAccOrder,
     DetailAccOrder,
     Print,
     Discount,
     GenerateDiscount,
     AdminList,
     SquardList,
     AddAdmin,
     Insurance,
     AddIns,
     DetailIns,
     SettingIns,
     Suppliers,
     AddSupplier,
     Leasing,
     AddLeasing,
     DetailLeasing,
     SettingSupplier,
     SupplierDetail,
     SupplierApprove,
     Car,
     DeliverCar,
     ManageCar,
     ListCar,
     CarModel,
     CarUpload,
     ManageCarModel,
     DetailPriceCarModel,
     Item,
     ManageItem,
     DetailItem,
     ItemsUpload,
     ApproveItem,
     DetailItemApprove,
     CarPayment,
     AccPayment,
     GeneratePaymentCar,
     GeneratePaymentAcc,
     OrderPayment,
     GeneratePaymentOrder,
     Tax,
     LsApp,
     GenerateTax,
     Promotion,
     PromotionDetail,
     AddPromotion,
     GenerateDeliverCar,
     MatchCar,
     MatchList,
     MatchConfirm,
     MakeUp,
     GenerateMakeUp,
     GenerateMatch,
     GenerateMatchCar,
     GenerateMatchList,
     GenerateMatchConfirm,
     Device,
     ListPreemtionReady,
     GenerateInstallReport,
     GenerateInstallList,
     GenerateInstallListAcc,
     InstallItem,
     InstallPreemption,
     FormDeliver,
     GenerateFormDeliver,
     ListRedLabel,
     GenerateListLabel,
     AddEditListLabel,
     LendListLabel,
     AcceptListLabel,
     RegisterLabel,
     GenerateRegisterLabel,
     SaveLabel,
     GenerateSaveLabel,
     CancelLabel,
     GenerateCancelLabel,
     OrderLeasing,
     GenerateOrderLeasing,
     SaleReport,
     PayReport,
     StockReport,
     LeasingReport,
     RegistrationReport,
     ItemReport,
     AccountReport,
     RevenueReport,
     ExecutiveReport,
     Commission,
     DetailCommission,
     GenerateDeliverAcc,
     PaymentsReport,
     LeasingPaymentList,
     generateLeasing,
     BranchList,
     BranchManage,
     ItemsModel,
     ManageItemModel,
     ManageItemEdit,
     InsuranceReport,
} from "../containers/index";

export const field_text = {
     permission: [
          {
               path: "/admin/a_card",
               arr: [
                    { c: ACard, p: "/admin/a_card" },
                    { c: ACardManage, p: "/admin/a_card/manage/:manage" },
                    { c: ACardList, p: "/admin/a_card/list" },
               ],
          },
          {
               path: "/admin/order/car",
               arr: [
                    { c: CarOrder, p: "/admin/order/car" },
                    { c: AddCarOrder, p: "/admin/order/car/detail/:manage/:id?" },
                    { c: DetailCarOrder, p: "/admin/order/car/detail:id" },
               ],
          },
          {
               path: "/admin/order/acc",
               arr: [
                    { c: AccOrder, p: "/admin/order/acc" },
                    { c: AddAccOrder, p: "/admin/order/acc/manage/:manage/:id?" },
                    { c: DetailAccOrder, p: "/admin/order/acc/detail:id" },
                    { c: Print, p: "/admin/print/:previous/:id" },
               ],
          },
          {
               path: "/admin/order/leasing",
               arr: [
                    { c: OrderLeasing, p: "/admin/order/leasing" },
                    { c: GenerateOrderLeasing, p: "/admin/order/leasing/:id" },
               ],
          },
          {
               path: "/admin/discount",
               arr: [
                    { c: Discount, p: "/admin/discount" },
                    { c: GenerateDiscount, p: "/admin/discount/:id" },
               ],
          },
          {
               path: "/admin/payment/order",
               arr: [
                    { c: OrderPayment, p: "/admin/payment/order" },
                    { c: GeneratePaymentOrder, p: "/admin/payment/order/:id" },
               ],
          },
          {
               path: "/admin/payment/car",
               arr: [
                    { c: CarPayment, p: "/admin/payment/car" },
                    { c: GeneratePaymentCar, p: "/admin/payment/product/car/:id" },
               ],
          },
          {
               path: "/admin/payment/leasing",
               arr: [
                    { c: LeasingPaymentList, p: "/admin/payment/leasing" },
                    { c: generateLeasing, p: "/admin/payment/leasing/:id" },
               ],
          },
          {
               path: "/admin/payment/acc",
               arr: [
                    { c: AccPayment, p: "/admin/payment/acc" },
                    { c: GeneratePaymentAcc, p: "/admin/payment/product/acc/:id" },
               ],
          },
          {
               path: "/admin/payment/tax",
               arr: [
                    { c: Tax, p: "/admin/payment/tax" },
                    { c: GenerateTax, p: "/admin/payment/tax/:supplier_id/:id/:car_turn_id?" },
               ],
          },
          {
               path: "/admin/payment/form",
               arr: [
                    { c: FormDeliver, p: "/admin/payment/form" },
                    { c: GenerateFormDeliver, p: "/admin/payment/form/:id" },
               ],
          },
          {
               path: "/admin/match/car",
               arr: [
                    { c: MatchCar, p: "/admin/match/car" },
                    { c: GenerateMatchCar, p: "/admin/match/car/:series/:model/:color" },
               ],
          },
          {
               path: "/admin/match/confirm",
               arr: [
                    { c: MatchConfirm, p: "/admin/match/confirm" },
                    { c: GenerateMatchConfirm, p: "/admin/match/confirm/:car_id/:order_id" },
               ],
          },
          {
               path: "/admin/match/makeup",
               arr: [
                    { c: MakeUp, p: "/admin/match/makeup" },
                    { c: GenerateMakeUp, p: "/admin/match/makeup/:car_id/:order_id" },
               ],
          },
          {
               path: "/admin/match/list",
               arr: [
                    { c: MatchList, p: "/admin/match/list" },
                    { c: GenerateMatchList, p: "/admin/match/list/:car_id/:order_id" },
               ],
          },
          {
               path: "/admin/install/list",
               arr: [
                    { c: ListPreemtionReady, p: "/admin/install/list" },
                    { c: GenerateInstallList, p: "/admin/install/list/car/:id" },
                    { c: GenerateInstallListAcc, p: "/admin/install/list/acc/:id" },
               ],
          },
          {
               path: "/admin/install/report",
               arr: [
                    { c: Device, p: "/admin/install/report" },
                    { c: GenerateInstallReport, p: "/admin/install/report/:supplier_code" },
                    { c: InstallItem, p: "/admin/install/report/item/:item_id/:sub_id" },
                    { c: InstallPreemption, p: "/admin/install/report/preemption/:preemption_type/:preemption_id/:sup_id" },
               ],
          },
          {
               path: "/admin/label/list",
               arr: [
                    { c: ListRedLabel, p: "/admin/label/list" },
                    { c: AddEditListLabel, p: "/admin/label/list/manage/:type/:label_id?" },
                    { c: GenerateListLabel, p: "/admin/label/list/:registra_id" },
                    { c: AcceptListLabel, p: "/admin/label/list/:labelhis_id/:manage" },
                    { c: AddEditListLabel, p: "/admin/label/list/:type/:empty_status/:registra_id" },
                    { c: LendListLabel, p: "/admin/label/lend/:label_id" },
               ],
          },
          {
               path: "/admin/label/cancel",
               arr: [
                    { c: CancelLabel, p: "/admin/label/cancel" },
                    { c: GenerateCancelLabel, p: "/admin/label/cancel/:id" },
               ],
          },
          {
               path: "/admin/label/register",
               arr: [
                    { c: RegisterLabel, p: "/admin/label/register" },
                    { c: GenerateRegisterLabel, p: "/admin/label/register/:id" },
               ],
          },
          {
               path: "/admin/label/save",
               arr: [
                    { c: SaveLabel, p: "/admin/label/save" },
                    { c: GenerateSaveLabel, p: "/admin/label/save/:id" },
               ],
          },
          {
               path: "/admin/deliver/car",
               arr: [
                    { c: DeliverCar, p: "/admin/deliver/car" },
                    { c: GenerateDeliverCar, p: "/admin/deliver/car/car/:id" },
                    { c: GenerateDeliverAcc, p: "/admin/deliver/car/acc/:id" },
               ],
          },
          //-----------------------------------------------------------------------------------//
          //-----------------------------------------------------------------------------------//
          //---------------------------------- Admin ------------------------------------------//
          //-----------------------------------------------------------------------------------//
          //-----------------------------------------------------------------------------------//
          {
               path: "/admin/user/admin-list",
               arr: [
                    { c: AdminList, p: "/admin/user/admin-list" },
                    { c: AddAdmin, p: "/admin/user/:manage/:list/:id?" },
               ],
          },
          {
               path: "/admin/user/squard-list",
               arr: [
                    { c: SquardList, p: "/admin/user/squard-list" },
                    { c: AddAdmin, p: "/admin/user/:manage/:list/:id?" },
               ],
          },
          {
               path: "/admin/branch",
               arr: [
                    { c: BranchList, p: "/admin/branch" },
                    { c: BranchManage, p: "/admin/branch/manage/:id?" },
               ],
          },
          { path: "/admin/report/sale", arr: [{ c: SaleReport, p: "/admin/report/sale" }] },
          { path: "/admin/report/pay", arr: [{ c: PayReport, p: "/admin/report/pay" }] },
          { path: "/admin/report/payments", arr: [{ c: PaymentsReport, p: "/admin/report/payments" }] },
          { path: "/admin/report/insurance", arr: [{ c: InsuranceReport, p: "/admin/report/insurance" }] },
          { path: "/admin/report/stock", arr: [{ c: StockReport, p: "/admin/report/stock" }] },
          { path: "/admin/report/leasing", arr: [{ c: LeasingReport, p: "/admin/report/leasing" }] },
          { path: "/admin/report/registration", arr: [{ c: RegistrationReport, p: "/admin/report/registration" }] },
          { path: "/admin/report/item", arr: [{ c: ItemReport, p: "/admin/report/item" }] },
          { path: "/admin/report/account", arr: [{ c: AccountReport, p: "/admin/report/account" }] },
          { path: "/admin/report/revenue", arr: [{ c: RevenueReport, p: "/admin/report/revenue" }] },
          { path: "/admin/report/executive", arr: [{ c: ExecutiveReport, p: "/admin/report/executive" }] },
          {
               path: "/admin/supplier/list",
               arr: [
                    { c: Suppliers, p: "/admin/supplier/list" },
                    { c: AddSupplier, p: "/admin/supplier/detail/:manage/:id?" },
                    { c: SupplierDetail, p: "/admin/supplier/detail:id" },
               ],
          },
          {
               path: "/admin/supplier/setting",
               arr: [
                    { c: SettingSupplier, p: "/admin/supplier/setting" },
                    { c: SupplierApprove, p: "/admin/supplier/setting/approve/:id" },
               ],
          },
          {
               path: "/admin/car-model",
               arr: [
                    { c: CarModel, p: "/admin/car-model" },
                    { c: ManageCarModel, p: "/admin/car-model/detail/:manage/:id?" },
                    { c: DetailPriceCarModel, p: "/admin/car-model/detail/:manage/price/:id?" },
               ],
          },
          {
               path: "/admin/car",
               arr: [
                    { c: Car, p: "/admin/car" },
                    { c: ManageCar, p: "/admin/car/:manage/:id?" },
                    { c: CarUpload, p: "/admin/car-upload" },
                    { c: ListCar, p: "/admin/car/search/list/car" },
               ],
          },
          {
               path: "/admin/item",
               arr: [
                    // { c: Item, p: "/admin/item" },
                    //-----------------------------------------------------------------//
                    { c: ItemsModel, p: "/admin/item" },
                    { c: ManageItemModel, p: "/admin/item-model/detail/:id?" },
                    { c: ManageItemEdit, p: "/admin/item-model/detail/:manage/:id?/:supid?" },
                    //-----------------------------------------------------------------//
                    { c: ManageItem, p: "/admin/item/detail/:manage/:id?" },
                    { c: DetailItem, p: "/admin/item/detail:id" },
                    { c: ItemsUpload, p: "/admin/items-upload" },
               ],
          },
          {
               path: "/admin/item/approve",
               arr: [
                    { c: ApproveItem, p: "/admin/item/approve" },
                    { c: DetailItemApprove, p: "/admin/item/approve/:id" },
               ],
          },
          {
               path: "/admin/leasing",
               arr: [
                    { c: Leasing, p: "/admin/leasing" },
                    { c: AddLeasing, p: "/admin/leasing/detail/:manage/:id?" },
                    { c: DetailLeasing, p: "/admin/leasing/detail:id" },
               ],
          },
          {
               path: "/admin/insurance",
               arr: [
                    { c: Insurance, p: "/admin/insurance" },
                    { c: AddIns, p: "/admin/insurance/detail/:manage/:id?" },
                    { c: DetailIns, p: "/admin/insurance/detail:id" },
               ],
          },
          {
               path: "/admin/promotion",
               arr: [
                    { c: Promotion, p: "/admin/promotion" },
                    { c: PromotionDetail, p: "/admin/promotion/detail/:id?" },
                    { c: AddPromotion, p: "/admin/promotion/detail/:manage/:id?/:this?" },
               ],
          },
          {
               path: "/admin/commission",
               arr: [
                    { c: Commission, p: "/admin/commission" },
                    { c: DetailCommission, p: "/admin/commission/detail" },
               ],
          },
     ],
     side_bar_sales: [
          {
               sub: false,
               active: "acard",
               path: "/admin/a_card",
               click: "acard",
               label: "A-Card",
          },
          {
               sub: true,
               title: "จอง รถยนต์/อุปกรณ์",
               active: "order",
               menu: [
                    { active: "a1", path: "/admin/order/car", click: "order_a1", label: "การจองรถยนต์" },
                    { active: "a2", path: "/admin/order/acc", click: "order_a2", label: "การขายอุปกรณ์" },
                    { active: "a3", path: "/admin/order/leasing", click: "order_a3", label: "การอนุมัติ Leasing" },
               ],
          },
          {
               sub: false,
               active: "discount",
               path: "/admin/discount",
               click: "discount",
               label: "อนุมัติส่วนลดของใบจอง",
          },
          {
               sub: true,
               title: "การเงิน",
               active: "finance",
               menu: [
                    { active: "a1", path: "/admin/payment/order", click: "finance_a1", label: "การรับเงินจอง" },
                    { active: "a2", path: "/admin/payment/car", click: "finance_a2", label: "รับชำระเงินค่ารถ" },
                    { active: "a3", path: "/admin/payment/leasing", click: "finance_a3", label: "รับเงินจากไฟแนนซ์" },
                    { active: "a4", path: "/admin/payment/acc", click: "finance_a4", label: "รับชำระเงินค่าอุปกรณ์" },
                    { active: "a5", path: "/admin/payment/tax", click: "finance_a5", label: "ใบกำกับภาษีกรณีเทิร์นสินค้า" },
                    { active: "a6", path: "/admin/payment/form", click: "finance_a6", label: "แบบฟอร์มสำหรับส่งมอบ" },
               ],
          },
          {
               sub: true,
               title: "จับคู่รถ",
               active: "match",
               menu: [
                    { active: "a1", path: "/admin/match/car", click: "match_a1", label: "จับคู่รถให้ลูกค้า" },
                    { active: "a2", path: "/admin/match/confirm", click: "match_a2", label: "ยืนยันการจับคู่" },
                    { active: "a4", path: "/admin/match/makeup", click: "match_a4", label: "ยืนยันการส่งแต่ง" },
                    { active: "a3", path: "/admin/match/list", click: "match_a3", label: "รายการที่จับคู่แล้ว" },
               ],
          },
          {
               sub: true,
               title: "ติดตั้งอุปกรณ์",
               active: "install",
               menu: [
                    { active: "a1", path: "/admin/install/list", click: "install_a1", label: "รายการใบจองที่พร้อมติดตั้ง" },
                    { active: "a2", path: "/admin/install/report", click: "install_a2", label: "รายงานติดตั้งอุปกรณ์" },
               ],
          },
          {
               sub: true,
               title: "ป้ายแดง",
               active: "label",
               menu: [
                    { active: "a1", path: "/admin/label/list", click: "label_a1", label: "รายการป้ายแดง" },
                    { active: "a4", path: "/admin/label/cancel", click: "label_a4", label: "ยกเลิกป้ายแดง" },
               ],
          },
          {
               sub: true,
               title: "จดทะเบียน",
               active: "label",
               menu: [
                    { active: "a2", path: "/admin/label/register", click: "label_a2", label: "การจดทะเบียน" },
                    { active: "a3", path: "/admin/label/save", click: "label_a3", label: "บันทึกรับป้ายและเล่มในระบบ" },
               ],
          },
          {
               sub: false,
               active: "deliver",
               path: "/admin/deliver/car",
               click: "deliver",
               label: "ส่งมอบรถ",
          },
     ],
     side_bar_system: [
          {
               sub: true,
               title: "Admin",
               active: "admin",
               menu: [
                    { active: "a1", path: "/admin/user/admin-list", click: "admin_a1", label: "รายการผู้ใช้งาน" },
                    { active: "a2", path: "/admin/user/squard-list", click: "admin_a2", label: "รายการทีม" },
                    { active: "a3", path: "/admin/branch", click: "admin_a3", label: "รายการสาขา" },
               ],
          },
          {
               sub: true,
               title: "รายงาน",
               active: "report",
               menu: [
                    { active: "a1", path: "/admin/report/sale", click: "report_a1", label: "รายงานการขาย" },
                    { active: "a2", path: "/admin/report/pay", click: "report_a2", label: "รายงานรับเงิน" },
                    { active: "a10", path: "/admin/report/payments", click: "report_a10", label: "รายงานการจ่ายเงิน" },
                    { active: "a11", path: "/admin/report/insurance", click: "report_a11", label: "รายงานประกันภัย" },
                    { active: "a3", path: "/admin/report/stock", click: "report_a3", label: "รายงานสต็อก" },
                    { active: "a4", path: "/admin/report/leasing", click: "report_a4", label: "รายงาน Leasing" },
                    { active: "a5", path: "/admin/report/registration", click: "report_a5", label: "รายงานการจดทะเบียน" },
                    { active: "a6", path: "/admin/report/item", click: "report_a6", label: "รายงานอุปกรณ์" },
                    { active: "a7", path: "/admin/report/account", click: "report_a7", label: "รายงานบัญชี" },
                    { active: "a8", path: "/admin/report/revenue", click: "report_a8", label: "รายงานสรรพากร" },
                    { active: "a9", path: "/admin/report/executive", click: "report_a9", label: "รายงานผู้บริหาร" },
               ],
          },
          {
               sub: true,
               title: "Supplier",
               active: "supplier",
               menu: [
                    { active: "a1", path: "/admin/supplier/list", click: "supplier_a1", label: "รายการ Supplier" },
                    { active: "a2", path: "/admin/supplier/setting", click: "supplier_a2", label: "การอนุมัติ Supplier" },
               ],
          },
          {
               sub: true,
               title: "รถยนต์",
               active: "car",
               menu: [
                    { active: "a1", path: "/admin/car-model", click: "car_a1", label: "รุ่นรถ" },
                    { active: "a2", path: "/admin/car", click: "car_a2", label: "สต็อกรถยนต์" },
               ],
          },
          {
               sub: true,
               title: "Item",
               active: "item",
               menu: [
                    { active: "a1", path: "/admin/item", click: "item_a1", label: "รายการ Item" },
                    { active: "a2", path: "/admin/item/approve", click: "item_a2", label: "การอนุมัติ Item" },
               ],
          },
          {
               sub: false,
               active: "leasing",
               path: "/admin/leasing",
               click: "leasing",
               label: "Leasing",
          },
          {
               sub: false,
               active: "insurance",
               path: "/admin/insurance",
               click: "insurance",
               label: "ประกัน",
          },
          {
               sub: false,
               active: "promotion",
               path: "/admin/promotion",
               click: "promotion",
               label: "โปรโมชั่น",
          },
          {
               sub: false,
               active: "commission",
               path: "/admin/commission",
               click: "commission",
               label: "ค่า Commission",
          },
     ],
};
export const detail_car = [
     // {
     //      type: "text",
     //      title: "REGION",
     //      name: "REGION",
     //      placeholder: "REGION",
     //      value: "REGION",
     // },
     {
          type: "text",
          title: "DEALER_CODE",
          name: "DEALER_CODE",
          placeholder: "DEALER_CODE",
          value: "DEALER_CODE",
     },
     {
          type: "text",
          title: "DEALER_NAME",
          name: "DEALER_NAME",
          placeholder: "DEALER_NAME",
          value: "DEALER_NAME",
     },
     {
          type: "date",
          title: "INVOICE_DATE",
          name: "INVOICE_DATE",
          placeholder: "เลือกวันที่",
          value: "INVOICE_DATE",
     },
     {
          type: "text",
          title: "INVOICE_NO",
          name: "INVOICE_NO",
          placeholder: "INVOICE_NO",
          value: "INVOICE_NO",
     },
     {
          type: "date",
          title: "DUE_DATE",
          name: "DUE_DATE",
          placeholder: "เลือกวันที่",
          value: "DUE_DATE",
     },
     // {
     //      type: "text",
     //      title: "PAYMENT_TERM",
     //      name: "PAYMENT_TERM",
     //      placeholder: "PAYMENT_TERM",
     //      value: "PAYMENT_TERM",
     // },
     // {
     //      type: "date",
     //      title: "SCH_DATE",
     //      name: "SCH_DATE",
     //      placeholder: "เลือกวันที่",
     //      value: "SCH_DATE",
     // },
     // {
     //      type: "date",
     //      title: "FINAL_ASSIGN",
     //      name: "FINAL_ASSIGN",
     //      placeholder: "เลือกวันที่",
     //      value: "FINAL_ASSIGN",
     // },
     // {
     //      type: "date",
     //      title: "TMT_DEPARTURE_DATE",
     //      name: "TMT_DEPARTURE_DATE",
     //      placeholder: "เลือกวันที่",
     //      value: "TMT_DEPARTURE_DATE",
     // },
];
