import React, { Component } from "react";
import { GET } from "api/index";
import { Breadcrumbs, Table, Search, Skeleton, Image } from "components/index";
import dayjs from "dayjs";
import { getToken } from "../../../../../static/function";

export default class OrderPayment extends Component {
     constructor(props) {
          super(props);
          this.state = {
               search: {
                    orp_status: "รอรับเงินจอง",
               },
               order: [],
               order_search: [],
               branchs: [],
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getOrder();
          this.getBranchs();
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
     };

     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          // console.log('search', search)
          this.setState({
               search,
          });
     };

     getBranchs = async () => {
          try {
               let branchs = await GET("/ps/get_branchs");
               branchs.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branchs: [...branchs] });
          } catch (err) {
               console.log(err);
          }
     };

     getOrder = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               // this.setState({ order: [...await GET('/order/get_order_payment')] })
               let res = await GET("/order/get_order_payment");
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search.filter(
                    (el) => el.orp_status === "รอรับเงินจอง" && el.status === "อนุมัติ" && el.acept_status === "ใบจองอนุมัติแล้ว"
               );
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     /*----------------------Search Data--------------------------*/
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = [];
          if (search.orp_status === "รอรับเงินจอง") {
               newSearch = order_search
                    .filter(
                         (el) =>
                              (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                              (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el)
                    )
                    .filter((el) => el.orp_status === "รอรับเงินจอง" && el.status === "อนุมัติ" && el.acept_status === "ใบจองอนุมัติแล้ว");
          } else if (search.orp_status === "รับเงินจองแล้ว") {
               newSearch = order_search
                    .filter(
                         (el) =>
                              (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                              (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el)
                    )
                    .filter((el) => el.orp_code);
          } else {
               newSearch = order_search.filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el)
               );
          }
          newSearch = newSearch.filter(
               (el) =>
                    (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                    // (search.orp_status !== "ทั้งหมด" ? String(el.orp_status).indexOf(String(search.orp_status)) > -1 : el) &&
                    (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                    (search.userinfo_a_card ? String(el.userinfo_a_card).indexOf(String(search.userinfo_a_card)) > -1 : el)
          );
          this.setState({ newSearch });
     };
     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     lableFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
          } else {
               return <p>-</p>;
          }
     };

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          // return <a onClick={() => window.location.assign(`/admin/payment/order/${row.orp_status}/${row.order_id}`)}>ทำรายการ</a>;
          return <a onClick={() => window.location.assign(`/admin/payment/order/${row.order_id}`)}>ทำรายการ</a>;
     }

     render() {
          let { order, branchs, search, newSearch } = this.state;
          const table = {
               column: [
                    {
                         dataField: "list_item",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         // headerClasses: "header-custom __left",
                         formatter: this.lableFormatter,
                    },

                    {
                         dataField: "orp_status",
                         text: "สถานะเงินจอง",
                         // formatter: this.lableFormatter,
                    },
                    {
                         dataField: search.orp_status === "รับเงินจองแล้ว" ? "orp_code" : "userinfo_a_card",
                         text: search.orp_status === "รับเงินจองแล้ว" ? "เลขที่ TB" : "A-Card No.",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "branch_name",
                         text: "โชว์รูม",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "status",
                         text: "การอนุมัติ",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "preemption_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "การรับเงินจอง" }]} active={0} button={""} />
                    <div className="content __main-panel oderpayment">
                         <Search
                              type={"oderpayment"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   branchs: branchs,
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
