import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel } from "components/index";
import { Form, Button, Col, Row } from "react-bootstrap";
// import swal from "sweetalert";
import swal from "@sweetalert/with-react";
import { POST, GET } from "api/index";
import { useEffect } from "react";
import { Empty } from "antd";
import { getToken } from "../../../../../static/function";

function ScrollToTopOnMount() {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

export default class AddAccOrder extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               previous_path: this.props.match.params.manage,
               id: this.props.match.params.id,
               title: [],
               show: false,
               order: {
                    item: [],
                    additem: [],
                    deleteitem: [],
               },
               provice: [],
               amphures: [],
               amphuresOwner: [],
               subdistrictOwner: [],
               amphuresContact: [],
               subdistrictContact: [],
               amphuresInfo: [],
               subdistrictInfo: [],
               series: [],
               model: [],
               color: [],
               acc: [],
               item: [],
               deleteitem: [],
               confirm: false,
               branch: [],
               acard: [],
               total_state: 0,
               seller: [],
               user: {},
          };
     }

     componentDidMount = async () => {
          await this.setState({ user: getToken() });

          await this.getSeller();
          if (this.state.previous_path === "edit") {
               this.getOrderDetail();
          } else this.getAddress();
          this.getACard();
          // this.getProvice();
          this.setPath();
          this.getSeriesCars();
          // this.getAccessories();
          this.getBranch();
     };
     /* Set Breadcrumbs */
     setPath = (nextStep) => {
          let { previous_path, title, id } = this.state;
          if (nextStep) {
               title.push({ title: "ตรวจสอบข้อมูล" });
          } else {
               switch (previous_path) {
                    case "add":
                         title = [
                              {
                                   title: "การขายอุปกรณ์",
                                   onClick: () => window.location.assign("/admin/order/acc"),
                              },
                              {
                                   title: "เพิ่มใบสั่งขายอุปกรณ์",
                                   onClick: () => this.handleNext(false),
                              },
                         ];
                         break;
                    case "edit":
                         title = [
                              {
                                   title: "การขายอุปกรณ์",
                                   onClick: () => window.location.assign("/admin/order/acc"),
                              },
                              {
                                   title: `ใบสั่งขายอุปกรณ์ (${id})`,
                                   onClick: () => window.location.assign(`/admin/order/acc/detail${id}`),
                              },
                              {
                                   title: "แก้ไข",
                              },
                         ];
                         // this.getOrderDetail();
                         break;
                    default:
                         break;
               }
          }
          this.setState({ title: title });
     };
     /* ------------------------- Get ---------------------------- */

     getOrderDetail = async (province) => {
          let { id, provice, amphuresOwner, user, previous_path } = this.state;
          try {
               // get order
               let order = await POST("/order/this_acc_order", { acorder_id: id });

               // province
               this.getAddress();
               // district
               this.getAddress(order.acorder_province);
               // sub_district
               this.getAddress(order.acorder_province, order.acorder_district);

               // // get district
               // let province_id = await Number(province?.find((e) => e.name_th === order.acorder_province)?.id);
               // await this.getamphures(province_id, "acorder_province");
               // // get sub district
               // let getamphures = await POST("/address/getdistrict", { province_id });
               // let amphure_id = await Number(getamphures?.find((e) => e.name_th === order.acorder_district)?.id);
               // this.getSubdistrict(amphure_id, "acorder_district");

               this.getModel(order.series_id);
               this.getColors(order.model_id);
               // this.getAccessories(order);
               this.getAcc(order);

               let itemOld = [];
               order.item.map((el) => {
                    return itemOld.push(el);
               });
               this.setState({ order: { ...order, acard_customer_type: order.prefix ? "บุคคลทั่วไป" : "นิติบุคคล" }, itemOld });
          } catch (error) {
               console.log(error);
          }
     };

     getSeller = async () => {
          let { order, user, previous_path } = this.state;
          let sell_fullname = `${user?.firstname} ${user?.lastname}`;
          try {
               let seller = await GET("/ps/sellers");

               if (previous_path === "add") {
                    // order.branch_id = user.branch_id;
                    order.saler_name = seller.find((el) => el.name === sell_fullname)?.user_id;
                    this.setState({ order });
               }

               this.setState({ seller: seller });
          } catch (err) {
               console.log(err);
          }
     };

     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               // console.log("getBranch", getBranch);
               // getBranch.unshift({ branch_id: 0, branch_name: "เลือกสาขา" });
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getAddress = async (province_pass, district_pass, sub_district_pass) => {
          try {
               let { order } = this.state;
               let getAddress = await POST("/aos/search_address", {
                    province: province_pass,
                    district: district_pass,
                    sub_district: sub_district_pass,
               });
               let type = getAddress.type;
               let result = getAddress.array;
               if (type === "No") {
                    this.setState({ provice: result });
               } else if (type === "province") {
                    this.setState({ amphuresInfo: result });
               } else if (type === "district") {
                    this.setState({ subdistrictInfo: result });
               } else if (type === "sub_district") {
                    order.acorder_zipcode = result[0].postcode;
                    this.setState({ postcode: result[0].postcode, order });
               }
          } catch (error) {
               // console.log("error", error);
          }
     };

     getProvice = async () => {
          try {
               let getprovice = await GET("/address/getprovince");
               this.setState({ provice: [...getprovice] });
               this.getOrderDetail(getprovice);
          } catch (error) {
               console.log("error ", error);
          }
     };
     getamphures = async (province_id, name) => {
          try {
               let getamphures = await POST("/address/getdistrict", { province_id });

               if (name === "owner_province") {
                    this.setState({ amphuresOwner: [...getamphures] });
               } else if (name === "contact_province") {
                    this.setState({ amphuresContact: [...getamphures] });
               } else if (name === "acorder_province") {
                    this.setState({ amphuresInfo: [...getamphures] });
               }
          } catch (error) {
               console.log("error ", error);
          }
     };
     getSubdistrict = async (amphure_id, name) => {
          try {
               let getSubdistrict = await POST("/address/getsubdistrict", {
                    amphure_id,
               });
               if (name === "owner_district") {
                    this.setState({ subdistrictOwner: [...getSubdistrict] });
               } else if (name === "contact_district") {
                    this.setState({ subdistrictContact: [...getSubdistrict] });
               } else if (name === "acorder_district") {
                    this.setState({ subdistrictInfo: [...getSubdistrict] });
               }
          } catch (error) {
               console.log("error ", error);
          }
     };
     getSeriesCars = async () => {
          try {
               let getseries = await GET("/car/series");
               this.setState({ series: [...getseries] });
          } catch (error) {
               console.log("error ", error);
          }
     };
     getModel = async (series_id) => {
          try {
               let getmodel = await POST("/car/model", { series_id });
               this.setState({ model: [...getmodel] });
          } catch (error) {
               console.log("error ", error);
          }
     };
     getColors = async (model_id) => {
          try {
               let getcolor = await POST("/car/color", { model_id });
               this.setState({ color: [...getcolor] });
          } catch (error) {
               console.log("error ", error);
          }
     };
     getAccessories = async (order) => {
          let { previous_path } = this.state;
          try {
               let getacc = await GET("/item/accessories");
               this.setState({ acc: [...getacc] });
               if (previous_path === "edit") {
                    order.item.forEach((element) => {
                         let index = getacc
                              .map(function (el) {
                                   return el.items_id;
                              })
                              .indexOf(element.items_id);
                         getacc.splice(index, 1);
                    });
                    this.setState({
                         acc: [...getacc],
                    });
               }
          } catch (error) {
               console.log("error ", error);
          }
     };

     getAcc = async (order) => {
          try {
               let getacc = await POST("/aos/get_acc", {
                    series_id: Number(order.series_id),
                    model_id: Number(order.model_id),
                    color_id: Number(order.color_id),
               });
               this.setState({
                    acc: getacc.map((el) => ({
                         ...el,
                         items_price: el.price_list ? Number(JSON.parse(el.price_list).price) || 0 : 0,
                         price: el.price_list ? Number(JSON.parse(el.price_list).price) || 0 : 0,
                    })),
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getACard = async () => {
          try {
               let res = await GET("/aos/get_acard");
               this.setState({ acard: res });
          } catch (err) {
               console.log(err);
          }
     };

     /* ------------------------ OnClick ---------------------------- */
     addAccOrder = async () => {
          let { order, previous_path, deleteitem, itemOld } = this.state;

          // order.branch_id = order?.branch_id === 99 ? 1 : order?.branch_id === "99" ? 1 : order?.branch_id;
          // console.log("order", order);
          if (previous_path === "add") {
               let result = await POST("/order/add_acc", order);
               if (result) {
                    swal({
                         icon: "success",
                         title: "ใบสั่งขายอุปกรณ์ได้บันทึกเรียบร้อยแล้ว",
                         button: "เสร็จสิ้น",
                         closeOnClickOutside: false,
                    }).then((value) => {
                         window.location.assign(`/admin/order/acc/detail${result.acorder_id}`);
                    });
               }
          } else if (previous_path === "edit") {
               let additem = [];
               order.item.forEach((el, i) => {
                    if (!el.acitem_id) {
                         additem.push(el);
                    }
               });
               order.item = itemOld;
               order.additem = additem;
               order.deleteitem = deleteitem;
               try {
                    let edit = await POST("/order/edit_acc_order", order);
                    if (edit) {
                         swal({
                              icon: "success",
                              title: "ข้อมูลถูกแก้ไขแล้ว",
                              button: "เสร็จสิ้น",
                              closeOnClickOutside: false,
                         }).then((value) => {
                              window.location.assign(`/admin/order/acc/detail${order.acorder_id}`);
                         });
                    }
               } catch (error) {
                    console.log(`error`, error);
               }
          }
     };

     /* ------------------------ Handle ----------------------------- */
     /* Handle [back, next] confirm state */
     handleNext = async (step) => {
          this.setState({
               confirm: step,
          });
          this.setPath(step);
     };
     /* Handle Validate field on submit */
     handleConfirm = (event) => {
          let { order } = this.state;
          // order.branch_id = order?.branch_id === 99 ? 1 : order?.branch_id === "99" ? 1 : order?.branch_id;
          let nextStep;
          event.preventDefault();

          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรอกให้ครบ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({
                    validated: true,
               });
               event.preventDefault();
               event.stopPropagation();
          } else {
               event.preventDefault();
               nextStep = true;
               this.setState({
                    validated: true,
                    confirm: true,
               });
          }

          this.setPath(nextStep);
     };
     /* Cofirm Order */
     handleSubmit = async (event) => {
          let { order } = this.state;

          order.acorder_price = Number(order?.acorder_price || 0);
          order.acorder_vat = Number(order?.acorder_vat || 0);

          event.preventDefault();
          try {
               await POST("/order/add", order);
               swal({
                    text: "เพิ่มรายการขายอุปกรณ์เรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               });
          } catch (error) {
               swal({
                    title: "failed",
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     calculateFinalPrice = () => {
          let { order } = this.state;
          let final_price = Number(order?.acorder_price || 0) + Number(order?.acorder_vat || 0);
          swal({
               text: "Calculate Final Price",
               buttons: {
                    submit: "เสร็จสิ้น",
               },
               content: (
                    <div>
                         <strong>{Number(final_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                         <p>บาท</p>
                    </div>
               ),
          });
     };
     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { order } = this.state;
          order[name] = value;

          if (name === "series_id") {
               this.getModel(value);
          } else if (name === "model_id") {
               this.getColors(value);
          }

          if (name === "color_id") {
               this.getAcc(order);
          }

          if (name === "branch_id") {
               order[name] = Number(value);
          }
          this.setState({ order });
     };

     handleChangeAutoCompleteSeller = (e) => {
          let { order, seller } = this.state;
          let saler_name_string = e[0];

          order.saler_name = seller.find((el) => el.name === saler_name_string)?.user_id;

          this.setState({ order });
     };

     handleChangeAutoComplete = async (e) => {
          let { order, acard, provice, seller } = this.state;
          if (e.length !== 0) {
               let arr = e[0].split(" ");
               let acard_no = arr[0];
               let acard_detail = acard.find((el) => el.acard_no === acard_no);
               order.acorder_acard = acard_no;
               // order.branch_id = acard_detail?.branch_id === 99 ? 1 : acard_detail?.branch_id === "99" ? 1 : acard_detail?.branch_id;
               order.acorder_name = acard_detail?.acard_fullname;
               order.acorder_tel = acard_detail?.acard_tel;
               order.acorder_province = acard_detail?.acard_province;
               order.acorder_idcard = acard_detail?.userinfo_idcard;
               order.acorder_address = acard_detail?.userinfo_address;
               order.saler_name = seller?.find((el) => el.name === acard_detail?.acard_saler_name)?.user_id;
               order.prefix = acard_detail?.acard_prefix;
               order.series_id = acard_detail.series_id;
               await this.getModel(acard_detail.series_id);
               order.model_id = acard_detail.model_id;
               await this.getColors(acard_detail.model_id);
               order.color_id = acard_detail.color_id;
               let province_id = provice?.find((el) => el.name_th === acard_detail?.acard_province)?.id;
               await this.getAddress(acard_detail.acard_province);
               await this.getAddress(acard_detail.acard_province, acard_detail.acard_district);
               await this.getAddress(acard_detail.acard_province, acard_detail.acard_district, acard_detail.acard_sub_district);
               // await this.getamphures(province_id, "acorder_province");
               order.acorder_district = acard_detail?.acard_district;

               let district_id = this.state.amphuresInfo?.find((el) => el.name_th === acard_detail?.acard_district)?.id;
               // await this.getSubdistrict(district_id, "acorder_district");
               order.acorder_sub_district = acard_detail?.acard_sub_district;

               order.acorder_zipcode = acard_detail?.acard_zipcode;
               order.acard_customer_type = acard_detail?.acard_customer_type;
               this.setState({ order });
               this.getAcc(order);
          }
     };

     findBranchName = () => {
          return null;
     };
     /* Handle Data input change */
     handleChangeNumber = (event, name) => {
          let { order } = this.state;
          order[name] = event.value;
     };
     /* Handle address */
     handleChangeAddress = ({ target: { name, value } }) => {
          let { order, provice, amphuresOwner, subdistrictOwner, amphuresContact, subdistrictContact, amphuresInfo, subdistrictInfo } = this.state;
          if (name === "owner_province") {
               /// owner
               order[name] = provice.find((e) => e.id === Number(value)).name_th;
               order.owner_district = "";
               order.owner_sub_district = "";
               this.getamphures(value, name);
          } else if (name === "owner_district") {
               order[name] = amphuresOwner.find((e) => e.id === Number(value)).name_th;
               this.getSubdistrict(value, name);
          } else if (name === "owner_sub_district") {
               order[name] = subdistrictOwner.find((e) => e.id === value).name_th;
               order.owner_zipcode = subdistrictOwner.find((e) => e.id === value).zip_code;
          }
          /////////////////////////////////////////////////////////////////////////////
          else if (name === "contact_province") {
               /// contact
               order[name] = provice.find((e) => e.id === Number(value)).name_th;
               order.contact_district = "";
               order.contact_sub_district = "";
               this.getamphures(value, name);
          } else if (name === "contact_district") {
               order[name] = amphuresContact.find((e) => e.id === Number(value)).name_th;
               this.getSubdistrict(value, name);
          } else if (name === "contact_sub_district") {
               order[name] = subdistrictContact.find((e) => e.id === value).name_th;
               order.contact_zipcode = subdistrictContact.find((e) => e.id === value).zip_code;
          }
          /////////////////////////////////////////////////////////////////////////////
          else if (name === "acorder_province") {
               /// info
               order[name] = value;
               order.acorder_district = "";
               order.acorder_sub_district = "";
               order.acorder_zipcode = "";
               // this.getamphures(value, name);
               this.getAddress(value);
          } else if (name === "acorder_district") {
               order[name] = value;
               order.acorder_sub_district = "";
               order.acorder_zipcode = "";
               this.getAddress(order.acorder_province, value);
               // this.getSubdistrict(value, name);
          } else if (name === "acorder_sub_district") {
               order[name] = value;
               order.acorder_zipcode = "";
               this.getAddress(order.acorder_province, order.acorder_district, value);
               // order.acorder_zipcode = this.state.postcode;
               // console.log("order.acorder_zipcode", this.state.postcode);
          }

          this.setState({ order });
     };

     handleChangeItems = (checked, row, rowIndex) => {
          let { item } = this.state;
          if (checked) {
               item.push(row.items_id);
               this.setState({ item });
          } else {
               item.splice(
                    item.findIndex((el) => el === row.items_id),
                    1
               );
               this.setState({ item });
          }
     };
     /* Handle modal close */
     onAddItem = () => {
          let { item, acc, order } = this.state;
          let itemnew = [];
          let total = 0;
          if (item) {
               acc.map((element) => {
                    if (item.find((el) => el === element.items_id)) {
                         order.item.push(element);
                    }
               });
               item.forEach((element) => {
                    let index = acc
                         .map((el) => {
                              return el.items_id;
                         })
                         .indexOf(element);
                    acc.splice(index, 1);
               });

               order.item.map((el, i) => {
                    total += el.items_price;
               });

               order.acorder_price = total !== 0 ? total : 0;
               order.acorder_vat = total !== 0 ? ((total * 100) / 107) * 0.07 : 0;
          }
          this.setState({
               show: false,
               order,
               item: [],
          });
     };

     removeItem = (row, rowIndex) => {
          let { acc, order, deleteitem, item } = this.state;
          let total = 0;
          let acc_current = acc?.find((el) => el.items_name === row.items_name);

          if (!acc_current) acc.push({ ...row, items_name: row.items_name });
          deleteitem.push(row.acitem_id);
          order.item.splice(rowIndex, 1);

          order.item.map((el, i) => {
               total += el.items_price;
          });

          order.acorder_price = total === 0 ? 0 : total;
          order.acorder_vat = total !== 0 ? ((total * 100) / 107) * 0.07 : 0;

          this.setState({ order, deleteitem, item, total_state: total });
     };

     /* Handle Add function */
     handleClick = () => {
          let { show } = this.state;
          this.setState({
               show: !show,
          });
     };

     /* Handle modal close */
     onHide = () => {
          let { item } = this.state;

          this.setState({
               show: false,
               item: [],
          });
     };

     /* ------------------------- Formatter ------------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     removeBtnFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button variant="danger" onClick={() => this.removeItem(row, rowIndex)}>
                    Remove
               </Button>
          );
     };
     parseFloatFormatter(cell, row) {
          let new_cell = Number(cell).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     }
     unitFormatter() {
          return <p>บาท</p>;
     }
     noFormatter(cell, row, rowIndex, formatExtraData) {
          return <p>{rowIndex + 1}</p>;
     }
     totalPrice = (columnData) => {
          let total = columnData.reduce((acc, item) => acc + item, 0);
          total = Number(total).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          return total;
     };

     checkboxFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <>
                    {/* <LabelBar
                         type={"checkbox"}
                         label={[{ value: rowIndex, name: null, id: `checkboxBonus${rowIndex}` }]}
                         handleChangeText={(e) => this.onChangeCheckboxAddBonus(e.target.checked, row)}
                    /> */}
                    <LabelBar
                         type={"checkbox"}
                         // label={[{ value: row.items_id }]}
                         label={[{ value: rowIndex, name: null, id: `checkboxAccBuy${rowIndex}` }]}
                         handleChangeText={(e) => this.handleChangeItems(e.target.checked, row)}
                    />
               </>
          );
          // <Field type={"checkbox"} name={`items_id`} value={row.items_id} handleChange={(e) => this.handleChangeItems(e.target.checked, row, rowIndex)} />;
     };

     render() {
          let prefix = ["นาย", "นางสาว", "นาง"];
          let { validated, order, title, show, confirm, acc, previous_path, user, seller, acard_detail } = this.state;
          const acc_service = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __btn-ss",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ Car accessories",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "items_price",
                         text: "ราคา(บาท)",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeBtnFormatter,
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: order?.item ? order.item : [],
               keyField: "no",
          };
          // console.log("order?.item", order?.item);
          const add_acc_service = {
               column: [
                    {
                         dataField: "items_id",
                         text: "เลือก",
                         headerClasses: "header-custom __left __btn-ss",
                         formatter: this.checkboxFormatter,
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ Car accessories",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "price",
                         text: "ราคาขาย (บาท)",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
               ],
               data: acc ? acc : [],
               keyField: "items_id",
          };
          // console.log(
          //      " acc",
          //      acc.map((el) => ({ ...el, price: JSON.parse(el.price_list).price }))
          // );
          const conf_acc = {
               column: [
                    {
                         dataField: "items_id",
                         text: "ลำดับ ",
                         footer: "",
                         formatter: this.noFormatter,
                         align: "center",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการของแถม",
                         footer: "รวม",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย",
                         footer: this.totalPrice,
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
               ],
               data: [],
               data: order?.item ? order.item : [],
               keyField: "no",
          };
          // console.log("this.state.color", this.state.color, order?.color_id);
          switch (confirm) {
               case true:
                    return (
                         <div className="panel">
                              <ScrollToTopOnMount />
                              {title ? (
                                   <Breadcrumbs
                                        title={title}
                                        active={title.length - 1}
                                        button={""}
                                        // onClick={() => this.handleNext(false)}
                                   />
                              ) : null}
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  {/* รายละเอียดผู้ซื้อ */}
                                                  <>
                                                       <LabelBar type={"title"} title={"รายละเอียดผู้สั่งจอง"} />
                                                       <div className="-padding">
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "A-Card No. : ",
                                                                           detail: order?.acorder_acard || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "สาขา : ",
                                                                           detail:
                                                                                this.state.branch?.find(
                                                                                     (e) => e.branch_id === Number(order?.branch_id)
                                                                                )?.branch_name || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ชื่อ-นามสกุล พนักงงานขาย :",
                                                                           detail: order?.saler_name
                                                                                ? this.state.seller.find((el) => el.user_id === order?.saler_name)
                                                                                       ?.name
                                                                                : "-",
                                                                      }}
                                                                      md={12}
                                                                 />

                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ชื่อ-นามสกุล ผู้สั่งจอง :",
                                                                           detail: order?.acorder_name || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "หมายเลขประจำตัว :",
                                                                           detail: order?.acorder_idcard || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "หมายเลขโทรศัพท์ :",
                                                                           detail: order?.acorder_tel ? this.telFormat(order?.acorder_tel) : "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ที่อยู่ :",
                                                                           detail: `${order?.acorder_address} ตำบล/แขวง ${order?.acorder_sub_district} อำเภอ/เขต ${order?.acorder_district} จังหวัด ${order?.acorder_province} ${order?.acorder_zipcode}`,
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                       </div>
                                                  </>
                                                  {/* เลือกรุ่นรถยนต์ */}
                                                  <>
                                                       <LabelBar type={"title"} title={"เลือกรุ่นรถยนต์"} />
                                                       <div className="-padding">
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "Serie : ",
                                                                           detail: this.state.series?.find(
                                                                                (el) => el.series_id === Number(order?.series_id)
                                                                           )?.series_name,
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "Model : ",
                                                                           detail: this.state.model?.find(
                                                                                (el) => el.model_id === Number(order?.model_id)
                                                                           )?.model_name,
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "สี : ",
                                                                           detail:
                                                                                this.state.color?.find(
                                                                                     (el) => el.color_id === Number(order?.color_id)
                                                                                )?.color_name || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "เลขเครื่องยนต์ : ",
                                                                           detail: order?.engine || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "เลขตัวถัง : ",
                                                                           detail: order?.body_no || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                       </div>
                                                  </>
                                                  {/* Car Accessories */}
                                                  <>
                                                       <LabelBar type={"title"} title={"Car Accessories (ลูกค้าซื้อเอง)"} />
                                                       <Table
                                                            type={"summary"}
                                                            column={conf_acc.column}
                                                            data={conf_acc.data}
                                                            keyField={conf_acc.keyField}
                                                       />
                                                  </>
                                                  {/* หมายเหตุ */}
                                                  <>
                                                       <LabelBar type={"title"} title={"หมายเหตุ"} />
                                                       <div className="-padding">
                                                            <div className="-padding">
                                                                 <Row>
                                                                      <span>{order?.acorder_note || "-"}</span>
                                                                 </Row>
                                                            </div>
                                                       </div>
                                                  </>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             <Button type="submit" onClick={this.addAccOrder}>
                                                  Submit
                                             </Button>
                                        </Col>
                                        <Col xs={6}>
                                             <Button variant="light" onClick={() => this.handleNext(false)}>
                                                  แก้ไข
                                             </Button>
                                        </Col>
                                   </div>
                              </div>
                         </div>
                    );
               default:
                    return (
                         <div className="panel">
                              {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} /> : null}
                              <Form noValidate validated={validated} onSubmit={this.handleConfirm}>
                                   <div className="content __input-panel">
                                        {/* รายละเอียดผู้ซื้อ */}
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>รายละเอียดผู้ซื้อ</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"autocomplete"}
                                                                      title={"A-Card No."}
                                                                      name={"acorder_acard"}
                                                                      value={order?.acorder_acard}
                                                                      placeholder={order?.acorder_acard || "กรุณากรอกA-Card No."}
                                                                      require={false}
                                                                      option={this.state.acard?.map(
                                                                           // (el) => `${el.acard_no} ${el.acard_fullname.split("null").join("")}`
                                                                           (el) => `${el.acard_no} ${el.acard_fullname}`
                                                                      )}
                                                                      md={3}
                                                                      handleChangeAutocomplete={this.handleChangeAutoComplete}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"สาขา"}
                                                                      name={"branch_id"}
                                                                      placeholder={"กรุณาเลือกสาขา"}
                                                                      option={this.state.branch?.map((e) => ({
                                                                           value: e.branch_id,
                                                                           name: e.branch_name,
                                                                      }))}
                                                                      value={order?.branch_id}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"autocomplete"}
                                                                      title={"พนักงานขาย"}
                                                                      name={"saler_name"}
                                                                      value={this.state.seller?.find((el) => el.user_id === order?.saler_name)?.name}
                                                                      placeholder={
                                                                           order.saler_name
                                                                                ? this.state.seller?.find((el) => el.user_id === order?.saler_name)
                                                                                       ?.name
                                                                                : "กรุณากรอกชื่อพนักงานขาย"
                                                                      }
                                                                      require={false}
                                                                      option={this.state.seller?.map((el) => el.name)}
                                                                      md={6}
                                                                      handleChangeAutocomplete={this.handleChangeAutoCompleteSeller}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 {order.acard_customer_type !== "นิติบุคคล" && (
                                                                      <>
                                                                           <Field
                                                                                type={"text"}
                                                                                title={"คำนำหน้า"}
                                                                                name={"prefix"}
                                                                                value={order && order.prefix ? order.prefix : ""}
                                                                                placeholder={"กรุณาเลือกคำนำหน้า"}
                                                                                // option={prefix?.map((e) => ({ value: e, name: e }))}
                                                                                // require={true}
                                                                                md={2}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </>
                                                                 )}
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ชื่อผู้ซื้อ"}
                                                                      name={"acorder_name"}
                                                                      value={order.acorder_name ? order.acorder_name.split("null").join("") : null}
                                                                      placeholder={"กรุณากรอก ชื่อ-นามสกุลผู้ซื้อ"}
                                                                      require={true}
                                                                      md={order.acard_customer_type !== "นิติบุคคล" ? 4 : 6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"number"}
                                                                      title={"หมายเลขประจำตัว"}
                                                                      name={"acorder_idcard"}
                                                                      value={order?.acorder_idcard}
                                                                      placeholder={"กรุณากรอกหมายเลขประจำตัว"}
                                                                      require={true}
                                                                      md={6}
                                                                      format="#-####-#####-##-#"
                                                                      mask="X"
                                                                      handleChangeNumber={(e) => this.handleChangeNumber(e, "acorder_idcard")}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"number"}
                                                                      title={"หมายเลขโทรศัพท์"}
                                                                      name={"acorder_tel"}
                                                                      value={order?.acorder_tel}
                                                                      placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                                                      require={true}
                                                                      md={6}
                                                                      format="###-#######"
                                                                      mask="X"
                                                                      typeInput={"tel"}
                                                                      handleChangeNumber={(e) => this.handleChangeNumber(e, "acorder_tel")}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ที่อยู่"}
                                                                      name={"acorder_address"}
                                                                      value={order?.acorder_address}
                                                                      placeholder={"กรุณากรอกที่อยู่"}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"จังหวัด"}
                                                                      name={"acorder_province"}
                                                                      placeholder={"กรุณาเลือกจังหวัด"}
                                                                      option={this.state.provice?.map((e) => ({
                                                                           value: e.province,
                                                                           name: e.province,
                                                                      }))}
                                                                      value={order.acorder_province}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeAddress}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"อำเภอ/เขต"}
                                                                      name={"acorder_district"}
                                                                      placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                      option={this.state.amphuresInfo?.map((e) => ({
                                                                           value: e.district,
                                                                           name: e.district,
                                                                      }))}
                                                                      value={order.acorder_district}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeAddress}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"ตำบล/แขวง"}
                                                                      name={"acorder_sub_district"}
                                                                      placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                      option={this.state.subdistrictInfo?.map((e) => ({
                                                                           value: e.sub_district,
                                                                           name: e.sub_district,
                                                                      }))}
                                                                      value={order.acorder_sub_district}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeAddress}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสไปรษณีย์"}
                                                                      name={"acorder_zipcode"}
                                                                      value={order?.acorder_zipcode}
                                                                      placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                      require={true}
                                                                      md={3}
                                                                      disabled={true}
                                                                      // handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* เลือกรุ่นรถยนต์ */}
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>เลือกรุ่นรถยนต์</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"Serie"}
                                                                      name={"series_id"}
                                                                      value={order?.series_id}
                                                                      placeholder={"กรุณาเลือก Serie"}
                                                                      option={this.state.series.map((e) => ({
                                                                           value: e.series_id,
                                                                           name: e.series_name,
                                                                      }))}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"Model"}
                                                                      name={"model_id"}
                                                                      value={order?.model_id}
                                                                      placeholder={"กรุณาเลือก Model"}
                                                                      option={this.state.model.map((e) => ({
                                                                           value: e.model_id,
                                                                           name: e.model_name,
                                                                      }))}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"สี"}
                                                                      name={"color_id"}
                                                                      value={order?.color_id}
                                                                      placeholder={"กรุณาเลือกสีที่1"}
                                                                      option={this.state.color.map((e) => ({
                                                                           value: e.color_id,
                                                                           name: e.color_name,
                                                                      }))}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขเครื่องยนต์"}
                                                                      name={"engine"}
                                                                      value={order?.engine}
                                                                      placeholder={"กรอกเลขเครื่องยนต์"}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขตัวถัง"}
                                                                      name={"body_no"}
                                                                      value={order?.body_no}
                                                                      placeholder={"กรอกเลขตัวถัง"}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* Car accessories ที่ลูกค้าจะซื้อ */}
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>Car accessories ที่ลูกค้าจะซื้อ</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Table
                                                                 type={"add"}
                                                                 add={"text"}
                                                                 column={acc_service.column}
                                                                 data={acc_service.data}
                                                                 keyField={acc_service.keyField}
                                                                 handleClick={this.handleClick}
                                                            />
                                                            {show ? (
                                                                 <ModalPanel
                                                                      onHide={() => this.setState({ show: false })}
                                                                      show={show}
                                                                      type={"select"}
                                                                      title={"เลือก Car accessories ที่ลูกค้าจะซื้อ"}
                                                                      // show={show}
                                                                      table_data={add_acc_service}
                                                                      view={true}
                                                                      button={[
                                                                           {
                                                                                name: "+ Add",
                                                                                variant: "primary",
                                                                                onClick: this.onAddItem,
                                                                           },
                                                                           {
                                                                                name: "Cancel",
                                                                                variant: "danger",
                                                                                onClick: this.onHide,
                                                                           },
                                                                      ]}
                                                                 />
                                                            ) : null}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* ราคา */}
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>ส่วนต่างที่ลูกค้าจ่ายเอง</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"number"}
                                                                      title={"ราคารวม"}
                                                                      name={"acorder_price"}
                                                                      value={order?.acorder_price === 0 ? "0" : order?.acorder_price}
                                                                      placeholder={"0.00"}
                                                                      require={true}
                                                                      unit={"บาท"}
                                                                      decimalScale={2}
                                                                      style_id={"__inline__space"}
                                                                      space={{ span: 3, offset: 0 }}
                                                                      handleChangeNumber={(e) => this.handleChangeNumber(e, "acorder_price")}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"number"}
                                                                      title={"Vat"}
                                                                      name={"acorder_vat"}
                                                                      value={order?.acorder_vat === 0 ? "0" : order?.acorder_vat}
                                                                      placeholder={"0.00"}
                                                                      require={true}
                                                                      unit={"บาท"}
                                                                      decimalScale={2}
                                                                      style_id={"__inline__space"}
                                                                      space={{ span: 3, offset: 0 }}
                                                                      handleChangeNumber={(e) => this.handleChangeNumber(e, "acorder_vat")}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* note */}
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>หมายเหตุ</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"หมายเหตุ"}
                                                                      name={"acorder_note"}
                                                                      value={order?.acorder_note}
                                                                      as={"textarea"}
                                                                      textarea_rows={5}
                                                                      md={12}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* Calculate Button  */}
                                        {/* <div className="button-group __onPanel row">
                                             <Col xl={3} md={4}>
                                                  <Button id={"calc_final"} onClick={this.calculateFinalPrice}>
                                                       Calculate Final Price
                                                  </Button>
                                             </Col>
                                        </div> */}
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button type="submit">ดำเนินการต่อ</Button>
                                             </Col>
                                             <Col xs={6}>
                                                  <Button variant="danger" onClick={this.handleReset}>
                                                       Reset
                                                  </Button>
                                             </Col>
                                        </div>
                                   </div>
                              </Form>
                         </div>
                    );
          }
     }
}
