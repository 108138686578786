import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel } from "components/index";
import { Form, Button, Col, Row } from "react-bootstrap";
import { POST, GET } from "api/index";
import swal from "sweetalert";
import dayjs from "dayjs";

// import promotion from './promotion';
const tofieds = (num) =>
     Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
     });

export default class AddPromotion extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               addpromotion: [],
               promotion: {
                    promotion_type: "ส่วนลดเงินดาวน์",
                    item: [],
                    tied: "ไม่ผูกกับ Leasing",
               },
               previous_path: this.props.match.params.manage,
               id: this.props.match.params.id,
               pro_id: this.props.match.params.this,
               title: [],
               supplier: [],
               accItem: [],
               leasingItem: [],
               insuranceItem: [],
               insuranceItem2: [],
               insuranceMockItem: [
                    {
                         items_id: null,
                         tied: 0,
                    },
                    {
                         items_id: null,
                         tied: 1,
                    },
               ],
          };
     }
     componentDidMount = () => {
          // console.log("asds", this.props.location.state);
          this.setPath();
          this.getSupplier();
     };

     setPath = async () => {
          let { previous_path, title, id } = this.state;
          // console.log("previous_path ", previous_path);
          let { series_name, series_id } = this.props.location.state;

          switch (previous_path) {
               case "add":
                    title = [
                         {
                              title: "โปรโมชั่น",
                              onClick: () => window.location.assign("/admin/promotion"),
                              // onClick: () => this.props.history.push("/admin/promotion"),
                         },
                         {
                              title: `จัดการโปรโมชั่น (${series_name})`,
                              onClick: () =>
                                   this.props.history.push({
                                        pathname: `/admin/promotion/detail/${series_id}`,
                                        state: { series_id, series_name },
                                   }),
                         },
                         {
                              title: "เพิ่มโปรโมชั่น",
                         },
                    ];
                    break;
               case "edit":
                    title = [
                         {
                              title: "โปรโมชั่น",
                              onClick: () => window.location.assign("/admin/promotion"),
                         },
                         {
                              title: `จัดการโปรโมชั่น (${series_name})`,
                              onClick: () =>
                                   this.props.history.push({
                                        pathname: `/admin/promotion/detail/${series_id}`,
                                        state: { series_id, series_name },
                                   }),
                         },
                         {
                              title: "แก้ไข",
                         },
                    ];
                    this.getAddPromotion();
                    break;
               default:
                    break;
          }
          title = this.setState({ title: title });
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { promotion, insuranceMockItem } = this.state;
          promotion[name] = value;
          if (name === "supplier_id") {
               this.getAccItem(value);
          } else if (name === "leasing_supplier_id") {
               this.getLeasingItem(value);
               promotion.leasing_item_id = null;
               promotion.leasing_type_id = null;
          } else if (name === "leasing_item_id") {
               this.getTypeLeasing(value);
               promotion.leasing_type_id = null;
               promotion.item[0] = { ...promotion.item[0], items_id: promotion.leasing_item_id };
          } else if (name === "leasinge_id") {
               promotion.item[0] = { ...promotion.item[0], leasinge_id: promotion.leasinge_id };
          } else if (name === "ins_supplier_id") {
               this.getInsuranceItem(value);
               promotion.ins_item_id = null;
               promotion.ins_type_id = null;
          } else if (name === "ins_item_id") {
               this.getTypeInsurance(value);
               promotion.ins_type_id = null;
               promotion.item[0] = { ...promotion.item[0], ins_item_id: promotion.ins_item_id };
          } else if (name === "ins_type_id") {
               promotion.item[0] = { ...promotion.item[0], ins_type_id: promotion.ins_type_id };
          } else if (name === "les_supplier_id") {
               this.getLeasingItem(value);
               promotion.les_item_id = null;
               promotion.les_type_id = null;
          } else if (name === "les_item_id") {
               this.getTypeLeasing(value);
               promotion.les_type_id = null;
               promotion.item[0] = { ...promotion.item[0], les_item_id: promotion.les_item_id };
          } else if (name === "les_type_id") {
               promotion.item[0] = { ...promotion.item[0], les_type_id: promotion.les_type_id };
          } else if (name === "promotion_type") {
               promotion.item = [];
          }
          // console.log("promotion", promotion);
          this.setState({ promotion, insuranceMockItem });
     };
     getAddPromotion = async () => {
          try {
               let result = await POST("/ps/this_promotion", {
                    pro_id: this.state.pro_id,
               });
               if (result.promotion_type === "Leasing") {
                    this.getLeasingItem(result.item[0].supplier_id);
                    this.getTypeLeasing(result.leasing_item_id);
               } else if (result.promotion_type === "ประกัน") {
                    this.getInsuranceItem(result.ins_supplier_id);
                    this.getTypeInsurance(result.ins_item_id);
                    this.getLeasingItem(result.les_supplier_id);
                    this.getTypeLeasing(result.les_item_id);
                    result.tied === 1 && (result.tied = "ผูกกับ Leasing");
               } else {
                    this.get_item();
               }
               // console.log('result', result)
               this.setState({ promotion: result });
          } catch (error) {
               console.log(error);
          }
     };
     getAccItem = async (supplier_id) => {
          try {
               this.setState({
                    accItem: [...(await POST("/item/accessories_by_supplier", { supplier_id }))],
               });
          } catch (error) {}
     };
     getLeasingItem = async (supplier_id) => {
          try {
               this.setState({
                    leasingItem: [...(await POST("/item/leasing_by_supplier", { supplier_id }))],
               });
          } catch (error) {}
     };
     getTypeLeasing = async (id) => {
          try {
               this.setState({ typeLeasing: [...(await POST("/item/get_type_leasing", { items_id: id }))] });
               // console.log("this.state.typeLeasing", this.state.typeLeasing);
          } catch (error) {
               console.log("error", error);
          }
     };

     getInsuranceItem = async (supplier_id) => {
          try {
               let result = [...(await POST("/item/insurance", { supplier_id }))];
               // console.log('result', supplier_id)
               this.setState({ insuranceItem: result });
          } catch (error) {
               console.log("error", error);
          }
     };
     getTypeInsurance = async (id) => {
          try {
               let getTypeInsurance = await POST("/item/get_type_insurance", { items_id: id });
               this.setState({ typeInsurance: [...getTypeInsurance] });
               // console.log('getTypeInsurance', getTypeInsurance)
          } catch (error) {
               console.log("error ", error);
          }
     };
     get_item = async () => {
          try {
               let { pro_id } = this.props.location.state;
               let res = await POST("/aos/get_item_pro", { pro_id });
               let { promotion } = this.state;
               promotion.item = res;
               this.setState({ promotion });
          } catch (error) {}
     };
     handleChangeNumber = (value, name) => {
          let { promotion } = this.state;
          promotion[name] = value.value;
          this.setState({ promotion });
     };
     handleSelectDateStart = (date, dateString) => {
          let { promotion } = this.state;
          if (date === null) {
               promotion.start_date = "";
               this.setState({ promotion });
          } else {
               promotion.start_date = dateString;
               this.setState({ promotion });
          }
     };
     handleSelectDateEnd = (date, dateString) => {
          let { promotion } = this.state;

          if (date === null) {
               promotion.end_date = "";
               this.setState({ promotion });
          } else {
               promotion.end_date = dateString;
               this.setState({ promotion });
          }
     };
     getSupplier = async () => {
          try {
               let result = await GET("/ps/slect_suppliers");
               this.setState({
                    supplier: result,
               });
          } catch (error) {}
     };
     addItem = () => {
          let { promotion, accItem } = this.state;

          let acc = [
               ...accItem?.filter((e) => {
                    if (e.items_id === Number(promotion.items_id)) {
                         return e;
                    }
               }),
          ][0];
          promotion.item.push({
               ...acc,
          });
          // console.log("promotion ", promotion);
          this.setState({ promotion });
     };

     handleSubmit = async (event) => {
          event.preventDefault();
          let { promotion, insuranceMockItem, previous_path } = this.state;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    icon: "error",
                    button: "เสร็จสิ้น",
               });
               event.stopPropagation();
          } else {
               console.log("promotion", promotion);
               try {
                    if (promotion.promotion_type === "ประกัน") {
                         insuranceMockItem.forEach((element) => {
                              if (element.items_id !== null) {
                                   promotion.item.push(element);
                              }
                         });
                    }
                    if (previous_path === "add") {
                         await POST("/ps/add_promotion", {
                              ...promotion,
                              series_id: this.state.id,
                         });
                         swal({
                              text: "เพิ่มประเภท Series ในรายการเรียบร้อยแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              let { series_name, series_id } = this.props.location.state;
                              this.props.history.push({
                                   pathname: `/admin/promotion/detail/${series_id}`,
                                   state: { series_id, series_name },
                              });
                         });
                    } else {
                         await POST("/ps/edit_promotion", {
                              ...promotion,
                              series_id: this.state.id,
                         });
                         swal({
                              text: "เพิ่มประเภท Series ในรายการเรียบร้อยแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              let { series_name, series_id } = this.props.location.state;
                              this.props.history.push({
                                   pathname: `/admin/promotion/detail/${series_id}`,
                                   state: { series_id, series_name },
                              });
                         });
                    }
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }
          this.setState({
               validated: true,
          });
     };

     removeBtnFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <a onClick={() => this.onRemove(row, rowIndex)} style={{ color: "red" }}>
                    ลบ
               </a>
          );
     };

     onRemove = (row, rowIndex) => {
          let { promotion } = this.state;
          promotion.item.splice(rowIndex, 1);
          // console.log("row, rowIndex ", row, rowIndex);
          this.setState({ promotion });
     };
     render() {
          let { promotion, supplier, accItem, leasingItem, insuranceItem, insuranceItem2, validated, title, typeLeasing, typeInsurance, previous_path } = this.state;
          // console.log('promotion', promotion)
          const table = {
               column: [
                    {
                         dataField: "items_code",
                         text: "Item Code",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "items_name",
                         text: "Item Name",
                    },
                    {
                         dataField: "supplier_code",
                         text: "Supplier Code",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน",
                         formatter: (cell) => <span>{tofieds(cell)}</span>,
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย",
                         formatter: (cell) => <span>{tofieds(cell)}</span>,
                    },
                    {
                         dataField: "delete",
                         text: "ลบ",
                         formatter: this.removeBtnFormatter,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: promotion.item,
               keyField: "promotion_list",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={title} active={title.length - 1} button={""} />
                    <div className="content __input-panel">
                         <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <LabelBar type={"title"} title={"ข้อมูลโปรโมชัน:"} />
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"ชื่อโปรโมชัน"}
                                                            name={"promotion_name"}
                                                            placeholder={"กรุณากรอกชื่อโปรโมชัน"}
                                                            value={promotion?.promotion_name}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"โปรโมชัน Code"}
                                                            name={"promotion_code"}
                                                            placeholder={"กรุณากรอกโปรโมชัน Code"}
                                                            value={promotion?.promotion_code}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            title={"วันที่เริ่มต้น"}
                                                            name={"start_date"}
                                                            value={promotion.start_date ? dayjs(promotion?.start_date) : ""}
                                                            placeholder={"วว-ดด-ปปปป"}
                                                            require={true}
                                                            md={3}
                                                            handleSelectDate={this.handleSelectDateStart}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            title={"วันที่สิ้นสุด"}
                                                            name={"end_date"}
                                                            placeholder={"วว-ดด-ปปปป"}
                                                            require={true}
                                                            md={3}
                                                            value={promotion.end_date ? dayjs(promotion?.end_date) : ""}
                                                            handleSelectDate={this.handleSelectDateEnd}
                                                       />
                                                  </Form.Row>

                                                  <LabelBar type={"title"} title={"ข้อมูลโปรโมชัน:"} />
                                                  <Form.Row>
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"ประเภท :"}
                                                            name={"promotion_type"}
                                                            label={["ส่วนลดเงินดาวน์", "อุปกรณ์ตกแต่ง", "Leasing", "ประกัน"]}
                                                            value={promotion.promotion_type}
                                                            handleChangeText={this.handleChangeText}
                                                            disabled={previous_path === "edit"}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>

                                   {promotion.promotion_type === "ส่วนลดเงินดาวน์" && (
                                        <div className="input-field">
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"number"}
                                                                      title={"กรอกส่วนลดเงินดาวน์"}
                                                                      name={"promotion_down"}
                                                                      value={promotion?.promotion_down}
                                                                      handleChangeNumber={(e) => this.handleChangeNumber(e, "promotion_down")}
                                                                      placeholder={"0.00"}
                                                                      require={true}
                                                                      decimalScale={2}
                                                                      md={6}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )}

                                   {promotion.promotion_type === "อุปกรณ์ตกแต่ง" && (
                                        <div className="input-field">
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกบริษัทอุปกรณ์ตกแต่ง"}
                                                                      name={"supplier_id"}
                                                                      placeholdText={"Supplier"}
                                                                      require={false}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (e.supplier_type.indexOf("อุปกรณ์ตกแต่ง") > -1) {
                                                                                     return e;
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      md={4}
                                                                      value={promotion.supplier_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือก Item"}
                                                                      name={"items_id"}
                                                                      placeholdText={"เลือก"}
                                                                      require={false}
                                                                      option={accItem?.map((e) => ({
                                                                           value: e.items_id,
                                                                           name: e.items_name,
                                                                      }))}
                                                                      md={4}
                                                                      value={promotion.items_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Col md={2}>
                                                                      <Button onClick={this.addItem} variant="success">
                                                                           เพิ่ม
                                                                      </Button>
                                                                 </Col>
                                                            </Form.Row>

                                                            <Form.Row>
                                                                 <Table type={"index"} column={table.column} data={table.data} keyField={table.keyField} />
                                                            </Form.Row>

                                                            {/* </Form.Row> */}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )}

                                   {promotion.promotion_type === "Leasing" && (
                                        <div className="input-field">
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือก Leasing Supplier"}
                                                                      name={"leasing_supplier_id"}
                                                                      require={true}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (e.supplier_type === "Leasing") {
                                                                                     return e;
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      md={4}
                                                                      md={6}
                                                                      value={promotion?.leasing_supplier_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกตัวแทน Leasing"}
                                                                      name={"leasing_item_id"}
                                                                      require={true}
                                                                      option={leasingItem?.map((e) => ({
                                                                           value: e.items_id,
                                                                           name: e.items_name,
                                                                      }))}
                                                                      value={promotion?.leasing_item_id}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกประเภท Leasing"}
                                                                      name={"leasinge_id"}
                                                                      require={true}
                                                                      option={
                                                                           typeLeasing
                                                                                ? typeLeasing?.map((e) => ({
                                                                                       value: e.leasinge_id,
                                                                                       name: e.leasing_type,
                                                                                  }))
                                                                                : []
                                                                      }
                                                                      value={promotion?.leasinge_id}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )}

                                   {promotion.promotion_type === "ประกัน" && (
                                        <div className="input-field">
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกบริษัทประกัน Supplier"}
                                                                      name={"ins_supplier_id"}
                                                                      require={true}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (e.supplier_type === "ประกัน") {
                                                                                     return e;
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      md={6}
                                                                      value={promotion?.ins_supplier_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"ตัวแทนประกัน"}
                                                                      name={"ins_item_id"}
                                                                      require={true}
                                                                      option={insuranceItem?.map((e) => ({
                                                                           value: e.items_id,
                                                                           name: e.items_name,
                                                                      }))}
                                                                      md={6}
                                                                      value={promotion?.ins_item_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกประกัน"}
                                                                      name={"ins_type_id"}
                                                                      require={true}
                                                                      option={
                                                                           typeInsurance
                                                                                ? typeInsurance?.map((e) => ({
                                                                                       value: e.insurance_id,
                                                                                       name: e.insurance_type,
                                                                                  }))
                                                                                : []
                                                                      }
                                                                      md={6}
                                                                      value={promotion?.ins_type_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <LabelBar
                                                                      type={"radio"}
                                                                      title={"ผูกกับ Leasing :"}
                                                                      name={"tied"}
                                                                      label={["ไม่ผูกกับ Leasing", "ผูกกับ Leasing"]}
                                                                      value={promotion?.tied}
                                                                      handleChangeText={this.handleChangeText}
                                                                      disabled={!promotion?.ins_type_id}
                                                                 />
                                                            </Form.Row>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือก Leasing Supplier"}
                                                                      name={"les_supplier_id"}
                                                                      require={true}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (e.supplier_type === "Leasing") {
                                                                                     return e;
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      md={4}
                                                                      md={6}
                                                                      disabled={promotion?.tied === "ไม่ผูกกับ Leasing"}
                                                                      value={promotion?.les_supplier_id}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกตัวแทน Leasing"}
                                                                      name={"les_item_id"}
                                                                      require={true}
                                                                      option={leasingItem?.map((e) => ({
                                                                           value: e.items_id,
                                                                           name: e.items_name,
                                                                      }))}
                                                                      disabled={promotion?.tied === "ไม่ผูกกับ Leasing"}
                                                                      value={promotion?.les_item_id}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"เลือกประเภท Leasing"}
                                                                      name={"les_type_id"}
                                                                      require={true}
                                                                      option={
                                                                           typeLeasing
                                                                                ? typeLeasing?.map((e) => ({
                                                                                       value: e.leasinge_id,
                                                                                       name: e.leasing_type,
                                                                                  }))
                                                                                : []
                                                                      }
                                                                      disabled={promotion?.tied === "ไม่ผูกกับ Leasing"}
                                                                      value={promotion?.les_type_id}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )}

                                   <div className="text-right">
                                        <Button type="submit" style={{ width: "25%", marginBottom: "1rem" }}>
                                             Submit
                                        </Button>
                                   </div>
                              </div>
                         </Form>
                    </div>
               </div>
          );
     }
}
