import { Breadcrumbs, Search, Skeleton, Table } from "components";
import React, { Component } from "react";
import { GET } from "api";
import dayjs from "dayjs";
import { getToken } from "../../../../static/function";

export default class DeliverCar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {
                    // acept_status: "รอส่งมอบ",
                    acept_status: "ชำระเงินแล้ว",
               },
               branch: [],
               loading: false,
          };
     }
     componentDidMount() {
          this.getCarPayment();
          this.getBranch();
     }
     getCarPayment = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/get_to_delivery");
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               let order = order_search.filter(
                    (el) => el.acept_status === "รอส่งมอบ" || el.acept_status === "ชำระเงินแล้ว" || el.acept_status === "ชำระแล้ว"
               );
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               getBranch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error", error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          let arr = row.order_code_id ? row.order_code_id.split("") : "-";
          switch (formatExtraData) {
               case "generate":
                    if (arr[0] === "B") {
                         return <a onClick={() => window.location.assign(`/admin/deliver/car/car/${row.order_id}`)}>ทำรายการ</a>;
                    } else {
                         return <a onClick={() => window.location.assign(`/admin/deliver/car/acc/${row.order_id}`)}>ทำรายการ</a>;
                    }
               case "order":
                    if (arr[0] === "B") {
                         return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
                    } else {
                         return <a onClick={() => window.location.assign(`/admin/order/acc/detail${row.order_id}`)}>{cell}</a>;
                    }
          }
     }

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     statusFormatter = (cell, row) => {
          let arr = row.order_code_id ? row.order_code_id.split("") : "-";
          if (row.acept_status === "ส่งมอบรถแล้ว") {
               if (arr[0] === "B") {
                    return cell;
               } else {
                    return "ส่งมอบอุปกรณ์แล้ว";
               }
          } else {
               return cell;
          }
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
          // console.log(search);
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.delivery_date ? dayjs(el.delivery_date).format("DD/MM/YYYY") === search.delivery_date : el) &&
                         ((search.acept_status
                              ? search.acept_status === "ทั้งหมด"
                                   ? el
                                   : String(el.acept_status) === String(search.acept_status === "รอส่งมอบ" ? "ชำระเงินแล้ว" : search.acept_status)
                              : el) ||
                              (search.acept_status
                                   ? search.acept_status === "ทั้งหมด"
                                        ? el
                                        : String(el.acept_status) === String(search.acept_status === "รอส่งมอบ" ? "ชำระแล้ว" : search.acept_status)
                                   : el))
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.userinfo_a_card ? String(el.userinfo_a_card).indexOf(String(search.userinfo_a_card)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     render() {
          let { order, newSearch, search, branch } = this.state;
          const table = {
               column: [
                    {
                         dataField: "payment_list",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                         formatExtraData: "generate",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatter,
                         formatExtraData: "order",
                         headerClasses: "header-custom __btn-s",
                    },

                    {
                         dataField: "acept_status",
                         text: "สถานะใบจอง",
                         formatter: this.statusFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "branch_name",
                         text: "สาขา/โชว์รูม",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "delivery_date",
                         text: "วันที่ส่งมอบ",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_a_card",
                         text: "A-Card No.",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "preemption_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "ส่งมอบรถ" }]} active={0} button={""} />
                    <div className="content __main-panel delivercar">
                         <Search
                              type={"deliver-car"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: search,
                                   acept_status: search.acept_status,
                                   branchs: branch,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
