import React, { Component } from "react";
import { Breadcrumbs } from "../../components/index";
import { Button, Col } from "react-bootstrap";

export default class Print extends Component {
     constructor(props) {
          super(props);
          this.state = {
               previous_path: this.props.match.params.previous,
               id: this.props.match.params.id,
               title: "",
          };
     }

     componentDidMount = () => {
          this.setPath();
     };

     setPath = () => {
          let { previous_path, title, id } = this.state;
          switch (previous_path) {
               case "car":
                    title = ["การจองรถยนต์", `ใบจองรถยนต์ (${id})`];
               case "acc":
                    title = ["การขายอุปกรณ์", `ใบสั่งขายอุปกรณ์ (${id})`];
               case "discount":
                    title = [
                         "อนุมัติส่วนลดของใบจอง (รายการรอการอนุมัติ)",
                         `ใบอนุมัติการลดเกิน Margin / ขอเครดิต`,
                    ];
               default:
                    break;
          }
          this.setState({ title });
     };

     render() {
          let { title, previous_path } = this.state;
          return (
               <div className='panel'>
                    {title ? (
                         <Breadcrumbs
                              title={title}
                              active={1}
                              button={[
                                   {
                                        icon: "fas fa-pen-square -icon",
                                        name: "แก้ไขเอกสาร",
                                   },
                                   {
                                        icon: "fas fa-print -icon",
                                        name: "พิ่มพ์เอกสาร",
                                   },
                              ]}
                              path={"/admin/order/add"}
                              onClick={() => this.props.history.goBack()}
                         />
                    ) : null}
                    <div className='content'>
                         {previous_path === "discount" && (
                              <div className='button-group row'>
                                   <Col md={{ span: 3, offset: 6 }}>
                                        <Button>อนุมัติ</Button>
                                   </Col>
                                   <Col md={{ span: 3, offset: 0 }}>
                                        <Button variant='danger'>
                                             ยกเลิกใบสั่งจอง
                                        </Button>
                                   </Col>
                              </div>
                         )}
                         {previous_path === "acc" ||
                              (previous_path === "car" && <div>print</div>)}
                    </div>
               </div>
          );
     }
}
