import React, { Component } from "react";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import "../containers/login/login.scss";
import { POST } from "api/index";
import { Img } from "components/index";
// import swal from "sweetalert";

export default class line_page extends Component {
     constructor(props) {
          super(props);

          this.state = {
               phone: undefined,
               set_err: "",
               success: false,
          };
     }

     componentDidMount = () => {
          // console.log("params", this.props.match.params);
     };
     Submit = async () => {
          try {
               let { phone } = this.state;
               let { id } = this.props.match.params;
               if (id && id.length > 20) {
                    if (phone) {
                         await POST("/aos/set_uid", { phone, u_id: id });
                         this.setState({ success: true });
                    } else {
                         // this.setState({ set_err: "กรุณากรอกเบอร์โทรให้ถูกต้อง" });
                         this.setState({ set_err: "กรุณากรอก LINE ID ให้ถูกต้อง" });
                    }
               } else {
                    this.setState({ set_err: "กรุณาเปิดหน้าเว็บไซต์นี้จากลิ้งใน LINE PS Notification เท่านั้น" });
               }
          } catch (error) {
               this.setState({ set_err: error });
          }
     };

     render() {
          let { phone, set_err, success } = this.state;
          return (
               <div className="panel" id="panel__login">
                    <div className="text-center">
                         <Row className="logo __header">
                              <Col className="align-self-center">
                                   <Image src={Img.toyota_logo} className="logo" />
                              </Col>
                              <Col>
                                   <Image src={Img.toyota_ps_logo} className="logo" />
                              </Col>
                         </Row>

                         {success ? (
                              <text style={{ color: "green", fontWeight: "bold", fontSize: "large" }}>{"เชื่อมต่อระบบเรียบร้อยแล้วค่ะ (*≧∇≦*)"}</text>
                         ) : (
                              <Form className="login">
                                   <Form.Group controlId="formBasicEmail">
                                        <Form.Label style={{ fontSize: "large" }}>LINE ID</Form.Label>
                                        <Form.Control
                                             placeholder="กรุณากรอก LINE ID"
                                             name="phone"
                                             onChange={(e) => {
                                                  // if (e.target.value.length <= 10) {
                                                  this.setState({ phone: e.target.value, set_err: "" });
                                                  // }
                                             }}
                                             type="text"
                                             value={phone}
                                        />
                                        <small style={{ color: "red" }}>{set_err}</small>
                                   </Form.Group>
                                   <Button variant="primary" style={{ background: "#3fc8f3" }} onClick={this.Submit}>
                                        ยืนยันการสมัคร
                                   </Button>
                              </Form>
                         )}
                    </div>
               </div>
          );
     }
}
