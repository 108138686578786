import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Col, Table as ta_ble } from "react-bootstrap";
import { Field } from "../index";

const rowClasses = (row, rowIndex) => {
     if (row.field === "summary") {
          return "row-custom __summary";
     }
     if (row.field === "summary-total") {
          return "row-custom __summary-total";
     }
     if (row.field === "summary-gold") {
          return "row-custom __summary-gold";
     }
     if (row.field === "summary-detail") {
          return "row-custom __summary-detail";
     }
     if (row.field === "summary-danger") {
          return "row-custom __summary-danger";
     }
     if (rowIndex % 2 !== 0) {
          return "row-custom";
     }
     if (row.left === "left") {
     }
};

const pagination = paginationFactory({
     hideSizePerPage: true,
});

export default class Table extends Component {
     render() {
          let {
               type,
               data,
               column,
               keyField,
               handleClick,
               button,
               add,
               handleChange,
               disabled,
               button_id,
               leasing,
               noscroll,
               selectRow,
               amount,
               used,
               duplicate,
          } = this.props;
          switch (type) {
               case "index":
                    return (
                         <div className="index __primary">
                              <BootstrapTable
                                   bordered={false}
                                   keyField={keyField}
                                   data={data}
                                   columns={column}
                                   rowClasses={rowClasses}
                                   pagination={pagination}
                              />
                         </div>
                    );
               case "index-fixhead":
                    return (
                         <div className="index __primary tableFixHead">
                              <BootstrapTable
                                   bordered={false}
                                   keyField={keyField}
                                   data={data}
                                   columns={column}
                                   rowClasses={rowClasses}
                                   pagination={pagination}
                              />
                         </div>
                    );
               case "full":
                    return (
                         <div className="index __full">
                              <BootstrapTable
                                   bordered={false}
                                   keyField={keyField}
                                   data={data}
                                   columns={column}
                                   rowClasses={rowClasses}
                                   pagination={pagination}
                              />
                         </div>
                    );
               case "full-fixhead":
                    return (
                         <div className="index __full tableFixHead">
                              <BootstrapTable
                                   bordered={false}
                                   keyField={keyField}
                                   data={data}
                                   columns={column}
                                   rowClasses={rowClasses}
                                   pagination={pagination}
                              />
                         </div>
                    );
               case "custom":
                    return (
                         <div className="index">
                              <BootstrapTable bordered={true} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                         </div>
                    );
               case "custom-fixHead":
                    return (
                         <div className="index tableFixHead">
                              <BootstrapTable bordered={true} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                         </div>
                    );
               case "custom-fixHead-modal":
                    return (
                         <div className="index tableFixHead-modal">
                              <BootstrapTable bordered={true} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                         </div>
                    );
               case "add":
                    return (
                         <div className="index __add">
                              {add === "file" && (
                                   <div className="-btn">
                                        <Field
                                             type={"file"}
                                             title={""}
                                             placeholder={""}
                                             handleChange={handleChange}
                                             button={button}
                                             style_id={"custom-btn __only-btn"}
                                             require={false}
                                        />
                                   </div>
                              )}
                              {add === "text" && (
                                   <div className="-btn">
                                        <Button id={button_id} variant="success" disabled={disabled} onClick={handleClick}>
                                             {button ? button : "+ เพิ่ม"}
                                        </Button>
                                   </div>
                              )}
                              <div className="-table">
                                   <div className={"index __summary " + `${leasing && leasing}`}>
                                        <BootstrapTable
                                             id="add-table"
                                             bordered={false}
                                             keyField={keyField}
                                             data={data}
                                             columns={column}
                                             rowClasses={rowClasses}
                                             noDataIndication="ไม่พบข้อมูล"
                                        />
                                   </div>
                              </div>
                         </div>
                    );

               case "add-custom":
                    return (
                         <div className="index __add row">
                              {add === "file" && (
                                   <Col md={2}>
                                        <Field
                                             type={"file"}
                                             title={""}
                                             placeholder={""}
                                             handleChange={handleChange}
                                             button={button}
                                             style_id={"custom-btn __only-btn"}
                                             require={false}
                                        />
                                   </Col>
                              )}
                              {/* {add === "text" && (
              <Col md={2}>
                <Button
                  variant="success"
                  disabled={disabled}
                  onClick={handleClick}
                >
                  {button ? button : "+ เพิ่ม"}
                </Button>
              </Col>
            )} */}
                              <Col md={12}>
                                   <BootstrapTable
                                        id="add-table"
                                        bordered={false}
                                        keyField={keyField}
                                        data={data}
                                        columns={column}
                                        rowClasses={rowClasses}
                                        noDataIndication="ไม่พบข้อมูล"
                                   />
                              </Col>
                         </div>
                    );

               case "index-list":
                    return (
                         <div className="index-list">
                              <BootstrapTable
                                   bordered={true}
                                   keyField={keyField}
                                   data={data}
                                   columns={column}
                                   rowClasses={rowClasses}
                                   headerClasses={"list-header"}
                                   bodyClasses={"list-tbody"}
                              />
                         </div>
                    );
               case "summary":
                    return (
                         <div className={"index __summary " + `${leasing && leasing}`}>
                              {amount ? <strong style={{ fontSize: "1rem" }}>ทั้งหมด {amount} รายการ, </strong> : null}
                              {used ? <strong style={{ fontSize: "1rem" }}>มีรุ่นรถ {used} รายการ, </strong> : null}
                              {amount && used ? <strong style={{ fontSize: "1rem" }}>ไม่มีรุ่นรถ {amount - used} รายการ, </strong> : null}
                              {duplicate ? <strong style={{ fontSize: "1rem" }}>รถซ้ำ {duplicate} รายการ</strong> : null}
                              <BootstrapTable bordered={false} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                         </div>
                    );
               case "summary-no-padding":
                    return (
                         <div className="index __summary-no-padding">
                              <BootstrapTable bordered={false} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                         </div>
                    );
               case "summary-custom-padding":
                    if (noscroll) {
                         return (
                              <div className="index __summary-custom-padding -noscroll">
                                   <BootstrapTable
                                        bordered={false}
                                        keyField={keyField}
                                        data={data}
                                        selectRow={selectRow}
                                        columns={column}
                                        rowClasses={rowClasses}
                                   />
                              </div>
                         );
                    } else if (selectRow) {
                         return (
                              <div className="index __summary-custom-padding">
                                   <BootstrapTable
                                        keyField={keyField}
                                        data={data}
                                        columns={column}
                                        selectRow={selectRow}
                                        rowClasses={rowClasses}
                                        striped
                                        hover
                                   />
                              </div>
                         );
                    } else {
                         return (
                              <div className="index __summary-custom-padding">
                                   <BootstrapTable bordered={false} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                              </div>
                         );
                    }

               case "document":
                    return (
                         <div className="index __document">
                              <BootstrapTable bordered={false} keyField={keyField} data={data} columns={column} rowClasses={rowClasses} />
                         </div>
                    );
               case "custom-column":
                    return (
                         <div className="index __summary-no-padding">
                              <BootstrapTable
                                   bordered={false}
                                   keyField={keyField}
                                   data={data}
                                   columns={column}
                                   rowClasses={rowClasses}
                                   // headerClasses={'list-header'}
                                   // bodyClasses={'list-left'}
                              />
                         </div>
                    );
               default:
                    return <React.Fragment></React.Fragment>;
          }
     }
}
