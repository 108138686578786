import React, { Component } from "react";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import { POST } from "api/index";
import { Img } from "components/index";
import { GET } from "api/index";
import swal from "sweetalert";
import { getToken_permission_page } from "../../../static/function";

import "./dashboard.scss";

export default class Dashboard extends Component {
     onLogout = async () => {
          swal("ต้องการจะออกจากระบบหรือไม่ ?", {
               buttons: {
                    cancel: "ยกเลิก",
                    submit: {
                         text: "ตกลง",
                         value: "submit",
                    },
                    onOpen: (e) => {
                         console.log(e);
                    },
               },
          }).then(async (value) => {
               if (value === "submit") {
                    try {
                         await GET("/authen/logout");
                         localStorage.removeItem("token");
                         localStorage.removeItem("username");
                         localStorage.removeItem("permission_page");
                         window.location.assign("/login");
                    } catch (error) {
                         console.log(error);
                    }
               }
          });
     };
     clickToPage = () => {
          let page = getToken_permission_page();
          let permission = page ? JSON.parse(page.permission_page) : [];
          window.location.assign(permission.length > 0 ? permission.sort((a, b) => a.id - b.id)[0].path : "/admin");
     };
     render() {
          return (
               <div className="panel" id="panel__login">
                    <div className="dashboard">
                         <Row className="logo __header">
                              <Col className="align-self-center">
                                   <Image src={Img.toyota_logo} className="logo" />
                              </Col>
                              <Col>
                                   <Image src={Img.toyota_ps_logo} className="logo" />
                              </Col>
                         </Row>
                         <Row className="carousel" data-flickity='{ "groupCells": true }' data-flickity-options='{ "wrapAround": true }'>
                              <div>
                                   <div className="carousel-cell" id="marketing" onClick={() => this.clickToPage()}>
                                        <Image src={Img.sale} />
                                        <h4>การซื้อขาย</h4>
                                   </div>
                                   <div className="carousel-cell" id="repair">
                                        <Image src={Img.maintenance} />
                                        <h4>ซ่อมตัวถังและสี</h4>
                                   </div>
                                   {/* <div className="carousel-cell">
                            <Image src={Img.service} />
                            <h4>บริการ</h4>
                        </div> */}
                                   <div className="carousel-cell" id="disbur">
                                        <div className="animate-finance">
                                             <Image src={Img.finance_pp} id="layer-1" />
                                             <Image src={Img.finance_calculator} id="layer-2" />
                                             <Image src={Img.finance_bg_2} id="layer-3" />
                                             <Image src={Img.finance_bg} id="layer-4" />
                                        </div>
                                        <h4>การเบิกจ่าย</h4>
                                   </div>
                              </div>
                         </Row>
                         <Row className="justify-content-center noMargin">
                              <Col xs="auto">
                                   <Button id="logoutBtn" onClick={this.onLogout}>
                                        <i className="fas fa-external-link-alt icon"></i>
                                        ออกจากระบบ
                                   </Button>
                              </Col>
                         </Row>
                         <Row className="logo __footer">
                              <Col className="align-self-center">
                                   <Image src={Img.list_car} className="logo" />
                              </Col>
                         </Row>
                    </div>
               </div>
          );
     }
}
