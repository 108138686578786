import { GET, POST } from "api/index";
import { Breadcrumbs, Table, Search, Img, Skeleton } from "components/index";
import React, { Component } from "react";
import swal from "sweetalert";

export default class Item extends Component {
     constructor(props) {
          super(props);
          this.state = {
               item: [],
               item_backup: [],
               file: {},
               search: {
                    series_name: "ทั้งหมด",
               },
               fileTextHolder: "",
               loading: false,
               searchSeries: [],
          };
     }
     componentDidMount = () => {
          this.getItem();
     };
     getItem = async () => {
          try {
               let result = await GET("/item");
               result
                    .sort((end, start) => parseFloat(start.items_id) - parseFloat(end.items_id))
                    .sort((end, start) => parseFloat(start.items_id) - parseFloat(end.items_id));

               let searchSeries = result
                    .filter((el, i) => result.map((ele) => ele.series).indexOf(el.series) === i)
                    .map((el) => ({ value: el.series, name: el.series }));
               searchSeries.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });

               await this.setState({ item: result, item_backup: result, searchSeries: searchSeries });
          } catch (error) {
               console.log(error);
          }
     };
     handleChange = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleUploadFile = async () => {
          let { file, search } = this.state;
          // alert(search.item_type);
          // console.log("file", file, search);
          if (file.name && search.item_type) {
               try {
                    let formData = new FormData();
                    if (file && search.item_type) {
                         formData.append("type", search.item_type);
                         formData.append("file", file);
                         this.setState({ loading: true });
                         await POST("/aos/item_master", formData, true);
                         // window.location.replace("/admin/items-upload");
                         // setTimeout(() => {
                         this.getItem();
                         search.item_type = "";
                         this.setState({ loading: false, fileTextHolder: "", file: null, search });
                         // }, 180000);
                    }
               } catch (error) {
                    // setTimeout(() => {
                    //      this.getItem();
                    //      search.item_type = "";
                    //      this.setState({ loading: false, fileTextHolder: "", file: null, search });
                    // }, 180000);
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
                    this.setState({ loading: false });
               }
          } else {
               swal({
                    text: "กรุณาอัปเลือกประเภทและไฟล์",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     handleClick = () => {
          window.location.assign("/admin/item/detail/add");
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-search"
                    onClick={() => window.location.assign(`/admin/item/detail${row.items_id}`)}
               ></i>
          );
     }
     nameFormatter(cell, row, rowIndex, formatExtraData) {
          if (row.items_name && row.items_name.length > 55) {
               return row.items_name.substring(0, 55) + "...";
          } else {
               return row.items_name || "-";
          }
     }
     linearSearch = (value) => {
          const supplier_type = [
               { value: "TMT", name: "TMT" },
               { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง (TOYOTA)", name: "อุปกรณ์ตกแต่ง (TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง (Out Source Supplier)", name: "อุปกรณ์ตกแต่ง (Out Source Supplier)" },
               { value: "ประกัน", name: "ประกัน" },
               { value: "Leasing", name: "Leasing" },
               { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
          ];
          for (let i = 0; i < supplier_type.length; i++) {
               if (supplier_type[i].value === value) {
                    return true;
               }
          }
          return false;
     };
     onSearch = () => {
          let { item_backup, search } = this.state;
          // console.log(
          //      "item_backup",
          //      item_backup.map((el) => el.items_type).filter(el=> el.includes('ประกัน'))
          // );
          // console.log(search);
          let item = item_backup.filter(
               (e) =>
                    (search.items_code ? String(e.items_code).includes(String(search.items_code)) : e) &&
                    (search.items_name ? String(e.items_name).toLowerCase().includes(String(search.items_name).toLowerCase()) : e) &&
                    (search.status
                         ? String(e.status).toLowerCase() === String(search.status).toLowerCase() ||
                           String(search.status).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.items_type === "อื่น ๆ"
                         ? !this.linearSearch(e.items_type)
                              ? e
                              : null
                         : search.items_type
                         ? String(e.items_type).toLowerCase() === String(search.items_type).toLowerCase() ||
                           String(search.items_type).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.supplier_name ? String(e.supplier_name).includes(String(search.supplier_name).toLowerCase()) : e) &&
                    (search.series_name === "อื่น ๆ"
                         ? !this.linearSearch(e.series)
                              ? e
                              : null
                         : search.series_name
                         ? String(e.series).toLowerCase() === String(search.series_name).toLowerCase() ||
                           String(search.series_name).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.acept_status
                         ? String(e.acept_status).toLowerCase() === String(search.acept_status).toLowerCase() ||
                           String(search.acept_status).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ item });
          // console.log("item", item)
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     // handleChangeOption = (e) => {
     //      // handle option by name
     //      let { search } = this.state;
     //      search[e.target.name] = e.target.value;
     //      this.setState({ ...search });
     // };

     downloadFile = async () => {
          try {
               window.open(await POST("/ps/download_excel", { download: "item" }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     render() {
          let { fileTextHolder, item, search, loading, searchSeries } = this.state;
          const table = {
               column: [
                    {
                         dataField: "edit",
                         text: "ข้อมูล",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "items_code",
                         text: "Item Code",
                         headerClasses: "header-custom  __btn-s",
                    },
                    {
                         dataField: "items_name",
                         text: "Item Name",
                         formatter: this.nameFormatter,
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท Item",
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "series",
                         text: "Series",
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "status",
                         text: "สถานะ",
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: item.map((el) => ({ ...el, items_code: el.items_code ? el.items_code : "-", series: el?.series || "-" })),
               keyField: "item_code",
          };

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "รายการ Item" }]}
                         active={0}
                         button={[
                              {
                                   icon: "fas fa-user-plus -icon",
                                   name: "เพิ่ม Item",
                                   handleClick: this.handleClick,
                              },
                         ]}
                         onClick={() => this.props.history.goBack()}
                    />
                    <div className="content __main-panel item">
                         <div className="search-bar">
                              <Search
                                   type={"item"}
                                   searchOptions={{ ...search, searchSeries }}
                                   onSearch={this.onSearch}
                                   handleChangeText={this.handleChangeText}
                                   handleChange={this.handleChangeOption}
                              />
                              <Search
                                   type={"upload-supplier"}
                                   title={{
                                        name: "อัปโหลด Item Master",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   button={"แนบไฟล์"}
                                   typeSup={search}
                                   style_id={"custom-btn __below-btn"}
                                   handleChange={this.handleChange}
                                   handleChangeOption={this.handleChangeOption}
                                   handleUploadFile={this.handleUploadFile}
                                   downloadFile={this.downloadFile}
                                   itemUp={true}
                              />
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
