import React, { Component } from "react";
import { Breadcrumbs, LabelBar, Field, Sign, ModalPanel } from "components";
import { Row, Col, Button, Image } from "react-bootstrap";
import { GET, ip, POST } from "api";
import swal from "sweetalert";
import axios from "axios";

export default class GenerateSaveLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order_id: this.props.match.params.id,
               show: false,
               order: {
                    get_label: 0,
               },
               detail: {},
               province: [],
               province_format: [],
               fileURL: "",
               register_price: 0,
               regis_need: 0,
               regis_real: 0,
          };
     }
     componentDidMount = () => {
          this.getOrderDetail();
          this.getProvice();
          this.getOrderPdf();
     };

     getOrderDetail = async () => {
          let { order_id, order } = this.state;
          try {
               let detail = await POST("/order/this", { order_id: order_id });
               order.order_id = parseInt(order_id);
               this.setState({ detail: { ...detail }, order }, () => this.getPrice(detail.car_price_list));
               //    console.log("detail", this.state.detail);
          } catch (err) {
               console.log(err);
          }
     };
     getPrice = async (price_list) => {
          let { detail } = this.state;
          try {
               let price = price_list && price_list.length > 2 ? JSON.parse(price_list) : null;
               let regis_need, regis_real;
               if (detail.userinfo_customer_type === "บุคคลทั่วไป") {
                    regis_need = price ? price.register_fun_normal_to_company : 0;
                    regis_real = price ? price.register_fun_normal_to_delivery : 0;
               } else {
                    regis_need = price ? price.register_fun_coporation_to_company : 0;
                    regis_real = price ? price.register_fun_coporation_to_delivery : 0;
               }
               this.setState({ regis_real, regis_need, register_price: detail.register_price });
          } catch (error) {
               console.log(`error`, error);
          }
     };
     getProvice = async () => {
          let { province_format } = this.state;
          try {
               let getprovice = await GET("/address/getprovince");

               for (const element of getprovice) {
                    element.value = element.id;
                    element.name = element.name_th;
               }

               await this.setState({ province: [...getprovice] });
               //    console.log("province", this.state.province);
          } catch (error) {
               console.log("error ", error);
          }
     };

     getOrderPdf = async () => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/delivery_pdf`,
                         { order_id: this.state.order_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              fileURL: fileURL,
                              // spinner: false,
                              // show_modal_pdf: true
                         });
                    });
          } catch (error) {}
     };

     /* --------------- onClick ----------------- */
     handleClickCheckLabel = async () => {
          let { order, detail } = this.state;
          // console.log("order send -->", order);
          // if (order.checklabel_number && order.checklabel_province) {
          try {
               let result = await POST("/order/submit_check_label", { ...order, branch_id: detail.branch_id });
               swal({
                    icon: "success",
                    text: "บันทึกข้อมูลแล้ว",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    // window.location.assign(`/admin/label/save`);
                    window.location.reload();
               });
          } catch (err) {
               swal({
                    icon: "warning",
                    text: `ดำเนินการผิดพลาด ${err}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
          }
          // } else {
          //      swal({
          //           icon: "warning",
          //           text: `กรุณากรอกหมายเลขป้ายทะเบียนและจังหวัด`,
          //           buttons: {
          //                submit: "เสร็จสิ้น",
          //           },
          //      });
          // }
     };

     /* --------------- onChange ----------------- */
     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
          //   console.log(this.state.order);
     };
     handleChangeSelect = (e) => {
          let { order, province } = this.state;

          let province_id = parseInt(e.target.value);
          let value_str = province.find((e) => e.id === province_id).name_th;

          order[e.target.name] = value_str;
          this.setState({ order });
          //   console.log(this.state.order);
     };

     handleChangeCheckBox(checked, e) {
          let { order } = this.state;

          if (checked) {
               order[e.target.name] = 1;
          } else {
               order[e.target.name] = 0;
          }

          this.setState({ order });
          //   console.log(this.state.order);
     }

     handleChangeSign = async (sigCanvas) => {
          let { order } = this.state;
          let sign = await sigCanvas.toDataURL("image/png");
          order.checklabe_sign = sign;
          this.setState({ order });
          //   console.log(this.state.order);
     };

     downloadPdf = async () => {
          let token = localStorage.getItem("token");
          await axios
               .post(
                    `${ip}${"/order/tempory_register_pdf"}`,
                    { order_id: this.state.order_id, type: "ต้นฉบับ" },
                    {
                         responseType: "blob",
                         headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              token,
                         },
                    }
               )
               .then(async (res) => {
                    const pdfBlob = new Blob([res.data], {
                         type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(pdfBlob);
                    // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                    window.open(fileURL);
               });
     };
     onClickCloseSell = async () => {
          let { order_id } = this.state;
          // console.log("carorder.register", carorder.register, carorder.checklabel_status);
          try {
               await POST("/order/close", { order_id });
               swal({
                    icon: "success",
                    text: "ปิดการขายเรียบร้อย",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => {
                    window.location.reload();
               });
          } catch (err) {
               console.log(err);
          }
     };
     render() {
          let { order_id, order, detail, province, fileURL, register_price, regis_need, regis_real } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `บันทึกรับป้ายและเล่มในระบบ`,
                                   onClick: () => window.location.assign("/admin/label/save"),
                              },
                              {
                                   title: `รับเล่มและป้ายของใบจอง (${detail?.order_code_id})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${this.state.order_id}`),
                              },
                         ]}
                         active={2}
                         button={[]}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">{fileURL && <embed src={fileURL} type="application/pdf" height="1150px" width="100%" />}</div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ค่าจดทะเบียน"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ผู้จดทะเบียน : ",
                                                            detail: `${detail?.register_pay}`,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ลักษณะรถ : ",
                                                            detail: `${detail?.series_name} + ${detail?.model_name} + ${detail?.color_name}`,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อผู้จดทะเบียน : ",
                                                            detail: detail?.register_name,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ค่าจดที่เรียกเก็บ : ",
                                                            detail: parseFloat(register_price || 0).toLocaleString(undefined, {
                                                                 maximumFractionDigits: 2,
                                                                 minimumFractionDigits: 2,
                                                            }),
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ค่าจดที่ผู้จดเรียกเก็บ : ",
                                                            detail: parseFloat(regis_need || 0).toLocaleString(undefined, {
                                                                 maximumFractionDigits: 2,
                                                                 minimumFractionDigits: 2,
                                                            }),
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ค่าจดที่จ่ายจริง : ",
                                                            detail: parseFloat(regis_real || 0).toLocaleString(undefined, {
                                                                 maximumFractionDigits: 2,
                                                                 minimumFractionDigits: 2,
                                                            }),
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <Col md={2}>
                                                       <strong>{`ใบกำกับภาษี : `}</strong>
                                                  </Col>
                                                  <Col>
                                                       {detail && detail.register_pay === "ลูกค้า" ? (
                                                            <i className="fas fa-print icon __btn active" onClick={this.downloadPdf} />
                                                       ) : (
                                                            "-"
                                                       )}
                                                  </Col>
                                             </Row>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลทะเบียน"} />
                                        {detail?.checklabel_status === "รอดำเนินการ" ? (
                                             <>
                                                  <Row className="noMargin">
                                                       <Col xl={12}>
                                                            <Field
                                                                 type={"text"}
                                                                 title="หมายเลขป้ายทะเบียน : "
                                                                 placeholder="กรอกหมายเลขป้ายทะเบียน"
                                                                 name={"checklabel_number"}
                                                                 style_id="__inline"
                                                                 xl={6}
                                                                 handleChangeText={(e) => this.handleChangeText(e)}
                                                                 value={order?.checklabel_number}
                                                                 disabled={false}
                                                            />
                                                       </Col>
                                                  </Row>
                                                  <Row className="noMargin">
                                                       <Col xl={12}>
                                                            <Field
                                                                 type="select"
                                                                 title="จังหวัด : "
                                                                 name="checklabel_province"
                                                                 value={
                                                                      order.checklabel_province
                                                                           ? province.find((e) => e.name_th === order?.checklabel_province).id
                                                                           : null
                                                                 }
                                                                 option={province}
                                                                 style_id="__inline"
                                                                 xl={6}
                                                                 handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 disabled={false}
                                                            />
                                                       </Col>
                                                  </Row>
                                             </>
                                        ) : (
                                             <>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "หมายเลขป้ายทะเบียน : ",
                                                                 detail: detail?.checklabel_number || "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "จังหวัด : ",
                                                                 detail: detail?.checklabel_province || "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"เล่มจดทะเบียน"} />

                                        <span className="warning">{"โปรดตรวจสอบข้อมูลหมายเลขทะเบียนและจังหวัดให้ถูกต้อง"}</span>

                                        <div className="nopadding">
                                             <Field
                                                  handleChange={(e) => this.handleChangeCheckBox(e.target.checked, e)}
                                                  name={"get_label"}
                                                  type={"checkbox"}
                                                  option={[{ name: "ลูกค้าได้รับเล่มแล้ว", value: 0 }]}
                                                  md={12}
                                             />
                                        </div>
                                        <div className="-padding">
                                             {"ลายเซ็นลูกค้ายืนยันการรับเล่มและป้าย"}
                                             {detail?.checklabel_status === "รอดำเนินการ" ? (
                                                  <Sign save={this.handleChangeSign} type="default-no-btn" />
                                             ) : (
                                                  <>
                                                       <div
                                                            style={{
                                                                 border: "1px solid #000",
                                                                 borderRadius: "8px",
                                                                 height: "200px",
                                                                 textAlign: "center",
                                                            }}
                                                       >
                                                            <Image src={detail?.checklabe_sign} />
                                                       </div>
                                                       {detail?.checklabel_status === "จดทะเบียนแล้ว" && detail?.acept_status !== "ปิดการขาย" && (
                                                            <div className="button-group __submit row">
                                                                 <Col xs={4}>
                                                                      <Button onClick={this.onClickCloseSell}>ปิดการขาย</Button>
                                                                 </Col>
                                                            </div>
                                                       )}
                                                       <Breadcrumbs under={true} type={"success"} title={"ดำเนินการบันทึกป้ายแล้ว"} />
                                                       <div style={{ height: "80px" }}></div>
                                                  </>
                                             )}
                                        </div>
                                   </div>
                              </div>
                              {detail?.checklabel_status === "รอดำเนินการ" && (
                                   <div className="button-group __submit row">
                                        <Col xs={4}>
                                             <Button onClick={this.handleClickCheckLabel}>Submit</Button>
                                        </Col>
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          );
     }
}
