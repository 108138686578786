import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Skeleton } from "../../../../components/index";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import { GET, POST } from "api/index";
import { getToken } from "../../../../static/function";

export default class Discount extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               newSearch: [],
               user: {},
               search: {
                    status: "รออนุมัติ",
               },
               branchs: [],
               loading: false,
          };
     }

     componentDidMount = async () => {
          await this.setState({ user: getToken() });
          // console.log("users: ", this.state.user);
          this.getOrder();
          this.getBranchs();
     };

     getOrder = async () => {
          this.setState({ loading: true });
          let { user } = this.state;
          try {
               let res = await POST("/order/discount", {
                    branch_id: Number(user.branch_id),
                    position_id: user.position_id,
                    user_id: user.user_id,
                    team_id: user.team_id,
               });
               this.setState({ order: res, loading: false });
               //===========================================//
               // let res = await GET("/order/");
               // let fn = await GET("/aos/get_function");
               // // console.log("fn", fn);
               // // console.log('res', res);
               // let order_search = res
               //      .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
               //      .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el))
               //      .filter((el) => el.acept_status !== "save" && el.acept_status !== "ยกเลิกใบจอง")
               //      .filter((el) =>
               //           user.position_id === 18
               //                ? Number(el.saler_name) === user.user_id
               //                : user.position_id === 16
               //                ? Number(el.team_id) === user.team_id &&
               //                  el.margin_used <= el.margin + el.addition_price &&
               //                  dayjs(el.created_at) >= dayjs("2023-05-05 13:00:00", "YYYY-MM-DD HH:mm:ss")
               //                : el
               //      );
               // let order = order_search
               //      .filter((el) =>
               //           user.position_id === fn[0].position_id
               //                ? dayjs(el.created_at) >= dayjs("2023-05-05 13:00:00", "YYYY-MM-DD HH:mm:ss")
               //                     ? el.margin_used > el.margin + el.addition_price && el.lvl_margin === 1
               //                     : el.lvl_margin === 1
               //                : user.position_id === fn[1].position_id
               //                ? el.lvl_margin === 2
               //                : user.position_id === fn[2].position_id
               //                ? el.lvl_margin === 3
               //                : user.position_id === fn[3].position_id
               //                ? el.lvl_margin === 4
               //                : el
               //      )
               //      .filter((el) => el.status === "รออนุมัติ");
               // this.setState({ order, order_search });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };

     getBranchs = async () => {
          try {
               let branchs = await GET("/ps/get_branchs");
               branchs.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branchs: [...branchs] });
          } catch (error) {
               console.log(error);
          }
     };

     /** ---------------------- handle change ----------------- */
     handleChangeText = (e) => {
          let { search } = this.state;
          // console.log(e);
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
          // console.log(search);
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = date;
          this.setState({ ...search });
          // console.log(search);
     };

     /*----------------------Search Data--------------------------*/
     handleClickSearch = async () => {
          this.setState({ loading: true });
          let { order_search, search, user } = this.state;
          try {
               let res = await POST("/order/discount_search", {
                    branch_id: Number(user.branch_id),
                    position_id: user.position_id,
                    user_id: user.user_id,
                    team_id: user.team_id,
                    search,
               });
               this.setState({ order: res, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
          // let newSearch = order_search
          //      .filter(
          //           (el) =>
          //                (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
          //                (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
          //                (search.status ? (search.status === "ทั้งหมด" ? el : String(el.status) === String(search.status)) : el)
          //      )
          //      .filter(
          //           (el) =>
          //                (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
          //                (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
          //                (search.userinfo_a_card ? String(el.userinfo_a_card).indexOf(String(search.userinfo_a_card)) > -1 : el)
          //      );
          // this.setState({ newSearch });
     };

     printFormatter(cell, row, rowIndex, formatExtraData) {
          return <i className="fas fa-print" onClick={() => window.location.assign(`/admin/print/discount/0`)}></i>;
     }

     dateFormatter(cell, row, rowIndex, formatExtraData) {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     }

     statusBtnFormatter(cell, row, rowIndex, formatExtraData) {
          return <Button onClick={() => window.location.assign(`/admin/print/discount/0`)}>อนุมัติ</Button>;
     }

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => window.location.assign(`/admin/discount/${row.order_id}`)}>ทำรายการ</a>;
     };

     orderFormatter = (cell, row) => {
          if (cell) {
               return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
          } else {
               return <p>-</p>;
          }
     };

     render() {
          let { order, search, branchs, newSearch } = this.state;
          let table = {
               colunm: [
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "userinfo_a_card",
                         text: "A-Card No.",
                         headerClasses: "header-custom __btn-ss",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "branch_name",
                         text: "โชว์รูม",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "status",
                         text: "การอนุมัติ",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-ss",
                    },

                    // {
                    //      dataField: "acept_status",
                    //      text: "สถานะใบจอง",
                    //      formatter: (e) => (e ? e : "-"),
                    // },
               ],
               // data: newSearch.length > 0 ? newSearch : order,
               data: order,
               keyField: "preemtion_id",
          };

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: "อนุมัติใบจอง",
                              },
                         ]}
                         active={0}
                         button={[]}
                    />
                    <div className="content __main-panel discount">
                         <Search
                              type={"discount"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   branchs: branchs && branchs,
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.colunm} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.colunm} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
