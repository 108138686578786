import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './skeleton.scss'

export default class Skeleton extends Component {
    render() {
        let {type, column} = this.props;
        switch (type) {
            case 'index' : return (
                <div className="index __primary">
                    <div className='react-bootstrap-table'>
                        <Table className='table'>
                            <thead>
                                <tr>
                                    {column.map((col, index) => (
                                        <th key={index} className={col.headerClasses}>{col.text}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                {Array.from({length: column.length}).map((_, index) => (
                                    <td key={index}><div className='skeleton' /></td>
                                ))}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    
                </div>
            );
            case 'document' : return (
                <div className="index">
                    <div className='react-bootstrap-table'>
                        <Table className='table'>
                            <thead>
                                <tr>
                                    {column.map((col, index) => (
                                        <th key={index} className={col.headerClasses}>{col.text}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                {Array.from({length: column.length}).map((_, index) => (
                                    <td key={index}><div className='skeleton' /></td>
                                ))}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    
                </div>
            );
            default: return <></>
        }
        
    }
}
