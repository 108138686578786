import { Breadcrumbs, Table, Field, LabelBar, ModalPanel } from "components";
import { Button, Col, Modal, Row } from "react-bootstrap";
import React, { Component } from "react";
import dayjs from "dayjs";
import { GET, POST } from "api";
import swal from "sweetalert";

export default class RevenueReport extends Component {
     constructor(props) {
          super(props);
          this.state = {
               showModal: false,
               searchReport: { branch: [] },
               error_data: "",
          };
     }

     componentDidMount = async () => {
          let branch = await GET("/ps/get_branchs");
          this.setState({ branch, searchReport: { branch: branch.map((el) => el.branch_id) } });
     };

     modalViewReport = () => {
          let { searchReport, branch } = this.state;
          return (
               <div>
                    <Row>
                         <Field
                              name={"date_start"}
                              type={"date"}
                              placeholder={searchReport.date_start ? searchReport.date_start : "วว/ดด/ปป"}
                              title={"ประจำวันที่"}
                              md={6}
                              handleSelectDate={(date, dateString, name) => this.handleSelectDate(date, dateString, name)}
                         />
                         <Field
                              name={"date_end"}
                              type={"date"}
                              placeholder={searchReport.date_end ? searchReport.date_end : "วว/ดด/ปป"}
                              title={"ถึงวันที่"}
                              md={6}
                              handleSelectDate={(date, dateString, name) => this.handleSelectDate(date, dateString, name)}
                         />
                    </Row>
                    {/* <LabelBar
                         name={"branch_name"}
                         type={"radio"}
                         title={"สาขา"}
                         label={branch?.map((el) => el.branch_name)}
                         value={searchReport?.branch_name}
                         handleChangeText={this.onChangeCheckbox}
                    /> */}
                    <LabelBar
                         type={"checkbox"}
                         title={"สาขา"}
                         md={12}
                         name={"branch"}
                         label={branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                         // value={pay?.branch}
                         arrChecked={searchReport?.branch}
                         handleChangeText={this.onChangeCheckbox}
                    />
               </div>
          );
     };

     modalShow = (value, rowIndex) => {
          let { showModal } = this.state;
          this.setState({ showModal: !showModal, report_title: value, row_index: rowIndex + 1 });
     };

     /*--------------------Formatter--------------------*/
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.modalShow(`${row.report}`, rowIndex)}>ทำรายการ</a>;
     };

     /*--------------------Handle--------------------*/
     handleSelectDate = (date, dateString, name) => {
          let { searchReport } = this.state;
          searchReport[name] = dateString;
          this.setState({ searchReport });
     };

     onChangeCheckbox = ({ target: { name, value, checked } }) => {
          let { searchReport } = this.state;
          if (checked) {
               searchReport[name].push(Number(value));
               searchReport[name] = searchReport[name].sort((a, z) => a - z);
               this.setState({ searchReport });
          } else {
               let index = searchReport[name].findIndex((el) => el === Number(value));
               searchReport[name].splice(index, 1);
               searchReport[name] = searchReport[name].sort((a, z) => a - z);
               this.setState({ searchReport });
          }
     };

     handelClickViewReport = async () => {
          const { searchReport, row_index, report_title, branch } = this.state;
          try {
               // if (row_index === 1) {
               //      let res = await POST("/aos/report_revenue_one", searchReport);
               //      window.open(res);
               // } else if (row_index === 2) {
               //      let res = await POST("/aos/report_revenue_two", searchReport);
               //      window.open(res);
               // } else if (row_index === 3) {
               //      let res = await POST("/aos/report_revenue_three", searchReport);
               //      window.open(res);
               // } else if (row_index === 4) {
               //      let res = await POST("/aos/report_revenue_four", searchReport);
               //      window.open(res);
               // } else if (row_index === 5) {
               //      let res = await POST("/aos/report_revenue_five", searchReport);
               //      window.open(res);
               // } else if (row_index === 6) {
               //      let res = await POST("/aos/report_revenue_six", searchReport);
               //      window.open(res);
               // } else if (row_index === 7) {
               //      let res = await POST("/aos/report_revenue_seven", searchReport);
               //      window.open(res);
               // } else if (row_index === 8) {
               //      let res = await POST("/aos/report_revenue_eight", searchReport);
               //      window.open(res);
               // } else if (row_index === 9) {
               //      let res = await POST("/aos/report_revenue_nine", searchReport);
               //      window.open(res);
               // } else if (row_index === 10) {
               //      let res = await POST("/aos/report_revenue_ten", searchReport);
               //      window.open(res);
               if (searchReport.date_start && searchReport.date_end && searchReport.branch.length > 0) {
                    searchReport.type_excel = report_title;
                    let dataBranch = searchReport.branch.map((el) => ({
                         branch_name: branch.find((e) => e.branch_id == el).branch_name,
                         branch_id: branch.find((e) => e.branch_id == el).branch_id,
                    }));
                    // console.log(`searchReport`, { ...searchReport, branch: dataBranch });
                    let res = await POST("/ps/report_excel_revenue", { ...searchReport, branch: dataBranch });
                    window.open(res);
                    this.setState({ error_data: "" });
               } else {
                    this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
                    // swal({
                    //      button: "ตกลง",
                    //      text: "กรอกข้อมูลให้ครบ",
                    //      icon: "warning",
                    // });
               }
               // alert(`success${row_index}`);
               // this.modalShow();
          } catch (error) {
               this.setState({ error_data: error });
               // console.log("error", error);
               // swal({
               //      button: "ตกลง",
               //      text: "ไม่สามารถออกรายงานได้",
               //      icon: "warning",
               // });
          }
     };

     handleCloseModal = () => {
          let { searchReport } = this.state;
          this.setState({
               showModal: false,
               // searchReport: { ...searchReport, branch: [] },
               error_data: "",
          });
     };

     render() {
          let { showModal, report_title, error_data } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left  __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    { report: "รายงานภาษีขายค่าจองรถ" },
                    { report: "รายงานภาษีขายลดหนี้ค่าจอง" },
                    { report: "รายงานภาษีขายลดหนี้ค่ารถยนต์" },
                    { report: "รายงานภาษีขายค่ารถ" },
                    { report: "รายงานภาษีขายค่าอุปกรณ์ (TA)" },
                    { report: "รายงานภาษีขายค่าอุปกรณ์ (TS)" },
                    { report: "รายงานภาษีขายลดหนี้ค่าอุปกรณ์" },
                    { report: "รายงานการจ่ายเงินตัวแทนจดทะเบียน" },
                    { report: "รายงานภาษีค่าคอมไฟแนนซ์" },
                    { report: "รายงานภาษีขายลดหนี้ค่าคอมไฟแนนซ์" },
                    { report: "รายงานขายขาดทุน" },
               ],
               keyField: "no",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานสรรพากร" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <ModalPanel
                         type="input-check-box"
                         show={showModal}
                         title={report_title}
                         onHide={this.handleCloseModal}
                         body={this.modalViewReport()}
                         button={[{ name: "แสดงรายงาน", variant: "primary", onClick: () => this.handelClickViewReport() }]}
                         error_data={error_data}
                    />
               </div>
          );
     }
}
