import React, { Component } from "react";
import { GET } from "api/index";
import { Breadcrumbs, Table, Search, Skeleton, Image } from "components/index";
import dayjs from "dayjs";

export default class Tax extends Component {
     constructor(props) {
          super(props);
          this.state = {
               tax: [],
               search: {
                    // acept_status: "ส่งมอบรถแล้ว",
               },
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getTax();
     };
     getTax = async () => {
          try {
               this.setState({ loading: true });
               let result = await GET("/order/order_turn");
               result.sort((a, b) => b.order_id - a.order_id);
               this.setState({ tax: result, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          if (row.supplier_id) {
               return <a onClick={() => window.location.assign(`/admin/payment/tax/${row.supplier_id}/${row.order_id}`)}>ทำรายการ</a>;
          } else {
               return <a onClick={() => window.location.assign(`/admin/payment/tax/${0}/${row.order_id}/${row.car_turn_id}`)}>ทำรายการ</a>;
          }
     }
     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };

     //---------------------handleChange--------------------

     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({ ...search });
     };

     /*----------------------Search Data--------------------------*/
     handleClickSearch = () => {
          let { tax, search } = this.state;
          let newSearch = tax
               .filter(
                    (el) =>
                         (search.oder_turnpay_status
                              ? search.oder_turnpay_status === "ทั้งหมด"
                                   ? el
                                   : String(el.oder_turnpay_status) === String(search.oder_turnpay_status)
                              : el) && (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el)
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
               );
          this.setState({ newSearch });
     };

     render() {
          let { tax, search, newSearch } = this.state;
          const table = {
               column: [
                    {
                         dataField: "payment_list",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "Color",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "พนักงานขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "oder_turnpay_status",
                         text: "สถานะ",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: newSearch ? newSearch : tax,
               keyField: "tax",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "ใบกำกับภาษีกรณีเทิร์นสินค้า" }]} active={0} button={""} />
                    <div className="content __main-panel tax">
                         <Search
                              type={"tax"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
