import React, { Component } from "react";
import { Breadcrumb, Button, Col } from "react-bootstrap";

export default class Step extends Component {
     render() {
          let { step } = this.props;
          return (
               <Breadcrumb className="step">
                    {step.map((st, i) => (
                         <Breadcrumb.Item active={st.active} key={i}>
                              {st.activeBtn ? <b onClick={st.btn}>{st.title}</b> : <b>{st.title}</b>}
                         </Breadcrumb.Item>
                    ))}
               </Breadcrumb>
          );
     }
}
