import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel } from "components/index";
import { Form, Button, Col, Accordion } from "react-bootstrap";
import { GET, POST } from "api/index";
import swal from "sweetalert";
import { data } from "flickity";
import { getToken } from "../../../../static/function";

export default class AddAdmin extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               previous_path: this.props.match.params.list,
               title: [],
               adminInfo: {
                    status: "ใช้งาน",
               },
               branch: [],
               team: [],
               department: [],
               position: [],
               workflow: [],
               permission: [],
               permission_yes: [],
               show: false,
               title1: "",
               passwordField: {
                    typeInput: "password",
                    isHide: true,
               },
          };
     }

     componentDidMount = async () => {
          await Promise.all([this.setPath(), this.getData()]);
          if (this.props.match.params.id) {
               await this.setData(this.props.match.params.list);
          }
     };

     /* Set title from previous path */
     setPath = () => {
          let { manage } = this.props.match.params;
          // console.log(manage);
          let { previous_path, title1 } = this.state;
          switch (previous_path) {
               case "squard":
                    title1 = [
                         {
                              title: "รายการทีม",
                              onClick: () => this.props.history.goBack(),
                         },
                         {
                              title: manage === "add" ? "เพิ่มทีม" : "แก้ไขทีม",
                              // onClick: () => this.props.history.goBack(),
                         },
                    ];
                    break;

               case "user":
                    title1 = [
                         {
                              title: "รายการผู้ใช้งาน",
                              onClick: () => this.props.history.goBack(),
                         },
                         // { title: "รายการผู้ใช้งาน" }, { title: `${manage === "add" ? "เพิ่ม" : "แก้ไข"}ผู้ใช้งาน` }
                         {
                              title: manage === "add" ? "เพิ่มผู้ใช้งาน" : "แก้ไข",
                              // onClick: () => this.props.history.goBack(),
                         },
                    ];
               default:
                    break;
          }
          this.setState({ title1: title1 });
     };

     getData = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               let getTeam = await GET("/ps/get_teams");
               let get_department = await GET("/ps/get_department");
               let get_permission = await GET("/ps/get_permission");
               let getPosition = await GET("/ps/position");
               let getWorkFlow = await GET("/ps/workflow");
               // let get_functions = await GET("/ps/get_functions");
               let get_page_master = await GET("/aos/get_page_master");
               let get_function = await GET("/aos/get_function");
               if (this.props.match.params.list === "user") {
                    getBranch.unshift({ branch_id: 99, branch_name: "ส่วนกลาง" });
               }
               this.setState({
                    branch: [...getBranch],
                    team: [...getTeam],
                    department: [...get_department],
                    permission: [...get_permission],
                    position: [...getPosition],
                    workflow: [...getWorkFlow],
                    get_page_master: [...get_page_master],
                    get_function: [...get_function],
                    set_new_function: [...get_function],
               });
          } catch (error) {
               // console.log(error);
          }
     };
     setData = async (item) => {
          try {
               if (item === "user") {
                    let { id } = this.props.match.params;
                    let res = await POST("/aos/get_user_by", { user_id: id });
                    this.setState(
                         {
                              adminInfo: res[0],
                              permission_yes: JSON.parse(res[0].pm_arr),
                         },
                         () => this.addTablePageMaster(JSON.parse(res[0].permission_page))
                    );
               } else {
                    let { id } = this.props.match.params;
                    let { adminInfo } = this.state;
                    let res = await POST("/aos/get_team_by", { team_id: id });
                    // console.log(`res`, res);

                    adminInfo["squard_name"] = res[0].team_name;
                    adminInfo["department_code"] = res[0].department_code;
                    adminInfo["department_id"] = res[0].department_id;
                    adminInfo["branch_id"] = res[0].branch_id;

                    this.setState(
                         {
                              adminInfo,
                              permission_yes: JSON.parse(res[0].pm_arr),
                              branch_id_old: res[0].branch_id,
                         },
                         () => this.addTablePageMaster(JSON.parse(res[0].permission_page))
                    );
                    // console.log(res);
               }
          } catch (error) {}
     };
     /* Validate field on submit */
     create_user = async (event) => {
          let { adminInfo, permission_yes, get_page_master, set_new_function } = this.state;
          let { manage, id } = this.props.match.params;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    button: "เสร็จสิ้น",
                    text: "กรอกให้ครบ",
                    icon: "warning",
               });
               event.preventDefault();
               event.stopPropagation();
          } else {
               event.preventDefault();
               try {
                    let permission_page = get_page_master.filter((el) => el.view || el.add || el.edit || el.delete || el.approve || el.report);
                    let fn_arr = set_new_function;
                    if (manage === "add") {
                         await POST("/aos/register", {
                              adminInfo,
                              permission_yes,
                              permission_page,
                              fn_arr,
                         });
                         swal({
                              button: "เสร็จสิ้น",
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                         }).then(() => this.props.history.push("/admin/user/admin-list"));
                    } else {
                         await POST("/aos/update_user", {
                              user_id: id,
                              adminInfo,
                              permission_yes,
                              permission_page,
                              fn_arr,
                         });
                         let user = getToken();
                         if (Number(id) === Number(user.user_id)) {
                              let res = await POST("/aos/get_user_token", {
                                   user_id: id,
                              });
                              localStorage.setItem("token", res.cf1);
                              localStorage.setItem("username", res.username);
                              localStorage.setItem("permission_page", res.permission_page);
                         }
                         swal({
                              button: "เสร็จสิ้น",
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                         }).then(() => window.location.reload());
                    }
               } catch (error) {
                    swal({
                         button: "เสร็จสิ้น",
                         text: error,
                         icon: "error",
                    });
                    // console.log("error", error);
               }
          }
          this.setState({
               validated: true,
          });
     };

     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { adminInfo } = this.state;
          adminInfo[name] = value;
          this.setState({ adminInfo });
          // console.log(name, value);
          if (name === "team_id") {
               this.setState({ permission_yes: [] }, () => this.setPerAndPage(value));
          }
     };

     handleChangeNumber = (value, i, name) => {
          let v = value.floatValue ? value.floatValue : 0;
          let { set_new_function } = this.state;
          let news = set_new_function.map((el, index) => {
               if (index === i) {
                    let obj = { ...el, [name]: v };
                    return obj;
               } else {
                    return el;
               }
          });
          this.setState({ set_new_function: news });
     };

     checkBoxAccessFormatter = (cell, rowIndex, name) => {
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: null, name }]}
                    checked={cell === true ? true : false}
                    disabled={cell === "disabled" ? true : false}
                    handleChangeText={(e) => this.changePermissionPage(e, rowIndex, name)}
               />
          );
     };
     changePermissionPage = (e, i, name) => {
          let { get_page_master } = this.state;
          get_page_master[i][name] = e.target.checked;
          this.setState({ get_page_master });
     };

     inputFormatter = (cell, rowIndex, name) => {
          return (
               <Field
                    type={"number"}
                    name={name}
                    value={cell ? cell : "0"}
                    handleChangeNumber={(value) => this.handleChangeNumber(value, rowIndex, name)}
               />
          );
     };

     noFormatter(cell, row, rowIndex, formatExtraData) {
          return `${rowIndex + 1}.`;
     }

     handleDeleteUser = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         let { id } = this.props.match.params;
                         await POST("/aos/del_user", { id });
                         swal({
                              text: "ลบเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              window.location.assign(`/admin/user/admin-list`);
                         });
                    }
               });
          } catch (error) {}
     };

     handleDelete = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         let { id } = this.props.match.params;
                         await POST("/aos/del_team", { id });
                         swal({
                              text: "ลบเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              window.location.assign(`/admin/user/squard-list`);
                         });
                    }
               });
          } catch (error) {}
     };

     setPerAndPage = async (value) => {
          let { team, permission, get_page_master } = this.state;
          let pm_arr = value ? JSON.parse(team.find((e) => e.team_id === Number(value)).pm_arr) : [];
          let newPage = get_page_master.map((el) => ({
               ...el,
               add: false,
               approve: false,
               delete: false,
               edit: false,
               report: false,
               view: false,
          }));
          await this.setState({ permission_yes: pm_arr, get_page_master: newPage });
          //----------------------------------------------------------------------------
          for (let index = 0; index < pm_arr.length; index++) {
               const e = pm_arr[index];
               let arr = JSON.parse(permission.filter((el) => el.permission_id === Number(e))[0].permission_page);
               let newArr = this.state.get_page_master.map((el) => {
                    let some = arr.filter((e) => e.id === el.id);
                    if (some[0]) {
                         let obj = {
                              ...el,
                              add: some[0].add ? some[0].add : el.add,
                              approve: some[0].approve ? some[0].approve : el.approve,
                              delete: some[0].delete ? some[0].delete : el.delete,
                              edit: some[0].edit ? some[0].edit : el.edit,
                              report: some[0].report ? some[0].report : el.report,
                              view: some[0].view ? some[0].view : el.view,
                         };
                         return obj;
                    } else return el;
               });
               await this.setState({ get_page_master: newArr });
          }
     };
     onChangeCheckbox = async (e) => {
          let { permission, get_page_master, permission_yes } = this.state;
          if (e.target.checked) {
               permission_yes.push(Number(e.target.value));
               let arr = JSON.parse(permission.filter((el) => el.permission_id === Number(e.target.value))[0].permission_page);
               let newPage = get_page_master.map((el) => {
                    let some = arr.filter((e) => e.id === el.id);
                    if (some[0]) {
                         let obj = {
                              ...el,
                              add: some[0].add ? some[0].add : el.add,
                              approve: some[0].approve ? some[0].approve : el.approve,
                              delete: some[0].delete ? some[0].delete : el.delete,
                              edit: some[0].edit ? some[0].edit : el.edit,
                              report: some[0].report ? some[0].report : el.report,
                              view: some[0].view ? some[0].view : el.view,
                         };
                         return obj;
                    } else return el;
               });
               this.setState({ get_page_master: newPage, permission_yes });
          } else {
               permission_yes.splice(
                    permission_yes.findIndex((ele) => ele === Number(e.target.value)),
                    1
               );
               let checkArr = [];
               for (let index = 0; index < permission_yes.length; index++) {
                    const element = permission_yes[index];
                    checkArr = checkArr.concat(JSON.parse(permission.filter((el) => el.permission_id === Number(element))[0].permission_page));
               }
               let arr = JSON.parse(permission.filter((el) => el.permission_id === Number(e.target.value))[0].permission_page);
               let newPage = get_page_master.map((el) => {
                    let some = arr.filter((e) => e.id === el.id);
                    if (!checkArr.some((e) => e.id === el.id) && some[0]) {
                         let obj = {
                              ...el,
                              add: some[0].add ? false : el.add,
                              approve: some[0].approve ? false : el.approve,
                              delete: some[0].delete ? false : el.delete,
                              edit: some[0].edit ? false : el.edit,
                              report: some[0].report ? false : el.report,
                              view: some[0].view ? false : el.view,
                         };
                         return obj;
                    } else return el;
               });
               this.setState({ get_page_master: newPage, permission_yes });
          }
     };

     addTablePageMaster = async (arr) => {
          let { get_page_master } = this.state;
          let newPage = get_page_master.map((el) => {
               let some;
               if (arr) some = arr.filter((e) => e.id === el.id);
               if (some && some[0]) {
                    let obj = {
                         ...el,
                         add: some[0].add,
                         approve: some[0].approve,
                         delete: some[0].delete,
                         edit: some[0].edit,
                         report: some[0].report,
                         view: some[0].view,
                    };
                    return obj;
               } else return el;
          });
          this.setState({ get_page_master: newPage });
     };

     togglePassword = () => {
          let { passwordField } = this.state;
          if (passwordField.isHide) {
               passwordField.isHide = false;
               passwordField.typeInput = "text";
          } else {
               passwordField.isHide = true;
               passwordField.typeInput = "password";
          }
          this.setState({ ...this.state, passwordField });
          // console.log(this.state.passwordField);
     };
     create_team = async (event) => {
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    button: "เตือน",
                    text: "กรอกให้ครบ",
                    icon: "warning",
               });
               event.preventDefault();
               event.stopPropagation();
          } else {
               event.preventDefault();
               try {
                    let { manage, id } = this.props.match.params;
                    let { adminInfo, permission_yes, get_page_master, branch_id_old } = this.state;
                    let permission_page = get_page_master.filter((el) => el.view || el.add || el.edit || el.delete || el.approve || el.report);
                    if (manage === "add") {
                         await POST("/aos/create_team", {
                              team_name: adminInfo.squard_name,
                              permission_yes,
                              branch_id: adminInfo.branch_id || null,
                              department_code: adminInfo.department_code || null,
                              department_id: adminInfo.department_id || null,
                              permission_page,
                         });
                         swal({
                              button: "เสร็จสิ้น",
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                         }).then(() => this.props.history.push("/admin/user/squard-list"));
                    } else {
                         let obj = {
                              team_id: id,
                              team_name: adminInfo.squard_name,
                              permission_yes,
                              branch_id: adminInfo.branch_id || null,
                              department_code: adminInfo.department_code || null,
                              department_id: adminInfo.department_id || null,
                              permission_page,
                         };
                         if (Number(branch_id_old) === Number(adminInfo.branch_id)) {
                              await POST("/aos/update_team", obj);
                              swal({
                                   button: "เสร็จสิ้น",
                                   text: "ทำรายการสำเร็จ",
                                   icon: "success",
                              }).then(() => window.location.reload());
                         } else {
                              swal({
                                   icon: "warning",
                                   title: "สาขามีการเปลี่ยนแปลง",
                                   text: "ต้องการเปลี่ยนแปลงสาขาของพนักงานขายในทีมทั้งหมดด้วยหรือไม่",
                                   buttons: {
                                        submit: { text: "เปลี่ยนแปลง", value: 1 },
                                        catch: { text: "ไม่เปลี่ยนแปลง", value: 2 },
                                   },
                              }).then(async (value) => {
                                   if (value == 1) {
                                        await POST("/aos/update_team", { ...obj, change: true });
                                        swal({
                                             button: "เสร็จสิ้น",
                                             text: "ทำรายการสำเร็จ",
                                             icon: "success",
                                        }).then(() => window.location.reload());
                                   } else if (value == 2) {
                                        await POST("/aos/update_team", obj);
                                        swal({
                                             button: "เสร็จสิ้น",
                                             text: "ทำรายการสำเร็จ",
                                             icon: "success",
                                        }).then(() => window.location.reload());
                                   } else {
                                   }
                              });
                         }
                    }
               } catch (error) {
                    swal({
                         button: "เสร็จสิ้น",
                         text: error,
                         icon: "error",
                    });
               }
          }
          this.setState({
               validated: true,
          });
     };
     marginFormat = (a, b, i) => {
          // console.log(a, b, c, d);
          let { position } = this.state;
          let position_new =
               position.length > 0
                    ? position.map((e) => ({
                           value: e.position_id,
                           name: e.position_name,
                      }))
                    : [];
          // console.log(`position_new`, position_new)
          position_new.unshift({ value: -99, name: "ปิดการใช้งาน" });
          return (
               <Field
                    type={"select"}
                    title={""}
                    name={"position_id"}
                    placeholder={"กรุณาเลือกตำแหน่ง"}
                    option={position_new}
                    value={b.position_id}
                    md={12}
                    handleChangeText={(e) => {
                         let name = e.target.name;
                         let v = e.target.value;
                         let { set_new_function } = this.state;
                         let news = set_new_function.map((el, index) => {
                              if (index === i) {
                                   let obj = { ...el, [name]: v };
                                   return obj;
                              } else {
                                   return el;
                              }
                         });
                         this.setState({ set_new_function: news, get_function: news });
                    }}
               />
          );
     };
     onLineNotify = async () => {
          let { adminInfo } = this.state;
          // console.log("adminInfo", adminInfo);
          try {
               let res = await POST("/aos/test_line", { u_id: adminInfo.u_id });
               // console.log("res", res);
               swal({ icon: "success", title: "สำเร็จ", text: res });
          } catch (error) {
               swal({ icon: "error", title: "ผิดพลาด", text: "ลองใหม่อีกครั้ง" });
               console.log("error", error);
          }
     };
     render() {
          let {
               validated,
               title,
               previous_path,
               branch,
               team,
               department,
               adminInfo,
               position,
               permission,
               permission_yes,
               title1,
               passwordField,
               get_page_master,
               get_function,
          } = this.state;
          let { manage } = this.props.match.params;
          // console.log("get_function", get_function && get_function);
          // let get_function_margin = get_function ? get_function.unshift({}) : [];
          const data_function = {
               column: [
                    {
                         dataField: "no",
                         text: "No.",
                         formatter: this.noFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "function_name",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "function_name",
                         text: "ตำแหน่ง",
                         formatter: this.marginFormat,
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "min",
                         text: "ค่าต่ำสุด",
                         formatter: (a, b, c) => this.inputFormatter(a, c, "min"),
                         headerClasses: "header-custom __right __btn-s",
                    },
                    {
                         dataField: "max",
                         text: "ค่าสูงสุด",
                         formatter: (a, b, c) => this.inputFormatter(a, c, "max"),
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: get_function ? get_function : [],
          };

          const data_page_master = {
               column: [
                    {
                         dataField: "no",
                         text: "No.",
                         formatter: this.noFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "page_name",
                         text: "Pages",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "view",
                         text: "View",
                         formatter: (a, b, c) => this.checkBoxAccessFormatter(a, c, "view"),
                         headerClasses: "header-custom __checkAcc __icon",
                    },
                    {
                         dataField: "add",
                         text: "Add",
                         formatter: (a, b, c) => this.checkBoxAccessFormatter(a, c, "add"),
                         headerClasses: "header-custom __checkAcc __icon",
                    },
                    {
                         dataField: "edit",
                         text: "Edit",
                         formatter: (a, b, c) => this.checkBoxAccessFormatter(a, c, "edit"),
                         headerClasses: "header-custom __checkAcc __icon",
                    },
                    {
                         dataField: "delete",
                         text: "Delete",
                         formatter: (a, b, c) => this.checkBoxAccessFormatter(a, c, "delete"),
                         headerClasses: "header-custom __checkAcc __icon",
                    },
                    {
                         dataField: "approve",
                         text: "Approve",
                         formatter: (a, b, c) => this.checkBoxAccessFormatter(a, c, "approve"),
                         headerClasses: "header-custom __checkAcc __icon",
                    },
                    {
                         dataField: "report",
                         text: "Report",
                         formatter: (a, b, c) => this.checkBoxAccessFormatter(a, c, "report"),
                         headerClasses: "header-custom __checkAcc __icon",
                    },
               ],
               data: get_page_master ? get_page_master : [],
          };

          return (
               <div className="panel">
                    {title1 ? <Breadcrumbs title={title1} active={1} button={""} /> : null}
                    {previous_path === "user" && (
                         <Form noValidate validated={validated} onSubmit={this.create_user}>
                              <div className="content __input-panel">
                                   {/* ข้อมูลผู้ใช้งาน */}
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>ข้อมูลผู้ใช้งาน</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <div>
                                                       <Form.Row>
                                                            <Field
                                                                 type={"text"}
                                                                 title={"Username"}
                                                                 name={"username"}
                                                                 placeholder={"กรุณากรอก Username"}
                                                                 require={true}
                                                                 value={adminInfo?.username}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"รหัสผ่าน"}
                                                                 name={"password"}
                                                                 placeholder={"กรุณากรอก รหัสผ่าน"}
                                                                 typeInput={passwordField.typeInput}
                                                                 require={true}
                                                                 value={adminInfo?.password}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                                 isHide={passwordField.isHide}
                                                                 togglePassword={this.togglePassword}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"ชื่อจริง"}
                                                                 name={"firstname"}
                                                                 placeholder={"กรุณากรอก ชื่อจริง"}
                                                                 require={true}
                                                                 value={adminInfo?.firstname}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"นามสกุล"}
                                                                 name={"lastname"}
                                                                 placeholder={"กรุณากรอก นามสกุล"}
                                                                 require={true}
                                                                 value={adminInfo?.lastname}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"Employee No."}
                                                                 name={"employee_no"}
                                                                 placeholder={"กรุณากรอก หมายเลขประจำตัว"}
                                                                 // require={true}
                                                                 value={adminInfo?.employee_no}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"โชว์รูม"}
                                                                 name={"branch_id"}
                                                                 placeholder={"กรุณาเลือกโชว์รูม"}
                                                                 option={branch?.map((e) => ({
                                                                      value: e.branch_id,
                                                                      name: e.branch_name,
                                                                 }))}
                                                                 value={adminInfo?.branch_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"ทีม"}
                                                                 name={"team_id"}
                                                                 placeholder={"กรุณาเลือกทีม"}
                                                                 option={team?.map((e) => ({
                                                                      value: e.team_id,
                                                                      name: e.team_name,
                                                                 }))}
                                                                 value={adminInfo?.team_id}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"แผนก"}
                                                                 name={"department_id"}
                                                                 placeholder={"กรุณาเลือกแผนก"}
                                                                 option={department?.map((e) => ({
                                                                      value: e.department_id,
                                                                      name: e.department_name,
                                                                 }))}
                                                                 value={adminInfo?.department_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"ตำแหน่ง"}
                                                                 name={"position_id"}
                                                                 placeholder={"กรุณาเลือกตำแหน่ง"}
                                                                 option={position?.map((e) => ({
                                                                      value: e.position_id,
                                                                      name: e.position_name,
                                                                 }))}
                                                                 value={adminInfo?.position_id}
                                                                 // require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"E-mail"}
                                                                 name={"email"}
                                                                 placeholder={"กรุณากรอก E-mail"}
                                                                 require={true}
                                                                 value={adminInfo?.email}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"หมายเลขโทรศัพท์"}
                                                                 name={"phone"}
                                                                 placeholder={"กรุณากรอก หมายเลขโทรศัพท์"}
                                                                 require={true}
                                                                 value={adminInfo?.phone}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"ID Line"}
                                                                 name={"id_line"}
                                                                 placeholder={"กรุณากรอก ID Line"}
                                                                 require={true}
                                                                 value={adminInfo?.id_line}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <LabelBar
                                                                 type={"radio"}
                                                                 name={"status"}
                                                                 title={"สถานะ : "}
                                                                 label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                                 value={adminInfo?.status}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                       </Form.Row>
                                                       <LabelBar type={"title"} title={"ข้อมูลบัญชีธนาคาร"} />
                                                       <Form.Row>
                                                            <Field
                                                                 type={"text"}
                                                                 title={"ธนาคาร"}
                                                                 name={"bank"}
                                                                 placeholder={"กรุณากรอกธนาคาร"}
                                                                 // require={true}
                                                                 value={adminInfo?.bank}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"เลขบัญชีธนาคาร"}
                                                                 name={"bank_number"}
                                                                 placeholder={"กรุณากรอกเลขบัญชีธนาคาร"}
                                                                 // require={true}
                                                                 value={adminInfo?.bank_number}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"ชื่อบัญชี"}
                                                                 name={"name_book"}
                                                                 placeholder={"กรุณากรอกชื่อบัญชี"}
                                                                 // require={true}
                                                                 value={adminInfo?.name_book}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                       </Form.Row>
                                                       <LabelBar type={"title"} title={"LINE Notify"} />
                                                       <strong>U-ID : {adminInfo.u_id ? adminInfo.u_id : "กรุณาลงทะเบียน"}</strong>
                                                       <div className="button-group __submit row">
                                                            <Col xs={6}>
                                                                 <Button onClick={this.onLineNotify} style={{ background: "green" }}>
                                                                      ทดสอบแจ้งเตือน
                                                                 </Button>
                                                            </Col>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   {/* ขอบเขตของการใช้งาน */}
                                   {/* {(Number(adminInfo?.position_id) === 1 ||
                                        Number(adminInfo?.position_id) === 2 ||
                                        Number(adminInfo?.position_id) === 9 ||
                                        Number(adminInfo?.position_id) === 17) && ( */}
                                   <Accordion defaultActiveKey="1">
                                        <div className="input-field">
                                             <Accordion.Toggle eventKey="0" className="-header">
                                                  <div className="-header d-flex justify-content-center">
                                                       <div className="mr-2">การอนุมัติ Margin</div>
                                                       <i className="fas fa-sort-down"></i>
                                                  </div>
                                             </Accordion.Toggle>
                                             <Accordion.Collapse eventKey="0" className="-body">
                                                  <div className="-body">
                                                       <div className="content" id="table-content">
                                                            <div className="-padding">
                                                                 <div>
                                                                      <Table
                                                                           type={"document"}
                                                                           add={"text"}
                                                                           column={data_function.column}
                                                                           data={data_function.data}
                                                                           keyField={"function"}
                                                                      />
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </Accordion.Collapse>
                                        </div>
                                   </Accordion>
                                   {/* )} */}
                                   {/* กำหนดสิทธ์ */}
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>กำหนดสิทธ์</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <div>
                                                       <Form.Row>
                                                            <LabelBar
                                                                 md={4}
                                                                 type={"checkbox"}
                                                                 //  title={"กำหนดสิทธ์ :"}
                                                                 label={permission.map((e) => ({
                                                                      value: e.permission_id,
                                                                      name: e.permission_name,
                                                                 }))}
                                                                 arrChecked={permission_yes}
                                                                 handleChangeText={this.onChangeCheckbox}
                                                            />
                                                       </Form.Row>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <Accordion defaultActiveKey="1">
                                        <div className="input-field">
                                             <Accordion.Toggle eventKey="0" className="-header">
                                                  <div className="-header d-flex justify-content-center">
                                                       <div className="mr-2">ขอบเขตการเข้าถึง</div>
                                                       <i className="fas fa-sort-down"></i>
                                                  </div>
                                             </Accordion.Toggle>
                                             <Accordion.Collapse eventKey="0" className="-body">
                                                  <div className="-body">
                                                       <div className="content" id="table-content">
                                                            <div className="-padding">
                                                                 <div>
                                                                      <Table
                                                                           type={"document"}
                                                                           add={"text"}
                                                                           column={data_page_master.column}
                                                                           data={data_page_master.data}
                                                                           keyField={"function"}
                                                                      />
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </Accordion.Collapse>
                                        </div>
                                   </Accordion>

                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             <Button type="submit">{manage === "add" ? "เพิ่มผู้ใช้งาน" : "บันทึกการแก้ไข"}</Button>
                                        </Col>
                                        <Col xs={6}>
                                             <Button variant="danger" onClick={this.handleDeleteUser}>
                                                  ลบ
                                             </Button>
                                        </Col>
                                   </div>
                              </div>
                         </Form>
                    )}
                    {previous_path === "squard" && (
                         <Form noValidate validated={validated} onSubmit={this.create_team}>
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>{title[1]}</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <div>
                                                       <Form.Row>
                                                            <Field
                                                                 value={adminInfo.squard_name}
                                                                 type={"text"}
                                                                 title={"ชื่อทีม"}
                                                                 name={"squard_name"}
                                                                 placeholder={"กรุณากรอก ชื่อทีม"}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"โชว์รูม"}
                                                                 name={"branch_id"}
                                                                 placeholder={"กรุณาเลือกโชว์รูม"}
                                                                 option={branch?.map((e) => ({
                                                                      value: e.branch_id,
                                                                      name: e.branch_name,
                                                                 }))}
                                                                 value={adminInfo?.branch_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 value={adminInfo.department_code}
                                                                 type={"text"}
                                                                 title={"Department Code"}
                                                                 name={"department_code"}
                                                                 placeholder={"กรุณากรอก Department Code"}
                                                                 // require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"Department (แผนก)"}
                                                                 name={"department_id"}
                                                                 placeholder={"กรุณากรอก Department (แผนก)"}
                                                                 option={department?.map((e) => ({
                                                                      value: e.department_id,
                                                                      name: e.department_name,
                                                                 }))}
                                                                 value={adminInfo?.department_id}
                                                                 // require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                       </Form.Row>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   {/* ขอบเขตของการใช้งาน */}
                                   {/* {(Number(adminInfo?.position_id) === 1 ||
                                        Number(adminInfo?.position_id) === 2 ||
                                        Number(adminInfo?.position_id) === 9 ||
                                        Number(adminInfo?.position_id) === 17) && ( */}
                                   {/* <Accordion defaultActiveKey="1">
                                        <div className="input-field">
                                             <Accordion.Toggle eventKey="0" className="-header">
                                                  <div className="-header d-flex justify-content-center">
                                                       <div className="mr-2">การอนุมัติ Margin</div>
                                                       <i className="fas fa-sort-down"></i>
                                                  </div>
                                             </Accordion.Toggle>
                                             <Accordion.Collapse eventKey="0" className="-body">
                                                  <div className="-body">
                                                       <div className="content">
                                                            <div>
                                                                 <Table
                                                                      type={"document"}
                                                                      add={"text"}
                                                                      column={data_function.column}
                                                                      data={data_function.data}
                                                                      keyField={"function"}
                                                                 />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </Accordion.Collapse>
                                        </div>
                                   </Accordion> */}
                                   {/* )} */}
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>กำหนดสิทธ์</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <div>
                                                       <Form.Row>
                                                            <LabelBar
                                                                 md={4}
                                                                 type={"checkbox"}
                                                                 //  title={"กำหนดสิทธ์ :"}
                                                                 label={permission.map((e) => ({
                                                                      value: e.permission_id,
                                                                      name: e.permission_name,
                                                                 }))}
                                                                 arrChecked={permission_yes}
                                                                 handleChangeText={this.onChangeCheckbox}
                                                            />
                                                       </Form.Row>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <Accordion defaultActiveKey="1">
                                        <div className="input-field">
                                             <Accordion.Toggle eventKey="0" className="-header">
                                                  <div className="-header d-flex justify-content-center">
                                                       <div className="mr-2">ขอบเขตการเข้าถึง</div>
                                                       <i className="fas fa-sort-down"></i>
                                                  </div>
                                             </Accordion.Toggle>
                                             <Accordion.Collapse eventKey="0" className="-body">
                                                  <div className="-body">
                                                       <div className="content" id="table-content">
                                                            <div className="-padding">
                                                                 <Table
                                                                      type={"document"}
                                                                      add={"text"}
                                                                      column={data_page_master.column}
                                                                      data={data_page_master.data}
                                                                      keyField={"function"}
                                                                 />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </Accordion.Collapse>
                                        </div>
                                   </Accordion>

                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             <Button type="submit">{manage === "add" ? "เพิ่มทีม" : "บันทึกการแก้ไข"}</Button>
                                        </Col>
                                        <Col xs={6}>
                                             <Button variant="danger" onClick={this.handleDelete}>
                                                  ลบ
                                             </Button>
                                        </Col>
                                   </div>
                              </div>
                         </Form>
                    )}
               </div>
          );
     }
}
