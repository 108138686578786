import { Field } from "components";
import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
// import ThaiBaht from "thai-baht-text";
import { tofieds, ThaiBaht } from "../../static/function";

export default class FormTable extends Component {
     render() {
          let { type, order, column, data, item, formdata, handleChangeNumber } = this.props;
          // console.log(formdata);
          // console.log(order);

          let price = 0.0;
          let vat = 0.0;
          let total = 0.0;

          // acc payment
          let price_acc = 0;
          let vat_acc = 0;
          let total_acc = 0;
          for (let i in order?.item) {
               price_acc += order.item[i].amount ? order.item[i].amount * order.item[i].items_price : 1 * order.item[i].items_price;
          }
          vat_acc = (price_acc * 7) / 100;
          total_acc = price_acc + vat_acc;

          switch (type) {
               case "car-payment":
                    return (
                         <Container className="tb" style={{ marginLeft: "0", maxWidth: "98%" }}>
                              <div className="-padding">
                                   <Row>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ลำดับ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>รหัสสินค้า</strong>
                                        </Col>
                                        <Col className="cell-form-table header">
                                             <strong>รายการสินค้า</strong>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ปริมาณ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>ราคาต่อหน่วย</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header" id="table__last-cell">
                                             <strong>จำนวนเงิน</strong>
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col xs={1} className="cell-form-table header">
                                             1
                                        </Col>
                                        <Col xs={2} className="cell-form-table"></Col>
                                        <Col className="cell-form-table">
                                             <>
                                                  <p>
                                                       ค่ารถยนต์ TOYOTA รายละเอียด ดังนี้
                                                       <br />- รุ่น {order.series_name} {order.model_name}
                                                       <br />- โมเดล {order.model_code}
                                                       <br />- สี {order.color_name}
                                                       <br />- หมายเลขเครื่องยนต์ {order.car_engine}
                                                       <br />- หมายเลขตัวถัง {order.car_tank}
                                                       <br />- ชื่อผู้ซื้อ {order.userinfo_name}
                                                  </p>
                                                  {(order.accbonus && order.accbonus.length > 0) ||
                                                  order.insurance_pay === "แถม" ||
                                                  order.register_pay === "บริษัท" ? (
                                                       <p>
                                                            พร้อมของแถมดังนี้
                                                            <br />
                                                            {order.accbonus && order.accbonus.length > 0
                                                                 ? order.accbonus.map((e) => (
                                                                        <>
                                                                             - {e.items_name} <br />
                                                                        </>
                                                                   ))
                                                                 : null}
                                                            {order.insurance_pay === "แถม" ? (
                                                                 <>
                                                                      - {order.insurance_type} {order.supplier_insurance_name}
                                                                 </>
                                                            ) : null}
                                                            <br />
                                                            {order.register === "Yes" && order.register_pay === "บริษัท" ? <>- ค่าจดทะเบียน</> : null}
                                                            <br />
                                                       </p>
                                                  ) : null}
                                             </>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">
                                             1
                                        </Col>
                                        <Col xs={2} className="cell-form-table num">
                                             {(Number(order.start_price) + Number(order.addition_price || 0)).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                             })}
                                        </Col>
                                        <Col xs={2} className="cell-form-table num" id="table__last-cell">
                                             {(Number(order.start_price) + Number(order.addition_price || 0)).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                             })}
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col className="cell-form-table"></Col>
                                        <Col xs={3} className="row-form-table noPadding">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมราคาสินค้า
                                                  </Col>
                                                  {/* <Col xs={12} className="cell-form-table">
                                                       ส่วนเพิ่ม
                                                  </Col> */}
                                                  <Col xs={12} className="cell-form-table">
                                                       ส่วนลด
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       ราคาสินค้ารวม
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       มูลค่าก่อนภาษีมูลค่าเพิ่ม
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       ภาษีมูลค่าเพิ่ม(VAT 7%)
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมทั้งสิ้น
                                                  </Col>
                                             </>
                                        </Col>
                                        <Col xs={2} className="num noPadding" id="table__last-cell">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       {(Number(order.start_price) + Number(order.addition_price || 0)).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  {/* <Col xs={12} className="cell-form-table">
                                                       {Number(order?.addition_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                  </Col> */}
                                                  <Col xs={12} className="cell-form-table">
                                                       {Number(order.discount_price ? order.discount_price : parseFloat(0).toFixed(2)).toLocaleString(
                                                            undefined,
                                                            {
                                                                 minimumFractionDigits: 2,
                                                                 maximumFractionDigits: 2,
                                                            }
                                                       )}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {Number(
                                                            Number(order.start_price) - Number(order.discount_price) + Number(order?.addition_price)
                                                       ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {Number(
                                                            Number(order.start_price) -
                                                                 Number(order.discount_price) +
                                                                 Number(order.addition_price) -
                                                                 ((Number(order.start_price) -
                                                                      Number(order.discount_price) +
                                                                      Number(order.addition_price)) *
                                                                      7) /
                                                                      107
                                                       ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {(
                                                            (Number(
                                                                 Number(order.start_price) -
                                                                      Number(order.discount_price) +
                                                                      Number(order.addition_price)
                                                            ) *
                                                                 7) /
                                                            107
                                                       ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {/* {Number(order?.start_price).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })} */}
                                                       {Number(
                                                            Number(order.start_price) - Number(order.discount_price) + Number(order.addition_price)
                                                       ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                             </>
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col xs={3} className="cell-form-table" id="table__last-row">
                                             <strong>จำนวนเงิน (บาท)</strong>
                                        </Col>
                                        <Col id="table__last-row">
                                             {ThaiBaht(Number(order.start_price) - Number(order.discount_price) + Number(order.addition_price))}
                                        </Col>
                                   </Row>
                              </div>
                         </Container>
                    );
               case "acc-car-payment":
                    return (
                         <Container className="tb" style={{ marginLeft: "0", maxWidth: "98%" }}>
                              <div className="-padding">
                                   <Row>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ลำดับ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>รหัสสินค้า</strong>
                                        </Col>
                                        <Col className="cell-form-table header">
                                             <strong>รายการสินค้า</strong>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ปริมาณ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>ราคาต่อหน่วย</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header" id="table__last-cell">
                                             <strong>จำนวนเงิน</strong>
                                        </Col>
                                   </Row>
                                   {/* <Row>
                                <Col xs={1} className="cell-form-table header">1</Col>
                                <Col xs={2} className="cell-form-table"></Col>
                                <Col className="cell-form-table">
                                    <>
                                        <p style={{ marginBottom: '0' }}>
                                            ค่าจองรถยนต์
                                        </p>
                                    </>
                                </Col>
                                <Col xs={1} className="cell-form-table header">1</Col>
                                <Col xs={2} className="cell-form-table num">{order?.order_price}</Col>
                                <Col xs={2} className="cell-form-table num" id="table__last-cell">{order?.order_price}</Col>
                            </Row> */}
                                   {order.item?.map((item, i) => (
                                        <Row>
                                             <Col xs={1} className="cell-form-table header">
                                                  {i + 1}
                                             </Col>
                                             <Col xs={2} className="cell-form-table" style={{ textAlign: "center" }}>
                                                  {item.items_id}
                                             </Col>
                                             <Col className="cell-form-table">
                                                  <>
                                                       <p style={{ marginBottom: "0" }}>{item.items_name}</p>
                                                  </>
                                             </Col>
                                             <Col xs={1} className="cell-form-table header">
                                                  {item.amount ? item.amount : 1}
                                             </Col>
                                             <Col xs={2} className="cell-form-table num">
                                                  {Number(item.items_price).toLocaleString(undefined, {
                                                       minimumFractionDigits: 2,
                                                       maximumFractionDigits: 2,
                                                  })}
                                             </Col>
                                             <Col xs={2} className="cell-form-table num" id="table__last-cell">
                                                  {Number(item.amount ? item.amount * item.items_price : 1 * item.items_price).toLocaleString(
                                                       undefined,
                                                       {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       }
                                                  )}
                                             </Col>
                                        </Row>
                                   ))}

                                   <Row>
                                        <Col className="cell-form-table"></Col>
                                        <Col xs={2} className="row-form-table noPadding">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมราคาสินค้า
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       ภาษีมูลค่าเพิ่ม 7%
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมทั้งสิ้น
                                                  </Col>
                                             </>
                                        </Col>
                                        <Col xs={2} className="num noPadding" id="table__last-cell">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       {/* {order?.acorder_price - order?.acorder_vat} */}
                                                       {Number((price_acc * 100) / 107).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {/* {order?.acorder_vat} */}
                                                       {Number(((price_acc * 100) / 107) * 0.07).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {/* {order?.acorder_price} */}
                                                       {Number(price_acc).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                             </>
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col xs={3} className="cell-form-table" id="table__last-row">
                                             <strong>จำนวนเงิน (บาท)</strong>
                                        </Col>
                                        <Col id="table__last-row">{ThaiBaht(price_acc)}</Col>
                                   </Row>
                              </div>
                         </Container>
                    );
               case "order-payment":
                    return (
                         <Container className="tb" style={{ marginLeft: "0", maxWidth: "98%" }}>
                              <div className="-padding">
                                   <Row>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ลำดับ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>รหัสสินค้า</strong>
                                        </Col>
                                        <Col className="cell-form-table header">
                                             <strong>รายการสินค้า</strong>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ปริมาณ</strong>
                                        </Col>
                                        <Col xs={3} className="cell-form-table header">
                                             <strong>ราคาต่อหน่วย</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header" id="table__last-cell">
                                             <strong>จำนวนเงิน</strong>
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col xs={1} className="cell-form-table header">
                                             1
                                        </Col>
                                        <Col xs={2} className="cell-form-table"></Col>
                                        <Col className="cell-form-table">
                                             <>
                                                  <p style={{ marginBottom: "0" }}>ค่าจองรถยนต์</p>
                                             </>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">
                                             1
                                        </Col>
                                        <Col xs={3} className="cell-form-table num">
                                             {(order?.order_price - order?.order_vat).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                        </Col>
                                        <Col xs={2} className="cell-form-table num" id="table__last-cell">
                                             {(order?.order_price - order?.order_vat).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                        </Col>
                                   </Row>

                                   <Row>
                                        <Col className="cell-form-table"></Col>
                                        <Col xs={3} className="row-form-table noPadding">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       มูลค่าก่อนภาษี
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       ภาษีมูลค่าเพิ่ม(VAT 7%)
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมทั้งสิ้น
                                                  </Col>
                                             </>
                                        </Col>
                                        <Col xs={2} className="num noPadding" id="table__last-cell">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       {(order?.order_price - order?.order_vat).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {Number(order?.order_vat).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {Number(order?.order_price).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                  </Col>
                                             </>
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col xs={3} className="cell-form-table" id="table__last-row">
                                             <strong>จำนวนเงิน (บาท)</strong>
                                        </Col>
                                        <Col id="table__last-row">{ThaiBaht(order?.order_price || 0)}</Col>
                                   </Row>
                              </div>
                         </Container>
                    );
               case "preemption_pending":
                    return (
                         <Container>
                              <Table striped bordered hover size="sm">
                                   <thead>
                                        <tr>
                                             {column.map((e) => (
                                                  <th>{e.text}</th>
                                             ))}
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {data.map((e, i) => (
                                             <tr>
                                                  <td>{e.supplier_name}</td>
                                                  <td>{e.product_list}</td>
                                                  <td>{e.type}</td>
                                                  <td>{e.cost_price}</td>
                                                  <td>{e.sell_price}</td>
                                             </tr>
                                        ))}
                                        <tr>
                                             <td></td>
                                             <td></td>
                                             <td>รวมราคาสินค้า</td>
                                             <td>0.00</td>
                                             <td>0.00</td>
                                        </tr>
                                        <tr>
                                             <td></td>
                                             <td></td>
                                             <td>ภาษีมูลค่าเพิ่ม 7 %</td>
                                             <td></td>
                                             <td>0.00</td>
                                        </tr>
                                        <tr>
                                             <td></td>
                                             <td></td>
                                             <td>รวมทั้งสิ้น</td>
                                             <td></td>
                                             <td>0.00</td>
                                        </tr>
                                   </tbody>
                              </Table>
                         </Container>
                    );
               case "tax":
                    return (
                         <Container className="tb" style={{ marginLeft: "0", maxWidth: "98%" }}>
                              <div className="-padding">
                                   <Row>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ลำดับ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>รหัสสินค้า</strong>
                                        </Col>
                                        <Col className="cell-form-table header">
                                             <strong>รายการสินค้า</strong>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">
                                             <strong>ปริมาณ</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header">
                                             <strong>ราคาต่อหน่วย</strong>
                                        </Col>
                                        <Col xs={2} className="cell-form-table header" id="table__last-cell">
                                             <strong>จำนวนเงิน</strong>
                                        </Col>
                                   </Row>
                                   {item?.map((e, i) => {
                                        let price_per_piece = e?.order_ac_price / e?.order_ac_amount;
                                        return (
                                             <Row>
                                                  <Col xs={1} className="cell-form-table header">
                                                       {i + 1}
                                                  </Col>
                                                  <Col xs={2} className="cell-form-table">
                                                       {e.items_code || "-"}
                                                  </Col>
                                                  <Col className="cell-form-table">
                                                       <>
                                                            <p style={{ marginBottom: "0" }}>{e.items_name}</p>
                                                       </>
                                                  </Col>
                                                  <Col xs={1} className="cell-form-table header">
                                                       {e?.order_ac_amount}
                                                  </Col>
                                                  <Col xs={2} className="cell-form-table num">
                                                       {price_per_piece.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={2} className="cell-form-table num" id="table__last-cell">
                                                       {(price_per_piece * e.order_ac_amount).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                             </Row>
                                        );
                                   })}

                                   {item?.map((el, i) => {
                                        let price_per_piece = el?.order_ac_price / el?.order_ac_amount;
                                        price += price_per_piece * el.order_ac_amount;
                                        // vat += (price * 7) / 100;
                                        // total += price + vat;
                                   })}
                                   {/* {
                                test?.map((item, i) => (
                                    <Row>
                                        <Col xs={1} className="cell-form-table header">{item.index}</Col>
                                        <Col xs={2} className="cell-form-table">{item.id}</Col>
                                        <Col className="cell-form-table">
                                            <>
                                                <p style={{ marginBottom: '0' }}>
                                                    {item.list}
                                                </p>
                                            </>
                                        </Col>
                                        <Col xs={1} className="cell-form-table header">{item.amount}</Col>
                                        <Col xs={2} className="cell-form-table num">{item.price}</Col>
                                        <Col xs={2} className="cell-form-table num" id="table__last-cell">{item.total}</Col>
                                    </Row>
                                ))
                            } */}

                                   <Row>
                                        <Col className="cell-form-table"></Col>
                                        <Col xs={2} className="row-form-table noPadding">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมราคาสินค้า
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       ภาษีมูลค่าเพิ่ม 7%
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       รวมทั้งสิ้น
                                                  </Col>
                                             </>
                                        </Col>
                                        <Col xs={2} className="num noPadding" id="table__last-cell">
                                             <>
                                                  <Col xs={12} className="cell-form-table">
                                                       {price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {((price * 7) / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                                  <Col xs={12} className="cell-form-table">
                                                       {(price + (price * 7) / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                  </Col>
                                             </>
                                        </Col>
                                   </Row>
                                   <Row>
                                        <Col xs={3} className="cell-form-table" id="table__last-row">
                                             <strong>จำนวนเงิน (บาท)</strong>
                                        </Col>
                                        <Col id="table__last-row">{ThaiBaht(price + (price * 7) / 100)}</Col>
                                   </Row>
                              </div>
                         </Container>
                    );

               case "commission":
                    return (
                         <Table striped bordered hover className="form-table">
                              <thead>
                                   <tr className="tr __center">
                                        <th className="__orange" colspan="9">
                                             {formdata.header1}
                                        </th>
                                        <th className="__gold" colspan="7">
                                             {formdata.header2}
                                        </th>
                                        <th className="__green" colspan="4">
                                             {formdata.header3}
                                        </th>
                                        <th className="__sky" colspan="3">
                                             {formdata.header4}
                                        </th>
                                        <th className="__violet" colspan="4">
                                             {formdata.header5}
                                        </th>
                                        <th className="__blue" colspan="1" rowSpan="2">
                                             {formdata.header6}
                                        </th>
                                        <th className="__blue -width" colspan="1" rowSpan="2">
                                             {formdata.header7}
                                        </th>
                                   </tr>
                                   <tr className="tr-2">
                                        {formdata.th1.map((el, i) => (
                                             <th className="__orange-sec" key={i}>
                                                  {el}
                                             </th>
                                        ))}
                                        {formdata.th2.map((el, i) => (
                                             <th className="__gold-sec" key={i}>
                                                  {el}
                                             </th>
                                        ))}
                                        {formdata.th3.map((el, i) => (
                                             <th className="__green-sec" key={i}>
                                                  {el}
                                             </th>
                                        ))}
                                        {formdata.th4.map((el, i) => (
                                             <th className="__sky-sec" key={i}>
                                                  {el}
                                             </th>
                                        ))}
                                        {formdata.th5.map((el, i) => (
                                             <th className="__violet-sec" key={i}>
                                                  {el}
                                             </th>
                                        ))}
                                   </tr>
                              </thead>
                              <tbody>
                                   {data &&
                                        data.map((el, i) => (
                                             <tr>
                                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>{i + 1}</td>
                                                  <td>{el.order_code_id}</td>
                                                  <td>{el.delivery_date}</td>
                                                  <td>{el.customer_name}</td>
                                                  <td>{el.series_name}</td>
                                                  <td>{el.model_name}</td>
                                                  <td>{tofieds(el.margin)}</td>
                                                  <td>{tofieds(el.acept_margin)}</td>
                                                  <td>{tofieds(el.addition_price)}</td>
                                                  <td>{tofieds(el.sub_down)}</td>
                                                  <td>{tofieds(el.subsidy)}</td>
                                                  <td>{tofieds(el.recommend_price)}</td>
                                                  <td>{tofieds(el.discount_price)}</td>
                                                  <td>{tofieds(el.acc_price)}</td>
                                                  <td>{tofieds(el.insurance_premium)}</td>
                                                  <td>{tofieds(el.register_price)}</td>
                                                  <td>{tofieds(el.margin_balance)}</td>
                                                  <td>{tofieds(el.margin10_pse)}</td>
                                                  <td>{tofieds(el.margin90_sales)}</td>
                                                  <td>{tofieds(el.margin_salesman)}</td>
                                                  <td>{tofieds(el.commission_car)}</td>
                                                  <td>{tofieds(el.commission_extra)}</td>
                                                  <td className="field-table">
                                                       <Field
                                                            name="s"
                                                            value={order ? order[i].s : undefined}
                                                            type={"number"}
                                                            placeholder={"0.00"}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => handleChangeNumber(e.floatValue, "s", i)}
                                                       />
                                                  </td>
                                                  <td className="field-table">
                                                       <Field
                                                            name="t"
                                                            value={order ? order[i].t : undefined}
                                                            type={"number"}
                                                            placeholder={"0.00"}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => handleChangeNumber(e.floatValue, "t", i)}
                                                       />
                                                  </td>
                                                  <td className="field-table">
                                                       <Field
                                                            name="u"
                                                            value={order ? order[i].u : undefined}
                                                            type={"number"}
                                                            placeholder={"0.00"}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => handleChangeNumber(e.floatValue, "u", i)}
                                                       />
                                                  </td>
                                                  <td>{tofieds(el.sub_add_15)}</td>
                                                  <td>{tofieds(el.cost_move_car)}</td>
                                                  <td className="field-table">
                                                       <Field
                                                            name="w"
                                                            type={"number"}
                                                            value={order ? order[i].w : undefined}
                                                            placeholder={"0.00"}
                                                            decimalScale={2}
                                                            handleChangeNumber={(e) => handleChangeNumber(e.floatValue, "w", i)}
                                                       />
                                                  </td>
                                                  {/* <td>{tofieds(el.total_sales + (order ? order[i].s + order[i].w - (order[i].t + order[i].u) : 0))}</td> */}
                                                  <td>{tofieds(el.total_sales + el.s + el.w - (el.t + el.u))}</td>
                                             </tr>
                                        ))}
                              </tbody>
                         </Table>
                    );
          }
     }
}
