import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { POST, ip } from "api/index";
import axios from "axios";
import swal from "@sweetalert/with-react";
import { getToken } from "../../../../static/function";
import dayjs from "dayjs";

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class GenerateDiscount extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               order: {
                    accbonus: [],
                    accturn: [],
               },
               fileURL: "",
               fileURLMargin: "",
               accept: false,
               over_margin: 0,
               user: {},
               allow: false,
               forward_role: "",
               forward: {
                    check: false,
               },
               status: "",
          };
     }

     componentDidMount = async () => {
          this.setState({ user: getToken() });
          this.getOrder();
          this.getOrderPdf();
          this.setPermission();
     };

     setPermission = () => {
          let { over_margin, user, allow, forward_role } = this.state;

          if (user.position_name === "หัวหน้าทีมขาย") {
               forward_role = "ผู้จัดการฝ่ายขาย";
               if (over_margin >= 0 && over_margin <= 3000) allow = true;
               else allow = false;
          } else if (user.position_name === "ผู้จัดการฝ่ายขาย") {
               forward_role = "ผู้อำนวยการฝ่ายขาย";
               if (over_margin >= 3001 && over_margin <= 10000) allow = true;
               else allow = false;
          } else if (user.position_name === "ผู้อำนวยการฝ่ายขาย") {
               forward_role = "กรรมการผู้บริหาร";
               if (over_margin >= 10001 && over_margin <= 30000) allow = true;
               else allow = false;
          } else if (user.position_name === "กรรมการผู้บริหาร") {
               if (over_margin > 30000) allow = true;
               else allow = false;
          } else {
               allow = false;
          }

          if (user.position_name === "ผู้ดูแลระบบ") {
               allow = true;
          }

          this.setState({ allow, forward_role });
     };

     getOrder = async () => {
          try {
               let order = await POST("/order/this", { order_id: this.state.id });
               //Sum AC Bonus
               let acc_price = 0;
               order.accbonus.map((el) => {
                    acc_price += JSON.parse(el.price_list).price_add_old * el.order_ac_amount;
               });

               //Total Price
               acc_price +=
                    Number(order?.discount_price) +
                    Number(order?.insurance === "Yes" ? order?.insurance_premium : 0) +
                    Number(order?.recommend_price) +
                    Number(order?.other_pay_price) +
                    (order?.register === "Yes" ? Number(order?.register_price) : 0);

               //Margin Over
               let over_margin = acc_price - Number(order.margin);

               this.setState({ order: order, status: "", over_margin: over_margin });
          } catch (error) {
               console.log(error);
          }
     };

     getOrderPdf = async () => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/order_leadsing_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              fileURL: fileURL,
                         });
                    });
          } catch (error) {}
     };

     downloadPdf = async () => {
          let { carorder } = this.state;
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/order_margin_pdf`,
                         { order_id: this.state.id, type: "ต้นฉบับ" },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {}
     };

     handleSubmit = async () => {
          try {
               let { order, status } = this.state;
               order.status = status;
               order.acept_margin = order.acept_margin ? order.acept_margin : 0;

               await POST("/order/accept_discount", order);

               await axios
                    .post(
                         `${ip}/order/order_margin_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         order.acept_status = "ใบจองอนุมัติแล้ว";

                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              fileURLMargin: fileURL,
                              order,
                         });

                         swal({
                              icon: "success",
                              text: "ดำเนินการเรียบร้อย",
                              content: (
                                   <a href={fileURL}>
                                        <i className="fas fa-print icon __btn active" /> พิมพ์ใบอนุมัติการลดเกิน Margin / ขอเครดิต
                                   </a>
                              ),
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         }).then(() => {
                              window.location.reload();
                         });
                         // this.setState({ order: await POST("/order/this", { order_id: this.state.id }) });
                    });
          } catch (error) {
               console.log(error);
          }
     };
     /* ----------------------- onChange -------------------- */
     handleChangeNumber = (e, name) => {
          let { order } = this.state;
          order[name] = e.floatValue;
          this.setState({ order });
     };

     handleChangeRadio = (e) => {
          // let { order,status } = this.state;
          // if (e.target.checked) {
          //      order[e.target.name] = e.target.value;
          // }
          this.setState({ status: e.target.value });
     };
     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
     };

     handleChangeRadioForwardRole = (check, e) => {
          let { forward } = this.state;
          if (check) {
               forward.check = e.target.value;
          }
          this.setState({ forward });
     };

     /* ----------------------- formatter --------------------- */
     parseFloatFormatter = (cell, row, rowIndex, formatExtraData) => {
          let new_cell = parseFloat(cell).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          if (formatExtraData === "footer") {
               switch (row.cost_price) {
                    case "-":
                         return null;
                    default:
                         if (row.field === "summary-total") {
                              return <span className="text">{new_cell}</span>;
                         } else {
                              return new_cell;
                         }
               }
          } else {
               if (row.field === "summary-total") {
                    return <span className="text">{new_cell}</span>;
               } else {
                    return new_cell;
               }
          }
     };

     unitFormatter = () => {
          return <p>บาท</p>;
     };

     marginOver = (columnData, overSubdown, strSubNum) => {
          let total = columnData[columnData.length - 1] + columnData[columnData.length - 2] - columnData[columnData.length - 3];
          total = parseFloat(Math.abs(total)).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          return overSubdown ? `${strSubNum}\n${total}` : `${total}`;
     };

     render() {
          let { id, show, order, fileURL, fileURLMargin, allow, forward_role, forward } = this.state;

          let table_list = {
               column: [
                    {
                         dataField: "list",
                         text: "รายการ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "price",
                         text: "ราคาขาย",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         formatter: this.unitFormatter,
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: [
                    {
                         list: "ราคาตัวรถ",
                         price: order?.start_price,
                    },
                    {
                         list: "ราคาตัวรถเพิ่ม",
                         price: order?.addition_price ? order?.addition_price : 0,
                    },
                    {
                         list: "Margin",
                         price: order?.margin ? order?.margin : 0,
                    },
               ],
               keyField: "list",
          };
          // let table_listbonus = {
          //      column: [
          //           {
          //                dataField: 'list',
          //                text: 'รายการของแถม',
          //                footer: 'ส่วนเกิน Margin',
          //                headerClasses: 'header-custom __left',
          //                footerClasses: 'summary-custom __yellow',
          //           },
          //           {
          //                dataField: 'cost_price',
          //                text: 'ราคาทุน',
          //                formatter: this.parseFloatFormatter,
          //                formatExtraData: 'footer',
          //                footer: '',
          //                headerClasses: 'header-custom __btn-m',
          //                footerClasses: 'summary-custom __yellow',
          //           },
          //           {
          //                dataField: 'sell_price',
          //                text: 'ราคาขาย',
          //                formatter: this.parseFloatFormatter,
          //                headerClasses: 'header-custom __btn-m',
          //                footer: this.marginOver,
          //                footerClasses: 'summary-custom __yellow total',
          //           },
          //           {
          //                dataField: 'unit',
          //                text: 'หน่วย',
          //                formatter: this.unitFormatter,
          //                footer: 'บาท',
          //                headerClasses: 'header-custom __right __btn-m',
          //                footerClasses: 'summary-custom __yellow',
          //           },
          //      ],
          //      data: [
          //           ...order?.accbonus.map((el) => ({
          //                list: el.items_name,
          //                cost_price: JSON.parse(el.price_list).cost ? JSON.parse(el.price_list).cost : 0,
          //                sell_price: JSON.parse(el.price_list).price_add_new * el.order_ac_amount,
          //           })),
          //           { field: 'summary-detail', list: 'รวมราคาอุปกรณ์', sell_price: sumAccBonus, cost_price: '-' },
          //           { field: 'summary-detail', list: 'ส่วนลดตัวรถ', sell_price: Number(order?.discount_price), cost_price: '-' },
          //           { field: 'summary-detail', list: 'ซัพดาวน์', sell_price: Number(order?.sub_down), cost_price: '-' },
          //           { field: 'summary-detail', list: 'Subsidy', sell_price: Number(order?.subsidy), cost_price: '-' },
          //           {
          //                field: 'summary-detail',
          //                list: 'ประกัน',
          //                sell_price: order?.insurance === 'Yes' && order.insurance_pay === 'บริษัท' ? Number(order?.insurance_premium) : 0.0,
          //                cost_price: '-',
          //           },
          //           // {
          //           //      field: 'summary-detail',
          //           //      list: 'พรบ.',
          //           //      sell_price: order.act === 'Yes' && order.act_pay === 'บริษัท' ? Number(order?.act_premium) : 0,
          //           //      cost_price: '-',
          //           // },
          //           {
          //                field: 'summary-detail',
          //                list: 'ค่าจดทะเบียน',
          //                sell_price: order?.register === 'Yes' && order.register_pay === 'บริษัท' ? Number(order?.register_price) : 0.0,
          //                cost_price: '-',
          //           },
          //           { field: 'summary-detail', list: 'ค่าแนะนำ', sell_price: Number(order?.recommend_price), cost_price: '-' },
          //           // { field: 'summary-detail', list: 'ค่าอื่นๆ', sell_price: Number(order?.other_pay_price), cost_price: '-' },
          //           {
          //                field: 'summary-total',
          //                list: 'รวม Margin ที่ใช้ทั้งสิ้น',
          //                sell_price: Number(
          //                     Number(sumAccBonus) +
          //                          (Number(order.discount_price) || 0) +
          //                          (order.insurance === 'Yes' && order.insurance_pay === 'บริษัท' ? Number(order?.insurance_premium) : 0) +
          //                          (order.register === 'Yes' && order.register_pay === 'บริษัท' ? Number(order?.register_price) : 0) +
          //                          (Number(order.recommend_price) || 0) +
          //                          (Number(order.sub_down) || 0) +
          //                          (Number(order.subsidy) || 0)
          //                ),
          //                cost_price: '-',
          //           },
          //      ],
          //      keyField: 'no',
          // };
          let sumAccBonus = 0;
          order.accbonus.forEach((element) => {
               // sumAccBonus += Number(JSON.parse(element.price_list).price_add_new * element.order_ac_amount);
               sumAccBonus += Number(element.order_ac_price * element.order_ac_amount);
          });
          let sumAccTurn = 0;
          if (order.accturn && order.accturn.length > 0) {
               order.accturn.map((el) => {
                    sumAccTurn += Number(el.order_ac_price);
               });
          }
          let totalMargin =
               Number(sumAccBonus) -
               Number(sumAccTurn || 0) +
               (order?.discount_price ? order.discount_price : 0) +
               (order?.recommend_price ? Number(order.recommend_price) : 0) +
               (order?.other_pay_price ? Number(order.other_pay_price) : 0) +
               (order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0) +
               (order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0) +
               (order?.sub_down ? order.sub_down : 0) +
               (order?.subsidy ? order.subsidy : 0);

          let SubdownAndAccBonus = Number(sumAccBonus) - Number(sumAccTurn || 0) + (order?.sub_down ? order.sub_down : 0);

          let overAllMargin = order.addition_price + order.margin;
          let strMargin;
          let margin_over = 0;
          let marginColor;
          if (totalMargin <= overAllMargin) {
               strMargin = "คงเหลือ Margin";
               margin_over = 0;
               marginColor = "__number-green";
          } else {
               strMargin = "ส่วนเกิน Margin";
               margin_over = Math.abs(overAllMargin - totalMargin);
               marginColor = "__number-danger";
          }

          let overSubdown = Number(SubdownAndAccBonus) > Number(overAllMargin) - Number(totalMargin - SubdownAndAccBonus);
          let strSub;
          let strSubNum;
          if (overSubdown) {
               strSub = "ซัพดาวน์เกิน Margin";
               strSubNum = Number(Number(SubdownAndAccBonus) - (Number(overAllMargin) - Number(totalMargin - SubdownAndAccBonus))).toLocaleString(
                    undefined,
                    {
                         minimumFractionDigits: 2,
                         maximumFractionDigits: 2,
                    }
               );
          }

          const table_listbonus = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการของแถม",
                         footer: overSubdown ? `${strSub}\n${strMargin}` : `${strMargin}`,
                         footerClasses: "summary-custom __yellow",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "price",
                         text: "ราคาขาย",
                         footer: (e) => this.marginOver(e, overSubdown, strSubNum),
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: `summary-custom __yellow ${marginColor}`,
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: overSubdown ? "บาท\nบาท" : "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom __yellow",
                    },
                    // {
                    //      dataField: 'note',
                    //      text: 'หมายเหตุ',
                    //      formatter: noteFormatter,
                    //      footerClasses: 'summary-custom __yellow',
                    //      footer: '',
                    //      headerClasses: 'header-custom __right  __btn',
                    // },
               ],
               data: [
                    ...order?.accbonus.map((el) => ({
                         title: el.items_name,
                         field: "summary",
                         price: el.order_ac_price * el.order_ac_amount,
                    })),
                    { field: "summary", title: "รวมราคาอุปกรณ์", price: sumAccBonus },
                    ...order.accturn.map((el) => ({ field: "summary", title: el.items_name, price: el.order_ac_price })),
                    { field: "summary", title: "รวมราคาเทิร์นอุปกรณ์ (ล้อ)", price: sumAccTurn },
                    { field: "summary", title: "ส่วนลดตัวรถ", price: order?.discount_price ? order.discount_price : 0 },
                    { field: "summary", title: "ซัพดาวน์", price: order?.sub_down ? order.sub_down : 0 },
                    { field: "summary", title: "Subsidy", price: order?.subsidy ? order.subsidy : 0 },
                    {
                         field: "summary",
                         title: "ประกัน (แถม)",
                         price: order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0,
                         payer: order.insurance_pay,
                    },
                    {
                         field: "summary",
                         title: "ค่าจดทะเบียน (แถม)",
                         price: order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0,
                         payer: order.register_pay,
                    },
                    { field: "summary", title: "ค่าแนะนำ (แถม)", price: order?.recommend_price ? Number(order.recommend_price) : 0 },
                    { field: "summary", title: "ค่าอื่นๆ (แถม)", price: order?.other_pay_price ? Number(order.other_pay_price) : 0 },
                    {
                         field: "summary-total",
                         title: "ใช้ไปทั้งสิ้น",
                         price: Number(totalMargin),
                    },
                    { field: "summary-total", title: "หักราคาตัวรถที่บวกเพิ่ม", price: order?.addition_price ? Number(order.addition_price) : 0 },
                    { field: "summary-total", title: "หัก margin", price: order?.margin ? Number(order.margin) : 0 },
               ],
               keyField: "title",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `อนุมัติใบจอง`,
                                   onClick: () => window.location.assign("/admin/discount"),
                              },
                              {
                                   title: `ใบจองรถยนต์ (${order.order_code_id ? order.order_code_id : ""})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order?.order_id}`),
                              },
                         ]}
                         active={3}
                         button={""}
                    />
                    <div className="content __input-panel">
                         {/* {(getToken().position_id === 19 || getToken().position_id === 20 || getToken().position_id === 25) && ( */}
                         <div className="input-field">
                              <div className="-body">{fileURL && <embed src={fileURL} type="application/pdf" height="1150px" width="100%" />}</div>
                         </div>
                         {/* )} */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <div className="-padding">
                                             <div style={{ textAlign: "right", marginTop: "32px", marginBottom: "32px" }}>
                                                  <span>วันที่</span> <span className="underline-date">{dateFormatter(new Date())}</span>
                                             </div>
                                             <h2 style={{ textAlign: "center" }}>รายงานส่วนลดเกิน Margin</h2>
                                             <div className="-padding">
                                                  <Row>
                                                       <LabelBar
                                                            type={"underline"}
                                                            title={{ name: "ชื่อพนักงานขาย", detail: order?.saler_name_string || "-" }}
                                                            md={6}
                                                       />
                                                       <LabelBar type={"underline"} title={{ name: "ทีม", detail: order?.team_name || "-" }} md={3} />
                                                       <LabelBar
                                                            type={"underline"}
                                                            title={{ name: "สาขา", detail: order?.branch_name || "-" }}
                                                            md={3}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"underline"}
                                                            title={{ name: "ชื่อลูกค้า", detail: order?.userinfo_name || "-" }}
                                                            md={6}
                                                       />
                                                       <LabelBar
                                                            type={"underline"}
                                                            title={{ name: "เบอร์โทรลูกค้า", detail: order?.userinfo_tel || "-" }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"underline"}
                                                            title={{ name: "รายละเอียดรุ่นรถ", detail: order?.series_name || "-" }}
                                                            md={6}
                                                       />
                                                       <LabelBar type={"underline"} title={{ name: "สี", detail: order?.color_name || "-" }} md={6} />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"underline"}
                                                            title={{ name: "Model Code", detail: order?.model_code || "-" }}
                                                            md={6}
                                                       />
                                                  </Row>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="content" id="table-content">
                                        <Table type={"summary"} column={table_list.column} data={table_list.data} keyField={table_list.keyField} />
                                        <Table
                                             type={"summary"}
                                             column={table_listbonus.column}
                                             data={table_listbonus.data}
                                             keyField={table_listbonus.keyField}
                                        />
                                   </div>
                                   <div className="content">
                                        {order?.status === "รออนุมัติ" && order?.lvl_margin > 0 && (
                                             <Row style={{ justifyContent: "center" }} md={4}>
                                                  <Button
                                                       onClick={() =>
                                                            this.props.history.push({
                                                                 pathname: `/admin/order/car/detail${order.order_id}`,
                                                                 state: { scroll: true },
                                                            })
                                                       }
                                                  >
                                                       อนุมัติใบจอง
                                                  </Button>
                                             </Row>
                                        )}
                                   </div>

                                   {/* ----------------------------------- */}
                                   {order?.status === "อนุมัติ" && (
                                        <div className="content">
                                             <Row className="noMargin">
                                                  <Col>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ยอด Margin ที่อนุมัติ : ",
                                                                 detail: order.acept_margin
                                                                      ? order?.acept_margin?.toLocaleString(undefined, {
                                                                             minimumFractionDigits: 0,
                                                                             maximumFractionDigits: 2,
                                                                        })
                                                                      : "-",
                                                            }}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Row className="noMargin">
                                                  <Col>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{ name: "การอนุมัติใบจอง : ", detail: order?.status || "-" }}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Row className="noMargin">
                                                  <Col>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{ name: "ผู้ดำเนินการ : ", detail: order?.name_margin_acept || "-" }}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Row className="noMargin">
                                                  <Col>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{ name: "หมายเหตุ : ", detail: order?.margin_note || "-" }}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Breadcrumbs
                                                  under={true}
                                                  type={"success"}
                                                  title={"อนุมัติ"}
                                                  button={{
                                                       onClick: () => this.downloadPdf(),
                                                       name: "พิมพ์ใบอนุมัติ Margin",
                                                       variant: "outline-primary",
                                                  }}
                                             />
                                             <div style={{ height: "80px" }}></div>
                                        </div>
                                   )}
                                   {order?.status === "รออนุมัติ" && order?.lvl_margin === 0 && (
                                        <div className="content">
                                             <Field
                                                  type={"number"}
                                                  title={"ระบุยอด Margin ที่สามารถอนุมัติได้ (บาท)"}
                                                  name={"acept_margin"}
                                                  value={order?.acept_margin}
                                                  placeholder={"0.00"}
                                                  require={true}
                                                  unit={"บาท"}
                                                  decimalScale={2}
                                                  lg={6}
                                                  handleChangeNumber={(e) => this.handleChangeNumber(e, "acept_margin")}
                                             />
                                             <LabelBar
                                                  type={"radio"}
                                                  title={"การอนุมัติใบจอง"}
                                                  label={["อนุมัติ", "ไม่อนุมัติ"]}
                                                  name={"status"}
                                                  require={true}
                                                  // value={order?.status}
                                                  value={this.state.status}
                                                  handleChangeText={(e) => this.handleChangeRadio(e)}
                                             />
                                             <Col>
                                                  <Field
                                                       type="text"
                                                       title={"หมายเหตุ"}
                                                       name="margin_note"
                                                       as="textarea"
                                                       textarea_rows={4}
                                                       handleChangeText={(e) => this.handleChangeText(e)}
                                                  />
                                             </Col>
                                             <Row>
                                                  <Col md={6}></Col>
                                                  <Col md={3}>
                                                       {/* {allow ? null : (
                                                       <LabelBar
                                                            value={forward.check}
                                                            handleChangeText={(e) => this.handleChangeRadioForwardRole(e.target.checked, e)}
                                                            title={`ต้องการส่งต่อไปยัง ${forward_role} หรือไม่ ?`}
                                                            type={"radio"}
                                                            label={["ส่งต่อ"]}
                                                       />
                                                  )} */}
                                                  </Col>
                                                  <Col md={3}>
                                                       <Button disabled={this.state.status === "" ? true : false} onClick={this.handleSubmit}>
                                                            Submit
                                                       </Button>
                                                  </Col>
                                             </Row>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
