import { GET, POST } from "api";
import { Breadcrumbs, Table, Field, LabelBar, ModalPanel } from "components";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
export default class AccountReport extends Component {
     constructor(props) {
          super(props);
          this.state = {
               showModal: false,
               searchReport: { branch: [] },
               error_data: "",
          };
     }
     componentDidMount = async () => {
          try {
               let branch = await GET("/aos/get_branch");
               this.setState({ branch: branch, searchReport: { branch: branch.map((el) => el.branch_id) } });
          } catch (error) {
               console.log("error", error);
          }
     };

     /*-----------------------Modal--------------------------*/
     modalViewReport = () => {
          let { modal_type, searchReport } = this.state;
          return (
               <div>
                    {modal_type === "11" ? (
                         <Row style={{ padding: "0 33%" }}>
                              <Field
                                   type={"date"}
                                   placeholder={searchReport.date_start ? searchReport.date_start : "เลือกวันที่"}
                                   title={"ณ. วันที่"}
                                   name={"date_start"}
                                   md={12}
                                   // handleSelectDate={this.handleChangeDate}
                                   handleSelectDate={(date, dateString, name) => this.handleSelectDate(date, dateString, name)}
                              />
                         </Row>
                    ) : (
                         <>
                              <Row className={"noMargin"}>
                                   <Field
                                        name={"date_start"}
                                        type={"date"}
                                        placeholder={searchReport.date_start ? dayjs(searchReport.date_start).format("YYYY-MM-DD") : "เลือกวันที่"}
                                        title={"ประจำวันที่"}
                                        md={6}
                                        handleSelectDate={(date, dateString, name) => this.handleSelectDate(date, dateString, name)}
                                   />
                                   <Field
                                        placeholder={searchReport.date_end ? dayjs(searchReport.date_end).format("YYYY-MM-DD") : "เลือกวันที่"}
                                        name={"date_end"}
                                        type={"date"}
                                        title={"ถึงวันที่"}
                                        handleSelectDate={(date, dateString, name) => this.handleSelectDate(date, dateString, name)}
                                   />
                              </Row>
                              {modal_type !== "1" && (
                                   // <LabelBar
                                   //      name={"branch"}
                                   //      type={"radio"}
                                   //      title={"สาขา"}
                                   //      label={this.state.branch?.map((el) => el.branch_name)}
                                   //      value={searchReport.branch}
                                   //      handleChangeText={this.onChangeCheckbox}
                                   // />
                                   <LabelBar
                                        type={"checkbox"}
                                        title={"สาขา"}
                                        name={"branch"}
                                        md={12}
                                        label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                        // value={pay?.branch}
                                        arrChecked={searchReport?.branch}
                                        handleChangeText={this.onChangeCheckbox}
                                   />
                              )}
                              {/* {modal_type === "3" && (
                     <LabelBar
                          name={"status"}
                          type={"radio"}
                          title={"สถานะ"}
                          label={["จับคู่แล้ว", "ยังไม่ได้จับคู่"]}
                          value={searchReport?.status}
                          handleChangeText={this.onChangeCheckbox}
                     />
                )} */}{" "}
                         </>
                    )}
               </div>
          );
     };

     modalShow = (value, type, id) => {
          let { showModal, searchReport } = this.state;
          searchReport.id = id;
          this.setState({ showModal: !showModal, report_title: value, modal_type: String(type), searchReport });
     };

     /*--------------------Formatter--------------------*/
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.modalShow(row.report, row.type, row.id)}>ทำรายการ</a>;
     };
     reportFormatter = (cell, row, rowIndex, formatExtraData) => {
          // if (row.report === "รายงานการจับคู่ ค่ามัดจำชุดทะเบียน(RC) - ใบคืนเงินค่ามัดจำชุดจดทะเบียน(CC)") {
          //      return (
          //           <div>
          //                <span>{cell}</span>
          //                <span style={{ color: "red", marginLeft: "40px" }}>ไม่มีรูปแบบรายงาน</span>
          //           </div>
          //      );
          // } else {
          return <span>{cell}</span>;
          // }
     };

     /*--------------------Handle--------------------*/
     handleSelectDate = (date, dateString, name) => {
          let { searchReport } = this.state;
          searchReport[name] = dateString;
          this.setState({ searchReport });
     };

     onChangeCheckbox = ({ target: { name, value, checked } }) => {
          let { searchReport } = this.state;
          if (name === "branch") {
               // console.log(name + " " + value, checked);
               if (checked) {
                    searchReport[name].push(Number(value));
                    searchReport[name] = searchReport[name].sort((a, z) => a - z);
                    this.setState({ searchReport });
               } else {
                    let index = searchReport[name].findIndex((el) => el === Number(value));
                    searchReport[name].splice(index, 1);
                    searchReport[name] = searchReport[name].sort((a, z) => a - z);
                    this.setState({ searchReport });
               }
          } else {
               searchReport[name] = value;
               this.setState({ searchReport });
          }
     };

     handelClickViewReport = async () => {
          let { searchReport, branch } = this.state;
          let type_excel = "";
          // console.log("searchReport ", searchReport);
          try {
               switch (searchReport.id) {
                    case 1:
                         type_excel = "รายงานการซื้อรถยนต์";
                         break;
                    case 2:
                         type_excel = "รายงานต้นทุนขายรถยนต์";
                         break;
                    case 3:
                         type_excel = "รายงานต้นทุนอุปกรณ์ตกแต่ง";
                         break;
                    case 4:
                         type_excel = "รายงานรับเงินค่าต่อเติม";
                         break;
                    case 5:
                         type_excel = "รายงานรับเงินค่ามัดจำชุดจดทะเบียน";
                         break;
                    case 6:
                         type_excel = "รายงานค่าแนะนำ";
                         break;
                    case 7:
                         type_excel = "รายงานค่าซัพดาวน์";
                         break;
                    case 8:
                         type_excel = "รายงานการจับคู่ ค่าจอง(TB) - ลดหนี้ค่าจอง(CB)";
                         break;
                    case 9:
                         type_excel = "รายงานการจับคู่ ค่าป้ายแดง(RP) - ลดหนี้ค่าป้ายแดง(CP)";
                         break;
                    case 10:
                         type_excel = "รายงานการจับคู่ ค่ามัดจำชุดทะเบียน(RC) - ใบคืนเงินค่ามัดจำชุดจดทะเบียน(CC)";
                         break;
                    // case 11:
                    //      type_excel = "รายงานการจับคู่ ค่าจดทะเบียน - ลดหนี้ค่าจดทะเบียน";
                    //      break;
                    case 11:
                         type_excel = "รายงานสินค้าคงเหลือทั้งหมด";
                         break;

                    default:
                         break;
               }
               if ((!searchReport.date_start || !searchReport.date_end) && searchReport.id === 1) {
                    return this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
               }
               if (
                    (!searchReport.date_start || !searchReport.date_end || searchReport.branch.length === 0) &&
                    searchReport.id > 1 &&
                    searchReport.id < 11
               ) {
                    return this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
               }

               if (!searchReport.date_start && searchReport.id === 11) {
                    return this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
               }

               let dataBranch = searchReport.branch.map((el) => ({
                    branch_name: branch.find((e) => e.branch_id == el).branch_name,
                    branch_id: branch.find((e) => e.branch_id == el).branch_id,
               }));
               // console.log(`data`, { ...searchReport, type_excel, branch: dataBranch });
               let excel = await POST("/ps/report_excel_account", { ...searchReport, type_excel, branch: dataBranch });
               window.open(excel);
               this.setState({ error_data: "" });
               // this.setState({ showModal: false });
          } catch (error) {
               this.setState({ error_data: error });
               // console.log("error", error);
          }
     };

     handleCloseModal = () => {
          try {
               let { searchReport } = this.state;
               this.setState({ showModal: false, error_data: "" });
          } catch (error) {
               console.log("error", error);
          }
     };

     render() {
          let { showModal, report_title, error_data } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left  __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         formatter: this.reportFormatter,
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    { report: "รายงานการซื้อรถยนต์", type: 1, id: 1 },
                    { report: "รายงานต้นทุนรถยนต์", type: 2, id: 2 },
                    { report: "รายงานต้นทุนอุปกรณ์ตกแต่ง", type: 2, id: 3 },
                    { report: "รายงานรับเงินค่าต่อเติม", type: 2, id: 4 },
                    { report: "รายงานรับเงินค่ามัดจำชุดจดทะเบียน", type: 2, id: 5 },
                    { report: "รายงานค่าแนะนำ", type: 2, id: 6 },
                    { report: "รายงานค่าซัพดาวน์", type: 2, id: 7 },
                    { report: "รายงานการจับคู่ ค่าจอง(TB) - ลดหนี้ค่าจอง(CB)", type: 3, id: 8 },
                    { report: "รายงานการจับคู่ ค่าป้ายแดง(RP) - ลดหนี้ค่าป้ายแดง(CP)", type: 3, id: 9 },
                    { report: "รายงานการจับคู่ ค่ามัดจำชุดทะเบียน(RC) - ใบคืนเงินค่ามัดจำชุดจดทะเบียน(CC)", type: 3, id: 10 },
                    { report: "รายงานสินค้าคงเหลือทั้งหมด", type: 11, id: 11 },
                    // { report: "รายงานการจับคู่ ค่าจดทะเบียน - ลดหนี้ค่าจดทะเบียน", type: 2, id: 11 },
               ],
               keyField: "no",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานบัญชี" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                              </div>
                         </div>
                    </div>
                    <ModalPanel
                         error_data={error_data}
                         type="input"
                         show={showModal}
                         title={report_title}
                         onHide={this.handleCloseModal}
                         body={this.modalViewReport()}
                         button={[{ name: "แสดงรายงาน", variant: "primary", onClick: () => this.handelClickViewReport() }]}
                    />
               </div>
          );
     }
}
