import React, { Component } from "react";
import { Breadcrumbs, Search, Sign, Skeleton, Table } from "components";
import dayjs from "dayjs";
import { GET } from "api";
import { getToken } from "../../../../../static/function";

export default class MatchConfirm extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {},
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getMatchConfirm();
     };

     getMatchConfirm = async () => {
          let { series_id, model_id, color_id } = this.state;
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/car/get_matched");
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search;
               this.setState({ order, order_search, loading: false });
          } catch (err) {
               this.setState({ loading: false });
               console.log(err);
          }
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
          if (e.target.name === "series_name" && e.target.value === "ทั้งหมด") {
               search.model_name = "";
               search.color_name = "";
               this.setState({ search });
          }
          if (e.target.name === "model_name" && e.target.value === "ทั้งหมด") {
               search.color_name = "";
               this.setState({ search });
          }
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.series_name
                              ? search.series_name === "ทั้งหมด"
                                   ? el
                                   : String(el.series_name) === String(search.series_name)
                              : el) &&
                         (search.model_name ? (search.model_name === "ทั้งหมด" ? el : String(el.model_name) === String(search.model_name)) : el) &&
                         (search.color_name ? (search.color_name === "ทั้งหมด" ? el : String(el.color_name) === String(search.color_name)) : el)
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
                    // (search.series_name ? String(el.series_name).indexOf(String(search.series_name)) > -1 : el) &&
                    // (search.model_name ? String(el.model_name).indexOf(String(search.model_name)) > -1 : el) &&
                    // (search.color_name ? String(el.color_name).indexOf(String(search.color_name)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => window.location.assign(`/admin/match/confirm/${row.car_id}/${row.order_id}`)}>{cell ? cell : "ทำรายการ"}</a>;
     };
     detailFormatterBooking(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     }

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     dateBetweenFormatter = (cell, row) => {
          let date = dayjs(new Date()).format("YYYY-MM-DD").split("-")[2];
          let new_cell = dayjs(cell).format("YYYY-MM-DD").split("-")[2];

          let number = Math.abs(parseInt(new_cell) - parseInt(date));
          let number_date = dayjs(new Date()).diff(dayjs(cell), "day");
          if (cell) {
               // return dayjs(cell).add(543, "year").format("DD/MM/YYYY");
               return `${number_date} วัน`;
          } else {
               return <p>-</p>;
          }
     };
     render() {
          let { order, newSearch, search } = this.state;
          let table = {
               column: [
                    {
                         dataField: "match_list",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "ใบจองที่จับคู่",
                         formatter: this.detailFormatterBooking,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "พนักงานขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_match_date",
                         text: "จำนวนวันที่จับคู่รถ",
                         formatter: this.dateBetweenFormatter,
                         // formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         // headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "car_engine",
                         text: "เลขเครื่องยนต์",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_create",
                         text: "วันที่เข้าสต๊อก",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "order_id",
          };
          let series = order
               .filter((el, i) => order.map((ele) => ele.series_name).indexOf(el.series_name) === i)
               .map((el) => ({ value: el.series_name, name: el.series_name }));
          series.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          let model = order
               .filter((el, i) => order.map((ele) => ele.model_name).indexOf(el.model_name) === i)
               .filter((el) =>
                    search.series_name ? (search.series_name === "ทั้งหมด" ? el : String(el.series_name) === String(search.series_name)) : el
               )
               .map((el) => ({ value: el.model_name, name: el.model_name }));
          model.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          let color = order
               .filter((el, i) => order.map((ele) => ele.color_name).indexOf(el.color_name) === i)
               .filter((el) =>
                    search.model_name ? (search.model_name === "ทั้งหมด" ? el : String(el.model_name) === String(search.model_name)) : el
               )
               .map((el) => ({ value: el.color_name, name: el.color_name }));
          color.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "ยืนยันการจับคู่" }]} active={0} button={""} />
                    <div className="content __main-panel matchconfirm">
                         <Search
                              type={"match-confirm"}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: {
                                        series,
                                        model,
                                        color,
                                        series_name: search.series_name,
                                        model_name: search.model_name,
                                        color_name: search.color_name,
                                   },
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
