import { GET } from "api";
import { Breadcrumbs, Search, Skeleton, Table } from "components";
import React, { Component } from "react";
// import { getToken } from "../../../../../static/function";

export default class Device extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {},
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getOrder();
     };

     getOrder = async () => {
          try {
               this.setState({ loading: true });
               // let user = getToken();
               let res = await GET("/order/supplier_acc");
               let order_search = res.sort((end, start) => parseFloat(start.supplier_id) - parseFloat(end.supplier_id));
               // .filter((el) => el.branch_id === Number(user.branch_id))
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search;
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          // console.log('search', search)
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               // .filter((el) => (search.supplier_name ? (search.supplier_name === "ทั้งหมด" ? el : String(el.supplier_name) === String(search.supplier_name)) : el))
               .filter(
                    (el) =>
                         (search.supplier_code ? String(el.supplier_code).indexOf(String(search.supplier_code)) > -1 : el) &&
                         (search.supplier_name ? String(el.supplier_name).indexOf(String(search.supplier_name)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/install/report/${row.supplier_id}`)}>ทำรายการ</a>;
     }
     render() {
          let { order, search, newSearch } = this.state;
          const table = {
               column: [
                    {
                         dataField: "match_car",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "supplier_code",
                         text: "Supplier Code",
                         // headerClasses: "header-custom __left",
                         headerClasses: "header-custom __btn-m",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "notinstalled",
                         text: "ใบจอง/ ใบสั่งขาย รอติดตั้ง",
                         // formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "installed",
                         text: "ใบจอง/ ใบสั่งขาย ติดตั้งแล้ว",
                         // formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "supplier_code",
          };
          let supplier = order
               .filter((el, i) => order.map((ele) => ele.supplier_name).indexOf(el.supplier_name) === i)
               .map((el) => ({ value: el.supplier_name, name: el.supplier_name }));
          supplier.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "รายงานติดตั้งอุปกรณ์" }]} active={0} button={""} />
                    <div className="content __main-panel device">
                         <Search
                              type={"install-device"}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: {
                                        supplier,
                                        supplier_name: search.supplier_name,
                                        supplier_code: search.supplier_code,
                                   },
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
