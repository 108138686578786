import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel } from "../../../../components";
import { Form, Button, Col, Accordion, Table as TableBoot } from "react-bootstrap";
import { GET, POST } from "api/index";
import swal from "sweetalert";
import { data } from "flickity";
import { getToken } from "../../../../static/function";
import "./css.css";
export default class branchManage extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               adminInfo: {},
               title1: [],
               formula: [],
               formula_manager: [],
          };
     }

     componentDidMount = async () => {
          // console.log(`this.props.match.params`, this.props.match.params);
          this.setPath();
          if (this.props.match.params.id) {
               await this.setData();
          }
     };

     /* Set title from previous path */
     setPath = () => {
          let { id } = this.props.match.params;
          let { title1 } = this.state;
          title1 = [
               {
                    title: "รายการสาขา",
                    onClick: () => this.props.history.goBack(),
               },
               {
                    title: !id ? "เพิ่มสาขา" : "แก้ไขสาขา",
               },
          ];
          this.setState({ title1 });
     };

     setData = async () => {
          try {
               let { id } = this.props.match.params;
               let { adminInfo } = this.state;
               let res = await POST("/aos/get_branch_by", { id });
               adminInfo["branch_name"] = res[0].branch_name;
               adminInfo["number_branch"] = res[0].number_branch;
               adminInfo["address"] = res[0].address;
               adminInfo["tax"] = res[0].tax;
               adminInfo["phone"] = res[0].phone;
               this.setState({
                    adminInfo,
                    formula: res[0].formula ? JSON.parse(res[0].formula) : [],
                    formula_manager: res[0].formula_manager ? JSON.parse(res[0].formula_manager) : [],
               });
          } catch (error) {}
     };
     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { adminInfo } = this.state;
          adminInfo[name] = value;
          this.setState({ adminInfo });
     };

     handleDelete = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         let { id } = this.props.match.params;
                         await POST("/aos/del_branch", { id });
                         swal({
                              text: "ลบเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              window.location.assign(`/admin/branch`);
                         });
                    }
               });
          } catch (error) {}
     };
     create_branch = async (event) => {
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    button: "เตือน",
                    text: "กรอกให้ครบ",
                    icon: "warning",
               });
               event.preventDefault();
               event.stopPropagation();
          } else {
               event.preventDefault();
               try {
                    let { id } = this.props.match.params;
                    let { adminInfo } = this.state;
                    if (!id) {
                         await POST("/aos/create_branch", adminInfo);
                         swal({
                              button: "เสร็จสิ้น",
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                         }).then(() => this.props.history.push("/admin/branch"));
                    } else {
                         await POST("/aos/update_branch", { data: adminInfo, branch_id: id });
                         swal({
                              button: "เสร็จสิ้น",
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                         }).then(() => window.location.reload());
                    }
               } catch (error) {
                    swal({
                         button: "ผิดพลาด",
                         text: "",
                         icon: "error",
                    });
               }
          }
          this.setState({
               validated: true,
          });
     };
     onClickAddFormula = (item) => {
          let { formula, formula_manager } = this.state;
          if (item && item === "saler") {
               formula.push({
                    id: formula.length + 1,
                    status: 1,
                    name_formula: "",
                    data: [{ amount_car: "", commission: "", percen_sale: "", percen_company: "", income: "" }],
               });
               this.setState({ formula });
          } else {
               formula_manager.push({
                    id: formula_manager.length + 1,
                    status: 1,
                    name_formula: "",
                    data: [{ amount_car: "", commission: "", percen_sale: "", percen_company: "", income: "" }],
               });
               this.setState({ formula_manager });
          }
     };
     onClickAddCol = (index, item) => {
          let { formula, formula_manager } = this.state;
          if (item && item === "saler") {
               formula
                    .filter((el) => el.status)
                    [index].data.push({
                         amount_car: "",
                         commission: "",
                         percen_sale: "",
                         percen_company: "",
                         income: "",
                    });
               this.setState({ formula });
          } else {
               formula_manager
                    .filter((el) => el.status)
                    [index].data.push({
                         amount_car: "",
                         commission: "",
                         percen_sale: "",
                         percen_company: "",
                         income: "",
                    });
               this.setState({ formula_manager });
          }
     };
     handleChangeNameFormula = (e, index, item) => {
          // console.log("value", e.target.value);
          let { formula, formula_manager } = this.state;
          if (item && item === "saler") {
               formula.filter((el) => el.status)[index].name_formula = e.target.value;
               this.setState({ formula });
          } else {
               formula_manager.filter((el) => el.status)[index].name_formula = e.target.value;
               this.setState({ formula_manager });
          }
     };
     handleChangeEditCol = (e, i, ind, name, item) => {
          // console.log("value", e);
          let { formula, formula_manager } = this.state;
          if (item && item === "saler") {
               formula.filter((el) => el.status)[i].data[ind][name] = e.floatValue;
               this.setState({ formula });
          } else {
               formula_manager.filter((el) => el.status)[i].data[ind][name] = e.floatValue;
               this.setState({ formula_manager });
          }
     };
     updateFormula = async () => {
          try {
               let { id } = this.props.match.params;
               let { formula, formula_manager } = this.state;
               await POST("/aos/update_branch", {
                    data: {
                         formula: formula.length ? JSON.stringify(formula) : null,
                         formula_manager: formula_manager.length ? JSON.stringify(formula_manager) : null,
                    },
                    branch_id: id,
               });
               swal({
                    button: "เสร็จสิ้น",
                    text: "ทำรายการสำเร็จ",
                    icon: "success",
               }).then(() => window.location.reload());
          } catch (error) {
               swal({
                    button: "ผิดพลาด",
                    text: "",
                    icon: "error",
               });
          }
     };
     onClickDelFormula = (index, item) => {
          let { formula, formula_manager } = this.state;
          if (item && item === "saler") {
               formula.filter((el) => el.status)[index].status = 0;
               this.setState({ formula });
          } else {
               formula_manager.filter((el) => el.status)[index].status = 0;
               this.setState({ formula_manager });
          }
     };
     onClickDelCol = (i, ind, item) => {
          let { formula, formula_manager } = this.state;
          if (item && item === "saler") {
               formula.filter((el) => el.status)[i].data.splice(ind, 1);
               this.setState({ formula });
          } else {
               formula_manager.filter((el) => el.status)[i].data.splice(ind, 1);
               this.setState({ formula_manager });
          }
     };
     render() {
          let { validated, adminInfo, title1, formula, formula_manager } = this.state;
          let { id } = this.props.match.params;
          return (
               <div className="panel">
                    {title1 ? <Breadcrumbs title={title1} active={1} button={""} /> : null}
                    <Form noValidate validated={validated} onSubmit={this.create_branch}>
                         <div className="content __input-panel">
                              <div className="input-field">
                                   <div className="-header">
                                        <div>รายละเอียดสาขา</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            value={adminInfo.branch_name}
                                                            type={"text"}
                                                            title={"ชื่อสาขา"}
                                                            name={"branch_name"}
                                                            placeholder={"กรุณากรอก ชื่อสาขา"}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            value={adminInfo.number_branch}
                                                            type={"text"}
                                                            title={"เลขที่สาขา"}
                                                            name={"number_branch"}
                                                            placeholder={"กรุณากรอก เลขที่สาขา"}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            value={adminInfo.address}
                                                            type={"text"}
                                                            title={"ที่อยู่"}
                                                            name={"address"}
                                                            placeholder={"กรุณากรอก ที่อยู่"}
                                                            require={true}
                                                            md={12}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            value={adminInfo.tax}
                                                            type={"text"}
                                                            title={"เลขประจำตัวผู้เสียภาษี"}
                                                            name={"tax"}
                                                            placeholder={"กรุณากรอก เลขประจำตัวผู้เสียภาษี"}
                                                            require={true}
                                                            md={12}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            value={adminInfo.phone}
                                                            type={"text"}
                                                            title={"โทรศัพท์ โทรสาร"}
                                                            name={"phone"}
                                                            placeholder={"กรุณากรอก โทรศัพท์ โทรสาร"}
                                                            require={true}
                                                            md={12}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="button-group __submit row">
                                   <Col xs={6}>
                                        <Button type="submit">{!id ? "เพิ่มสาขา" : "บันทึกการแก้ไข"}</Button>
                                   </Col>
                                   {id && (
                                        <Col xs={6}>
                                             <Button variant="danger" onClick={this.handleDelete}>
                                                  ลบ
                                             </Button>
                                        </Col>
                                   )}
                              </div>
                         </div>
                    </Form>
                    <div className="content __input-panel" style={{ paddingTop: "1em" }}>
                         <div className="input-field">
                              <div className="-header">
                                   <div>สูตรค่า Commission พนักงานขาย</div>
                              </div>
                              <div className="-body">
                                   {formula.length ? (
                                        formula
                                             .filter((el) => el.status)
                                             .map((el, i) => (
                                                  <div key={i + 1} className="content">
                                                       <Form.Row>
                                                            <Field
                                                                 value={el.name_formula}
                                                                 type={"text"}
                                                                 title={"ชื่อสูตร"}
                                                                 name={"name_formula"}
                                                                 placeholder={"กรุณากรอก ชื่อสูตร"}
                                                                 md={6}
                                                                 handleChangeText={(value) => this.handleChangeNameFormula(value, i, "saler")}
                                                            />
                                                       </Form.Row>
                                                       <TableBoot striped bordered hover responsive>
                                                            <thead>
                                                                 <tr>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                                           ยอดขาย
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                                           คอมมิชชั่น
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} colSpan={2}>
                                                                           Margin
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                                           รายได้{" "}
                                                                           <button
                                                                                style={{
                                                                                     border: "none",
                                                                                     background: "#88b759",
                                                                                     color: "white",
                                                                                     borderRadius: "15px",
                                                                                     width: "25px",
                                                                                }}
                                                                                onClick={() => this.onClickAddCol(i, "saler")}
                                                                           >
                                                                                +
                                                                           </button>
                                                                      </th>
                                                                 </tr>
                                                                 <tr>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }}>พนักงานขาย(%)</th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }}>บริษัท(%)</th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody className="no-bottom">
                                                                 {el.data.length
                                                                      ? el.data.map((e, ind) => (
                                                                             <tr key={ind + 1}>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.amount_car}
                                                                                                 type={"number"}
                                                                                                 name={"amount_car"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "amount_car",
                                                                                                           "saler"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.commission}
                                                                                                 type={"number"}
                                                                                                 name={"commission"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "commission",
                                                                                                           "saler"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.percen_sale}
                                                                                                 type={"number"}
                                                                                                 name={"percen_sale"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "percen_sale",
                                                                                                           "saler"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.percen_company}
                                                                                                 type={"number"}
                                                                                                 name={"percen_company"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "percen_company",
                                                                                                           "saler"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.income}
                                                                                                 type={"number"}
                                                                                                 name={"income"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "income",
                                                                                                           "saler"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                                       <button
                                                                                            style={{
                                                                                                 border: "none",
                                                                                                 background: "#d86060",
                                                                                                 color: "white",
                                                                                                 borderRadius: "15px",
                                                                                                 width: "25px",
                                                                                            }}
                                                                                            onClick={() => this.onClickDelCol(i, ind, "saler")}
                                                                                       >
                                                                                            x
                                                                                       </button>
                                                                                  </td>
                                                                             </tr>
                                                                        ))
                                                                      : null}
                                                            </tbody>
                                                       </TableBoot>
                                                       <div className="button-group __submit row">
                                                            <Col xs={6} className="text-center">
                                                                 <Button
                                                                      className="w-50"
                                                                      variant="danger"
                                                                      onClick={() => this.onClickDelFormula(i, "saler")}
                                                                 >
                                                                      ลบสูตร
                                                                 </Button>
                                                            </Col>
                                                            <Col xs={6} className="text-center">
                                                                 <Button
                                                                      className="w-50"
                                                                      variant="success"
                                                                      onClick={() => this.onClickAddFormula("saler")}
                                                                 >
                                                                      เพิ่มสูตร
                                                                 </Button>
                                                            </Col>
                                                       </div>
                                                  </div>
                                             ))
                                   ) : (
                                        <div className="content">
                                             <div className="button-group __submit row">
                                                  <Col xs={12} className="text-center">
                                                       <Button className="w-50" variant="success" onClick={() => this.onClickAddFormula("saler")}>
                                                            เพิ่มสูตร
                                                       </Button>
                                                  </Col>
                                             </div>
                                        </div>
                                   )}
                              </div>
                         </div>
                         {/*==================== ======================*/}
                         {/*==================== ======================*/}
                         <div className="input-field">
                              <div className="-header">
                                   <div>สูตรค่า Commission หัวหน้าทีม</div>
                              </div>
                              <div className="-body">
                                   {formula_manager.filter((el) => el.status).length ? (
                                        formula_manager
                                             .filter((el) => el.status)
                                             .map((el, i) => (
                                                  <div key={i + 1} className="content">
                                                       <Form.Row>
                                                            <Field
                                                                 value={el.name_formula}
                                                                 type={"text"}
                                                                 title={"ชื่อสูตร"}
                                                                 name={"name_formula"}
                                                                 placeholder={"กรุณากรอก ชื่อสูตร"}
                                                                 md={6}
                                                                 handleChangeText={(value) => this.handleChangeNameFormula(value, i, "team")}
                                                            />
                                                       </Form.Row>
                                                       <TableBoot striped bordered hover responsive>
                                                            <thead>
                                                                 <tr>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                                           ยอดขาย
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                                           คอมมิชชั่น
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} colSpan={2}>
                                                                           Margin
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }} rowSpan={2}>
                                                                           รายได้{" "}
                                                                           <button
                                                                                style={{
                                                                                     border: "none",
                                                                                     background: "#88b759",
                                                                                     color: "white",
                                                                                     borderRadius: "15px",
                                                                                     width: "25px",
                                                                                }}
                                                                                onClick={() => this.onClickAddCol(i, "team")}
                                                                           >
                                                                                +
                                                                           </button>
                                                                      </th>
                                                                 </tr>
                                                                 <tr>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                           หัวหน้าทีมขาย(%)
                                                                      </th>
                                                                      <th style={{ textAlign: "center", verticalAlign: "middle" }}>บริษัท(%)</th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody className="no-bottom">
                                                                 {el.data.length
                                                                      ? el.data.map((e, ind) => (
                                                                             <tr key={ind + 1}>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.amount_car}
                                                                                                 type={"number"}
                                                                                                 name={"amount_car"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "amount_car",
                                                                                                           "team"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.commission}
                                                                                                 type={"number"}
                                                                                                 name={"commission"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "commission",
                                                                                                           "team"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.percen_sale}
                                                                                                 type={"number"}
                                                                                                 name={"percen_sale"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "percen_sale",
                                                                                                           "team"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.percen_company}
                                                                                                 type={"number"}
                                                                                                 name={"percen_company"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "percen_company",
                                                                                                           "team"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td>
                                                                                       <Form.Row>
                                                                                            <Field
                                                                                                 value={e.income}
                                                                                                 type={"number"}
                                                                                                 name={"income"}
                                                                                                 md={12}
                                                                                                 handleChangeNumber={(value) =>
                                                                                                      this.handleChangeEditCol(
                                                                                                           value,
                                                                                                           i,
                                                                                                           ind,
                                                                                                           "income",
                                                                                                           "team"
                                                                                                      )
                                                                                                 }
                                                                                            />
                                                                                       </Form.Row>
                                                                                  </td>
                                                                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                                       <button
                                                                                            style={{
                                                                                                 border: "none",
                                                                                                 background: "#d86060",
                                                                                                 color: "white",
                                                                                                 borderRadius: "15px",
                                                                                                 width: "25px",
                                                                                            }}
                                                                                            onClick={() => this.onClickDelCol(i, ind, "saler")}
                                                                                       >
                                                                                            x
                                                                                       </button>
                                                                                  </td>
                                                                             </tr>
                                                                        ))
                                                                      : null}
                                                            </tbody>
                                                       </TableBoot>
                                                       <div className="button-group __submit row">
                                                            <Col xs={6} className="text-center">
                                                                 <Button
                                                                      className="w-50"
                                                                      variant="danger"
                                                                      onClick={() => this.onClickDelFormula(i, "team")}
                                                                 >
                                                                      ลบสูตร
                                                                 </Button>
                                                            </Col>
                                                            <Col xs={6} className="text-center">
                                                                 <Button
                                                                      className="w-50"
                                                                      variant="success"
                                                                      onClick={() => this.onClickAddFormula("team")}
                                                                 >
                                                                      เพิ่มสูตร
                                                                 </Button>
                                                            </Col>
                                                       </div>
                                                  </div>
                                             ))
                                   ) : (
                                        <div className="content">
                                             <div className="button-group __submit row">
                                                  <Col xs={12} className="text-center">
                                                       <Button className="w-50" variant="success" onClick={() => this.onClickAddFormula("team")}>
                                                            เพิ่มสูตร
                                                       </Button>
                                                  </Col>
                                             </div>
                                        </div>
                                   )}
                              </div>
                         </div>
                         <div className="button-group __submit row">
                              <Col xs={12} className="text-center">
                                   <Button className="w-50" onClick={this.updateFormula}>
                                        บันทึกสูตร
                                   </Button>
                              </Col>
                         </div>
                    </div>
               </div>
          );
     }
}
