import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Skeleton } from "components/index";
import { GET, POST } from "api/api";
import dayjs from "dayjs";
import swal from "sweetalert";
import { getToken } from "../../../../../static/function";

export default class ListRedLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               file: {},
               fileTextHolder: "",
               loading: false,
               order: [],
               order_search: [],
               search: {
                    free: "ว่าง",
               },
               branch: [],
          };
     }

     componentDidMount = () => {
          this.getlist();
          this.getBranch();
     };

     getlist = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/car/get_label");
               // console.log(`res`, res)
               let order_search = res.sort((end, start) => parseFloat(start.label_id) - parseFloat(end.label_id));
               let order = order_search.filter((el) => el.free === "ว่าง");
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };

     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               getBranch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error", error);
          }
     };

     handleClick = () => {
          window.location.assign("/admin/label/list/manage/add");
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.label_brrow_date ? dayjs(el.label_brrow_date).format("DD/MM/YYYY") === search.label_brrow_date : el) &&
                         (search.labelhis_open_date ? dayjs(el.labelhis_open_date).format("DD/MM/YYYY") === search.labelhis_open_date : el) &&
                         (search.free ? (search.free === "ทั้งหมด" ? el : String(el.free) === String(search.free)) : el)
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.label_no ? String(el.label_no).indexOf(String(search.label_no)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     /* --------------- formatter ------------------ */
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          switch (formatExtraData) {
               case "generate":
                    return <a onClick={() => window.location.assign(`/admin/label/list/${row.label_id}`)}>ทำรายการ</a>;
               case "preemtion_id":
                    if (row.free === "ว่าง") {
                         return <span>-</span>;
                    } else if (!row.order_id) {
                         return <span>{"ไม่มีใบจอง"}</span>;
                    } else {
                         return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
                    }

               case "label_book":
                    if (row.label_book) {
                         return <span>{cell}</span>;
                    } else {
                         return <span>-</span>;
                    }
          }
     }

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.empty_status === "ว่าง") {
               return <p>-</p>;
          } else {
               if (cell !== "-") {
                    return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
               } else {
                    return <p>-</p>;
               }
          }
     };

     /* Handle File upload */
     handleChange = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };

     handleUploadFile = async () => {
          const { file } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    formData.append("type", "car_red_master");
                    formData.append("file", file);

                    this.setState({ loading: true });
                    await POST("/aos/car_red_master", formData, true);
                    await this.getlist();
                    this.setState({ loading: false, fileTextHolder: "", file: null });
               }
          } catch (error) {
               swal({
                    text: "กรุณาอัปโหลดให้ถูกประเภท",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false });
          }
     };

     downloadFile = async () => {
          try {
               window.open(await POST("/ps/download_excel", { download: "redplate" }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     render() {
          let { order, newSearch, search, fileTextHolder, loading, branch } = this.state;
          let table = {
               column: [
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                         formatExtraData: "generate",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preemtion_id",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "label_no",
                         text: "เลขทะเบียน",
                         // headerClasses: "header-custom __left",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "label_province",
                         text: "จังหวัด",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "label_permit",
                         text: "เลขที่ใบอนุญาต",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "label_book",
                         text: "เลขที่เล่ม",
                         formatter: this.detailFormatter,
                         formatExtraData: "label_book",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "branch_name",
                         text: "สาขา",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "free",
                         text: "ว่าง/ไม่ว่าง",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "label_brrow_date",
                         text: "วันที่ยืม",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "labelhis_open_date",
                         text: "วันที่เบิกป้าย",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "status",
                         text: "สถานะ",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "hisstatus",
                         text: "ให้/คืน",
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "registra_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "รายการป้ายแดง" }]}
                         active={0}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "เพิ่มรายการป้ายแดง",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __main-panel listlabel">
                         <div className="search-bar">
                              <Search
                                   type={"list-label"}
                                   className="w-100 pr-10"
                                   handleSelectDate={this.handleChangeDate}
                                   handleChangeText={this.handleChangeText}
                                   handleClickSearch={this.handleClickSearch}
                                   option={{
                                        value: search,
                                        branchs: branch,
                                   }}
                              />
                              <Search
                                   className="w-100 pr-5"
                                   type={"upload"}
                                   title={{
                                        name: "อัปโหลด Master ป้ายแดง",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __below-btn"}
                                   handleChange={this.handleChange}
                                   handleUploadFile={this.handleUploadFile}
                                   downloadFile={this.downloadFile}
                                   disabled={loading}
                              />
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
