import React, { Component } from "react";
import { POST } from "api/index";
import { Breadcrumbs, Table, Search, Image } from "components/index";

export default class Approve extends Component {
     constructor(props) {
          super(props);
          this.state = {
               approve: [],
               approve_backup: [],
               search: {},
          };
     }
     componentDidMount = () => {
          this.getApprove();
     };
     getApprove = async () => {
          try {
               let result = await POST("/ps/confirm_list", { log_type: "item" });
               result.sort((end, start) => parseFloat(start.log_id) - parseFloat(end.log_id)).sort((end, start) => parseFloat(start.log_id) - parseFloat(end.log_id));
               this.setState({ approve: result, approve_backup: result });
          } catch (error) {
               console.log(error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/item/approve/${row.log_id}`)}>ทำรายการ</a>;
     }
     linearSearch = (value) => {
          const supplier_type = [
               { value: "TMT", name: "TMT" },
               { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง (TOYOTA)", name: "อุปกรณ์ตกแต่ง (TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง (Out Source Supplier)", name: "อุปกรณ์ตกแต่ง (Out Source Supplier)" },
               { value: "ประกัน", name: "ประกัน" },
               { value: "Leasing", name: "Leasing" },
               { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
          ];
          for (let i = 0; i < supplier_type.length; i++) {
               if (supplier_type[i].value === value) {
                    return true;
               }
          }
          return false;
     };
     onSearch = () => {
          let { approve_backup, search } = this.state;
          console.log("approve_backup", approve_backup);
          console.log(search);
          let approve = approve_backup.filter(
               (e) =>
                    (search.items_code ? String(e.items_code).toLowerCase().includes(String(search.items_code).toLowerCase()) : e) &&
                    (search.items_name ? String(e.items_name).toLowerCase().includes(String(search.items_name).toLowerCase()) : e) &&
                    (search.status
                         ? String(e.status).toLowerCase() === String(search.status).toLowerCase() || String(search.status).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.items_type === "อื่น ๆ"
                         ? !this.linearSearch(e.items_type)
                              ? e
                              : null
                         : search.items_type
                         ? String(e.items_type).toLowerCase() === String(search.items_type).toLowerCase() || String(search.items_type).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.supplier_name ? String(e.supplier_name).includes(String(search.supplier_name).toLowerCase()) : e) &&
                    (search.manage_type
                         ? String(e.log_do).toLowerCase() === String(search.manage_type).toLowerCase() || String(search.manage_type).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ approve });
          // console.log("item", item)
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };

     render() {
          let { search } = this.state;
          const table = {
               column: [
                    {
                         // dataField: 'log_status',
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "items_code",
                         text: "Item Code",
                         headerClasses: "header-custom  __btn-ss",
                    },
                    {
                         dataField: "items_name",
                         text: "Item Name",
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท Item",
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         headerClasses: "header-custom  __btn-ml",
                    },
                    {
                         dataField: "log_do",
                         text: "ประเภทรายการ",
                         headerClasses: "header-custom  __btn-s",
                    },
                    {
                         dataField: "log_status",
                         text: "การอนุมัติ",
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: this.state.approve.map((el) => ({ ...el, items_code: el.items_code ? el.items_code : "-" })),
               keyField: "item_code",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "การอนุมัติ Item" }]} active={0} button={""} />
                    <div className="content __main-panel item">
                         <Search
                              type={"approve"}
                              searchOptions={search}
                              onSearch={this.onSearch}
                              handleChangeText={this.handleChangeText}
                              handleChange={this.handleChangeOption}
                         />
                         <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                    </div>
               </div>
          );
     }
}
