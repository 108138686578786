import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Skeleton } from "components/index";
import { GET, POST } from "api/api";
import swal from "sweetalert";
export default class Insurance extends Component {
     constructor(props) {
          super(props);
          this.state = {
               insurance_list: [],
               file: {},
               fileTextHolder: "",
               search: {},
               insurance_list_backup: {},
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getInsurance();
     };
     getInsurance = async () => {
          try {
               let result = await GET("/aos/get_insurance/");
               result
                    .sort((end, start) => parseFloat(start.insurance_id) - parseFloat(end.insurance_id))
                    .sort((end, start) => parseFloat(start.insurance_id) - parseFloat(end.insurance_id));
               // console.log(result)
               this.setState({ insurance_list: result, insurance_list_backup: result });
          } catch (error) {
               console.log(error);
          }
     };
     onSearch = () => {
          let { insurance_list_backup, search } = this.state;
          let insurance_list = insurance_list_backup.filter(
               (e) =>
                    (search.insurance_type ? String(e.insurance_type).toLowerCase().includes(String(search.insurance_type).toLowerCase()) : e) &&
                    (search.status
                         ? String(e.status).toLowerCase().includes(String(search.status).toLowerCase()) || String(search.status).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ insurance_list });
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };

     handleClick = () => {
          window.location.assign("/admin/insurance/detail/add");
     };

     //Formatter
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <i className="fas fa-file-alt icon __btn" onClick={() => window.location.assign(`/admin/insurance/detail${row.insurance_id}`)}></i>;
     }

     handleChange = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };

     handleUploadFile = async () => {
          const { file } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    formData.append("type", "insurance_master");
                    formData.append("file", file);

                    this.setState({ loading: true });
                    await POST("/aos/insurance_master", formData, true);
                    await this.getInsurance();
                    this.setState({ loading: false, fileTextHolder: "", file: null });
               }
          } catch (error) {
               swal({
                    text: "กรุณาอัปโหลดให้ถูกประเภท",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false });
          }
     };

     downloadFile = async () => {
          try {
               window.open(await POST("/ps/download_excel", { download: 'insuranceprod' }))
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     render() {
          let { fileTextHolder, search, loading } = this.state;
          const car_reserve = [
               {
                    dataField: "detail",
                    text: "ข้อมูล",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "insurance_type",
                    text: "ประเภทประกัน",
                    // headerClasses: "header-custom __left",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "status",
                    text: "สถานะ",
                    headerClasses: "header-custom __right __btn-ss",
               },
          ];
          return (
               // <div className="panel hide-scroll">
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "ประกัน" }]}
                         active={0}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "เพิ่มประกัน",
                                   handleClick: this.handleClick,
                              },
                         ]}
                         onClick={() => this.props.history.goBack()}
                    />
                    <div className="content __main-panel listinsurance">
                         <div className="search-bar">
                              <Search
                                   type={"insurance"}
                                   searchOptions={search}
                                   onSearch={this.onSearch}
                                   handleChangeText={this.handleChangeText}
                                   handleChange={this.handleChangeOption}
                              />
                              <Search
                                   type={"upload"}
                                   title={{
                                        name: "อัปโหลด  Insurance Master",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __below-btn"}
                                   handleChange={this.handleChange}
                                   handleUploadFile={this.handleUploadFile}
                                   downloadFile={this.downloadFile}
                                   disabled={loading}
                              />
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={car_reserve} />
                         ) : (
                                   <Table type={"index-fixhead"} column={car_reserve} data={this.state.insurance_list} keyField={"a_card"} />
                              )}
                    </div>
               </div>
          );
     }
}
