import { GET, POST } from "api";
import { Breadcrumbs, Field, LabelBar, ModalPanel, Table } from "../../../../../components";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
// import { ROW_SELECT_SINGLE } from "react-bootstrap-table-next";
import { getToken } from "../../../../../static/function";
import Spinner from "react-bootstrap/Spinner";

export default class SaleReport extends Component {
     constructor(props) {
          super(props);
          this.state = {
               show: false,
               sales: {
                    status: [],
                    branch: [],
               },
               report: {
                    title: "",
                    type: 0,
               },
               getSale: [],
               teams: [],
               loading: false,
          };
     }
     componentDidMount = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               let getSale = await GET("/aos/get_sale");
               let get_team = await GET("/aos/get_team");
               // console.log("getSale", getSale);
               // let getseries = await GET("/car/series");
               // let series = getseries.map((el) => ({ value: el.series_id, name: el.series_name }));
               // series.unshift({ value: "all", name: "Series ทั้งหมด" });
               let data_team = get_team.map((el) => ({ value: el.team_id, name: el.team_name }));
               data_team.unshift({ value: "all", name: "ทีมทั้งหมด" });
               let data = getSale.map((el) => ({ value: el.user_id, name: el.name, team_id: el.team_id, branch_id: Number(el.branch_id) }));
               this.setState({
                    branch: getBranch,
                    getSale_2: data,
                    series: [],
                    sales: { branch: getBranch.map((el) => el.branch_id) },
                    teams: data_team,
               });
          } catch (error) {
               console.log("error", error);
          }
     };

     getModel = async (series_id) => {
          try {
               let res = await POST("/car/model", { series_id });
               let model = res.map((el) => ({ value: el.model_id, name: el.model_name }));
               model.unshift({ value: "all", name: "Model ทั้งหมด" });
               this.setState({ model });
          } catch (error) {
               console.log("error", error);
          }
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { sales, branch } = this.state;
          // if (name === "branch_name") {
          //      // sales.branch_id = branch.find((el) => String(el.branch_name) === String(value)).branch_id;
          //      // sales.user_id = "all";
          //      // this.filterSeller(sales.branch_id);
          // }
          // if (name === "series_id") {
          //      this.getModel(value);
          // }
          if (name === "team" && value === "all") {
               sales["user_id"] = "all";
               this.setState({ getSale: [{ value: "all", name: "พนักงานขายทุกคน" }] });
          }
          if (name === "team" && value !== "all") {
               sales["user_id"] = null;
               this.filterSeller(value);
          }
          sales[name] = value;
          this.setState({ sales });
     };
     filterSeller = (team_id) => {
          // console.log("team_id", team_id);
          let { getSale_2 } = this.state;
          // console.log('getSale_2', getSale_2)
          let data = getSale_2.filter((el) => el.team_id === Number(team_id));
          data.unshift({ value: "all", name: "พนักงานขายทุกคน" });
          this.setState({ getSale: data });
     };

     handleChangeDate = (date, dateString, name) => {
          let { sales } = this.state;
          sales[name] = dateString;
          this.setState({ sales });
     };

     handleChangeCheckBox(checked, e) {
          let { sales } = this.state;
          if (checked) {
               sales.status.push(e.target.value);
               this.setState({ sales });
          } else {
               let index = sales.status.findIndex((el) => el === e.target.value);
               sales.status.splice(index, 1);
               this.setState({ sales });
          }
     }
     onChangeCheckbox = ({ target: { name, value, checked } }) => {
          let { sales } = this.state;
          if (name === "branch") {
               // console.log(name + " " + value, checked);
               if (checked) {
                    sales[name].push(Number(value));
                    sales[name] = sales[name].sort((a, z) => a - z);
                    this.setState({ sales });
               } else {
                    let index = sales[name].findIndex((el) => el === Number(value));
                    sales[name].splice(index, 1);
                    sales[name] = sales[name].sort((a, z) => a - z);
                    this.setState({ sales });
               }
          } else {
               sales[name] = value;
               this.setState({ sales });
          }
     };
     closeReport = async () => {
          this.setState({ loading: true });
          let { branch, sales, row_id } = this.state;
          // console.log(`sales`, sales);
          try {
               if (Number(row_id) === 1 && sales.user_id && sales.status.length && sales.date_start && sales.date_end && sales.branch.length) {
                    let res = await POST("/aos/report_sales_one", {
                         ...sales,
                         branch: branch.filter((el) => sales.branch.some((e) => e === el.branch_id)),
                    });
                    window.open(res);
                    this.setState({
                         show: !this.state.show,
                         response: "",
                         // sales: { status: [] },
                         loading: false,
                    });
               } else if (Number(row_id) === 4 && sales.user_id && sales.date_start && sales.date_end && sales.branch.length) {
                    let res = await POST("/aos/report_sales_two", {
                         ...sales,
                         branch: branch.filter((el) => sales.branch.some((e) => e === el.branch_id)),
                    });
                    window.open(res);
                    this.setState({
                         show: !this.state.show,
                         response: "",
                         // sales: { status: [] },
                         loading: false,
                    });
               } else if (Number(row_id) === 8 && sales.user_id && sales.date_start && sales.date_end && sales.branch.length) {
                    let res = await POST("/aos/report_sales_three", {
                         ...sales,
                         branch: branch.filter((el) => sales.branch.some((e) => e === el.branch_id)),
                    });
                    window.open(res);
                    this.setState({
                         show: !this.state.show,
                         response: "",
                         // sales: { status: [] },
                         loading: false,
                    });
               } else if (Number(row_id) === 10 && sales.date_start_old && sales.date_end_old && sales.date_start && sales.date_end) {
                    let res = await POST("/ps/report_excel_sell", { ...sales });
                    window.open(res);
                    this.setState({
                         show: !this.state.show,
                         response: "",
                         // sales: { status: [] },
                         loading: false,
                    });
               } else {
                    this.setState({
                         response: "กรุณากรอกให้ครบ",
                         loading: false,
                    });
               }
               // if (
               //      (Number(row_id) === 2 || Number(row_id) === 3 || Number(row_id) === 4 || Number(row_id) === 7) &&
               //      sales.user_id &&
               //      sales.date_end &&
               //      sales.date_start &&
               //      sales.branch_id
               // ) {
               //      let res = "";
               //      if (Number(row_id) === 2) {
               //           res = await POST("/aos/report_sales_two", sales);
               //      }
               //      if (Number(row_id) === 3) {
               //           res = await POST("/aos/report_sales_three", sales);
               //      }
               //      if (Number(row_id) === 4) {
               //           res = await POST("/aos/report_sales_four", sales);
               //      }
               //      if (Number(row_id) === 7) {
               //           res = await POST("/aos/report_sales_seven", sales);
               //      }
               //      window.open(res);
               //      this.setState({
               //           show: !this.state.show,
               //           response: "",
               //           // sales: { status: [] },
               //      });
               // }
               // if ((Number(row_id) === 5 || Number(row_id) === 6) && sales.branch_id) {
               //      let branch_id = sales.branch_id;
               //      let res = "";
               //      if (Number(row_id) === 5) {
               //           res = await POST("/aos/report_sales_five", { branch_id });
               //      }
               //      if (Number(row_id) === 6) {
               //           res = await POST("/aos/report_sales_six", { branch_id });
               //      }
               //      //---------------------------------------------------------------//
               //      //---------------------------------------------------------------//
               //      window.open(res);
               //      this.setState({
               //           show: !this.state.show,
               //           response: "",
               //           // sales: { status: [] },
               //      });
               // }
               // if (
               //      Number(row_id) === 8 &&
               //      sales.branch_id &&
               //      // sales.series_id &&
               //      // sales.model_id &&
               //      sales.date_start &&
               //      sales.date_end &&
               //      sales.user_id
               //      // sales.status
               // ) {
               //      sales.type_excel = "รายงานการขายที่ยังไม่ส่งมอบ (Back Order)";
               //      let res = await POST("/ps/report_excel_sell", { sales });
               //      window.open(res);
               //      this.setState({
               //           show: !this.state.show,
               //           response: "",
               //           // sales: { status: [] },
               //      });
               // }
               // if (Number(row_id) === 9) {
               //      sales.type_excel = "รายงานเปลี่ยนพนักงานขาย";
               //      let res = await POST("/ps/report_excel_sell", { sales });
               //      window.open(res);
               //      this.setState({
               //           show: !this.state.show,
               //           response: "",
               //           // sales: { status: [] },
               //      });
               // }
          } catch (error) {
               this.setState({
                    response: error,
                    loading: false,
               });
          }
     };
     showReport = (title, type, row_id, id) => {
          let { report, sales } = this.state;
          report.title = title;
          report.type = type;
          if (id === 1) {
               this.setState({
                    show: !this.state.show,
                    report,
                    row_id: id,
                    sales: {
                         ...sales,
                         status: [
                              "ใบจองรออนุมัติ",
                              "ใบจองอนุมัติแล้ว",
                              "รับเงินจองแล้ว",
                              "Leasing อนุมัติแล้ว",
                              "ยืนยันข้อมูล",
                              "จับคู่รถแล้ว",
                              "ติดตั้งอุปกรณ์แล้ว",
                              "ชำระเงินแล้ว",
                              "ส่งมอบรถแล้ว",
                              "ปิดการขาย",
                         ],
                    },
               });
          } else {
               this.setState({
                    show: !this.state.show,
                    report,
                    row_id: id,
                    sales: {
                         type_excel: id === 10 ? "รายงานตัวเลขการขาย" : "",
                         ...sales,
                         status: [],
                    },
               });
          }
     };
     /* --------------- formatter ----------------- */
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.showReport(`${row.report}`, row.type, rowIndex, row.id)}>ทำรายการ</a>;
     };

     render() {
          let {
               show,
               sales,
               report: { title, type },
               branch,
               response,
               getSale,
               teams,
               row_id,
          } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left  __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    {
                         report: "รายงานการขายรายพนักงานขายรายเดือน",
                         type: 1,
                         id: 1,
                    },
                    // {
                    //      report: "รายงานการขายรายพนักงาน (ที่ส่งมอบแล้ว)",
                    //      type: 2,
                    //      id: 2,
                    // },
                    // {
                    //      report: "รายงานการจองรถยนต์",
                    //      type: 2,
                    //      id: 3,
                    // },
                    {
                         report: "รายงานส่งมอบรถยนต์",
                         type: 1,
                         id: 4,
                    },
                    // {
                    //      report: "รายงานการขายที่บริษัทแถมประกัน",
                    //      type: 2,
                    //      id: 7,
                    // },
                    {
                         report: "รายงานการขายที่ยังไม่ส่งมอบ (Back Order)",
                         type: 1,
                         id: 8,
                    },
                    {
                         report: "รายงานตัวเลขการขาย",
                         type: 5,
                         id: 10,
                    },
               ],
               keyField: "no",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานการขาย" }]} active={0} button={""} />
                    <div className="content __input-panel" id="table-content">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"summary-custom-padding"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                                   <Modal
                                        show={show}
                                        centered
                                        size="lg"
                                        onHide={() =>
                                             this.setState({
                                                  show: !this.state.show,
                                                  // sales: { status: [] },
                                                  response: "",
                                             })
                                        }
                                   >
                                        <Modal.Header>
                                             <Modal.Title className="text-center">
                                                  <strong>{title}</strong>
                                                  {response && <p style={{ color: "red" }}>*{response}*</p>}
                                             </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{ padding: "0 5%" }}>
                                             {type === 1 && (
                                                  <>
                                                       <Row>
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={sales.date_start ? sales.date_start : "เลือกวันที่"}
                                                                 title={"ประจำวันที่"}
                                                                 md={6}
                                                                 name={"date_start"}
                                                                 handleSelectDate={this.handleChangeDate}
                                                                 require={true}
                                                            />
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={sales.date_end ? sales.date_end : "เลือกวันที่"}
                                                                 title={"ถึงวันที่"}
                                                                 md={6}
                                                                 name={"date_end"}
                                                                 handleSelectDate={this.handleChangeDate}
                                                                 require={true}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <Field
                                                                 type={"select"}
                                                                 title={"ทีมพนักงาน"}
                                                                 name={"team"}
                                                                 option={teams}
                                                                 value={sales?.team}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"พนักงานขาย"}
                                                                 name={"user_id"}
                                                                 option={getSale}
                                                                 value={sales?.user_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                                 disabled={sales.team === "all"}
                                                            />
                                                       </Row>
                                                       <LabelBar
                                                            type={"checkbox"}
                                                            title={"สาขา"}
                                                            name={"branch"}
                                                            md={12}
                                                            label={branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            arrChecked={sales?.branch}
                                                            handleChangeText={this.onChangeCheckbox}
                                                       />
                                                       {row_id === 1 && (
                                                            <LabelBar
                                                                 type={"checkbox"}
                                                                 title={"สถานะการขาย"}
                                                                 name={"status_sale"}
                                                                 label={[
                                                                      { value: "ใบจองรออนุมัติ", name: "ใบจองรออนุมัติ" },
                                                                      { value: "ใบจองอนุมัติแล้ว", name: "ใบจองอนุมัติแล้ว" },
                                                                      { value: "รับเงินจองแล้ว", name: "รับเงินจองแล้ว" },
                                                                      { value: "Leasing อนุมัติแล้ว", name: "Leasing อนุมัติแล้ว" },
                                                                      { value: "ยืนยันข้อมูล", name: "ยืนยันข้อมูล" },
                                                                      { value: "จับคู่รถแล้ว", name: "จับคู่รถแล้ว" },
                                                                      { value: "ติดตั้งอุปกรณ์แล้ว", name: "ติดตั้งอุปกรณ์แล้ว" },
                                                                      { value: "ชำระเงินแล้ว", name: "ชำระเงินแล้ว" },
                                                                      { value: "ส่งมอบรถแล้ว", name: "ส่งมอบรถแล้ว" },
                                                                      { value: "ปิดการขาย", name: "ปิดการขาย" },
                                                                 ]}
                                                                 value={sales?.status_sale}
                                                                 arrChecked={sales?.status}
                                                                 handleChangeText={(e) => this.handleChangeCheckBox(e.target.checked, e)}
                                                            />
                                                       )}
                                                  </>
                                             )}

                                             {type === 5 && (
                                                  <Row>
                                                       <Field
                                                            type={"date"}
                                                            placeholder={sales.date_start_old ? sales.date_start_old : "เลือกวันที่"}
                                                            title={"ประจำวันที่(เดือนที่ผ่านมา)"}
                                                            md={6}
                                                            name={"date_start_old"}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            placeholder={sales.date_end_old ? sales.date_end_old : "เลือกวันที่"}
                                                            title={"ถึงวันที่(เดือนที่ผ่านมา)"}
                                                            md={6}
                                                            name={"date_end_old"}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            placeholder={sales.date_start ? sales.date_start : "เลือกวันที่"}
                                                            title={"ประจำวันที่(เดือนปัจจุบัน)"}
                                                            md={6}
                                                            name={"date_start"}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            placeholder={sales.date_end ? sales.date_end : "เลือกวันที่"}
                                                            title={"ถึงวันที่(เดือนปัจจุบัน)"}
                                                            md={6}
                                                            name={"date_end"}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                  </Row>
                                             )}

                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       {this.state.loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button onClick={this.closeReport}>แสดงรายงาน</Button>
                                                       )}
                                                  </Col>
                                             </Row>
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
