import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Row, Button, Col } from "react-bootstrap";
import dayjs from "dayjs";
import { POST } from "api";
import swal from "sweetalert";

export default class InstallItem extends Component {
     constructor(props) {
          super(props);
          this.state = {
               items_id: this.props.match.params.item_id,
               sub_id: this.props.match.params.sub_id,
               order_item: [],
               data: [],
               order_send: {},
               detail: {},
          };
     }

     componentDidMount = () => {
          this.getDetailItem();
          this.getOrderItem();
     };

     getDetailItem = async () => {
          try {
               let result = await POST("/item/this", { items_id: this.state.items_id });
               this.setState({ detail: { ...result } });
          } catch (error) {
               console.log("error", error);
          }
     };

     getOrderItem = async () => {
          let { order_item, items_id } = this.state;
          let items = await POST("/item/this_order_item", { items_id: items_id });
          this.setState({ data: items });
          for (let i in items) {
               if (items[i].order_ac_type === "bonus") {
                    items[i].order_ac_type = "แถม";
               } else {
                    items[i].order_ac_type = "ซื้อ";
               }
          }
          this.setState({ order_item: items });
     };

     openPopup = () => {
          /* Modal case="default" */
          swal({
               icon: "warning",
               text: "ยืนยันการออกรายงาน",
               buttons: {
                    cancel: {
                         visible: true,
                         text: "ยกเลิก",
                    },
                    submit: {
                         visible: true,
                         value: true,
                         text: "ยืนยัน",
                    },
               },
          }).then((value) => {
               if (value) {
                    this.onClickSendOrder();
               }
          });
     };

     /* ------------------ onClick --------------------- */

     onClickSendOrder = async () => {
          let { order_send } = this.state;

          try {
               let result = await POST("/item/send_order", order_send);
               if (result === "add success") {
                    swal({
                         icon: "success",
                         title: "ดำเนินการเรียบร้อย",
                         // text: "ข้อมูลการสั่งซื้อจะถูกส่งไปยัง Email ของ Supplier",
                         buttons: { submit: { text: "เสร็จสิ้น" } },
                         closeOnClickOutside: false,
                    });
               }
          } catch (err) {
               swal({
                    icon: "error",
                    title: "ดำเนินการเรียบร้อยล้มเหลว",
                    // text: "กรุณาตรวจสอบการทำรายการอีกครั้ง",
                    buttons: { submit: { text: "เสร็จสิ้น" } },
                    closeOnClickOutside: false,
               });
          }
     };

     /* ------------------ onChange --------------------- */

     onChangeDateOrder = (date, dateString, name) => {
          let { data, order_send, items_id } = this.state;
          order_send[name] = dateString;

          for (let i in data) {
               if (data[i].order_ac_type === "แถม") {
                    data[i].order_ac_type = "bonus";
               } else {
                    data[i].order_ac_type = "buy";
               }
          }
          order_send["items_id"] = items_id;
          order_send["data"] = data;

          this.setState({ order_send });
     };

     /* ----------------- formatter -------------------- */

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "preemtion_sale_no":
                    return (
                         <a
                         // onClick={() =>
                         //     window.location.assign(
                         //         `/admin/payment/install/device/${row.supplier_code}`
                         //     )
                         // }
                         >
                              {row.orderac_id}
                         </a>
                    );
          }
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };
     parseFloatFormatter = (cell, row) => {
          let new_cell = parseFloat(cell).toFixed(2);
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     };
     /* ----------- footer formater -------------- */
     totalPrice = (data) => {
          let total = 0;
          for (let i in data) {
               total += data[i].items_price;
          }
          total = total.toFixed(2);
          return `${total}`;
     };

     render() {
          let { order_item, detail } = this.state;
          const table = {
               column: [
                    {
                         dataField: "orderac_id",
                         text: "เลขที่ใบจอง / ใบสั่งขาย",
                         formatter: this.detailFormatter,
                         footer: "มูลค่ารวม",
                         footerClasses: "summary-custom ",
                         formatExtraData: "preemtion_sale_no",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "created_at",
                         text: "วันจอง/วันสั่งซื้อ",
                         formatter: this.dateFormatter,
                         footer: this.totalPrice(order_item),
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "delivery_date",
                         text: "วันส่งมอบ",
                         formatter: this.dateFormatter,
                         footer: "บาท",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "series_name",
                         text: "Series",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "engine_no",
                         text: "เลขเครื่อง",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "body_no",
                         text: "เลขถัง",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ลูกค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "saler_name",
                         text: "พนักงานขาย",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_type",
                         text: "แถม/ ซื้อ",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_price",
                         text: "ราคา (บาท)",
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right",
                         formatter: this.parseFloatFormatter,
                    },
               ],
               data: order_item ? order_item : [],
               keyField: "preemtion_sale_no",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `ติดตั้งอุปกรณ์`,
                                   onClick: () => window.location.assign("/admin/install/report"),
                              },
                              {
                                   title: `${detail?.supplier_name || "Supplier Name"}`,
                                   onClick: () => window.location.assign(`/admin/install/report/${this.state.sub_id}`),
                              },
                              { title: `${detail?.items_name || "Item Name"}` },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Item Code : ",
                                                            detail: detail?.items_code || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Item Name : ",
                                                            detail: detail?.items_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Name : ",
                                                            detail: detail?.supplier_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายการใบจองที่สั่งซื้ออุปกรณ์ตกแต่งรถ"} />
                                        <div className="-padding">
                                             <Row>
                                                  <Field
                                                       type={"select"}
                                                       title={"ประเภทใบสั่งซื้อ"}
                                                       placeholder={"เลือก"}
                                                       require={false}
                                                       md={3}
                                                       option={[
                                                            { value: "0", name: "ทั้งหมด" },
                                                            { value: "1", name: "ใบจองรถยนต์" },
                                                            { value: "2", name: "ใบสั่งขายอุปกรณ์" },
                                                       ]}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันจอง/วันสั่งซื้อ (เริ่มต้น)"}
                                                       placeholder={"วว-ดด-ปปปป"}
                                                       md={3}
                                                       require={false}
                                                       name={"order_date_start"}
                                                       handleSelectDate={this.onChangeDateOrder}
                                                  />
                                                  ถึง
                                                  <Field
                                                       type={"date"}
                                                       title={"วันจอง/วันสั่งซื้อ (สิ้นสุด)"}
                                                       placeholder={"วว-ดด-ปปปป"}
                                                       md={3}
                                                       require={false}
                                                       name={"order_date_end"}
                                                       handleSelectDate={this.onChangeDateOrder}
                                                  />
                                             </Row>
                                        </div>
                                        <div className="table-content-no-margin">
                                             <div className="content order-item" id="table-content">
                                                  <Table type="summary" column={table.column} data={table.data} keyField={table.keyField} />
                                             </div>
                                        </div>

                                        {/* <LabelBar type={'title'} title={''} />
                                <Row>
                                    <LabelBar
                                        type={'textheader'}
                                        title={{
                                            name: 'มูลค่ารวม',
                                            detail: '0.00 บาท',
                                        }}
                                        md={6}
                                    />
                                </Row> */}
                                        <Row style={{ marginTop: "20px" }}>
                                             <Col md={5}></Col>
                                             <Col md={2}>
                                                  <Button onClick={this.openPopup}>ออกรายงาน</Button>
                                             </Col>
                                             <Col md={5}></Col>
                                        </Row>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
