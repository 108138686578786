import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, ModalPanel, Table } from "components/index";
import { GET, POST } from "api";
import { Button, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import InputPanel from "components/input-panel/input-panel";
import swal from "sweetalert";
function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}
export default class GenerateInstallListAcc extends Component {
     constructor(props) {
          super(props);
          this.state = {
               acorder_id: this.props.match.params.id,
               order: {
                    item: [],
               },
               showmodal_mail: false,
               send_mail_acc_date: dayjs(new Date()).format("YYYY-MM-DD"),
               arrChecked: [],
               check: [],
               updateAcc: [],
               btn_approve: true,
               total: 0,
          };
     }

     componentDidMount = () => {
          this.getAccOrder();
     };

     getAccOrder = async () => {
          let { arrChecked, updateAcc } = this.state;
          let items_fun = 0;
          let items_price = 0;
          try {
               let acorder = await POST("/order/this_acc_order", { acorder_id: this.state.acorder_id });

               for (let i in acorder.item) {
                    acorder.item[i].install === "ติดตั้งแล้ว" ? arrChecked.push(true) : arrChecked.push(false);

                    updateAcc[i] = {
                         acitem_id: acorder.item[i].acitem_id,
                         install: acorder.item[i].install,
                    };
                    items_fun += acorder.item[i].items_fun;
                    items_price += acorder.item[i].items_price;
               }

               let install_status = acorder?.item.find((el) => el.install === "รอการติดตั้ง");

               let vat = (items_price * 7) / 107;

               let footer = [
                    {
                         items_type: "รวมราคาสินค้า",
                         items_fun: items_fun,
                         items_price: items_price,
                         field: "summary-detail",
                    },
                    {
                         items_type: "ภาษีมูลค่าเพิ่ม 7 %",
                         items_fun: null,
                         items_price: vat,
                         field: "summary-detail",
                    },
               ];

               acorder.item.push(footer[0]);
               acorder.item.push(footer[1]);

               this.setState({
                    order: { ...acorder },
                    arrChecked,
                    updateAcc,
                    btn_approve: install_status ? false : true,
                    total: items_price + vat,
               });
          } catch (error) {
               console.log("error", error);
          }
     };

     /* -------------------- onClick -------------------- */
     openModal = () => {
          this.setState({ showmodal_mail: true });
     };

     onClickSendMailSupplier = async () => {
          let { order } = this.state;
          try {
               let r = await POST("/order/send_mail_acc", {
                    acorder_id: this.state.acorder_id,
                    send_mail_acc_date: this.state.send_mail_acc_date,
                    type_mail: "acorder",
               });
               order.order_mail_acc = 1;
               this.setState({ order, showmodal_mail: false });
               swal({
                    icon: "success",
                    title: "สั่งซื้ออุปกรณ์เรียบร้อยแล้ว",
                    text: "ข้อมูลการสั่งซื้อจะถูกส่งไปยัง Email ของ Supplier",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               });
               // .then(() => {
               //      window.location.reload();
               // });
          } catch (err) {
               console.log(err);
          }
     };

     onClickUpdate = async () => {
          let { updateAcc, order, check } = this.state;
          let arr = order.item;
          order.item = [];
          await this.setState({ order });
          order.item = arr;
          for (let i in check) {
               updateAcc[i].acitem_id = order.item[i].acitem_id;
               if (check[i]) {
                    updateAcc[i].install = "ติดตั้งแล้ว";
                    order.item[i].install = "ติดตั้งแล้ว";
               } else {
                    updateAcc[i].install = "รอการติดตั้ง";
                    order.item[i].install = "รอการติดตั้ง";
               }
          }

          try {
               let result = await POST("/order/update_acorder_ac", { updateAcc: updateAcc });
               swal({
                    icon: "success",
                    title: "บันทึกเรียบร้อยแล้ว",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               });
          } catch (err) {
               console.log("error : ", err);
          }

          let install_status = order?.item.find((el) => el.install === "รอการติดตั้ง");

          this.setState({
               order,
               updateAcc,
               btn_approve: install_status ? false : true,
          });
     };

     onClickApprove = async () => {
          let { order } = this.state;
          try {
                    let result = await POST("/order/acept_acorder_ac", { acorder_id: order.acorder_id, ac_install_note: order.ac_install_note });
                    swal({
                         icon: "success",
                         title: "อุปกรณ์ติดตั้งเรียบร้อยแล้ว",
                         buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                         closeOnClickOutside: false,
                    }).then((value) => {
                         window.location.assign(`/admin/install/list/acc/${order.acorder_id}`);
                    }); 
               
          } catch (error) {
               console.log("error", error);
          }
     };

     /* -------------------- onChange -------------------- */
     handleSelectDateMail = (date, dateString, name) => {
          this.setState({ send_mail_acc_date: dateString });
     };

     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          let { check, arrChecked } = this.state;

          check[rowIndex] = checked;
          arrChecked[rowIndex] = !arrChecked[rowIndex];
          this.setState({ check, arrChecked });
     };

     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
     };

     /* -------------------- Modal Body -------------------- */
     modalSendMailSupplier = () => {
          return (
               <div>
                    <div style={{ margin: "0 20%" }}>
                         <h4 style={{ textAlign: "center", marginBottom: "10%" }}>สั่งซื้อและติดตั้งอุปกรณ์</h4>
                         <Field
                              type={"date"}
                              title={"วันที่ติดตั้งอุปกรณ์"}
                              placeholder={this.state.send_mail_acc_date}
                              handleSelectDate={this.handleSelectDateMail}
                         />
                    </div>
               </div>
          );
     };

     /* --------------------formatter -------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { check } = this.state;
          if (row.supplier_name === null) {
               return null;
          } else {
               if (row.install) {
                    return (
                         <LabelBar
                              handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
                              type={"checkbox-check"}
                              name={row.install}
                              label={[{ value: rowIndex }]}
                              checked={formatExtraData[rowIndex].value}
                         />
                    );
               } else {
                    return null;
               }
          }
     };

     detailFormatter = (cell, row) => {
          if (row.install === "รอการติดตั้ง") {
               return <span>{row.install}</span>;
          } else {
               return <span style={{ color: "green" }}>{row.install}</span>;
          }
     };

     render() {
          let { acorder_id, order } = this.state;

          let mail_table = {
               column: [
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : ""),
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท",
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter2,
                         footer: "",
                         headerClasses: "header-custom  __btn-m",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null),
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter,
                         footer: this.state.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null),
                    },
               ],
               data: order?.item,
               keyField: "no",
          };

          let update_table = {
               column: [
                    {
                         dataField: "install",
                         text: "การติดตั้ง",
                         formatter: this.checkBoxFormatter,
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __btn-s",
                         formatExtraData: this.state.arrChecked.map((el) => ({ value: el })),
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         footer: "",
                         footerClasses: "summary-custom",
                         formatter: (e) => (e ? e : ""),
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท",
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน (บาท)",
                         footerClasses: "summary-custom",
                         // formatter: this.parseFloatFormatter2,
                         footer: "",
                         headerClasses: "header-custom  __btn-m",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null),
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footerClasses: "summary-custom",
                         // formatter: this.parseFloatFormatter,
                         footer: this.state.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null),
                    },
               ],
               data: order?.item,
               keyField: "no",
          };

          let success_table = {
               column: [
                    {
                         dataField: "install",
                         text: "การติดตั้ง",
                         formatter: this.detailFormatter,
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __btn-s",
                         formatExtraData: this.state.arrChecked.map((el) => ({ value: el })),
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         footer: "",
                         footerClasses: "summary-custom",
                         formatter: (e) => (e ? e : ""),
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท",
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน (บาท)",
                         footerClasses: "summary-custom",
                         // formatter: this.parseFloatFormatter2,
                         footer: "",
                         headerClasses: "header-custom  __btn-m",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null),
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footerClasses: "summary-custom",
                         // formatter: this.parseFloatFormatter,
                         footer: this.state.total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null),
                    },
               ],
               data: order?.item,
               keyField: "no",
          };

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รายการใบจองที่พร้อมติดตั้ง`,
                                   onClick: () => window.location.assign("/admin/install/list"),
                              },
                              {
                                   title: `รายการใบสั่งขายอุปกรณ์ (${order?.acorder_code_id || " "})`,
                                   onClick: () => window.location.assign(`/admin/order/acc/detail${acorder_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลใบจอง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบสั่งขายอุปกรณ์ : ",
                                                            detail: `${order?.acorder_code_id || ""}`,
                                                       }}
                                                       md={6}
                                                       onClickLabelBar={() => window.location.assign(`/admin/order/acc/detail${acorder_id}`)}
                                                       textHeader_style={"underline"}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "โชว์รูม : ",
                                                            detail: order?.branch_name,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: order?.saler_name_string,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                            detail: order?.acorder_name,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: order?.acorder_idcard,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: order?.acorder_tel ? this.telFormat(String(order?.acorder_tel)) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${order?.acorder_address} ตำบล/แขวง ${order?.acorder_sub_district} อำเภอ/เขต ${order?.acorder_district} จังหวัด ${order?.acorder_province} ${order?.acorder_zipcode}`,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series : ",
                                                            detail: order?.series_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model : ",
                                                            detail: order?.model_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Color : ",
                                                            detail: order?.color_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขตัวถัง : ",
                                                            detail: order?.car_tank || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขเครื่องยนต์ : ",
                                                            detail: order?.car_engine || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายการ Car Accessories"} />
                                        {order?.ac_install === "รอการติดตั้ง" ? (
                                             <>
                                                  {!order?.order_mail_acc ? (
                                                       <>
                                                            <Table
                                                                 type="summary-custom-padding"
                                                                 column={mail_table.column}
                                                                 data={mail_table.data}
                                                                 keyField={mail_table.keyField}
                                                            />
                                                            <Row>
                                                                 <Col md={5}></Col>
                                                                 <Col>
                                                                      <Button onClick={this.openModal}>สั่งซื้อและติดตั้งอุปกรณ์</Button>
                                                                 </Col>
                                                                 <Col md={5}></Col>
                                                            </Row>
                                                       </>
                                                  ) : (
                                                       <>
                                                            <Table
                                                                 type="summary-custom-padding"
                                                                 column={update_table.column}
                                                                 data={update_table.data}
                                                                 keyField={update_table.keyField}
                                                            />
                                                            <Row>
                                                                 <Col md={5}></Col>
                                                                 <Col>
                                                                      <Button onClick={this.onClickUpdate}>บันทึกการติดตั้ง</Button>
                                                                 </Col>
                                                                 <Col md={5}></Col>
                                                            </Row>
                                                       </>
                                                  )}
                                             </>
                                        ) : (
                                             <>
                                                  <Table
                                                       type="summary-custom-padding"
                                                       column={success_table.column}
                                                       data={success_table.data}
                                                       keyField={success_table.keyField}
                                                  />
                                             </>
                                        )}

                                        <ModalPanel
                                             type={"input"}
                                             show={this.state.showmodal_mail}
                                             onHide={() => this.setState({ showmodal_mail: false })}
                                             button={[{ onClick: () => this.onClickSendMailSupplier(), name: "ตกลง", variant: "primary" }]}
                                             body={this.modalSendMailSupplier()}
                                        />
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"อนุมัติการติดตั้ง"} />
                                        {order?.ac_install === "รอการติดตั้ง" ? (
                                             <>
                                                  <div className="-padding">
                                                       <InputPanel
                                                            title={"หมายเหตุ"}
                                                            type={"textarea"}
                                                            name={"ac_install_note"}
                                                            placeholder={order?.ac_install_note}
                                                            value={order?.ac_install_note}
                                                            handleChange={(e) => this.handleChangeText(e)}
                                                            rows={3}
                                                       />
                                                  </div>
                                                  <Row style={{ margin: "20px 0" }}>
                                                       <Col md={5}></Col>
                                                       <Col>
                                                            <Button disabled={!this.state.btn_approve} onClick={this.onClickApprove}>
                                                                 ติดตั้งเสร็จแล้ว
                                                            </Button>
                                                       </Col>
                                                       <Col md={5}></Col>
                                                  </Row>
                                             </>
                                        ) : (
                                             <>
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเหตุ : ",
                                                                      detail: order?.ac_install_note || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ผู้อนุมัติ : ",
                                                                      detail: order?.user_acc_install_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "วันที่อนุมัติ : ",
                                                                      detail: order?.acc_install_date ? dateFormatter(order.acc_install_date) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Breadcrumbs under={true} type={"success"} title={"ติดตั้งอุปกรณ์เรียบร้อยแล้ว"} />
                                                       <div style={{ height: "80px" }}></div>
                                                  </div>
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
