import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Skeleton } from "components/index";
import { GET, POST } from "api/index";
import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";
import XlsExport from "xlsexport";
import swal from "sweetalert";
export default class AdminList extends Component {
     constructor(props) {
          super(props);
          this.state = {
               users: [],
               searchData: [],
               branch: [],
               team: [],
               position: [],
               fileTextHolder: "",
               loading: false,
               disabled: false,
          };
     }

     componentDidMount = () => {
          this.getData();
     };
     handleClick = () => {
          window.location.assign("/admin/user/add/user");
     };
     getData = async () => {
          try {
               let res = await GET("/aos/get_user");
               let getBranch = await GET("/ps/get_branchs");
               let getTeam = await GET("/ps/get_teams");
               let getPosition = await GET("/aos/get_position");
               res.sort((end, start) => parseFloat(start.user_id) - parseFloat(end.user_id));
               // console.log(res)
               this.setState({
                    users: res,
                    searchData: res,
                    branch: [...getBranch],
                    team: [...getTeam],
                    position: [...getPosition],
               });
          } catch (error) {
               console.log(error);
          }
     };
     editFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-pen-square"
                    onClick={() => window.location.assign(`/admin/user/edit/user/${row.user_id}`)}
               ></i>
          );
     }
     onSearch = async () => {
          this.setState({ loading: true });
          let { username, name, branch_name, team_name, status, searchData, position_name } = this.state;
          let check = username || name || branch_name || team_name || status || position_name ? true : false;
          // console.log(username, name, branch_name, team_name, status, searchData);
          let data = searchData.filter(
               (el) =>
                    (username ? String(el.username).toLowerCase().indexOf(username.toLowerCase()) > -1 : null) ||
                    (name ? String(el.name).toLowerCase().indexOf(name.toLowerCase()) > -1 : null) ||
                    (branch_name ? String(el.branch_name).toLowerCase().indexOf(branch_name.toLowerCase()) > -1 : null) ||
                    (team_name ? String(el.team_name).toLowerCase() === team_name.toLowerCase() : null) ||
                    (status ? String(el.status).toLowerCase() === status.toLowerCase() : null) ||
                    (position_name ? Number(el.position_id) === Number(position_name) : null)
          );
          this.setState({ users: check ? data : searchData, loading: false });
     };
     onChange = async (e) => {
          this.setState({ [e.target.name]: e.target.value });
          // console.log(e.target.name, e.target.value);
     };
     handleChange = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };

     handleUploadFile = async () => {
          const { file } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    formData.append("type", "user_master");
                    formData.append("file", file);

                    this.setState({ loading: true, disabled: true });
                    await POST("/aos/user_master", formData, true);
                    await this.getData();
                    this.setState({ loading: false, disabled: false, fileTextHolder: "", file: null });
               }
          } catch (error) {
               swal({
                    text: "กรุณาอัปโหลดให้ถูกประเภท",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false, disabled: false });
          }
     };

     downloadFile = async () => {
          try {
               window.open(await POST("/ps/download_excel", { download: "user" }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     downloadUser = () => {
          let { users } = this.state;
          let data = users.map((el, i) => {
               return {
                    ชื่อผู้ใช้งาน: el.username,
                    รหัสผ่าน: null,
                    รหัสพนักงาน: null,
                    ชื่อจริง: el.firstname,
                    นามสกุล: el.lastname,
                    ตำแหน่ง: el.position_name,
                    ทีม: el.team_name,
                    แผนก: null,
                    "สาขา/โชว์รูม": el.branch_name,
                    "E-Mail": null,
                    "ID Line": null,
                    หมายเลขโทรศัพท์: null,
                    ธนาคาร: null,
                    เลขบัญชีธนาคาร: null,
               };
          });
          if (data.length > 0) {
               const xls = new XlsExport(data, "ข้อมูลพนักงาน");
               xls.exportToXLS("ข้อมูลพนักงาน" + "(" + dayjs(new Date()).format("DD-MM-YYYY") + ")" + ".xls");
          }
     };
     render() {
          let { branch, team, branch_name, team_name, status, fileTextHolder, loading, disabled, position, position_name, users } = this.state;
          const column = [
               {
                    dataField: "edit",
                    text: "แก้ไข",
                    formatter: this.editFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "username",
                    text: "Username",
                    // headerClasses: "header-custom __left",
               },
               {
                    dataField: "name",
                    text: "ชื่อผู้ใช้งาน",
               },
               {
                    dataField: "branch_name",
                    text: "สาขา/โชว์รูม",
               },
               {
                    dataField: "position_name",
                    text: "ตำแหน่ง",
               },
               {
                    dataField: "team_name",
                    text: "ทีม",
               },
               {
                    dataField: "status",
                    text: "สถานะ",
                    headerClasses: "header-custom __right",
               },
               // {
               //      dataField: "edit",
               //      text: "แก้ไข",
               //      formatter: this.editFormatter,
               //      headerClasses: "header-custom __right __icon",
               // },
          ];
          let obj = {
               branch: branch.map((e) => ({
                    value: e.branch_name,
                    name: e.branch_name,
               })),
               team: team.map((e) => ({
                    value: e.team_name,
                    name: e.team_name,
               })),
               position: position.map((e) => ({
                    value: e.position_id,
                    name: e.position_name,
               })),
               value: {
                    branch_name,
                    team_name,
                    position_name,
                    status,
               },
          };
          return (
               // <div className="panel hide-scroll">
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "รายการผู้ใช้งาน" }]}
                         active={0}
                         button={[
                              {
                                   icon: "fas fa-user-plus -icon",
                                   name: "เพิ่มผู้ใช้งาน",
                                   handleClick: this.handleClick,
                                   variant: "primary",
                              },
                         ]}
                         onClick={() => this.props.history.goBack()}
                    />
                    <div className="content __main-panel">
                         <div className="search-bar">
                              <Search
                                   type={"admin"}
                                   arr={obj}
                                   onSearch={this.onSearch}
                                   handleChange={this.onChange}
                                   downloadUser={this.downloadUser}
                                   textDownload="ข้อมูล"
                              />
                              <Search
                                   type={"upload"}
                                   title={{
                                        name: "อัปโหลด  User Master",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __below-btn"}
                                   handleChange={this.handleChange}
                                   handleUploadFile={this.handleUploadFile}
                                   downloadFile={this.downloadFile}
                                   disabled={disabled}
                                   textDownload="แบบฟอร์ม"
                              />
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={column} />
                         ) : (
                              <Table type={"index-fixhead"} column={column} data={this.state.users} keyField={"username"} />
                         )}
                    </div>
               </div>
          );
     }
}
