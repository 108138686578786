import { POST, ip } from "api";
import { Breadcrumbs, Field, LabelBar, ModalPanel, Sign } from "components";
import InputPanel from "components/input-panel/input-panel";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Row, Button, Col, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class AcceptListLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               //    label_id: this.props.match.params.label_id,
               labelhis_id: this.props.match.params.labelhis_id,
               manage: this.props.match.params.manage,
               detail: {},
               order: {},
               title: "",
               spinner: false,
          };
     }

     componentDidMount = () => {
          this.getDetail();
     };

     getDetail = async () => {
          try {
               let orders = await POST("/car/this_label_his", { labelhis_id: this.state.labelhis_id });
               await this.setState({ detail: { ...orders } });

               let { order, manage } = this.state;
               order.label_id = orders.label_id;
               order.labelhis_id = orders.labelhis_id;
               if (manage === "give") {
                    order.status = "รับแล้ว";
                    this.setState({ title: "อนุมัติให้ป้ายแดง" });
               } else if (manage === "refund") {
                    order.status = "คืนป้ายแล้ว";
                    this.setState({ title: "คืนป้ายแดง" });
               } else {
                    this.setState({ title: "ข้อมูล" });
               }

               this.setState({ order });
          } catch (err) {
               console.log(err);
          }
     };

     /* -------------- onClick ---------------- */
     handleClickUpdateLabel = async (update) => {
          let { order, detail } = this.state;
          this.setState({ spinner: true });
          try {
               // console.log(`order`, order);
               // if (update === "return") {
               // order.label_return_date = dayjs(new Date()).format("YYYY-MM-DD")
               // }
               order.quality = order.quality === "ใช่" ? 0 : 1;
               await POST("/car/update_label_his", { ...order, branch_id: detail.branch_id });
               order.quality = order.quality === 0 ? "ใช่" : "ไม่";
               if (update === "lend") {
                    swal({
                         icon: "success",
                         text: "อนุมัติการยืมป้ายแดง",
                         buttons: {
                              // next: "พิมพ์ใบยืมป้ายแดง",
                              submit: "เสร็จสิ้น",
                         },
                    }).then((value) => {
                         // if (value === "submit") {
                         window.location.assign(`/admin/label/list/${detail.label_id}`);
                         // } else {
                         //      this.handleClickPrintSlip();
                         // }
                    });
                    this.setState({ spinner: false });
               } else {
                    swal({
                         icon: "success",
                         text: "คืนป้ายแดงเรียบร้อยแล้ว",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then((value) => {
                         window.location.assign(`/admin/label/list/${detail.label_id}`);
                    });
                    this.setState({ spinner: false });
               }
          } catch (err) {
               swal({
                    icon: "warning",
                    text: `ไม่สามารถทำรายการได้ ${err}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
               this.setState({ spinner: false });
          }
     };

     /* -------------- onChange ---------------- */

     handleChangeSign = async (sigCanvas) => {
          let { order } = this.state;
          let sign = await sigCanvas.toDataURL("image/png");
          order.sign = sign;
          this.setState({ order });
          // console.log("order : ", this.state.order);
     };
     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
          console.log("order : ", this.state.order);
     };

     handleClickPrintSlip = async () => {
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/car/borrow_label_pdf`,
                         { labelhis_id: this.state.labelhis_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         await window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                         await window.location.assign(`/admin/label/list/${this.state.detail.label_id}`);
                    });
          } catch (error) {}
     };

     render() {
          let { label_id, detail, order, spinner } = this.state;
          return (
               <div className="panel">
                    {spinner && (
                         <Spinner variant="primary" size="md" style={{ position: "fixed", top: "50%", left: "50%" }} animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                         </Spinner>
                    )}
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รายการป้ายแดง`,
                                   onClick: () => window.location.assign("/admin/label/list"),
                              },
                              {
                                   title: `ป้ายแดง (${detail?.label_no})`,
                                   onClick: () => window.location.assign(`/admin/label/list/${detail?.label_id}`),
                              },
                              { title: this.state.title },
                         ]}
                         active={2}
                         button={[]}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ยืมป้ายแดง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "โชว์รูม : ",
                                                            detail: detail?.branch_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ : ",
                                                            detail: detail.created_at ? dateFormatter(detail?.created_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบจอง : ",
                                                            detail: detail?.order_code_id || "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบยืม : ",
                                                            detail: detail?.checklabel_code || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: detail?.saler_name_string || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ขอยืมป้ายแดงให้แก่",
                                                            detail: "",
                                                       }}
                                                       textStyle={"margin"}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ลูกค้าชื่อ : ",
                                                            detail: detail?.labelhis_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: detail?.labelhis_idcard || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "โดยใช้ป้ายแดง",
                                                            detail: "",
                                                       }}
                                                       md={6}
                                                       textStyle={"margin"}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ : ",
                                                            detail: `${detail?.label_no || "-"} ${detail?.label_province || "-"}`,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ : ",
                                                            detail: detail?.label_book || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ยืม : ",
                                                            detail: detail.label_brrow_date ? dateFormatter(detail?.label_brrow_date) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "กำหนดคืน : ",
                                                            detail: detail.to_return_date ? dateFormatter(detail?.to_return_date) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             {this.state.manage === "detail" ? null : (
                                                  <>
                                                       <Row className="noMargin mb-3">
                                                            <span>
                                                                 และขอรับรองว่าจะคืนป้ายแดงภายใน 30 วันนับจากวันที่ยืม
                                                                 ถ้าหากเกินกำหนดจะยอมชำระค่าปรับตามที่กำหนดไว้
                                                            </span>
                                                       </Row>
                                                       <Row className="noMargin">
                                                            <span>ลายเซ็นผู้ยืม</span>
                                                       </Row>
                                                       <Sign save={this.handleChangeSign} type={"default-no-btn"} title={"ลายเซ็นผู้ยืม"} />
                                                  </>
                                             )}
                                        </div>
                                        {this.state.manage === "detail" ? null : (
                                             <>
                                                  <LabelBar type={"title"} title={"การมอบป้ายแดงให้แก่พนักงานขาย"} />
                                                  {this.state.manage === "give" ? (
                                                       <Row className="noMargin">
                                                            <Col xl={12}>
                                                                 <Field
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                      name={"note_borrow"}
                                                                      as="textarea"
                                                                      type="text"
                                                                      title={"หมายเหตุ"}
                                                                      rows={3}
                                                                 />
                                                            </Col>
                                                       </Row>
                                                  ) : (
                                                       <Row className="noMargin">
                                                            <Col xl={12}>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{ name: "หมายเหตุการอนุมัติ : ", detail: detail?.note_borrow }}
                                                                 />
                                                                 <LabelBar
                                                                      type={"radio"}
                                                                      title={"สภาพป้ายแดงสมบูรณ์"}
                                                                      name={"quality"}
                                                                      label={["ใช่", "ไม่"]}
                                                                      handleChangeText={(e) => {
                                                                           this.handleChangeText(e);
                                                                      }}
                                                                      value={order?.quality}
                                                                 />
                                                            </Col>

                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      value={detail?.order_code_id}
                                                                      type="text"
                                                                      title="เลขที่ใบจอง"
                                                                      placeholder="กรอกเลขที่ใบจอง"
                                                                 />
                                                            </Col>
                                                            <Col xl={12}>
                                                                 <Field
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                      name={"note_return"}
                                                                      as="textarea"
                                                                      type="text"
                                                                      title={"หมายเหตุ"}
                                                                      rows={3}
                                                                 />
                                                            </Col>
                                                       </Row>
                                                  )}

                                                  <div className="button-group __submit row">
                                                       <Col xs={6}>
                                                            {this.state.manage === "give" ? (
                                                                 <Button onClick={() => this.handleClickUpdateLabel("lend")} disabled={spinner}>
                                                                      อนุมัติการยืมป้ายแดง
                                                                 </Button>
                                                            ) : (
                                                                 <Button onClick={() => this.handleClickUpdateLabel("return")} disabled={spinner}>
                                                                      รับคืนป้ายแดง
                                                                 </Button>
                                                            )}
                                                       </Col>
                                                  </div>
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
