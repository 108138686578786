import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar } from "../../../../components/index";
import { Image, Form, Button, Col, Row } from "react-bootstrap";
import { GET, POST } from "../../../../api/api";
import swal from "sweetalert";

export default class supplier_add extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               previous_path: this.props.match.params.manage,
               title: "",
               supplierInfo: {
                    status: "ใช้งาน",
               },
               branch: [],
               team: [],
               position: [],
               workflow: [],
               provice: [],
               district: [],
               subDistrict: [],
               file: null,
               filepreview: {
                    name: "",
                    img: null,
               },
               id: this.props.match.params.id,
          };
     }

     componentDidMount = async () => {
          this.getProvice();
          this.setPath();
     };

     /* Set title from previous path */
     setPath = async () => {
          let { previous_path, title, filepreview, id } = this.state;
          // console.log("previous_path ", this.props.match.params);
          switch (previous_path) {
               case "add":
                    title = [
                         {
                              title: "Supplier",
                              onClick: () => window.location.assign("/admin/supplier/list"),
                         },
                         {
                              title: "เพิ่ม Supplier",
                              // onClick: () => this.handleNext(false),
                         },
                    ];
                    // title = this.setState({ title: title });
                    break;
               case "edit":
               case "status":
                    // title = ["Supplier", "ข้อมูล", "แก้ไข"];
                    title = [
                         {
                              title: "Supplier",
                              onClick: () => window.location.assign("/admin/supplier/list"),
                         },
                         {
                              title: "ข้อมูล",
                              onClick: () => window.location.assign(`/admin/supplier/detail${id}`),
                         },
                         {
                              title: "แก้ไข",
                              // onClick: () => this.handleNext(false),
                         },
                    ];
                    // title = this.setState({ title: title });
                    try {
                         let getsupplier = await POST("/ps/this_supplier", {
                              supplier_id: this.props.match.params.id,
                         });
                         filepreview.img = getsupplier.supplier_file;
                         this.setState({
                              supplierInfo: getsupplier,
                              filepreview,
                         });
                         // console.log("getsupplier", getsupplier);
                    } catch (error) {
                         console.log(error);
                    }
                    break;
               default:
                    break;
          }
          this.setState({ title: title });
     };

     getProvice = async () => {
          try {
               this.setState({ provice: [...(await GET("/address/getprovince"))] });
          } catch (error) {
               console.log(error);
          }
     };

     /* Validate field on submit */
     handleSubmit = async (event) => {
          let { supplierInfo, file } = this.state;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรอกให้ครบ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               event.preventDefault();
               event.stopPropagation();
          } else {
               event.preventDefault();
               try {
                    let formData = new FormData();
                    formData.append("file", file);
                    formData.append("supplier_code", supplierInfo.supplier_code || null);
                    formData.append("supplier_name", supplierInfo.supplier_name || null);
                    formData.append("supplier_type", supplierInfo.supplier_type || null);
                    formData.append("supplier_created", supplierInfo.supplier_created || null);
                    formData.append("supplier_address", supplierInfo.supplier_address || null);
                    formData.append("supplier_tel", supplierInfo.supplier_tel || null);
                    formData.append("supplier_person", supplierInfo.supplier_person || null);
                    formData.append("supplier_mail", supplierInfo.supplier_mail || null);
                    formData.append("supplier_line", supplierInfo.supplier_line || null);
                    formData.append("supplier_tax", supplierInfo.supplier_tax || null);
                    formData.append("supplier_accountname", supplierInfo.supplier_accountname || null);
                    formData.append("supplier_bank", supplierInfo.supplier_bank || null);
                    formData.append("supplier_bookbank", supplierInfo.supplier_bookbank || null);
                    formData.append("status", supplierInfo.status);
                    formData.append("insurance_premium", supplierInfo.insurance_premium || null);
                    formData.append("name_reserve", supplierInfo.name_reserve || null);

                    await POST("/ps/add_supplier", formData, true);
                    swal({
                         text: "เพิ่ม Supplier เรียบร้อยแล้ว รอการอนุมัติ",
                         icon: "success",
                         button: "เสร็จสิ้น",
                    }).then(() => this.props.history.push("/admin/supplier/list"));
               } catch (error) {
                    swal({
                         title: "failed",
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }

          this.setState({
               validated: true,
          });
     };

     /* Handle Data input change */
     handleChangeNumber = (value, name) => {
          let { supplierInfo } = this.state;
          supplierInfo[name] = value.floatValue;
          this.setState({ supplierInfo });
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { supplierInfo } = this.state;
          supplierInfo[name] = value;
          this.setState({ supplierInfo });
     };

     /* Handle Data input change */
     handleChangeTel = (value) => {
          let { supplierInfo } = this.state;
          supplierInfo.supplier_tel = value.value;
          this.setState({ supplierInfo });
     };

     removeBtnFormatter(cell, row, rowIndex, formatExtraData) {
          return <Button variant="danger">Remove</Button>;
     }

     handleReset = () => {
          let { supplierInfo } = this.setState;
          supplierInfo = {
               username: "",
               password: "",
               firstname: "",
               lastname: "",
               email: "",
               position_id: "",
               team_id: "",
               department_id: "",
               branch: "",
               employee_no: "",
               status: "ใช้งาน",
          };
          this.setState({ supplierInfo });
     };

     handleChangeAddress = ({ target: { name, value } }) => {
          let { supplierInfo, provice, district, subDistrict } = this.state;
          if (name === "supplier_province") {
               supplierInfo[name] = provice.find((e) => e.id === Number(value)).name_th;
               supplierInfo.supplier_district = "";
               supplierInfo.supplier_sub_district = "";
               supplierInfo.supplier_zipcode = "";
               this.getDistrict(value);
          } else if (name === "supplier_district") {
               supplierInfo[name] = district.find((e) => e.id === Number(value)).name_th;
               supplierInfo.supplier_sub_district = "";
               supplierInfo.supplier_zipcode = "";
               this.getSubdistrict(value);
          } else {
               supplierInfo[name] = subDistrict.find((e) => e.id === value).name_th;
               supplierInfo.supplier_zipcode = subDistrict.find((e) => e.id === value).zip_code;
          }
          this.setState({ supplierInfo });
     };

     getDistrict = async (province_id) => {
          try {
               this.setState({
                    district: [...(await POST("/address/getdistrict", { province_id }))],
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getSubdistrict = async (amphure_id) => {
          try {
               this.setState({
                    subDistrict: [...(await POST("/address/getsubdistrict", { amphure_id }))],
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     handleChangeFile = (event) => {
          let { filepreview, file } = this.state;
          file = event.target.files[0];

          filepreview.name = file.name;
          filepreview.img = URL.createObjectURL(event.target.files[0]);

          this.setState({
               file,
               filepreview,
          });
     };

     handleSelectDate = (date, dateString) => {
          let { supplierInfo } = this.state;
          supplierInfo.supplier_created = dateString;
          this.setState({ supplierInfo });
     };

     handleReset = () => {
          let { supplierInfo } = this.state;
          supplierInfo.supplier_code = "";
          supplierInfo.supplier_name = "";
          supplierInfo.supplier_type = "";
          supplierInfo.supplier_created = "";
          supplierInfo.supplier_email = "";
          supplierInfo.supplier_address = "";
          supplierInfo.supplier_sub_district = "";
          supplierInfo.supplier_district = "";
          supplierInfo.supplier_province = "";
          supplierInfo.supplier_zipcode = "";
          supplierInfo.status = "ใช้งาน";

          this.setState({ supplierInfo });
     };

     handleEdit = async (event) => {
          event.preventDefault();
          try {
               let { supplierInfo, file } = this.state;

               let formData = new FormData();
               if (file) {
                    formData.append("file", file);
               }

               for (const [key, value] of Object.entries(supplierInfo)) {
                    formData.append(key, value);
               }
               await POST("/ps/edit_supplier", formData, true);

               swal({
                    text: "อนุมัติเรียบร้อบแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => this.props.history.push("/admin/supplier/list"));
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     handleDelete = async () => {
          try {
               let { supplierInfo } = this.state;

               await POST("/ps/edit_supplier", {
                    status: "ไม่ได้ใช้งาน",
                    supplier_id: supplierInfo.supplier_id,
               });
               swal({
                    title: "success",
                    text: "ทำรายการสำเร็จ",
                    icon: "success",
               });
          } catch (error) {
               console.log(error);
          }
     };

     render() {
          let { validated, title, supplierInfo, provice, district, subDistrict, previous_path, filepreview } = this.state;
          const supplier_type = [
               // { value: "TMT", name: "TMT" },
               { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง (TOYOTA)", name: "อุปกรณ์ตกแต่ง (TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง (Out Source Supplier)", name: "อุปกรณ์ตกแต่ง (Out Source Supplier)" },
               { value: "ประกัน", name: "ประกัน" },
               { value: "Leasing", name: "Leasing" },
               { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
               // { value: "อื่น ๆ", name: "อื่น ๆ" },
          ];
          // console.log(`supplierInfo`, supplierInfo);
          return (
               <div className="panel">
                    {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} onClick={() => this.props.history.goBack()} /> : null}
                    <Form noValidate validated={validated} onSubmit={previous_path === "add" ? this.handleSubmit : this.handleEdit}>
                         <div className="content __input-panel">
                              {/* ข้อมูล Supplier */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ข้อมูล Supplier</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"select"}
                                                            title={"Supplier Type"}
                                                            name={"supplier_type"}
                                                            placeholder={"เลือกประเภท Supplier"}
                                                            option={supplier_type}
                                                            value={supplierInfo?.supplier_type}
                                                            require={true}
                                                            disabled={previous_path === "edit" && true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Supplier Code"}
                                                            name={"supplier_code"}
                                                            placeholder={"กรอก Supplier Code"}
                                                            require={true}
                                                            value={supplierInfo?.supplier_code}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                                  {/* ///////////////////////////////// */}

                                                  {supplierInfo?.supplier_type &&
                                                       (supplierInfo?.supplier_type === "อุปกรณ์ตกแต่ง (TOYOTA)" ||
                                                            supplierInfo?.supplier_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)" ||
                                                            supplierInfo?.supplier_type === "Dealer ซื้อรถ") && (
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Supplier Name"}
                                                                      name={"supplier_name"}
                                                                      placeholder={"กรอก Supplier Name"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_name}
                                                                      // md={12}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ชื่อผู้ติดต่อ"}
                                                                      name={"supplier_person"}
                                                                      placeholder={"กรุณากรอกชื่อผู้ติดต่อ"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_person}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"หมายเลขโทรศัพท์"}
                                                                      name={"supplier_tel"}
                                                                      placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_tel}
                                                                      md={6}
                                                                      typeInput={"tel"}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Email"}
                                                                      name={"supplier_mail"}
                                                                      placeholder={"กรุณากรอก Email"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_mail}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ID LINE"}
                                                                      name={"supplier_line"}
                                                                      placeholder={"กรุณากรอก ID LINE"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_line}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ชื่อบัญชี"}
                                                                      name={"supplier_accountname"}
                                                                      placeholder={"กรุณากรรอกชื่อบัญชี"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_accountname}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ธนาคาร"}
                                                                      name={"supplier_bank"}
                                                                      placeholder={"กรุณากรอกธนาคาร"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_bank}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขบัญชีธนาคาร"}
                                                                      name={"supplier_bookbank"}
                                                                      placeholder={"กรุณากรอกเลขบัญชีธนาคาร"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_bookbank}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ที่อยู่ Supplier"}
                                                                      name={"supplier_address"}
                                                                      placeholder={"กรุณากรอกที่อยู่"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_address}
                                                                      md={12}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       )}
                                                  {supplierInfo?.supplier_type &&
                                                       supplierInfo?.supplier_type !== "อุปกรณ์ตกแต่ง (TOYOTA)" &&
                                                       supplierInfo?.supplier_type !== "อุปกรณ์ตกแต่ง (Out Source Supplier)" &&
                                                       supplierInfo?.supplier_type !== "Dealer ซื้อรถ" && (
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Supplier  Name"}
                                                                      name={"supplier_name"}
                                                                      placeholder={"กรอก Supplier Name"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_name}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 {supplierInfo?.supplier_type !== "ตัวแทนจดทะเบียน" && (
                                                                      <Field
                                                                           type={"text"}
                                                                           title={"Supplier Name (สคบ.)"}
                                                                           name={"name_reserve"}
                                                                           placeholder={"กรอก Supplier Name (สคบ.)"}
                                                                           value={supplierInfo?.name_reserve}
                                                                           warning_title={`*กำหนดการกรอก ${
                                                                                supplierInfo?.supplier_type === "ประกัน" ? 16 : 18
                                                                           } ตัวอักษร`}
                                                                           md={6}
                                                                           handleChangeText={this.handleChangeText}
                                                                      />
                                                                 )}

                                                                 {supplierInfo?.supplier_type === "ตัวแทนจดทะเบียน" && (
                                                                      <>
                                                                           <Field
                                                                                type={"text"}
                                                                                title={"ชื่อบัญชี"}
                                                                                name={"supplier_accountname"}
                                                                                placeholder={"กรุณากรรอกชื่อบัญชี"}
                                                                                require={true}
                                                                                value={supplierInfo?.supplier_accountname}
                                                                                md={6}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           <Field
                                                                                type={"text"}
                                                                                title={"ธนาคาร"}
                                                                                name={"supplier_bank"}
                                                                                placeholder={"กรุณากรอกธนาคาร"}
                                                                                require={true}
                                                                                value={supplierInfo?.supplier_bank}
                                                                                md={6}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           <Field
                                                                                type={"text"}
                                                                                title={"เลขบัญชีธนาคาร"}
                                                                                name={"supplier_bookbank"}
                                                                                placeholder={"กรุณากรอกเลขบัญชีธนาคาร"}
                                                                                require={true}
                                                                                value={supplierInfo?.supplier_bookbank}
                                                                                md={6}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </>
                                                                 )}
                                                                 {supplierInfo?.supplier_type === "ประกัน" && (
                                                                      <>
                                                                           <Field
                                                                                type={"number"}
                                                                                title={"เบี้ยประกัน"}
                                                                                name={`insurance_premium`}
                                                                                value={supplierInfo?.insurance_premium}
                                                                                md={6}
                                                                                require={true}
                                                                                placeholder={"0.00"}
                                                                                decimalScale={2}
                                                                                handleChangeNumber={(e) =>
                                                                                     this.handleChangeNumber(e, "insurance_premium")
                                                                                }
                                                                           />
                                                                           <Field
                                                                                type={"text"}
                                                                                title={"เลขประจำตัวผู้เสียภาษี"}
                                                                                name={"supplier_tax"}
                                                                                placeholder={"กรุณากรอกเลขประจำตัวผู้เสียภาษี"}
                                                                                require={true}
                                                                                value={supplierInfo?.supplier_tax}
                                                                                md={6}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </>
                                                                 )}
                                                                 {supplierInfo?.supplier_type === "Leasing" && (
                                                                      <Field
                                                                           type={"text"}
                                                                           title={"เลขประจำตัวผู้เสียภาษี"}
                                                                           name={"supplier_tax"}
                                                                           placeholder={"กรุณากรอกเลขประจำตัวผู้เสียภาษี"}
                                                                           require={true}
                                                                           value={supplierInfo?.supplier_tax}
                                                                           md={6}
                                                                           handleChangeText={this.handleChangeText}
                                                                      />
                                                                 )}
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ที่อยู่ Supplier"}
                                                                      name={"supplier_address"}
                                                                      placeholder={"กรุณากรอกที่อยู่"}
                                                                      require={true}
                                                                      value={supplierInfo?.supplier_address}
                                                                      md={12}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </Form.Row>
                                                       )}

                                                  {/* { 
                       !(supplierInfo?.supplier_type === "ประกัน" || supplierInfo?.supplier_type === "อุปกรณ์ตกแต่ง TOYOTA" || supplierInfo?.supplier_type === "อุปกรณ์ตกแต่ง Out Source Supplier") && (
                            <Form.Row>
                              <Field
                                type={"text"}
                                title={"Supplier  Name"}
                                name={"supplier_name"}
                                placeholder={"กรอก Supplier Name"}
                                require={true}
                                value={supplierInfo?.supplier_name}
                                md={6}
                                handleChangeText={this.handleChangeText}
                              />
                              <Field
                                type={"number"}
                                title={"หมายเลขโทรศัพท์"}
                                name={"supplier_tel"}
                                placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                require={true}
                                value={supplierInfo?.supplier_tel}
                                md={6}
                                format="###-###-####"
                                mask="X"
                                typeInput={"tel"}
                                handleChangeNumber={this.handleChangeTel}
                              />          
                               <Field
                                type={"text"}
                                title={"Email"}
                                name={"supplier_mail"}
                                placeholder={"กรุณากรอก Email"}
                                require={true}
                                value={supplierInfo?.supplier_email}
                                md={6}
                                handleChangeNumber={this.handleChangeText}
                              />               
                               <Field
                                  type={"text"}
                                  title={"ที่อยู่ Supplier"}
                                  name={"supplier_address"}
                                  placeholder={"กรุณากรอกที่อยู่"}
                                  require={true}
                                  value={supplierInfo?.supplier_address}
                                  md={12}
                                  handleChangeText={this.handleChangeText}
                                />
                            </Form.Row>

                      )
                    } */}
                                                  {/* /////////////////////////////////// */}

                                                  {/* <Field
                        type={"text"}
                        title={"Supplier  Name"}
                        name={"supplier_name"}
                        placeholder={"กรอก Supplier Name"}
                        require={true}
                        value={supplierInfo?.supplier_name}
                        // md={12}
                        handleChangeText={this.handleChangeText}
                      />
                      <Field
                        type={"number"}
                        title={"หมายเลขโทรศัพท์"}
                        name={"supplier_tel"}
                        placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                        require={true}
                        value={supplierInfo?.supplier_tel}
                        md={6}
                        format="###-###-####"
                        mask="X"
                        typeInput={"tel"}
                        handleChangeNumber={this.handleChangeTel}
                      />
                      <Col md={6}>
                      <Field
                        type={"text"}
                        title={"Email"}
                        name={"supplier_mail"}
                        placeholder={"กรุณากรอก Email"}
                        require={true}
                        value={supplierInfo?.supplier_email}
                        // md={6}
                        handleChangeNumber={this.handleChangeText}
                      />
                      </Col>
                      <Field
                        type={"text"}
                        title={"ที่อยู่ Supplier"}
                        name={"supplier_address"}
                        placeholder={"กรุณากรอกที่อยู่"}
                        require={true}
                        value={supplierInfo?.supplier_address}
                        md={12}
                        handleChangeText={this.handleChangeText}
                      />
                      
                      <LabelBar
                        type={"radio"}
                        name={"status"}
                        title={"สถานะ : "}
                        label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                        value={supplierInfo?.status}
                        handleChangeText={this.handleChangeText}
                      /> */}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* Payment Term */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>Payment Term</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"file"}
                                                            title={{
                                                                 name: "File Book Bank",
                                                                 icon: null,
                                                            }}
                                                            require={false}
                                                            md={6}
                                                            placeholder={filepreview.name === "" ? "File Book Bank" : filepreview.name}
                                                            button={"แนบไฟล์"}
                                                            style_id={"custom-btn __beside-btn"}
                                                            handleChange={this.handleChangeFile}
                                                       />
                                                  </Form.Row>
                                                  {filepreview.img && <Image className="preview" src={filepreview.img} />}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* Button */}
                              <div className="input-field">
                                   <div className="button-group __submit row">
                                        {previous_path === "add" && (
                                             <>
                                                  <Col xs={12} md={6}>
                                                       <Button type="submit">Submit</Button>
                                                  </Col>
                                                  <Col xs={12} md={6}>
                                                       <Button variant="danger" onClick={this.handleReset}>
                                                            Reset
                                                       </Button>
                                                  </Col>
                                             </>
                                        )}
                                        {previous_path === "edit" && (
                                             <>
                                                  <Col xs={12} md={6}>
                                                       <Button type="submit">บันทึกการแก้ไข</Button>
                                                  </Col>
                                                  <Col xs={12} md={6}>
                                                       <Button variant="danger" onClick={this.handleDelete}>
                                                            ลบข้อมูล
                                                       </Button>
                                                  </Col>
                                             </>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </Form>
               </div>
          );
     }
}
