import { GET, POST } from "api";
import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import dayjs from "dayjs";

export default class RegistrationReport extends Component {
     constructor(props) {
          super(props);

          this.state = {
               title: "",
               show: false,
               registration: { branch: [] },
               error_data: "",
          };
     }

     componentDidMount = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");

               this.setState({ branch: getBranch, registration: { branch: getBranch.map((el) => el.branch_id) } });
          } catch (error) {
               console.log("error", error);
          }
     };

     /*------------ onClick ------------------ */

     showModal = (title, id) => {
          let { registration } = this.state;
          registration.id = id;
          registration.type_excel = title;
          this.setState({
               title: title,
               show: !this.state.show,
               registration,
          });
     };

     closeReport = async () => {
          let { registration } = this.state;
          try {
               if (!registration.branch.length) {
                    this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
               } else {
                    if (registration.id < 4) {
                         let dataBranch = registration.branch.map((el) => ({
                              branch_name: this.state.branch.find((e) => e.branch_id == el).branch_name,
                              branch_id: this.state.branch.find((e) => e.branch_id == el).branch_id,
                         }));
                         let res = await POST("/ps/report_excel_register", { ...registration, branch: dataBranch });
                         window.open(res);
                         this.setState({ show: !this.state.show, error_data: "" });
                    } else if (!registration.date_start || !registration.date_end) {
                         this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
                    } else {
                         let dataBranch = registration.branch.map((el) => ({
                              branch_name: this.state.branch.find((e) => e.branch_id == el).branch_name,
                              branch_id: this.state.branch.find((e) => e.branch_id == el).branch_id,
                         }));
                         let res = await POST("/ps/report_excel_register", { ...registration, branch: dataBranch });
                         window.open(res);
                         this.setState({ show: !this.state.show, error_data: "" });
                    }
               }
          } catch (error) {
               // console.log("error", error);
               this.setState({ error_data: error });
          }
     };

     /* ---------- formatter ----------------- */

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.showModal(row.report, row.id)}>ทำรายการ</a>;
     };

     handleChangeText = ({ target: { name, value, checked } }) => {
          let { registration } = this.state;
          if (name === "branch") {
               // console.log(name + " " + value, checked);
               if (checked) {
                    registration[name].push(Number(value));
                    registration[name] = registration[name].sort((a, z) => a - z);
                    this.setState({ registration });
               } else {
                    let index = registration[name].findIndex((el) => el === Number(value));
                    registration[name].splice(index, 1);
                    registration[name] = registration[name].sort((a, z) => a - z);
                    this.setState({ registration });
               }
          } else {
               // let branch_id = branch.find((ele) => ele.branch_name === value).branch_id;
               registration[name] = value;
               // registration.branch_id = branch_id;
               this.setState({ registration });
          }
     };
     handleChangeDate = (date, dateString, name) => {
          let { registration } = this.state;
          registration[name] = dayjs(dateString).format("YYYY-MM-DD");
          this.setState({ registration });
     };
     handleCloseModal = () => {
          let { registration } = this.state;
          this.setState({
               show: false,
               // registration: { ...registration, branch: [] },
               error_data: "",
          });
     };

     // reportFormatter = (cell, row, rowIndex, formatExtraData) => {
     //      if (row.report === "รายงานใบเสร็จรับเงิน") {
     //           return (
     //                <div>
     //                     <span>{cell}</span>
     //                     <span style={{ color: "red", marginLeft: "40px" }}>ไม่มีรูปแบบรายงาน</span>
     //                </div>
     //           );
     //      } else {
     //           return <span>{cell}</span>;
     //      }
     // };

     render() {
          let { title, show, registration, error_data } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         formatter: this.reportFormatter,
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "list",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    { report: "รายงานการจดทะเบียนที่รอลูกค้ามารับป้ายทะเบียน", id: 1 },
                    { report: "รายงานป้ายแดงที่จดทะเบียนเสร็จแล้วยังไม่ได้คืน", id: 2 },
                    { report: "รายงานยังไม่ได้จดทะเบียน", id: 3 },
                    { report: "รายงานการจ่ายเงินตัวแทนจดทะเบียน", id: 4 },
                    { report: "รายงานรับเงินค่าต่อเติม", id: 5 },
                    { report: "รายงานการจับคู่ ค่าป้ายแดง(RP) - ลดหนี้ค่าป้ายแดง(CP)", id: 6 },
               ],
               keyField: "on",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานการจดทะเบียน" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                                   <Modal show={show} centered size="lg" onHide={this.handleCloseModal}>
                                        <Modal.Header>
                                             <Modal.Title className="text-center">
                                                  <strong>{title}</strong>
                                                  {error_data && (
                                                       <p className="text-center">
                                                            <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                                       </p>
                                                  )}
                                             </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                             {registration.id > 3 && (
                                                  <Row>
                                                       <Field
                                                            type={"date"}
                                                            placeholder={
                                                                 registration.date_start
                                                                      ? dayjs(registration.date_start).format("YYYY-MM-DD")
                                                                      : "เลือกวันที่"
                                                            }
                                                            title={"ประจำวันที่"}
                                                            name={"date_start"}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            placeholder={
                                                                 registration.date_end
                                                                      ? dayjs(registration.date_end).format("YYYY-MM-DD")
                                                                      : "เลือกวันที่"
                                                            }
                                                            title={"ถึงวันที่"}
                                                            name={"date_end"}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                  </Row>
                                             )}

                                             <LabelBar
                                                  type={"checkbox"}
                                                  title={"สาขา"}
                                                  name={"branch"}
                                                  md={12}
                                                  label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                  // value={pay?.branch}
                                                  arrChecked={registration?.branch}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       <Button onClick={this.closeReport}>แสดงรายงาน</Button>
                                                  </Col>
                                             </Row>
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
