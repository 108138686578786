import React, { Component } from "react";
import { POST } from "../../../api/api";
import { Loader } from "components/index";
import CommissionTeam from "../manage/commission/commissionTeam";
import dayjs from "dayjs";
import { Form, Col, Button } from "react-bootstrap";

export class pscommission extends Component {
     constructor(...props) {
          super(...props);

          this.state = {
               loading: true,
               lastData: [],
               setDataOld: [],
          };
     }
     componentDidMount = () => {
          this.getData();
     };
     getData = async () => {
          try {
               let { id } = this.props.match.params;
               // console.log("id", JSON.parse(id));
               let res = await POST("/aos/download_commission_sum", { ...JSON.parse(id), pscom: true });
               // console.log("res", res);

               this.setState({ ...res, loading: false });
          } catch (error) {
               this.setState({ loading: false });
          }
     };
     handleExportExcel = async () => {
          // console.log("this.comTeam", this.comTeam.returnState());
          try {
               this.setState({ loading: true });
               let { id } = this.props.match.params;
               // console.log("id", JSON.parse(id));
               let res = await POST("/aos/download_commission_excel", { ...JSON.parse(id), ...this.comTeam.returnState() });
               window.open(res);
               this.setState({ loading: false });
          } catch (error) {
               this.setState({ loading: false });
          }
     };
     render() {
          let props = JSON.parse(this.props.match.params.id);
          if (this.state.loading) {
               return <Loader />;
          } else {
               return (
                    <div style={{ background: "#f5f5f5", paddingY: 28 }}>
                         <div className="content __input-panel pt-3">
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content" style={{ whiteSpace: "break-spaces" }}>
                                             <Form.Group id="title" as={Col}>
                                                  <Form.Label>
                                                       <strong>{`ค่าคอมมิชชั่นเดือน : ${
                                                            props.date_start ? dayjs(props.date_start).add(543, "y").format("MM-YY") : "-"
                                                       }\nสาขา : ${props.branch_name}\nทีมขาย : ${props.team_name}`}</strong>
                                                       <div>
                                                            <Button
                                                                 style={{ marginTop: 15, background: "#217c36", borderColor: "#217c36" }}
                                                                 onClick={this.handleExportExcel}
                                                            >
                                                                 ดาวน์โหลด EXCEL
                                                            </Button>
                                                       </div>
                                                  </Form.Label>
                                             </Form.Group>
                                             <CommissionTeam onRef={(ref) => (this.comTeam = ref)} state={this.state} params={props} />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               );
          }
     }
}

export default pscommission;
