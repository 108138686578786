import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img } from "components/index";
import { GET, POST } from "api/api";
import swal from "sweetalert";
import { Skeleton } from "antd";

export default class Leasing extends Component {
     constructor(props) {
          super(props);
          this.state = {
               leasing: [],
               leasing_backup: [],
               search: [],
               file: {},
               fileTextHolder: "",
               //  title: [],
               loading: false,
               searchSeries: []
          };
     }

     componentDidMount = () => {
          this.getLeasing();
     };

     getLeasing = async () => {
          try {
               let { search } = this.state
               search["series_name"] = "ทั้งหมด"
               let result = await GET("/ps/get_leasing");
               result
                    .sort((end, start) => parseFloat(start.leasinge_id) - parseFloat(end.leasinge_id))
                    .sort((end, start) => parseFloat(start.leasinge_id) - parseFloat(end.leasinge_id));
               // this.setState({ leasing: [...(await GET("/ps/get_leasing"))] });

               let searchSeries = result
                    .filter((el, i) => result.map((ele) => ele.series_name).indexOf(el.series_name) === i)
                    .map((el) => ({ value: el.series_name, name: el.series_name }));
               searchSeries.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });

               this.setState({ leasing: result, leasing_backup: result, searchSeries: searchSeries, ...search });
               // console.log(this.state.leasing)
          } catch (error) {
               console.log(error);
          }
     };

     handleClick = () => {
          window.location.assign("/admin/leasing/detail/add");
     };

     /* Handle File upload */
     handleChange = (event) => {
          const fileUploaded = event.target.files[0];
          this.setState({
               file: fileUploaded,
               fileTextHolder: fileUploaded.name,
          });
     };

     handleUploadFile = async () => {
          let { file } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    formData.append("type", "leasing_master");
                    formData.append("file", file);
                    this.setState({ loading: true });
                    await POST("/aos/leasing_master", formData, true);
                    await this.getLeasing();
                    this.setState({ loading: false, fileTextHolder: "", file: null });
               }
          } catch (error) {
               swal({
                    text: "กรุณาอัปโหลดให้ถูกประเภท",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false });
          }
     };

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-file-alt"
                    onClick={() => window.location.assign(`/admin/leasing/detail${row.leasinge_id}`)}
               ></i>
          );
     }

     onSearch = () => {
          let { leasing_backup, search } = this.state;
          // console.log("item_backup", item_backup)
          // console.log(search);
          let leasing = leasing_backup.filter(
               (e) =>
                    (search.leasing_type ? String(e.leasing_type).toLowerCase().includes(String(search.leasing_type).toLowerCase()) : e) &&
                    (search.series_name === "อื่น ๆ"
                         ? !this.linearSearch(e.series_name)
                              ? e
                              : null
                         : search.series_name
                              ? String(e.series_name).toLowerCase() === String(search.series_name).toLowerCase() ||
                              String(search.series_name).toLowerCase() === String("ทั้งหมด")
                              : e) &&
                    (search.status
                         ? String(e.status).toLowerCase() === String(search.status).toLowerCase() ||
                         String(search.status).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ leasing });
          // console.log("item", item)
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };

     //   setPath = async () => {
     //     let { title } = this.state;

     //     title = [
     //       {
     //         title: "Leasing",
     //         //     onClick: () => window.location.assign("/admin/leasing"),
     //       },
     //     ];

     //     title = this.setState({ title: title });
     //   };

     downloadFile = async () => {
          try {
               let download = "leasing_type";
               // console.log(`download`, download)
               window.open(await POST("/ps/download_excel", { download }));
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     render() {
          let { leasing, fileTextHolder, search, loading, searchSeries } = this.state;
          const column = [
               {
                    dataField: "detail",
                    text: "ข้อมูล",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "leasing_type",
                    text: "ประเภท Leasing",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "amount_year",
                    text: "จำนวนปีผ่อนชำระ",
                    formatter: (e) => (e ? e + " ปี" : "-"),
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "supplier_name",
                    text: "บริษัท Leasing",
                    formatter: (e) => (e ? e : "-"),
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "series_name",
                    text: "Series",
                    formatter: (e) => (e ? e : "-"),
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "status",
                    text: "สถานะ",
                    headerClasses: "header-custom __right __btn-ss",
               },
          ];
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "Leasing" }]}
                         active={0}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "เพิ่ม  Leasing",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __main-panel listleasing">
                         <div className="search-bar">
                              <Search
                                   type={"leasing"}
                                   searchOptions={{ ...search, searchSeries }}
                                   onSearch={this.onSearch}
                                   handleChangeText={this.handleChangeText}
                                   handleChange={this.handleChangeOption}
                              />
                              <Search
                                   type={"upload"}
                                   title={{
                                        name: "อัปโหลด Leasing Master ",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __below-btn"}
                                   disabled={loading}
                                   handleUploadFile={this.handleUploadFile}
                                   handleChange={this.handleChange}
                                   downloadFile={this.downloadFile}
                              />
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={column} />
                         ) : (
                              <Table type={"index-fixhead"} column={column} data={leasing} keyField={"company"} />
                         )}
                    </div>
               </div>
          );
     }
}
