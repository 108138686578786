import { POST } from "api";
import { Breadcrumbs, Field, LabelBar, ModalPanel, Table } from "components";
import InputPanel from "components/input-panel/input-panel";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Row, Button, Col } from "react-bootstrap";
import swal from "sweetalert";
import { getToken, add } from "../../../../../static/function";

export default class GenerateMatchList extends Component {
     constructor(props) {
          super(props);
          this.state = {
               car_id: this.props.match.params.car_id,
               order_id: this.props.match.params.order_id,
               show_confirm: false,
               match_status: false,
               list_order: {},
               order: {},
               showModalCancel: false,
          };
     }
     componentDidMount = () => {
          this.getListOrder();
          this.getOrder();
     };

     getOrder = async () => {
          try {
               let result = await POST("/order/this", { order_id: this.state.order_id });
               this.setState({ order: { ...result } });
          } catch (err) {
               console.log(err);
          }
     };
     getListOrder = async () => {
          let { car_id, order_id } = this.state;
          try {
               let order = await POST("/car/this_matched", { car_id: car_id, order_id: order_id });
               this.setState({ list_order: { ...order } });
          } catch (err) {
               console.log(err);
          }
     };

     // onClickCancelMatch = async () => {
     //      let { car_id, order_id, color_id } = this.state;
     //      try {
     //           let result = await POST("/car/cancel_match", { car_id: car_id, order_id: order_id });

     //           if (result === "cancel success") {
     //                swal({
     //                     icon: "success",
     //                     text: "ยกเลิกจับคู่รถเรียบร้อย",
     //                     buttons: {
     //                          submit: "เสร็จสิ้น",
     //                     },
     //                }).then((value) => {
     //                     window.location.assign("/admin/match/list");
     //                });
     //           }
     //      } catch (err) {
     //           swal({
     //                icon: "warning",
     //                text: `ไม่สามารถทำรายการได้ ${err}`,
     //                buttons: {
     //                     danger: "ยกเลิก",
     //                },
     //           });
     //      }
     // };

     onClickCancelModal = () => {
          this.setState({ showModalCancel: !this.state.showModalCancel });
     };

     cancleAcceptMatch = async () => {
          try {
               this.onClickCancelModal();
               // await POST("/car/cancel_accept_match", { order_id: this.state.order_id, car_id: this.state.car_id });
               await POST("/car/cancel_match", { order_id: this.state.order_id, car_id: this.state.car_id });
               swal({
                    icon: "success",
                    text: "ยกเลิกการยืนยันจับคู่รถเรียบร้อย",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    window.location.assign("/admin/match/list");
               });
          } catch (error) {
               console.log("error", error);
          }
     };

     /* ------------------------ Formatter ---------------------------- */
     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "operator":
                    return <a>{cell}</a>;
          }
     };
     detailFormatterBooking(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     }
     parseFloatFormatter = (cell, row) => {
          let new_cell = parseFloat(cell ? cell : 0).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     };

     noteFormatter = (cell, row) => {
          if (cell) {
               let acc = JSON.parse(cell);
               // let acc_filter = acc.filter((el, i) => acc.map((ele) => ele.items_name).indexOf(el.items_name) === i);
               let acc_filter = acc;
               return (
                    <div>
                         {/* <strong style={{ color: "#000" }}>รายการอุปกรณ์ที่เคยติดตั้ง</strong> */}
                         {/* <br></br> */}
                         {acc_filter?.map((el, i) => (
                              <>
                                   {i + 1}.{` ${el.items_name}`}
                                   <br></br>
                              </>
                         ))}
                         {/* - อุปกรณ์ 1<br></br>- อุปกรณ์ 2<br></br>- อุปกรณ์ 3<br></br>- อุปกรณ์ 4<br></br> */}
                    </div>
               );
          } else {
               return <span>-</span>;
          }
     };

     render() {
          let { list_order, order_id, order } = this.state;
          const table_list = {
               column: [
                    {
                         dataField: "series_name",
                         text: "Serie",
                         headerClasses: "header-custom __left __btn-s",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         headerClasses: "header-custom __5em",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "car_engine",
                         text: "เลขเครื่องยนต์",
                         headerClasses: "header-custom __btn-s",
                    },

                    {
                         dataField: "car_fun",
                         text: "ราคาทุน",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "car_price",
                         text: "ราคาขาย",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    // {
                    //      dataField: 'car_vat',
                    //      text: 'ราคาขาย (รวม Vat)',
                    //      headerClasses: 'header-custom __right __btn-m',
                    //      formatter: this.parseFloatFormatter,
                    // },
                    {
                         dataField: "acc_installed",
                         text: "รายการอุปกรณ์ที่ติดตั้งแล้ว",
                         headerClasses: "header-custom __btn-match-car",
                         formatter: this.noteFormatter,
                    },
                    {
                         dataField: "car_note",
                         text: "หมายเหตุ",
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? e : "-"),
                    },
               ],
               data: list_order.car ? [list_order.car] : [],
               keyField: "car_id",
          };

          const table_preemption_list = {
               column: [
                    {
                         dataField: "payment_date",
                         text: "วันเวลาที่ชำระค่าจอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __left __10em",
                    },
                    {
                         dataField: "getcar_date",
                         text: "วันรับรถ",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.detailFormatterBooking,
                         formatExtraData: "operator",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "user_match_name",
                         text: "ผู้ดำเนินการ",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อพนักงานขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __9em",
                    },
                    {
                         dataField: "discount_price",
                         text: "ส่วนลด (บาท)",
                         formatter: this.parseFloatFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "note",
                         text: "หมายเหตุ",
                         headerClasses: "header-custom __right __btn-m",
                         formatter: (e) => (e ? e : "-"),
                    },
               ],
               data: list_order.order ? [list_order.order] : [],
               keyField: "order_id",
          };

          return (
               <div className="panel mb-5">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รายการที่จับคู่แล้ว`,
                                   onClick: () => window.location.assign("/admin/match/list"),
                              },
                              {
                                   title: `การจับคู่ใบจอง (${order?.order_code_id || ""})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order.order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel ">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* <LabelBar type={"title"} title={"รายละเอียดของ Invoice"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบ Invoice : ",
                                                            detail: "20200000000",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Invoice Date : ",
                                                            detail: "วว/ดด/ปป",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div> */}
                                        <LabelBar type={"title"} title={"รายละเอียดเกี่ยวกับรถ"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series : ",
                                                            detail: order?.series_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model : ",
                                                            detail: order?.model_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สี : ",
                                                            detail: order?.color_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รถที่จับคู่"} />
                                        <div className="content" id="table-content">
                                             <Table
                                                  type={"summary-no-padding"}
                                                  column={table_list.column}
                                                  data={table_list.data}
                                                  keyField={table_list.keyField}
                                             />
                                        </div>
                                        <br></br>
                                        <LabelBar type={"title"} title={"ใบจองที่จับคู่"} />
                                        <div className="content" id="table-content">
                                             <Table
                                                  type={"summary-no-padding"}
                                                  column={table_preemption_list.column}
                                                  data={table_preemption_list.data}
                                                  keyField={table_preemption_list.keyField}
                                             />
                                        </div>
                                   </div>
                              </div>
                              {getToken().position_id === 25 && (
                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             <Button onClick={() => this.onClickCancelModal()} variant={"danger"}>
                                                  ยกเลิกการยืนยันการจับคู่รถ
                                             </Button>
                                        </Col>
                                        <ModalPanel
                                             show={this.state.showModalCancel}
                                             title={"ต้องการยกเลิกการจับคู่รถหรือไม่"}
                                             type={"default"}
                                             onHide={() => this.onClickCancelModal()}
                                             button={[
                                                  { onClick: () => this.cancleAcceptMatch(), name: "ตกลง", variant: "primary" },
                                                  { onClick: () => this.onClickCancelModal(), name: "ยกเลิก", variant: "outline-primary" },
                                             ]}
                                        />
                                   </div>
                              )}
                         </div>
                    </div>
               </div>
          );
     }
}
