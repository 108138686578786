import { Breadcrumbs } from "components";
import React, { Component } from "react";
import { Field, LabelBar } from "../../../../components/index";
import { Image, Form, Button, Col, Row } from "react-bootstrap";
import { POST, GET } from "../../../../api/api";
import swal from "@sweetalert/with-react";
import dayjs from "dayjs";
import CommissionTeam from "./commissionTeam";
import { getToken } from "../../../../static/function";
import "../admin/css.css";

export default class Commission extends Component {
     constructor(props) {
          super(props);

          this.state = {
               id: 0,
               commissions: [],
               branchs: [],
               teams: [],
               users: [],
               formulas: [],
               formulas_manager: [],
          };
     }
     componentDidMount = async () => {
          try {
               // console.log("getToken", getToken());
               let res = await GET("/ps/get_branchs");
               let data = res.map((el) => ({ value: el.branch_id, name: el.branch_name }));
               // data.unshift({ value: 0, name: "สาขาทั้งหมด" });
               this.setState({ branchs: data }, () => {
                    if (getToken().position_id === 16) {
                         let { branchs, commissions } = this.state;
                         commissions["branch"] = Number(getToken().branch_id);
                         this.setState({
                              commissions,
                              branchs: branchs.filter((el) => el.value === Number(getToken().branch_id)),
                         });
                         this.getTeam(Number(getToken().branch_id));
                    }
               });
          } catch (error) {}
     };
     getTeam = async (value) => {
          try {
               let team = await POST("/aos/get_team_by_branch", { branch_id: value });
               // console.log('team', team)
               let res = await POST("/aos/get_branch_by", { id: value });
               // console.log("res", res);
               let data_map = res[0].formula
                    ? JSON.parse(res[0].formula)
                           .filter((el) => el.status)
                           .map((el) => ({ value: el.id, name: el.name_formula }))
                    : [];
               let data_map_manager = res[0].formula_manager
                    ? JSON.parse(res[0].formula_manager)
                           .filter((el) => el.status)
                           .map((el) => ({ value: el.id, name: el.name_formula }))
                    : [];
               this.setState(
                    {
                         teams: team
                              .map((el) => ({ value: el.team_id, name: el.team_name }))
                              .filter((el) => (getToken().position_id === 16 ? el.value === getToken().team_id : el)),
                         //----------------------------//
                         formulas: data_map,
                         formula_all: JSON.parse(res[0].formula).filter((el) => el.status),
                         formulas_manager: data_map_manager,
                         formula_all_manager: JSON.parse(res[0].formula_manager).filter((el) => el.status),
                    },
                    () => {
                         if (getToken().position_id === 11 || getToken().position_id === 25) {
                              let { teams } = this.state;
                              teams.unshift({ value: 0, name: "ทีมทั้งหมด" });
                              this.setState({
                                   teams,
                              });
                         }
                         if (getToken().position_id === 16) {
                              let { commissions } = this.state;
                              commissions["team"] = Number(getToken().team_id);
                              this.setState({
                                   commissions,
                              });
                         }
                    }
               );
          } catch (error) {}
     };
     getUserByTeam = async (value) => {
          try {
               let team = await POST("/aos/get_user_by_team", { team_id: value });
               // console.log('team', team)
               let setTeam = team.map((el) => ({ value: el.user_id, name: el.firstname + " " + (el.lastname || "") }));
               setTeam.unshift({ value: 0, name: "ทั้งหมด" });
               this.setState({ users: setTeam });
          } catch (error) {}
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { commissions } = this.state;
          commissions[name] = value;
          this.setState({ commissions });
          if (name === "branch") {
               commissions["team"] = undefined;
               commissions["user_id"] = undefined;
               commissions["formula"] = undefined;
               commissions["formula_manager"] = undefined;
               this.setState({ teams: [], formulas: [], formulas_manager: [] });
               this.getTeam(Number(value));
          }
          if (name === "team") {
               commissions["user_id"] = undefined;
               this.setState({ users: [] });
               this.getUserByTeam(Number(value));
          }
     };

     handleChangeDate = (date, dateString, name) => {
          let { commissions } = this.state;
          commissions[name] = date;
          this.setState({ commissions });
     };

     onClickDetail = async () => {
          let { commissions, branchs, teams, users, formula_all, formula_all_manager } = this.state;
          if (
               commissions.branch &&
               commissions.team &&
               // commissions.user_id &&
               commissions.formula &&
               commissions.formula_manager &&
               commissions.date_start &&
               commissions.date_end &&
               commissions.date_start_old &&
               commissions.date_end_old
          ) {
               // console.log("commissions", commissions);
               // let branch_name = branchs.find((e) => e.value === Number(commissions.branch)).name;
               // let team_name = teams.find((e) => e.value === Number(commissions.team)).name;
               // let username = users.find((e) => e.value === Number(commissions.user_id)).name;
               // this.props.history.push({
               //      pathname: "/admin/commission/detail",
               //      state: { ...commissions, branch_name, team_name, username },
               // });
               ///------------------------------------///
               let formula = formula_all.filter((el) => el.id === Number(commissions.formula));
               let formula_manager = formula_all_manager.filter((el) => el.id === Number(commissions.formula_manager));
               try {
                    let branch_name = branchs.find((e) => e.value === Number(commissions.branch)).name;
                    let team_name = teams.find((e) => e.value === Number(commissions.team)).name;
                    // if (getToken().position_id === 16) {
                    // if (getToken().position_id === 16 || getToken().position_id === 25) {
                    let obj = {
                         ...commissions,
                         team_name: team_name.replace(/ /g, "_"),
                         branch_name,
                         date_start: dayjs(commissions.date_start).format("YYYY-MM-DD"),
                         date_end: dayjs(commissions.date_end).format("YYYY-MM-DD"),
                         formula: formula.length ? formula[0].data : [],
                         formula_manager: formula_manager.length ? formula_manager[0].data : [],
                         date_start_old: dayjs(commissions.date_start_old).format("YYYY-MM-DD"),
                         date_end_old: dayjs(commissions.date_end_old).format("YYYY-MM-DD"),
                    };
                    window.open(`/pscom/${JSON.stringify(obj)}`);
                    // } else {
                    //      let res = await POST("/aos/download_commission_sum", {
                    //           ...commissions,
                    //           team_name: team_name.replace(/ /g, "_"),
                    //           branch_name,
                    //           date_start: dayjs(commissions.date_start).format("YYYY-MM-DD"),
                    //           date_end: dayjs(commissions.date_end).format("YYYY-MM-DD"),
                    //           formula: formula.length ? formula[0].data : [],
                    //           formula_manager: formula_manager.length ? formula_manager[0].data : [],
                    //           date_start_old: dayjs(commissions.date_start_old).format("YYYY-MM-DD"),
                    //           date_end_old: dayjs(commissions.date_end_old).format("YYYY-MM-DD"),
                    //      });
                    //      window.open(res);
                    // }
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } else {
               swal({
                    text: "กรอกให้ครบ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     render() {
          let { commissions, branchs, teams, users, formulas, formulas_manager } = this.state;
          // console.log("formulas_manager", formulas_manager, "formulas", formulas);
          let branch_name = commissions.branch ? branchs.find((e) => e.value === Number(commissions.branch)).name : "-";
          let team_name = commissions.team ? teams.find((e) => e.value === Number(commissions.team)).name : "-";
          // const test = [{ value: "ทดสอบ", name: "ทดสอบ" }];
          return (
               <div className="panel label-space">
                    <Breadcrumbs title={[{ title: "รายงานค่า Commission" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ค้นหาพนักงานขาย"} />
                                        <Form.Row>
                                             <Field
                                                  type={"select"}
                                                  title={"สาขา"}
                                                  name={"branch"}
                                                  option={branchs}
                                                  require={true}
                                                  value={commissions?.branch}
                                                  md={6}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Field
                                                  type={"select"}
                                                  title={"ทีมพนักงาน"}
                                                  name={"team"}
                                                  option={teams}
                                                  value={commissions?.team}
                                                  require={true}
                                                  md={6}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             {/*===============================================*/}
                                             {/*===============================================*/}
                                             {/* <Field
                                                  type={"select"}
                                                  title={"ชื่อพนักงานขาย"}
                                                  name={"user_id"}
                                                  option={users}
                                                  require={true}
                                                  value={commissions?.user_id}
                                                  md={6}
                                                  handleChangeText={this.handleChangeText}
                                             /> */}
                                             <Field
                                                  type={"select"}
                                                  title={"สูตรคำนวนคอมมิชชั่น หัวหน้าทีม"}
                                                  name={"formula_manager"}
                                                  option={formulas_manager}
                                                  require={true}
                                                  value={commissions?.formula_manager}
                                                  md={6}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Field
                                                  type={"select"}
                                                  title={"สูตรคำนวนคอมมิชชั่น หนักงานขาย"}
                                                  name={"formula"}
                                                  option={formulas}
                                                  require={true}
                                                  value={commissions?.formula}
                                                  md={6}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             {/*===============================================*/}
                                             {/*===============================================*/}
                                             <Field
                                                  type={"date"}
                                                  title={"ประจำวันที่​(เดือนที่ผ่านมา)"}
                                                  name={"date_start_old"}
                                                  formatDate={"DD-MM-YYYY"}
                                                  require={true}
                                                  md={3}
                                                  handleSelectDate={this.handleChangeDate}
                                             />
                                             <Field
                                                  type={"date"}
                                                  title={"ถึงวันที่(เดือนที่ผ่านมา)"}
                                                  name={"date_end_old"}
                                                  formatDate={"DD-MM-YYYY"}
                                                  require={true}
                                                  md={3}
                                                  handleSelectDate={this.handleChangeDate}
                                             />
                                             {/*===============================================*/}
                                             {/*===============================================*/}
                                             <Field
                                                  type={"date"}
                                                  title={"ประจำวันที่(เดือนปัจจุบัน)"}
                                                  name={"date_start"}
                                                  formatDate={"DD-MM-YYYY"}
                                                  require={true}
                                                  md={3}
                                                  handleSelectDate={this.handleChangeDate}
                                             />
                                             <Field
                                                  type={"date"}
                                                  title={"ถึงวันที่(เดือนปัจจุบัน)"}
                                                  name={"date_end"}
                                                  formatDate={"DD-MM-YYYY"}
                                                  require={true}
                                                  md={3}
                                                  handleSelectDate={this.handleChangeDate}
                                             />
                                        </Form.Row>
                                        <Row>
                                             <Col xs={12} sm={6}>
                                                  <Button className="button-group __submit" style={{ width: "50%" }} onClick={this.onClickDetail}>
                                                       ดึงคอมมิชชั่น
                                                  </Button>
                                             </Col>
                                             <Col xs={12} sm={6}>
                                                  <Button
                                                       className="button-group __submit"
                                                       style={{ width: "50%", background: "green" }}
                                                       onClick={this.onDownload}
                                                  >
                                                       รายงานบัญชี
                                                  </Button>
                                             </Col>
                                        </Row>
                                   </div>
                              </div>
                         </div>
                    </div>
                    {/* <div className="content __input-panel pt-3">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar
                                             type={"title"}
                                             title={`ค่าคอมมิชชั่นเดือน : ${
                                                  commissions.date_start ? dayjs(commissions.date_start).add(543, "y").format("MM-YY") : "-"
                                             }\nสาขา : ${branch_name}\nทีมขาย : ${team_name}`}
                                        />
                                        <CommissionTeam />
                                   </div>
                              </div>
                         </div>
                    </div> */}
               </div>
          );
     }
     onDownload = async () => {
          let { commissions, teams, branchs } = this.state;
          if (commissions.branch === "0") {
               if (commissions.branch && commissions.date_start && commissions.date_end) {
                    try {
                         let branch_name = branchs.find((e) => e.value === Number(commissions.branch)).name;
                         let team_name = "ทีมทั้งหมด";
                         let res = await POST("/aos/download_commission", {
                              ...commissions,
                              team_name: team_name.replace(/ /g, "_"),
                              branch_name,
                              date_start: dayjs(commissions.date_start).format("YYYY-MM-DD"),
                              date_end: dayjs(commissions.date_end).format("YYYY-MM-DD"),
                         });
                         window.open(res);
                    } catch (error) {
                         swal({
                              text: error,
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    }
               } else {
                    swal({
                         text: "กรอกให้ครบ",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } else {
               if (commissions.branch && commissions.date_start && commissions.date_end) {
                    // console.log("commissions.branch", commissions.branch);
                    try {
                         let branch_name = branchs.find((e) => e.value === Number(commissions.branch)).name;
                         let team_name = commissions.team ? teams.find((e) => e.value === Number(commissions.team)).name : "0";
                         let res = await POST("/aos/download_commission", {
                              ...commissions,
                              team_name: team_name.replace(/ /g, "_"),
                              branch_name,
                              date_start: dayjs(commissions.date_start).format("YYYY-MM-DD"),
                              date_end: dayjs(commissions.date_end).format("YYYY-MM-DD"),
                         });
                         window.open(res);
                    } catch (error) {
                         swal({
                              text: error,
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    }
               } else {
                    swal({
                         text: "กรอกให้ครบ",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }
     };
}
