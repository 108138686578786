import React, { Component } from "react";
import { Form, Button, Image, Row, Col } from "react-bootstrap";
import "./login.scss";
import { POST, GET } from "api/index";
import { Img } from "components/index";
import swal from "sweetalert";
import dayjs from "dayjs";

const querystring = require("querystring");

export default class Login extends Component {
     constructor(props) {
          super(props);
          this.state = {
               username: "",
               password: "",
          };
     }

     onChangeText = ({ target: { value, name } }) => {
          this.setState({ [name]: value });
     };

     onLogin = async (e) => {
          e.preventDefault();
          let { username, password } = this.state;
          try {
               let res = await POST("/authen/login", {
                    username,
                    password,
               });

               localStorage.setItem("token", res.cf1);
               localStorage.setItem("username", res.username);
               localStorage.setItem("permission_page", res.permission_page);
               localStorage.setItem("time_out", dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"));

               if (this.props.match.params.link) {
                    const search = "@";
                    const searchRegExp = new RegExp(search, "g"); // Throws SyntaxError
                    const replaceWith = "/";
                    let link = this.props.match.params.link.replace(searchRegExp, replaceWith);

                    link = link.split("|", link.length - 1);

                    // window.location.replace(`${link[0]}?${querystring.stringify({ 'position': link[1] })}`);
                    window.location.assign(await GET(`/authen/locate/${link[0]}?${querystring.stringify({ position: link[1] })}`));
               } else {
                    window.location.replace("/admin/dashboard");
               }
          } catch (err) {
               console.log("err", err);
               swal({
                    title: "เข้าสู่ระบบไม่สำเร็จ",
                    text: `${err}`,
                    icon: "warning",
                    buttons: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     render() {
          return (
               <div className="panel" id="panel__login">
                    <div>
                         <Row className="logo __header">
                              <Col className="align-self-center">
                                   <Image src={Img.toyota_logo} className="logo" />
                              </Col>
                              <Col>
                                   <Image src={Img.toyota_ps_logo} className="logo" />
                              </Col>
                         </Row>
                         <Form onSubmit={this.onLogin} className="login">
                              <Form.Group controlId="formBasicEmail">
                                   <Form.Label>Username</Form.Label>
                                   <Form.Control placeholder="Enter username" name="username" onChange={(e) => this.onChangeText(e)} />
                              </Form.Group>

                              <Form.Group controlId="formBasicPassword">
                                   <Form.Label>Password</Form.Label>
                                   <Form.Control type="password" placeholder="Password" name="password" onChange={(e) => this.onChangeText(e)} />
                              </Form.Group>
                              <Form.Group controlId="formBasicCheckbox">
                                   <Form.Text className="text-muted">Forgot Password?</Form.Text>
                              </Form.Group>
                              <Button variant="primary" type="submit">
                                   เข้าสู่ระบบ
                              </Button>
                         </Form>
                         <Row className="logo __footer">
                              <Col className="align-self-center">
                                   <Image src={Img.list_car} className="logo" />
                              </Col>
                         </Row>
                    </div>
               </div>
          );
     }
}
