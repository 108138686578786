import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Button, Image } from "react-bootstrap";
import Img from "../image";
import { getToken, getToken_permission_page } from "../../static/function";
import { field_text } from "../../static/Static";
// import { POST, GET } from "../../api/api";

import "./side-bar.scss";

let page = getToken_permission_page();
let permission = page ? JSON.parse(page.permission_page) : [];
let onPath = (item) => permission.some((el) => el.path === item);

export default class Sidebar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               u_id: null,
               toggle: false,
               user: {},
               active: 0,
               actives: {
                    acard: 0,
                    order: {
                         a1: 0,
                         a2: 0,
                         a3: 0,
                    },
                    discount: 0,
                    finance: {
                         a1: 0,
                         a2: 0,
                         a3: 0,
                         a4: 0,
                         a5: 0,
                         a6: 0,
                    },
                    match: {
                         a1: 0,
                         a2: 0,
                         a3: 0,
                         a4: 0,
                    },
                    install: {
                         a1: 0,
                         a2: 0,
                    },
                    label: {
                         a1: 0,
                         a2: 0,
                         a3: 0,
                         a4: 0,
                    },
                    deliver: 0,
                    admin: {
                         a1: 0,
                         a2: 0,
                         a3: 0,
                    },
                    report: {
                         a1: 0,
                         a2: 0,
                         a3: 0,
                         a4: 0,
                         a5: 0,
                         a6: 0,
                         a7: 0,
                         a8: 0,
                         a9: 0,
                         a10: 0,
                         a11: 0,
                    },
                    supplier: {
                         a1: 0,
                         a2: 0,
                    },
                    car: {
                         a1: 0,
                         a2: 0,
                    },
                    item: {
                         a1: 0,
                         a2: 0,
                    },
                    leasing: 0,
                    insurance: 0,
                    promotion: 0,
                    commission: 0,
               },
          };
     }

     onToggle = (e) => {
          this.setState({
               toggle: e,
          });
     };

     menuActive = (e) => {
          this.setState({ active: e });
     };

     changeActive = (e) => {
          let { order_active, actives } = this.state;

          /*A-Card*/
          if (e === "acard") {
               actives.acard = 1;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Order */
          if (e === "order_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => {
                    v === "a1" ? (actives.order[v] = 1) : (actives.order[v] = 0);
               });
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "order_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => {
                    v === "a2" ? (actives.order[v] = 1) : (actives.order[v] = 0);
               });
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "order_a3") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => {
                    v === "a3" ? (actives.order[v] = 1) : (actives.order[v] = 0);
               });
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }
          /*discount*/
          if (e === "discount") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 1;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* finance*/
          if (e === "finance_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => {
                    v === "a1" ? (actives.finance[v] = 1) : (actives.finance[v] = 0);
               });
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "finance_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => {
                    v === "a2" ? (actives.finance[v] = 1) : (actives.finance[v] = 0);
               });
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "finance_a3") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => {
                    v === "a3" ? (actives.finance[v] = 1) : (actives.finance[v] = 0);
               });
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "finance_a4") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => {
                    v === "a4" ? (actives.finance[v] = 1) : (actives.finance[v] = 0);
               });
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "finance_a5") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => {
                    v === "a5" ? (actives.finance[v] = 1) : (actives.finance[v] = 0);
               });
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "finance_a6") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => {
                    v === "a6" ? (actives.finance[v] = 1) : (actives.finance[v] = 0);
               });
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Match */
          if (e === "match_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => {
                    v === "a1" ? (actives.match[v] = 1) : (actives.match[v] = 0);
               });
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "match_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => {
                    v === "a2" ? (actives.match[v] = 1) : (actives.match[v] = 0);
               });
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "match_a3") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => {
                    v === "a3" ? (actives.match[v] = 1) : (actives.match[v] = 0);
               });
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "match_a4") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => {
                    v === "a4" ? (actives.match[v] = 1) : (actives.match[v] = 0);
               });
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Install */
          if (e === "install_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => {
                    v === "a1" ? (actives.install[v] = 1) : (actives.install[v] = 0);
               });
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "install_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => {
                    v === "a2" ? (actives.install[v] = 1) : (actives.install[v] = 0);
               });
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }
          /* Label */
          if (e === "label_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => {
                    v === "a1" ? (actives.label[v] = 1) : (actives.label[v] = 0);
               });
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "label_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => {
                    v === "a2" ? (actives.label[v] = 1) : (actives.label[v] = 0);
               });
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "label_a3") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => {
                    v === "a3" ? (actives.label[v] = 1) : (actives.label[v] = 0);
               });
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "label_a4") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => {
                    v === "a4" ? (actives.label[v] = 1) : (actives.label[v] = 0);
               });
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /*Deliver*/
          if (e === "deliver") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 1;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Admin */
          if (e === "admin_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => {
                    v === "a1" ? (actives.admin[v] = 1) : (actives.admin[v] = 0);
               });
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "admin_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => {
                    v === "a2" ? (actives.admin[v] = 1) : (actives.admin[v] = 0);
               });
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "admin_a3") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => {
                    v === "a3" ? (actives.admin[v] = 1) : (actives.admin[v] = 0);
               });
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }
          /* Report */
          if (e === "report_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a1" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a2" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a10") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a10" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a11") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a11" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a3") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a3" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a4") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a4" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a5") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a5" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a6") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a6" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a7") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a7" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a8") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a8" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "report_a9") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => {
                    v === "a9" ? (actives.report[v] = 1) : (actives.report[v] = 0);
               });
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Supplier */
          if (e === "supplier_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => {
                    v === "a1" ? (actives.supplier[v] = 1) : (actives.supplier[v] = 0);
               });
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "supplier_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => {
                    v === "a2" ? (actives.supplier[v] = 1) : (actives.supplier[v] = 0);
               });
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Car */
          if (e === "car_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => {
                    v === "a1" ? (actives.car[v] = 1) : (actives.car[v] = 0);
               });
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "car_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => {
                    v === "a2" ? (actives.car[v] = 1) : (actives.car[v] = 0);
               });
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }

          /* Item */
          if (e === "item_a1") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => {
                    v === "a1" ? (actives.item[v] = 1) : (actives.item[v] = 0);
               });
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          } else if (e === "item_a2") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => {
                    v === "a2" ? (actives.item[v] = 1) : (actives.item[v] = 0);
               });
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }
          /* Leasing */
          if (e === "leasing") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 1;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 0;
          }
          /* Insurance */
          if (e === "insurance") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 1;
               actives.promotion = 0;
               actives.commission = 0;
          }
          /* Promotion */
          if (e === "promotion") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 1;
               actives.commission = 0;
          }
          /* Commission */
          if (e === "commission") {
               actives.acard = 0;
               Object.keys(actives.order).forEach((v) => (actives.order[v] = 0));
               actives.discount = 0;
               Object.keys(actives.finance).forEach((v) => (actives.finance[v] = 0));
               Object.keys(actives.match).forEach((v) => (actives.match[v] = 0));
               Object.keys(actives.install).forEach((v) => (actives.install[v] = 0));
               Object.keys(actives.label).forEach((v) => (actives.label[v] = 0));
               actives.deliver = 0;
               Object.keys(actives.admin).forEach((v) => (actives.admin[v] = 0));
               Object.keys(actives.report).forEach((v) => (actives.report[v] = 0));
               Object.keys(actives.supplier).forEach((v) => (actives.supplier[v] = 0));
               Object.keys(actives.car).forEach((v) => (actives.car[v] = 0));
               Object.keys(actives.item).forEach((v) => (actives.item[v] = 0));
               actives.leasing = 0;
               actives.insurance = 0;
               actives.promotion = 0;
               actives.commission = 1;
          }

          this.setState({ ...actives });
          // console.log(this.state.actives);
     };
     componentDidMount = async () => {
          try {
               this.setState({ user: getToken() });
          } catch (error) {}
     };

     render() {
          let { toggle, active, actives, u_id, user } = this.state;
          let { onLogout, onShow } = this.props;
          // console.log(`user`, user);
          return (
               <div className="side-bar">
                    <Button variant="link" className="icon" id="icon__sidebar" onClick={() => this.onToggle(!toggle)}>
                         <i className="fa fa-bars" aria-hidden="true"></i>
                    </Button>

                    <ProSidebar breakPoint="lg" toggled={toggle} onToggle={() => this.onToggle(!toggle)}>
                         <SidebarHeader>
                              <div className="content">
                                   <div className="-img" onClick={() => window.location.assign("/admin/dashboard")} style={{ cursor: "pointer" }}>
                                        <Image src={Img.toyota_logo} />
                                        <Image src={Img.toyota_ps_logo} />
                                   </div>
                                   <div className="-text d-flex flex-column">
                                        <h5>{`${user?.firstname} ${user?.lastname}`}</h5>
                                        <span>{user.branch_name}</span>
                                        <span>{user.team_name}</span>
                                        <small onClick={onShow} className="change-password">
                                             <span>
                                                  <i className="fa fa-chevron-right icon" />
                                                  เปลี่ยนรหัสผ่าน
                                             </span>
                                        </small>
                                        {/* {!u_id && ( */}
                                        <small className="ps-noti" onClick={() => window.open("https://line.me/R/ti/p/@555snzby")}>
                                             <span style={{ color: user?.u_id ? "green" : "black" }}>
                                                  <i className="fa fa-chevron-right icon" />
                                                  PS Notification
                                             </span>
                                        </small>
                                        {/* )} */}
                                   </div>
                              </div>
                         </SidebarHeader>
                         <SidebarContent>
                              <Menu>
                                   <li className="pro-menu-main">
                                        <strong>การขาย</strong>
                                   </li>
                                   {field_text.side_bar_sales.map((el) => {
                                        if (el.sub) {
                                             let checkMenu = el.menu.map((e) => onPath(e.path));
                                             if (checkMenu.some((e) => e === true)) {
                                                  return (
                                                       <SubMenu title={el.title}>
                                                            {el.menu.map((ele) => {
                                                                 let show = onPath(ele.path);
                                                                 if (show) {
                                                                      return (
                                                                           <MenuItem active={actives[el.active][ele.active]}>
                                                                                <Link to={ele.path} onClick={() => this.changeActive(ele.click)}>
                                                                                     {ele.label}
                                                                                </Link>
                                                                           </MenuItem>
                                                                      );
                                                                 } else return <span />;
                                                            })}
                                                       </SubMenu>
                                                  );
                                             } else return <span />;
                                        } else {
                                             let show = onPath(el.path);
                                             if (show) {
                                                  return (
                                                       <MenuItem active={actives[el.active]}>
                                                            <Link to={el.path} onClick={() => this.changeActive(el.click)}>
                                                                 {el.label}
                                                            </Link>
                                                       </MenuItem>
                                                  );
                                             } else return <span />;
                                        }
                                   })}
                              </Menu>
                              <Menu>
                                   <li className="pro-menu-main">
                                        <strong>จัดการระบบ</strong>
                                   </li>
                                   {field_text.side_bar_system.map((el) => {
                                        if (el.sub) {
                                             let checkMenu = el.menu.map((e) => onPath(e.path));
                                             if (checkMenu.some((e) => e === true)) {
                                                  return (
                                                       <SubMenu title={el.title}>
                                                            {el.menu.map((ele) => {
                                                                 let show = onPath(ele.path);
                                                                 if (show) {
                                                                      return (
                                                                           <MenuItem active={actives[el.active][ele.active]}>
                                                                                <Link to={ele.path} onClick={() => this.changeActive(ele.click)}>
                                                                                     {ele.label}
                                                                                </Link>
                                                                           </MenuItem>
                                                                      );
                                                                 } else return <span />;
                                                            })}
                                                       </SubMenu>
                                                  );
                                             } else return <span />;
                                        } else {
                                             let show = onPath(el.path);
                                             if (show) {
                                                  return (
                                                       <MenuItem active={actives[el.active]}>
                                                            <Link to={el.path} onClick={() => this.changeActive(el.click)}>
                                                                 {el.label}
                                                            </Link>
                                                       </MenuItem>
                                                  );
                                             } else return <span />;
                                        }
                                   })}
                              </Menu>
                         </SidebarContent>
                         <SidebarFooter>
                              <div onClick={onLogout} className="content">
                                   <div className="-text icon">
                                        <p>
                                             <i className="fas fa-external-link-alt icon"></i>
                                             ออกจากระบบ
                                        </p>
                                   </div>
                              </div>
                         </SidebarFooter>
                    </ProSidebar>
               </div>
          );
     }
}
