import { GET, POST } from "api";
import { Breadcrumbs, LabelBar, Field, ModalPanel } from "components";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import swal from "sweetalert";

export default class LendListLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               label_id: this.props.match.params.label_id,
               detail: {},
               order: {
                    labelhis_price: 0,
               },
               provice: [],
               district: [],
               sub_district: [],
               series: [],
               models: [],
               colors: [],
               show: false,
               order_id: [],
               order_find: [],
               spinner: false,
          };
     }

     componentDidMount = () => {
          // console.log(this.state.label_id);
          this.getDetailLabel();
          this.getOrdersId();
          this.getProvice();
          this.getSeriesCars();
     };

     getDetailLabel = async () => {
          let { label_id, order } = this.state;
          try {
               let orders = await POST("/car/this_label", { label_id });
               await this.setState({ detail: { ...orders } });
               //    console.log("detail", this.state.detail);

               order["label_id"] = this.state.detail.label_id;
               order["status"] = "จอง";
               // order["labelhis_open_date"] = dayjs(new Date()).format("YYYY-MM-DD");
               // order["to_return_date"] = dayjs(new Date()).format("YYYY-MM-DD");
               order["labelhis_open_date"] = dayjs(new Date());
               order["to_return_date"] = dayjs(new Date());
               order["order"] = "มี";
               // order.labelhis_price = orders.labelhis[0].labelhis_price;
               order.labelhis_price = 4000;

               this.setState({ order });
               // console.log(order);
          } catch (err) {
               console.log(err);
          }
     };

     getOrdersId = async () => {
          let { order_id } = this.state;
          try {
               let orders = await GET("/order/order_borrow_label");
               orders.map((el, i) => {
                    order_id.push(el.order_code_id.toString());
               });
               this.setState({ order_id, order_find: [...orders] });
          } catch (error) {
               console.log(error);
          }
     };

     /* ----------------- GET --------------------- */
     getProvice = async () => {
          let province_option = [];
          try {
               let getprovice = await GET("/address/getprovince");
               let arr = [...getprovice];
               for (let i = 0; i < arr.length; i++) {
                    province_option.push({ name: arr[i].name_th, value: arr[i].name_th, id: arr[i].id });
               }
               this.setState({
                    provice: province_option,
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getDistrict = async () => {
          let { order, provice } = this.state;
          let district_option = [];

          let province_id = provice.find((e) => e.name === order.labelhis_provinces).id;
          try {
               let getamphures = await POST("/address/getdistrict", { province_id });
               let arr = [...getamphures];
               for (let i = 0; i < arr.length; i++) {
                    district_option.push({ name: arr[i].name_th, value: arr[i].name_th, id: arr[i].id });
               }
               this.setState({
                    district: district_option,
               });
          } catch (error) {
               console.log("error ", error);
          }
     };
     getSubDistrict = async () => {
          let { order, district } = this.state;
          let district_id = district.find((e) => e.name === order.labelhis_district).id;
          let subdistrict_option = [];

          try {
               let getSubdistrict = await POST("/address/getsubdistrict", { amphure_id: district_id });
               let arr = [...getSubdistrict];
               for (let i = 0; i < arr.length; i++) {
                    subdistrict_option.push({ name: arr[i].name_th, value: arr[i].name_th, id: arr[i].id, zip_code: arr[i].zip_code });
               }
               this.setState({
                    sub_district: subdistrict_option,
               });
          } catch (err) {
               console.log(err);
          }
     };

     getSeriesCars = async () => {
          let series_option = [];
          try {
               let getseries = await GET("/car/series");
               let arr = [...getseries];
               for (let i = 0; i < arr.length; i++) {
                    series_option.push({ name: arr[i].series_name, value: arr[i].series_id });
               }
               this.setState({
                    series: series_option,
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getModelCar = async () => {
          let models_option = [];

          try {
               let getmodel = await POST("/car/model", { series_id: this.state.order.series_id });
               let arr = [...getmodel];
               for (let i = 0; i < arr.length; i++) {
                    models_option.push({ name: arr[i].model_name, value: arr[i].model_id });
               }
               this.setState({
                    models: models_option,
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getColors = async () => {
          let color_option = [];
          try {
               let getcolor = await POST("/car/color", { model_id: this.state.order.model_id });
               let arr = [...getcolor];
               for (let i = 0; i < arr.length; i++) {
                    color_option.push({ name: arr[i].color_name, value: arr[i].color_id });
               }
               this.setState({
                    colors: color_option,
               });
          } catch (error) {
               console.log("error ", error);
          }
     };
     /* -------------- onClick --------------------- */

     onClickLendLabel = async () => {
          let { order } = this.state;
          // console.log(`order.order_id`, order.order_id)
          if (order.order_id) {
               this.setState({ spinner: true });
               try {
                    // console.log(`order`, order);
                    let result = await POST("/car/brrow_label", order);
                    if (result === "add success") {
                         // await POST("/order/addReceiptCarLabel", {
                         //      receiptData: [
                         //           {
                         //                order_id: order.order_id,
                         //                receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำป้ายแดง",
                         //                receipt_price: 3000,
                         //                code: "RP",
                         //           },
                         //      ],
                         // });
                         //      //----------------------------------------------------------------------//
                         swal({
                              text: "ยืมป้ายแดงเรียบร้อยแล้ว",
                              icon: "success",
                              buttons: { submit: "เสร็จสิ้น" },
                         }).then((value) => {
                              window.location.assign(`/admin/label/list/${this.state.label_id}`);
                         });
                         this.setState({ spinner: false });
                    }
               } catch (err) {
                    console.log("err", err);
                    this.setState({ spinner: false });
                    swal({
                         text: err,
                         icon: "warning",
                         buttons: { submit: "เสร็จสิ้น" },
                    });
               }
          } else {
               swal("กรุณาเลือกเลขที่ใบจองก่อน", "", "warning");
          }
     };

     handleReset = () => {
          let { order } = this.state;
          Object.keys(order).forEach((v) => {
               if (v === "labelhis_open_date" || v === "to_return_date" || v === "labelhis_price" || v === "order" || v === "label_id") {
                    //No Doing
               } else {
                    order[`${v}`] = "";
               }
          });

          this.setState({ order });
     };

     onClickClosePopup = () => {
          this.setState({ show: !this.state.show });
          window.location.assign(`/admin/label/list/${this.state.label_id}`);
     };

     onClickPrintSlip = () => {
          //   this.setState({ show: !this.state.show });
     };

     /* -------------- onChange --------------------- */

     handleChangeRadio = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
          // console.log(this.state.order);
     };
     handleChangeText = (e) => {
          let { order } = this.state;
          let arr = e.target.value.split("-");
          let value = "";
          for (let i in arr) {
               value = value + arr[i];
          }

          order[e.target.name] = value;

          this.setState({ order });
          // console.log(this.state.order);
     };

     handleChangeAutocomplete = async (e) => {
          let { order, order_find } = this.state;
          let order_code_id = e[0];
          let order_id = order_find.find((el) => el.order_code_id === order_code_id)?.order_id;

          order.order_id = parseInt(order_id);
          try {
               let details = await POST("/order/this", { order_id: order_id });

               // get order from detail
               order.order_id = parseInt(order_id);
               order.labelhis_name = details.userinfo_name;
               order.labelhis_idcard = details.userinfo_idcard;
               order.labelhis_tel = details.userinfo_tel;
               order.labelhis_address = details.userinfo_address;
               order.model_code = details.model_code;
               order.tank = details.car_tank ? details.car_tank : "";
               order.engine = details.car_engine ? details.car_engine : "";

               order.labelhis_provinces = details.userinfo_province;

               await this.getDistrict();
               order.labelhis_district = details.userinfo_district;

               await this.getSubDistrict();
               order.labelhis_sub_district = details.userinfo_sub_district;

               order.labelhis_zipcode = details.userinfo_zipcode;

               order.series_id = details.series_id;

               await this.getModelCar();
               order.model_id = details.model_id;

               await this.getColors();
               order.color_id = details.color_id;

               // console.log("detail", details);
               // console.log("order", this.state.order);
          } catch (err) {
               console.log(err);
          }
          this.setState({ order });
     };
     handleChangeSelect = (e) => {
          let { order, sub_district } = this.state;
          order[e.target.name] = e.target.value;
          // province
          if (e.target.name === "labelhis_provinces") {
               this.getDistrict();
          }
          if (e.target.name === "labelhis_district") {
               this.getSubDistrict();
          }
          if (e.target.name === "labelhis_sub_district") {
               let zip_code = sub_district.find((e) => e.name === order.labelhis_sub_district).zip_code;
               order["labelhis_zipcode"] = zip_code;
          }

          //car
          if (e.target.name === "series_id") {
               this.getModelCar();
          }
          if (e.target.name === "model_id") {
               this.getColors();
          }

          this.setState({ order });
          // console.log(this.state.order);
     };
     handleChangeDate = (date, dateString, name) => {
          let { order } = this.state;
          order[name] = date;
          this.setState({ order });
          // console.log(this.state.order);
     };

     handleChangeNumberPrice = (e, name) => {
          let { order } = this.state;
          order[name] = e.floatValue;
          this.setState({ order });
          // console.log(this.state.order);
     };

     render() {
          let { label_id, detail, order, provice, district, sub_district, series, models, colors, spinner } = this.state;
          return (
               <div className="panel">
                    {spinner && (
                         <Spinner
                              variant="primary"
                              size="md"
                              style={{ position: "fixed", top: "50%", left: "50%", zIndex: 1 }}
                              animation="border"
                              role="status"
                         >
                              <span className="sr-only">Loading...</span>
                         </Spinner>
                    )}
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รายการป้ายแดง`,
                                   onClick: () => window.location.assign("/admin/label/list"),
                              },
                              {
                                   title: `ป้ายแดง (${detail?.label_no})`,
                                   onClick: () => window.location.assign(`/admin/label/list/${label_id}`),
                              },
                              { title: `ยืมป้ายแดง` },
                         ]}
                         active={2}
                         button={[]}
                    />

                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลลูกค้า"} />
                                        <Row className="noMargin">
                                             <LabelBar
                                                  type={"radio"}
                                                  title={"ใบจอง "}
                                                  label={["มี", "ไม่มี"]}
                                                  name={"order"}
                                                  value={order?.order}
                                                  disabled={false}
                                                  style_id="__inline"
                                                  xl={6}
                                                  handleChangeText={(e) => {
                                                       this.handleChangeRadio(e);
                                                  }}
                                             />
                                        </Row>
                                        <Row className="noMargin">
                                             <Col md={6}>
                                                  <Field
                                                       name={"order_id"}
                                                       handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                       type={"autocomplete"}
                                                       title={"เลขที่ใบจอง"}
                                                       placeholder={"กรอกเลขที่ใบจอง"}
                                                       require={false}
                                                       option={this.state.order_id}
                                                  />
                                             </Col>
                                             <Col md={6}>
                                                  <Field
                                                       name={"labelhis_name"}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeText(e);
                                                       }}
                                                       type={"text"}
                                                       title={"ข้อมูลลูกค้า"}
                                                       placeholder={"กรุณากรอก ชื่อ-สกุล ผู้ยืม"}
                                                       value={order?.labelhis_name}
                                                       require={false}
                                                  />
                                             </Col>
                                        </Row>
                                        <Row className="noMargin">
                                             <Col md={6}>
                                                  <Field
                                                       name={"labelhis_idcard"}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeText(e);
                                                       }}
                                                       type={"number"}
                                                       title={"หมายเลขประจำตัว"}
                                                       value={order?.labelhis_idcard}
                                                       placeholder={"กรุณากรอกหมายเลขประจำตัว"}
                                                       require={false}
                                                       format="#-####-#####-##-#"
                                                       mask="X"
                                                       typeInput={"tel"}
                                                       md={12}
                                                  />
                                             </Col>
                                             <Col md={6}>
                                                  {/* <Field type={"number"} title={"หมายเลขโทรศัพท์"} placeholder={"กรุณากรอกหมายเลขโทรศัพท์"} require={false} /> */}
                                                  <Field
                                                       type={"number"}
                                                       title={"หมายเลขโทรศัพท์"}
                                                       placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                                       require={true}
                                                       name={"labelhis_tel"}
                                                       value={order?.labelhis_tel}
                                                       md={12}
                                                       format="###-#######"
                                                       mask="X"
                                                       typeInput={"tel"}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeText(e);
                                                       }}
                                                  />
                                             </Col>
                                        </Row>
                                        <Row className="noMargin">
                                             <Col md={6}>
                                                  <Field
                                                       name={"labelhis_address"}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeText(e);
                                                       }}
                                                       value={order?.labelhis_address}
                                                       type={"text"}
                                                       title={"ที่อยู่"}
                                                       placeholder={"กรุณากรอกที่อยู่"}
                                                       require={false}
                                                  />
                                             </Col>
                                             <Col md={3}>
                                                  <Field
                                                       type={"select"}
                                                       title={"จังหวัด"}
                                                       placeholder={"กรุณาเลือกจังหวัด"}
                                                       option={provice}
                                                       value={order?.labelhis_provinces}
                                                       require={false}
                                                       name={"labelhis_provinces"}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeSelect(e);
                                                       }}
                                                  />
                                             </Col>
                                             <Col md={3}>
                                                  <Field
                                                       type={"select"}
                                                       title={"อำเภอ/เขต"}
                                                       name={"labelhis_district"}
                                                       placeholder={"กรุณาเลือกอำเภอ/เขต"}
                                                       option={district}
                                                       value={order?.labelhis_district}
                                                       require={false}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeSelect(e);
                                                       }}
                                                  />
                                             </Col>
                                        </Row>
                                        <Row className="noMargin">
                                             <Col md={3}>
                                                  {/* <Field type={"text"} title={"ตำบล/แขวง"} placeholder={"กรุณากรอกตำบล/แขวง"} require={false} /> */}
                                                  <Field
                                                       type={"select"}
                                                       title={"ตำบล/แขวง"}
                                                       placeholder={"กรุณาเลือกตำบล/แขวง"}
                                                       name={"labelhis_sub_district"}
                                                       value={order?.labelhis_sub_district}
                                                       option={sub_district}
                                                       require={false}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeSelect(e);
                                                       }}
                                                  />
                                             </Col>
                                             <Col md={3}>
                                                  <Field
                                                       value={sub_district?.zip_code}
                                                       type={"text"}
                                                       name={"labelhis_zipcode"}
                                                       // value={order?.labelhis_zipcode}
                                                       title={"รหัสไปรษณีย์"}
                                                       placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                       require={false}
                                                       handleChangeText={(e) => {
                                                            this.handleChangeText(e);
                                                       }}
                                                  />
                                             </Col>
                                             <Col md={6}></Col>
                                        </Row>

                                        <LabelBar type={"title"} title={"ข้อมูลรถ"} />
                                        <>
                                             <Row className="noMargin">
                                                  <Col md={6}>
                                                       <Field
                                                            type={"select"}
                                                            title={"Serie"}
                                                            placeholder={"กรุณาเลือก Series"}
                                                            value={order?.series_id}
                                                            name={"series_id"}
                                                            option={series}
                                                            require={false}
                                                            handleChangeText={(e) => {
                                                                 this.handleChangeSelect(e);
                                                            }}
                                                       />
                                                  </Col>
                                                  <Col md={6}>
                                                       <Field
                                                            type={"select"}
                                                            title={"Model"}
                                                            placeholder={"กรุณาเลือก Model"}
                                                            value={order?.model_id}
                                                            name={"model_id"}
                                                            option={models}
                                                            require={false}
                                                            handleChangeText={(e) => {
                                                                 this.handleChangeSelect(e);
                                                            }}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Row className="noMargin">
                                                  <Col md={6}>
                                                       <Field
                                                            type={"select"}
                                                            title={"สี"}
                                                            placeholder={"กรุณาเลือกสี"}
                                                            value={order?.color_id}
                                                            name={"color_id"}
                                                            option={colors}
                                                            require={false}
                                                            handleChangeText={(e) => {
                                                                 this.handleChangeSelect(e);
                                                            }}
                                                       />
                                                  </Col>
                                                  <Col md={6}>
                                                       <Field
                                                            name={"model_code"}
                                                            handleChangeText={(e) => {
                                                                 this.handleChangeText(e);
                                                            }}
                                                            type={"text"}
                                                            value={order.model_code}
                                                            title={"Model Code"}
                                                            placeholder={"กรุณากรอก Model Code"}
                                                            require={false}
                                                       />
                                                  </Col>
                                             </Row>
                                             <Row className="noMargin">
                                                  <Col md={6}>
                                                       <Field
                                                            name={"tank"}
                                                            handleChangeText={(e) => {
                                                                 this.handleChangeText(e);
                                                            }}
                                                            type={"text"}
                                                            value={order.tank}
                                                            title={"เลขตัวถัง"}
                                                            placeholder={"กรุณากรอกเลขตัวถัง"}
                                                            require={false}
                                                       />
                                                  </Col>
                                                  <Col md={6}>
                                                       <Field
                                                            name={"engine"}
                                                            handleChangeText={(e) => {
                                                                 this.handleChangeText(e);
                                                            }}
                                                            type={"text"}
                                                            value={order.engine}
                                                            title={"เลขเครื่องยนต์"}
                                                            placeholder={"กรุณากรอกเลขเครื่องยนต์"}
                                                            require={false}
                                                       />
                                                  </Col>
                                             </Row>
                                        </>
                                        <LabelBar type={"title"} title={"ค่ามัดจำ"} />
                                        <Row className="noMargin">
                                             <Col md={6}>
                                                  <Field
                                                       name={"labelhis_open_date"}
                                                       handleSelectDate={this.handleChangeDate}
                                                       type={"date"}
                                                       title={"วันที่เบิกป้าย"}
                                                       placeholder={order?.labelhis_open_date ? order?.labelhis_open_date : "เลือกวันที่"}
                                                       require={false}
                                                       formatDate={"DD/MM/YYYY"}
                                                       value={order?.labelhis_open_date}
                                                  />
                                             </Col>
                                             <Col md={6}>
                                                  <Field
                                                       name={"to_return_date"}
                                                       handleSelectDate={this.handleChangeDate}
                                                       type={"date"}
                                                       title={"กำหนดคืน"}
                                                       placeholder={order?.to_return_date ? order?.to_return_date : "เลือกวันที่"}
                                                       require={false}
                                                       formatDate={"DD/MM/YYYY"}
                                                       value={order?.to_return_date}
                                                  />
                                             </Col>
                                             <Col md={6}>
                                                  <Field
                                                       name={"labelhis_price"}
                                                       handleChangeNumber={(e) => {
                                                            this.handleChangeNumberPrice(e, "labelhis_price");
                                                       }}
                                                       type={"number"}
                                                       title={"จำนวนเงินมัดจำ (บาท)"}
                                                       placeholder={"0.00"}
                                                       require={false}
                                                       value={Number(order?.labelhis_price).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })}
                                                       decimalScale={2}
                                                  />
                                             </Col>
                                        </Row>

                                        {/* Submit group */}
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button onClick={this.onClickLendLabel} id={"btn_proceed"} type="submit" disabled={spinner}>
                                                       ยืมป้ายแดง
                                                  </Button>
                                             </Col>
                                             <Col xs={6}>
                                                  <Button id={"btn_reset"} variant="danger" onClick={this.handleReset}>
                                                       Reset
                                                  </Button>
                                             </Col>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
