import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table } from "components/index";
import { Form, Button, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import { POST } from "../../../../../api/api";
import XlsExport from "xlsexport";
import dayjs from "dayjs";

export default class ManageCarModel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               previous_path: this.props.match.params.manage,
               id: this.props.match.params.id,
               title: [],
               serie: {
                    status: "ใช้งาน",
                    model: [],
               },
               showColorTable: false,
               nameColorTable: "",
               indexModel: 0,
               deleteModel: [],
               deleteColors: [],
               addmodelEdit: [],
               addCar: [],
               price_list: {
                    car_cost: 0,
                    air_conditioner_cost: 0,
                    cost_before_vat: 0,
                    cost_vat: 0,
                    cost_after_vat: 0,
                    equipment_cost: 0,
                    equipment_cost_vat: 0,
                    equipment_cost_after_vat: 0,
                    total_cost: 0,
                    car_sale_price: 0,
                    price_of_air_conditioner: 0,
                    car_sale_price_air_before_vat: 0,
                    price_of_air_conditioner_vat: 0,
                    car_sale_price_air_after_vat: 0,
                    price_of_equipment: 0,
                    price_of_equipment_vat: 0,
                    price_of_equipment_after_vat: 0,
                    all_sales_prices: 0,
                    car_profit: 0,
                    air_conditioning_profit: 0,
                    equipment_profit: 0,
                    margin_cost: 0,
                    act_premium: 0,
                    register_price: 0,
                    commission_extra: 0,
                    act_person_general_price: 0,
                    act_person_notmore15_price: 0,
                    act_person_more15_price: 0,
                    act_commerce_notmore7_price: 0,
                    act_commerce_notmore15_price: 0,
                    act_commerce_notmore20_price: 0,
                    act_commerce_more20_price: 0,
               },
               search: "",
          };
     }

     componentDidMount = () => {
          this.setPath();
          this.getSeries();
     };
     /* ----------------------- Set Breadcrumbs ------------------------ */
     setPath = async () => {
          let { previous_path, title, id } = this.state;

          switch (previous_path) {
               case "add":
                    title = [
                         {
                              title: "รุ่นรถ",
                              onClick: () => window.location.assign("/admin/car-model"),
                         },
                         {
                              title: "เพิ่มรุ่นรถ",
                         },
                    ];
                    break;
               case "edit":
                    title = [
                         {
                              title: "รุ่นรถ",
                              onClick: () => window.location.assign("/admin/car-model"),
                         },
                         {
                              title: "แก้ไข",
                         },
                    ];
                    this.getSeries();

                    break;
               default:
                    break;
          }

          this.setState({ title: title });
     };

     getSeries = async () => {
          try {
               let serie = await POST("/car/price_car_list", { series_id: this.state.id });
               this.setState({
                    serie: { ...serie },
                    addmodelEdit: serie.seriesData,
               });
               // console.log("serie", serie);
          } catch (error) {
               console.log(error);
          }
     };

     /* ------------------------------ Handle -------------------------- */
     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { serie } = this.state;
          serie[name] = value;
          this.setState({ serie });
          console.log(serie);
     };
     /* Validate field on submit */
     handleSubmit = async (event) => {
          event.preventDefault();

          let { serie } = this.state;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    icon: "error",
                    button: "เสร็จสิ้น",
               });
               event.stopPropagation();
          } else {
               try {
                    await POST("/car/add_series", serie);
                    swal({
                         text: "เพิ่มประเภท Series ในรายการเรียบร้อยแล้ว",
                         icon: "success",
                         button: "เสร็จสิ้น",
                    }).then(() => this.props.history.push("/admin/car-model"));
                    event.preventDefault();
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }
          this.setState({
               validated: true,
          });
     };

     //Search
     handleChangeSearch = (e) => {
          this.setState({ search: e.target.value });
     };
     onClickSearch = () => {
          let { serie, search } = this.state;
          let newSearch = serie.seriesData?.filter((el) => (search ? el.model_code.indexOf(search) > -1 : el));

          this.setState({ newSearch });
     };

     handleEdit = async () => {
          let { serie, deleteModel, deleteColors } = this.state;
          try {
               let addColors = [];
               // serie.model.forEach((element, index) => {
               //      if (element.colors) {
               //           element.colors.forEach((e, i) => {
               //                if (!e.color_id && element.model_id) {
               //                     addColors.push({
               //                          color_name: e.color_name,
               //                          model_id: element.model_id,
               //                     });
               //                }
               //           });
               //      }
               //      if (element.model_id) {
               //           serie.model.splice(index, 1);
               //      }
               // });

               await POST("/car/edit_series", {
                    ...serie,
                    // deleteModel,
                    // addColors,
                    // deleteColors,
               });
               swal({
                    text: "แก้ไขรุ่นรถในรายการเรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => window.location.reload());

               this.getSeries();
          } catch (error) {
               console.log(`error`, error);
               swal({
                    text: "บันทึกไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };
     handleReset = () => {
          let { serie } = this.state;
          console.log(serie.color_name);
          serie = {
               series_name: "",
               series_code: "",
               type: "",
               status: "ใช้งาน",
               model: [],
               model_code: "",
               model_name: "",
               engine: "",
               color_code: "",
               color_name: "",
               color_tmt: "",
               modeledit: serie.modeledit,
          };

          this.setState({
               serie,
               showColorTable: false,
          });
     };
     /* Add Modal */
     handleAddModal = () => {
          let { serie } = this.state;
          serie.model.push({
               model_name: serie.namemodel,
               model_price: serie.pricemodel,
               unit: "บาท",
               colors: [],
          });
          // console.log("serie ", serie);
          this.setState({ serie });
     };
     /* ---------------------------- Formatter ------------------------ */
     rmModelFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemoveModel(row, rowIndex)} variant="danger -small ">
                    Remove
               </Button>
          );
     };
     rmColorFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemoveColor(row, rowIndex)} variant="danger -small ">
                    Remove
               </Button>
          );
     };
     addFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.showAddColor(row, rowIndex)} variant="primary -small">
                    เพิ่มสี
               </Button>
          );
     };

     parseFloatFormatter(cell, row) {
          let new_cell = parseFloat(cell).toFixed(2);
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     }

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Col style={{ textAlign: "center" }}>
                    <span>{`${rowIndex + 1}.`}</span>
               </Col>
          );
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "detail":
                    return (
                         <div>
                              <a
                                   onClick={() => {
                                        window.location.assign(`/admin/car-model/detail/edit/price/${row.id}`);
                                   }}
                              >
                                   ข้อมูลราคา
                              </a>
                         </div>
                    );
               case "remove":
                    if (this.state.previous_path === "add") {
                         return (
                              <a onClick={() => this.removeItem(rowIndex)} style={{ color: "red" }}>
                                   ลบ
                              </a>
                         );
                    } else if (this.state.previous_path === "edit") {
                         return (
                              <a onClick={() => this.handleDelete(row.id)} style={{ color: "red" }}>
                                   ลบ
                              </a>
                         );
                    } else {
                         return <a style={{ color: "red" }}>ลบ</a>;
                    }
               case "close":
                    if (cell === 1) {
                         return (
                              <a onClick={() => this.handleClose(row.id, cell)} style={{ color: "red" }}>
                                   {cell ? "ปิด" : "เปิด"}
                              </a>
                         );
                    } else if (cell === 0) {
                         return (
                              <a onClick={() => this.handleClose(row.id, cell)} style={{ color: "green" }}>
                                   {cell ? "ปิด" : "เปิด"}
                              </a>
                         );
                    } else {
                         return "-";
                    }
          }
     };

     // addColorFormatter = (cell, row, rowIndex, formatExtraData) => {
     //      return <Button onClick={() => this.handleAddColor(row, rowIndex)} variant='primary -small'>เพิ่มสี</Button>;
     // }

     onRemoveModel = (row, rowIndex) => {
          let { serie, deleteModel } = this.state;
          serie.model.splice(rowIndex, 1);
          deleteModel.push(row.model_id);

          this.setState({ serie, showColorTable: false, deleteModel });
     };

     onRemoveColor = (row, rowIndex) => {
          let { serie, indexModel, deleteColors } = this.state;
          serie.model[indexModel].colors.splice(rowIndex, 1);
          deleteColors.push(row.color_id);
          this.setState({ serie });
     };

     showAddColor = (row, rowIndex) => {
          this.setState({
               showColorTable: true,
               nameColorTable: row.model_name,
               indexModel: rowIndex,
          });
     };

     handleAddColor = () => {
          let { serie, indexModel } = this.state;
          serie.model[indexModel].colors.push({
               color_name: serie.namecolor,
          });
          this.setState({ serie });
     };
     //Edit
     handleAddCarDetail = async () => {
          let { serie } = this.state;
          serie.price_list = JSON.stringify(this.state.price_list);
          try {
               let result = await POST("/car/add_series", serie);
               let new_series = await POST("/car/price_car_list", { series_id: this.state.id });
               serie.seriesData = new_series.seriesData;

               this.setState({ serie });
          } catch (err) {
               console.log(err);
          }
     };

     //Delete
     handleDelete = async (id) => {
          swal("ต้องการจะลบรือไม่ ?", {
               buttons: {
                    cancel: "ยกเลิก",
                    submit: {
                         text: "ตกลง",
                         value: "submit",
                    },
                    onOpen: (e) => {
                         console.log(e);
                    },
               },
          }).then(async (value) => {
               if (value === "submit") {
                    let { serie } = this.state;
                    try {
                         await POST("/car/delete_price_car", { id: id });

                         let new_series = await POST("/car/price_car_list", { series_id: this.state.id });
                         serie.seriesData = new_series.seriesData;

                         this.setState({ serie });
                         swal({
                              button: "เสร็จสิ้น",
                              text: "ลบเสร็จสิ้น",
                              icon: "success",
                         });
                    } catch (err) {
                         console.log(err);
                         swal({
                              button: "ยืนยัน",
                              text: "กรุณาทำรายการใหม่",
                              icon: "warning",
                         });
                    }
               }
          });
     };
     handleClose = async (id, cell) => {
          swal(`ต้องการจะ${cell ? "ปิด" : "เปิด"}ใช้งานรือไม่ ?`, {
               buttons: {
                    cancel: "ยกเลิก",
                    submit: {
                         text: "ตกลง",
                         value: "submit",
                    },
                    onOpen: (e) => {
                         console.log(e);
                    },
               },
          }).then(async (value) => {
               if (value === "submit") {
                    let { serie } = this.state;
                    try {
                         await POST("/car/delete_price_car", { id: id, type: "close", cell });
                         let new_series = await POST("/car/price_car_list", { series_id: this.state.id });
                         serie.seriesData = new_series.seriesData;
                         this.setState({ serie });
                         swal({
                              button: "เสร็จสิ้น",
                              text: `${cell ? "ปิด" : "เปิด"}การใช้งานเสร็จสิ้น`,
                              icon: "success",
                         });
                    } catch (err) {
                         console.log(err);
                         swal({
                              button: "ยืนยัน",
                              text: "กรุณาทำรายการใหม่",
                              icon: "warning",
                         });
                    }
               }
          });
     };
     //Add
     addCar = () => {
          let { serie, addCar } = this.state;
          addCar.push({
               model_code: serie.model_code,
               model_name: serie.model_name,
               engine: serie.engine,
               color_code: serie.color_code,
               color_tmt: serie.color_tmt,
               color_name: serie.color_name,
          });

          serie.modeledit = addCar;
          this.setState({ serie });
     };
     removeItem = (rowIndex) => {
          let { serie } = this.state;
          let arr = serie.modeledit;
          arr.splice(rowIndex, 1);
          serie.modeledit = arr;

          this.setState({ serie });
     };
     downloadData = () => {
          let { serie } = this.state;
          let data = serie.seriesData.map((el) => {
               let price = JSON.parse(el.price_list);
               return {
                    รหัสรุ่นรถยนต์: serie.series_code,
                    ชื่อรุ่นรถยนต์: serie.series_name,
                    รหัสโมเดลรถยนต์: el.model_code,
                    ชื่อโมเดลรถยนต์: el.model_name,
                    เครื่องยนต์: el.car_cc,
                    รหัสสีรถยนต์: el.color_code,
                    "ชื่อสีรถยนต์ตาม TMT": el.color_tmt,
                    ชื่อสีรถยนต์ตามกรมขนส่ง: el.color_name,
                    ต้นทุนรถยนต์: price.car_cost,
                    ต้นทุนเครื่องปรับอากาศ: price.air_conditioner_cost,
                    ต้นทุนก่อนภาษีมูลค่าเพิ่ม: price.cost_before_vat,
                    ภาษีมูลค่าเพิ่มของต้นทุน: price.cost_vat,
                    ต้นทุนหลังภาษีมูลค่าเพิ่ม: price.cost_after_vat,
                    ต้นทุนอุปกรณ์: price.equipment_cost,
                    ภาษีมูลค่าเพิ่มต้นทุนอุปกรณ์: price.equipment_cost_vat,
                    ต้นทุนอุปกรณ์หลังภาษีมูลค่าเพิ่ม: price.equipment_cost_after_vat,
                    รวมต้นทุนทั้งหมด: price.total_cost,
                    ราคาขายรถยนต์: price.car_sale_price,
                    ราคาขายเครื่องปรับอากาศ: price.price_of_air_conditioner,
                    ราคาขายก่อนภาษีมูลค่าเพิ่ม: price.car_sale_price_air_before_vat,
                    ภาษีมูลค่าเพิ่มของราคาขาย: price.price_of_air_conditioner_vat,
                    ราคาขายหลังภาษีมูลค่าเพิ่ม: price.car_sale_price_air_after_vat,
                    ราคาขายอุปกรณ์: price.price_of_equipment,
                    ภาษีมูลค่าราคาขายเพิ่มอุปกรณ์: price.price_of_equipment_vat,
                    ราคาขายอุปกรณ์หลังภาษีมูลค่าเพิ่ม: price.price_of_equipment_after_vat,
                    รวมราคาขายทั้งหมด: price.all_sales_prices,
                    กำไรรถยนต์: price.car_profit,
                    กำไรเครื่องปรับอากาศ: price.air_conditioning_profit,
                    กำไรอุปกรณ์: price.equipment_profit,
                    "Margin มาตรฐาน": price.margin_cost,
                    "คอมมิชชั่น Extra": price.commission_extra,
                    "พ.ร.บ. บุคคลธรรมดา": price.act_person_general_price,
                    "พ.ร.บ. บุคคลไม่เกิน 15 ที่นั่ง": price.act_person_notmore15_price,
                    "พ.ร.บ. บุคคลเกิน 15 ที่นั่ง": price.act_person_more15_price,
                    "พ.ร.บ. เชิงพาณิชย์ไม่เกิน 7 ที่นั่ง": price.act_commerce_notmore7_price,
                    "พ.ร.บ. เชิงพาณิชย์ไม่เกิน 15 ที่นั่ง": price.act_commerce_notmore15_price,
                    "พ.ร.บ. เชิงพาณิชย์ไม่เกิน 20 ที่นั่ง": price.act_commerce_notmore20_price,
                    "พ.ร.บ. เชิงพาณิชย์เกิน 20 ที่นั่ง": price.act_commerce_more20_price,
                    ค่าจดทะเบียนบุคคลธรรมดา: price.register_normal,
                    ทุนค่าจดทะเบียนบุคคลธรรมดาที่จ่ายตัวแทน: price.register_fun_normal_to_company,
                    ทุนค่าจดทะเบียนบุคคลธรรมดาที่จ่ายขนส่ง: price.register_fun_normal_to_delivery,
                    ค่าจดทะเบียนนิติบุคคล: price.coporation,
                    ทุนค่าจดทะเบียนนิติบุคคลที่จ่ายตัวแทน: price.register_fun_coporation_to_company,
                    ทุนค่าจดทะเบียนนิติบุคคลที่จ่ายขนส่ง: price.register_fun_coporation_to_delivery,
                    INNER: price.inner,
               };
          });
          if (data.length > 0) {
               const xls = new XlsExport(data, "ข้อมูลรุ่นรถยนต์ " + serie.series_name);
               xls.exportToXLS("ข้อมูลรุ่นรถยนต์ " + serie.series_name + "(" + dayjs(new Date()).format("DD-MM-YYYY") + ")" + ".xls");
          }
     };
     render() {
          let { title, serie, validated, showColorTable, nameColorTable, indexModel, previous_path, newSearch } = this.state;
          // let typeCars = ["Pick-up", "MPV", "PPV", "SUV", "CUV", "HEV", "VAN", "Eco Car", "Compact Car", "Mid-size Car", "Full-size Car"];
          let typeCars = ["Sedan", "Pickup", "Van", "อื่นๆ"];
          const model_table = {
               column: [
                    {
                         dataField: "model_name",
                         text: "รายการ Model ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "model_price",
                         text: "รายการ model_price ",
                         headerClasses: "header-custom __left",
                         formatter: this.parseFloatFormatter,
                    },
                    {
                         dataField: "unit",
                         text: "รายการ Model ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "color",
                         text: "",
                         headerClasses: "header-custom __btn",
                         formatter: this.addFormatter,
                    },
                    {
                         dataField: "remove",
                         text: "",
                         formatter: this.rmModelFormatter,
                         headerClasses: "header-custom __btn",
                    },
               ],
               keyField: "title",
          };

          const color_table = {
               column: [
                    {
                         dataField: "color_name",
                         text: "รายการ Model ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "remove",
                         text: "",
                         formatter: this.rmColorFormatter,
                         headerClasses: "header-custom __btn",
                    },
               ],
               keyField: "title",
          };

          let table_edit = {
               column: [
                    // {
                    //      dataField: "no",
                    //      text: "No.",
                    //      formatter: this.noFormatter,
                    //      headerClasses: "header-custom __left __no",
                    // },
                    {
                         dataField: "detail",
                         text: "ข้อมูลราคา",
                         formatter: this.detailFormatter,
                         formatExtraData: "detail",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "model_code",
                         text: "Model Code",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "model_name",
                         text: "Model Name",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_cc",
                         text: "เครื่องยนต์",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "color_code",
                         text: "Color Code",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "color_tmt",
                         text: "Color TMT",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "Color Name",
                         headerClasses: "header-custom __btn-s",
                    },

                    {
                         dataField: "remove",
                         text: "ลบ",
                         formatter: this.detailFormatter,
                         formatExtraData: "remove",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "status",
                         text: "การใช้งาน",
                         formatter: this.detailFormatter,
                         formatExtraData: "close",
                         headerClasses: "header-custom __right __btn-ss",
                    },
               ],
               data: newSearch ? newSearch : serie?.seriesData || [],
               keyField: "codemodel",
          };
          return (
               <div className="panel">
                    {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} /> : null}
                    {previous_path === "add" ? (
                         <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                              <div className="content __input-panel">
                                   {/* รายละเอียดผู้ซื้อ */}
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>ข้อมูลการจองรถ</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  {/* <>
                                                       <LabelBar type={"title"} title={"รายละเอียดเพิ่มรุ่นรถ"} />
                                                       <Form.Row>
                                                            <Field
                                                                 type={"text"}
                                                                 title={"ชื่อ Series"}
                                                                 name={"series_name"}
                                                                 value={serie?.series_name}
                                                                 placeholder={"กรุณากรอกชื่อ Series"}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"ประเภทรถ"}
                                                                 name={"type"}
                                                                 value={serie?.type}
                                                                 placeholder={"กรุณาเลือกประเภทรถ"}
                                                                 option={typeCars?.map((e) => ({
                                                                      value: e,
                                                                      name: e,
                                                                 }))}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                       </Form.Row>
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"สถานะ : "}
                                                            name={"status"}
                                                            label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                            value={serie?.status}
                                                            style_id="__inline"
                                                            xl={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </>
                                                  <Row className="noMargin">
                                                       <Col md={6} className="noPadding">
                                                            <div className="left">
                                                                 <Form.Row>
                                                                      <Field
                                                                           type={"text"}
                                                                           title={"ชื่อ Model"}
                                                                           name={"namemodel"}
                                                                           value={serie?.namemodel}
                                                                           placeholder={"กรุณากรอกชื่อ Model"}
                                                                           md={6}
                                                                           handleChangeText={this.handleChangeText}
                                                                      />
                                                                      <Field
                                                                           name={"pricemodel"}
                                                                           value={serie?.pricemodel}
                                                                           type={"add"}
                                                                           title={"ราคาเริ่มต้น"}
                                                                           md={6}
                                                                           placeholder={"กรุณากรอกราคาเริ่มต้น"}
                                                                           button={{
                                                                                name: "+ เพิ่ม",
                                                                                onClick: this.handleAddModal,
                                                                                variant: "success",
                                                                           }}
                                                                           // style_id={'custom-btn __beside-btn noPadding'}
                                                                           handleChange={this.handleChangeText}
                                                                      />
                                                                 </Form.Row>

                                                                 <LabelBar type="textheader" title={{ name: "รายการ Model", detail: "" }} />
                                                                 <Table
                                                                      type={"index-list"}
                                                                      column={model_table.column}
                                                                      data={serie.model.map((e) => ({ ...e, unit: "บาท" }))}
                                                                      keyField={model_table.keyField}
                                                                 />
                                                            </div>
                                                       </Col>
                                                       <Col md={6} className="noPadding">
                                                            {showColorTable && (
                                                                 <div className="right">
                                                                      <Field
                                                                           name={"namecolor"}
                                                                           value={serie?.namecolor}
                                                                           type={"add"}
                                                                           title={"ชื่อ Color"}
                                                                           md={12}
                                                                           placeholder={"กรุณากรอกชื่อ Color"}
                                                                           button={{
                                                                                name: "+ เพิ่ม",
                                                                                onClick: this.handleAddColor,
                                                                                variant: "success",
                                                                           }}
                                                                           style_id={"custom-btn __beside-btn noPadding"}
                                                                           handleChange={this.handleChangeText}
                                                                      />
                                                                      <LabelBar
                                                                           type="textheader"
                                                                           title={{
                                                                                name: `Color (${nameColorTable})`,
                                                                                detail: "",
                                                                           }}
                                                                      />
                                                                      <Table
                                                                           type={"index-list"}
                                                                           column={color_table.column}
                                                                           data={serie.model[indexModel].colors}
                                                                           keyField={color_table.keyField}
                                                                      />
                                                                 </div>
                                                            )}
                                                       </Col>
                                                  </Row> */}

                                                  <Row className="noMargin">
                                                       <Field
                                                            type={"text"}
                                                            title={"Series Name"}
                                                            name={"series_name"}
                                                            value={serie?.series_name}
                                                            placeholder={"กรุณากรอกชื่อ Series"}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Series Code"}
                                                            name={"series_code"}
                                                            value={serie?.series_code}
                                                            placeholder={"กรุณากรอกชื่อ Series"}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"ประเภทรถ"}
                                                            name={"type"}
                                                            value={serie?.type}
                                                            placeholder={"เลือกประเภทรถ"}
                                                            option={typeCars?.map((e) => ({
                                                                 value: e,
                                                                 name: e,
                                                            }))}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Row>
                                                  <LabelBar
                                                       type={"radio"}
                                                       title={"สถานะ : "}
                                                       name={"status"}
                                                       label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                       value={serie?.status}
                                                       style_id="__inline"
                                                       xl={6}
                                                       handleChangeText={this.handleChangeText}
                                                  />
                                                  <Row className="noMargin">
                                                       <Field
                                                            type={"text"}
                                                            title={"Model Code"}
                                                            name={"model_code"}
                                                            value={serie?.model_code}
                                                            placeholder={"กรุณากรอก Model Code"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Model Name"}
                                                            name={"model_name"}
                                                            value={serie?.model_name}
                                                            placeholder={"กรุณากรอกชื่อ Model Name"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"เครื่องยนต์"}
                                                            name={"engine"}
                                                            value={serie?.engine}
                                                            placeholder={"กรุณากรอกเครื่องยนต์"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Row>
                                                  <Row className="noMargin">
                                                       <Field
                                                            type={"text"}
                                                            title={"Color Code"}
                                                            name={"color_code"}
                                                            value={serie?.color_code}
                                                            placeholder={"กรุณากรอก Color Code"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Color TMT"}
                                                            name={"color_tmt"}
                                                            value={serie?.color_tmt}
                                                            placeholder={"กรุณากรอก Color TMT"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"add"}
                                                            title={"Color Name"}
                                                            name={"color_name"}
                                                            value={serie?.color_name}
                                                            placeholder={"กรุณากรอก Color Name"}
                                                            md={3}
                                                            handleChange={this.handleChangeText}
                                                            button={{
                                                                 name: "+ เพิ่ม",
                                                                 onClick: this.addCar,
                                                                 variant: "success",
                                                            }}
                                                       />
                                                       <div className="content" id="table-content">
                                                            <Table
                                                                 type={"document"}
                                                                 column={table_edit.column}
                                                                 data={table_edit.data}
                                                                 keyField={table_edit.keyField}
                                                            />
                                                       </div>
                                                  </Row>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        {previous_path === "add" && (
                                             <div className="button-group __submit row">
                                                  <Col xs={6}>
                                                       <Button type="submit">Submit</Button>
                                                  </Col>
                                                  <Col xs={6}>
                                                       <Button onClick={this.handleReset} variant="danger">
                                                            Reset
                                                       </Button>
                                                  </Col>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </Form>
                    ) : (
                         <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>รายละเอียดรุ่นรถ</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  {/* <LabelBar type={"title"} title={"รายละเอียดเพิ่มรุ่นรถ"} /> */}
                                                  <Row className="noMargin">
                                                       <Field
                                                            type={"text"}
                                                            title={"Series Name"}
                                                            name={"series_name"}
                                                            value={serie?.series_name}
                                                            placeholder={"กรุณากรอกชื่อ Series"}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Series Code"}
                                                            name={"series_code"}
                                                            value={serie?.series_code}
                                                            placeholder={"กรุณากรอกชื่อ Series"}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"ประเภทรถ"}
                                                            name={"type"}
                                                            value={serie?.type}
                                                            placeholder={"เลือกประเภทรถ"}
                                                            option={typeCars?.map((e) => ({
                                                                 value: e,
                                                                 name: e,
                                                            }))}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Row>
                                                  <LabelBar
                                                       type={"radio"}
                                                       title={"สถานะ : "}
                                                       name={"status"}
                                                       label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                       value={serie?.status}
                                                       style_id="__inline"
                                                       xl={6}
                                                       handleChangeText={this.handleChangeText}
                                                  />
                                                  <div className="button-group __submit row mt-0 mb-0 pt-0 pb-0">
                                                       <Col xs={6}>
                                                            <Button onClick={this.handleEdit}>บันทึกการแก้ไข</Button>
                                                       </Col>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>รายละเอียดโมเดล</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <Row className="noMargin">
                                                       <Field
                                                            type={"text"}
                                                            title={"Model Code"}
                                                            name={"model_code"}
                                                            value={serie?.model_code}
                                                            placeholder={"กรุณากรอก Model Code"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Model Name"}
                                                            name={"model_name"}
                                                            value={serie?.model_name}
                                                            placeholder={"กรุณากรอกชื่อ Model Name"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"เครื่องยนต์"}
                                                            name={"car_cc"}
                                                            value={serie?.car_cc}
                                                            placeholder={"กรุณากรอกเครื่องยนต์"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Row>
                                                  <Row className="noMargin">
                                                       {/* <Col md={6} className="noPadding"> */}
                                                       {/* <div className="left"> */}
                                                       {/* <Form.Row> */}
                                                       <Field
                                                            type={"text"}
                                                            title={"Color Code"}
                                                            name={"color_code"}
                                                            value={serie?.color_code}
                                                            placeholder={"กรุณากรอก Color Code"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Color TMT"}
                                                            name={"color_tmt"}
                                                            value={serie?.color_tmt}
                                                            placeholder={"กรุณากรอก Color TMT"}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"add"}
                                                            title={"Color Name"}
                                                            name={"color_name"}
                                                            value={serie?.color_name}
                                                            placeholder={"กรุณากรอก Color Name"}
                                                            md={3}
                                                            handleChange={this.handleChangeText}
                                                            button={{
                                                                 name: "+ เพิ่ม",
                                                                 onClick: this.handleAddCarDetail,
                                                                 variant: "success",
                                                            }}
                                                       />
                                                  </Row>
                                                  <div style={{ margin: "20px 0" }} className="-padding">
                                                       <Field
                                                            type={"add"}
                                                            title={"Model Code"}
                                                            name={"search_model_code"}
                                                            // value={serie?.color_code}
                                                            placeholder={"กรุณากรอก Model Code"}
                                                            md={3}
                                                            handleChange={(e) => this.handleChangeSearch(e)}
                                                            button={{
                                                                 name: "ค้นหา",
                                                                 onClick: this.onClickSearch,
                                                                 variant: "primary",
                                                            }}
                                                       />
                                                       <Col md={3}>
                                                            <Button onClick={this.downloadData}>ดาวโหลดข้อมูล</Button>
                                                       </Col>
                                                  </div>
                                                  <div className="content" id="table-content">
                                                       <Table
                                                            type={"document"}
                                                            column={table_edit.column}
                                                            data={table_edit.data}
                                                            keyField={table_edit.keyField}
                                                       />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Form>
                    )}
               </div>
          );
     }
}
