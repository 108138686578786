import React, { Component } from "react";
import { Breadcrumbs, Field, FormTable, LabelBar, ModalPanel, Sign, Table } from "components/index";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { ip } from "api/index";
import axios from "axios";
import { POST } from "api/index";
import dayjs from "dayjs";
import swal from "sweetalert";
import { Link } from "react-router-dom";

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class GeneratePaymentOrder extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               show_popup: false,
               id: this.props.match.params.id,
               // status: this.props.match.params.status,
               fileURL: "",
               order: {
                    payment_method: "เงินสด",
                    orp_date: dayjs(),
               },
               rowIndex: 0,
               placeholder: {
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    n0: "หลักฐานการชำระเงิน",
                    n1: "หลักฐานการชำระเงิน",
                    n2: "หลักฐานการชำระเงิน",
                    n3: "หลักฐานการชำระเงิน",
                    n4: "หลักฐานการชำระเงิน",
               },
               slectPay: [
                    {
                         payment_method: "เงินสด",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "เงินโอน",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "เช็ค",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "บัตรเครดิต",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "รับชำระโดย อื่น ๆ",
                         detail: "",
                         price: 0,
                         file: "",
                         orderleasingPdf: "",
                    },
               ],
               test: true,
               checkTotal: 0,
               total: 0,
               showmodal_file: false,
               row: {},
               loading: false,
          };
     }

     componentDidMount = async () => {
          await this.getOrder();
          this.setPath();
          this.getOrderLeasingPdf();
     };

     setPath = async () => {
          let { id, status, order } = this.state;
          try {
               if (order?.orp_status === "รับเงินจองแล้ว") {
                    this.downloadPdf();
                    this.setState({ order: { ...(await POST("/order/this_order_payment", { order_id: id })) } });
               }
          } catch (err) {
               console.log("error: ", err);
          }

          // if (status === "รับเงินจองแล้ว") {
          //      this.downloadPdf();
          //      this.setState({ order: { ...(await POST("/order/this_order_payment", { order_id: id })) } });
          // } else if (status === "รอรับเงินจอง") {
          //      this.getOrder();
          // }
     };

     getOrder = async () => {
          let { slectPay } = this.state;
          let date = new Date();
          try {
               let order = await POST("/order/this", { order_id: this.state.id });
               // console.log(`order`, order);
               // order.orp_date = dayjs(new Date()).format("YYYY-MM-DD");
               order.orp_date = dayjs(new Date());
               slectPay.map((el, i) => {
                    slectPay[0].price = order.order_price;
                    slectPay[i].check = false;
               });

               let detailPayment = [];
               let total = 0;

               if (order.orp_status === "รับเงินจองแล้ว" || order.orp_code) {
                    for (let e of JSON.parse(order.orp_payment_method)) {
                         if (e.check) {
                              detailPayment.push(e);
                              total += Number(e.price);
                         }
                    }
               }

               this.setState({ order: { ...order }, slectPay, detailPayment, total: total, orp_code: order.orp_code });
          } catch (error) {
               console.log("error", error);
          }
     };

     downloadPdf = async () => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/order_payment_tax_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              fileURL: fileURL,
                              // spinner: false,
                              // show_modal_pdf: true
                         });
                    });
          } catch (error) {
               console.log(error);
          }
     };

     getOrderLeasingPdf = async () => {
          try {
               await axios
                    .post(
                         `${ip}/order/order_leadsing_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              orderleasingPdf: fileURL,
                              // spinner: false,
                              // show_modal_pdf: true
                         });
                    });
          } catch (error) {}
     };

     saveSign = async (sigCanvas) => {
          let { order, id, slectPay, rowIndex, orp_code } = this.state;
          this.setState({ show_popup: false, loading: true });
          try {
               if (!orp_code) {
                    let base64 = sigCanvas.toDataURL("image/png");
                    order.orp_sign = base64;
                    // for (let i in slectPay) {
                    //      delete slectPay[i].payment_method;
                    // }
                    let payment_method = JSON.stringify(slectPay);
                    order.orp_payment_method = payment_method;
                    order.orp_date = dayjs(order?.orp_date).format("YYYY-MM-DD");
                    order.orp_payment_method = payment_method;
                    order.orp_date = dayjs(order?.orp_date).format("YYYY-MM-DD");
                    await POST("/order/add_receipt_payment", { ...order, orp_status: "รับเงินจองแล้ว", orp_code });

                    if (slectPay[1].file && slectPay[1].check) {
                         let formData1 = new FormData();
                         formData1.append("orp_id", order.orp_id);
                         formData1.append("file", slectPay[1].file);
                         formData1.append("index", 1);
                         await POST("/order/file_receipt_payment", formData1, true);
                    }
                    if (slectPay[3].file && slectPay[3].check) {
                         let formData3 = new FormData();
                         formData3.append("orp_id", order.orp_id);
                         formData3.append("file", slectPay[3].file);
                         formData3.append("index", 3);
                         await POST("/order/file_receipt_payment", formData3, true);
                    }
                    if (slectPay[4].file && slectPay[4].check) {
                         let formData4 = new FormData();
                         formData4.append("orp_id", order.orp_id);
                         formData4.append("file", slectPay[4].file);
                         formData4.append("index", 4);
                         await POST("/order/file_receipt_payment", formData4, true);
                    }
               } else {
                    await POST("/order/add_receipt_payment", { ...order, orp_status: "รับเงินจองแล้ว", orp_code });
               }

               swal({
                    text: "รับเงินจองเรียบร้อยแล้ว",
                    icon: "success",
                    buttons: {
                         submit: "ยืนยัน",
                    },
               }).then((value) => {
                    window.location.reload();
               });
               // swal({
               //      text: "รอ API",
               //      icon: "warning",
               //      button: "ตกลง",
               //      dangerMode: true,
               // });
               /*
               order.orp_payment_method = slectPay[rowIndex].payment_method;
               order.orp_detail = slectPay[rowIndex].detail;
               order.orp_price = slectPay[rowIndex].price;
               // order.orp_file = slectPay[rowIndex].file

               await POST("/order/add_receipt_payment", { ...order, orp_status: "รับเงินจองแล้ว" });

               if (slectPay[rowIndex].file) {
                    let formData = new FormData();
                    formData.append("orp_id", order.orp_id);
                    formData.append("file", slectPay[rowIndex].file);
                    await POST("/order/file_receipt_payment", formData, true);
               }

               this.setState({
                    show_popup: !this.state.show_popup,
                    order,
                    // status: 'รับเงินจองแล้ว'
               });

               window.location.assign(`/admin/payment/order/${id}`);
               */
          } catch (error) {
               swal({
                    text: error.length > 50 ? "กรุณาทำรายการใหม่" : error,
                    icon: "error",
                    buttons: {
                         submit: "ยืนยัน",
                    },
               }).then((value) => {
                    // this.setState({ loading: false });
                    window.location.reload();
               });
               console.log(error);
          }
     };
     clear = (sigCanvas) => {
          sigCanvas.clear();
     };

     openPopup = (event) => {
          let { order, slectPay, rowIndex } = this.state;
          event.preventDefault();
          const form = event.currentTarget;

          let check_status = slectPay.find((el) => el.check === true);
          let price_status = !slectPay.find((el) => el.check === true && (el.price === 0 || el.price === ""));

          // if (form.checkValidity() === false || !check_status) {
          if (!check_status) {
               swal({
                    text: "กรุณาเลือกวิธีชำระเงิน",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               event.stopPropagation();
          } else {
               if (price_status) {
                    if (slectPay[1].check && slectPay[1].file === "") {
                         swal({
                              text: "กรณีเงินโอน กรุณาแนบหลักฐานการชำระเงิน",
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    } else {
                         this.setState({
                              validated: true,
                              show_popup: !this.state.show_popup,
                         });
                    }
               } else {
                    swal({
                         text: "กรุณากรอกจำนวนเงินให้ครบ",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }

          /* อันเดิม
          if (form.checkValidity() === false || order.payment_method === undefined) {
               swal({
                    text: "กรอกให้ครบ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               event.stopPropagation();
          } else {
               if (
                    slectPay[rowIndex].payment_method === "เงินโอน"
                    // slectPay[rowIndex].payment_method === "บัตรเครดิต" ||
                    // slectPay[rowIndex].payment_method === "รับชำระโดย อื่น ๆ"
               ) {
                    if (slectPay[rowIndex].file === "") {
                         swal({
                              text: "กรุณาแนบหลักฐานการชำระเงิน",
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    } else {
                         let { id, show_popup } = this.state;

                         // if (show_popup) {
                         //      window.location.assign(`/admin/payment/order/รับเงินจองแล้ว/${id}`);
                         // }
                         this.setState({
                              validated: true,
                              show_popup: !this.state.show_popup,
                         });
                    }
               } else {
                    let { id, show_popup } = this.state;

                    // if (show_popup) {
                    //      window.location.assign(`/admin/payment/order/รับเงินจองแล้ว/${id}`);
                    // }
                    this.setState({
                         validated: true,
                         show_popup: !this.state.show_popup,
                    });
               }
          }
          */
     };

     viewFile = (row) => {
          this.setState({ showmodal_file: true, row: row });
     };
     printSlip = () => {
          window.open(this.state.fileURL);
     };
     /* ------------------------ onChange ----------------------------- */
     handleChangeText = ({ target: { name, value } }) => {
          let { order } = this.state;
          order[name] = value;
          this.setState({ order });
     };

     handleChangeTextSelect = (e, rowIndex) => {
          let { slectPay } = this.state;
          slectPay[rowIndex].detail = e.target.value;
          // this.setState({ slectPay });
     };
     handleChangeNumber = (value, rowIndex) => {
          let { slectPay, order } = this.state;
          slectPay[rowIndex].price = value.value;
          this.setState({ slectPay });
     };
     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          let { order, slectPay } = this.state;
          ///check
          if (checked) {
               // order.payment_method = row.payment_method;
               slectPay[rowIndex].check = true;
               this.setState({ order, rowIndex: rowIndex, slectPay });
          } else {
               slectPay[rowIndex].check = false;
               this.setState({ slectPay });
          }
     };

     handleSelectDate = (date, dateString, name) => {
          let { order } = this.state;
          order[name] = date;
          this.setState({ ...order, orpDate: date });
     };

     handleChangeFile = (e, rowIndex) => {
          let { slectPay, placeholder } = this.state;

          if (e.target.files.length !== 0) {
               const fileUploaded = e.target.files[0];
               slectPay[rowIndex].file = fileUploaded;
               placeholder[`n${rowIndex}`] = e.target.files[0].name;
               placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

               this.setState({
                    slectPay,
                    placeholder,
               });
          }
     };

     totalPay = () => {
          let { slectPay, order } = this.state;
          let total = 0;
          for (let i in slectPay) {
               if (slectPay[i].check) total += Number(slectPay[i].price);
          }
          total = (order.order_price - total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return total;
     };

     /* ------------------------ Formatter ---------------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { order } = this.state;
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: order.payment_method }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
               />
               // อย่าพึ่งลบ

               // <Form.Check
               //      type="radio"
               //      name={"payment_method"}
               //      value={order.payment_method}
               //      require={true}
               //      onChange={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
               // />
          );
     };

     inputFormFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { slectPay } = this.state;
          if (row.payment_method === "เช็ค") {
               return (
                    <Field
                         type={"text"}
                         name={"detail"}
                         placeholder={slectPay[rowIndex].detail === "" ? "เลขที่" : slectPay[rowIndex].detail}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, rowIndex)}
                         // require={formatExtraData === "เช็ค" ? true : false}
                    />
               );
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"text"}
                         name={"detail"}
                         placeholder={slectPay[rowIndex].detail === "" ? "ระบุ" : slectPay[rowIndex].detail}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, rowIndex)}
                         // require={formatExtraData === "รับชำระโดย อื่น ๆ" ? true : false}
                    />
               );
          } else {
               return (
                    <Field
                         type={"text"}
                         name={"detail"}
                         placeholder={slectPay[rowIndex].detail === "" ? "หมายเหตุ" : slectPay[rowIndex].detail}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, rowIndex)}
                         // require={formatExtraData === "รับชำระโดย อื่น ๆ" ? true : false}
                    />
               );
          }
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={"orp_price"}
                    value={row?.price}
                    placeholder={"0.00"}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangeNumber(e, rowIndex)}
                    require={false}
               />
          );
     };

     inputFileFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { placeholder } = this.state;

          switch (row.payment_method) {
               case "เงินโอน":
                    if (!formatExtraData[1] === formatExtraData[1]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n1}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "บัตรเครดิต":
                    if (!formatExtraData[3] === formatExtraData[3]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n3}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "รับชำระโดย อื่น ๆ":
                    if (!formatExtraData[4] === formatExtraData[4]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n4}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               default:
                    if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                         return (
                              <Field
                                   type={"file"}
                                   // name={row.payment_method}
                                   placeholder={"หลักฐานการชำระเงิน"}
                                   value={null}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __beside-btn"}
                                   handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                   accept={".png,.jpg,.pdf"}
                              />
                         );
                    } else if (row.payment_method === "เช็ค") {
                         return (
                              <span className="warning __info">
                                   ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                              </span>
                         );
                    } else {
                         return <></>;
                    }
          }
     };

     // viewFileFormatter = (cell, row) => {
     //      if (cell) {
     //           return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
     //      } else {
     //           return <span>-</span>;
     //      }
     // };
     viewFileFormatter = (cell, row) => {
          let arr = row.file.split(".")[1];
          if (cell) {
               if (arr === "pdf") {
                    return <a onClick={() => window.open(`${ip}/static/${row.file}`)}>หลักฐานการชำระเงิน</a>;
               } else {
                    return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
               }
          } else {
               return <span>-</span>;
          }
     };

     render() {
          let { show_popup, id, status, fileURL, order, validated, orderleasingPdf, slectPay, placeholder, detailPayment, row, orp_code, loading } =
               this.state;
          // console.log(`orp_code`, orp_code);
          let total_price = Number(order?.order_price);
          let total_pay = 0;

          let price = "";
          let pay = 0;

          // for (let e of total_price.split(",")) {
          //      price = price.concat(e);
          // }
          for (let i = 0; i < slectPay.length; i++) {
               if (slectPay[i].check) {
                    pay += Number(slectPay[i].price);
               } else {
                    pay += 0;
               }
          }
          price = Number(price);
          pay = Number(pay);

          let table = {
               column: [
                    {
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __no",
                         footer: "",
                    },
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "กรอกข้อมูล",
                         formatter: this.inputFormFormatter,
                         formatExtraData: order?.payment_method,
                         footer: "ยอดคงเหลือจ่าย",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatter,
                         formatExtraData: order?.payment_method,
                         footer: this.totalPay,
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.inputFileFormatter,
                         headerClasses: "header-custom __right",
                         formatExtraData: [placeholder.p0, placeholder.p1, placeholder.p2, placeholder.p3, placeholder.p4],
                         footer: "บาท",
                    },
               ],
               data: slectPay,
               keyField: "payment_method",
          };

          let table_detail_payment = {
               column: [
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "รายละเอียด",
                         formatter: (e) => (e ? e : "-"),
                         footer: "รวมทั้งสิ้น",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
                         footer: Number(this.state.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.viewFileFormatter,
                         headerClasses: "header-custom __right",
                         footer: "บาท",
                    },
               ],
               data: detailPayment ? detailPayment : [],
               keyField: "payment_method",
          };

          switch (order?.orp_status) {
               case "รอรับเงินจอง":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `การรับเงินจอง`,
                                             onClick: () => window.location.assign("/admin/payment/order"),
                                        },
                                        {
                                             title: `ใบจองรถยนต์ (${order?.order_code_id})`,
                                             onClick: () => window.location.assign(`/admin/order/car/detail${order.order_id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"รายการ"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "ชื่อลูกค้า : ",
                                                                      detail:
                                                                           (order.userinfo_prefix ? order.userinfo_prefix : "") +
                                                                                order?.userinfo_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: order?.userinfo_idcard || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "ที่อยู่ :",
                                                                      detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "โทรศัพท์ :",
                                                                      // detail: order?.userinfo_tel || "-",
                                                                      detail: order?.userinfo_tel ? this.telFormat(String(order?.userinfo_tel)) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: `${order?.order_code_id || "-"}`,
                                                                 }}
                                                                 md={6}
                                                                 onClickLabelBar={() => {
                                                                      window.open(orderleasingPdf);
                                                                 }}
                                                                 // onClickLabelBar={() => window.location.assign(`/admin/order/car/detail${order.order_id}`)}
                                                                 textHeader_style={"underline"}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                             </div>
                                             <div className="content" id="table-content">
                                                  <FormTable order={order} type="order-payment" />
                                             </div>
                                        </div>
                                   </div>
                                   {orp_code && orp_code.length > 0 ? (
                                        <div className="input-field">
                                             <div className="-body">
                                                  <div className="content">
                                                       <LabelBar type={"title"} title={"ออกใบเสร็จรับเงิน"} />
                                                       <div className="-padding">
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader-genpayment"}
                                                                      title={{
                                                                           name: "วันที่ออกใบเสร็จรับเงิน :",
                                                                           detail: order?.orp_date ? dateFormatter(order?.orp_date) : "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                       </div>
                                                       <div className="content checkcenter" id="table-content" style={{ padding: "0 15px" }}>
                                                            <Table
                                                                 type={"summary"}
                                                                 column={table_detail_payment.column}
                                                                 data={table_detail_payment.data}
                                                                 keyField={table_detail_payment.keyField}
                                                            />
                                                       </div>
                                                  </div>
                                                  <div className="button-group __submit row">
                                                       <Col xs={6}>
                                                            <Button onClick={() => this.saveSign()}>ข้าม</Button>
                                                       </Col>
                                                  </div>
                                                  <ModalPanel
                                                       show={this.state.showmodal_file}
                                                       title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                       type={"image"}
                                                       image={`${ip}/static/${row.file}`}
                                                       onHide={() => this.setState({ showmodal_file: false })}
                                                  />
                                             </div>
                                        </div>
                                   ) : (
                                        <Form noValidate validated={validated} onSubmit={this.openPopup}>
                                             <div className="input-field">
                                                  <div className="-body">
                                                       <div className="content">
                                                            <LabelBar type={"title"} title={"ออกใบเสร็จรับเงิน"} />
                                                            <Field
                                                                 type={"date"}
                                                                 title={"วันที่ออกใบเสร็จ"}
                                                                 name={"orp_date"}
                                                                 placeholder={"เลือกวันที่"}
                                                                 require={true}
                                                                 formatDate={"DD/MM/YYYY"}
                                                                 md={3}
                                                                 value={this.state.orpDate || dayjs(new Date())}
                                                                 handleSelectDate={this.handleSelectDate}
                                                            />
                                                       </div>
                                                       <div className="content checkcenter" id="table-content">
                                                            {/* {this.renderTable()} */}
                                                            <Table
                                                                 type={"summary"}
                                                                 column={table.column}
                                                                 data={table.data}
                                                                 keyField={table.keyField}
                                                            />
                                                       </div>

                                                       {/* Button Panel */}
                                                       <div className="button-group __submit row">
                                                            <Col xs={6}>
                                                                 {loading ? (
                                                                      <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                           <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                           />
                                                                           Loading...
                                                                      </Button>
                                                                 ) : (
                                                                      <Button disabled={!(pay === order.order_price)} type="submit">
                                                                           ยืนยันการรับเงินจอง
                                                                      </Button>
                                                                 )}
                                                            </Col>
                                                       </div>

                                                       <ModalPanel
                                                            show={show_popup}
                                                            save={this.saveSign}
                                                            clear={this.clear}
                                                            type={"sign"}
                                                            title={"ยืนยันการรับเงินจอง"}
                                                            // sub_title={'ลายเซ็นลูกค้า'}
                                                            sub_title={"ลายเซ็นผู้รับเงิน"}
                                                            onHide={() => {
                                                                 this.setState({ show_popup: false, validated: false });
                                                            }}
                                                            // onClick={this.openPopup}
                                                       />
                                                  </div>
                                             </div>
                                        </Form>
                                   )}
                              </div>
                         </div>
                    );
               case "รับเงินจองแล้ว":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `การรับเงินจอง`,
                                             onClick: () => window.location.assign("/admin/payment/order"),
                                        },
                                        {
                                             title: `ใบจองรถยนต์ (${order?.order_code_id})`,
                                             onClick: () => window.location.assign(`/admin/order/car/detail${order.order_id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"รายการ"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "ชื่อลูกค้า : ",
                                                                      detail: order?.userinfo_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: order?.userinfo_idcard || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "ที่อยู่ :",
                                                                      detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "โทรศัพท์ :",
                                                                      detail: order?.userinfo_tel ? this.telFormat(String(order?.userinfo_tel)) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>

                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: `${order?.order_code_id}`,
                                                                 }}
                                                                 md={6}
                                                                 onClickLabelBar={() => {
                                                                      window.open(orderleasingPdf);
                                                                 }}
                                                                 // onClickLabelBar={() => window.location.assign(`/admin/order/car/detail${order.order_id}`)}
                                                                 textHeader_style={"underline"}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                             </div>
                                             <div className="content" id="table-content">
                                                  <FormTable order={order} type="order-payment" />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ออกใบเสร็จรับเงิน"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "วันที่ออกใบเสร็จรับเงิน :",
                                                                      detail: order?.orp_date ? dateFormatter(order?.orp_date) : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>

                                                       {/* <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชำระโดย :",
                                                                      detail: order?.orp_payment_method || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                       {order.orp_payment_method === "เงินสด" ? null : (
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "หลักฐานการชำระเงิน :",
                                                                           detail: "ดู",
                                                                      }}
                                                                      onClickLabelBar={() => {
                                                                           window.open(order?.orp_file);
                                                                      }}
                                                                      textHeader_style={"detail underline"}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                       )}

                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "จำนวนเงิน :",
                                                                      detail:
                                                                           `${Number(order?.orp_price || 0).toLocaleString(undefined, {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2,
                                                                           })} บาท` || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row> */}
                                                  </div>
                                             </div>
                                             <div className="content checkcenter" id="table-content" style={{ padding: "0 15px" }}>
                                                  {/* {this.renderTable()} */}
                                                  <Table
                                                       type={"summary"}
                                                       column={table_detail_payment.column}
                                                       data={table_detail_payment.data}
                                                       keyField={table_detail_payment.keyField}
                                                  />
                                             </div>
                                             <ModalPanel
                                                  show={this.state.showmodal_file}
                                                  title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                  type={"image"}
                                                  image={`${ip}/static/${row.file}`}
                                                  onHide={() => this.setState({ showmodal_file: false })}
                                             />
                                             <div>
                                                  <Breadcrumbs
                                                       under={true}
                                                       type={"success"}
                                                       title={"รับเงินจองแล้ว"}
                                                       button={{ onClick: this.printSlip, name: "พิมพ์ใบเสร็จรับเงิน", variant: "outline-primary" }}
                                                  />
                                                  <div style={{ height: "80px" }}></div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* <div className='input-field'>
                                <div className='-body'>
                                    <div className='content'>
                                        <div className='-padding'>
                                            <a className="button" href={fileURL} target="_blank" rel="noopener noreferrer">พิมพ์ใบเสร็จรับเงิน</a>
                                            <Button onClick={this.downloadPdf}>download</Button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                              </div>
                         </div>
                    );

               default:
                    return <div></div>;
          }
     }
}
