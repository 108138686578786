import { Breadcrumbs, Search, Skeleton, Table } from "components";
import React, { Component } from "react";
import dayjs from "dayjs";
import { GET } from "api/index";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export default class FormDeliver extends Component {
     constructor(props) {
          super(props);
          this.state = {
               orderpayment: [],
               order: [],
               search: {
                    acept_status: "ส่งมอบรถแล้ว",
               },
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getOrder();
     };

     getOrder = async () => {
          try {
               this.setState({ loading: true });
               let result = await GET("/order/get_to_delivery");
               let order = result.filter((el) => el.acept_status === "ส่งมอบรถแล้ว" || el.acept_status === "ปิดการขาย");
               this.setState({ order, order_search: result, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/payment/form/${row.order_id}`)}>ทำรายการ</a>;
     }

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };

     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({ ...search });
     };

     /*----------------------Search Data--------------------------*/
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter((el) =>
                    search.acept_status ? (search.acept_status === "ทั้งหมด" ? el : String(el.acept_status) === String(search.acept_status)) : el
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el) &&
                         (search.year_book ? String(dayjs(el.created_at)).indexOf(String(search.year_book)) > -1 : el) &&
                         (search.month_book ? String(dayjs(el.created_at).format("YYYY-MM-DD")).indexOf(String(search.month_book)) > -1 : el) &&
                         (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
               );
          this.setState({ newSearch });
     };

     render() {
          let { order, search, newSearch } = this.state;
          const table = {
               column: [
                    {
                         dataField: "form",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.orderFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "acept_status",
                         text: "สถานะใบจอง",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "พนักงานขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-ml __right",
                         formatter: (e) => (e ? e : "-"),
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "preemptionp_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "แบบฟอร์มสำหรับส่งมอบ" }]} active={0} button={""} />
                    <div className="content __main-panel form">
                         <Search
                              type={"form"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
