import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Skeleton, Img } from "components/index";
import { GET } from "api/api";
import dayjs from "dayjs";
import { getToken } from "../../../../../static/function";

export default class RegisterLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {},
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getRegisterLabel();
     };

     getRegisterLabel = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/order_register");
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               let order = order_search;
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/label/register/${row.order_id}`)}>ตรวจสอบเอกสาร</a>;
     }

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
          // console.log(search);
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter((el) => (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el))
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     render() {
          let { order, newSearch, search } = this.state;
          const table = {
               column: [
                    {
                         dataField: "check",
                         text: "ตรวจสอบเอกสาร",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-s",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         // headerClasses: "header-custom __left",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "พนักงานขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-ml",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "id_tic",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "การจดทะเบียน" }]} active={0} button={""} />
                    <div className="content __main-panel registerlabel">
                         <Search
                              type={"registerlabel"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
