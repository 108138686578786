import React, { Component } from "react";
import { Breadcrumbs, LabelBar, Field, Table, FormTable, ModalPanel } from "../../../../../../components";
import { Row, Col, Button, Form } from "react-bootstrap";
import { POST, ip } from "api/index";
import { add } from "../../../../../../static/function";
import swal from "sweetalert";
import dayjs from "dayjs";
import axios from "axios";
function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}
export default class GeneratePaymentAcc extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               payment_status: this.props.match.params.status,
               id: this.props.match.params.id,
               order: {},
               openModal: false,
               accp_date: dayjs(new Date()),
               rowIndex: -1,
               selectPay: [
                    {
                         payment_method: "เงินสด",
                         price: 0,
                    },
                    {
                         payment_method: "เงินโอน",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "เช็ค",
                         detail: "",
                         price: 0,
                    },
                    {
                         payment_method: "บัตรเครดิต",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "รับชำระโดย อื่น ๆ",
                         detail: "",
                         price: 0,
                         file: "",
                    },
               ],
               placeholder: {
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    n0: "หลักฐานการชำระเงิน",
                    n1: "หลักฐานการชำระเงิน",
                    n2: "หลักฐานการชำระเงิน",
                    n3: "หลักฐานการชำระเงิน",
                    n4: "หลักฐานการชำระเงิน",
               },
               total_acc: 0,
               showmodal_file: false,
               row: {},
               total: 0,
               modal_sign: false,
          };
     }

     componentDidMount = async () => {
          this.getOrder();
     };

     /* Call Api*/
     getOrder = async () => {
          let { order, accp_date, selectPay } = this.state;
          try {
               let result = await POST("/order/this_acc_order_payment", { acorder_id: this.state.id });
               result.accp_date = result.accp_date ? result.accp_date : accp_date;

               let price_acc = 0;
               let vat_acc = 0;
               let total_acc = 0;
               for (let i in result?.item) {
                    price_acc += result.item[i].amount ? result.item[i].amount * result.item[i].items_price : 1 * result.item[i].items_price;
               }
               vat_acc = (price_acc * 7) / 100;
               total_acc = price_acc;

               selectPay.map((el, i) => {
                    selectPay[0].price = total_acc;
                    selectPay[i].check = false;
               });

               let detailPayment = [];
               let total = 0;

               if (result.accp_status === "รับเงินค่าอุปกรณ์แล้ว") {
                    for (let e of JSON.parse(result.accp_payment_method)) {
                         if (e.check) {
                              detailPayment.push(e);
                              total += Number(e.price);
                         }
                    }
               }

               this.setState({ order: { ...result }, selectPay, total_acc: total_acc, detailPayment, total: total });
          } catch (error) {
               console.log(error);
          }
     };

     addAccRecieptPaymentAPI = async (sign) => {
          let { rowIndex, selectPay, accp_date, order, id } = this.state;

          let accept = {
               accp_id: order.accp_id,
               accp_payment_method: JSON.stringify(selectPay),
               accp_sign: null,
               accp_date: dayjs(order.accp_date).format("YYYY-MM-DD"),
               accp_status: "รับเงินค่าอุปกรณ์แล้ว",
               branch_id: order.branch_id,
               acorder_id: order.acorder_id,
               accp_sign: sign,
          };

          try {
               await POST("/order/add_acc_receipt_payment", accept);
               if (selectPay[1].file && selectPay[1].check) {
                    let formData1 = new FormData();
                    formData1.append("accp_id", order.accp_id);
                    formData1.append("file", selectPay[1].file);
                    formData1.append("index", 1);
                    await POST("/order/file_acc_receipt_payment", formData1, true);
               }
               if (selectPay[3].file && selectPay[3].check) {
                    let formData3 = new FormData();
                    formData3.append("accp_id", order.accp_id);
                    formData3.append("file", selectPay[3].file);
                    formData3.append("index", 3);
                    await POST("/order/file_acc_receipt_payment", formData3, true);
               }
               if (selectPay[4].file && selectPay[4].check) {
                    let formData4 = new FormData();
                    formData4.append("accp_id", order.accp_id);
                    formData4.append("file", selectPay[4].file);
                    formData4.append("index", 4);
                    await POST("/order/file_acc_receipt_payment", formData4, true);
               }

               swal({
                    text: "ยืนยันรับเงินค่าอุปกรณ์เรียบร้อยแล้ว",
                    icon: "success",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    window.location.assign(`/admin/payment/product/acc/${id}`);
               });
          } catch (error) {
               console.log("error", error);
          }

          // if (rowIndex >= 0 && rowIndex <= 4) {
          //      // No detail field api: selectPay[rowIndex].detail
          //      try {
          //           await POST("/order/add_acc_receipt_payment", {
          //                accp_id: order.accp_id,
          //                accp_payment_method: selectPay[rowIndex].payment_method,
          //                accp_price: selectPay[rowIndex].price,
          //                accp_sign: null,
          //                accp_date: accp_date,
          //                accp_status: "รับเงินค่าอุปกรณ์แล้ว",
          //                branch_id: order.branch_id,
          //                acorder_id: order.acorder_id,
          //           });
          //           if (selectPay[rowIndex].file) {
          //                let formData = new FormData();
          //                formData.append("accp_id", order.accp_id);
          //                formData.append("file", selectPay[rowIndex].file);
          //                await POST("/order/file_acc_receipt_payment", formData, true);
          //           }

          //           swal({
          //                text: "ยืนยันรับเงินค่าอุปกรณ์เรียบร้อยแล้ว",
          //                icon: "success",
          //                buttons: {
          //                     submit: "เสร็จสิ้น",
          //                },
          //           }).then((value) => {
          //                window.location.assign(`/admin/payment/product/acc/${id}`);
          //           });
          //      } catch (err) {
          //           console.log(err);
          //      }
          // }
     };

     /* Open popup */
     // openPopup = (event) => {
     //     let { id, show_popup } = this.state
     //     let validity = this.validateForm()
     //     if (validity) {
     //         this.setState({ show_popup: !this.state.show_popup })
     //         if (show_popup) {
     //             this.addAccRecieptPaymentAPI()
     //             window.location.assign(`/admin/payment/product/acc/รับเงินค่าอุปกรณ์แล้ว/${id}`)
     //         }
     //     }
     // }

     validateForm = () => {
          let { rowIndex, accp_date, selectPay } = this.state;
          let isSelectDate = accp_date;
          let isSelectPaymentMethod = rowIndex >= 0 && rowIndex <= 4;
          let paymentDetailValidation = false;
          var er = /^-?[0-9][0-9,\.]+$/;
          try {
               let typeofPrice = er.test(selectPay[rowIndex].price);
               switch (rowIndex) {
                    case 0:
                         paymentDetailValidation = selectPay[rowIndex].price != null;
                         break;
                    case 1:
                         paymentDetailValidation = selectPay[rowIndex].price != null || selectPay[rowIndex].file != null;
                         break;
                    case 2:
                         paymentDetailValidation = selectPay[rowIndex].detail != null || selectPay[rowIndex].price != null;
                         break;
                    case 3:
                         paymentDetailValidation = selectPay[rowIndex].price != null || selectPay[rowIndex].file != null;
                         break;
                    case 4:
                         paymentDetailValidation =
                              selectPay[rowIndex].detail != null || selectPay[rowIndex].price != null || selectPay[rowIndex].file != null;
                         break;
               }

               if (!isSelectDate || !isSelectPaymentMethod || !paymentDetailValidation || !typeofPrice) {
                    let text = "กรอกให้ครบ";
                    if (!typeofPrice) text = "จำนวนเงินต้องเป็นตัวเลขเท่านั้น";
                    // console.log(typeofPrice);
                    swal({
                         text: text,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
                    return false;
               } else {
                    // console.log(selectPay[rowIndex]);
                    return true;
               }
          } catch (e) {
               swal({
                    text: "กรอกให้ครบ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               return false;
          }
     };

     /* ------------------------ onChange ----------------------------- */
     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          // console.log(`${row.payment_method}`, checked);
          let { order, selectPay } = this.state;
          ///check
          if (checked) {
               // order.payment_method = row.payment_method;
               selectPay[rowIndex].check = true;
               this.setState({ order, rowIndex: rowIndex, selectPay });
          } else {
               selectPay[rowIndex].check = false;
               this.setState({ selectPay });
          }
     };

     /* ------------------------ handle ----------------------------- */
     handleSubmit = () => {
          let { id, selectPay, rowIndex } = this.state;
          // let validity = this.validateForm();

          let check_status = selectPay.find((el) => el.check === true);
          let price_status = !selectPay.find((el) => el.check === true && (el.price === 0 || el.price === ""));

          try {
               if (check_status) {
                    if (price_status) {
                         if (selectPay[1].check && selectPay[1].file === "") {
                              swal({
                                   text: "กรณีเงินโอน กรุณาแนบหลักฐานการชำระเงิน",
                                   icon: "warning",
                                   button: "ตกลง",
                                   dangerMode: true,
                              });
                         } else {
                              //OK
                              // this.addAccRecieptPaymentAPI();
                              this.setState({ modal_sign: true });
                              // swal({
                              //      text: "รอ API",
                              //      icon: "warning",
                              //      button: "ตกลง",
                              //      dangerMode: true,
                              // });
                         }
                    } else {
                         swal({
                              text: "กรุณากรอกจำนวนเงินให้ครบ",
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    }
               } else {
                    swal({
                         text: "กรุณาเลือกวิธีชำระเงิน",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } catch (error) {
               console.log("error", error);
          }

          // if (validity) {
          //      try {
          //           if (selectPay[rowIndex].payment_method === "เงินโอน") {
          //                if (selectPay[rowIndex].file) {
          //                     this.addAccRecieptPaymentAPI();
          //                } else {
          //                     swal({
          //                          text: "กรุณาแนบหลักฐานการชำระเงิน",
          //                          icon: "warning",
          //                          button: "ตกลง",
          //                          dangerMode: true,
          //                     });
          //                }
          //           } else {
          //                this.addAccRecieptPaymentAPI();
          //           }
          //      } catch (err) {
          //           console.log(err);
          //      }
          // }
     };

     saveSign = async (sigCanvas) => {
          // let { order, id, slectPay, rowIndex } = this.state;
          let base64 = await sigCanvas.toDataURL("image/png");

          this.addAccRecieptPaymentAPI(base64);
     };

     clear = (sigCanvas) => {
          sigCanvas.clear();
     };

     handleSelectDate = async (date, dateString, name) => {
          let { order } = this.state;
          order.accp_date = date;
          this.setState({ order });
          // await this.setState({ accp_date: date });
     };
     handleChangeTextDetail = (e, index) => {
          let { selectPay } = this.state;
          selectPay[index].detail = e.target.value;
          this.setState({ selectPay });
     };
     handleChangeNumber = (e, index) => {
          let { selectPay } = this.state;
          selectPay[index].price = e.floatValue;
          this.setState({ selectPay });
     };
     handleChangeFile = (e, rowIndex) => {
          let { selectPay, placeholder } = this.state;
          if (e.target.files.length !== 0) {
               const fileUploaded = e.target.files[0];
               selectPay[rowIndex].file = fileUploaded;
               // console.log("fileUploaded ", fileUploaded);

               placeholder[`n${rowIndex}`] = e.target.files[0].name;
               placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

               this.setState({ selectPay, placeholder });
          }
     };

     totalPay = () => {
          let { selectPay, order } = this.state;
          let total = 0;
          for (let i in selectPay) {
               if (selectPay[i].check) total += Number(selectPay[i]?.price || 0);
          }
          let total_acc = 0;
          if (order?.item) {
               total_acc = order.item.map((el) => ({ sum: el.amount ? el.amount * el.items_price : el.items_price })).reduce(add("sum"), 0);
          }
          // console.log(`order.total_acc`, total_acc);
          total = (total_acc - total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return total;
     };

     viewFile = (row) => {
          this.setState({ showmodal_file: true, row: row });
     };

     /* ------------------------ Formatter ---------------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     // viewFileFormatter = (cell, row) => {
     //      console.log("row", row);
     //      if (cell) {
     //           return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
     //      } else {
     //           return <span>-</span>;
     //      }
     // };

     viewFileFormatter = (cell, row) => {
          if (row.file) {
               let arr = row.file.split(".")[1];
               if (cell) {
                    if (arr === "pdf") {
                         return <a onClick={() => window.open(`${ip}/static/${row.file}`)}>หลักฐานการชำระเงิน</a>;
                    } else {
                         return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
                    }
               } else {
                    return <span>-</span>;
               }
          } else {
               return <span>-</span>;
          }
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { order } = this.state;
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: order.orcp_payment_method }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
               />
               // <div style={{ textAlign: "center" }}>
               //      <Form.Check
               //           type="radio"
               //           name={"payment_method"}
               //           value={order.payment_method}
               //           require={true}
               //           onChange={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
               //      />
               // </div>
          );
     };

     inputFormFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.payment_method === "เช็ค") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"เลขที่"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextDetail(e, rowIndex)}
                    />
               );
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"ระบุ"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextDetail(e, rowIndex)}
                    />
               );
          } else {
               return <>-</>;
          }
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    // name={row.payment_method}
                    name={"price"}
                    placeholder={"0.00"}
                    require={true}
                    decimalScale={2}
                    // value={Number(formatExtraData[rowIndex]).toLocaleString(undefined, {
                    //      minimumFractionDigits: 2,
                    //      maximumFractionDigits: 2,
                    // })}
                    value={row?.price}
                    handleChangeNumber={(e) => this.handleChangeNumber(e, rowIndex)}
               />
          );
     };

     inputFileFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { placeholder } = this.state;

          switch (row.payment_method) {
               case "เงินโอน":
                    if (!formatExtraData[1] === formatExtraData[1]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n1}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "บัตรเครดิต":
                    if (!formatExtraData[3] === formatExtraData[3]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n3}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "รับชำระโดย อื่น ๆ":
                    if (!formatExtraData[4] === formatExtraData[4]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n4}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               default:
                    if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                         return (
                              <Field
                                   type={"file"}
                                   // name={row.payment_method}
                                   placeholder={"หลักฐานการชำระเงิน"}
                                   value={null}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __beside-btn"}
                                   handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                   accept={".png,.jpg,.pdf"}
                              />
                         );
                    } else if (row.payment_method === "เช็ค") {
                         return (
                              <span className="warning __info">
                                   ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                              </span>
                         );
                    } else {
                         return <></>;
                    }
          }
     };

     getOrderAccPayPdf = async (type) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/acc_order_payment`,
                         { acorder_id: this.state.id, type },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {}
     };

     render() {
          let { payment_status, id, show_popup, order, validated, placeholder, selectPay, total_acc, detailPayment, row, modal_sign } = this.state;

          let total_price = Number(total_acc).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          let total_pay = this.totalPay();

          let price = "";
          let pay = "";

          for (let e of total_price.split(",")) {
               price = price.concat(e);
          }
          for (let e of total_pay.split(",")) {
               pay = pay.concat(e);
          }
          price = Number(price);
          pay = Number(pay);

          let table_acc = {
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __no",
                         footer: "",
                    },
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         footer: "",
                    },
                    {
                         dataField: "input_form",
                         text: "กรอกข้อมูล",
                         formatter: this.inputFormFormatter,
                         footer: "ยอดคงเหลือจ่าย",
                    },
                    {
                         dataField: "amount",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatter,
                         // formatExtraData: [selectPay[0].price, 0, 0, 0, 0],
                         // formatExtraData: order?.accp_payment_method,
                         footer: this.totalPay(),
                    },
                    {
                         dataField: "slip",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.inputFileFormatter,
                         headerClasses: "header-custom __right",
                         formatExtraData: [placeholder.p0, placeholder.p1, placeholder.p2, placeholder.p3, placeholder.p4],
                         footer: "บาท",
                    },
               ],
               data: selectPay ? selectPay : [],
               keyField: "payment_method",
          };

          let table_detail_payment = {
               column: [
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "รายละเอียด",
                         formatter: (e) => (e ? e : "-"),
                         footer: "รวมทั้งสิ้น",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
                         // footer: "0.00",
                         footer: Number(this.state.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.viewFileFormatter,
                         headerClasses: "header-custom __right",
                         footer: "บาท",
                    },
               ],
               data: detailPayment ? detailPayment : [],
               // data: [],
               keyField: "payment_method",
          };

          switch (order?.accp_status) {
               case "รอชำระเงิน":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รับชำระเงินอุปกรณ์`,
                                             onClick: () => window.location.assign("/admin/payment/acc"),
                                        },
                                        {
                                             title: `การชำระเงินใบสั่งขายอุปกรณ์ (${order?.acorder_code_id})`,
                                             onClick: () => window.location.assign(`/admin/order/acc/detail${order?.acorder_id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"รายการ"} />
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ชื่อลูกค้า : ",
                                                                 detail: order.acorder_name,
                                                            }}
                                                            md={6}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                 detail: order.acorder_idcard,
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ที่อยู่ :",
                                                                 // detail: `${accpayment?.userinfo_address} ถ.XXXXXXXX ตำบล/แขวง ${accpayment?.userinfo_sub_district} อำเภอ/เขต ${accpayment?.userinfo_district} จังหวัด ${accpayment?.userinfo_province} ${accpayment?.userinfo_zipcode}`,
                                                                 detail: `${order?.acorder_address}  ตำบล/แขวง ${order?.acorder_sub_district} อำเภอ/เขต ${order?.acorder_district} จังหวัด ${order?.acorder_province} ${order?.acorder_zipcode}`,
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "โทรศัพท์ :",
                                                                 detail: order.acorder_tel ? this.telFormat(order.acorder_tel) : "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขที่ใบสั่งขายอุปกรณ์ : ",
                                                                 detail: order?.acorder_code_id,
                                                            }}
                                                            md={6}
                                                            onClickLabelBar={() => {
                                                                 window.location.assign(`/admin/order/acc/detail${id}`);
                                                            }}
                                                            textHeader_style={"underline"}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "พนักงานขาย : ",
                                                                 detail: order.saler_name,
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                             </div>
                                             <div className="content" id="table-content">
                                                  <FormTable type="acc-car-payment" order={{ ...order }} />
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ออกใบเสร็จรับเงิน"} />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่ออกใบเสร็จ"}
                                                       placeholder={order.accp_date ? order.accp_date : "เลือกวันที่"}
                                                       md={6}
                                                       formatDate={"DD/MM/YYYY"}
                                                       value={order.accp_date}
                                                       require={true}
                                                       handleSelectDate={this.handleSelectDate}
                                                  />
                                             </div>
                                             <div className="content" id="table-content">
                                                  <div className="-padding">
                                                       <Table
                                                            type={"summary"}
                                                            column={table_acc.column}
                                                            data={table_acc.data}
                                                            keyField={table_acc.keyField}
                                                       />
                                                  </div>
                                             </div>
                                             <div className="button-group __submit row">
                                                  <Col md={4}>
                                                       <Button disabled={pay !== 0} onClick={this.handleSubmit}>
                                                            ยืนยันการรับค่าอุปกรณ์
                                                       </Button>
                                                  </Col>
                                             </div>
                                             <ModalPanel
                                                  show={modal_sign}
                                                  save={this.saveSign}
                                                  clear={this.clear}
                                                  type={"sign"}
                                                  title={"ยืนยันการรับเงินค่าอุปกรณ์"}
                                                  sub_title={"ลายเซ็นลูกค้า"}
                                                  onHide={() => {
                                                       this.setState({ modal_sign: false });
                                                  }}
                                                  // onClick={this.openPopup}
                                             />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );
               case "รับเงินค่าอุปกรณ์แล้ว":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รับชำระเงินอุปกรณ์`,
                                             onClick: () => window.location.assign("/admin/payment/acc"),
                                        },
                                        {
                                             title: `การชำระเงินใบสั่งขายอุปกรณ์ (${order?.acorder_code_id})`,
                                             onClick: () => window.location.assign(`/admin/order/acc/detail${order?.acorder_id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"รายการ"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อลูกค้า : ",
                                                                      detail: order?.prefix + order.acorder_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: order.acorder_idcard,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ :",
                                                                      // detail: `${accpayment?.userinfo_address} ถ.XXXXXXXX ตำบล/แขวง ${accpayment?.userinfo_sub_district} อำเภอ/เขต ${accpayment?.userinfo_district} จังหวัด ${accpayment?.userinfo_province} ${accpayment?.userinfo_zipcode}`,
                                                                      detail: `${order?.acorder_address} ตำบล/แขวง ${order?.acorder_sub_district} อำเภอ/เขต ${order?.acorder_district} จังหวัด ${order?.acorder_province} ${order?.acorder_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โทรศัพท์ :",
                                                                      detail: order.acorder_tel ? this.telFormat(order.acorder_tel) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่ใบสั่งขายอุปกรณ์ : ",
                                                                      detail: order?.acorder_code_id,
                                                                 }}
                                                                 md={6}
                                                                 onClickLabelBar={() => {
                                                                      window.location.assign(`/admin/order/acc/detail${id}`);
                                                                 }}
                                                                 textHeader_style={"underline"}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order.saler_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                             </div>
                                             <div className="content" id="table-content">
                                                  <FormTable type="acc-car-payment" order={{ ...order }} />
                                             </div>
                                        </div>
                                   </div>
                                   <Form noValidate validated={validated} onSubmit={this.openPopup}>
                                        <div className="input-field">
                                             <div className="-body">
                                                  <div className="content">
                                                       <LabelBar type={"title"} title={"ออกใบเสร็จรับเงิน"} />
                                                       <div className="-padding">
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "วันที่ออกใบเสร็จรับเงิน :",
                                                                           detail: order.accp_date ? dateFormatter(order.accp_date) : "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                            {/* <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ชำระโดย :",
                                                                           detail: order.accp_payment_method,
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                            {order.accp_payment_method === "เงินสด" ? null : (
                                                                 <Row>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "หลักฐานการชำระเงิน :",
                                                                                detail: order.accp_file ? "ดู" : "-",
                                                                           }}
                                                                           textHeader_style={order.accp_file ? "underline" : null}
                                                                           onClickLabelBar={() => window.open(order?.accp_file)}
                                                                           md={12}
                                                                      />
                                                                 </Row>
                                                            )}

                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "จำนวนเงิน :",
                                                                           detail: `${Number(order?.accp_price || 0).toLocaleString(undefined, {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2,
                                                                           })} บาท`,
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row> */}
                                                       </div>
                                                  </div>
                                                  <div className="content checkcenter" id="table-content" style={{ padding: "0 15px" }}>
                                                       {/* {this.renderTable()} */}
                                                       <Table
                                                            type={"summary"}
                                                            column={table_detail_payment.column}
                                                            data={table_detail_payment.data}
                                                            keyField={table_detail_payment.keyField}
                                                       />
                                                  </div>
                                                  <div>
                                                       <Breadcrumbs
                                                            under={true}
                                                            type={"document"}
                                                            title={"รับเงินค่าอุปกรณ์แล้ว"}
                                                            detail={"พิมพ์ใบกำกับภาษีค่าอุปกรณ์"}
                                                            button={{
                                                                 btn1: {
                                                                      onClick: () => this.getOrderAccPayPdf("ต้นฉบับ"),
                                                                      name: "ต้นฉบับ",
                                                                      variant: "outline-primary",
                                                                 },
                                                                 btn2: {
                                                                      onClick: () => this.getOrderAccPayPdf("สำเนา"),
                                                                      name: "สำเนา",
                                                                      variant: "outline-primary",
                                                                 },
                                                            }}
                                                       />
                                                       <div style={{ height: "80px" }}></div>
                                                  </div>
                                                  <ModalPanel
                                                       show={this.state.showmodal_file}
                                                       title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                       type={"image"}
                                                       image={`${ip}/static/${row.file}`}
                                                       onHide={() => this.setState({ showmodal_file: false })}
                                                  />
                                             </div>
                                        </div>
                                   </Form>
                              </div>
                         </div>
                    );

               default:
                    return null;
          }
     }
}
