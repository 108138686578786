import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table } from "../../../../components/index";
import { Form, Col, Button } from "react-bootstrap";
import { GET, POST } from "../../../../api/api";
import swal from "sweetalert";

export default class AddIns extends Component {
     constructor(props) {
          super(props);
          this.state = {
               validated: false,
               insurance: {
                    status: "ใช้งาน",
               },
               title: "",
               previous_path: this.props.match.params.manage,
               file: [],
               deletePic: [],
               id: this.props.match.params.id,
          };
     }
     componentDidMount = async () => {
          this.setPath();
          this.getLeasing();
     };

     /* Validate field on submit */
     handleSubmit = async (event) => {
          event.preventDefault();

          let { insurance, file } = this.state;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    icon: "error",
                    button: "เสร็จสิ้น",
               });
               event.stopPropagation();
          } else {
               let formData = new FormData();
               if (file) {
                    file.forEach((element) => {
                         formData.append("file", element.file);
                    });
               }

               for (const [key, value] of Object.entries(insurance)) {
                    formData.append(key, value);
               }

               try {
                    await POST("/ps/add_insurance", formData, true);

                    swal({
                         text: "เพิ่มประเภท Insurance ในรายการเรียบร้อยแล้ว",
                         icon: "success",
                         button: "เสร็จสิ้น",
                    }).then(() => this.props.history.push("/admin/insurance"));
                    event.preventDefault();
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }

          this.setState({
               validated: true,
          });
     };

     setPath = () => {
          let { previous_path, title, id } = this.state;
          switch (previous_path) {
               case "add":
                    title = [
                         {
                              title: "ประกัน",
                              onClick: () => window.location.assign("/admin/insurance"),
                         },
                         {
                              title: "เพิ่มประกัน",
                         },
                    ];
                    break;
               case "edit":
                    title = [
                         {
                              title: "ประกัน",
                              onClick: () => window.location.assign("/admin/insurance"),
                         },
                         {
                              title: "ข้อมูล",
                              onClick: () => window.location.assign(`/admin/insurance/detail${id}`),
                         },
                         {
                              title: "แก้ไข",
                         },
                    ];
                    this.getInsurance();
                    break;
               default:
                    break;
          }
          title = this.setState({ title: title });
     };

     getInsurance = async () => {
          try {
               let insurance = await POST("/ps/this_insurance", {
                    insurance_id: this.props.match.params.id,
               });
               insurance.files.forEach((element, i) => {
                    element.no = i + 1;
                    element.download = element.file;
               });
               this.setState({ insurance: { ...insurance }, file: insurance.files });
          } catch (error) {
               console.log(error);
          }
     };
     getLeasing = async () => {
          try {
               let res = await GET("/aos/get_leasing");
               let leasing = res.map((el) => ({
                    value: el.supplier_id,
                    name: el.supplier_name,
               }));
               this.setState({
                    leasing,
               });
          } catch (error) {
               console.log("error", error);
          }
     };

     handleReset = () => {
          // window.location.reload();
          swal({
               text: "ทำรายการสำเร็จ",
               icon: "success",
               button: "เสร็จสิ้น",
          }).then(() => this.props.history.push("/admin/insurance"));
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { insurance } = this.state;
          insurance[name] = value;
          this.setState({ insurance });
          console.log('insurance', insurance)
     };

     handleEdit = async () => {
          let { insurance, file, deletePic, id } = this.state;
          try {
               let formData = new FormData();
               if (file) {
                    file.forEach((element, i) => {
                         if (element.insurance_file_id) {
                              file.splice(i, 1);
                         }
                    });
                    file.forEach((element) => {
                         formData.append("file", element.file);
                    });
               }
               for (const [key, value] of Object.entries(insurance)) {
                    formData.append(key, value);
               }

               formData.append("deletePic", JSON.stringify(deletePic));

               await POST("/ps/edit_insurance", formData, true);

               swal({
                    text: "แก้ไขประเภท Insurance ในรายการเรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => this.props.history.push(`/admin/insurance/detail${id}`));

               this.getInsurance();
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     /* Upload file and set file name to table */
     handleChange = (event) => {
          let { file } = this.state;
          const fileUploaded = event.target.files[0];
          file.push({
               no: file.length + 1,
               name: fileUploaded.name,
               file: fileUploaded,
               download: URL.createObjectURL(fileUploaded),
          });
          this.setState({
               file,
          });
     };

     removeFile = (row, rowIndex) => {
          let { file, deletePic } = this.state;

          if (file.length > 0) {
               if (rowIndex < file[0].no) {
                    file.forEach((element) => {
                         element.no--;
                    });
               } else {
                    let indexOfStevie = file.findIndex((index) => index.no === rowIndex + 1);
                    for (let index = indexOfStevie; index < file.length; index++) {
                         file[index].no--;
                    }
               }
          }
          deletePic.push(row.insurance_file_id);
          file.splice(rowIndex, 1);

          this.setState({ file: [...file] });
     };

     removeBtnFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.removeFile(row, rowIndex)} variant={"danger"}>
                    Remove
               </Button>
          );
     };

     editDetailFormatter(cell, row, rowIndex, formatExtraData) {
          if (row.manage === "แก้ไข") {
               return <Button>ดูการแกไข</Button>;
          } else {
               return null;
          }
     }

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <a href={row.download} download>
                    <Button>ดูเอกสาร </Button>
               </a>
          );
     }
     render() {
          let { title, previous_path, file, insurance, validated, id, leasing } = this.state;
          const file_table = [
               {
                    dataField: "no",
                    text: "ลำดับ",
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "name",
                    text: "ไฟล์เอกสาร",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "detail",
                    text: "",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "remove",
                    text: "Remove",
                    formatter: this.removeBtnFormatter,
                    headerClasses: "header-custom __right __btn-ss",
               },
          ];
          return (
               <div className="panel">
                    {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} onClick={() => this.props.history.goBack()} /> : null}
                    <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                         <div className="content __input-panel">
                              {/* ข้อมูลประกัน */}
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ข้อมูลประกัน</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"ระบุประเภทประกัน*"}
                                                            name={"insurance_type"}
                                                            placeholder={"กรุณากรอกประเภทประกัน"}
                                                            require={true}
                                                            md={6}
                                                            value={insurance?.insurance_type}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"บริษัทไฟแนนซ์"}
                                                            name={"supplier_id"}
                                                            option={leasing ? leasing : []}
                                                            placeholder={"กรุณากรอกบริษัทไฟแนนซ์"}
                                                            require={false}
                                                            md={6}
                                                            value={insurance?.supplier_id}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            as={"textarea"}
                                                            textarea_rows={5}
                                                            title={"รายละเอียดประกัน"}
                                                            name={"insurance_text"}
                                                            placeholder={"กรุณากรอกรายละเอียดประกัน"}
                                                            require={true}
                                                            md={12}
                                                            value={insurance?.insurance_text}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"สถานะ"}
                                                            name={"status"}
                                                            label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                            // disabled={order?.insurance === 'No'}
                                                            value={insurance?.status}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="input-field">
                                   <div className="-header">
                                        <div>แนบไฟล์เอกสารได้</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             <div>
                                                  <Table
                                                       type={"add"}
                                                       add={"file"}
                                                       column={file_table}
                                                       data={file}
                                                       keyField={"no"}
                                                       button={"อับโหลด"}
                                                       handleChange={this.handleChange}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {previous_path === "add" ? (
                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             <Button type="submit">Submit</Button>
                                        </Col>
                                        <Col xs={6}>
                                             <Button variant="danger" onClick={this.handleReset}>
                                                  Reset
                                             </Button>
                                        </Col>
                                   </div>
                              ) : (
                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             <Button onClick={this.handleEdit}>บันทึกการแก้ไข</Button>
                                        </Col>
                                        <Col xs={6}>
                                             <Button variant="danger" onClick={this.handleReset}>
                                                  ลบข้อมูล
                                             </Button>
                                        </Col>
                                   </div>
                              )}
                         </div>
                    </Form>
               </div>
          );
     }
}
