import React, { Component } from 'react'
import swal from "@sweetalert/with-react";
import { GET } from "api/index";

export default class Other extends Component {
    componentDidMount = async () => {
        // alert(this.props.match.params.text)
        swal({
            title: 'บัญชีของคุณไม่มีสิทธิ์ดำเนินการในหน้านี้',
            content: (
                // <a href={fileURL}>
                <p className="justify-content-center" >{this.props.match.params.text}</p>
                // </a>
            ),
            // text: this.props.match.params.text,
            icon: 'warning',
            buttons: 'ตกลง',
            dangerMode: true,
        }).then(async () => {
            await GET("/authen/logout");
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("permission_page");
            window.location.assign(`/login/${this.props.match.params.link}`);
        });
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}
