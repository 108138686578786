import { POST } from "api";
import { Breadcrumbs, LabelBar, ModalPanel, Field } from "components";
import InputPanel from "components/input-panel/input-panel";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import swal from "sweetalert";

export default class GenerateCancelLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order_id: this.props.match.params.id,
               detail: {},
               order: {},
               show: false,
               showModalCancel: false,
          };
     }

     componentDidMount = () => {
          this.getOrderDetail();
     };

     getOrderDetail = async () => {
          let { order_id, order } = this.state;
          try {
               let detail = await POST("/order/this", { order_id: order_id });
               order.order_id = parseInt(order_id);

               this.setState({ detail: { ...detail }, order });
               console.log("detail", this.state.detail);
          } catch (err) {
               console.log(err);
          }
     };

     /* ---------- onChange ---------- */
     handleClickCancelLabel = async () => {
          let { order } = this.state;
          try {
               this.onClickCancelModal();
               let result = await POST("/order/cancel_redlabel", order);
               swal({
                    icon: "success",
                    text: "ยกเลิกป้ายแดงแล้ว",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    window.location.assign(`/admin/label/cancel`);
               });
          } catch (err) {
               swal({
                    icon: "warning",
                    text: `ไม่สามารถดำเนินการได้ ${err}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
          }
     };

     /* ---------- onChange ---------- */

     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
     };

     onClickCancelModal = () => {
          this.setState({ showModalCancel: !this.state.showModalCancel });
     };

     render() {
          let { order_id, detail } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `ยกเลิกป้ายแดง`,
                                   onClick: () => window.location.assign("/admin/label/cancel"),
                              },
                              {
                                   title: `ใบจองรถยนต์ (${detail?.order_code_id})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order_id}`),
                              },
                         ]}
                         active={2}
                         button={[]}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลใบจอง"} />
                                        <div className="-padding">
                                             <div className="text-underline">
                                                  <Row>
                                                       <LabelBar
                                                            onClickLabelBar={() =>
                                                                 window.location.assign(`/admin/order/car/detail${detail?.order_id}`)
                                                            }
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขที่ใบจอง : ",
                                                                 detail: `${detail?.order_code_id}`,
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                             </div>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "โชว์รูม : ",
                                                            detail: detail?.branch_name,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: detail?.saler_name_string,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                            detail: detail?.userinfo_name,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: detail?.userinfo_idcard,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: detail?.userinfo_tel,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${detail?.userinfo_address} ตำบล/แขวง ${detail?.userinfo_sub_district} อำเภอ/เขต ${detail?.userinfo_district} จังหวัด ${detail?.userinfo_province} ${detail?.userinfo_zipcode}`,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series : ",
                                                            detail: detail?.series_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model : ",
                                                            detail: detail?.model_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Color : ",
                                                            detail: detail?.color_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขตัวถัง : ",
                                                            detail: detail?.car_tank || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขเครื่องยนต์ : ",
                                                            detail: detail?.car_engine || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"ยกเลิกป้ายแดง"} />
                                        <Row className="noMargin">
                                             <Col xl={12}>
                                                  <Field
                                                       type="text"
                                                       title="หมายเหตุ"
                                                       name="red_label_note"
                                                       as="textarea"
                                                       rows={3}
                                                       handleChangeText={this.handleChangeText}
                                                  />
                                             </Col>
                                        </Row>
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button id={"btn_reset"} variant="danger" onClick={this.onClickCancelModal}>
                                                       ยกเลิกป้ายแดง
                                                  </Button>
                                             </Col>
                                        </div>
                                        <ModalPanel
                                             show={this.state.showModalCancel}
                                             title={"ต้องการยกเลิกใบจองป้ายแดงหรือไม่"}
                                             type={"default"}
                                             onHide={() => this.onClickCancelModal()}
                                             button={[
                                                  { onClick: () => this.handleClickCancelLabel(), name: "ตกลง", variant: "primary" },
                                                  { onClick: () => this.onClickCancelModal(), name: "ยกเลิก", variant: "outline-primary" },
                                             ]}
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
