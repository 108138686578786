import { Breadcrumbs, LabelBar, FormTable, Field, Table, ModalPanel, Skeleton } from "../../../../../../components/index";
import React, { Component, useState } from "react";
import { Row, Button, Col, Form, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { POST, ip } from "api/index";
import dayjs from "dayjs";
import axios from "axios";
import printJS from "print-js";
import { getToken, add, tofieds } from "static/function";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}
export default class GeneratePaymentCar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               // payment_status: this.props.match.params.status,
               id: this.props.match.params.id,
               showmodal_sign: false,
               disabled: false,
               order: {
                    addAccbonus: [],
                    addAccbuy: [],
                    addAccturn: [],
                    addReceipt: [],
                    promotions: [],
                    addPromotions: [],
                    deletePromotion: [],
                    accbonus: [],
                    deleteAccbonus: [],
                    accbuy: [],
                    deleteAccbuy: [],
                    accturn: [],
                    deleteAccturn: [],
                    receipt: [],
                    deletereceipt: [],
               },
               issue: {
                    // orcp_payment_method: null,
                    // orcp_leasing_payment_method: null,
                    order_id: this.props.match.params.id,
               },
               rowIndex: 0,
               disabled: false,
               receiptDataPay: [
                    {
                         receipt_method: "ใบเสร็จรับเงินค่ารถ",
                         receipt_price: 0,
                         check: false,
                         // code: 'RM',
                         code: null,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่า พรบ.",
                         receipt_price: 0,
                         check: false,
                         code: "RM",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย",
                         receipt_price: 0,
                         check: false,
                         // code: 'RM',
                         code: null,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         receipt_price: 0,
                         check: false,
                         code: "RR",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าต่อเติม",
                         receipt_price: 0,
                         check: false,
                         code: "RS",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                         code: "RC",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                         code: "RE",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าแจ้งย้าย",
                         receipt_price: 0,
                         check: false,
                         code: "RT",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตร-เช็ค ตจว.",
                         receipt_price: 0,
                         check: false,
                         code: "RQ",
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         // code: "CB",
                         code: null,
                    },
                    {
                         receipt_method: "ใบลดหนี้/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         code: "CB",
                    },
                    {
                         receipt_method: "อื่น ๆ (OR)",
                         receipt_price: 0,
                         check: false,
                         code: "OR",
                    },
                    {
                         receipt_method: "อื่น ๆ (OR)",
                         receipt_price: 0,
                         check: false,
                         code: "OR",
                    },
                    {
                         receipt_method: "อื่น ๆ (OI)",
                         receipt_price: 0,
                         check: false,
                         code: "OI",
                    },
                    {
                         receipt_method: "อื่น ๆ (OI)",
                         receipt_price: 0,
                         check: false,
                         code: "OI",
                    },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      code: "OI",
                    // },
               ],
               receiptDataFinance: [
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเงินดาวน์",
                         receipt_price: 0,
                         check: false,
                         code: "RD",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่า พรบ.",
                         receipt_price: 0,
                         check: false,
                         code: "RM",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย",
                         receipt_price: 0,
                         check: false,
                         // code: 'RM',
                         code: null,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         receipt_price: 0,
                         check: false,
                         code: "RR",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมไฟแนนซ์",
                         receipt_price: 0,
                         check: false,
                         code: "RH",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่างวดงวดแรก",
                         receipt_price: 0,
                         check: false,
                         // code: 'RM',
                         code: null,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าประกันสินเชื่อ",
                         receipt_price: 0,
                         check: false,
                         code: "RG",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าต่อเติม",
                         receipt_price: 0,
                         check: false,
                         code: "RS",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                         code: "RC",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                         code: "RE",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าแจ้งย้าย",
                         receipt_price: 0,
                         check: false,
                         code: "RT",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าภาษีซัพดาวน์",
                         receipt_price: 0,
                         check: false,
                         code: "SD",
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงินค่า VAT 15%",
                         receipt_price: 0,
                         check: false,
                         // code: 'RS',
                         code: null,
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงินค่า VAT 7%",
                         receipt_price: 0,
                         check: false,
                         code: "RS",
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตร-เช็ค ตจว.",
                         receipt_price: 0,
                         check: false,
                         code: "RQ",
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         // code: "CB",
                         code: null,
                    },
                    {
                         receipt_method: "ใบลดหนี้/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         code: "CB",
                    },
                    {
                         receipt_method: "อื่น ๆ (OR)",
                         receipt_price: 0,
                         check: false,
                         code: "OR",
                    },
                    {
                         receipt_method: "อื่น ๆ (OR)",
                         receipt_price: 0,
                         check: false,
                         code: "OR",
                    },
                    {
                         receipt_method: "อื่น ๆ (OI)",
                         receipt_price: 0,
                         check: false,
                         code: "OI",
                    },
                    {
                         receipt_method: "อื่น ๆ (OI)",
                         receipt_price: 0,
                         check: false,
                         code: "OI",
                    },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      code: "OI",
                    // },
               ],
               modalreceiptData: [],
               slectPay: [
                    {
                         check: false,
                         payment_method: "เงินสด",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "เงินโอน",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "เช็ค",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "บัตรเครดิต",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "รับชำระโดย อื่น ๆ",
                         detail: "",
                         price: 0,
                         file: "",
                         orderleasingPdf: "",
                    },
               ],
               openModal: false,
               placeholder: {
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    n0: "หลักฐานการชำระเงิน",
                    n1: "หลักฐานการชำระเงิน",
                    n2: "หลักฐานการชำระเงิน",
                    n3: "หลักฐานการชำระเงิน",
                    n4: "หลักฐานการชำระเงิน",
               },
               row: {},
               showmodal_file: false,
               total: 0,
               total2: 0,
               insu_placeholder: "เลือกไฟล์",
               insu: {},
               insurance_file_path: false,
               show_sign: false,
               checkPrice: 0,
               reload: false,
               checkedReciept: [],
               loading: true,
               loadingPayment: false,
               save_loading: false,
               priceCal: [
                    { price: 0, check: false },
                    { price: 0, check: false },
                    { price: 0, check: false },
                    { price: 0, check: false },
                    { price: 0, check: false },
               ],
               note_reducing: "",
               get_log_app: [],
          };
     }

     componentDidMount = async () => {
          this.initial();
          await this.getOrder();
          await this.getLogPayment();
          this.setSumTable();
          this.calMargin();
          this.setState({ role: getToken().position_id });
     };

     initial = () => {
          let { payment_status, disabled } = this.state;

          if (payment_status === "ชำระเงินแล้ว") {
               disabled = true;

               this.setState({
                    disabled,
               });
          }
     };

     getOrder = async () => {
          let { receiptDataPay, receiptDataFinance, slectPay, issue } = this.state;
          try {
               let result = await POST("/order/this", { order_id: this.state.id });
               let get_log_app = await POST("/order/log_margin", { order_id: this.state.id });
               let accbuy_price = 0;
               // result.accbonus = [];
               // result.accbuy = [];
               result.accturn = [];
               result.promotions = [];
               result.deleteAccbonus = [];
               result.deleteAccbuy = [];
               result.deletePromotion = [];
               result.deleteAccturn = [];
               result.deletereceipt = [];
               result.insurance_pay = result.insurance_pay === "ลูกค้า" ? "ซื้อ" : "แถม";
               result.finace_need = Number(result?.start_price || 0) - Number(result?.down || 0);

               // result.receipt.forEach((element) => {
               //      let index = receiptData
               //           .map(function (e) {
               //                return e.receipt_method;
               //           })
               //           .indexOf(element.receipt_method);
               //      receiptData.splice(index, 1);
               // });
               // console.log("result", result.day_credit, result.pay_choice);
               let receiptData = [];
               if (result.pay_choice === "ผ่อนชำระ" || Number(result.day_credit) > 0) {
                    result.receipt = result.receipt
                         .map((el) => {
                              if (el.receipt_method === "ใบเสร็จรับเงินค่ารถ" || el.receipt_method === "ใบรับเงินค่าคอมไฟแนนซ์") {
                                   return null;
                              } else return { ...el };
                         })
                         .filter((el) => el);
                    result.receipt.map((element) => {
                         // if (element.receipt_method !== "ใบเสร็จรับเงินค่ารถ" || element.receipt_method !== "ใบรับเงินค่าคอมไฟแนนซ์") {
                         let index = receiptDataFinance.findIndex((el) => el.receipt_method === element.receipt_method);
                         if (index >= 0) {
                              receiptDataFinance.splice(
                                   receiptDataFinance.findIndex((el) => el.receipt_method === element.receipt_method),
                                   1
                              );
                         }
                         // }
                    });
                    receiptData = receiptDataFinance;
               } else {
                    result.receipt.map((element) => {
                         let index = receiptDataPay.findIndex((el) => el.receipt_method === element.receipt_method);
                         if (index >= 0) {
                              receiptDataPay.splice(
                                   receiptDataPay.findIndex((el) => el.receipt_method === element.receipt_method),
                                   1
                              );
                         }
                    });
                    receiptData = receiptDataPay;
               }

               result.accbuy.forEach((ele) => {
                    accbuy_price += ele.order_ac_amount * Number(JSON.parse(ele.price_list).price);
               });

               result.addition_acc_price = accbuy_price;
               let accbonus_price = 0;
               let margin = 0;

               let discount_price = result?.discount_price ? result.discount_price : 0;
               let insurance_premium = result.insurance === "Yes" && result.insurance_pay === "บริษัท" ? Number(result?.insurance_premium) : 0;
               let act_premium = result.act === "Yes" && result.act_pay === "บริษัท" ? Number(result?.act_premium) : 0;
               let register_price = result.register === "Yes" && result.register_pay === "บริษัท" ? Number(result?.register_price) : 0;
               let recommend_price = result?.recommend_price ? Number(result.recommend_price) : 0;
               let other_pay_price = result?.other_pay_price ? Number(result.other_pay_price) : 0;

               result.accbonus.forEach((ele) => {
                    accbonus_price += ele.order_ac_amount * JSON.parse(ele.price_list).price_add_old;
               });

               margin = accbonus_price + discount_price + insurance_premium + act_premium + register_price + recommend_price + other_pay_price;

               let detailPayment = [];
               let total = 0;
               let detailPayment2 = [];
               let total2 = 0;
               if (result.orcp_payment_method) {
                    for (let e of JSON.parse(result.orcp_payment_method)) {
                         if (e.check) {
                              detailPayment.push(e);
                              total += Number(e.price);
                         }
                    }
               }
               if (result.orcp_leasing_payment_method) {
                    for (let e of JSON.parse(result.orcp_leasing_payment_method)) {
                         if (e.check) {
                              detailPayment2.push(e);
                              total2 += Number(e.price);
                         }
                    }
                    let pay = JSON.parse(result.orcp_leasing_payment_method);
                    let a = pay.filter((el) => el.check === true);
                    let p = 0;
                    for (let i in a) {
                         p += Number(a[i].price);
                    }
               }
               this.setState({
                    order: result,
                    receiptData,
                    // disabled: result.orcp_payment_method ? true : false,
                    disabled: true,
                    slectPay,
                    detailPayment,
                    total: total,
                    detailPayment2,
                    total2: total2,
                    issue,
                    get_log_app,
               });
          } catch (error) {
               console.log(error);
          }
     };

     getLogPayment = async () => {
          const { order } = this.state;
          try {
               let res = await POST("/order/log_car_payment", { order_id: order.order_id });
               let newRes = res.map((element) => {
                    let obj = {
                         ...element,
                         orcp_payment_method: element.orcp_payment_method
                              ? JSON.parse(element.orcp_payment_method)
                                     .filter((el) => el.check === true)
                                     .filter((el) => el.page !== "รับเงินจากไฟแนนซ์")
                              : [],
                    };
                    return obj;
               });
               if (newRes.length > 0) {
                    await this.setState({ payment_log: newRes });
               }
          } catch (error) {
               console.log(`error`, error);
          }
     };

     setSumTable = () => {
          let { order, slectPay } = this.state;
          this.setState({ loading: true });
          // ----------price pay-----------
          let data = order.receipt.sort((a, z) => z.check - a.check).map((el) => ({ ...el, check: true }));
          // console.log(`data`, data);
          let sum = 0;
          if (data[0]) {
               for (let i = 0; i < data.length; i++) {
                    let el = data[i];
                    if (!el.receipt_code) {
                         if (el.check) {
                              if (el.receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                                   sum = sum - el.receipt_price;
                              } else {
                                   sum = sum + el.receipt_price;
                              }
                         }
                    }
               }
          }
          if (order.orcp_status && order.orcp_status.includes("รอ")) {
               sum = sum - order.sub_down;
          } else {
               sum = sum;
          }
          slectPay.map((el, i) => {
               slectPay[0].price = sum;
               slectPay[i].check = false;
          });
          this.setState({ loading: false, checkedReciept: data, checkPrice: sum, slectPay });
     };

     getOrderLeasingPdf = async () => {
          try {
               await axios
                    .post(
                         `${ip}/order/order_leadsing_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         window.open(fileURL);
                         // this.setState({
                         // orderleasingPdf: fileURL,
                         // spinner: false,
                         // show_modal_pdf: true
                         // });
                    });
          } catch (error) {}
     };

     getOrderCarPayPdf = async (type) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/car_pay_pdf`,
                         { order_id: this.state.id, type },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {}
     };

     calMargin = () => {
          let { order } = this.state;
          let accbonus_price = 0;
          let margin = 0;

          let discount_price = order?.discount_price ? order.discount_price : 0;
          let insurance_premium = order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0;
          let act_premium = order.act === "Yes" && order.act_pay === "บริษัท" ? Number(order?.act_premium) : 0;
          let register_price = order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0;
          let recommend_price = order?.recommend_price ? Number(order.recommend_price) : 0;
          let other_pay_price = order?.other_pay_price ? Number(order.other_pay_price) : 0;

          order.accbonus.forEach((ele) => {
               accbonus_price += ele.order_ac_amount * JSON.parse(ele.price_list).price_add_old;
          });

          margin = accbonus_price + discount_price + insurance_premium + act_premium + register_price + recommend_price + other_pay_price;

          this.setState({ margin: margin });
     };

     viewFile = (row) => {
          this.setState({ showmodal_file: true, row: row });
     };

     /* ------------------------ onClick ----------------------------- */
     onOpenModal = () => {
          let { openModal } = this.state;
          this.setState({ openModal: !openModal });
     };

     onAddReceipt = async () => {
          let { modalreceiptData, order, receiptData, checkPrice, slectPay, checkedReciept } = this.state;
          let dataAdd = [];
          // console.log(`modalreceiptData`, modalreceiptData);
          await Promise.all(
               modalreceiptData.map(async (el) => {
                    let id = await POST("/aos/create_receipt", {
                         data: {
                              order_id: order.order_id,
                              receipt_method: el.receipt_method,
                              receipt_price: el.receipt_price,
                              code: el.code,
                         },
                    });
                    dataAdd.push({ id, receipt_method: el.receipt_method });
               })
          );
          // console.log(`dataAdd`, dataAdd);
          let addReceipt = [];
          await Promise.all(
               receiptData.map((element) => {
                    if (dataAdd.find((e) => e.receipt_method === element.receipt_method)) {
                         let receipt_id = dataAdd.find((e) => e.receipt_method === element.receipt_method).id;
                         order.receipt.unshift({ ...element, check: true, receipt_id });
                         checkedReciept.unshift({ ...element, check: true, receipt_id });
                         if (element.receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                              checkPrice -= element.receipt_price;
                         } else {
                              checkPrice += element.receipt_price;
                         }
                         addReceipt.push({ ...element });
                    }
               })
          );

          modalreceiptData.forEach((element) => {
               let index = receiptData
                    .map(function (e) {
                         return e.receipt_method;
                    })
                    .indexOf(element.receipt_method);
               receiptData.splice(index, 1);
          });
          // console.log(`order.receipt`, order.receipt);
          // console.log(`checkedReciept`, checkedReciept);
          order.addReceipt = addReceipt;
          // await POST("/order/edit", { ...order });
          slectPay[0].price = checkPrice;
          this.setState({ order, openModal: false, modalreceiptData: [], checkPrice, slectPay, checkedReciept }, () => {
               this.totalPay();
          });
     };

     onChangeCheckboxAddReceipt = async (checked, row) => {
          let { modalreceiptData } = this.state;
          ///check
          if (checked) {
               modalreceiptData.push(row);
               ///uncheck
          } else {
               modalreceiptData.splice(
                    modalreceiptData.findIndex((el) => el.receipt_method === row.receipt_method),
                    1
               );
          }
          this.setState({ modalreceiptData });
     };

     showPdf = async (row) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/template_pdf`,
                         {
                              order_id: this.state.id,
                              namepayment: row.receipt_method,
                              pricePayment: row.receipt_price,
                              code: row.receipt_code,
                              branch_id: this.state.order.branch_id,
                              name_pay: row.name_pay,
                         },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {}
     };
     /* ------------------------ onChange ----------------------------- */
     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          let { issue, order, slectPay, file } = this.state;
          issue.order_id = order.order_id;
          issue.oder_turnpay_status = "ออกใบกำกับภาษีแล้ว";
          if (checked) {
               issue.order_id = order.order_id;
               issue.orcp_payment_method = row.payment_method;
               issue.orcp_detail = slectPay[rowIndex].detail;
               issue.orcp_price = Number(slectPay[rowIndex].price);
               file = slectPay[rowIndex].file;
               this.setState({ issue, rowIndex: rowIndex, file });
          }
     };
     onChangeCheckboxAddPrice = (checked, row, rowIndex) => {
          let { checkPrice, slectPay, checkedReciept } = this.state;
          let newCheckedReciept = [];
          if (checked) {
               if (row.receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                    checkPrice -= row.receipt_price;
               } else {
                    checkPrice += row.receipt_price;
               }
               newCheckedReciept = checkedReciept.map((el, i) => {
                    if (rowIndex === i) {
                         return { ...el, check: true };
                    }
                    return el;
               });
          } else {
               if (row.receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                    checkPrice += row.receipt_price;
               } else {
                    checkPrice -= row.receipt_price;
               }
               newCheckedReciept = checkedReciept.map((el, i) => {
                    if (rowIndex === i) {
                         return { ...el, check: false };
                    }
                    return el;
               });
          }
          slectPay[0].price = checkPrice;
          this.setState({ checkPrice, checkedReciept: newCheckedReciept, slectPay });
     };

     onChangeCheckboxPay = (checked, row, rowIndex) => {
          let { slectPay } = this.state;
          ///check
          if (checked) {
               slectPay[rowIndex].check = true;
               this.setState({ slectPay });
          } else {
               slectPay[rowIndex].check = false;
               this.setState({ slectPay });
          }
     };

     onChangeModalPrice = (e, rowIndex) => {
          let { receiptData } = this.state;
          receiptData[rowIndex].receipt_price = e.floatValue;
          this.setState({ receiptData });
     };
     /* ------------------------ handle ----------------------------- */

     saveSign = async (sigCanvas) => {
          let { order, id, rowIndex, issue } = this.state;
          let base64 = await sigCanvas.toDataURL("image/png");

          let addReceipt = [],
               receipt = [];
          order.receipt.map((el) => {
               if (!el.receipt_id) {
                    addReceipt.push(el);
               } else if (el.receipt_id) {
                    receipt.push(el);
               }
          });

          order.receipt = [...receipt];
          order.addReceipt = [...addReceipt];
          order.addAccbonus = [];
          order.addAccbuy = [];
          order.addAccturn = [];
          order.insurance_pay = order.insurance_pay === "ซื้อ" ? "ลูกค้า" : "บริษัท";
          order.orcp_sign = base64;

          this.onSubmit();

          issue.branch_id = order.branch_id;

          // if(order?.orcp_leasing_date)
          // order.orcp_leasing_date = null

          // issue.orcp_payment_method = JSON.stringify(slectPay);

          this.setState({ show_sign: false });
     };

     clear = (sigCanvas) => {
          sigCanvas.clear();
     };

     onSubmit = async () => {
          let { id, show_popup, issue, file, order, slectPay, receiptData, checkedReciept, note_reducing } = this.state;

          let check_status = slectPay.find((el) => el.check === true);
          let price_status = !slectPay.find((el) => el.check === true && (el.price === 0 || el.price === ""));
          try {
               if (check_status) {
                    if (price_status) {
                         if (slectPay[1].check && slectPay[1].file === "") {
                              swal({
                                   text: "กรณีเงินโอน กรุณาแนบหลักฐานการชำระเงิน",
                                   icon: "warning",
                                   button: "ตกลง",
                                   dangerMode: true,
                              });
                         } else {
                              let setDate = issue.orcp_date ? dayjs(issue.orcp_date).format("YYYY-MM-DD") : dayjs(new Date()).format("YYYY-MM-DD");
                              issue.receiptData = order.receipt.map((el) => {
                                   let check = checkedReciept.filter((e) => e.receipt_method === el.receipt_method);
                                   check = check[0] ? check[0].check : false;
                                   return { ...el, check };
                              });
                              // console.log(`issue.receiptData`, issue.receiptData);
                              if (order.orcp_status && !order.orcp_status.includes("รอ")) {
                                   swal({
                                        text: "ต้องการบันทึกการรับเงินค่ารถหรือไม่",
                                        icon: "warning",
                                        buttons: {
                                             submit: "ยืนยัน",
                                             cancel: "ไม่ยืนยัน",
                                        },
                                   }).then(async (value) => {
                                        if (value === "submit") {
                                             this.setState({ loadingPayment: true, save_loading: true });
                                             issue.orcp_payment_method = JSON.stringify(slectPay);
                                             issue.orcp_date = setDate;
                                             let check = checkedReciept.find((el) => el.receipt_method.includes("ใบลดหนี้"));
                                             await POST("/order/add_other_carpay", {
                                                  ...issue,
                                                  note_reducing: check && check.check && check.receipt_code ? false : note_reducing,
                                             });
                                             let res = await POST("/order/log_car_payment", { order_id: order.order_id });
                                             if (slectPay[1].file && slectPay[1].check) {
                                                  let formData1 = new FormData();
                                                  formData1.append("cp_log_id", res[res.length - 1].cp_log_id);
                                                  formData1.append("file", slectPay[1].file);
                                                  formData1.append("index", 1);
                                                  formData1.append("pay_choice", order.pay_choice);
                                                  await POST("/order/file_other_car_payment", formData1, true);
                                             }
                                             if (slectPay[3].file && slectPay[3].check) {
                                                  let formData3 = new FormData();
                                                  formData3.append("cp_log_id", res[res.length - 1].cp_log_id);
                                                  formData3.append("file", slectPay[3].file);
                                                  formData3.append("index", 3);
                                                  formData3.append("pay_choice", order.pay_choice);
                                                  await POST("/order/file_other_car_payment", formData3, true);
                                             }
                                             if (slectPay[4].file && slectPay[4].check) {
                                                  let formData4 = new FormData();
                                                  formData4.append("cp_log_id", res[res.length - 1].cp_log_id);
                                                  formData4.append("file", slectPay[4].file);
                                                  formData4.append("index", 4);
                                                  formData4.append("pay_choice", order.pay_choice);
                                                  await POST("/order/file_other_car_payment", formData4, true);
                                             }
                                             swal({
                                                  text: "รับเงินค่ารถเรียบร้อย",
                                                  icon: "success",
                                                  button: "ตกลง",
                                             }).then(() => {
                                                  window.location.reload();
                                                  // this.setState({ loadingPayment: false, save_loading: false });
                                             });
                                        } else {
                                             return null;
                                        }
                                   });
                              } else {
                                   this.setState({ loadingPayment: true, save_loading: true });
                                   if (order.pay_choice === "เงินสด") {
                                        issue.orcp_payment_method = JSON.stringify(slectPay);
                                        issue.orcp_date = setDate;
                                   } else {
                                        issue.orcp_leasing_payment_method = JSON.stringify(slectPay);
                                        issue.orcp_leasing_date = setDate;
                                   }
                                   let check = checkedReciept.find((el) => el.receipt_method.includes("ใบลดหนี้"));
                                   await POST("/order/add_car_payment", {
                                        ...issue,
                                        note_reducing: check && check.check && check.receipt_code ? false : note_reducing,
                                   });
                                   if (slectPay[1].file && slectPay[1].check) {
                                        let formData1 = new FormData();
                                        formData1.append("order_id", id);
                                        formData1.append("file", slectPay[1].file);
                                        formData1.append("index", 1);
                                        formData1.append("pay_choice", order.pay_choice);
                                        await POST("/order/fille_car_payment", formData1, true);
                                   }
                                   if (slectPay[3].file && slectPay[3].check) {
                                        let formData3 = new FormData();
                                        formData3.append("order_id", id);
                                        formData3.append("file", slectPay[3].file);
                                        formData3.append("index", 3);
                                        formData3.append("pay_choice", order.pay_choice);
                                        await POST("/order/fille_car_payment", formData3, true);
                                   }
                                   if (slectPay[4].file && slectPay[4].check) {
                                        let formData4 = new FormData();
                                        formData4.append("order_id", id);
                                        formData4.append("file", slectPay[4].file);
                                        formData4.append("index", 4);
                                        formData4.append("pay_choice", order.pay_choice);
                                        await POST("/order/fille_car_payment", formData4, true);
                                   }
                                   swal({
                                        text: "รับเงินค่ารถเรียบร้อย",
                                        icon: "success",
                                        button: "ตกลง",
                                   }).then(() => {
                                        window.location.reload();
                                        // this.setState({ loadingPayment: false, save_loading: false });
                                   });
                              }
                         }
                    } else {
                         swal({
                              text: "กรุณากรอกจำนวนเงินให้ครบ",
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    }
               } else {
                    swal({
                         text: "กรุณาเลือกวิธีชำระเงิน",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } catch (error) {
               console.log("error", error);
               // this.setState({ loadingPayment: false, save_loading: false });
               swal({
                    text: error.length > 50 ? "กรุณาทำรายการใหม่ในภายหลัง" : error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               }).then(() => window.location.reload());
          }
     };

     showPdfLeasing = async (name) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         // `${ip}/order${name === "ใบกำกับภาษี / ใบแจ้งหนี้ ไฟแนนซ์" ? "/tax_leasing_pdf" : "/car_pay_leasing_pdf"}`,
                         `${ip}/order${name === "ใบแจ้งหนี้ / ใบกำกับภาษี" ? "/tax_leasing_pdf" : "/car_pay_leasing_pdf"}`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {
               console.log("error", error);
          }
     };

     onSaveInsurance = async () => {
          let { insu, order } = this.state;
          let formData = new FormData();
          try {
               if (insu.file && order.insurance_recive_no) {
                    formData.append("order_id", this.state.id);
                    formData.append("file", insu.file);
                    formData.append("insurance_recive_no", order.insurance_recive_no);

                    await POST("/order/edit_insurance", formData, true);

                    swal({
                         text: "บันทึกข้อมูลประกันภัยแล้ว",
                         icon: "success",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    });
               } else {
                    swal({
                         text: "กรอกให้ครบ",
                         icon: "warning",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
               this.getOrder();
          } catch (error) {
               console.log(`error`, error);
          }
     };

     handleChangeFileInsurance = (e) => {
          let { insu } = this.state;
          if (e.target.files[0]) {
               let file = e.target.files[0];
               insu.file = file;
               this.setState({
                    insu,
                    insu_placeholder: file.name,
               });
               // console.log(`insu`, insu);
          }
     };
     handleChangeTextInsurance = (e) => {
          let { insu, order } = this.state;
          insu[e.target.name] = e.target.value;
          order[e.target.name] = e.target.value;
          this.setState({ insu, order });
          // console.log(`insu`, insu);
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { order } = this.state;

          order[name] = value;

          this.setState({ order });
     };

     handleChangeNumber = (e, name) => {
          let { order } = this.state;
          let value = e.value;
          order[name] = value;
          this.setState({ order });
     };

     onRemoveReceipt = (row, rowIndex) => {
          let { checkPrice, slectPay, checkedReciept } = this.state;
          swal({
               text: "ต้องการลบรายการนี้หรือไม่",
               icon: "warning",
               buttons: {
                    submit: "ใช่",
                    cancel: "ไม่",
               },
          }).then(async (value) => {
               if (value === "submit") {
                    let { receiptData } = this.state;
                    receiptData.push({ receipt_method: row.receipt_method, receipt_price: 0, check: false });
                    // order.deletereceipt.push(row.receipt_id);
                    // console.log(`row.receipt_id`, row.receipt_id);
                    checkedReciept.splice(rowIndex, 1);
                    if (row.check && row.receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                         checkPrice += row.receipt_price;
                    } else if (row.check) {
                         checkPrice -= row.receipt_price;
                    }
                    slectPay[0].price = checkPrice;
                    await POST("/aos/del_receipt", { receipt_id: row.receipt_id });
                    // await POST("/order/edit", { ...order });
                    this.setState({ receiptData, checkPrice, slectPay }, () => this.totalPay());
               }
          });
     };
     /* ------------------------ Formatter ---------------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     checkBoxFormatterModal = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null }]}
                    handleChangeText={(e) => this.onChangeCheckboxAddReceipt(e.target.checked, row)}
               />
          );
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ name: "", value: rowIndex }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row)}
               />
          );
     };
     checkBoxFormatterAddPrice = (cell, row, rowIndex) => {
          return (
               <LabelBar
                    type={"checkbox-check"}
                    checked={cell}
                    label={[{ name: "", value: rowIndex }]}
                    disabled={row.receipt_code || row.receipt_method === "ใบเสร็จรับเงินค่ารถ" ? true : false}
                    handleChangeText={(e) => this.onChangeCheckboxAddPrice(e.target.checked, row, rowIndex)}
               />
          );
     };

     handleChangNameReceipt = (e, rowIndex) => {
          let { receiptData } = this.state;
          receiptData[rowIndex].receipt_method = e.target.value;
          this.setState({ receiptData });
     };

     inputFormFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.receipt_method.includes("อื่น ๆ")) {
               return (
                    <Field
                         id={`field-temp-other-${rowIndex}`}
                         type={"text"}
                         title={row.receipt_method}
                         placeholder={"ระบุ"}
                         value={null}
                         style_id={"__inline__space"}
                         space={{ span: 1, offset: 0 }}
                         handleChangeText={(e) => this.handleChangNameReceipt(e, rowIndex)}
                    />
               );
          } else {
               return <>{row.receipt_method}</>;
          }
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { receiptData } = this.state;
          return (
               <Field
                    type={"number"}
                    name={receiptData[rowIndex].payment_method}
                    placeholder={"0.00"}
                    require={true}
                    decimalScale={2}
                    value={null}
                    handleChangeNumber={(e) => this.onChangeModalPrice(e, rowIndex)}
               />
          );
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "preview":
                    if (row.receipt_method === "ใบแจ้งหนี้ / ใบกำกับภาษี" || row.receipt_method === "ใบเสร็จรับเงิน") {
                         return <Button onClick={() => this.showPdfLeasing(row.receipt_method)}>ตัวอย่าง</Button>;
                    } else if (row.receipt_method === "ใบเสร็จรับเงินค่ารถ") {
                         return <Button onClick={() => this.getOrderCarPayPdf("สำเนา")}>ตัวอย่าง</Button>;
                    } else {
                         return <Button onClick={() => this.showPdf(row)}>ตัวอย่าง</Button>;
                    }

               case "print":
                    if (row.receipt_method === "ใบแจ้งหนี้ / ใบกำกับภาษี" || row.receipt_method === "ใบเสร็จรับเงิน") {
                         return <Button onClick={() => this.showPdfLeasing(row.receipt_method)}>พิมพ์</Button>;
                    } else if (!row.receipt_code) {
                         return (
                              <Button onClick={() => this.onRemoveReceipt(row, rowIndex)} variant="danger">
                                   Remove
                              </Button>
                         );
                    } else if (row.receipt_method === "ใบเสร็จรับเงินค่ารถ") {
                         return <Button onClick={() => this.getOrderCarPayPdf("สำเนา")}>พิมพ์</Button>;
                    } else {
                         return <Button onClick={() => this.showPdf(row)}>พิมพ์</Button>;
                    }
               case "remove":
                    return (
                         <Button
                              // disabled={row.receipt_method === "ใบเสร็จรับเงินค่ารถ" ? true : false}
                              onClick={() => this.onRemoveReceipt(row, rowIndex)}
                              variant="danger"
                         >
                              Remove
                         </Button>
                    );
          }
     };

     checkBoxFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          let { order } = this.state;
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: order.orcp_payment_method }]}
                    handleChangeText={(e) => this.onChangeCheckboxPay(e.target.checked, row, rowIndex)}
               />
          );
     };

     inputFormFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          if (row.payment_method === "เช็ค") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"เลขที่ , หมายเหตุ"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"ระบุ"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          } else {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"หมายเหตุ"}
                         // require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          }
     };

     handleChangeTextSelect = (e, row, rowIndex) => {
          let { issue, id, slectPay } = this.state;
          slectPay[rowIndex].detail = e.target.value;
          if (rowIndex === this.state.rowIndex) {
               issue.orcp_detail = slectPay[rowIndex].detail;
          }
          this.setState({ ...slectPay, ...issue });
     };

     inputAmountFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          let { slectPay } = this.state;
          return (
               <Field
                    type={"number"}
                    name={"orcp_price"}
                    value={row?.price}
                    placeholder={"0.00"}
                    decimalScale={2}
                    // disabled={slectPay[rowIndex].check ? false : true}
                    handleChangeNumber={(e) => this.handleChangeNumberPay(e, rowIndex)}
                    require={false}
               />
          );
     };

     handleChangeNumberPay = (value, rowIndex) => {
          let { slectPay, checkPrice, priceCal } = this.state;
          slectPay[rowIndex].price = value.value;
          this.setState({ slectPay });
     };

     inputFileFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { placeholder } = this.state;

          switch (row.payment_method) {
               case "เงินโอน":
                    if (!formatExtraData[1] === formatExtraData[1]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n1}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "บัตรเครดิต":
                    if (!formatExtraData[3] === formatExtraData[3]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n3}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "รับชำระโดย อื่น ๆ":
                    if (!formatExtraData[4] === formatExtraData[4]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n4}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               default:
                    if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                         return (
                              <Field
                                   type={"file"}
                                   // name={row.payment_method}
                                   placeholder={"หลักฐานการชำระเงิน"}
                                   value={null}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __beside-btn"}
                                   handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                   accept={".png,.jpg,.pdf"}
                              />
                         );
                    } else if (row.payment_method === "เช็ค") {
                         return (
                              <span className="warning __info">
                                   ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                              </span>
                         );
                    } else {
                         return <></>;
                    }
          }
     };

     viewFileFormatter = (cell, row) => {
          if (row.file && row.file.length > 0) {
               let arr = row.file.split(".")[1];
               if (cell) {
                    if (arr === "pdf") {
                         return <a onClick={() => window.open(`${ip}/static/${row.file}`)}>หลักฐานการชำระเงิน</a>;
                    } else {
                         return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
                    }
               } else {
                    return <span>-</span>;
               }
          } else return <span>-</span>;
     };
     totalLog = (cell, row) => {
          let total = 0;
          cell.map((el) => (total += Number(el)));

          return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
     };

     handleChangeFile = (e, rowIndex) => {
          let { slectPay, file, placeholder } = this.state;
          if (e.target.files.length !== 0) {
               const fileUploaded = e.target.files[0];
               slectPay[rowIndex].file = fileUploaded;

               if (rowIndex === this.state.rowIndex) {
                    file = slectPay[rowIndex].file;
               }

               placeholder[`n${rowIndex}`] = e.target.files[0].name;
               placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

               this.setState({ ...slectPay, file, placeholder });
          }
     };

     handleSelectDate = (date, dateString, name) => {
          let { issue, id, order } = this.state;
          issue[name] = date;
          issue.orcp_status = "ออกใบกำกับภาษีแล้ว";
          issue.orcp_sign = "";
          this.setState({ ...issue, datePay: date });
     };
     totalPay = () => {
          let { slectPay, checkPrice } = this.state;
          let total = 0;
          for (let i in slectPay) {
               if (slectPay[i].check) total += Number(slectPay[i].price);
          }
          total = (checkPrice - total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          if (total == "-0.00") {
               total = "0.00";
          }
          return total;
     };
     downloadPdf = async (date, first) => {
          try {
               let token = localStorage.getItem("token");
               let { order } = this.state;
               await axios
                    .post(
                         `${ip}/order/summary_delivery_car`,
                         { order_id: order.order_id, type: "ต้นฉบับ", date, first: order.orcp_date === date },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         window.open(fileURL);
                    });
          } catch (error) {
               console.log("error", error);
          }
     };
     render() {
          let {
               issue,
               payment_status,
               id,
               order,
               show_popup,
               receiptData,
               openModal,
               slectPay,
               placeholder,
               margin,
               disabled,
               row,
               detailPayment,
               detailPayment2,
               loading,
               checkPrice,
               payment_log,
               loadingPayment,
               checkedReciept,
               get_log_app,
               save_loading,
          } = this.state;
          // let total_price = slectPay.reduce(add("price"), 0);
          // slectPay[0].price = checkPrice;
          let table = {
               column: [
                    {
                         text: "เลือก",
                         formatter: this.checkBoxFormatterPay,
                         headerClasses: "header-custom __left __no",
                         footer: "",
                    },
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "กรอกข้อมูล",
                         formatter: this.inputFormFormatterPay,
                         formatExtraData: order?.payment_method,
                         footer: "ยอดคงเหลือจ่าย",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatterPay,
                         formatExtraData: checkPrice,
                         footer: this.totalPay,
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.inputFileFormatter,
                         headerClasses: "header-custom __right",
                         formatExtraData: [placeholder.p0, placeholder.p1, placeholder.p2, placeholder.p3, placeholder.p4],
                         footer: "บาท",
                    },
               ],
               data: slectPay,
               keyField: "payment_method",
          };
          let table_detail_payment = {
               column: [
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "detail",
                         text: "รายละเอียด",
                         formatter: (e) => (e ? e : "-"),
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
                         footer: Number(this.state.total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                         // footer: "0.00",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.viewFileFormatter,
                         headerClasses: "header-custom __right",
                         footer: "บาท",
                         footerClasses: "summary-custom",
                    },
               ],
               keyField: "payment_method",
          };
          let table_detail_payment_log = {
               column: [
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "detail",
                         text: "รายละเอียด",
                         formatter: (e) => (e ? e : "-"),
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
                         footer: this.totalLog,
                         // footer: "0.00",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.viewFileFormatter,
                         headerClasses: "header-custom __right",
                         footer: "บาท",
                         footerClasses: "summary-custom",
                    },
               ],
               keyField: "payment_method",
          };
          const detail_pay = {
               column: [
                    {
                         dataField: "check",
                         text: "เลือก",
                         headerClasses: "header-custom __left __no",
                         formatter: this.checkBoxFormatterAddPrice,
                    },
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         headerClasses: "header-custom ",
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         // formatter: (e, row) =>
                         //      row.receipt_method === 'ใบเสร็จรับเงินค่า VAT 15%'
                         //           ? (e * 0.15).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                         //           : e.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         formatter: (e) => (e ? e.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "0.00"),
                    },
                    {
                         dataField: "preview",
                         text: "ดูตัวอย่าง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preview",
                         headerClasses: "header-custom",
                    },
                    {
                         dataField: "remove",
                         text: `${order.orcp_status && order.orcp_status === "ชำระเงินแล้ว" ? "พิมพ์" : "Remove"}`,
                         formatter: this.detailFormatter,
                         formatExtraData: `${order.orcp_status && order.orcp_status === "ชำระเงินแล้ว" ? "print" : "remove"}`,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               keyField: "list",
          };
          const detail_pay_modal = {
               column: [
                    {
                         dataField: "check",
                         text: "เลือก",
                         formatter: this.checkBoxFormatterModal,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         formatter: this.inputFormFormatter,
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatter,
                    },
                    {
                         dataField: "preview",
                         text: "ดูตัวอย่าง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preview",
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: receiptData,
               keyField: "list",
          };
          const detail_pay_comp = {
               column: [
                    {
                         dataField: "check",
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "list",
                         text: "รายการ",
                    },
                    {
                         dataField: "amount",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatter,
                    },
                    {
                         dataField: "preview",
                         text: "ดูตัวอย่าง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preview",
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: [
                    // {
                    //      list: "ใบลดหนี้ค่าจอง",
                    //      // amount: '0.01',
                    // },
                    {
                         list: "ใบรับเงินชั่วคราวค่าเงินดาวน์",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่า พรบ.",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าธรรมเนียมไฟแนนซ์",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าประกันสินเชื่อ",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตรเช็ค ดจว.",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าต่อเติม",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
                         // amount: '0.01',
                    },
                    {
                         list: "ใบรับเงินชั่วคราวค่าแจ้งย้าน",
                         // amount: '0.01',
                    },
                    {
                         list: "ค่า อื่นๆ",
                         // amount: '0.01',
                    },
                    {
                         list: "ค่า อื่นๆ",
                         // amount: '0.01',
                    },
               ],
               keyField: "list",
          };
          const log = {
               column_manager: [
                    {
                         dataField: "updated_at",
                         text: "วันที่ดำเนินการ",
                         formatter: (el) => {
                              return dayjs(el).format("DD/MM/") + (dayjs(el).year() + 543);
                         },
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "event",
                         text: "การดำเนินการ",
                         formatter: (el) => {
                              let color;
                              if (el === "อนุมัติ") {
                                   color = "green";
                              } else if (el === "ไม่อนุมัติ") {
                                   color = "red";
                              }
                              return <p style={{ color: color }}>---{el}---</p>;
                         },
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "note",
                         text: "หมายเหตุสำหรับพนักงานขาย",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "note_manager",
                         text: "หมายเหตุสำหรับผู้อนุมัติ",
                         headerClasses: "header-custom __btn-ml",
                    },
                    { dataField: "user_do_name", text: "ผู้ดำเนินการ", headerClasses: "header-custom __btn-ml" },
               ],
               data: get_log_app.length ? [get_log_app[get_log_app.length - 1]] : [],
               keyField: "Function",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รับชำระเงินค่ารถ`,
                                   onClick: () => window.location.assign("/admin/payment/car"),
                              },
                              {
                                   title: `การชำระเงินค่ารถ (${order?.order_code_id || ""})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order.order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         {/* รายการ */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"รายการ"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "ชื่อลูกค้า : ",
                                                            detail:
                                                                 (order.userinfo_prefix ? order.userinfo_prefix : "") + order?.userinfo_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "เลขประจำตัวผู้เสียภาษี : ",
                                                            detail: order?.userinfo_idcard || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "ที่อยู่ :",
                                                            detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ :",
                                                            detail: order.userinfo_tel ? this.telFormat(order.userinfo_tel) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "เลขที่ใบจอง : ",
                                                            detail: order?.order_code_id || "-",
                                                       }}
                                                       textHeader_style={"underline"}
                                                       // onClickLabelBar={() => window.location.assign(`/admin/order/car/detail${order.order_id}`)}
                                                       onClickLabelBar={this.getOrderLeasingPdf}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: order?.saler_name_string || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                   </div>
                                   <div className="content" id="table-content">
                                        <FormTable order={order} type="car-payment" />
                                   </div>
                              </div>
                         </div>
                         {order?.insurance === "Yes" && (
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content">
                                             <LabelBar type={"title"} title={"ข้อมูลประกันภัย"} />
                                             <Field
                                                  type={"text"}
                                                  title={"เลขที่รับแจ้งประกันภัย"}
                                                  name={"insurance_recive_no"}
                                                  placeholder={"กรอกเลขที่รับแจ้งประกันภัย"}
                                                  handleChangeText={(e) => this.handleChangeTextInsurance(e)}
                                                  value={order?.insurance_recive_no}
                                                  md={6}
                                             />

                                             <Field
                                                  type={"file"}
                                                  title={{
                                                       name: "ข้อมูลประกันภัย",
                                                  }}
                                                  name={"insurance_file"}
                                                  placeholder={this.state.insu_placeholder}
                                                  md={6}
                                                  handleChange={(e) => this.handleChangeFileInsurance(e, "file")}
                                                  accept={".png,.jpg,.pdf"}
                                             />
                                             <ModalPanel
                                                  show={this.state.insurance_file_path}
                                                  title={"ข้อมูลประกันภัย"}
                                                  type={"image"}
                                                  image={order?.insurance_file_path}
                                                  onHide={() => this.setState({ insurance_file_path: false })}
                                             />

                                             <div className="button-group __submit row">
                                                  {order?.insurance_file_path && (
                                                       <Col xs={6}>
                                                            <Button
                                                                 variant="outline-primary"
                                                                 onClick={() => this.setState({ insurance_file_path: true })}
                                                            >
                                                                 ดูไฟล์
                                                            </Button>
                                                       </Col>
                                                  )}

                                                  <Col xs={6}>
                                                       {order?.insurance_file_path ? (
                                                            <Button disabled={order?.acept_status === "ชำระเงินแล้ว"} onClick={this.onSaveInsurance}>
                                                                 แก้ไข
                                                            </Button>
                                                       ) : (
                                                            <Button disabled={order?.acept_status === "ชำระเงินแล้ว"} onClick={this.onSaveInsurance}>
                                                                 บันทึก
                                                            </Button>
                                                       )}
                                                  </Col>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         )}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"หมายเหตุ"} />

                                        <Table type="document" column={log.column_manager} data={log.data} keyField={log.keyField} />
                                   </div>
                              </div>
                         </div>
                         {/* สรุปค่าใช้จ่าย */}
                         {/* {console.log(`checkedReciept`, checkedReciept)} */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"สรุปค่าใช้จ่าย"} />
                                        {!loading && (
                                             <Table
                                                  // type={this.state.role === 19 || this.state.role === 25 ? "add" : "summary"}
                                                  type={"add"}
                                                  add={"text"}
                                                  leasing="__pay"
                                                  column={detail_pay.column}
                                                  data={checkedReciept}
                                                  keyField={detail_pay.keyField}
                                                  handleClick={this.onOpenModal}
                                             />
                                        )}
                                        {checkedReciept.some((el) => el.receipt_method.includes("ใบลดหนี้")) &&
                                        checkedReciept.find((el) => el.receipt_method.includes("ใบลดหนี้")).check ? (
                                             <Row>
                                                  <Col xl={12}>
                                                       <strong>สาเหตุการลดหนี้ :</strong>
                                                  </Col>
                                                  <Col xl={12}>
                                                       <Field
                                                            type={"text"}
                                                            name="note_reducing"
                                                            placeholder="กรอกสาเหตุการลดหนี้"
                                                            // require={true}
                                                            value={this.state.note_reducing}
                                                            handleChangeText={(e) => this.setState({ note_reducing: e.target.value })}
                                                            disabled={
                                                                 checkedReciept.find((el) => el.receipt_method.includes("ใบลดหนี้")).receipt_code
                                                            }
                                                       />
                                                  </Col>
                                             </Row>
                                        ) : null}

                                        <Row className="input-auto">
                                             {/* {order.pay_choice !== "เงินสด" && (
                                                  <> */}
                                             <Col xl={2} md>
                                                  <strong>หักซับดาวน์ :</strong>
                                             </Col>
                                             <Col xl={{ span: 2, offset: 3 }} md>
                                                  <span className="summary-value">
                                                       {order.orcp_status && order.orcp_status.includes("รอ") && order.sub_down
                                                            ? order.sub_down.toLocaleString(undefined, {
                                                                   maximumFractionDigits: 2,
                                                                   minimumFractionDigits: 2,
                                                              })
                                                            : "0.00"}
                                                  </span>
                                             </Col>
                                             <Col xl={5} md>
                                                  บาท
                                             </Col>
                                             {/* </>
                                             )} */}

                                             <Col xl={2} md>
                                                  <strong>รวมจ่ายเงิน :</strong>
                                             </Col>
                                             <Col xl={{ span: 2, offset: 3 }} md>
                                                  <span className="summary-value">
                                                       {checkPrice.toLocaleString(undefined, {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                       })}
                                                  </span>
                                             </Col>
                                             <Col xl={5} md>
                                                  บาท
                                             </Col>
                                        </Row>
                                        {openModal ? (
                                             <ModalPanel
                                                  type={"select"}
                                                  title={"เพิ่มรายละเอียดเงินรับชั่วคราว"}
                                                  show={openModal}
                                                  table_data={payment_status === "ชำระเงินแล้ว" ? detail_pay_comp : detail_pay_modal}
                                                  onHide={this.onOpenModal}
                                                  button={[
                                                       {
                                                            name: "+ Add",
                                                            variant: "primary",
                                                            onClick: () => this.onAddReceipt(),
                                                       },
                                                       {
                                                            name: "Cancel",
                                                            variant: "danger",
                                                            onClick: () => this.setState({ openModal: false }),
                                                       },
                                                  ]}
                                             />
                                        ) : null}
                                   </div>
                              </div>
                         </div>
                         {/* ประวัติการรับเงิน */}
                         {order?.orcp_payment_method || order?.orcp_leasing_payment_method || (payment_log && payment_log.length > 0) ? (
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content">
                                             <LabelBar type={"title"} title={"ประวัติการรับเงิน"} />
                                             {(order.orcp_payment_method &&
                                                  JSON.parse(order.orcp_payment_method).filter((el) => el.page !== "รับเงินจากไฟแนนซ์")) ||
                                             (order.orcp_leasing_payment_method &&
                                                  JSON.parse(order.orcp_leasing_payment_method).filter((el) => el.page !== "รับเงินจากไฟแนนซ์")) ? (
                                                  <>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader-genpayment"}
                                                                 title={{
                                                                      name: "วันที่รับชำระเงิน :",
                                                                      detail: (
                                                                           <i
                                                                                className="fas fa-print icon __btn active"
                                                                                style={{ textDecoration: "underline" }}
                                                                                onClick={() => this.downloadPdf(order.orcp_date, true)}
                                                                           >
                                                                                {order?.orcp_date ? dateFormatter(order?.orcp_date) : "-"}
                                                                           </i>
                                                                      ),
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                       <ModalPanel
                                                            show={this.state.showmodal_file}
                                                            title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                            type={"image"}
                                                            image={`${ip}/static/${row.file}`}
                                                            onHide={() => this.setState({ showmodal_file: false })}
                                                       />
                                                       {/* </div> */}
                                                       <div id="table-content" style={{ padding: "0 15px" }}>
                                                            {/* {this.renderTable()} */}
                                                            <Table
                                                                 type={"summary"}
                                                                 column={table_detail_payment.column}
                                                                 data={
                                                                      detailPayment.length > 0
                                                                           ? detailPayment
                                                                           : detailPayment2.length > 0
                                                                           ? detailPayment2
                                                                           : []
                                                                 }
                                                                 keyField={table_detail_payment.keyField}
                                                            />
                                                       </div>
                                                       <ModalPanel
                                                            show={this.state.showmodal_sign}
                                                            title={"หลักฐานการชำระเงิน"}
                                                            type={"image"}
                                                            image={order?.orcp_file}
                                                            onHide={() => this.setState({ showmodal_sign: false })}
                                                       />{" "}
                                                  </>
                                             ) : null}
                                        </div>
                                        {payment_log &&
                                             payment_log.length > 0 &&
                                             payment_log.map(
                                                  (el) =>
                                                       el.orcp_payment_method.length > 0 && (
                                                            <div className="content">
                                                                 <Row>
                                                                      <LabelBar
                                                                           type={"textheader-genpayment"}
                                                                           title={{
                                                                                name: "วันที่รับชำระเงิน :",
                                                                                detail: (
                                                                                     <i
                                                                                          className="fas fa-print icon __btn active"
                                                                                          style={{ textDecoration: "underline" }}
                                                                                          onClick={() => this.downloadPdf(el.orcp_date, false)}
                                                                                     >
                                                                                          {el?.orcp_date ? dateFormatter(el?.orcp_date) : "-"}
                                                                                     </i>
                                                                                ),
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                 </Row>
                                                                 <ModalPanel
                                                                      show={this.state.showmodal_file}
                                                                      title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                                      type={"image"}
                                                                      image={`${ip}/static/${row.file}`}
                                                                      onHide={() => this.setState({ showmodal_file: false })}
                                                                 />
                                                                 <div id="table-content" style={{ padding: "0 15px" }}>
                                                                      <Table
                                                                           type={"summary"}
                                                                           column={table_detail_payment_log.column}
                                                                           data={el.orcp_payment_method}
                                                                           keyField={table_detail_payment_log.keyField}
                                                                      />
                                                                 </div>
                                                                 <ModalPanel
                                                                      show={this.state.showmodal_sign}
                                                                      title={"หลักฐานการชำระเงิน"}
                                                                      type={"image"}
                                                                      image={el?.orcp_file}
                                                                      onHide={() => this.setState({ showmodal_sign: false })}
                                                                 />
                                                            </div>
                                                       )
                                             )}
                                   </div>
                              </div>
                         ) : null}

                         {/* ตารางรับเงิน*/}
                         {/* {alert(Number(checkPrice) >= 0)} */}
                         {/* {console.log(`Number(checkPrice)`, Number(checkPrice))} */}
                         {Number(checkPrice) >= 0 ? (
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content">
                                             <LabelBar type={"title"} title={"การรับเงิน"} />
                                             <Field
                                                  type={"date"}
                                                  title={"วันที่ชำระเงิน"}
                                                  name={"orcp_date"}
                                                  placeholder={"เลือกวันที่"}
                                                  md={6}
                                                  formatDate={"DD/MM/YYYY"}
                                                  value={this.state.datePay || dayjs(new Date())}
                                                  require={false}
                                                  handleSelectDate={this.handleSelectDate}
                                             />
                                        </div>
                                        <div className="content checkcenter" id="table-content">
                                             {!loading &&
                                                  (loadingPayment ? (
                                                       <div className="-padding">
                                                            <Skeleton type={"document"} column={table.column} />
                                                       </div>
                                                  ) : (
                                                       <div className="-padding">
                                                            <Table
                                                                 type={"summary"}
                                                                 column={table.column}
                                                                 data={table.data}
                                                                 keyField={table.keyField}
                                                            />
                                                       </div>
                                                  ))}
                                        </div>
                                        {/* {console.log("checkPrice >>> ", checkPrice, "this.totalPay >> ", this.totalPay())} */}
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  {/* {console.log(`checkPrice`, order.orcp_status && order.orcp_status)} */}
                                                  {save_loading ? (
                                                       <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            Loading...
                                                       </Button>
                                                  ) : (
                                                       <Button
                                                            disabled={this.totalPay() !== "0.00"}
                                                            onClick={() => {
                                                                 let { checkedReciept, order, note_reducing } = this.state;
                                                                 let check = checkedReciept.find((el) => el.receipt_method.includes("ใบลดหนี้"));
                                                                 // console.log(`check`, check);
                                                                 if (
                                                                      (check && check.check && (note_reducing || check.receipt_code)) ||
                                                                      (check && !check.check) ||
                                                                      !check
                                                                 ) {
                                                                      if (order.orcp_status && order.orcp_status.includes("รอ")) {
                                                                           this.setState({ show_sign: true });
                                                                      } else {
                                                                           this.onSubmit();
                                                                      }
                                                                 } else {
                                                                      swal("แจ้งเตือน!", "กรุณากรอกสาเหตุการลดหนี้ก่อน", "warning");
                                                                 }
                                                            }}
                                                       >
                                                            ยืนยันการชำระเงิน
                                                       </Button>
                                                  )}
                                             </Col>
                                             <ModalPanel
                                                  show={this.state.show_sign}
                                                  save={this.saveSign}
                                                  clear={this.clear}
                                                  type={"sign"}
                                                  title={"ยืนยันการรับเงินจอง"}
                                                  sub_title={"ลายเซ็นลูกค้า"}
                                                  onHide={() => {
                                                       this.setState({ show_sign: false });
                                                  }}
                                             />
                                        </div>
                                   </div>
                              </div>
                         ) : null}
                    </div>
               </div>
          );
     }
}
