import React, { Component } from 'react'
import { GET } from "api/index";

export default class Locate extends Component {
    componentDidMount = async () => {
        try {
            // window.open(await POST(`/authen/locate/${this.props.match.params.link}/${this.props.match.params.branch}?${this.props.match.params.array}`))
            window.location.assign(await GET(`/authen/locate/${this.props.match.params.link}/${this.props.match.params.branch}?${this.props.match.params.array}`))

        } catch (error) {
            console.log(error)
        }

    }
    render() {
        return (
            <div>

            </div>
        )
    }
}
