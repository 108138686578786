import React, { Component } from "react";
import { Breadcrumbs, LabelBar, Table } from "components/index";
import { Row, Button } from "react-bootstrap";
import swal from "sweetalert";
import { POST } from "api";
import dayjs from "dayjs";
export default class Detail extends Component {
     constructor(props) {
          super(props);
          this.state = {
               file: [],
               id: this.props.match.params.id,
               insurance: {},
               title: [],
          };
     }

     componentDidMount = () => {
          this.getInsurance();
          this.setPath();
     };

     getInsurance = async () => {
          try {
               let insurance = await POST("/ps/this_insurance", {
                    insurance_id: this.state.id,
               });
               insurance.files.forEach((element, i) => {
                    element.no = i + 1;
               });
               this.setState({
                    insurance: { ...insurance },
                    file: insurance.files,
               });
          } catch (error) {
               console.log(error);
          }
     };

     /* Remove insurance */
     handleClickRemove = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         let { id } = this.props.match.params;
                         await POST("/aos/del_ins_type", { id });
                         swal({
                              title: "success",
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                         }).then(() => this.props.history.push("/admin/insurance"));
                    }
               });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     handleClick = () => {
          let { id } = this.state;
          window.location.assign(`/admin/insurance/detail/edit/${id}`);
     };

     /* Formatter */

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          console.log(cell);
          return (
               <a href={row.file} download>
                    <Button>ดูเอกสาร </Button>
               </a>
          );
     }

     setPath = async () => {
          let { title } = this.state;

          title = [
               {
                    title: "ประกัน",
                    onClick: () => window.location.assign("/admin/insurance"),
               },
               {
                    title: "ข้อมูล",
                    // onClick: () => this.handleNext(false),
               },
          ];

          this.setState({ title: title });
     };

     render() {
          let { id, file, insurance, title } = this.state;
          const column = [
               {
                    dataField: "no",
                    text: "ลำดับ",
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "name",
                    text: "ไฟล์เอกสาร",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "detail",
                    text: "ดูเอกสาร",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __right __btn-s",
               },
          ];
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={title}
                         active={title.length - 1}
                         button={[
                              {
                                   name: "แก้ไขข้อมูล",
                                   icon: "fas fa-pen-square",
                                   handleClick: this.handleClick,
                                   variant: "primary",
                              },
                              {
                                   name: "ลบข้อมูล",
                                   icon: "fas fa-trash alt",
                                   handleClick: this.handleClickRemove,
                                   variant: "danger",
                              },
                         ]}
                         onClick={() => this.props.history.goBack()}
                    />

                    <div className="content __input-panel">
                         {/* addresscus Detail */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* ข้อมูลประกัน */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Create : ",
                                                            detail: insurance?.nameuser_create,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Update ล่าสุด :",
                                                            detail: insurance?.nameuser_update,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Create : ",
                                                            detail: dayjs(insurance?.created_at).format("DD/MM/") + (dayjs(insurance?.created_at).year() + 543),
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Update ล่าสุด : ",
                                                            detail: dayjs(insurance?.updated_at).format("DD/MM/") + (dayjs(insurance?.updated_at).year() + 543),
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        {/* ข้อมูลประเภทประกัน */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูลประเภทประกัน"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ประเภทประกัน : ",
                                                            detail: insurance?.insurance_type,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "บริษัทไฟแนนซ์ : ",
                                                            detail: insurance?.supplier_name,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สถาณะ : ",
                                                            detail: insurance?.status,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                        <div>
                                             <LabelBar type={"title"} title={"ไฟล์เอกสาร	"} />
                                             <div className="content" id="table-content">
                                                  <Table type={"custom"} keyField={"no"} column={column} data={file} />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
