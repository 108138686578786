import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel } from "components/index";
import { Form, Row, Col, Button, Image, Spinner } from "react-bootstrap";
import { POST, GET, ip } from "api/index";
import swal from "sweetalert";

function ScrollToTopOnMount() {
     React.useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

export default class ManageItem extends Component {
     constructor(probs) {
          super(probs);
          this.state = {
               previous_path: this.props.match.params.manage,
               insurance: [],
               leasing: [],
               item: {
                    items_type: "อุปกรณ์ตกแต่ง",
                    status: "ใช้งาน",
                    addTypeItems: [],
                    deleteTypeItem: [],
                    addtype: [],
                    price_list: {
                         cost: 0,
                         cost_vat: 0,
                         price_add_old: 0,
                         price_add_new: 0,
                         difference_add_and_cost: 0,
                         commission_add_sale: 0,
                         commission_add_company: 0,
                         price: 0,
                         difference_price_and_cost: 0,
                         commission_price_sale: 0,
                         commission_price_company: 0,
                    },
               },
               supplier: [],
               series: [],
               model: [],
               colors: [],
               modaAddInsurance: [],
               dataModalInsurance: [],
               modaAddLeasing: [],
               dataModalLeasing: [],
               deleteTypeItem: [],
               title: [],
               placeholder: "อัพโหลดรูปภาพ (ถ้ามี)",
               loading: false,
               data_series: [],
          };
     }
     componentDidMount = async () => {
          this.setPath();
          this.getSupplier();
          this.getSeries();
          this.getInsurance();
          this.getLeasing();
     };

     setPath = async () => {
          let { previous_path, title } = this.state;
          switch (previous_path) {
               case "add":
                    // title = ["Item", "เพิ่ม Item"];
                    title = [
                         {
                              title: "รายการ Item",
                              onClick: () => window.location.assign("/admin/item"),
                         },
                         {
                              title: "เพิ่ม Item",
                              // onClick: () => this.handleNext(false),
                         },
                    ];
                    break;
               case "edit":
                    title = [
                         // { title: "รายการ Item", onClick: () => window.location.assign("/admin/item") },
                         { title: "รายการ Item", onClick: () => window.location.assign("/admin/item") },
                         // { title: "ข้อมูล", onClick: () => window.location.assign(`/admin/item/detail${this.props.match.params.id}`) },
                         { title: "ข้อมูล", onClick: () => this.props.history.goBack() },
                         { title: "แก้ไขข้อมูล Item" },
                    ];
                    this.getItem();
                    break;
               default:
                    break;
          }
          title = this.setState({ title: title });
     };

     getItem = async () => {
          let { placeholder } = this.state;
          try {
               let item = await POST("/item/this", {
                    items_id: this.props.match.params.id,
               });

               this.getModel(item.series_id);
               this.getColors(item.model_id);

               this.setState({ item: item });
               if (this.state.previous_path === "edit") {
                    if (item.acc_image) {
                         placeholder = item.acc_image;
                    }
                    this.getLeasing(item);
                    this.getInsurance(item);

                    let oldItem = [];
                    if (item.addTypeItem) {
                         item.addTypeItems.map((el) => {
                              return oldItem.push(el);
                         });
                    }
                    this.setState({ item: { ...item }, oldItem, placeholder });
               }
          } catch (error) {
               console.log(error);
          }
     };

     getSupplier = async () => {
          try {
               this.setState({ supplier: [...(await GET("/ps/get_suppliers"))] });
          } catch (error) {
               console.log(error);
          }
     };

     getSeries = async () => {
          try {
               this.setState({ series: [...(await GET("/car/series"))] });
          } catch (error) {
               console.log(error);
          }
     };

     getModel = async (series_id) => {
          try {
               let model = await POST("/car/model", { series_id });
               this.setState({ model });
          } catch (error) {
               console.log(error);
          }
     };

     getColors = async (model_id) => {
          try {
               let colors = await POST("/car/color", { model_id });
               this.setState({ colors });
          } catch (error) {
               console.log(error);
          }
     };

     handleSubmit = async (event) => {
          event.preventDefault();

          let { item, data_series } = this.state;
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    icon: "error",
                    button: "เสร็จสิ้น",
               });
               event.stopPropagation();
          } else {
               try {
                    item["model_id"] = item.model_id ? item.model_id : null;
                    item["color_id"] = item.color_id ? item.color_id : null;
                    this.setState({ loading: true });
                    await POST("/item/add", { ...item, data_series });
                    swal({
                         text: "เพิ่ม item ในรายการเรียบร้อยแล้ว",
                         icon: "success",
                         button: "เสร็จสิ้น",
                    }).then(() => this.props.history.push("/admin/item"));
                    event.preventDefault();
               } catch (error) {
                    swal({
                         text: error,
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          }

          this.setState({
               validated: true,
          });
     };

     handleEdit = async () => {
          let { item, deleteTypeItem, oldItem } = this.state;
          try {
               let addType = [];
               let addTypeItem = [];
               if (item.items_type === "Leasing" && item.addTypeItems[0]?.insurance_id) {
                    item.addTypeItems.map((e) => deleteTypeItem.push(e.items_type_id));
               } else if (item.items_type === "ประกัน" && item.addTypeItems[0]?.leasing_type) {
                    item.addTypeItems.map((e) => deleteTypeItem.push(e.items_type_id));
               }
               if (item.items_type === "Leasing" || item.items_type === "ประกัน") {
                    item.addTypeItems.map((el) => {
                         if (el.items_type_id) {
                              addTypeItem.push(el);
                         }
                    });
                    item.addTypeItems.map((el) => {
                         if (!el.items_type_id) {
                              addType.push(el);
                         }
                    });
                    item.addTypeItems = oldItem;
                    item.addtype = addType;
                    item.deleteTypeItem = deleteTypeItem;
               } else {
                    item.addtype = [];
                    item.deleteTypeItem = [];
                    item.addTypeItems = oldItem;
               }
               this.setState({ loading: true });
               await POST("/item/edit", { ...item });
               swal({
                    text: "แก้ไข Item ในรายการเรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => {
                    // window.location.assign(`/admin/item/detail${this.props.match.params.id}`);
                    window.location.reload();
               });
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { item } = this.state;
          item[name] = value;
          if (name === "items_type") {
               this.getInsurance();
               this.getLeasing();
          }

          if (name === "series_id") {
               this.getModel(value);
               item.model_id = "";
               item.color_id = "";
          }

          if (name === "model_id") {
               this.getColors(value);
               item.color_id = "";
          }
          this.setState({ item });
     };

     handleChangeNumberFun = (e) => {
          let { item } = this.state;
          item.items_fun = e.floatValue;
          this.setState({ item });
     };

     handleChangeNumberPrice = (e) => {
          let { item } = this.state;
          item.items_price = e.floatValue;
          this.setState({ item });
     };

     handleChangeNumber = (e) => {
          let { item } = this.state;
          item.items_price_customer = e.floatValue;
          this.setState({ item });
     };

     handleChangeFile = (e) => {
          let { item, placeholder } = this.state;
          let fileUploaded = e.target.files;

          if (e.target.files && e.target.files[0]) {
               let reader = new FileReader();
               reader.onload = (e) => {
                    item.acc_image = e.target.result.split(",")[1];
                    this.setState({ item });
               };
               reader.readAsDataURL(e.target.files[0]);
          }

          if (fileUploaded[0] && fileUploaded[0].name && fileUploaded[0].length > 25) {
               let str = fileUploaded[0].name;
               placeholder = str.substring(0, 25) + "...";
          } else {
               placeholder = fileUploaded[0].name;
          }

          this.setState({ item, placeholder, showImage: false, hidenImg: true });
     };

     getInsurance = async (item) => {
          try {
               let getInsurance = await GET("/ps/get_insurance");
               this.setState({
                    insurance: [...getInsurance],
                    dataModalInsurance: [...getInsurance],
               });
               if (this.state.previous_path === "edit" && item?.items_type === "ประกัน") {
                    let { dataModalInsurance } = this.state;
                    item.addTypeItems.forEach((element) => {
                         let index = dataModalInsurance
                              .map(function (e) {
                                   return e.insurance_id;
                              })
                              .indexOf(element.insurance_id);
                         dataModalInsurance.splice(index, 1);
                    });
                    this.setState({
                         dataModalInsurance,
                    });
               }
          } catch (error) {
               console.log("error ", error);
          }
     };

     getLeasing = async (item) => {
          try {
               let getLeasing = await GET("/ps/get_leasing");
               this.setState({
                    leasing: [...getLeasing],
                    dataModalLeasing: [...getLeasing],
               });
               if (this.state.previous_path === "edit" && item?.items_type === "Leasing") {
                    let { dataModalLeasing } = this.state;
                    item.addTypeItems.forEach((element) => {
                         let index = dataModalLeasing
                              .map(function (e) {
                                   return e.leasinge_id;
                              })
                              .indexOf(element.leasinge_id);
                         dataModalLeasing.splice(index, 1);
                    });
                    this.setState({
                         dataModalLeasing,
                    });
               }
          } catch (error) {
               console.log("error ", error);
          }
     };

     handleClick = async () => {
          let { dataModalInsurance, dataModalLeasing, show } = this.state;
          dataModalInsurance.sort((a, b) => parseFloat(a.insurance_id) - parseFloat(b.insurance_id));
          dataModalLeasing.sort((a, b) => parseFloat(a.leasinge_id) - parseFloat(b.leasinge_id));
          this.setState({
               show: !show,
               dataModalInsurance,
               dataModalLeasing,
               dataLeasing: [],
          });
     };

     onHide = () => {
          this.setState({
               show: false,
          });
     };

     removeInsurance = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemoveInsurance(row, rowIndex)} variant="danger">
                    Remove
               </Button>
          );
     };

     removeLeasing = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemoveLeasing(row, rowIndex)} variant="danger">
                    Remove
               </Button>
          );
     };

     onRemoveInsurance = (row, rowIndex) => {
          console.log(row, rowIndex);
          let { dataModalInsurance, item, deleteTypeItem } = this.state;
          dataModalInsurance.push(row);
          deleteTypeItem.push(row.items_type_id);
          item.addTypeItems.splice(rowIndex, 1);
          this.setState({ dataModalInsurance, item, deleteTypeItem });
     };

     onRemoveLeasing = (row, rowIndex) => {
          console.log(row, rowIndex);
          let { dataModalLeasing, item, deleteTypeItem } = this.state;
          dataModalLeasing.push(row);
          deleteTypeItem.push(row.items_type_id);
          item.addTypeItems.splice(rowIndex, 1);
          this.setState({ dataModalLeasing, item, deleteTypeItem });
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row)}
               />
          );
     };

     onChangeCheckboxAdd = async (checked, row) => {
          let { modaAddInsurance, item, modaAddLeasing } = this.state;
          ///check
          if (checked) {
               if (item.items_type === "ประกัน") {
                    modaAddInsurance.push(row.insurance_id);
                    this.setState({ modaAddInsurance });
               } else {
                    modaAddLeasing.push(row.leasinge_id);
                    this.setState({ modaAddLeasing });
               }
               ///uncheck
          } else {
               if (item.items_type === "ประกัน") {
                    modaAddInsurance.splice(
                         modaAddInsurance.findIndex((el) => el === row.insurance_id),
                         1
                    );
                    this.setState({ modaAddInsurance });
               } else {
                    modaAddLeasing.splice(
                         modaAddLeasing.findIndex((el) => el === row.leasinge_id),
                         1
                    );
                    this.setState({ modaAddLeasing });
               }
          }
     };

     onAddInurance = () => {
          let { modaAddInsurance, item, dataModalInsurance } = this.state;
          dataModalInsurance.map((element) => {
               if (modaAddInsurance.find((e) => e === element.insurance_id)) {
                    item.addTypeItems.push(element);
               }
          });
          modaAddInsurance.forEach((element) => {
               let index = dataModalInsurance
                    .map(function (e) {
                         return e.insurance_id;
                    })
                    .indexOf(element);
               dataModalInsurance.splice(index, 1);
          });
          this.setState({ item, show: false, modaAddInsurance: [] });
     };

     onAddLeasing = () => {
          let { modaAddLeasing, item, dataModalLeasing } = this.state;
          dataModalLeasing.map((element) => {
               if (modaAddLeasing.find((e) => e === element.leasinge_id)) {
                    item.addTypeItems.push(element);
               }
          });
          modaAddLeasing.forEach((element) => {
               let index = dataModalLeasing
                    .map(function (e) {
                         return e.leasinge_id;
                    })
                    .indexOf(element);
               dataModalLeasing.splice(index, 1);
          });
          // console.log("itemAdd", item);
          this.setState({ item, show: false, modaAddLeasing: [] });
     };
     noFormatter(cell, row, rowIndex, formatExtraData) {
          return `${rowIndex + 1}.`;
     }
     inputFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    id={`field-temp-number-${rowIndex}`}
                    type={"number"}
                    placeholder={"0.00"}
                    value={row.value}
                    decimalScale={2}
                    handleChangeNumber={(value) => this.handleChangePriceAcc(value, row)}
               />
          );
     };

     handleChangePriceAcc = (value, row) => {
          let { item } = this.state;
          item.price_list[row.name] = value.floatValue;
          this.setState({ item });
     };
     setMultiSelections = (data) => {
          // console.log("data", data);
          this.setState({ data_series: data });
     };
     render() {
          let { validated, show, item, supplier, series, model, colors, previous_path, title, loading, data_series } = this.state;
          const insurance = {
               column: [
                    {
                         dataField: "insurance_type",
                         text: "ประเภทประกัน",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeInsurance,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: this.state.insurance,
               keyField: "no",
          };

          const tableSlectDataInsurance = {
               keyField: "function",
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "insurance_type",
                         text: "ประเภทประกันที่ใช้งานได้",
                         headerClasses: "header-custom __right",
                    },
               ],
               data: this.state.dataModalInsurance,
          };

          const leasing = {
               column: [
                    {
                         dataField: "leasing_type",
                         text: "รายการ Leasing",
                    },
                    {
                         dataField: "leasing_interest",
                         text: "อัตราดอกเบี้ย",
                         headerClasses: "header-custom __right",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeLeasing,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: this.state.leasing,
               keyField: "no",
          };
          const tableSlectDataLeasing = {
               keyField: "function",
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "leasing_type",
                         text: "ประเภทประกันที่ใช้งานได้",
                         headerClasses: "header-custom __right",
                    },
                    {
                         dataField: "leasing_interest",
                         text: "อัตราดอกเบี้ย",
                         headerClasses: "header-custom __right",
                    },
               ],
               data: this.state.dataModalLeasing,
          };
          const tablePriceAcc = {
               keyField: "no",
               column: [
                    {
                         dataField: "no",
                         text: "No.",
                         formatter: this.noFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "title",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         text: "จำนวน (บาท)",
                         formatter: this.inputFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
               ],
               data: [
                    { title: "ต้นทุน", name: "cost", value: item.price_list ? item.price_list.cost : 0 },
                    { title: "ต้นทุนรวมภาษีมูลค่าเพิ่ม", name: "cost_vat", value: item.price_list ? item.price_list.cost_vat : 0 },
                    { title: "ราคาแถมเก่า", name: "price_add_old", value: item.price_list ? item.price_list.price_add_old : 0 },
                    { title: "ราคาแถมใหม่", name: "price_add_new", value: item.price_list ? item.price_list.price_add_new : 0 },
                    {
                         title: "ส่วนต่างราคาแถมกับต้นทุน",
                         name: "difference_add_and_cost",
                         value: item.price_list ? item.price_list.difference_add_and_cost : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาแถมสำหรับพนักงานขาย",
                         name: "commission_add_sale",
                         value: item.price_list ? item.price_list.commission_add_sale : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาแถมสำหรับบริษัท",
                         name: "commission_add_company",
                         value: item.price_list ? item.price_list.commission_add_company : 0,
                    },
                    { title: "ราคาขาย", name: "price", value: item.price_list ? item.price_list.price : 0 },
                    {
                         title: "ส่วนต่างราคาขายกับต้นทุน",
                         name: "difference_price_and_cost",
                         value: item.price_list ? item.price_list.difference_price_and_cost : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาขายสำหรับพนักงานขาย",
                         name: "commission_price_sale",
                         value: item.price_list ? item.price_list.commission_price_sale : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาขายสำหรับบริษัท",
                         name: "commission_price_company",
                         value: item.price_list ? item.price_list.commission_price_company : 0,
                    },
               ],
          };

          return (
               <div>
                    <ScrollToTopOnMount />
                    {title ? (
                         <Breadcrumbs
                              title={title}
                              active={title.length - 1}
                              button={""}
                              // onClick={() => this.handleNext(false)}
                         />
                    ) : null}
                    <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                         <div className="content __input-panel">
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ประเภท Item</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             {/* Show Room Field */}
                                             <div>
                                                  <Form.Row>
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"ประเภท Item"}
                                                            name={"items_type"}
                                                            label={[
                                                                 "อุปกรณ์ตกแต่ง (TOYOTA)",
                                                                 "อุปกรณ์ตกแต่ง (Out Source Supplier)",
                                                                 "ประกัน",
                                                                 "Leasing",
                                                            ]}
                                                            value={item?.items_type}
                                                            handleChangeText={this.handleChangeText}
                                                            disabled={previous_path === "edit" && true}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              {/* ข้อมูลอุปกรณ์ตกแต่ง */}
                              {item?.items_type === "อุปกรณ์ตกแต่ง (TOYOTA)" || item?.items_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)" ? (
                                   <>
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>ข้อมูลอุปกรณ์ตกแต่ง</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       {/* Show Room Field */}
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Code"}
                                                                      name={"items_code"}
                                                                      placeholder={"กรุณากรอก Item Code"}
                                                                      require={true}
                                                                      value={item?.items_code}
                                                                      md={item?.items_name && item?.items_name.length > 50 ? 12 : 6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Name"}
                                                                      name={"items_name"}
                                                                      placeholder={"กรุณากรอก Item Name"}
                                                                      require={true}
                                                                      as={item?.items_name && item?.items_name.length > 50 ? "textarea" : undefined}
                                                                      value={item?.items_name}
                                                                      md={item?.items_name && item?.items_name.length > 50 ? 12 : 6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Name (สคบ.)"}
                                                                      name={"name_reserve"}
                                                                      placeholder={"กรอก Item Name (สคบ.)"}
                                                                      warning_title={"*กำหนดการกรอก 26 ตัวอักษร"}
                                                                      value={item?.name_reserve}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"Supplier Name"}
                                                                      name={"supplier_id"}
                                                                      placeholdText={"เลือก Supplier Name"}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (item?.items_type === "อุปกรณ์ตกแต่ง (TOYOTA)") {
                                                                                     if (e.supplier_type === "อุปกรณ์ตกแต่ง (TOYOTA)") {
                                                                                          return e;
                                                                                     }
                                                                                }
                                                                                if (item?.items_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)") {
                                                                                     if (e.supplier_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)") {
                                                                                          return e;
                                                                                     }
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      value={item?.supplier_id}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Account Code"}
                                                                      name={"items_account"}
                                                                      placeholder={"กรุณากรอก Account Code"}
                                                                      require={false}
                                                                      value={item?.items_account}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                                      name={"items_taxbuy"}
                                                                      placeholder={"กรอก รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                                      require={false}
                                                                      value={item?.items_taxbuy}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                                      name={"items_taxpay"}
                                                                      placeholder={"กรอก รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                                      require={false}
                                                                      value={item?.items_taxpay}
                                                                      md={previous_path === "add" ? 12 : 6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 {previous_path === "add" ? (
                                                                      <Field
                                                                           type={"typeahead"}
                                                                           title={"Series"}
                                                                           // name={"series_id"}
                                                                           placeholdText={"เลือก Series"}
                                                                           option={series?.map((e) => ({
                                                                                id: e.series_id,
                                                                                name: e.series_name,
                                                                           }))}
                                                                           require={true}
                                                                           md={12}
                                                                           setMultiSelections={this.setMultiSelections}
                                                                           multiSelections={data_series}
                                                                      />
                                                                 ) : (
                                                                      <Field
                                                                           type={"select"}
                                                                           title={"Series"}
                                                                           name={"series_id"}
                                                                           placeholdText={"เลือก Series"}
                                                                           option={series?.map((e) => ({
                                                                                value: e.series_id,
                                                                                name: e.series_name,
                                                                           }))}
                                                                           require={true}
                                                                           value={item?.series_id}
                                                                           md={6}
                                                                           handleChangeText={this.handleChangeText}
                                                                      />
                                                                 )}
                                                                 {previous_path !== "add" && (
                                                                      <>
                                                                           <Field
                                                                                type={"select"}
                                                                                title={"Model"}
                                                                                name={"model_id"}
                                                                                placeholdText={"เลือก Model"}
                                                                                option={model?.map((e) => ({
                                                                                     value: e.model_id,
                                                                                     name: e.model_name,
                                                                                }))}
                                                                                // require={true}
                                                                                value={item?.model_id}
                                                                                md={6}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           <Field
                                                                                type={"select"}
                                                                                title={"Color"}
                                                                                name={"color_id"}
                                                                                placeholdText={"กรุณาเลือก Color"}
                                                                                option={colors?.map((e) => ({
                                                                                     value: e.color_id,
                                                                                     name: e.color_name,
                                                                                }))}
                                                                                // require={true}
                                                                                value={item?.color_id}
                                                                                md={6}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </>
                                                                 )}

                                                                 {/* <LabelBar
                                                                      type={"radio"}
                                                                      title={"สถานะ"}
                                                                      name={"status"}
                                                                      label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                                      value={item?.status}
                                                                      handleChangeText={this.handleChangeText}
                                                                 /> */}
                                                                 <Field
                                                                      type={"file"}
                                                                      md={12}
                                                                      placeholder={this.state.placeholder}
                                                                      value={null}
                                                                      button={"แนบไฟล์"}
                                                                      style_id={"custom-btn __beside-btn-nohight"}
                                                                      handleChange={(e) => this.handleChangeFile(e)}
                                                                 />
                                                            </Form.Row>
                                                            {previous_path === "edit" && !this.state.hidenImg && item.acc_image && (
                                                                 <Form.Row>
                                                                      <div className="button-group __submit row">
                                                                           <Col xs={6}>
                                                                                <Button
                                                                                     md={6}
                                                                                     variant={"success"}
                                                                                     onClick={() =>
                                                                                          this.setState({ showImage: !this.state.showImage })
                                                                                     }
                                                                                >
                                                                                     ดูเอกสาร
                                                                                </Button>
                                                                           </Col>
                                                                           {this.state.showImage === true && (
                                                                                <Image
                                                                                     width={400}
                                                                                     height={400}
                                                                                     style={{ marginTop: 20 }}
                                                                                     src={ip + item.acc_image + `?time=${new Date()}`}
                                                                                />
                                                                           )}
                                                                      </div>
                                                                 </Form.Row>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>ราคา</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content" id="table-content">
                                                       {/* Show Room Field */}
                                                       <div>
                                                            <Table
                                                                 type={"document"}
                                                                 add={"text"}
                                                                 column={tablePriceAcc.column}
                                                                 data={tablePriceAcc.data}
                                                                 keyField={tablePriceAcc.keyField}
                                                            />
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </>
                              ) : null}

                              {/* ข้อมูลประกัน */}
                              {item?.items_type === "ประกัน" && (
                                   <>
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>ข้อมูลประกัน</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       {/* Show Room Field */}
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Code"}
                                                                      name={"items_code"}
                                                                      placeholder={"กรุณากรอก Item Code"}
                                                                      require={true}
                                                                      value={item?.items_code}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Name"}
                                                                      name={"items_name"}
                                                                      placeholder={"กรุณากรอก Item Name"}
                                                                      require={true}
                                                                      value={item?.items_name}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"Supplier Name"}
                                                                      name={"supplier_id"}
                                                                      placeholdText={"เลือก Supplier Name"}
                                                                      // option={supplier?.map((e) => ({
                                                                      //      value: e.supplier_id,
                                                                      //      name: e.supplier_name,
                                                                      // }))}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (e.supplier_type === "ประกัน") {
                                                                                     return e;
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      value={item?.supplier_id}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ตำแหน่ง"}
                                                                      name={"items_position"}
                                                                      placeholder={"กรุณากรอก ตำแหน่งงาน"}
                                                                      require={true}
                                                                      value={item?.items_position}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                                      name={"items_taxbuy"}
                                                                      placeholder={"กรอกรหัสภาษีซื้อ  (Tax Classification Code)"}
                                                                      require={false}
                                                                      value={item?.items_taxbuy}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                                      name={"items_taxpay"}
                                                                      placeholder={"กรอกรหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                                      require={false}
                                                                      value={item?.items_taxpay}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"หมายเลขโทรศัพท์ผู้ติดต่อ"}
                                                                      name={"items_phone"}
                                                                      placeholder={"กรอกหมายเลขโทรศัพท์ผู้ติดต่อ"}
                                                                      require={true}
                                                                      value={item?.items_phone}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"E-Mail"}
                                                                      name={"items_mail"}
                                                                      placeholder={"กรอก E-Mail"}
                                                                      require={true}
                                                                      value={item?.items_mail}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 {/* <LabelBar
                                                                      type={"radio"}
                                                                      title={"สถานะ"}
                                                                      name={"status"}
                                                                      label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                                      value={item?.status}
                                                                      // disabled={order?.insurance === 'No'}
                                                                      handleChangeText={this.handleChangeText}
                                                                 /> */}
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* <div className="input-field">
                                             <div className="-header">
                                                  <div>เลือกประเภทประกัน</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Table
                                                                 type={"add"}
                                                                 add={"text"}
                                                                 column={insurance.column}
                                                                 data={item?.addTypeItems}
                                                                 keyField={insurance.keyField}
                                                                 handleClick={this.handleClick}
                                                            />
                                                            {show ? (
                                                                 <ModalPanel
                                                                      type={"select"}
                                                                      title={"insuranceSet"}
                                                                      show={show}
                                                                      table_data={tableSlectDataInsurance}
                                                                      onHide={this.onHide}
                                                                      button={[
                                                                           {
                                                                                name: "+ Add",
                                                                                variant: "primary",
                                                                                onClick: this.onAddInurance,
                                                                           },
                                                                           {
                                                                                name: "Cancel",
                                                                                variant: "danger",
                                                                                onClick: this.onHide,
                                                                           },
                                                                      ]}
                                                                 />
                                                            ) : null}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div> */}
                                   </>
                              )}

                              {/* Leasing */}

                              {item?.items_type === "Leasing" && (
                                   <>
                                        <div className="input-field">
                                             <div className="-header">
                                                  <div>ข้อมูล Leasing</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       {/* Show Room Field */}
                                                       <div>
                                                            <Form.Row>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Code"}
                                                                      name={"items_code"}
                                                                      placeholder={"กรุณากรอก Item Code"}
                                                                      require={true}
                                                                      value={item?.items_code}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Item Name"}
                                                                      name={"items_name"}
                                                                      placeholder={"กรุณากรอก Item Name"}
                                                                      require={true}
                                                                      value={item?.items_name}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"Supplier Name"}
                                                                      name={"supplier_id"}
                                                                      placeholdText={"เลือก Supplier Name"}
                                                                      option={supplier
                                                                           ?.filter((e) => {
                                                                                if (e.supplier_type === "Leasing") {
                                                                                     return e;
                                                                                }
                                                                           })
                                                                           .map((e) => ({
                                                                                value: e.supplier_id,
                                                                                name: e.supplier_name,
                                                                           }))}
                                                                      value={item?.supplier_id}
                                                                      require={true}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"Account Code"}
                                                                      name={"items_account"}
                                                                      placeholder={"กรุณากรอก Account Code"}
                                                                      require={false}
                                                                      value={item?.items_account}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                                      name={"items_taxbuy"}
                                                                      placeholder={"กรอก รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                                      require={false}
                                                                      value={item?.items_taxbuy}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                                      name={"items_taxpay"}
                                                                      placeholder={"กรอก รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                                      require={false}
                                                                      value={item?.items_taxpay}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"หมายเลขโทรศัพท์ตัวแทน"}
                                                                      name={"items_telagent"}
                                                                      placeholder={"กรอกหมายเลขโทรศัพท์ตัวแทน"}
                                                                      require={true}
                                                                      value={item?.items_phone}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"E-Mail"}
                                                                      name={"items_email"}
                                                                      placeholder={"กรอก E-Mail"}
                                                                      require={true}
                                                                      value={item?.items_mail}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ID LINE"}
                                                                      name={"items_idline"}
                                                                      placeholder={"กรอก ID Line"}
                                                                      require={true}
                                                                      value={item?.items_line}
                                                                      md={6}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 {/* <LabelBar
                                                                      type={"radio"}
                                                                      title={"สถานะ"}
                                                                      name={"status"}
                                                                      label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                                      value={item?.status}
                                                                      handleChangeText={this.handleChangeText}
                                                                 /> */}
                                                            </Form.Row>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* <div className="input-field">
                                             <div className="-header">
                                                  <div>เลือกประเภท Leasing</div>
                                             </div>
                                             <div className="-body">
                                                  <div className="content">
                                                       <div>
                                                            <Table
                                                                 type={"add"}
                                                                 add={"text"}
                                                                 column={leasing.column}
                                                                 data={item?.addTypeItems}
                                                                 keyField={leasing.keyField}
                                                                 handleClick={this.handleClick}
                                                            />
                                                            {show ? (
                                                                 <ModalPanel
                                                                      type={"select"}
                                                                      title={"leasingSet"}
                                                                      show={show}
                                                                      table_data={tableSlectDataLeasing}
                                                                      onHide={this.onHide}
                                                                      button={[
                                                                           {
                                                                                name: "+ Add",
                                                                                variant: "primary",
                                                                                onClick: this.onAddLeasing,
                                                                           },
                                                                           {
                                                                                name: "Cancel",
                                                                                variant: "danger",
                                                                                onClick: this.onHide,
                                                                           },
                                                                      ]}
                                                                 />
                                                            ) : null}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div> */}
                                   </>
                              )}

                              <div className="input-field">
                                   {previous_path === "add" && (
                                        <div className="button-group __submit row">
                                             <Col xs={12}>
                                                  {loading ? (
                                                       <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            Loading...
                                                       </Button>
                                                  ) : (
                                                       <Button disabled={loading} type="submit">
                                                            Submit
                                                       </Button>
                                                  )}
                                             </Col>
                                             {/* <Col xs={6}>
                                                  <Button variant="danger">Reset</Button>
                                             </Col> */}
                                        </div>
                                   )}
                                   {previous_path === "edit" && (
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  {loading ? (
                                                       <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            Loading...
                                                       </Button>
                                                  ) : (
                                                       <Button onClick={this.handleEdit} disabled={loading}>
                                                            บันทึกการแก้ไข
                                                       </Button>
                                                  )}
                                             </Col>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </Form>
               </div>
          );
     }
}
