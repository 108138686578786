import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel } from "components/index";
import { Form, Row, Col, Button, Image, Spinner } from "react-bootstrap";
import { POST, GET, ip } from "api/index";
import swal from "sweetalert";

function ScrollToTopOnMount() {
     React.useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

export default class ManageItemEdit extends Component {
     constructor(probs) {
          super(probs);
          this.state = {
               previous_path: this.props.match.params.manage,
               item: {
                    items_type: "อุปกรณ์ตกแต่ง",
                    status: "ใช้งาน",
                    addTypeItems: [],
                    addtype: [],
                    price_list: {
                         cost: 0,
                         cost_vat: 0,
                         price_add_old: 0,
                         price_add_new: 0,
                         difference_add_and_cost: 0,
                         commission_add_sale: 0,
                         commission_add_company: 0,
                         price: 0,
                         difference_price_and_cost: 0,
                         commission_price_sale: 0,
                         commission_price_company: 0,
                    },
               },
               supplier: [],
               series: [],
               title: [],
               loading: false,
               data_series: [],
          };
     }
     componentDidMount = async () => {
          this.setPath();
          this.getSupplier();
          this.getSeries();
          this.getItem();
     };

     setPath = async () => {
          let { previous_path, title } = this.state;
          title = [
               {
                    title: "รายการ Item",
                    onClick: () => window.location.assign("/admin/item"),
               },
               {
                    title: "แก้ไข Item",
                    // onClick: () => this.handleNext(false),
               },
          ];
          title = this.setState({ title: title });
     };

     getItem = async () => {
          try {
               let item = await POST("/item/this", {
                    items_id: this.props.match.params.id,
               });
               this.setState(
                    {
                         item: item,
                         oldItem: { items_name_old: item.items_name, supplier_id_old: item.supplier_id, items_type_old: item.items_type },
                    },
                    () => this.getItemSeries()
               );
          } catch (error) {
               console.log(error);
          }
     };

     getSupplier = async () => {
          try {
               this.setState({ supplier: [...(await GET("/ps/get_suppliers"))] });
          } catch (error) {
               console.log(error);
          }
     };

     getSeries = async () => {
          try {
               this.setState({ series: [...(await GET("/car/series"))] });
          } catch (error) {
               console.log(error);
          }
     };
     getItemSeries = async () => {
          try {
               let { item } = this.state;
               // console.log("item", item);
               let res = await POST("/aos/get_item_series", {
                    items_name: item.items_name,
                    items_type: item.items_type,
                    supplier_id: item.supplier_id,
               });
               // console.log("res", res);
               this.setState({ data_series: res });
          } catch (error) {
               console.log(error);
          }
     };
     handleEdit = async () => {
          let { item, oldItem, data_series } = this.state;
          try {
               let news = {
                    items_type: item.items_type,
                    items_code: item.items_code,
                    items_name: item.items_name,
                    name_reserve: item.name_reserve,
                    items_account: item.items_account,
                    items_taxbuy: item.items_taxbuy,
                    items_taxpay: item.items_taxpay,
                    price_list: item.price_list,
               };
               let olds = { items_name: oldItem.items_name_old, supplier_id: oldItem.supplier_id_old, items_type: oldItem.items_type_old };

               this.setState({ loading: true });
               await POST("/aos/item_series_edit", { data_series, news, olds });
               swal({
                    text: "แก้ไข Item ในรายการเรียบร้อยแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { item } = this.state;
          item[name] = value;
          this.setState({ item });
     };

     handleChangeNumberFun = (e) => {
          let { item } = this.state;
          item.items_fun = e.floatValue;
          this.setState({ item });
     };

     handleChangeNumberPrice = (e) => {
          let { item } = this.state;
          item.items_price = e.floatValue;
          this.setState({ item });
     };

     handleChangeNumber = (e) => {
          let { item } = this.state;
          item.items_price_customer = e.floatValue;
          this.setState({ item });
     };

     noFormatter(cell, row, rowIndex, formatExtraData) {
          return `${rowIndex + 1}.`;
     }
     inputFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    id={`field-temp-number-${rowIndex}`}
                    type={"number"}
                    placeholder={"0.00"}
                    value={row.value}
                    decimalScale={2}
                    handleChangeNumber={(value) => this.handleChangePriceAcc(value, row)}
               />
          );
     };

     handleChangePriceAcc = (value, row) => {
          let { item } = this.state;
          item.price_list[row.name] = value.floatValue;
          this.setState({ item });
     };
     setMultiSelections = (data) => {
          // console.log("data", data);
          this.setState({ data_series: data });
     };
     render() {
          let { validated, item, supplier, series, previous_path, title, loading, data_series } = this.state;
          const tablePriceAcc = {
               keyField: "no",
               column: [
                    {
                         dataField: "no",
                         text: "No.",
                         formatter: this.noFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "title",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         text: "จำนวน (บาท)",
                         formatter: this.inputFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
               ],
               data: [
                    { title: "ต้นทุน", name: "cost", value: item.price_list ? item.price_list.cost : 0 },
                    { title: "ต้นทุนรวมภาษีมูลค่าเพิ่ม", name: "cost_vat", value: item.price_list ? item.price_list.cost_vat : 0 },
                    { title: "ราคาแถมเก่า", name: "price_add_old", value: item.price_list ? item.price_list.price_add_old : 0 },
                    { title: "ราคาแถมใหม่", name: "price_add_new", value: item.price_list ? item.price_list.price_add_new : 0 },
                    {
                         title: "ส่วนต่างราคาแถมกับต้นทุน",
                         name: "difference_add_and_cost",
                         value: item.price_list ? item.price_list.difference_add_and_cost : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาแถมสำหรับพนักงานขาย",
                         name: "commission_add_sale",
                         value: item.price_list ? item.price_list.commission_add_sale : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาแถมสำหรับบริษัท",
                         name: "commission_add_company",
                         value: item.price_list ? item.price_list.commission_add_company : 0,
                    },
                    { title: "ราคาขาย", name: "price", value: item.price_list ? item.price_list.price : 0 },
                    {
                         title: "ส่วนต่างราคาขายกับต้นทุน",
                         name: "difference_price_and_cost",
                         value: item.price_list ? item.price_list.difference_price_and_cost : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาขายสำหรับพนักงานขาย",
                         name: "commission_price_sale",
                         value: item.price_list ? item.price_list.commission_price_sale : 0,
                    },
                    {
                         title: "คอมมิชชั่นราคาขายสำหรับบริษัท",
                         name: "commission_price_company",
                         value: item.price_list ? item.price_list.commission_price_company : 0,
                    },
               ],
          };
          // console.log("data_series", data_series);
          return (
               <div>
                    <ScrollToTopOnMount />
                    {title ? (
                         <Breadcrumbs
                              title={title}
                              active={title.length - 1}
                              button={""}
                              // onClick={() => this.handleNext(false)}
                         />
                    ) : null}
                    <Form noValidate validated={validated}>
                         <div className="content __input-panel">
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ประเภท Item</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             {/* Show Room Field */}
                                             <div>
                                                  <Form.Row>
                                                       <LabelBar
                                                            type={"radio"}
                                                            title={"ประเภท Item"}
                                                            name={"items_type"}
                                                            label={[
                                                                 "อุปกรณ์ตกแต่ง (TOYOTA)",
                                                                 "อุปกรณ์ตกแต่ง (Out Source Supplier)",
                                                                 "ประกัน",
                                                                 "Leasing",
                                                            ]}
                                                            value={item?.items_type}
                                                            handleChangeText={this.handleChangeText}
                                                            disabled={previous_path === "edit" && true}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              {/* ข้อมูลอุปกรณ์ตกแต่ง */}

                              <div className="input-field">
                                   <div className="-header">
                                        <div>ข้อมูลอุปกรณ์ตกแต่ง</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             {/* Show Room Field */}
                                             <div>
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"Item Code"}
                                                            name={"items_code"}
                                                            placeholder={"กรุณากรอก Item Code"}
                                                            require={true}
                                                            value={item?.items_code}
                                                            md={item?.items_name && item?.items_name.length > 50 ? 12 : 6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Item Name"}
                                                            name={"items_name"}
                                                            placeholder={"กรุณากรอก Item Name"}
                                                            require={true}
                                                            as={item?.items_name && item?.items_name.length > 50 ? "textarea" : undefined}
                                                            value={item?.items_name}
                                                            md={item?.items_name && item?.items_name.length > 50 ? 12 : 6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Item Name (สคบ.)"}
                                                            name={"name_reserve"}
                                                            placeholder={"กรอก Item Name (สคบ.)"}
                                                            warning_title={"*กำหนดการกรอก 26 ตัวอักษร"}
                                                            value={item?.name_reserve}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"Supplier Name"}
                                                            name={"supplier_id"}
                                                            placeholdText={"เลือก Supplier Name"}
                                                            option={supplier
                                                                 ?.filter((e) => {
                                                                      if (item?.items_type === "อุปกรณ์ตกแต่ง (TOYOTA)") {
                                                                           if (e.supplier_type === "อุปกรณ์ตกแต่ง (TOYOTA)") {
                                                                                return e;
                                                                           }
                                                                      }
                                                                      if (item?.items_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)") {
                                                                           if (e.supplier_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)") {
                                                                                return e;
                                                                           }
                                                                      }
                                                                 })
                                                                 .map((e) => ({
                                                                      value: e.supplier_id,
                                                                      name: e.supplier_name,
                                                                 }))}
                                                            value={item?.supplier_id}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                            disabled={true}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"Account Code"}
                                                            name={"items_account"}
                                                            placeholder={"กรุณากรอก Account Code"}
                                                            require={false}
                                                            value={item?.items_account}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                            name={"items_taxbuy"}
                                                            placeholder={"กรอก รหัสภาษีซื้อ  (Tax Classification Code)"}
                                                            require={false}
                                                            value={item?.items_taxbuy}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                            name={"items_taxpay"}
                                                            placeholder={"กรอก รหัสภาษีหัก ณ ที่จ่าย (Payment Withholding Tax)"}
                                                            require={false}
                                                            value={item?.items_taxpay}
                                                            md={12}
                                                            handleChangeText={this.handleChangeText}
                                                       />

                                                       <Field
                                                            type={"typeahead"}
                                                            title={"Series"}
                                                            // name={"series_id"}
                                                            placeholdText={"เลือก Series"}
                                                            option={series?.map((e) => ({
                                                                 id: e.series_id,
                                                                 name: e.series_name,
                                                            }))}
                                                            require={true}
                                                            md={12}
                                                            setMultiSelections={this.setMultiSelections}
                                                            multiSelections={data_series}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="input-field">
                                   <div className="-header">
                                        <div>ราคา</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content" id="table-content">
                                             {/* Show Room Field */}
                                             <div>
                                                  <Table
                                                       type={"document"}
                                                       add={"text"}
                                                       column={tablePriceAcc.column}
                                                       data={tablePriceAcc.data}
                                                       keyField={tablePriceAcc.keyField}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="input-field">
                                   <div className="button-group __submit row">
                                        <Col xs={6}>
                                             {loading ? (
                                                  <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                       <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                       Loading...
                                                  </Button>
                                             ) : (
                                                  <Button onClick={this.handleEdit} disabled={loading}>
                                                       บันทึกการแก้ไข
                                                  </Button>
                                             )}
                                        </Col>
                                   </div>
                              </div>
                         </div>
                    </Form>
               </div>
          );
     }
}
