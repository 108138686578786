import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Skeleton } from "components/index";
import { POST, GET } from "api/index";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";

export default class supplier_list extends Component {
     constructor(props) {
          super(props);
          this.state = {
               suppliers: [],
               file: {},
               fileTextHolder: "",
               suppliers_backup: [],
               search: {},
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getSupplier();
     };
     getSupplier = async () => {
          try {
               let suppliers = await GET("/ps/get_suppliers");
               suppliers = suppliers.sort((end, start) => parseFloat(start.supplier_id) - parseFloat(end.supplier_id)).filter((el) => el.acept_status === "อนุมัติ");
               // console.log(suppliers)
               this.setState({ suppliers, suppliers_backup: suppliers });
          } catch (error) {
               console.log(error);
          }
     };
     onSearch = () => {
          let { suppliers_backup, search } = this.state;
          // console.log(search)

          let suppliers = suppliers_backup.filter(
               (e) =>
                    (search.supplier_code ? String(e.supplier_code).includes(String(search.supplier_code)) : e) &&
                    (search.supplier_name ? String(e.supplier_name).toLowerCase().includes(String(search.supplier_name).toLowerCase()) : e) &&
                    (search.status
                         ? String(e.status).toLowerCase() === String(search.status).toLowerCase() || String(search.status).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.supplier_type === "อื่น ๆ"
                         ? !this.linearSearch(e.supplier_type)
                              ? e
                              : null
                         : search.supplier_type
                         ? String(e.supplier_type).toLowerCase() === String(search.supplier_type).toLowerCase() ||
                           String(search.supplier_type).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.acept_status
                         ? String(e.acept_status).toLowerCase() === String(search.acept_status).toLowerCase() ||
                           String(search.acept_status).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ suppliers });
          // console.log("suppliers", suppliers)
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          // console.log(search);
          this.setState({ ...search });
     };
     handleClick = () => {
          window.location.assign("/admin/supplier/detail/add");
     };

     editFormatter(cell, row, rowIndex, formatExtraData) {
          return <i style={{ cursor: "pointer" }} className="fas fa-pen-square" onClick={() => window.location.assign(`/admin/supplier/detail${row.supplier_id}`)}></i>;
     }

     handleChange = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };

     handleUploadFile = async () => {
          const { file, search } = this.state;
          try {
               let formData = new FormData();
               if (file && search.supplier_type) {
                    formData.append("type", search.supplier_type);
                    formData.append("file", file);
                    this.setState({ loading: true });
                    await POST("/aos/" + search.supplier_type, formData, true);
                    await this.getSupplier();
                    search.supplier_type = "";
                    this.setState({ loading: false, fileTextHolder: "", file: null, search });
               }
          } catch (error) {
               swal({
                    text: "กรุณาอัปโหลดให้ถูกประเภท",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               this.setState({ loading: false });
          }
     };

     linearSearch = (value) => {
          const supplier_type = [
               { value: "TMT", name: "TMT" },
               { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง(TOYOTA)", name: "อุปกรณ์ตกแต่ง(TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง(Out Source Supplier)", name: "อุปกรณ์ตกแต่ง(Out Source Supplier)" },
               { value: "ประกัน", name: "ประกัน" },
               { value: "Leasing", name: "Leasing" },
               { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
          ];
          for (let i = 0; i < supplier_type.length; i++) {
               if (supplier_type[i].value === value) {
                    return true;
               }
          }
          return false;
     };

     downloadFile = async () => {
          try {
               let { search } = this.state;
               let download = "";
               if (search.supplier_type) {
                    // console.log(`search.supplier_type`, search.supplier_type);
                    switch (search.supplier_type) {
                         case "sup_dealer":
                              download = "vendor";
                              break;
                         case "sup_vendor_tmt_master":
                              download = "vendor";
                              break;
                         case "sup_vendor_out_master":
                              download = "vendor";
                              break;
                         case "sup_insurance_master":
                              download = "insuranceco";
                              break;
                         case "sup_leasing_master":
                              download = "leasing";
                              break;
                         case "sup_register":
                              download = "register";
                              break;
                         default:
                              break;
                    }
                    window.open(await POST("/ps/download_excel", { download }));
               }
          } catch (error) {
               swal({
                    text: "ดาวน์โหลดไม่สำเร็จ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     render() {
          let { fileTextHolder, search, loading } = this.state;
          const column = [
               {
                    dataField: "edit",
                    text: "ข้อมูล",
                    formatter: this.editFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "supplier_code",
                    text: "Supplier Code",
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "supplier_name",
                    text: "Supplier Name",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "supplier_type",
                    text: "ประเภท Supplier",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "status",
                    text: "สถานะ",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "acept_status",
                    text: "การอนุมัติ",
                    headerClasses: "header-custom __right  __btn-s",
               },
          ];
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "Supplier" }]}
                         active={0}
                         button={[
                              {
                                   icon: "fas fa-user-plus -icon",
                                   name: "เพิ่ม Supplier",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __main-panel listsupplier">
                         <div className="search-bar">
                              <Search
                                   type={"supplier-list"}
                                   searchOptions={search}
                                   onSearch={this.onSearch}
                                   handleChangeText={this.handleChangeText}
                                   handleChange={this.handleChangeOption}
                              />
                              <Search
                                   type={"upload-supplier"}
                                   title={{
                                        name: "อัปโหลด  Supplier Master",
                                        icon: Img.excel,
                                   }}
                                   placeholder={fileTextHolder !== "" ? fileTextHolder : "ไฟล์.csv"}
                                   typeSup={search}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __below-btn"}
                                   handleChange={this.handleChange}
                                   handleChangeOption={this.handleChangeOption}
                                   handleUploadFile={this.handleUploadFile}
                                   downloadFile={this.downloadFile}
                                   disabled={loading}
                              />
                         </div>
                         {loading ? (
                              <Skeleton type={"index"} column={column} />
                         ) : (
                              <Table
                                   type={"index-fixhead"}
                                   column={column}
                                   data={this.state.suppliers.map((el) => ({ ...el, supplier_code: el.supplier_code ? el.supplier_code : "-" }))}
                                   keyField={"username"}
                              />
                         )}
                    </div>
               </div>
          );
     }
}
