import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Loader } from "../../../../../components/index";
import { Form, Button, Col, Row, Image, Spinner, Modal } from "react-bootstrap";
import swal from "@sweetalert/with-react";
import dayjs from "dayjs";
import { POST, GET, ip } from "api/index";
import { useEffect } from "react";
import NumericInput from "react-numeric-input";
import { getToken, add } from "../../../../../static/function";

function ScrollToTopOnMount() {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class AddCarOrder extends Component {
     constructor(props) {
          super(props);
          this.state = {
               edit_receipt: false,
               validated: false,
               show_modal_add_success: false,
               dateGetCar: new Date(),
               modalShow: false,
               // created_at: new Date(),
               order: {
                    getcar_date: new Date(),
                    pay_choice: "เงินสด",
                    insurance: "Yes",
                    act: "Yes",
                    register: "Yes",
                    // created_at: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    register_pay: "ลูกค้า",
                    red_label: "Yes",
                    act_pay: "ลูกค้า",
                    register_pay: "ลูกค้า",
                    insurance_pay: "ลูกค้า",
                    turn_acc: "ไม่ต้องการ",
                    turn_discount: "Yes",
                    promotions: [],
                    addPromotions: [],
                    deletePromotion: [],
                    accbonus: [],
                    addAccbonus: [],
                    deleteAccbonus: [],
                    accbuy: [],
                    addAccbuy: [],
                    deleteAccbuy: [],
                    accturn: [],
                    addAccturn: [],
                    deleteAccturn: [],
                    sub_down_service: "ไม่แถม",
                    recommend_price_service: "ไม่แถม",
                    receipt: [],
                    addReceipt: [],
                    deletereceipt: [],
                    lvl_margin: 1,
                    insurance_premium: 0,
                    renameReceipt: [],
                    start_price: 0,
                    addition_price: 0,
                    margin: 0,
                    discount_price: 0,
                    vat: 0,
                    real_price: 0,
                    sub_down: 0,
                    recommend_price: 0,
                    down: 0,
                    first_down: 0,
                    subsidy: 0,
                    order_fleet: 0,
                    abroad: "ใน",
               },
               receiptData: [
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเงินดาวน์",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่า พรบ.",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมไฟแนนซ์",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตรเช็ค ตจว.",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าต่อเติม",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าแจ้งย้าย",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าภาษีซัพดาวน์",
                         receipt_price: 0,
                         check: false,
                         disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าประกันสินเชื่อ",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าประกันสินเชื่อ",
                         receipt_price: 0,
                         check: false,
                    },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    // },
               ],
               receipt_data_money: [
                    {
                         receipt_method: "ใบเสร็จรับเงินค่ารถ",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่า พรบ.",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    // {
                    //      receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำป้ายแดง",
                    //      receipt_price: 0,
                    //      check: false,
                    // disabled: true,
                    // },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าต่อเติม",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าแจ้งย้าย",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตร-เช็ค ตจว.",
                         receipt_price: 0,
                         check: false,
                    },
                    // {
                    //      receipt_method: 'ใบรับเงินชั่วคราวรับเงินมาเกิน',
                    //      receipt_price: 0,
                    //      check: false,
                    // disabled: true,
                    // },
                    {
                         receipt_method: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบลดหนี้/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินส่วนต่างมาจิ้น",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
               ],
               receipt_data_finance: [
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเงินดาวน์",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่า พรบ.",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    // {
                    //      receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำป้ายแดง",
                    //      receipt_price: 0,
                    //      check: false,
                    // disabled: true,
                    // },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมไฟแนนซ์",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่างวดงวดแรก",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าประกันสินเชื่อ",
                         receipt_price: 0,
                         check: false,
                    },

                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าต่อเติม",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าแจ้งย้าย",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าภาษีซัพดาวน์",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงินค่า VAT 15%",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงินค่า VAT 7%",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตร-เช็ค ตจว.",
                         receipt_price: 0,
                         check: false,
                    },
                    {
                         receipt_method: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบลดหนี้/ใบกำกับภาษี",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    {
                         receipt_method: "ใบรับเงินส่วนต่างมาจิ้น",
                         receipt_price: 0,
                         check: false,
                         // disabled: true,
                    },
                    // {
                    //      receipt_method: 'ใบรับเงินชั่วคราวรับเงินมาเกิน',
                    //      receipt_price: 0,
                    //      check: false,
                    //      disabled: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
                    // {
                    //      receipt_method: "อื่น ๆ",
                    //      receipt_price: 0,
                    //      check: false,
                    //      other: true,
                    // },
               ],
               modalreceiptData: [],
               accbonus: [],
               accbuy: [],
               accturn: [],
               promotions: [],
               fristSameCon: true,
               sameContact: false,
               fristSameOwn: true,
               sameOwner: false,
               series: [],
               color: [],
               previous_path: this.props.match.params.manage,
               title: [],
               itemInsurance: [],
               model: [],
               discount: [],
               provice: [],
               amphures: [],
               amphuresOwner: [],
               subdistrictOwner: [],
               amphuresContact: [],
               subdistrictContact: [],
               amphuresInfo: [],
               subdistrictInfo: [],
               promotions: [],
               branch: [],
               show: false,
               showPromotion: false,
               showAccBonus: false,
               confirm: false,
               showConfirmEdit: false,
               showMarginCal: false,
               showFinalCal: false,
               showReciept: false,
               supplier: [],
               modalAddPromotion: [],
               deletePromotion: [],
               accbonus: [],
               accbuy: [],
               modalAddAccBonus: [],
               deleteAccbonus: [],
               modalAddAccBuy: [],
               deleteAccbuy: [],
               itemAcc: [],
               deleteAccturn: [],
               promoShow: [],
               accbuyShow: [],
               accbonusShow: [],
               seller: [],
               arr_check: [],
               arr_check_money: [],
               arr_check_ficance: [],
               type_edit: this.props.history.location.state?.type,
               relation_booking_data: ["บิดา", "มารดา", "สามี/ภรรยา", "บุตร"],
               openModalImg: false,
               renameReciptBefore: [],
               loadingData: true,
               order_id_save: null,
               zipCodeUserInfo: [],
               zipCodeContact: [],
               zipCodeOwner: [],
          };
     }

     componentDidMount = async () => {
          this.setPath();
          await this.setState({ user: getToken() });
          await this.getBranch();
          await this.getSeller();
          if (this.state.user.position_name === "พนักงานขาย" && this.state.user.user_id) {
               let { user, seller, order } = this.state;
               await this.handleChangeText({ target: { name: "branch_id", value: user.branch_id } });
               let sell_name = [seller.find((el) => el.user_id === user.user_id)?.name];
               order.seller_phone = seller.find((el) => el.user_id === user.user_id)?.seller_phone;
               this.handleChangeAutocompleteSeller(sell_name);
          }
          this.getSeriesCars();
          this.getDiscount();
          await this.getAddress();
          this.getSupplier();
          await this.getAcardList();
          this.getFunction();
          let orderPass = JSON.parse(localStorage.getItem("orderObject"));
          if (orderPass && this.props.location.state?.order?.pass_acard) {
               let { order, acard_detail, birthDate } = this.state;
               let returnedTarget = Object.assign(order, orderPass);
               //---------------set type-------------------
               let type_customer = returnedTarget.userinfo_customer_type;

               //------------------set date----------------
               returnedTarget.userinfo_birth_date = dayjs(returnedTarget.userinfo_birth_date).format("YYYY-MM-DD");
               birthDate = dayjs(returnedTarget.userinfo_birth_date);

               //------------------set acard----------------
               let acard_name;
               if (returnedTarget.userinfo_customer_type === "นิติบุคคล") {
                    acard_name = acard_detail.find((el) => el.acard_no === returnedTarget.userinfo_a_card)?.acard_firstname;
               } else {
                    acard_name = acard_detail.find((el) => el.acard_no === returnedTarget.userinfo_a_card)?.acard_fullname;
               }
               returnedTarget.userinfo_a_card = returnedTarget.userinfo_a_card.concat(` ${acard_name}`);
               this.setState({ order: returnedTarget, acardno: returnedTarget.userinfo_a_card, birthDate, acard_name });

               //-----------------get address----------------
               this.getAddress("userinfo_province", order.userinfo_province);
               this.getAddress("userinfo_district", order.userinfo_province, order.userinfo_district);
               this.getAddress("userinfo_sub_district", order.userinfo_province, order.userinfo_district, order.userinfo_sub_district);

               //--------------------getCar------------------------
               if (order.series_id) this.getModel(order.series_id);
               if (order.model_id) this.getColors(order.model_id);
               if (order.series_id && order.model_id && order.color_id) this.getPriceNew(order.series_id, order.model_id, order.color_id);

               //-------------------get ACC turn------------------
               if (order.series_id && order.model_id && order.color_id) this.getSupplierTurn(order.series_id, order.model_id, order.color_id);

               // //------------------getInsurance-------------------
               // if (order.supplier_insurance_id) this.getInsurance(order.supplier_insurance_id);
               // if (order.item_insurance_id) this.getTypeInsurance(order.item_insurance_id);

               //---------------------get ACC------------------------
               if (order.series_id && order.model_id && order.color_id) this.getAcc(order.series_id, order.model_id, order.color_id);

               this.setState({ type_customer });
          } else if (this.props.location.state?.order_id) {
               let order_id = this.props.location.state?.order_id;
               this.setState({
                    order_id,
               });
               this.getOrderDetail(order_id);
          }
          this.setState({ loadingData: false });
     };

     setPath = (nextStep) => {
          let { previous_path, title } = this.state;
          if (nextStep) {
               title.push({ title: "ตรวจสอบข้อมูล" });
          } else {
               switch (previous_path) {
                    case "add":
                         title = [
                              {
                                   title: "การจองรถยนต์",
                                   onClick: () => window.location.assign("/admin/order/car"),
                              },
                              {
                                   title: "เพิ่มรายการจองรถยนต์",
                                   // onClick: () => this.handleNext(false),
                              },
                         ];
                         break;
                    case "edit":
                         title = [
                              {
                                   title: "การจองรถยนต์",
                                   onClick: () => window.location.assign("/admin/order/car"),
                              },
                              {
                                   title: "แก้ไขใบจองรถยนต์",
                                   onClick: () => window.location.reload(),
                              },
                         ];
                         break;
                    default:
                         break;
               }
          }
          this.setState({ title: title });
     };
     /* ------------------------- Get ---------------------------- */
     getSeller = async () => {
          try {
               this.setState({ seller: await GET("/ps/sellers") });
          } catch (err) {
               console.log(err);
          }
     };
     getFunction = async () => {
          try {
               this.setState({ dataFun: await GET("/aos/get_function") });
          } catch (error) {
               console.log("error", error);
          }
     };
     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getSeriesCars = async () => {
          try {
               let getseries = await GET("/car/series");
               this.setState({ series: [...getseries] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getModel = async (series_id) => {
          let { order } = this.state;
          try {
               let getmodel = await POST("/car/model", { series_id });
               if (order.model_id) {
                    order.model_code = getmodel?.find((el) => Number(el.model_id) === Number(order.model_id)).model_code;
               }
               this.setState({ model: [...getmodel], order });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getColors = async (model_id) => {
          try {
               let getcolor = await POST("/car/color", { model_id });
               this.setState({ color: [...getcolor] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getPrice = async (series_id, model_id, color_id) => {
          console.log("getPrice");
          let { order, previous_path, receipt_data_money, arr_check_money, receipt_data_finance } = this.state;
          try {
               // console.log("previous_path", previous_path);
               let res = await POST("/aos/get_price", {
                    series_id: Number(series_id),
                    model_id: Number(model_id),
                    color_id: Number(color_id),
                    page: "add",
                    order_id: order.order_id,
                    orp_status: order.orp_status,
               });
               // if (previous_path === "add" && order.acept_status === "save") {
               //      if (!order.margin) order.margin = res.margin || 0;
               //      if (!order.register_price) {
               //           if (this.state.type_customer === "นิติบุคคล") {
               //                order.register_price = res.coporation || 0;
               //           } else {
               //                order.register_price = res.register_price || 0;
               //           }
               //      }
               //      order.model_price = res.price || 0;
               //      // order.start_price = order.model_price;
               // } else {
               if (!order.margin) order.margin = res.margin || 0;
               if (!order.register_price) {
                    if (this.state.type_customer === "นิติบุคคล") {
                         order.register_price = res.coporation || 0;
                    } else {
                         order.register_price = res.register_price || 0;
                    }
               }
               order.model_price = res.price;
               // order.start_price = order.model_price;
               // }
               // console.log('order.start_price', order.start_price)
               let act_type = res.act_type;
               if (!arr_check_money.includes(0)) {
                    arr_check_money.push(0);
               }
               receipt_data_money[0].check = true;
               if (this.state.type_customer === "นิติบุคคล") {
                    receipt_data_money[3].receipt_price = res.coporation || 0;
                    receipt_data_finance[3].receipt_price = res.coporation || 0;
               } else {
                    receipt_data_money[3].receipt_price = res.register_price || 0;
                    receipt_data_finance[3].receipt_price = res.register_price || 0;
               }
               this.setState({ order, act_type, receipt_data_money, arr_check_money, receipt_data_finance });
          } catch (error) {
               console.log("error", error);
          }
     };

     getPriceNew = async (series_id, model_id, color_id) => {
          console.log("getPriceNew");
          let { order, previous_path, receipt_data_money, arr_check_money, receipt_data_finance } = this.state;
          try {
               let res = await POST("/aos/get_price", { series_id: Number(series_id), model_id: Number(model_id), color_id: Number(color_id) });
               // console.log("res", res);
               // if (previous_path === "add" && order.acept_status === "save") {
               // if (!order.register_price) {
               if (this.state.type_customer === "นิติบุคคล") {
                    order.register_price = res.coporation || 0;
               } else {
                    order.register_price = res.register_price || 0;
               }
               // }
               order.margin = res.margin || 0;
               order.model_price = res.price || 0;
               order.start_price = order.model_price;
               // } else {
               //      // if (!order.register_price) {
               //      if (this.state.type_customer === "นิติบุคคล") {
               //           order.register_price = res.coporation || 0;
               //      } else {
               //           order.register_price = res.register_price || 0;
               //      }
               //      // }
               //      order.margin = res.margin || 0;
               //      order.model_price = res.price;
               //      order.start_price = order.model_price;
               // }

               let act_type = res.act_type;
               if (!arr_check_money.includes(0)) {
                    arr_check_money.push(0);
               }
               receipt_data_money[0].check = true;
               if (this.state.type_customer === "นิติบุคคล") {
                    receipt_data_money[3].receipt_price = res.coporation || 0;
                    receipt_data_finance[3].receipt_price = res.coporation || 0;
               } else {
                    receipt_data_money[3].receipt_price = res.register_price || 0;
                    receipt_data_finance[3].receipt_price = res.register_price || 0;
               }
               this.setState({ order, act_type, receipt_data_money, arr_check_money, receipt_data_finance });
          } catch (error) {
               console.log("error", error);
          }
     };

     getInsurance = async (supplier_id) => {
          let { order } = this.state;
          try {
               let getInsurance = await POST("/item/insurance", { supplier_id: supplier_id });
               if (getInsurance) {
                    order.item_insurance_id = await getInsurance[0]?.items_id;
               } else {
                    order.item_insurance_id = null;
               }
               this.setState({ itemInsurance: [...getInsurance] });
          } catch (error) {
               console.log("error ", error);
          }
     };
     getTypeInsurance = async () => {
          try {
               let getTypeInsurance = await GET("/ps/get_insurance/");
               this.setState({ typeInsurance: [...getTypeInsurance] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getDiscount = async () => {
          try {
               let getDiscount = await GET("/ps/get_discount");
               this.setState({ discount: [...getDiscount] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getPromotions = async (series_id) => {
          try {
               let getPromotions = await POST("/ps/get_promotion", { series_id: series_id });
               this.setState({ promotions: [...getPromotions] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getAddress = async (name, province_pass, district_pass, sub_district_pass) => {
          try {
               let getAddress = await POST("/aos/search_address", {
                    province: province_pass,
                    district: district_pass,
                    sub_district: sub_district_pass,
               });
               if (getAddress.type === "No") {
                    await this.setState({
                         provice: getAddress.array,
                    });
               } else if (getAddress.type === "province") {
                    if (name === "owner_province") {
                         await this.setState({ amphuresOwner: getAddress.array });
                    } else if (name === "contact_province") {
                         await this.setState({ amphuresContact: getAddress.array });
                    } else if (name === "userinfo_province") {
                         await this.setState({ amphuresInfo: getAddress.array });
                    }
               } else if (getAddress.type === "district") {
                    if (name === "owner_district") {
                         await this.setState({ subdistrictOwner: getAddress.array });
                    } else if (name === "contact_district") {
                         await this.setState({ subdistrictContact: getAddress.array });
                    } else if (name === "userinfo_district") {
                         await this.setState({ subdistrictInfo: getAddress.array });
                    }
               } else if (getAddress.type === "sub_district") {
                    let adress = getAddress.array.map((ele) => ele.postcode);
                    let zip_code = adress;
                    if (name === "owner_sub_district") {
                         await this.setState({
                              zipCodeOwner: zip_code,
                         });
                    } else if (name === "contact_sub_district") {
                         await this.setState({
                              zipCodeContact: zip_code,
                         });
                    } else if (name === "userinfo_sub_district") {
                         await this.setState({
                              zipCodeUserInfo: zip_code,
                         });
                    }
               }
          } catch (error) {
               // console.log(error);
          }
     };

     getAcardList = async () => {
          try {
               let res = await GET("/aos/get_acard");
               let acard_no = res
                    .map((el) => {
                         // if (el.booking) {
                         if (el.acard_customer_type === "นิติบุคคล") {
                              return { label: el.acard_no.concat(` ${el.acard_firstname}`) };
                         } else {
                              return { label: el.acard_no.concat(` ${el.acard_fullname}`) };
                         }
                         // } else return null;
                    })
                    .filter((el) => el);
               this.setState({
                    acard_no,
                    acard_detail: res,
               });
          } catch (error) {
               console.log(error);
          }
     };

     /*---------------------GET EDit Detail-----------------------*/
     getOrderDetail = async (order_id) => {
          let { order, dateGetCar, birthDate, created_at } = this.state;
          try {
               let res = await POST("/order/this", { order_id });
               let returnedTarget = Object.assign(order, res);
               if (returnedTarget.getcar_date) dateGetCar = dayjs(returnedTarget.getcar_date);
               if (returnedTarget.userinfo_birth_date) birthDate = dayjs(returnedTarget.userinfo_birth_date);
               if (returnedTarget.created_at) created_at = dayjs(returnedTarget.created_at);
               await this.setState({
                    order: returnedTarget,
                    acardno: returnedTarget.userinfo_a_card,
                    dateGetCar,
                    birthDate,
                    created_at,
                    renameReciptBefore: returnedTarget.receipt,
                    // receipt_update: returnedTarget.receipt,
               });
               this.setEdit(returnedTarget);
               this.inputReciptPriceFormatter();
          } catch (error) {
               // console.log(error);
          }
     };

     setEdit = async (order) => {
          let type_customer = order.userinfo_customer_type;
          //-----------------user info-----------------------
          this.getAddress("userinfo_province", order.userinfo_province);
          this.getAddress("userinfo_district", order.userinfo_province, order.userinfo_district);
          this.getAddress("userinfo_sub_district", order.userinfo_province, order.userinfo_district, order.userinfo_sub_district);

          //------------------user contact-------------------
          this.getAddress("contact_province", order.contact_province);
          this.getAddress("contact_district", order.contact_province, order.contact_district);
          this.getAddress("contact_sub_district", order.contact_province, order.contact_district, order.contact_sub_district);

          //------------------user owner---------------------
          this.getAddress("owner_province", order.owner_province);
          this.getAddress("owner_district", order.owner_province, order.owner_district);
          this.getAddress("owner_sub_district", order.owner_province, order.owner_district, order.owner_sub_district);

          //---------------detail car------------------------
          if (order.series_id) await this.getModel(order.series_id);
          if (order.model_id) await this.getColors(order.model_id);
          if (order.series_id && order.model_id && order.color_id) await this.getPrice(order.series_id, order.model_id, order.color_id);

          //---------------insurance-------------------------
          if (order.supplier_insurance_id) await this.getInsurance(order.supplier_insurance_id);
          if (order.insurance_type) await this.getTypeInsurance();

          //--------------------Leasing----------------------
          if (order.leasing_down_id) {
               await this.getAgentLeasing(order.leasing_down_id);
               await this.getTypeLeasing();
          }
          // if (order.leasing_down_item_id) await this.getTypeLeasing(order.leasing_down_item_id);

          //--------------------reciept----------------------
          let { receiptData } = this.state;
          if (order.receipt) {
               order.receipt.forEach((element) => {
                    if (this.search(element.receipt_method, receiptData)) {
                         receiptData.splice(
                              receiptData.findIndex((ele) => ele.receipt_method === element.receipt_method),
                              1
                         );
                    }
               });
          }
          //--------------------getAcc----------------------
          if (order.color_id && order.model_id && order.series_id) {
               await this.getAcc(order.series_id, order.model_id, order.color_id);
          }
          let { accbonus, accbuy } = this.state;
          // if (order.accbonus) {
          //      order.accbonus.forEach((element) => {
          //           if (!this.search(element.items_id, accbonus)) {
          //                accbonus.splice(
          //                     accbonus.findIndex((ele) => ele.items_id === element.items_id),
          //                     1
          //                );
          //           }
          //      });
          // }
          // if (order.accbuy) {
          //      order.accbuy.forEach((element) => {
          //           if (!this.search(element.items_id, accbuy)) {
          //                accbuy.splice(
          //                     accbuy.findIndex((ele) => ele.items_id === element.items_id),
          //                     1
          //                );
          //           }
          //      });
          // }

          //-----------------get Acc Turn-------------------
          if (order.series_id && order.model_id && order.color_id) this.getSupplierTurn(order.series_id, order.model_id, order.color_id);

          //------------------getAct---------------------
          // if (order.act_premium && order.act_premium && this.state.act_type) {
          //      order.act_name = this.state.act_type.find((e) => e.act_premium === order.act_premium)?.act_name;
          // }
          
          // if (this.state.act_type) {
          //      if (order.act_name) {
          //      } else if (order.act_premium) {
          //           order.act_name = this.state.act_type.find((e) => e.act_premium === order.act_premium)?.act_name;
          //      } else {
          //           order.act_name = null;
          //      }
          // }

          this.setState({ accbonus, accbuy, type_customer });
     };

     search = (key, arr) => {
          for (var i = 0; i < arr.length; i++) {
               if (arr[i].receipt_method === key) {
                    return true;
               }
          }
     };

     getAgentLeasing = async (id) => {
          try {
               this.setState({ agentLeasing: [...(await POST("/item/leasing_by_supplier", { supplier_id: id }))] });
          } catch (error) {
               console.log("error ", error);
          }
     };
     getTypeLeasing = async (id) => {
          try {
               let { order } = this.state;
               // console.log(`order`, order.series_id , order.leasing_down_id)
               if (order.series_id && order.leasing_down_id) {
                    this.setState({
                         typeLeasing: [...(await GET("/ps/get_leasing"))].filter(
                              (el) => el.supplier_id === Number(order.leasing_down_id) && el.series_id === Number(order.series_id)
                         ),
                    });
               }
               // this.setState({ typeLeasing: [...(await POST('/item/get_type_leasing', { items_id: id }))] });
          } catch (error) {
               console.log("error", error);
          }
     };

     getSupplier = async () => {
          try {
               this.setState({ supplier: [...(await GET("/ps/slect_suppliers"))] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getSupplierTurn = async (series_id, model_id, color_id) => {
          try {
               let res = await POST("/ps/select_supplier_turn", { series_id, model_id, color_id });
               let supplierSelect = [];
               // if (res !== []) {
               if (res.length !== 0) {
                    supplierSelect = res.map((el) => ({ value: el.supplier_id, name: el.supplier_name }));
               } else {
                    supplierSelect = [];
               }
               this.setState({ supplierSelect });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     // getAccessories = async () => {
     //      try {
     //           let getacc = await GET("/item/accessories");
     //           this.setState({ accbonus: [...getacc], accbuy: [...getacc] });
     //      } catch (error) {
     //           console.log("error ", error);
     //      }
     // };

     getAcc = async (series_id, model_id, color_id) => {
          try {
               let getacc = await POST("/aos/get_acc", {
                    series_id: Number(series_id),
                    model_id: Number(model_id),
                    color_id: Number(color_id),
               });
               let accbonus = getacc.filter((el) => el.items_id);
               // let accbuy = getacc.filter((el) => el.items_type !== "อุปกรณ์ตกแต่ง (TOYOTA)");
               let accbuy = getacc.filter((el) => el.items_id);
               setTimeout(() => {
                    this.setState({ accbonus, accbuy });
               }, 1000);
          } catch (error) {
               console.log("error ", error);
          }
     };

     itemAccBySupplier = async (supplier_id) => {
          try {
               let series_id = this.state.order.series_id || null;
               let model_id = this.state.order.model_id || null;
               let color_id = this.state.order.color_id || null;
               this.setState({
                    itemAcc: [...(await POST("/item/accessories_by_supplier", { supplier_id, series_id, model_id, color_id, type: true }))].map(
                         (el) => ({
                              ...el,
                              items_price: el.price_list ? Number(JSON.parse(el.price_list).price_add_new) || 0 : 0,
                         })
                    ),
                    // .filter((el) => el.items_name.includes("เทริน"))
                    // .filter((el) => el.series_id === Number(series_id))
                    // .filter((el) => el.model_id === Number(model_id))
                    // .filter((el) => el.color_id === Number(color_id)),
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     /* ------------------------------------------------------------- */

     /* ------------------------ Handle ----------------------------- */
     /* Handle [back, next] confirm state */
     handleNext = (step) => {
          const { order, receipt_data_finance, receipt_data_money } = this.state;
          this.setState({
               confirm: step,
               order,
               receipt_data_finance,
               receipt_data_money,
               validated: true,
          });
          this.setPath(step);
     };
     /* Validate field on submit */
     handleConfirm = async (event, totalMargin, SubdownAndAccBonus) => {
          let { order, type_edit, previous_path } = this.state;
          event.preventDefault();
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
               swal({
                    text: "กรอกให้ครบ",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               event.preventDefault();
               event.stopPropagation();
               this.setState({ validated: true });
          } else {
               if (order.branch_id && Number(order.branch_id) === 3) {
                    event.preventDefault();
                    this.handleConfirm2();
               } else if (type_edit === "common" && previous_path === "edit") {
                    event.preventDefault();
                    this.handleConfirm2();
               } else {
                    if (Number(SubdownAndAccBonus) > Number(order.margin + order.addition_price) - Number(totalMargin - SubdownAndAccBonus)) {
                         event.preventDefault();
                         swal({
                              title: "ซัพดาวน์เกิน Margin !",
                              text: "ดำเนินการต่อหรือไม่",
                              icon: "warning",
                              buttons: {
                                   cancel: "ยกเลิก",
                                   catch: {
                                        text: "ดำเนินการต่อ",
                                        value: "catch",
                                   },
                              },
                         }).then((value) => {
                              if (value === "catch") {
                                   this.handleConfirm2();
                              } else {
                                   event.preventDefault();
                                   event.stopPropagation();
                                   this.setState({ validated: true });
                              }
                         });
                    } else {
                         event.preventDefault();
                         this.handleConfirm2();
                    }
               }
          }
     };
     handleConfirm2 = () => {
          let { order, receiptData, previous_path, receipt_data_finance } = this.state;
          if (previous_path === "add" && order.acept_status === "save") {
               order.addReceipt = [];
               receiptData.forEach((element) => {
                    if (element.check) {
                         order.addReceipt.push(element);
                    }
               });
               receiptData.forEach((element) => {
                    if (element.check) {
                         order.receipt.push(element);
                    }
               });
          } else if (previous_path === "add" && order.acept_status !== "save") {
               if (this.props.location.state?.order.pass_acard) {
                    this.handleChangeText({ target: { name: "userinfo_a_card", value: order.userinfo_a_card?.split(" ")[0] } });
               }
          }
          if (previous_path === "edit" && order.renameReceipt.length > 0) {
               order.receipt.forEach((element) =>
                    order.renameReceipt.map((el) => {
                         if (el.receipt_id === element.receipt_id) {
                              element.receipt_method = el.receipt_method;
                         }
                    })
               );
          }
          let nextStep = true;
          this.setState({
               validated: false,
               confirm: true,
          });
          this.setPath(nextStep);
     };
     /* Cofirm Order */
     handleSubmit = async (event) => {
          this.setState({ loading: true });
          let { order, order_id_save, receipt_data_money, receipt_data_finance, totalMargin } = this.state;
          event.preventDefault();
          if (order.pay_choice === "ผ่อนชำระ") {
               // order.receipt.splice(
               //      order.receipt.findIndex((el) => el.receipt_method === 'หักซัพดาวน์'),
               //      1
               // );
               // receipt_data_finance.splice(
               //      receipt_data_finance.findIndex((el) => el.receipt_method === 'หักซัพดาวน์'),
               //      1
               // );
               receipt_data_finance.map((el) => {
                    if (el.check) {
                         order.receipt.push({ ...el });
                    }
               });
          } else {
               receipt_data_money.map((el) => {
                    if (el.check) {
                         order.receipt.push({ ...el });
                    }
               });
          }
          try {
               //'log1'
               order.checkMargin = totalMargin > order.margin + order.addition_price;
               order.margin_used = totalMargin;
               // console.log("order", order);
               let res;
               if (order.acept_status === "save" || order_id_save) {
                    order.save = 2;
                    order.lvl_margin = 1;
                    if (order_id_save) {
                         order.order_id = order_id_save.order_id;
                    }
                    res = await POST("/order/edit", order);
               } else {
                    order.save = 0;
                    order.lvl_margin = 1;
                    res = await POST("/order/add", order);
                    this.setState({
                         order_id_new: res.order_id,
                    });
               }
               swal({
                    icon: "success",
                    text: "เพิ่มใบจองรถเรียบร้อยแล้ว",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    window.location.assign(`/admin/order/car/detail${res.order_id}`);
               });
          } catch (error) {
               swal({
                    icon: "error",
                    text: `${error.result || error}`,
                    title: "บันทึกข้อมูลไม่สำเร็จ",
                    buttons: {
                         danger: "ลองใหม่อีกครั้ง",
                    },
               }).then((value) => {
                    this.setState({ loading: false });
               });
               // swal({
               //      title: "failed",
               //      text: "ผิดพลาด! กรุณาลองใหม่อีกครั้ง",
               //      icon: "warning",
               //      button: "ตกลง",
               //      dangerMode: true,
               // }).then((value) => {
               //      this.setState({ loading: false });
               // });
               // console.log(`error`, error);
          }
     };
     /* Reset field on click */
     handleReset = () => {
          window.location.reload();
     };

     handleSave = async (totalMargin) => {
          let { order, order_id_save, order_id } = this.state;
          let marginOver = await (order.margin + order.addition_price - totalMargin);
          try {
               order.checkMargin = totalMargin > order.margin + order.addition_price;
               order.margin_used = totalMargin;
               if (order.branch_id && Number(order.branch_id) === 3) {
                    if (order.acept_status !== "save" && !order_id_save) {
                         order.save = 1;
                         order_id_save = await POST("/order/add", order);
                         swal({
                              icon: "success",
                              text: "บันทึกข้อมูลสำเร็จแล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         });
                         this.setState({ order_id_save });
                    } else if (order.acept_status !== "save" && order_id_save) {
                         order.save = 1;
                         order.order_id = order_id_save.order_id;
                         await POST("/order/edit", order);
                         swal({
                              icon: "success",
                              text: "บันทึกข้อมูลสำเร็จแล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         });
                    } else {
                         order.save = 1;
                         await POST("/order/edit", order);
                         swal({
                              icon: "success",
                              text: "บันทึกข้อมูลสำเร็จแล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         });
                    }
               } else {
                    // if (Math.abs(marginOver) <= order.margin) {
                    if (order.acept_status !== "save" && !order_id_save) {
                         order.save = 1;
                         order_id_save = await POST("/order/add", order);
                         swal({
                              icon: "success",
                              text: "บันทึกข้อมูลสำเร็จแล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         });
                         this.setState({ order_id_save });
                    } else if (order.acept_status !== "save" && order_id_save) {
                         order.save = 1;
                         order.order_id = order_id_save.order_id;
                         await POST("/order/edit", order);
                         swal({
                              icon: "success",
                              text: "บันทึกข้อมูลสำเร็จแล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         });
                    } else {
                         order.save = 1;
                         await POST("/order/edit", order);
                         swal({
                              icon: "success",
                              text: "บันทึกข้อมูลสำเร็จแล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         });
                    }
                    // } else {
                    //      console.log("handleSave");
                    //      swal({
                    //           title: "ใช้ Margin ไม่ถูกต้อง",
                    //           text: "Margin คงเหลือมากว่า Margin หลัก",
                    //           icon: "warning",
                    //           button: "ตกลง",
                    //           dangerMode: true,
                    //      });
                    // }
               }
          } catch (error) {
               swal({
                    icon: "error",
                    text: `${error.result || error}`,
                    title: "บันทึกข้อมูลไม่สำเร็จ",
                    buttons: {
                         danger: "ลองใหม่อีกครั้ง",
                    },
               });
          }
     };

     handleChangeTextSearchAcc = async ({ target: { name, value } }) => {
          let { accbonus, accbuy } = this.state;
          let newSearchAccBonus, newSearchAccBuy;
          let valueSearch = String(value).toLowerCase();
          if (name === "accbonus_text") {
               if (accbonus.length > 0) {
                    newSearchAccBonus = accbonus.filter((el) =>
                         value
                              ? String(el.items_name).toLowerCase().includes(valueSearch) || String(el.items_code).toLowerCase().includes(valueSearch)
                              : el
                    );
                    this.setState({ newSearchAccBonus });
               }
          } else {
               if (accbuy.length > 0) {
                    newSearchAccBuy = accbuy.filter((el) =>
                         value
                              ? String(el.items_name).toLowerCase().includes(valueSearch) || String(el.items_code).toLowerCase().includes(valueSearch)
                              : el
                    );
                    this.setState({ newSearchAccBuy });
               }
          }
     };
     /* Handle Data input change */
     handleChangeText = async ({ target: { name, value } }) => {
          let {
               order,
               itemAcc,
               model,
               arr_check,
               receiptData,
               itemInsurance,
               supplier,
               arr_check_ficance,
               receipt_data_finance,
               receipt_data_money,
               arr_check_money,
               previous_path,
          } = this.state;
          order[name] = value;
          if (name === "series_id") {
               order.model_id = null;
               order.color_id = null;
               order.model_code = "";
               order.start_price = "";
               order.supplier_turn_id = null;
               order.item_turn_id = null;
               order.turn_amount = "";
               order.turn_price = "";
               order.act_premium = null;
               order.act_name = null;
               if (previous_path === "edit") {
                    console.log("reset");
                    //----------------- ข้อมูลประกัน -------------------//
                    order.insurance = "Yes";
                    order.supplier_insurance_id = null;
                    order.type_insurance_id = null;
                    order.insurance_premium = 0;
                    order.insurance_pay = "ลูกค้า";
                    //----------------- พรบ -------------------//
                    order.act = "Yes";
                    order.act_company = null;
                    order.act_name = null;
                    order.act_premium = 0;
                    //----------------- การจดทะเบียน -------------------//
                    order.register = "Yes";
                    order.register_pay = "ลูกค้า";
                    //----------------- ป้ายแดง -------------------//
                    order.red_label = "Yes";
                    //----------------- Car Accessories (แถม) Car Accessories (ลูกค้าซื้อเอง) -------------------//
                    // console.log("order.accbonus", order.accbonus);
                    // console.log("order.accbuy", order.accbuy);
                    if (order.accbonus.length) {
                         order.deleteAccbonus = order.accbonus.map((el) => el.orderac_id);
                    }
                    if (order.accbuy.length) {
                         order.deleteAccbuy = order.accbuy.map((el) => el.orderac_id);
                    }
                    order.accbonus = [];
                    order.accbuy = [];
                    //----------------- turn ------------------//
                    // order.turn_acc = "ไม่ต้องการ";
                    // order.accTurnCheck = null;
                    // order.carTurnCheck = null;
                    // order.accturn = [];
                    //----------------------------------------//
               }
               await this.getModel(value);
               await this.getPromotions(value);
               await this.getTypeLeasing();
          } else if (name === "model_id") {
               await this.getColors(value);
               // await this.getSupplierTurn(order.series_id, value);
               order.color_id = null;
               // order.supplier_turn_id = null;
               // order.item_turn_id = null;
               // order.turn_amount = "";
               // order.turn_amount = "";
               // order.act_premium = null;
               // order.act_name = null;
               let modelSelect = model.find((e) => {
                    if (Number(e.model_id) === Number(value)) {
                         return e.model_code;
                    }
               });
               order.model_code = modelSelect?.model_code;
          } else if (name === "color_id") {
               // order.act_name = null;
               // order.act_premium = null;
               await this.getPriceNew(order.series_id, order.model_id, order.color_id);
               await this.getAcc(order.series_id, order.model_id, order.color_id);
               await this.getSupplierTurn(order.series_id, order.model_id, order.color_id);
          } else if (name === "supplier_insurance_id") {
               let ins_premium = supplier.filter((el) => el.supplier_id === Number(value));
               let pay = order.insurance_pay;
               order.insurance_premium = ins_premium[0].insurance_premium && pay === "บริษัท" ? Number(ins_premium[0].insurance_premium) : 0;
               await this.getInsurance(value);
               order.type_insurance_id = null;
               await this.getTypeInsurance();
          } else if (name === "supplier_turn_id") {
               order.item_turn_id = null;
               order.turn_amount = "";
               order.turn_price = "";
               this.itemAccBySupplier(value);
          } else if (name === "item_turn_id") {
               order.turn_price = itemAcc.find((e) => e.items_id === Number(order.item_turn_id)).items_price;
          } else if (name === "leasing_down_id") {
               await this.getAgentLeasing(value);
               await this.getTypeLeasing();
               order.leasing_down_item_id = null;
               order.leasing_down_leasing_id = null;
          } else if (name === "leasing_down_item_id") {
               // await this.getTypeLeasing(value);
               order.leasing_down_leasing_id = null;
          } else if (name === "pay_choice" && previous_path === "add") {
               if (value === "เงินสด") {
                    if (order.act_premium) {
                         if (!arr_check_money.find((el) => el === 1)) {
                              arr_check_money.push(1);
                         }
                         receipt_data_money[1].check = true;
                    }
                    if (order.insurance_premium) {
                         if (!arr_check_money.find((el) => el === 2)) {
                              arr_check_money.push(2);
                         }
                         receipt_data_money[2].check = true;
                    }
                    if (order.register_price) {
                         if (!arr_check_money.find((el) => el === 3)) {
                              arr_check_money.push(3);
                         }
                         receipt_data_money[3].check = true;
                    }
                    if (order.order_price >= 0) {
                         if (!arr_check_money.find((el) => el === 10)) {
                              arr_check_money.push(10);
                         }
                         receipt_data_money[10].check = true;
                    }
               } else {
                    if (order.act_premium) {
                         if (!arr_check_ficance.find((el) => el === 1)) {
                              arr_check_ficance.push(1);
                         }
                         receipt_data_finance[1].check = true;
                    }
                    if (order.insurance_premium) {
                         if (!arr_check_ficance.find((el) => el === 2)) {
                              arr_check_ficance.push(2);
                         }
                         receipt_data_finance[2].check = true;
                    }
                    if (order.register_price) {
                         if (!arr_check_ficance.find((el) => el === 3)) {
                              arr_check_ficance.push(3);
                         }
                         receipt_data_finance[3].check = true;
                    }
                    if (order.order_price >= 0) {
                         if (!arr_check_ficance.find((el) => el === 16)) {
                              arr_check_ficance.push(16);
                         }
                         receipt_data_finance[16].check = true;
                    }
               }
          } else if (name === "branch_id") {
               let seller_set = this.state.seller.filter((el) => value === el.branch_id);
               this.setState({ seller_set });
          } else if (name === "register_pay" && previous_path === "add") {
               if (value === "ลูกค้า" && order.register === "Yes") {
                    if (order.pay_choice === "เงินสด") {
                         if (!arr_check_money.find((el) => el === 3)) {
                              arr_check_money.push(3);
                              receipt_data_money[3].check = true;
                         }
                    } else {
                         if (!arr_check_ficance.find((el) => el === 3)) {
                              arr_check_ficance.push(3);
                              receipt_data_finance[3].check = true;
                         }
                    }
               } else {
                    if (order.pay_choice === "เงินสด") {
                         if (arr_check_money.find((el) => el === 3)) {
                              arr_check_money.splice(
                                   arr_check_money.findIndex((el) => el === 3),
                                   1
                              );
                              receipt_data_money[3].check = false;
                         }
                    } else {
                         if (arr_check_ficance.find((el) => el === 3)) {
                              arr_check_ficance.splice(
                                   arr_check_ficance.findIndex((el) => el === 3),
                                   1
                              );
                              receipt_data_finance[3].check = false;
                         }
                    }
               }
          } else if (name === "register" && previous_path === "add") {
               if (value === "Yes" && order.register_pay === "ลูกค้า") {
                    if (order.pay_choice === "เงินสด") {
                         if (!arr_check_money.find((el) => el === 3)) {
                              arr_check_money.push(3);
                              receipt_data_money[3].check = true;
                         }
                    } else {
                         if (!arr_check_ficance.find((el) => el === 3)) {
                              arr_check_ficance.push(3);
                              receipt_data_finance[3].check = true;
                         }
                    }
               } else {
                    if (order.pay_choice === "เงินสด") {
                         if (arr_check_money.find((el) => el === 3)) {
                              arr_check_money.splice(
                                   arr_check_money.findIndex((el) => el === 3),
                                   1
                              );
                              receipt_data_money[3].check = false;
                         }
                    } else {
                         if (arr_check_ficance.find((el) => el === 3)) {
                              arr_check_ficance.splice(
                                   arr_check_ficance.findIndex((el) => el === 3),
                                   1
                              );
                              receipt_data_finance[3].check = false;
                         }
                    }
               }
          } else if (name === "insurance_pay" && previous_path === "add") {
               // let ins_premium = supplier.filter((el) => el.supplier_id === Number(order.supplier_insurance_id));
               // order.insurance_premium =
               //      order.supplier_insurance_id && ins_premium[0].insurance_premium && value === 'บริษัท' ? Number(ins_premium[0].insurance_premium) : 0;
               if (value === "ลูกค้า" && order.insurance === "Yes") {
                    if (order.pay_choice === "เงินสด") {
                         if (!arr_check_money.find((el) => el === 2)) {
                              arr_check_money.push(2);
                              receipt_data_money[2].check = true;
                         }
                    } else {
                         if (!arr_check_ficance.find((el) => el === 2)) {
                              arr_check_ficance.push(2);
                              receipt_data_finance[2].check = true;
                         }
                    }
               } else {
                    if (order.pay_choice === "เงินสด") {
                         if (arr_check_money.find((el) => el === 2)) {
                              arr_check_money.splice(
                                   arr_check_money.findIndex((el) => el === 2),
                                   1
                              );
                              receipt_data_money[2].check = false;
                         }
                    } else {
                         if (arr_check_ficance.find((el) => el === 2)) {
                              arr_check_ficance.splice(
                                   arr_check_ficance.findIndex((el) => el === 2),
                                   1
                              );
                              receipt_data_finance[2].check = false;
                         }
                    }
               }
          } else if (name === "insurance" && previous_path === "add") {
               if (value === "Yes" && order.insurance_pay === "ลูกค้า") {
                    if (order.pay_choice === "เงินสด") {
                         if (!arr_check_money.find((el) => el === 2)) {
                              arr_check_money.push(2);
                              receipt_data_money[2].check = true;
                         }
                    } else {
                         if (!arr_check_ficance.find((el) => el === 2)) {
                              arr_check_ficance.push(2);
                              receipt_data_finance[2].check = true;
                         }
                    }
               } else {
                    if (order.pay_choice === "เงินสด") {
                         if (arr_check_money.find((el) => el === 2)) {
                              arr_check_money.splice(
                                   arr_check_money.findIndex((el) => el === 2),
                                   1
                              );
                              receipt_data_money[2].check = false;
                         }
                    } else {
                         if (arr_check_ficance.find((el) => el === 2)) {
                              arr_check_ficance.splice(
                                   arr_check_ficance.findIndex((el) => el === 2),
                                   1
                              );
                              receipt_data_finance[2].check = false;
                         }
                    }
               }
          } else if (name === "act" && previous_path === "add") {
               if (value === "Yes" && order.act_pay === "ลูกค้า") {
                    if (order.pay_choice === "เงินสด") {
                         if (!arr_check_money.find((el) => el === 1)) {
                              arr_check_money.push(1);
                              receipt_data_money[1].check = true;
                         }
                    } else {
                         if (!arr_check_ficance.find((el) => el === 1)) {
                              arr_check_ficance.push(1);
                              receipt_data_finance[1].check = true;
                         }
                    }
               } else {
                    if (order.pay_choice === "เงินสด") {
                         if (arr_check_money.find((el) => el === 1)) {
                              arr_check_money.splice(
                                   arr_check_money.findIndex((el) => el === 1),
                                   1
                              );
                              receipt_data_money[1].check = false;
                         }
                    } else {
                         if (arr_check_ficance.find((el) => el === 1)) {
                              arr_check_ficance.splice(
                                   arr_check_ficance.findIndex((el) => el === 1),
                                   1
                              );
                              receipt_data_finance[1].check = false;
                         }
                    }
               }
          }
          this.setState({ order, receiptData });
     };
     handleChangeCheckbox = (e) => {
          let {
               order,
               amphuresContact,
               amphuresInfo,
               subdistrictContact,
               subdistrictInfo,
               amphuresOwner,
               subdistrictOwner,
               type_turn_arr,
               zipCodeContact,
               zipCodeOwner,
               zipCodeUserInfo,
               previous_path,
          } = this.state;
          let sameOwn;
          if (e.target.name === "sameContact" && e.target.checked === true) {
               order.contact_address = order.userinfo_address;
               order.contact_sub_district = order.userinfo_sub_district ? order.userinfo_sub_district : "-";
               order.contact_district = order.userinfo_district;
               order.contact_province = order.userinfo_province;
               order.contact_zipcode = order.userinfo_zipcode;
               amphuresContact = [...amphuresInfo];
               subdistrictContact = [...subdistrictInfo];
               zipCodeContact = [...zipCodeUserInfo];
          } else if (e.target.name === "sameOwner" && e.target.checked === true) {
               order.owner_prefix = order?.userinfo_prefix;
               order.owner_name = order?.userinfo_name;
               order.owner_idcard = order.userinfo_idcard;
               order.owner_address = order.userinfo_address;
               order.owner_sub_district = order.userinfo_sub_district ? order.userinfo_sub_district : "-";
               order.owner_district = order.userinfo_district;
               order.owner_province = order.userinfo_province;
               order.owner_zipcode = order.userinfo_zipcode;
               amphuresOwner = [...amphuresInfo];
               subdistrictOwner = [...subdistrictInfo];
               zipCodeOwner = [...zipCodeUserInfo];
               sameOwn = true;
          } else if (e.target.name === "type_turn") {
               if (e.target.value === "2" && e.target.checked === true) {
                    order.carturn = 1;
                    order.carTurnCheck = 2;
               } else if (e.target.value === "2" && e.target.checked === false) {
                    order.carturn = 0;
                    order.carTurnCheck = null;
               }
               if (e.target.value === "1" && e.target.checked === true) {
                    order.accTurnCheck = 1;
               } else if (e.target.value === "1" && e.target.checked === false) {
                    order.accTurnCheck = null;
               }
          }
          this.setState({
               [e.target.name]: e.target.checked,
               order,
               amphuresContact,
               subdistrictContact,
               amphuresOwner,
               subdistrictOwner,
               sameOwn,
               zipCodeContact,
               zipCodeOwner,
          });
     };

     handleAddTurn = () => {
          let { order, supplier, itemAcc, previous_path, order_id_save } = this.state;
          let filteritem = itemAcc.find((e) => e.items_id === Number(order.item_turn_id));

          let items_price = filteritem.items_price * order.turn_amount;
          let order_ac_price = filteritem.items_price * order.turn_amount;
          if (previous_path === "edit" || order_id_save || order.acept_status === "save") {
               order.addAccturn.push({
                    items_id: order.item_turn_id,
                    items_name: filteritem.items_name,
                    items_price,
                    supplier_id: order.supplier_turn_id,
                    supplier_name: supplier.find((e) => e.supplier_id === Number(order.supplier_turn_id)).supplier_name,
                    order_ac_amount: order.turn_amount,
                    order_ac_price,
               });
               order.accturn.push({
                    items_id: order.item_turn_id,
                    items_name: filteritem.items_name,
                    items_price,
                    supplier_id: order.supplier_turn_id,
                    supplier_name: supplier.find((e) => e.supplier_id === Number(order.supplier_turn_id)).supplier_name,
                    order_ac_amount: order.turn_amount,
                    order_ac_price,
               });
          } else {
               order.accturn.push({
                    items_id: order.item_turn_id,
                    items_name: filteritem.items_name,
                    items_price,
                    supplier_id: order.supplier_turn_id,
                    supplier_name: supplier.find((e) => e.supplier_id === Number(order.supplier_turn_id)).supplier_name,
                    order_ac_amount: order.turn_amount,
                    order_ac_price,
               });
          }
          this.setState({ order });
     };

     /* Handle date picker */
     handleSelectDate = (date, dateString) => {
          let { order } = this.state;
          order.getcar_date = dayjs(date).format("YYYY-MM-DD");
          this.setState({ order, dateGetCar: date });
     };

     handleSelectCreate = (date, dateString) => {
          let { order } = this.state;
          order.created_at = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
          this.setState({ order, created_at: date });
     };

     handleSelectDateBirth = (date, dateString) => {
          let { order } = this.state;
          order.userinfo_birth_date = dayjs(date).format("YYYY-MM-DD");
          this.setState({ order, birthDate: date });
     };

     handleChangeAddress = ({ target: { name, value } }) => {
          let { order } = this.state;
          order[name] = value;
          if (name === "owner_province") {
               order.owner_district = "";
               order.owner_sub_district = order.abroad === "ใน" ? "" : "-";
               order.owner_zipcode = "";
               this.getAddress(name, order.owner_province);
          } else if (name === "owner_district") {
               order.owner_sub_district = order.abroad === "ใน" ? "" : "-";
               order.owner_zipcode = "";
               this.getAddress(name, order.owner_province, order.owner_district);
          } else if (name === "owner_sub_district") {
               order.owner_zipcode = "";
               this.getAddress(name, order.owner_province, order.owner_district, order.owner_sub_district);
          }
          ///////////////////////////////////////contac//////////////////////////////////////
          if (name === "contact_province") {
               order.contact_district = "";
               order.contact_sub_district = order.abroad === "ใน" ? "" : "-";
               order.contact_zipcode = "";
               this.getAddress(name, order.contact_province);
          } else if (name === "contact_district") {
               order.contact_sub_district = order.abroad === "ใน" ? "" : "-";
               order.contact_zipcode = "";
               this.getAddress(name, order.contact_province, order.contact_district);
          } else if (name === "contact_sub_district") {
               order.contact_zipcode = "";
               this.getAddress(name, order.contact_province, order.contact_district, order.contact_sub_district);
          }
          //////////////////////////////////////user info///////////////////////////////////////
          if (name === "userinfo_province") {
               order.userinfo_district = "";
               order.userinfo_sub_district = order.abroad === "ใน" ? "" : "-";
               order.userinfo_zipcode = "";
               this.getAddress(name, order.userinfo_province);
          } else if (name === "userinfo_district") {
               order.userinfo_sub_district = order.abroad === "ใน" ? "" : "-";
               order.userinfo_zipcode = "";
               this.getAddress(name, order.userinfo_province, order.userinfo_district);
          } else if (name === "userinfo_sub_district") {
               order.userinfo_zipcode = "";
               this.getAddress(name, order.userinfo_province, order.userinfo_district, order.userinfo_sub_district);
          }

          this.setState({ order });
     };
     handleChangeNumber = (value, name) => {
          let { order, arr_check, receiptData, previous_path, receipt_data_money, arr_check_money, arr_check_ficance, receipt_data_finance } =
               this.state;
          let addition_price = 0,
               discount_price = 0,
               real_price = 0;
          order[name] = value.floatValue;
          if (order.addition_price) {
               addition_price = order.addition_price;
          }
          if (order.discount_price) {
               discount_price = order.discount_price;
          }
          real_price = order.start_price + addition_price - discount_price;
          if (real_price) {
               order.vat = ((real_price * 7) / 107).toFixed(2);
          }
          order.real_price = real_price;
          if (name === "order_price") {
               order.order_vat = ((order?.order_price * 7) / 107).toFixed(2);
          }
          if (previous_path === "add" && order.pay_choice === "เงินสด") {
               if (name === "register_price" && order.register_pay === "ลูกค้า" && order.register === "Yes") {
                    if (!arr_check_money.find((el) => el === 3)) {
                         arr_check_money.push(3);
                    }
                    receipt_data_money[3].check = true;
               }
               if (name === "register_price" && !value.floatValue) {
                    if (arr_check_money.find((el) => el === 3)) {
                         arr_check_money.splice(
                              arr_check_money.findIndex((el) => el === 3),
                              1
                         );
                    }
                    receipt_data_money[3].check = false;
               }
               if (name === "act_premium" && order.act_pay === "ลูกค้า" && order.act === "Yes") {
                    if (!arr_check_money.find((el) => el === 1)) {
                         arr_check_money.push(1);
                    }
                    receipt_data_money[1].check = true;
               }
               if (name === "act_premium" && !value.floatValue) {
                    if (arr_check_money.find((el) => el === 1)) {
                         arr_check_money.splice(
                              arr_check_money.findIndex((el) => el === 1),
                              1
                         );
                    }
                    receipt_data_money[1].check = false;
               }
               if (name === "insurance_premium" && order.insurance_pay === "ลูกค้า" && order.insurance === "Yes") {
                    if (!arr_check_money.find((el) => el === 2)) {
                         arr_check_money.push(2);
                    }
                    receipt_data_money[2].check = true;
               }
               if (name === "insurance_premium" && !value.floatValue) {
                    if (arr_check_money.find((el) => el === 2)) {
                         arr_check_money.splice(
                              arr_check_money.findIndex((el) => el === 2),
                              1
                         );
                    }
                    receipt_data_money[2].check = false;
               }
               if (name === "order_price" && value.floatValue >= 0) {
                    if (!arr_check_money.find((el) => el === 10)) {
                         arr_check_money.push(10);
                    }
                    receipt_data_money[10].check = true;
               }
               if ((name === "order_price" && value.floatValue === undefined) || value.floatValue < 0) {
                    if (arr_check_money.find((el) => el === 10)) {
                         arr_check_money.splice(
                              arr_check_money.findIndex((el) => el === 10),
                              1
                         );
                    }
                    receipt_data_money[10].check = false;
               }
          } else if (previous_path === "add" && order.pay_choice === "ผ่อนชำระ") {
               if (name === "register_price" && order.register_pay === "ลูกค้า" && order.register === "Yes") {
                    if (!arr_check_ficance.find((el) => el === 3)) {
                         arr_check_ficance.push(3);
                    }
                    receipt_data_finance[3].check = true;
               }
               if (name === "register_price" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 3)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 3),
                              1
                         );
                    }
                    receipt_data_finance[3].check = false;
               }
               if (name === "act_premium" && order.act_pay === "ลูกค้า" && order.act === "Yes") {
                    if (!arr_check_ficance.find((el) => el === 1)) {
                         arr_check_ficance.push(1);
                    }
                    receipt_data_finance[1].check = true;
               }
               if (name === "act_premium" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 1)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 1),
                              1
                         );
                    }
                    receipt_data_finance[1].check = false;
               }
               if (name === "insurance_premium" && order.insurance_pay === "ลูกค้า" && order.insurance === "Yes") {
                    if (!arr_check_ficance.find((el) => el === 2)) {
                         arr_check_ficance.push(2);
                    }
                    receipt_data_finance[2].check = true;
               }
               if (name === "insurance_premium" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 2)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 2),
                              1
                         );
                    }
                    receipt_data_finance[2].check = false;
               }
               if (name === "down" && value.floatValue) {
                    if (!arr_check_ficance.find((el) => el === 0)) {
                         arr_check_ficance.push(0);
                    }
                    receipt_data_finance[0].check = true;
               }
               if (name === "down" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 0)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 0),
                              1
                         );
                    }
                    receipt_data_finance[0].check = false;
               }
               if (name === "first_down" && value.floatValue) {
                    if (!arr_check_ficance.find((el) => el === 5)) {
                         arr_check_ficance.push(5);
                    }
                    receipt_data_finance[5].check = true;
               }
               if (name === "first_down" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 5)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 5),
                              1
                         );
                    }
                    receipt_data_finance[5].check = false;
               }
               if (name === "sub_down") {
                    if (!arr_check_ficance.find((el) => el === 11)) {
                         arr_check_ficance.push(11);
                    }
                    receipt_data_finance[11].check = true;
               }
               if (name === "sub_down" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 11)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 11),
                              1
                         );
                    }
                    receipt_data_finance[11].check = false;
               }
               if (name === "addition_price") {
                    if (!arr_check_ficance.find((el) => el === 12)) {
                         arr_check_ficance.push(12);
                    }
                    // receipt_data_finance[12].receipt_price = (order.addition_price * 15) / 100;
                    receipt_data_finance[12].check = true;
               }
               if (name === "addition_price" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 12)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 12),
                              1
                         );
                    }
                    receipt_data_finance[12].check = false;
               }
               if (name === "subsidy") {
                    if (!arr_check_ficance.find((el) => el === 13)) {
                         arr_check_ficance.push(13);
                    }
                    // receipt_data_finance[13].receipt_price = (order.subsidy * 7) / 100;
                    receipt_data_finance[13].check = true;
               }
               if (name === "subsidy" && !value.floatValue) {
                    if (arr_check_ficance.find((el) => el === 13)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 13),
                              1
                         );
                    }
                    receipt_data_finance[13].check = false;
               }
               if (name === "order_price" && value.floatValue >= 0) {
                    if (!arr_check_ficance.find((el) => el === 16)) {
                         arr_check_ficance.push(16);
                    }
                    receipt_data_finance[16].check = true;
               }
               if ((name === "order_price" && value.floatValue === undefined) || value.floatValue < 0) {
                    if (arr_check_ficance.find((el) => el === 16)) {
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el === 16),
                              1
                         );
                    }
                    receipt_data_finance[16].check = false;
               }
          }
          if (name === "day_credit" && order.pay_choice === "เงินสด") {
               if (value.floatValue === 0 || !value.floatValue) {
                    order.order_fleet = 0;
                    receipt_data_money[0].check = true;
                    if (!arr_check_money.includes(0)) {
                         arr_check_money.push(0);
                    }
               } else {
                    order.order_fleet = 1;
                    if (arr_check_money.includes(0)) {
                         arr_check_money.splice(
                              arr_check_money.findIndex((el) => el == 0),
                              1
                         );
                    }
                    receipt_data_money[0].check = false;
               }
          }
          this.setState({ order, receiptData, arr_check_ficance, arr_check_money, receipt_data_finance, receipt_data_money });
     };
     /* Handle Data input change */
     handleChangeTel = (value) => {
          let { order } = this.state;
          order.userinfo_tel = value.value;
          this.setState({ order });
     };
     handleChangeIdCard = (value) => {
          let { order } = this.state;
          order.userinfo_idcard = value.value;
          this.setState({ order });
     };
     handleChangeIdCardOwner = (value) => {
          let { order } = this.state;
          order.owner_idcard = value.value;
          this.setState({ order });
     };
     /* Handle Add function */
     handleClick = () => {
          let { show } = this.state;
          this.setState({
               show: !show,
          });
     };
     /* Handle modal close */
     onHide = () => {
          this.setState({
               show: false,
          });
     };

     handleOnInputChange = (value) => {
          this.handleChangeText({ target: { name: "userinfo_a_card", value: value } });
     };

     handleChangeAutocompleteSeller = async (e) => {
          let { seller, order } = this.state;
          let seller_name = e[0];
          let user_id = seller.find((el) => el.name === seller_name)?.user_id;
          if (!seller_name) user_id = seller.find((el) => el.name === order?.saler_name_string)?.user_id;
          order.seller_phone = seller.find((el) => el.user_id === user_id)?.seller_phone;
          this.handleChangeText({ target: { name: "saler_name", value: user_id } });
          this.setState({ order });
     };

     handleChangeAutocomplete = (value) => {
          let { acard_detail, order } = this.state;
          let text = value[0]?.label;
          if (text) {
               let a_card = acard_detail?.find((el) => text?.split(" ")[0] === el.acard_no);
               this.setAutocomplete(a_card);
               this.setProvinceAutoComplete(a_card);
               this.setCarAutoComplete(a_card);
               this.handleChangeText({ target: { name: "userinfo_a_card", value: text?.split(" ")[0] } });
               this.setState({ clear: false });
          } else if (!text) {
               order.branch_id = null;
               order.saler_name = null;
               order.userinfo_name = "";
               order.userinfo_tel = null;
               order.userinfo_line = "";
               order.userinfo_birth_date = null;
               order.userinfo_zipcode = null;
               order.userinfo_province = null;
               order.userinfo_idcard = "";
               order.userinfo_address = "";
               order.series_id = null;
               order.model_id = null;
               order.color_id = null;
               order.model_code = "";
               this.state.birthDate = null;
               order.seller_phone = undefined;
               this.handleChangeAddress({ target: { name: "userinfo_province", value: null } });
               this.setState({ order, clear: true });
          }
     };

     setAutocomplete = (a_card) => {
          let { order, seller } = this.state;
          // console.log("seller", seller);
          // order.branch_id = a_card?.branch_id;
          order.branch_id = Number(seller.find((el) => el.name === a_card?.acard_saler_name)?.branch_id);
          order.saler_name = a_card?.acard_saler;
          order.userinfo_prefix = a_card?.acard_prefix;
          order.userinfo_province = a_card?.acard_fullname;
          order.userinfo_tel = a_card?.acard_tel;
          order.userinfo_line = a_card?.acard_line;
          order.userinfo_birth_date = dayjs(a_card?.acard_birthdate).format("YYYY-MM-DD");
          this.state.birthDate = dayjs(a_card?.acard_birthdate);
          if (a_card.acard_customer_type === "นิติบุคคล") {
               order.userinfo_name = a_card?.acard_firstname;
          } else {
               order.userinfo_name = a_card?.acard_fullname;
          }
          order.userinfo_customer_type = a_card?.acard_customer_type;
          order.userinfo_idcard = a_card?.userinfo_idcard;
          order.userinfo_address = a_card?.userinfo_address;
          order.series_id = a_card?.series_id;
          order.model_id = a_card?.model_id;
          order.color_id = a_card?.color_id;
          let type_customer = a_card?.acard_customer_type;
          order.seller_phone = seller.find((el) => el.name === a_card?.acard_saler_name)?.seller_phone;
          order.abroad = a_card?.abroad;
          this.handleChangeAddress({ target: { name: "userinfo_province", value: null } });
          this.setState({ order, type_customer });
     };
     setProvinceAutoComplete = async (a_card) => {
          await this.handleChangeAddress({ target: { name: "userinfo_province", value: a_card.acard_province } });
          await this.handleChangeAddress({ target: { name: "userinfo_district", value: a_card.acard_district } });
          await this.handleChangeAddress({ target: { name: "userinfo_sub_district", value: a_card.acard_sub_district } });
          await this.handleChangeAddress({ target: { name: "userinfo_zipcode", value: a_card.acard_zipcode } });
     };
     setCarAutoComplete = async (a_card) => {
          let { order } = this.state;
          if (a_card.series_id) await this.getModel(a_card.series_id);
          if (a_card.model_id) await this.getColors(a_card.model_id);
          if (a_card.series_id && a_card.model_id && a_card.color_id) {
               await this.getPriceNew(a_card.series_id, a_card.model_id, a_card.color_id);
               await this.getAcc(a_card.series_id, a_card.model_id, a_card.color_id);
               await this.getSupplierTurn(a_card.series_id, a_card.model_id, a_card.color_id);
          }
     };

     onHidePro = () => {
          let { promotions, showPromotion } = this.state;
          if (showPromotion === false) {
               promotions.sort((a, b) => parseFloat(a.pro_id) - parseFloat(b.pro_id));
          }

          this.setState({
               showPromotion: !this.state.showPromotion,
               promotions,
          });
     };
     onHideReciept = () => {
          let { receiptData, showReciept } = this.state;
          if (showReciept === false) {
               receiptData.sort((a, b) => parseFloat(a.pro_id) - parseFloat(b.pro_id));
          }
          this.setState({
               showReciept: !showReciept,
               // promotions,
          });
     };

     onHideAccBonus = () => {
          let { accbonus, showAccBonus } = this.state;
          if (showAccBonus === false) {
               accbonus.sort((a, b) => parseFloat(a.items_id) - parseFloat(b.items_id));
          }

          this.setState({
               showAccBonus: !this.state.showAccBonus,
               accbonus,
               newSearchAccBonus: null,
          });
     };

     onHideAccBuy = () => {
          let { accbuy, showAccBuy } = this.state;
          if (showAccBuy === false) {
               accbuy.sort((a, b) => parseFloat(a.items_id) - parseFloat(b.items_id));
          }
          this.setState({
               showAccBuy: !this.state.showAccBuy,
               accbuy,
               newSearchAccBuy: null,
          });
     };

     handleEdit = async () => {
          this.setState({ loading: true });
          let { order, receipt_data_finance, order_id_new, totalMargin } = this.state;
          let promotions = [],
               accbonus = [],
               accbuy = [],
               accturn = [];
          order.promotions.map((el) => {
               if (el.orderac_id) {
                    promotions.push(el);
               }
          });
          order.accbonus.map((el) => {
               if (el.orderac_id) {
                    accbonus.push(el);
               }
          });
          order.accbuy.map((el) => {
               if (el.orderac_id) {
                    accbuy.push(el);
               }
          });
          order.accturn.map((el) => {
               if (el.orderac_id) {
                    accturn.push(el);
               }
          });
          order.promotions = [...promotions];
          order.accbonus = [...accbonus];
          order.accbuy = [...accbuy];
          order.accturn = [...accturn];
          order.save = 0;
          order.lvl_margin = 1;
          // if (order.pay_choice === 'ผ่อนชำระ') {
          //      order.receipt.splice(
          //           order.receipt.findIndex((el) => el.receipt_method === 'หักซัพดาวน์'),
          //           1
          //      );
          //      receipt_data_finance.splice(
          //           receipt_data_finance.findIndex((el) => el.receipt_method === 'หักซัพดาวน์'),
          //           1
          //      );
          // }
          if (order_id_new) {
               order.order_id = order_id_new;
          }
          this.setState({ order });
          try {
               //'log1'
               order.checkMargin = totalMargin > order.margin + order.addition_price;
               order.margin_used = totalMargin;
               // console.log("order", order);
               await POST("/order/edit", { ...order });
               swal({
                    icon: "success",
                    text: "แก้ไขใบจองรถเรียบร้อยแล้ว",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    window.location.assign(`/admin/order/car/detail${order.order_id}`);
               });
          } catch (error) {
               swal({
                    icon: "error",
                    text: `${error.result || error}`,
                    title: "บันทึกข้อมูลไม่สำเร็จ",
                    buttons: {
                         danger: "ลองใหม่อีกครั้ง",
                    },
               }).then((value) => {
                    this.setState({ loading: false });
               });
               // swal({
               //      title: "failed",
               //      text: "ผิดพลาด! กรุณาลองใหม่อีกครั้ง",
               //      icon: "warning",
               //      button: "ตกลง",
               //      dangerMode: true,
               // }).then((value) => {
               //      this.setState({ loading: false });
               // });
               // console.log(`error`, error);
          }
     };

     calculateMarginButton = async (totalMargin) => {
          let { order } = this.state;
          let marginOver = order.margin + order.addition_price - totalMargin;
          // console.log("marginOver", marginOver, order.margin, order.addition_price, totalMargin);
          // if (order.branch_id && Number(order.branch_id) === 3) {
          swal({
               text: "คำนวณ Margin ทั้งหมด",
               buttons: {
                    submit: "เสร็จสิ้น",
               },
               content: (
                    <div>
                         <p>
                              <strong>
                                   Margin{" "}
                                   {Number(order.margin + order.addition_price).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                   })}{" "}
                                   บาท
                              </strong>
                         </p>
                         <p>
                              <strong>
                                   ใช้ไป {totalMargin.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท
                              </strong>
                         </p>
                    </div>
               ),
          });
          // } else {
          //      // if (Math.abs(marginOver) <= order.margin) {
          //      swal({
          //           text: "คำนวณ Margin ทั้งหมด",
          //           buttons: {
          //                submit: "เสร็จสิ้น",
          //           },
          //           content: (
          //                <div>
          //                     <strong>{totalMargin.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
          //                     <p>บาท</p>
          //                </div>
          //           ),
          //      });
          //      // } else {
          //      //      console.log("calculateMarginButton");
          //      //      swal({
          //      //           title: "ใช้ Margin ไม่ถูกต้อง",
          //      //           text: "Margin คงเหลือมากว่า Margin หลัก",
          //      //           icon: "warning",
          //      //           button: "ตกลง",
          //      //           dangerMode: true,
          //      //      });
          //      // }
          // }
     };

     CalculateFinalPriceButton = () => {
          let { order } = this.state;
          let accbuyTotal = 0,
               insurance_premium = 0,
               register_price = 0,
               act_price = 0;
          if (order.accbuy) {
               let accbuyPrice = order.accbuy.map((el) => el.items_price);
               for (let i = 0; i < accbuyPrice.length; i++) {
                    accbuyTotal = accbuyTotal + accbuyPrice[i];
               }
          }
          if (order.insurance_pay === "ลูกค้า" && order.insurance === "Yes" && order.insurance_premium) {
               insurance_premium = order.insurance_premium;
          }
          if (order.register_pay === "ลูกค้า" && order.register === "Yes" && order.register_price) {
               register_price = order.register_price;
          }
          if (order.act_pay === "ลูกค้า" && order.act === "Yes" && order.act_premium) {
               act_price = order.act_premium;
          }
          let finalPrice =
               (order?.start_price ? Number(order.start_price) : 0) +
               (order?.addition_price ? Number(order?.addition_price) : 0) +
               (order?.customer_pay ? Number(order?.customer_pay) : 0) +
               (accbuyTotal ? Number(accbuyTotal) : 0) +
               (insurance_premium ? Number(insurance_premium) : 0) +
               (register_price ? Number(register_price) : 0) +
               (act_price ? Number(act_price) : 0);

          swal({
               text: " คำนวณราคาขายทั้งหมด",
               buttons: {
                    submit: "เสร็จสิ้น",
               },
               content: (
                    <div>
                         <strong>{finalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                         <p>บาท</p>
                    </div>
               ),
          });
     };

     onCloseModalCal = () => {
          this.setState({
               showMarginCal: false,
               showFinalCal: false,
          });
     };

     /* ------------------------------------------------------------- */

     /* ------------------------- Formatter ------------------------- */
     removeBtnFormatter(cell, row, rowIndex, formatExtraData) {
          return <Button variant="danger">Remove</Button>;
     }
     parseFloatFormatter(cell, row) {
          let new_cell = parseFloat(cell).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell || 0}</span>;
          } else {
               return new_cell || 0;
          }
     }
     parseFloatFormatterAccBuy(cell, row) {
          let new_cell = Number(JSON.parse(cell).price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell || 0}</span>;
          } else {
               return new_cell || 0;
          }
     }
     parseFloatFormatterAccBonus(cell, row) {
          let new_cell = Number(JSON.parse(cell).price_add_new).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     }

     imageFormater = (cell, row) => {
          if (row.acc_image) {
               return (
                    <Image
                         onClick={() => this.setState({ modalShow: true, acc_image: cell })}
                         style={{ cursor: "pointer" }}
                         width={100}
                         height={100}
                         src={ip + cell}
                    />
               );
          } else {
               return null;
          }
     };

     unitFormatter() {
          return <p>บาท</p>;
     }
     imageViewFormatter = (cell, row) => {
          if (row.acc_image) {
               return <a onClick={() => this.setState({ openModalImg: true, imgPath: cell })}>View</a>;
          } else {
               return null;
          }
     };

     // noteFormatter(cell, row, rowIndex, formatExtraData) {
     //      if (row.price && row.title !== 'รวม Margin ที่ใช้ทั้งสิ้น') {
     //           return <p>ของแถม</p>;
     //      } else if (row.price === 0 && row.title !== 'รวม Margin ที่ใช้ทั้งสิ้น') {
     //           return <p>ลูกค้าซื้อเอง</p>;
     //      } else {
     //           return null;
     //      }
     // }
     noFormatter(cell, row, rowIndex, formatExtraData) {
          return rowIndex + 1;
     }
     totalPriceReceipt = (columnData, order) => {
          let total = 0;
          if (this.state.previous_path === "edit") {
               for (let i = 0; i < order.receipt.length; i++) {
                    if (order.receipt[i].receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                         total -= order.receipt[i].receipt_price;
                    } else {
                         total += order.receipt[i].receipt_price;
                    }
               }
          } else {
               for (let i = 0; i < columnData.length; i++) {
                    if (columnData[i].receipt_method === "ใบลดหนี้/ใบกำกับภาษี") {
                         total -= columnData[i].receipt_price;
                    } else {
                         total += columnData[i].receipt_price;
                    }
                    // console.log(`columnData`, columnData);
               }
          }
          if (order.sub_down && order.pay_choice !== "เงินสด") {
               total -= order.sub_down;
          } else {
               total += 0;
          }
          return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
     };
     totalPrice = (columnData) => {
          let total = columnData.reduce((acc, item) => acc + item, 0);
          // console.log('columnData', columnData)
          total = parseFloat(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return total;
     };

     marginOver = (columnData, overSubdown, strSubNum) => {
          let total = columnData[columnData.length - 1] + columnData[columnData.length - 2] - columnData[columnData.length - 3];
          total = parseFloat(Math.abs(total)).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          return overSubdown ? `${strSubNum}\n${total}` : `${total}`;
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null, id: `checkbox${rowIndex}` }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row)}
               />
          );
     };

     checkBoxFormatterBonus = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null, id: `checkboxBonus${rowIndex}` }]}
                    handleChangeText={(e) => this.onChangeCheckboxAddBonus(e.target.checked, row)}
               />
          );
     };

     checkBoxFormatterBuy = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null, id: `checkboxBuy${rowIndex}` }]}
                    handleChangeText={(e) => this.onChangeCheckboxAddBuy(e.target.checked, row)}
               />
          );
     };

     checkBoxFormatterReceipt = (cell, row, rowIndex, formatExtraData, arr_check) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null, id: `checkboxReciept${rowIndex}` }]}
                    arrChecked={this.state.arr_check}
                    handleChangeText={(e) => this.onChangeCheckboxReceipt(e.target.checked, rowIndex)}
               />
          );
     };

     checkBoxFormatterReceiptAdd = (rowIndex, el, pay_choice) => {
          let arrcheck;
          if (pay_choice === "เงินสด") {
               arrcheck = this.state.arr_check_money;
          } else {
               arrcheck = this.state.arr_check_ficance;
          }
          return (
               <LabelBar
                    type={"checkbox"}
                    disabled={el.disabled}
                    label={[{ value: rowIndex, name: null, id: `checkboxReciept${rowIndex}` }]}
                    arrChecked={arrcheck}
                    handleChangeText={(e) => this.onChangeCheckboxReceiptAdd(e.target.checked, rowIndex, pay_choice)}
               />
          );
     };

     onChangeCheckboxAdd = async (checked, row) => {
          let { modalAddPromotion } = this.state;
          ///check
          if (checked) {
               if (row.pro_id) {
                    modalAddPromotion.push(row.pro_id);
                    this.setState({ modalAddPromotion });
               }
               ///uncheck
          } else {
               if (row.pro_id) {
                    modalAddPromotion.splice(
                         modalAddPromotion.findIndex((el) => el === row.pro_id),
                         1
                    );
                    this.setState({ modalAddPromotion });
               }
          }
     };

     onChangeCheckboxAddBonus = async (checked, row) => {
          let { modalAddAccBonus } = this.state;
          ///check
          if (checked) {
               modalAddAccBonus.push(row.items_id);
               this.setState({ modalAddAccBonus });
               ///uncheck
          } else {
               modalAddAccBonus.splice(
                    modalAddAccBonus.findIndex((el) => el === row.items_id),
                    1
               );
               this.setState({ modalAddAccBonus });
          }
     };

     onChangeCheckboxAddBuy = async (checked, row) => {
          let { modalAddAccBuy } = this.state;
          ///check
          if (checked) {
               modalAddAccBuy.push(row.items_id);
               this.setState({ modalAddAccBuy });
               ///uncheck
          } else {
               modalAddAccBuy.splice(
                    modalAddAccBuy.findIndex((el) => el === row.items_id),
                    1
               );
               this.setState({ modalAddAccBuy });
          }
     };

     onChangeCheckboxReceipt = (checked, rowIndex) => {
          let { receiptData, arr_check } = this.state;
          receiptData[rowIndex].check = checked;
          if (checked !== true) {
               arr_check.splice(
                    arr_check.findIndex((el) => el === rowIndex),
                    1
               );
          }
          if (checked === true) {
               arr_check.push(rowIndex);
          }
          this.setState({ receiptData, arr_check });
     };

     onChangeCheckboxReceiptAdd = (checked, rowIndex, pay_choice) => {
          let { arr_check_ficance, arr_check_money, receipt_data_finance, receipt_data_money } = this.state;
          if (pay_choice === "เงินสด") {
               receipt_data_money[rowIndex].check = checked;
               if (checked !== true) {
                    arr_check_money.splice(
                         arr_check_money.findIndex((el) => el === rowIndex),
                         1
                    );
               }
               if (checked === true) {
                    arr_check_money.push(rowIndex);
               }
          } else {
               receipt_data_finance[rowIndex].check = checked;
               if (checked !== true) {
                    arr_check_ficance.splice(
                         arr_check_ficance.findIndex((el) => el === rowIndex),
                         1
                    );
               }
               if (checked === true) {
                    arr_check_ficance.push(rowIndex);
               }
          }
          this.setState({ arr_check_ficance, arr_check_money, receipt_data_finance, receipt_data_money });
     };

     removePromotion = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemovePromotion(row, rowIndex)} variant="danger">
                    Remove
               </Button>
          );
     };
     removeAccbonus = (cell, row, rowIndex, formatExtraData) => {
          if (getToken().position_id === 25 || getToken().position_id === 11) {
               return (
                    <Button onClick={() => this.onRemoveAccbonus(row, rowIndex)} variant="danger">
                         Remove
                    </Button>
               );
          } else if (row.install === "ติดตั้งแล้ว" || row.install === "สั่งซื้อแล้ว") {
               return <strong style={{ color: "green" }}>{row.install}</strong>;
          } else {
               return (
                    <Button onClick={() => this.onRemoveAccbonus(row, rowIndex)} variant="danger">
                         Remove
                    </Button>
               );
          }
     };

     removeAccbuy = (cell, row, rowIndex, formatExtraData) => {
          if (getToken().position_id === 25 || getToken().position_id === 11) {
               return (
                    <Button onClick={() => this.onRemoveAccbuy(row, rowIndex)} variant="danger">
                         Remove
                    </Button>
               );
          } else if (row.install === "ติดตั้งแล้ว" || row.install === "สั่งซื้อแล้ว") {
               return <strong style={{ color: "green" }}>{row.install}</strong>;
          } else {
               return (
                    <Button onClick={() => this.onRemoveAccbuy(row, rowIndex)} variant="danger">
                         Remove
                    </Button>
               );
          }
     };

     removeBtnFormatterTurn = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemoveAccTurn(row, rowIndex)} variant="danger">
                    Remove
               </Button>
          );
     };

     amountFormatterBonus = (cell, row, rowIndex, formatExtraData) => {
          return (
               <NumericInput
                    onChange={(e) => this.onChangeAmountBonus(e, rowIndex)}
                    value={row.order_ac_amount ? row.order_ac_amount : 1}
                    min={1}
                    className="form-control"
               />
          );
     };

     amountFormatterBuy = (cell, row, rowIndex, formatExtraData) => {
          return (
               <NumericInput
                    onChange={(e) => this.onChangeAmountBuy(e, rowIndex)}
                    value={row.order_ac_amount ? row.order_ac_amount : 1}
                    min={1}
                    className="form-control"
               />
          );
     };

     inputReciptPriceFormatter = (cell, row, rowIndex) => {
          let { order } = this.state;
          return (
               <Field
                    id={`field-temp-number-${rowIndex}`}
                    type={"number"}
                    placeholder={"0.00"}
                    value={null}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangReceiptPriceAdd(e, rowIndex, order.pay_choice)}
               />
          );
     };
     inputReciptPriceFormatterAdd = (rowIndex, value, pay_choice, disable) => {
          // console.log(`value`, value)
          let { receipt_data_finance, receipt_data_money, edit_receipt } = this.state;
          // console.log(`edit_receipt`, edit_receipt)
          if (disable) {
               if (pay_choice === "เงินสด" && value >= 0) {
                    receipt_data_money[rowIndex].receipt_price = value;
               } else if (pay_choice === "ผ่อนชำระ" && value && value >= 0) {
                    receipt_data_finance[rowIndex].receipt_price = value;
               }
          } else {
               if (pay_choice === "เงินสด" && !edit_receipt && value >= 0) {
                    receipt_data_money[rowIndex].receipt_price = value;
               } else if (pay_choice === "ผ่อนชำระ" && !edit_receipt && value && value >= 0) {
                    receipt_data_finance[rowIndex].receipt_price = value;
               }
          }

          return (
               <Field
                    id={`field-temp-number-${rowIndex}`}
                    type={"number"}
                    reciept={true}
                    value={pay_choice === "เงินสด" ? receipt_data_money[rowIndex].receipt_price : receipt_data_finance[rowIndex].receipt_price}
                    // placeholder={'0.00'}
                    decimalScale={2}
                    // disabled={value && rowIndex !== 12 ? true : false}
                    disabled={disable ? disable : !edit_receipt}
                    handleChangeNumber={(e) => {
                         if (
                              (pay_choice === "เงินสด" ? receipt_data_money : receipt_data_finance)[rowIndex].receipt_method ===
                              "ใบรับเงินส่วนต่างมาจิ้น"
                         ) {
                              if (e.floatValue <= 5500) {
                                   this.handleChangReceiptPriceAdd(e, rowIndex, pay_choice);
                              } else if (e.floatValue > 5500) {
                                   swal({ icon: "warning", title: "กรุณาห้ามกรอกส่วนต่างมาจิ้นเกิน 5,500 บาท", text: "กรุณากรอกจำนวนให้ถูกต้อง" });
                              } else {
                              }
                         } else {
                              this.handleChangReceiptPriceAdd(e, rowIndex, pay_choice);
                         }
                    }}
               />
          );
     };
     inputFormFormatterAdd = (row, rowIndex, pay_choice, other) => {
          if (other) {
               return (
                    <>
                         <Field
                              id={`field-temp-other-${rowIndex}`}
                              type={"text"}
                              title={"อื่นๆ"}
                              placeholder={"ระบุ"}
                              value={null}
                              style_id={"__inline__space"}
                              space={{ span: 1, offset: 0 }}
                              handleChangeText={(e) => this.handleChangNameReceiptAdd(e, rowIndex, pay_choice)}
                         />
                    </>
               );
          } else {
               return <>{row}</>;
          }
     };

     inputFormFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.receipt_method === "อื่น ๆ") {
               return (
                    <Field
                         id={`field-temp-other-${rowIndex}`}
                         type={"text"}
                         title={row.receipt_method}
                         placeholder={"ระบุ"}
                         value={null}
                         style_id={"__inline__space"}
                         space={{ span: 1, offset: 0 }}
                         handleChangeText={(e) => this.handleChangNameReceipt(e, rowIndex)}
                    />
               );
          } else {
               return <>{row.receipt_method}</>;
          }
     };

     //-------------------rename reciept------------------------
     inputFormFormatterRename = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    id={`field-temp-other-${rowIndex}`}
                    type={"text"}
                    placeholder={"ระบุ"}
                    value={null}
                    style_id={"__inline__space"}
                    space={{ span: 1, offset: 0 }}
                    handleChangeText={(e) => this.handleChangRenameNameReceipt(e, rowIndex)}
               />
          );
     };
     btnFormFormatterRename = (cell, row, rowIndex, formatExtraData) => {
          return (
               <>
                    <Button variant="primary" style={{ width: " 40%", marginRight: 10 }} onClick={() => this.onClickRename(row, rowIndex)}>
                         แก้ไข
                    </Button>
                    <Button variant="danger" style={{ width: "40%" }} onClick={() => this.onClickCancelRename(row, rowIndex)}>
                         ยกเลิก
                    </Button>
               </>
          );
     };
     handleChangRenameNameReceipt = (e, rowIndex) => {
          let { renameReciptBefore } = this.state;
          renameReciptBefore[rowIndex].receipt_method_edit = e.target.value;
          this.setState({ renameReciptBefore });
     };
     onClickRename = (row, rowIndex) => {
          let { order } = this.state;
          let id = order.renameReceipt.find((el) => el.receipt_id === row.receipt_id);
          if ((id && id.receipt_id) !== row.receipt_id && row.receipt_method_edit) {
               order.renameReceipt.push({ receipt_id: row.receipt_id, receipt_method: row.receipt_method_edit });
               swal({
                    text: "แก้ไขสำเร็จ",
                    icon: "success",
                    button: "ตกลง",
               });
          } else if ((id && id.receipt_id) !== row.receipt_id && !row.receipt_method_edit) {
               swal({
                    text: "กรุณากรอกเพื่อแก้ไขข้อมูล",
                    icon: "error",
                    button: "ตกลง",
               });
          } else {
               swal({
                    text: "ทำการแก้ไขไปแล้ว",
                    icon: "error",
                    button: "ตกลง",
               });
          }
     };
     onClickCancelRename = (row, rowIndex) => {
          let { order } = this.state;
          let id = order.renameReceipt.find((el) => el.receipt_id === row.receipt_id);
          if ((id && id.receipt_id) === row.receipt_id) {
               order.renameReceipt.splice(
                    order.renameReceipt.findIndex((el) => el.receipt_id === row.receipt_id),
                    1
               );
               swal({
                    text: "ยกเลิกการแก้ไขสำเร็จ",
                    icon: "success",
                    button: "ตกลง",
               });
          } else {
               swal({
                    text: "ยกเลิกการแก้ไขไปแล้ว",
                    icon: "error",
                    button: "ตกลง",
               });
          }
     };
     /*----------------------------------------------------------*/

     receiptPriceformat = (cell, row, rowIndex, formatExtraData) => {
          let price = cell.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          return price;
     };
     /* ------------------------------------------------------------- */
     handleChangNameReceipt = (e, rowIndex) => {
          let { receipt_data_finance, receipt_data_money, order } = this.state;
          if (order.pay_choice === "เงินสด") {
               receipt_data_money[rowIndex].receipt_method = e.target.value;
          } else {
               receipt_data_finance[rowIndex].receipt_method = e.target.value;
          }
          this.setState({ receipt_data_money, receipt_data_finance });
     };
     handleChangNameReceiptAdd = (e, rowIndex, pay_choice) => {
          let { receipt_data_finance, receipt_data_money } = this.state;
          if (pay_choice === "เงินสด") {
               receipt_data_money[rowIndex].receipt_method = e.target.value;
          } else {
               receipt_data_finance[rowIndex].receipt_method = e.target.value;
          }
          this.setState({ receipt_data_money, receipt_data_finance });
     };

     handleChangReceiptPrice = (e, rowIndex) => {
          let { receiptData } = this.state;
          receiptData[rowIndex].receipt_price = e.floatValue;
          this.setState({ receiptData });
     };
     handleChangReceiptPriceAdd = (e, rowIndex, pay_choice) => {
          let { receipt_data_money, receipt_data_finance } = this.state;
          if (pay_choice === "เงินสด") {
               receipt_data_money[rowIndex].receipt_price = e.floatValue || 0;
          } else {
               receipt_data_finance[rowIndex].receipt_price = e.floatValue || 0;
          }
          this.setState({ receipt_data_money, receipt_data_finance });
     };

     onChangeAmountBonus = (e, rowIndex) => {
          let { order } = this.state;
          order.accbonus[rowIndex].order_ac_amount = e;
          // order.accbonus[rowIndex].items_price = order.accbonus[rowIndex].old_price * e;
          this.setState({ order });
     };

     onChangeAmountBuy = (e, rowIndex) => {
          let { order } = this.state;
          order.accbuy[rowIndex].order_ac_amount = e;
          // order.accbuy[rowIndex].items_price = order.accbuy[rowIndex].old_price * e;
          this.setState({ order }, () => {
               let { order, receipt_data_finance, receipt_data_money, previous_path } = this.state;
               if (previous_path === "add") {
                    if (order.accbuy.length > 0) {
                         let sum_acc = order.accbuy.map((el) => ({ sum: el.order_ac_amount * el.order_ac_price })).reduce(add("sum"), 0);
                         receipt_data_finance[
                              receipt_data_finance.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")
                         ].receipt_price = sum_acc;
                         receipt_data_money[receipt_data_money.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].receipt_price =
                              sum_acc;
                         this.setState({ receipt_data_finance, receipt_data_money });
                    }
               }
          });
     };

     onAddPromotion = () => {
          let { modalAddPromotion, order, promotions, previous_path, promoShow } = this.state;
          promotions.map((element) => {
               if (modalAddPromotion.find((e) => e === element.pro_id)) {
                    if (previous_path === "edit") {
                         order.addPromotions.push(element);
                         order.promotions.push(element);
                    } else {
                         order.promotions.push(element);
                    }
               }
          });
          modalAddPromotion.forEach((element) => {
               let index = promotions
                    .map(function (e) {
                         return e.pro_id;
                    })
                    .indexOf(element);
               promotions.splice(index, 1);
          });

          this.setState({ order, showPromotion: false, modalAddPromotion: [] });
     };

     onAddAccBonus = () => {
          let { modalAddAccBonus, order, accbonus, previous_path, accbonusShow, order_id_save } = this.state;
          accbonus.map((element) => {
               if (modalAddAccBonus.find((e) => e === element.items_id)) {
                    if (previous_path === "edit" || order.acept_status === "save" || order_id_save) {
                         order.addAccbonus.push({
                              ...element,
                              items_price: element.price_list ? Number(JSON.parse(element.price_list).price_add_new) || 0 : 0,
                              order_ac_price: element.price_list ? Number(JSON.parse(element.price_list).price_add_new) || 0 : 0,
                              order_ac_amount: 1,
                         });
                         order.accbonus.push({
                              ...element,
                              items_price: element.price_list ? Number(JSON.parse(element.price_list).price_add_new) || 0 : 0,
                              order_ac_price: element.price_list ? Number(JSON.parse(element.price_list).price_add_new) || 0 : 0,
                              order_ac_amount: 1,
                         });
                    } else {
                         order.accbonus.push({
                              ...element,
                              items_price: element.price_list ? Number(JSON.parse(element.price_list).price_add_new) || 0 : 0,
                              order_ac_price: element.price_list ? Number(JSON.parse(element.price_list).price_add_new) || 0 : 0,
                              order_ac_amount: 1,
                         });
                    }
               }
          });
          modalAddAccBonus.forEach((element) => {
               let index = accbonus
                    .map(function (e) {
                         return e.items_id;
                    })
                    .indexOf(element);
               // accbonus.splice(index, 1);
          });
          this.setState({ order, showAccBonus: false, modalAddAccBonus: [], newSearchAccBonus: null });
     };

     onAddAccBuy = () => {
          let { modalAddAccBuy, order, accbuy, previous_path, accbuyShow, order_id_save } = this.state;
          accbuy.map((element) => {
               if (modalAddAccBuy.find((e) => e === element.items_id)) {
                    if (previous_path === "edit" || order.acept_status === "save" || order_id_save) {
                         order.addAccbuy.push({
                              ...element,
                              items_price: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              items_price_customer: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              order_ac_price: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              order_ac_amount: 1,
                         });
                         order.accbuy.push({
                              ...element,
                              items_price: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              items_price_customer: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              order_ac_price: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              order_ac_amount: 1,
                         });
                    } else {
                         order.accbuy.push({
                              ...element,
                              items_price: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              items_price_customer: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              order_ac_price: element.price_list ? Number(JSON.parse(element.price_list).price) || 0 : 0,
                              order_ac_amount: 1,
                         });
                    }
               }
          });
          modalAddAccBuy.forEach((element) => {
               let index = accbuy
                    .map(function (e) {
                         return e.items_id;
                    })
                    .indexOf(element);
               accbuy.splice(index, 1);
          });
          this.setState({ order, showAccBuy: false, modalAddAccBuy: [], newSearchAccBuy: null }, () => {
               let { order, receipt_data_finance, arr_check_ficance, receipt_data_money, arr_check_money, previous_path } = this.state;
               if (order.accbuy.length > 0 && previous_path === "add") {
                    let sum_acc = order.accbuy.map((el) => ({ sum: el.order_ac_amount * el.order_ac_price })).reduce(add("sum"), 0);
                    receipt_data_finance[receipt_data_finance.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].receipt_price =
                         sum_acc;
                    receipt_data_finance[receipt_data_finance.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].check = true;
                    arr_check_ficance.push(15);
                    receipt_data_money[receipt_data_money.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].receipt_price =
                         sum_acc;
                    receipt_data_money[receipt_data_money.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].check = true;
                    arr_check_money.push(9);
                    this.setState({ receipt_data_finance, arr_check_ficance, receipt_data_money, arr_check_money });
               }
          });
     };

     onRemovePromotion = (row, rowIndex) => {
          let { promotions, order, deletePromotion } = this.state;
          promotions.push(row);
          deletePromotion.push(row.opro_id);
          order.promotions.splice(rowIndex, 1);
          order.deletePromotion = deletePromotion;
          this.setState({ promotions, order });
     };

     onRemoveAccbonus = (row, rowIndex) => {
          // console.log("row", row);
          let { accbonus, order, deleteAccbonus } = this.state;
          // accbonus.push({ ...row });
          order.accbonus.splice(rowIndex, 1);
          if (row.orderac_id) {
               deleteAccbonus.push(row.orderac_id);
               order.deleteAccbonus = deleteAccbonus;
          } else {
               if (row.items_id) {
                    order.addAccbonus.splice(
                         order.addAccbonus.findIndex((el) => el.items_id === row.items_id),
                         1
                    );
               } else {
                    order.addAccbonus.splice(
                         order.addAccbonus.findIndex((el) => el.items_name === row.items_name),
                         1
                    );
               }
          }
          // this.setState({ accbonus, order });
          this.setState({ order });
     };

     onRemoveAccbuy = (row, rowIndex) => {
          let { accbuy, order, deleteAccbuy } = this.state;
          // accbuy.push({ ...row });
          order.accbuy.splice(rowIndex, 1);
          if (row.orderac_id) {
               deleteAccbuy.push(row.orderac_id);
               order.deleteAccbuy = deleteAccbuy;
          } else {
               order.addAccbuy.splice(
                    order.addAccbuy.findIndex((el) => el.items_id === row.items_id),
                    1
               );
          }
          // this.setState({ accbuy, order }, () => {
          this.setState({ order }, () => {
               let { order, receipt_data_finance, arr_check_ficance, receipt_data_money, arr_check_money, previous_path } = this.state;
               if (previous_path === "add") {
                    if (order.accbuy.length > 0) {
                         let sum_acc = order.accbuy.map((el) => ({ sum: el.order_ac_amount * el.order_ac_price })).reduce(add("sum"), 0);
                         receipt_data_finance[
                              receipt_data_finance.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")
                         ].receipt_price = sum_acc;
                         receipt_data_money[receipt_data_money.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].receipt_price =
                              sum_acc;
                         this.setState({ receipt_data_finance });
                    } else {
                         receipt_data_money[
                              receipt_data_money.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")
                         ].receipt_price = 0;
                         receipt_data_money[receipt_data_money.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")].check = false;
                         receipt_data_finance[
                              receipt_data_finance.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")
                         ].receipt_price = 0;
                         receipt_data_finance[
                              receipt_data_finance.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")
                         ].check = false;
                         arr_check_ficance.splice(
                              arr_check_ficance.findIndex((el) => el == 15),
                              1
                         );
                         arr_check_money.splice(
                              arr_check_money.findIndex((el) => el == 9),
                              1
                         );
                         this.setState({ receipt_data_finance, arr_check_ficance, receipt_data_money, arr_check_money });
                    }
               }
          });
     };

     onRemoveAccTurn = (row, rowIndex) => {
          let { order, deleteAccturn } = this.state;
          deleteAccturn.push(row.orderac_id);
          order.accturn.splice(rowIndex, 1);
          order.deleteAccturn = deleteAccturn;
          this.setState({ order });
     };

     /*----------------------Reciept---------------------------*/
     checkBoxFormatterReceiptModal = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null }]}
                    handleChangeText={(e) => this.onChangeCheckboxAddReceipt(e.target.checked, row)}
               />
          );
     };
     onAddReceipt = () => {
          let { modalreceiptData, order, receipt_data_finance, receipt_data_money } = this.state;
          let set_receipt = [];
          if (order.pay_choice === "เงินสด") {
               set_receipt = receipt_data_money;
          } else {
               set_receipt = receipt_data_finance;
          }
          let check_margin = set_receipt.find((el) => el.receipt_method === "ใบรับเงินส่วนต่างมาจิ้น");
          // console.log('check_margin', check_margin)
          if (check_margin && check_margin.receipt_price > 0 && check_margin.receipt_price > 5500) {
               swal({ icon: "warning", title: "กรุณาห้ามกรอกส่วนต่างมาจิ้นเกิน 5,500 บาท", text: "กรอกใหม่อีกครั้ง" });
          } else {
               // console.log(`modalreceiptData`, modalreceiptData);
               set_receipt.map((element) => {
                    if (modalreceiptData.find((e) => e.receipt_method === element.receipt_method)) {
                         order.addReceipt.push({ ...element });
                         order.receipt.push({ ...element });
                         // renameReciptBefore.push({ ...element });
                    }
               });
               modalreceiptData.forEach((element) => {
                    let index = set_receipt
                         .map(function (e) {
                              return e.receipt_method;
                         })
                         .indexOf(element.receipt_method);
                    set_receipt.splice(index, 1);
               });
               // console.log(`order.receipt`, order.receipt);
               this.setState({ order, showReciept: false, modalreceiptData: [] });
          }
     };

     onChangeCheckboxAddReceipt = async (checked, row) => {
          let { modalreceiptData } = this.state;
          ///check
          if (checked) {
               modalreceiptData.push(row);
               ///uncheck
          } else {
               modalreceiptData.splice(
                    modalreceiptData.findIndex((el) => el.receipt_method === row.receipt_method),
                    1
               );
          }
          this.setState({ modalreceiptData });
     };
     onChangeModalPrice = (e, row, rowIndex) => {
          let { receiptData } = this.state;
          receiptData[rowIndex].receipt_price = Number(e.target.value).toLocaleString(undefined, { minimumFractionDigits: 2 });

          this.setState({ receiptData });
     };
     removeReceipt = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Button onClick={() => this.onRemoveReceipt(row, rowIndex)} variant="danger">
                    Remove
               </Button>
          );
     };

     onRemoveReceipt = (row, rowIndex) => {
          let { order, receipt_data_finance, receipt_data_money } = this.state;
          // receiptData.push({ receipt_method: row?.receipt_method, receipt_price: 0, check: false });
          if (order.pay_choice === "เงินสด") {
               receipt_data_money.push({ receipt_method: row?.receipt_method, receipt_price: 0, check: false });
          } else {
               receipt_data_finance.push({ receipt_method: row?.receipt_method, receipt_price: 0, check: false });
          }
          if (row.receipt_id) {
               order.deletereceipt.push(row.receipt_id);
          }
          order.receipt.splice(rowIndex, 1);
          this.setState({ order, receipt_data_money, receipt_data_finance });
     };

     //----------------------TableReciept-------------------------
     TableReciept = () => {
          let { order, receipt_data_money, receipt_data_finance } = this.state;
          return (
               <div className="maxwidt_tbl">
                    <Row style={{ backgroundColor: "#1d419b", color: "#fff", padding: 10, borderRadius: 5 }}>
                         <Col xs={2} className="minwidth-ss">
                              เลือก
                         </Col>
                         <Col xs={5}>รายการ</Col>
                         <Col xs={5} className="d-flex">
                              <Form.Check
                                   type="checkbox"
                                   onChange={(e) => {
                                        if (!this.state.edit_receipt) {
                                             this.setState({ edit_receipt: e.target.checked });
                                        }
                                   }}
                                   disabled={this.state.edit_receipt}
                              />
                              แก้ไขจำนวนเงิน (บาท)
                         </Col>
                    </Row>
                    {order.pay_choice === "เงินสด"
                         ? receipt_data_money.map((el, i) => {
                                let color;
                                if ((i + 1) % 2 === 0) {
                                     color = "#fbfbfb";
                                } else {
                                     color = "#ffffff";
                                }
                                return (
                                     <Row
                                          style={{
                                               backgroundColor: color,
                                               color: "#000",
                                               alignItems: "center",
                                               paddingTop: 10,
                                               paddingBottom: 10,
                                          }}
                                          key={i + 1}
                                     >
                                          <Col xs={2} className="minwidth-ss">
                                               {this.checkBoxFormatterReceiptAdd(i, el, order.pay_choice)}
                                          </Col>
                                          <Col className="minwidth-ml">
                                               {this.inputFormFormatterAdd(el.receipt_method, i, order.pay_choice, el.other)}
                                          </Col>
                                          {el.receipt_method === "ใบเสร็จรับเงินค่ารถ" && order.real_price ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.real_price, order.pay_choice)}</Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่า พรบ." && order.act_premium ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.act_premium, order.pay_choice, true)}</Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย" && order.insurance_premium ? (
                                               <Col xs={5}>
                                                    {this.inputReciptPriceFormatterAdd(i, order.insurance_premium, order.pay_choice, true)}
                                               </Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์" && order.register_price ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.register_price, order.pay_choice, true)}</Col>
                                          ) : el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี" &&
                                            order.insurance_premium &&
                                            order.accbuy.length > 0 ? (
                                               <Col xs={5}>
                                                    {this.inputReciptPriceFormatterAdd(
                                                         i,
                                                         order.accbuy
                                                              .filter((el) => el.items_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)")
                                                              .map((el) => ({ sum: el.order_ac_amount * JSON.parse(el.price_list).price }))
                                                              .reduce(add("sum"), 0) || 0,
                                                         order.pay_choice,
                                                         true
                                                    )}
                                               </Col>
                                          ) : el.receipt_method === "ใบลดหนี้/ใบกำกับภาษี" && order.order_price >= 0 ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.order_price, order.pay_choice, true)}</Col>
                                          ) : (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, 0, order.pay_choice)}</Col>
                                          )}
                                     </Row>
                                );
                           })
                         : receipt_data_finance.map((el, i) => {
                                let color;
                                if ((i + 1) % 2 === 0) {
                                     color = "#fbfbfb";
                                } else {
                                     color = "#ffffff";
                                }
                                return (
                                     <Row
                                          style={{
                                               backgroundColor: color,
                                               color: "#000",
                                               alignItems: "center",
                                               paddingTop: 10,
                                               paddingBottom: 10,
                                          }}
                                          key={i + 1}
                                     >
                                          <Col xs={2}>{this.checkBoxFormatterReceiptAdd(i, el, order.pay_choice)}</Col>
                                          <Col xs={5}>{this.inputFormFormatterAdd(el.receipt_method, i, order.pay_choice, el.other)}</Col>
                                          {el.receipt_method === "ใบรับเงินชั่วคราวค่าเงินดาวน์" && order.down ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.down, order.pay_choice, true)}</Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่า พรบ." && order.act_premium ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.act_premium, order.pay_choice, true)}</Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย" && order.insurance_premium ? (
                                               <Col xs={5}>
                                                    {this.inputReciptPriceFormatterAdd(i, order.insurance_premium, order.pay_choice, true)}
                                               </Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์" && order.register_price ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.register_price, order.pay_choice, true)}</Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่างวดงวดแรก" && order.first_down ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.first_down, order.pay_choice, true)}</Col>
                                          ) : el.receipt_method === "ใบรับเงินชั่วคราวค่าภาษีซัพดาวน์" && order.sub_down ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, (order.sub_down * 5) / 100, order.pay_choice)}</Col>
                                          ) : el.receipt_method === "ใบเสร็จรับเงินค่า VAT 15%" && order.addition_price ? (
                                               <Col xs={5}>
                                                    {this.inputReciptPriceFormatterAdd(i, (order.addition_price * 15) / 100, order.pay_choice)}
                                               </Col>
                                          ) : el.receipt_method === "ใบเสร็จรับเงินค่า VAT 7%" && order.subsidy ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, (order.subsidy * 7) / 100, order.pay_choice)}</Col>
                                          ) : el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี" && order.accbuy.length > 0 ? (
                                               <Col xs={5}>
                                                    {this.inputReciptPriceFormatterAdd(
                                                         i,
                                                         order.accbuy
                                                              .filter((el) => el.items_type === "อุปกรณ์ตกแต่ง (Out Source Supplier)")
                                                              .map((el) => ({ sum: el.order_ac_amount * JSON.parse(el.price_list).price }))
                                                              .reduce(add("sum"), 0) || undefined,
                                                         order.pay_choice,
                                                         true
                                                    )}
                                               </Col>
                                          ) : el.receipt_method === "ใบลดหนี้/ใบกำกับภาษี" && order.order_price >= 0 ? (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, order.order_price, order.pay_choice, true)}</Col>
                                          ) : (
                                               <Col xs={5}>{this.inputReciptPriceFormatterAdd(i, 0, order.pay_choice)}</Col>
                                          )}
                                     </Row>
                                );
                           })}
               </div>
          );
     };

     setAccAddBonus = () => {
          let { name_item, amount_item, price_item, order, previous_path } = this.state;
          // console.log(name_item, amount_item, price_item, order, previous_path);
          if (name_item && amount_item && price_item) {
               if (previous_path === "edit") {
                    order.accbonus.push({
                         items_id: null,
                         items_code: "-",
                         items_name: name_item,
                         items_price: price_item,
                         order_ac_amount: amount_item,
                         order_ac_price: price_item,
                         price_list: JSON.stringify({ cost: price_item, price_add_old: price_item, price_add_new: price_item, price: price_item }),
                    });
                    order.addAccbonus.push({
                         items_id: null,
                         items_code: "-",
                         items_name: name_item,
                         items_price: price_item,
                         order_ac_amount: amount_item,
                         order_ac_price: price_item,
                         price_list: JSON.stringify({ cost: price_item, price_add_old: price_item, price_add_new: price_item, price: price_item }),
                    });
               } else {
                    order.accbonus.push({
                         items_id: null,
                         items_code: "-",
                         items_name: name_item,
                         items_price: price_item,
                         order_ac_amount: amount_item,
                         order_ac_price: price_item,
                         price_list: JSON.stringify({ cost: price_item, price_add_old: price_item, price_add_new: price_item, price: price_item }),
                    });
               }
               this.setState({ name_item: "", amount_item: null, price_item: null, order });
          }
     };
     /*--------------------------------------------------------*/
     onChangeReceipt = (value, id) => {
          // let { receipt_update } = this.state;
          // let index = receipt_update.findIndex((ele) => ele.receipt_id === id);
          // receipt_update[index].receipt_price = value;
          // this.setState({ receipt_update });
          let { order } = this.state;
          let index = order.receipt.findIndex((ele) => ele.receipt_id === id);
          order.receipt[index].receipt_price = value;
          this.setState({ order });
     };
     onChangeNumberReceipt = (e, el) => {
          // console.log("el", el);
          return (
               <Form.Row>
                    <Field
                         type={"number"}
                         value={e}
                         placeholder={"0.00"}
                         decimalScale={2}
                         lg={12}
                         disabled={!el.receipt_id}
                         handleChangeNumber={(ele) => this.onChangeReceipt(ele.floatValue, el.receipt_id)}
                    />
               </Form.Row>
          );
     };
     render() {
          let {
               validated,
               order,
               title,
               show,
               confirm,
               branch,
               itemInsurance,
               showPromotion,
               showAccBonus,
               showAccBuy,
               series,
               model,
               color,
               supplier,
               receiptData,
               acard_no,
               previous_path,
               promoShow,
               accbonusShow,
               accbuyShow,
               clear,
               dataFun,
               type_edit,
               loading,
               renameReciptBefore,
          } = this.state;

          const promotion = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         formatter: this.noFormatter,
                         align: "center",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "promotion_name",
                         text: "รายการ Promotion",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removePromotion,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: order?.promotions,
               keyField: "no",
          };
          const tableSlectPromotion = {
               keyField: "function",
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "promotion_name",
                         text: "รายการ Promotion",
                         headerClasses: "header-custom __right",
                    },
               ],
               data: this.state.promotions,
          };
          const acc_bonus = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         formatter: this.noFormatter,
                         align: "center",
                         headerClasses: "header-custom __left __no",
                         footer: () => <h5>รวม</h5>,
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                         footer: "",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ Car accessories",
                         headerClasses: "header-custom __btn-ml",
                         footer: "",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         formatter: this.amountFormatterBonus,
                         // headerClasses: "header-custom __btn-ss",
                         footer: "",
                    },
                    {
                         dataField: "order_ac_price",
                         text: "ราคา(บาท)",
                         // formatter: this.parseFloatFormatterAccBonus,
                         formatter: (e) => Number(e).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         // headerClasses: "header-custom __btn-s",
                         footer: () => (
                              <h5>
                                   {order.accbonus
                                        .map((ele) => ({ price: ele.order_ac_amount * ele.order_ac_price }))
                                        .reduce(add("price"), 0)
                                        .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                              </h5>
                         ),
                    },
                    {
                         dataField: "acc_image",
                         text: "รูปภาพ",
                         formatter: this.imageViewFormatter,
                         // headerClasses: "header-custom __btn-s",
                         footer: "",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeAccbonus,
                         headerClasses: "header-custom __right __btn-s",
                         footer: "",
                    },
               ],
               data: order?.accbonus,
               keyField: "no",
          };
          const tableSlectAccBonus = {
               keyField: "function",
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatterBonus,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ Car accessories",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "price_list",
                         text: "ราคา(บาท)",
                         formatter: this.parseFloatFormatterAccBonus,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "supplier_name",
                         text: "ร้านค้า",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "acc_image",
                         text: "รูปภาพ accessories",
                         formatter: this.imageFormater,
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: this.state.newSearchAccBonus ? this.state.newSearchAccBonus : this.state.accbonus,
          };
          const acc_buy = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         formatter: this.noFormatter,
                         align: "center",
                         headerClasses: "header-custom __left __no",
                         footer: () => <h5>รวม</h5>,
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                         footer: "",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ Car accessories",
                         headerClasses: "header-custom __btn-ml",
                         footer: "",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         formatter: this.amountFormatterBuy,
                         // headerClasses: "header-custom __btn-ss",
                         footer: "",
                    },
                    {
                         dataField: "order_ac_price",
                         text: "ราคา(บาท)",
                         // formatter: this.parseFloatFormatter,
                         formatter: (e) => Number(e).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         // headerClasses: "header-custom __btn-s",
                         footer: () => (
                              <h5>
                                   {order.accbuy
                                        .map((ele) => ({ price: ele.order_ac_amount * ele.order_ac_price }))
                                        .reduce(add("price"), 0)
                                        .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                              </h5>
                         ),
                    },
                    {
                         dataField: "acc_image",
                         text: "รูปภาพ",
                         formatter: this.imageViewFormatter,
                         // headerClasses: "header-custom __btn-ss",
                         footer: "",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeAccbuy,
                         headerClasses: "header-custom __right __btn-ss",
                         footer: "",
                    },
               ],
               data: order?.accbuy,
               keyField: "no",
          };
          const tableSlectAccBuy = {
               keyField: "function",
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatterBuy,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ Car accessories",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "price_list",
                         text: "ราคา(บาท)",
                         formatter: this.parseFloatFormatterAccBuy,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "supplier_name",
                         text: "ร้านค้า",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "acc_image",
                         text: "รูปภาพ accessories",
                         formatter: this.imageFormater,
                         headerClasses: "header-custom __btn-m",
                    },
               ],
               data: this.state.newSearchAccBuy ? this.state.newSearchAccBuy : this.state.accbuy,
          };
          const conf_payment = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการ ",
                         headerClasses: "header-custom __left __btn-ml",
                    },
                    {
                         dataField: "price",
                         text: "ราคา",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.parseFloatFormatter,
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         formatter: this.unitFormatter,
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: [
                    {
                         title: "ราคาเริ่มต้น",
                         price: order?.start_price ? order.start_price : 0,
                    },
                    {
                         title: "ส่วนลด",
                         price: order?.discount_price ? Number(order.discount_price) : 0,
                    },
                    {
                         title: "Vat 7%",
                         price: order?.vat,
                    },
                    {
                         title: "ราคาซื้อขายจริง",
                         price: order?.real_price ? Number(order.real_price) : 0,
                    },
                    {
                         title: "ซัพดาวน์ (Subdown)",
                         price: order?.sub_down ? Number(order.sub_down) : 0,
                    },
                    {
                         title: "ค่าแนะนำ",
                         price: order?.recommend_price ? Number(order.recommend_price) : 0,
                    },
                    {
                         title: "ค่าอื่น ๆ ที่ระบุไว้",
                         price: order?.other_pay_price ? Number(order.other_pay_price) : 0,
                    },
               ],
               keyField: "no",
          };
          const conf_payment_leas = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการ ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "price",
                         text: "ราคา",
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         formatter: this.unitFormatter,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: [
                    {
                         title: "ราคาเริ่มต้น",
                         price: order?.start_price,
                    },
                    {
                         title: "ส่วนลด",
                         price: order?.discount_price ? Number(order.discount_price) : 0.0,
                    },
                    {
                         title: "Vat 7%",
                         price: order?.vat,
                    },
                    {
                         title: "ราคาซื้อขายจริง",
                         price: order?.real_price,
                    },
                    {
                         title: "ดาวน์",
                         price: order?.down ? Number(order.down) : 0.0,
                    },
                    {
                         title: "ค่าเงินงวดแรก",
                         price: order?.first_down ? Number(order.first_down) : 0.0,
                    },
                    {
                         title: "ซัพดาวน์ (Subdown)",
                         price: order?.sub_down ? Number(order.sub_down) : 0.0,
                    },
                    {
                         title: "ค่าแนะนำ",
                         price: order?.recommend_price ? Number(order.recommend_price) : 0.0,
                    },
                    {
                         title: "ค่าอื่น ๆ ที่ระบุไว้",
                         price: order?.other_pay_price ? Number(order.other_pay_price) : 0.0,
                    },
               ],
               keyField: "no",
          };
          const conf_promo = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ ",
                         formatter: this.noFormatter,
                         align: "center",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "promotion_name",
                         text: "รายการ Promotion",
                         headerClasses: "header-custom __right __btn-ml",
                    },
               ],
               data: order?.promotions,
               keyField: "no",
          };
          const conf_acc_serv = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ ",
                         footer: "",
                         formatter: this.noFormatter,
                         align: "center",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการของแถม",
                         footer: "รวม",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         // footer: this.totalPrice,
                         footer: () => "",
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "order_ac_price",
                         text: "ราคาขาย",
                         // footer: this.totalPrice,
                         footer: () =>
                              order.accbonus
                                   .map((ele) => ({ sum: ele.order_ac_amount * ele.order_ac_price }))
                                   .reduce(add("sum"), 0)
                                   .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         headerClasses: "header-custom __btn",
                         formatter: (e) => Number(e).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         // formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
                    {
                         dataField: "acc_image",
                         text: "รูปภาพ",
                         footer: "",
                         formatter: this.imageViewFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
               ],
               data: order.accbonus ? order.accbonus.map((ele) => ({ ...ele, price: ele.order_ac_amount * ele.order_ac_price })) : [],
               keyField: "no",
          };
          const conf_acc_buy = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ ",
                         footer: "",
                         formatter: this.noFormatter,
                         align: "center",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "items_code",
                         text: "รหัสสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ",
                         footer: "รวม",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         // footer: this.totalPrice,
                         footer: () => "",
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "order_ac_price",
                         text: "ราคาขาย",
                         // footer: this.totalPrice,
                         footer: () =>
                              order.accbuy
                                   .map((ele) => ({ sum: ele.order_ac_amount * ele.order_ac_price }))
                                   .reduce(add("sum"), 0)
                                   .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         headerClasses: "header-custom __btn",
                         // formatter: this.parseFloatFormatter,
                         formatter: (e) => Number(e).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
                    {
                         dataField: "acc_image",
                         text: "รูปภาพ",
                         footer: "",
                         formatter: this.imageViewFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
               ],

               data: order.accbuy ? order.accbuy.map((ele) => ({ ...ele, price: ele.order_ac_amount * ele.order_ac_price })) : [],
               keyField: "no",
          };
          const conf_acc_turn = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ ",
                         footer: "",
                         formatter: this.noFormatter,
                         align: "center",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ",
                         footer: "รวม (นำไปเป็นส่วนลด)",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         footer: this.totalPrice,
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "order_ac_price",
                         text: "ราคาขาย",
                         footer: this.totalPrice,
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
               ],
               data: order?.accturn,
               keyField: "no",
          };
          const conf_receipt = {
               column: [
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         footer: "",
                         formatter: this.listReceipt,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left  __btn",
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         headerClasses: "header-custom  __btn-m",
                         footer: () =>
                              this.totalPriceReceipt(
                                   order.pay_choice === "เงินสด"
                                        ? this.state.receipt_data_money.filter((el) => el.check === true)
                                        : this.state.receipt_data_finance.filter((el) => el.check === true),
                                   order
                              ),
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
               ],
               // data: order.receipt,
               data:
                    order.pay_choice === "เงินสด"
                         ? this.state.receipt_data_money.filter((el) => el.check === true)
                         : this.state.receipt_data_finance.filter((el) => el.check === true),
               keyField: "no",
          };
          const conf_calc = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการ",
                         headerClasses: "header-custom __left __btn-ml",
                    },
                    {
                         dataField: "price",
                         text: "ราคาขาย",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.parseFloatFormatter,
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         formatter: this.unitFormatter,
                         headerClasses: "header-custom __right  __btn-ss",
                    },
               ],
               data: [
                    { title: "ราคาตัวรถ", price: order?.start_price },
                    { title: "ราคาตัวรถเพิ่ม", price: order?.addition_price ? Number(order.addition_price) : 0 },
                    { title: "Margin", price: order?.margin ? Number(order.margin) : 0 },
               ],
               keyField: "no",
          };

          let sumAccBonus = 0;
          conf_acc_serv.data.forEach((element) => {
               sumAccBonus += Number(element.price);
          });

          let sumAccTurn = 0;
          conf_acc_turn.data.forEach((ele) => {
               sumAccTurn += Number(ele.order_ac_price);
          });

          let turnAcc = order.accturn.map((el) => {
               return el.items_price;
          });

          let totalMargin =
               Number(sumAccBonus) -
               Number(sumAccTurn || 0) +
               (order?.discount_price ? order.discount_price : 0) +
               Number(order?.insurance === "Yes" && order.insurance_pay === "บริษัท" ? order?.insurance_premium : 0) +
               (order?.recommend_price ? Number(order.recommend_price) : 0) +
               (order?.other_pay_price ? Number(order.other_pay_price) : 0) +
               (order?.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0) +
               (order?.act === "Yes" && order.act_pay === "บริษัท" ? Number(order?.act_premium) : 0) +
               (order?.sub_down ? order.sub_down : 0) +
               (order.subsidy ? Number(order?.subsidy) : 0);

          let SubdownAndAccBonus = Number(sumAccBonus) - Number(sumAccTurn || 0) + (order?.sub_down ? order.sub_down : 0);

          let overAllMargin = order.addition_price + order.margin;
          let strMargin;
          let margin_over = 0;
          let marginColor;
          if (totalMargin <= overAllMargin) {
               strMargin = "คงเหลือ Margin";
               margin_over = 0;
               marginColor = "__number-green";
          } else {
               strMargin = "ส่วนเกิน Margin";
               margin_over = Math.abs(overAllMargin - totalMargin);
               marginColor = "__number-danger";
          }
          let overSubdown = Number(SubdownAndAccBonus) > Number(overAllMargin) - Number(totalMargin - SubdownAndAccBonus);
          let strSub;
          let strSubNum;
          if (overSubdown) {
               strSub = "ซัพดาวน์เกิน Margin";
               strSubNum = Number(Number(SubdownAndAccBonus) - (Number(overAllMargin) - Number(totalMargin - SubdownAndAccBonus))).toLocaleString(
                    undefined,
                    {
                         minimumFractionDigits: 2,
                         maximumFractionDigits: 2,
                    }
               );
          }
          const conf_calc_serv = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการของแถม",
                         footer: overSubdown ? `${strSub}\n${strMargin}` : `${strMargin}`,
                         footerClasses: "summary-custom __yellow",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "price",
                         text: "ราคาขาย",
                         footer: (e) => this.marginOver(e, overSubdown, strSubNum),
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: `summary-custom __yellow ${marginColor}`,
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: overSubdown ? "บาท\nบาท" : "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom __yellow",
                    },
                    // {
                    //      dataField: 'note',
                    //      text: 'หมายเหตุ',
                    //      formatter: this.noteFormatter,
                    //      footerClasses: 'summary-custom __yellow',
                    //      footer: '',
                    //      headerClasses: 'header-custom __right  __btn',
                    // },
               ],
               data: [
                    ...conf_acc_serv.data.map((el) => ({ field: "summary", title: el.items_name, price: el.price })),
                    { field: "summary", title: "รวมราคาอุปกรณ์", price: sumAccBonus },
                    ...conf_acc_turn.data.map((el) => ({ field: "summary", title: el.items_name, price: el.order_ac_price })),
                    { field: "summary", title: "รวมราคาเทิร์นอุปกรณ์ (ล้อ)", price: sumAccTurn },
                    { field: "summary", title: "ส่วนลดตัวรถ", price: order?.discount_price ? order.discount_price : 0 },
                    { field: "summary", title: "ซัพดาวน์", price: order?.sub_down ? order.sub_down : 0 },
                    { field: "summary", title: "Subsidy", price: order?.subsidy ? order.subsidy : 0 },
                    {
                         field: "summary",
                         title: "ประกัน (แถม)",
                         price: order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0,
                         payer: order.insurance_pay,
                    },
                    {
                         field: "summary",
                         title: "ค่าจดทะเบียน (แถม)",
                         price: order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0,
                         payer: order.register_pay,
                    },
                    { field: "summary", title: "ค่าแนะนำ (แถม)", price: order?.recommend_price ? Number(order.recommend_price) : 0 },
                    { field: "summary", title: "ค่าอื่นๆ (แถม)", price: order?.other_pay_price ? Number(order.other_pay_price) : 0 },
                    {
                         field: "summary-total",
                         title: "ใช้ไปทั้งสิ้น",
                         price: Number(totalMargin),
                    },
                    { field: "summary-total", title: "หักราคาตัวรถที่บวกเพิ่ม", price: order?.addition_price ? Number(order.addition_price) : 0 },
                    { field: "summary-total", title: "หัก margin", price: order?.margin ? Number(order.margin) : 0 },
               ],
               keyField: "no",
          };
          let sumAccBBuy = 0;
          conf_acc_buy.data.forEach((element) => {
               sumAccBBuy += element.price ? Number(element.price) : 0;
          });
          const conf_final_price = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ ",
                         footer: "",
                         formatter: this.noFormatter,
                         align: "center",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "title",
                         text: "รายการ",
                         footer: "Final Price",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "price",
                         text: "ราคา",
                         footer: this.totalPrice,
                         headerClasses: "header-custom __btn",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom __number-blue",
                    },
                    {
                         dataField: "unit",
                         text: "หน่วย",
                         footer: "บาท",
                         formatter: this.unitFormatter,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right  __btn",
                    },
               ],
               data: [
                    {
                         title: "ราคารถ",
                         price: Number(order?.start_price),
                    },
                    {
                         title: "ราคาที่บวกเพิ่ม",
                         price: order?.addition_price ? Number(order.addition_price) : 0,
                    },
                    {
                         title: "ส่วนลดตัวรถ",
                         price: order?.discount_price ? Number(-order.discount_price) : 0,
                    },
                    {
                         title: "เบี้ยประกันภัย",
                         price: order.insurance === "Yes" && order.insurance_pay === "ลูกค้า" ? Number(order?.insurance_premium) : 0,
                    },
                    {
                         title: "พ.ร.บ.",
                         price: order.act === "Yes" && order.act_pay === "ลูกค้า" ? Number(order?.act_premium) : 0,
                    },
                    {
                         title: "ค่าจดทะเบียน",
                         price: order.register === "Yes" && order.register_pay === "ลูกค้า" ? Number(order?.register_price) : 0,
                    },
                    {
                         title: "ค่าอุปกรณ์ซื้อเอง",
                         price: sumAccBBuy,
                    },
                    // {
                    //      title: 'ส่วนต่างที่ลูกค้าจ่ายเอง',
                    //      price: order?.customer_pay ? Number(order.customer_pay) : 0,
                    // },
                    { title: "ราคาประเมินเทิร์นรถยนต์", price: order?.car_turn_price ? -Number(order.car_turn_price) : 0 },
                    {
                         title: "ราคาประเมินเทิร์นอุปกรณ์",
                         price: turnAcc.length > 0 ? -Number(turnAcc.reduce((accumulator, currentValue) => accumulator + currentValue)) : 0,
                    },
                    { title: "ค่าอื่นๆ ที่ระบุไว้", price: order?.other_pay_price ? Number(order.other_pay_price) : 0 },
                    // { title: "รวม Margin ที่ใช้", price: -totalMargin },
               ],
               keyField: "no",
          };

          const turn_table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         formatter: this.noFormatter,
                         align: "center",
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการเทิร์นสินค้า",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         formatter: this.parseFloatFormatter,
                    },
                    {
                         dataField: "order_ac_price",
                         text: "ราคาทุน (บาท)",
                         formatter: this.parseFloatFormatter,
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeBtnFormatterTurn,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: order?.accturn,
               keyField: "no",
          };
          const selectReceipt = {
               column: [
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                    },
                    {
                         dataField: "receipt_price",
                         formatter: this.onChangeNumberReceipt,
                         text: "จำนวนเงิน (บาท)",
                    },
                    {
                         dataField: "cancel",
                         text: "ยกเลิก",
                         formatter: (e, el) => (e ? <label style={{ color: "red" }}>ยกเลิก</label> : <a onClick={() => alert(el)}>ยกเลิก</a>),
                         // headerClasses: "header-custom __right __btn",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeReceipt,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: order?.receipt,
               keyField: "no",
          };
          // console.log("order?.receipt", order.receipt);
          if (order.pay_choice === "เงินสด") {
               this.state.receipt_data_money.map((element) => {
                    if (order.receipt.find((el) => el.receipt_method === element.receipt_method)) {
                         this.state.receipt_data_money.splice(
                              this.state.receipt_data_money.findIndex((e) => e.receipt_method === element.receipt_method),
                              1
                         );
                    }
               });
          } else {
               this.state.receipt_data_finance.map((element) => {
                    if (order.receipt.find((el) => el.receipt_method === element.receipt_method)) {
                         this.state.receipt_data_finance.splice(
                              this.state.receipt_data_finance.findIndex((e) => e.receipt_method === element.receipt_method),
                              1
                         );
                    }
               });
          }
          const receiptModal = {
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatterReceiptModal,
                         headerClasses: "header-custom __left __icon",
                    },

                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         formatter: this.inputFormFormatter,
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputReciptPriceFormatter,
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: order.pay_choice === "เงินสด" ? this.state.receipt_data_money : this.state.receipt_data_finance,
               keyField: "no",
          };

          let renameReceiptTable = {
               column: [
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "",
                         text: "",
                         formatter: this.inputFormFormatterRename,
                    },
                    {
                         dataField: "",
                         text: "แก้ไขรายการ",
                         formatter: this.btnFormFormatterRename,
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.receiptPriceformat,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: renameReciptBefore,
               keyField: "no",
          };

          let receipt = {
               column: [
                    {
                         dataField: "select",
                         text: "เลือก",
                         formatter: this.checkBoxFormatterReceipt,
                         headerClasses: "header-custom __left __icon",
                    },

                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         formatter: this.inputFormFormatter,
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputReciptPriceFormatter,
                         headerClasses: "header-custom __right __btn",
                    },
               ],
               data: receiptData,
               keyField: "no",
          };
          if (type_edit === "money" || previous_path === "add") {
               // let overMargin = Number(totalMargin) - Number(order.margin);
               if (type_edit === "money") {
                    order.editmoney = 1;
                    order.acept_margin = 0;
               } else if (previous_path === "add") {
                    order.acept_margin = 0;
               }
          } else if (type_edit === "common" && previous_path === "edit") {
               order.editmoney = 0;
          }
          let editItem =
               getToken().position_id === 25 || getToken().position_id === 11 || getToken().position_id === 13
                    ? false
                    : order?.makeup > 0
                    ? true
                    : false;
          let editSeries =
               getToken().position_id === 25 || getToken().position_id === 11 || getToken().position_id === 13
                    ? false
                    : order?.car_match_status > 0
                    ? true
                    : false;
          // console.log('order', order)
          // console.log('confirm', confirm)
          switch (confirm) {
               case true:
                    return (
                         <div className="panel">
                              <ScrollToTopOnMount />
                              {title ? (
                                   <Breadcrumbs
                                        title={title}
                                        active={title.length - 1}
                                        button={""}
                                        // onClick={() => this.handleNext(false)}
                                   />
                              ) : null}
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  {/* Show Room Field */}
                                                  <>
                                                       <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โชว์รูม : ",
                                                                      detail: order?.branch_id
                                                                           ? branch.find((e) => Number(e.branch_id) === Number(order.branch_id))
                                                                                  ?.branch_name
                                                                           : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย :",
                                                                      detail: order?.saler_name
                                                                           ? this.state.seller.find((el) => el.user_id === Number(order?.saler_name))
                                                                                  ?.name
                                                                           : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขโทรศัพท์พนักงานขาย :",
                                                                      detail: order?.seller_phone || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "วันที่คาดว่าจะได้รับรถ : ",
                                                                      detail: order?.getcar_date ? dateFormatter(order?.getcar_date) : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "วันที่สร้างใบจอง : ",
                                                                      detail: order?.created_at ? dateFormatter(order?.created_at) : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่จากใบจอง Manual และ GMS (ถ้ามี) :",
                                                                      detail: order.order_manual_code || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* Customer Detail */}
                                                  <>
                                                       <LabelBar type={"title"} title={"รายละเอียดผู้สั่งจอง"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "A-Card No. : ",
                                                                      detail: order?.userinfo_a_card ? order.userinfo_a_card : null,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            {this.state.type_customer === "นิติบุคคล" ? (
                                                                 <>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "ชื่อ ห้าง/ร้าน/บริษัท :",
                                                                                detail: order?.userinfo_name ? order.userinfo_name : null,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "หมายเลขประจำตัวผู้เสียภาษี :",
                                                                                detail: order?.userinfo_idcard,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "ชื่อ-นามสกุล ผู้สั่งจอง :",
                                                                                detail: order?.userinfo_name ? order.userinfo_name : null,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "หมายเลขประจำตัว :",
                                                                                detail: order?.userinfo_idcard,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                 </>
                                                            )}
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขโทรศัพท์ :",
                                                                      detail: order?.userinfo_tel ? order.userinfo_tel : null,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ID LINE :",
                                                                      detail: order?.userinfo_line ? order.userinfo_line : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            {this.state.type_customer !== "นิติบุคคล" && (
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "วันเกิด : ",
                                                                           detail: order?.userinfo_birth_date
                                                                                ? dateFormatter(order.userinfo_birth_date)
                                                                                : "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            )}
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ :",
                                                                      detail: `${order?.userinfo_address} จังหวัด  ${order?.userinfo_province} อำเภอ/เขต ${order?.userinfo_district} ตำบล/แขวง ${order?.userinfo_sub_district} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ที่ใช้ติดต่อ :",
                                                                      detail: `${order?.contact_address} จังหวัด  ${order?.contact_province} อำเภอ/เขต ${order?.contact_district} ตำบล/แขวง ${order?.contact_sub_district} ${order?.contact_zipcode}`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* Owner Detail */}
                                                  <>
                                                       <LabelBar type={"title"} title={"รายละเอียดผู้จดทะเบียน"} />
                                                       <Row>
                                                            {this.state.type_customer === "นิติบุคคล" ? (
                                                                 <>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "ชื่อ ห้าง/ร้าน/บริษัท :",
                                                                                detail: order?.owner_name ? order.owner_name : null,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "หมายเลขประจำตัวผู้เสียภาษี :",
                                                                                detail: order?.owner_idcard,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "ชื่อ-นามสกุล ผู้จดทะเบียน : ",
                                                                                detail: order?.owner_name ? order.owner_name : "-",
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "หมายเลขประจำตัว : ",
                                                                                detail: order?.owner_idcard,
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                 </>
                                                            )}
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ : ",
                                                                      detail: `${order?.owner_address} จังหวัด  ${order?.owner_province} อำเภอ/เขต ${order?.owner_district} ตำบล/แขวง ${order?.owner_sub_district} ${order?.owner_zipcode}`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            {this.state.type_customer !== "นิติบุคคล" && (
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ความสัมพันธ์กับผู้จอง : ",
                                                                           detail: order?.relationship || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            )}
                                                       </Row>
                                                  </>
                                                  {/* Car Series */}
                                                  <>
                                                       <LabelBar type={"title"} title={"เลือกรุ่นรถยนต์"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Serie : ",
                                                                      detail: order?.series_id
                                                                           ? series.find((e) => e.series_id === Number(order.series_id))?.series_name
                                                                           : null,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Model : ",
                                                                      detail: order?.model_id
                                                                           ? model?.find((e) => e.model_id === Number(order.model_id))?.model_name
                                                                           : null,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "สีที่ 1 : ",
                                                                      detail: order?.color_id
                                                                           ? color.find((e) => e.color_id === Number(order.color_id))?.color_name
                                                                           : null,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Model Code : ",
                                                                      detail: order?.model_code,
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* Payment */}
                                                  <>
                                                       <LabelBar type={"title"} title={"การชำระเงิน"} />
                                                       {order?.pay_choice === "เงินสด" ? (
                                                            <>
                                                                 <Row>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "วิธีชำระเงิน : ",
                                                                                detail: order?.pay_choice || "-",
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "จำนวนวันที่ให้เครดิต : ",
                                                                                detail: `${order?.day_credit ? `${order.day_credit} วัน` : "-"} `,
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                 </Row>
                                                                 <div className="content" id="table-content">
                                                                      <Table
                                                                           type="custom"
                                                                           column={conf_payment?.column}
                                                                           data={conf_payment?.data}
                                                                           keyField={conf_payment?.keyField}
                                                                      />
                                                                 </div>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "หมายเหตุ : ",
                                                                           detail: order?.other_note ? order.other_note : "-",
                                                                      }}
                                                                      md={6}
                                                                 />
                                                            </>
                                                       ) : (
                                                            <>
                                                                 <Row>
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "วิธีชำระเงิน : ",
                                                                                detail: order?.pay_choice || "-",
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "บริษัท Leasing : ",
                                                                                detail: order?.leasing_down_id
                                                                                     ? supplier.find(
                                                                                            (e) =>
                                                                                                 Number(e.supplier_id) ===
                                                                                                 Number(order.leasing_down_id)
                                                                                       ).supplier_name
                                                                                     : null,
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "ตัวแทน : ",
                                                                                detail:
                                                                                     order.leasing_down_item_id && order.leasing_down_item_id
                                                                                          ? this.state.agentLeasing.find(
                                                                                                 (e) =>
                                                                                                      Number(e.items_id) ===
                                                                                                      Number(order.leasing_down_item_id)
                                                                                            ).items_name
                                                                                          : null,
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "Leasing : ",
                                                                                detail: order.leasing_down_item_id
                                                                                     ? `${
                                                                                            this.state.typeLeasing.find(
                                                                                                 (e) =>
                                                                                                      Number(e.leasinge_id) ===
                                                                                                      Number(order.leasing_down_leasing_id)
                                                                                            )?.leasing_type
                                                                                       } ดอกเบี้ย ${
                                                                                            this.state.typeLeasing.find(
                                                                                                 (e) =>
                                                                                                      Number(e.leasinge_id) ===
                                                                                                      Number(order.leasing_down_leasing_id)
                                                                                            )?.leasing_interest
                                                                                       }`
                                                                                     : null,
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                      <Table
                                                                           type="custom"
                                                                           column={conf_payment_leas?.column}
                                                                           data={conf_payment_leas?.data}
                                                                           keyField={conf_payment_leas?.keyField}
                                                                      />
                                                                      <LabelBar
                                                                           type={"textheader"}
                                                                           title={{
                                                                                name: "หมายเหตุ : ",
                                                                                detail: order?.other_note ? order.other_note : "-",
                                                                           }}
                                                                           md={6}
                                                                      />
                                                                 </Row>
                                                            </>
                                                       )}
                                                  </>
                                                  {/* Insurance */}
                                                  <>
                                                       <LabelBar type={"title"} title={"การประกันภัย"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ต้องการทำประกันภัย : ",
                                                                      detail: order?.insurance,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "บริษัทประกันภัย : ",
                                                                      detail:
                                                                           order?.insurance === "Yes"
                                                                                ? order?.supplier_insurance_id
                                                                                     ? supplier.find(
                                                                                            (e) =>
                                                                                                 e.supplier_id === Number(order.supplier_insurance_id)
                                                                                       ).supplier_name
                                                                                     : null
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            {/* <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ผู้ติดต่อ : ",
                                                                      detail:
                                                                           order?.insurance === "Yes"
                                                                                ? order?.item_insurance_id
                                                                                     ? itemInsurance.find((e) => e.items_id === Number(order.item_insurance_id))
                                                                                            ?.items_name
                                                                                     : null
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            /> */}
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ประเภทของการประกัน : ",
                                                                      detail:
                                                                           order?.insurance === "Yes"
                                                                                ? order?.type_insurance_id
                                                                                     ? this.state.typeInsurance?.find(
                                                                                            (e) =>
                                                                                                 Number(e.insurance_id) ===
                                                                                                 Number(order.type_insurance_id)
                                                                                       )?.insurance_type
                                                                                     : null
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เบี้ยประกัน : ",
                                                                      detail:
                                                                           order?.insurance === "Yes"
                                                                                ? `${
                                                                                       order.insurance_premium !== 0
                                                                                            ? order.insurance_premium.toLocaleString(undefined, {
                                                                                                   minimumFractionDigits: 2,
                                                                                                   maximumFractionDigits: 2,
                                                                                              })
                                                                                            : "0.00"
                                                                                  } บาท`
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ผู้ชำระเงิน : ",
                                                                      detail: order?.insurance === "Yes" ? order?.insurance_pay : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* ACT */}
                                                  <>
                                                       <LabelBar type={"title"} title={"พ.ร.บ."} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ต้องการทำ พ.ร.บ. : ",
                                                                      detail: order?.act,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "บริษัทประกันภัย : ",
                                                                      detail: order?.act === "Yes" ? order.act_company || "-" : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ประเภท พ.ร.บ. : ",
                                                                      detail:
                                                                           order?.act === "Yes"
                                                                                ? order?.act_name
                                                                                     ? order.act_name
                                                                                     : order?.act_premium
                                                                                     ? this.state.act_type.find(
                                                                                            (e) => e.act_premium === order.act_premium
                                                                                       )?.act_name
                                                                                     : "-"
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เบี้ย พ.ร.บ. : ",
                                                                      detail:
                                                                           order?.act === "Yes"
                                                                                ? `${order?.act_premium.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 2,
                                                                                       maximumFractionDigits: 2,
                                                                                  })}  บาท`
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ผู้ชำระเงิน : ",
                                                                      detail: order?.act === "Yes" ? order?.act_pay : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* Register */}
                                                  <>
                                                       <LabelBar type={"title"} title={"การจดทะเบียน"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ต้องการให้จดทะเบียน : ",
                                                                      detail: order?.register,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ค่าจดทะเบียน : ",
                                                                      detail:
                                                                           order?.register === "Yes"
                                                                                ? `${order?.register_price.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 2,
                                                                                       maximumFractionDigits: 2,
                                                                                  })}  บาท`
                                                                                : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ผู้ชำระเงิน : ",
                                                                      detail: order?.register === "Yes" ? order?.register_pay : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* ป้ายแดง */}
                                                  <>
                                                       <LabelBar type={"title"} title={"ป้ายแดง"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ต้องการป้ายแดง : ",
                                                                      detail: order?.red_label,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            {/* <LabelBar
                          type={'textheader'}
                          title={{
                            name: 'เลือกเลขทะเบียน : ',
                            detail: order?.register_pay,
                          }}
                          md={12}
                        /> */}
                                                       </Row>
                                                  </>
                                                  {/* Promotion Set */}
                                                  <LabelBar type={"title"} title={"Promotion Set"} />
                                                  <div className="content" id={"table-content"}>
                                                       <Table
                                                            type={"custom"}
                                                            column={conf_promo.column}
                                                            data={conf_promo.data}
                                                            keyField={conf_promo.keyField}
                                                       />
                                                  </div>
                                                  {/* Car Accessories Service */}
                                                  <div className="content" id={"table-content"}>
                                                       <LabelBar type={"title"} title={"Car Accessories (แถม)"} />
                                                       <Table
                                                            type={"summary"}
                                                            column={conf_acc_serv?.column}
                                                            data={conf_acc_serv?.data}
                                                            keyField={conf_acc_serv?.keyField}
                                                       />
                                                  </div>
                                                  <ModalPanel
                                                       type={"image"}
                                                       title={"Promotion Set"}
                                                       show={this.state.openModalImg}
                                                       image={ip + this.state.imgPath}
                                                       onHide={() => this.setState({ openModalImg: false })}
                                                  />
                                                  {/* Car Accessories */}
                                                  <div className="content" id={"table-content"}>
                                                       <LabelBar type={"title"} title={"Car Accessories (ลูกค้าซื้อเอง)"} />
                                                       <Table
                                                            type={"summary"}
                                                            column={conf_acc_buy?.column}
                                                            data={conf_acc_buy?.data}
                                                            keyField={conf_acc_buy?.keyField}
                                                       />
                                                  </div>
                                                  {/* Turn Accessories */}
                                                  <>
                                                       <LabelBar type={"title"} title={"เทิร์นสินค้า"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Brand รถ : ",
                                                                      detail: order.brand || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "รุ่นรถตาม Brand : ",
                                                                      detail: order.series_brand || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ปีรถ : ",
                                                                      detail: order.year || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ราคาประเมิน : ",
                                                                      detail: order.car_turn_price
                                                                           ? Number(order.car_turn_price).toLocaleString(undefined, {
                                                                                  maximumFractionDigits: 2,
                                                                                  minimumFractionDigits: 2,
                                                                             })
                                                                           : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                       {order.accturn && order.accturn.length > 0 && (
                                                            <Table
                                                                 type={"summary"}
                                                                 column={conf_acc_turn?.column}
                                                                 data={conf_acc_turn?.data}
                                                                 keyField={conf_acc_turn?.keyField}
                                                            />
                                                       )}
                                                  </>
                                                  {/* ส่วนต่างที่ลูกจ่ายเอง */}
                                                  {/* <>
                                                       <LabelBar type={'title'} title={'ส่วนต่างที่ลูกจ่ายเอง'} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={'textheader'}
                                                                 title={{
                                                                      name: 'ส่วนต่างที่ลูกค้าจ่ายเอง ',
                                                                      detail: `${
                                                                           order?.customer_pay
                                                                                ? order.customer_pay.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 2,
                                                                                       maximumFractionDigits: 2,
                                                                                  })
                                                                                : '0.00'
                                                                      }  บาท`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </> */}
                                                  {/* เงินจอง */}
                                                  <>
                                                       <LabelBar type={"title"} title={"เงินจอง"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เงินจอง ",
                                                                      detail: `${
                                                                           order?.order_price
                                                                                ? order.order_price.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 2,
                                                                                       maximumFractionDigits: 2,
                                                                                  })
                                                                                : "0.00"
                                                                      }  บาท`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Vat เงินจอง ",
                                                                      detail: `${
                                                                           order?.order_vat
                                                                                ? order.order_vat.toLocaleString(undefined, {
                                                                                       minimumFractionDigits: 2,
                                                                                       maximumFractionDigits: 2,
                                                                                  })
                                                                                : "0.00"
                                                                      }  บาท`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* Receipt */}
                                                  <LabelBar type={"title"} title={"รายละเอียดเงินรับชั่วคราว"} />
                                                  <div className="content" id={"table-content"}>
                                                       <Table
                                                            type={"summary"}
                                                            column={conf_receipt?.column}
                                                            data={this.state.previous_path === "edit" ? order.receipt || [] : conf_receipt.data || []}
                                                            keyField={conf_receipt?.keyField}
                                                       />
                                                  </div>
                                                  {/* หมายเหตุ */}
                                                  <>
                                                       <LabelBar type={"title"} title={"หมายเหตุ"} />
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเหตุ",
                                                                      detail: order?.note ? order?.note : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  </>
                                                  {/* Calculate Margin */}

                                                  <LabelBar type={"title"} title={"Calculate Margin"} />
                                                  <div className="content" id={"table-content"}>
                                                       <Table
                                                            type={"custom"}
                                                            column={conf_calc?.column}
                                                            data={conf_calc?.data}
                                                            keyField={conf_calc?.keyField}
                                                       />
                                                  </div>
                                                  <div className="content" id={"table-content"}>
                                                       <Table
                                                            type={"summary"}
                                                            column={conf_calc_serv.column}
                                                            data={conf_calc_serv.data}
                                                            keyField={conf_calc_serv.keyField}
                                                       />
                                                  </div>
                                                  {/* Final Price */}
                                                  <div className="content" id={"table-content"}>
                                                       <LabelBar type={"title"} title={"Final Price"} />
                                                       <Table
                                                            type={"summary"}
                                                            column={conf_final_price?.column}
                                                            data={conf_final_price?.data}
                                                            keyField={conf_final_price?.keyField}
                                                       />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="button-group __submit row">
                                        <Col xs={loading ? 12 : 6}>
                                             {
                                                  loading ? (
                                                       <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            Loading...
                                                       </Button>
                                                  ) : this.state.previous_path && this.state.previous_path === "edit" ? (
                                                       // loading ? (
                                                       //      <Button variant="outline-primary" disabled style={{ backgroundColor: '#fff' }}>
                                                       //           <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                       //           Loading...
                                                       //      </Button>
                                                       // ) : (
                                                       <Button onClick={this.handleEdit} type="submit">
                                                            Submit
                                                       </Button>
                                                  ) : (
                                                       // )
                                                       // loading ? (
                                                       //      <Button variant="outline-primary" disabled style={{ backgroundColor: '#fff' }}>
                                                       //           <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                       //           Loading...
                                                       //      </Button>
                                                       // ) : (
                                                       <Button onClick={this.handleSubmit} type="submit">
                                                            Submit
                                                       </Button>
                                                  )
                                                  // )
                                             }
                                        </Col>
                                        {loading ? null : (
                                             <Col xs={6}>
                                                  <Button variant="light" onClick={() => this.handleNext(false)}>
                                                       แก้ไข
                                                  </Button>
                                             </Col>
                                        )}
                                   </div>
                              </div>
                         </div>
                    );
               default:
                    return (
                         <div className="panel">
                              {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} /> : null}
                              <Form
                                   noValidate
                                   validated={validated}
                                   onSubmit={(event) => {
                                        this.handleConfirm(event, totalMargin, SubdownAndAccBonus);
                                   }}
                              >
                                   {this.state.loadingData ? (
                                        <Loader />
                                   ) : (
                                        <div className="content __input-panel">
                                             {/* Order Detail */}
                                             {type_edit === "common" || previous_path === "add" ? (
                                                  <>
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>ข้อมูลการจองรถ</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      {/* Customer Detail */}
                                                                      <div>
                                                                           <LabelBar type={"title"} title={"รายละเอียดผู้สั่งจอง"} />
                                                                           <Form.Row>
                                                                                {this.state.acardno && (
                                                                                     <Field
                                                                                          type={"autocomplete"}
                                                                                          title={"A-Card No."}
                                                                                          name={"userinfo_a_card"}
                                                                                          value={order?.userinfo_a_card ? order.userinfo_a_card : ""}
                                                                                          placeholder={"กรุณากรอกA-Card No."}
                                                                                          require={true}
                                                                                          validated={validated}
                                                                                          handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                                          handleOnInputChange={this.handleOnInputChange}
                                                                                          option={acard_no}
                                                                                          md={6}
                                                                                          disabled={
                                                                                               order &&
                                                                                               order.orp_code &&
                                                                                               (getToken().position_id === 25 ||
                                                                                               getToken().position_id === 11
                                                                                                    ? false
                                                                                                    : true)
                                                                                          }
                                                                                     />
                                                                                )}
                                                                                {!this.state.acardno && (
                                                                                     <Field
                                                                                          type={"autocomplete"}
                                                                                          title={"A-Card No."}
                                                                                          name={"userinfo_a_card"}
                                                                                          value={order?.userinfo_a_card ? order.userinfo_a_card : ""}
                                                                                          placeholder={"กรุณากรอกA-Card No."}
                                                                                          require={true}
                                                                                          validated={validated}
                                                                                          handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                                          handleOnInputChange={this.handleOnInputChange}
                                                                                          option={acard_no}
                                                                                          md={6}
                                                                                          disabled={
                                                                                               order &&
                                                                                               order.orp_code &&
                                                                                               (getToken().position_id === 25 ||
                                                                                               getToken().position_id === 11
                                                                                                    ? false
                                                                                                    : true)
                                                                                          }
                                                                                     />
                                                                                )}
                                                                                {this.state.type_customer === "นิติบุคคล" ? (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"ชื่อ ห้าง/ร้าน/บริษัท (พร้อมสาขา)"}
                                                                                               name={"userinfo_name"}
                                                                                               value={order?.userinfo_name && order.userinfo_name}
                                                                                               placeholder={
                                                                                                    "กรุณากรอก ชื่อ ห้าง/ร้าน/บริษัท (พร้อมสาขา)"
                                                                                               }
                                                                                               require={true}
                                                                                               md={6}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                               disabled={
                                                                                                    order &&
                                                                                                    order.orp_code &&
                                                                                                    (getToken().position_id === 25 ||
                                                                                                    getToken().position_id === 11
                                                                                                         ? false
                                                                                                         : true)
                                                                                               }
                                                                                          />
                                                                                     </>
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"ชื่อ-นามสกุล ผู้สั่งจอง"}
                                                                                               name={"userinfo_name"}
                                                                                               value={order?.userinfo_name && order.userinfo_name}
                                                                                               placeholder={"กรุณากรอก ชื่อ-นามสกุล ผู้สั่งจอง"}
                                                                                               require={true}
                                                                                               md={6}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                               disabled={
                                                                                                    order &&
                                                                                                    order.orp_code &&
                                                                                                    (getToken().position_id === 25 ||
                                                                                                    getToken().position_id === 11
                                                                                                         ? false
                                                                                                         : true)
                                                                                               }
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                           <Form.Row>
                                                                                {this.state.type_customer === "นิติบุคคล" ? (
                                                                                     <Field
                                                                                          id={"field-id-card"}
                                                                                          type={"number"}
                                                                                          title={"หมายเลขประจำตัวผู้เสียภาษี"}
                                                                                          name={"userinfo_idcard"}
                                                                                          value={order?.userinfo_idcard}
                                                                                          placeholder={"กรุณากรอกหมายเลขประจำตัวผู้เสียภาษี"}
                                                                                          require={true}
                                                                                          format="#-####-#####-##-#"
                                                                                          mask="X"
                                                                                          typeInput={"id"}
                                                                                          handleChangeNumber={this.handleChangeIdCard}
                                                                                     />
                                                                                ) : (
                                                                                     <Field
                                                                                          id={"field-id-card"}
                                                                                          // type={"number"}
                                                                                          type={"text"}
                                                                                          title={"หมายเลขประจำตัว"}
                                                                                          name={"userinfo_idcard"}
                                                                                          value={order?.userinfo_idcard}
                                                                                          placeholder={"กรุณากรอกหมายเลขประจำตัว"}
                                                                                          require={true}
                                                                                          // format="#-####-#####-##-#"
                                                                                          // mask="X"
                                                                                          // typeInput={"id"}
                                                                                          // handleChangeNumber={this.handleChangeIdCard}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                )}

                                                                                <Field
                                                                                     id={"field-tel"}
                                                                                     type={"text"}
                                                                                     title={"หมายเลขโทรศัพท์"}
                                                                                     name={"userinfo_tel"}
                                                                                     value={order?.userinfo_tel ? order.userinfo_tel : null}
                                                                                     placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                                                                     require={true}
                                                                                     md={3}
                                                                                     // format="###-###-####"
                                                                                     // mask="X"
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"ID LINE"}
                                                                                     name={"userinfo_line"}
                                                                                     placeholder={"กรุณากรอก Id LINE"}
                                                                                     value={order?.userinfo_line}
                                                                                     require={false}
                                                                                     md={3}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                {/* {this.state.type_customer !== "นิติบุคคล" && (
                                                                                <> */}
                                                                                <Field
                                                                                     id={"date-of-birth"}
                                                                                     type={"date"}
                                                                                     title={
                                                                                          this.state.type_customer !== "นิติบุคคล"
                                                                                               ? "วันเกิด"
                                                                                               : "วันจดทะเบียนพาณิชย์"
                                                                                     }
                                                                                     name={"userinfo_birth_date"}
                                                                                     value={this.state.birthDate ? this.state.birthDate : null}
                                                                                     validated={validated}
                                                                                     placeholder={"วว/ดด/ปปปป"}
                                                                                     formatDate={"DD/MM/YYYY"}
                                                                                     require={true}
                                                                                     md={3}
                                                                                     handleSelectDate={this.handleSelectDateBirth}
                                                                                />
                                                                                {/* </>
                                                                           )} */}
                                                                           </Form.Row>
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"ที่อยู่"}
                                                                                     name={"userinfo_address"}
                                                                                     placeholder={"กรุณากรอกที่อยู่"}
                                                                                     value={order?.userinfo_address}
                                                                                     require={true}
                                                                                     md={6}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                {order.abroad === "ใน" ? (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"จังหวัด"}
                                                                                               name={"userinfo_province"}
                                                                                               placeholder={"กรุณาเลือกจังหวัด"}
                                                                                               option={this.state.provice?.map((e) => ({
                                                                                                    value: e.province,
                                                                                                    name: e.province,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.userinfo_province
                                                                                                         ? order.userinfo_province
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"อำเภอ/เขต"}
                                                                                               name={"userinfo_district"}
                                                                                               placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                               option={this.state.amphuresInfo?.map((e) => ({
                                                                                                    value: e.district,
                                                                                                    name: e.district,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.userinfo_district
                                                                                                         ? order.userinfo_district
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                     </>
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"ถนน (อำเภอ/เขต)"}
                                                                                               name={"userinfo_district"}
                                                                                               placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                               value={
                                                                                                    order?.userinfo_district
                                                                                                         ? order.userinfo_district
                                                                                                         : ""
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"เมือง (จังหวัด)"}
                                                                                               name={"userinfo_province"}
                                                                                               placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                               value={
                                                                                                    order?.userinfo_province
                                                                                                         ? order.userinfo_province
                                                                                                         : ""
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                           <Form.Row>
                                                                                {order.abroad === "ใน" ? (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"ตำบล/แขวง"}
                                                                                               name={"userinfo_sub_district"}
                                                                                               placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                               option={this.state.subdistrictInfo?.map((e) => ({
                                                                                                    value: e.sub_district,
                                                                                                    name: e.sub_district,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.userinfo_sub_district
                                                                                                         ? order.userinfo_sub_district
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                          {/* {order.userinfo_zipcode && ( */}
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"รหัสไปรษณีย์"}
                                                                                               name={"userinfo_zipcode"}
                                                                                               placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                               option={this.state.zipCodeUserInfo.map((e) => ({
                                                                                                    value: e,
                                                                                                    name: e,
                                                                                               }))}
                                                                                               require={true}
                                                                                               md={3}
                                                                                               value={
                                                                                                    order?.userinfo_zipcode
                                                                                                         ? order.userinfo_zipcode
                                                                                                         : null
                                                                                               }
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                          {/* )} */}
                                                                                          {/* {!order.userinfo_zipcode && (
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"รหัสไปรษณีย์"}
                                                                                     name={"userinfo_zipcode"}
                                                                                     placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                     require={true}
                                                                                     md={3}
                                                                                     disabled={true}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                           )} */}
                                                                                     </>
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"รหัสไปรษณีย์"}
                                                                                               name={"userinfo_zipcode"}
                                                                                               placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                               require={true}
                                                                                               md={3}
                                                                                               value={
                                                                                                    order?.userinfo_zipcode
                                                                                                         ? order.userinfo_zipcode
                                                                                                         : ""
                                                                                               }
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                      </div>
                                                                      {/* Contact Address */}
                                                                      <div className="input-auto">
                                                                           <LabelBar
                                                                                type={"checkbox"}
                                                                                title={"ที่อยู่ที่ใช้ติดต่อ :"}
                                                                                label={[{ value: 0, name: "ที่ใช้ที่อยู่เดียวกัน" }]}
                                                                                name={"sameContact"}
                                                                                handleChangeText={(e) => {
                                                                                     if (previous_path === "edit") {
                                                                                          this.setState({ fristSameCon: false });
                                                                                     }
                                                                                     this.handleChangeCheckbox(e);
                                                                                }}
                                                                           />
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"ที่อยู่ที่ใช้ติดต่อ"}
                                                                                     name={"contact_address"}
                                                                                     value={order?.contact_address}
                                                                                     placeholder={"กรุณากรอกที่อยู่"}
                                                                                     require={true}
                                                                                     md={6}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                {previous_path === "add" ? (
                                                                                     this.state.sameContact && order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"ถนน (อำเภอ/เขต)"}
                                                                                                    name={"contact_district"}
                                                                                                    placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                                    value={
                                                                                                         order?.contact_district
                                                                                                              ? order.contact_district
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"เมือง (จังหวัด)"}
                                                                                                    name={"contact_province"}
                                                                                                    placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                                    value={
                                                                                                         order?.contact_province
                                                                                                              ? order.contact_province
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"จังหวัด"}
                                                                                                    name={"contact_province"}
                                                                                                    placeholder={"กรุณาเลือกจังหวัด"}
                                                                                                    option={this.state.provice?.map((e) => ({
                                                                                                         value: e.province,
                                                                                                         name: e.province,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order.contact_province
                                                                                                              ? order.contact_province
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />

                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"อำเภอ/เขต"}
                                                                                                    name={"contact_district"}
                                                                                                    placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                                    option={this.state.amphuresContact?.map((e) => ({
                                                                                                         value: e.district,
                                                                                                         name: e.district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.contact_district
                                                                                                              ? order.contact_district
                                                                                                              : null
                                                                                                    }
                                                                                                    // value={2}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.fristSameCon ? (
                                                                                     order.contact_sub_district === "-" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"ถนน (อำเภอ/เขต)"}
                                                                                                    name={"contact_district"}
                                                                                                    placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                                    value={
                                                                                                         order?.contact_district
                                                                                                              ? order.contact_district
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"เมือง (จังหวัด)"}
                                                                                                    name={"contact_province"}
                                                                                                    placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                                    value={
                                                                                                         order?.contact_province
                                                                                                              ? order.contact_province
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"จังหวัด"}
                                                                                                    name={"contact_province"}
                                                                                                    placeholder={"กรุณาเลือกจังหวัด"}
                                                                                                    option={this.state.provice?.map((e) => ({
                                                                                                         value: e.province,
                                                                                                         name: e.province,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order.contact_province
                                                                                                              ? order.contact_province
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />

                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"อำเภอ/เขต"}
                                                                                                    name={"contact_district"}
                                                                                                    placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                                    option={this.state.amphuresContact?.map((e) => ({
                                                                                                         value: e.district,
                                                                                                         name: e.district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.contact_district
                                                                                                              ? order.contact_district
                                                                                                              : null
                                                                                                    }
                                                                                                    // value={2}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.sameContact ? (
                                                                                     order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"ถนน (อำเภอ/เขต)"}
                                                                                                    name={"contact_district"}
                                                                                                    placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                                    value={
                                                                                                         order?.contact_district
                                                                                                              ? order.contact_district
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"เมือง (จังหวัด)"}
                                                                                                    name={"contact_province"}
                                                                                                    placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                                    value={
                                                                                                         order?.contact_province
                                                                                                              ? order.contact_province
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"จังหวัด"}
                                                                                                    name={"contact_province"}
                                                                                                    placeholder={"กรุณาเลือกจังหวัด"}
                                                                                                    option={this.state.provice?.map((e) => ({
                                                                                                         value: e.province,
                                                                                                         name: e.province,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order.contact_province
                                                                                                              ? order.contact_province
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"อำเภอ/เขต"}
                                                                                                    name={"contact_district"}
                                                                                                    placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                                    option={this.state.amphuresContact?.map((e) => ({
                                                                                                         value: e.district,
                                                                                                         name: e.district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.contact_district
                                                                                                              ? order.contact_district
                                                                                                              : null
                                                                                                    }
                                                                                                    // value={2}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"จังหวัด"}
                                                                                               name={"contact_province"}
                                                                                               placeholder={"กรุณาเลือกจังหวัด"}
                                                                                               option={this.state.provice?.map((e) => ({
                                                                                                    value: e.province,
                                                                                                    name: e.province,
                                                                                               }))}
                                                                                               value={
                                                                                                    order.contact_province
                                                                                                         ? order.contact_province
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />

                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"อำเภอ/เขต"}
                                                                                               name={"contact_district"}
                                                                                               placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                               option={this.state.amphuresContact?.map((e) => ({
                                                                                                    value: e.district,
                                                                                                    name: e.district,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.contact_district
                                                                                                         ? order.contact_district
                                                                                                         : null
                                                                                               }
                                                                                               // value={2}
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                           <Form.Row>
                                                                                {previous_path === "add" ? (
                                                                                     this.state.sameContact && order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"contact_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    value={
                                                                                                         order?.contact_zipcode
                                                                                                              ? order.contact_zipcode
                                                                                                              : ""
                                                                                                    }
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"ตำบล/แขวง"}
                                                                                                    name={"contact_sub_district"}
                                                                                                    placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                                    option={this.state.subdistrictContact?.map(
                                                                                                         (e) => ({
                                                                                                              value: e.sub_district,
                                                                                                              name: e.sub_district,
                                                                                                         })
                                                                                                    )}
                                                                                                    value={
                                                                                                         order?.contact_sub_district
                                                                                                              ? order.contact_sub_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"contact_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    option={this.state.zipCodeContact?.map((e) => ({
                                                                                                         value: e,
                                                                                                         name: e,
                                                                                                    }))}
                                                                                                    value={this.state.order?.contact_zipcode}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.fristSameCon ? (
                                                                                     order.contact_sub_district === "-" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"contact_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    value={
                                                                                                         order?.contact_zipcode
                                                                                                              ? order.contact_zipcode
                                                                                                              : ""
                                                                                                    }
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"ตำบล/แขวง"}
                                                                                                    name={"contact_sub_district"}
                                                                                                    placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                                    option={this.state.subdistrictContact?.map(
                                                                                                         (e) => ({
                                                                                                              value: e.sub_district,
                                                                                                              name: e.sub_district,
                                                                                                         })
                                                                                                    )}
                                                                                                    value={
                                                                                                         order?.contact_sub_district
                                                                                                              ? order.contact_sub_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"contact_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    option={this.state.zipCodeContact?.map((e) => ({
                                                                                                         value: e,
                                                                                                         name: e,
                                                                                                    }))}
                                                                                                    value={this.state.order?.contact_zipcode}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.sameContact ? (
                                                                                     order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"contact_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    value={
                                                                                                         order?.contact_zipcode
                                                                                                              ? order.contact_zipcode
                                                                                                              : ""
                                                                                                    }
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"ตำบล/แขวง"}
                                                                                                    name={"contact_sub_district"}
                                                                                                    placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                                    option={this.state.subdistrictContact?.map(
                                                                                                         (e) => ({
                                                                                                              value: e.sub_district,
                                                                                                              name: e.sub_district,
                                                                                                         })
                                                                                                    )}
                                                                                                    value={
                                                                                                         order?.contact_sub_district
                                                                                                              ? order.contact_sub_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"contact_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    option={this.state.zipCodeContact?.map((e) => ({
                                                                                                         value: e,
                                                                                                         name: e,
                                                                                                    }))}
                                                                                                    value={this.state.order?.contact_zipcode}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"ตำบล/แขวง"}
                                                                                               name={"contact_sub_district"}
                                                                                               placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                               option={this.state.subdistrictContact?.map((e) => ({
                                                                                                    value: e.sub_district,
                                                                                                    name: e.sub_district,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.contact_sub_district
                                                                                                         ? order.contact_sub_district
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"รหัสไปรษณีย์"}
                                                                                               name={"contact_zipcode"}
                                                                                               placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                               option={this.state.zipCodeContact?.map((e) => ({
                                                                                                    value: e,
                                                                                                    name: e,
                                                                                               }))}
                                                                                               value={this.state.order?.contact_zipcode}
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                      </div>
                                                                      {/* Show Room Field */}
                                                                      <div>
                                                                           <LabelBar type={"title"} title={"ข้อมูลโชว์รูม"} />
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"select"}
                                                                                     title={"โชว์รูม"}
                                                                                     name={"branch_id"}
                                                                                     placeholder={"กรุณาเลือกโชว์รูม"}
                                                                                     option={branch?.map((e) => ({
                                                                                          value: e.branch_id,
                                                                                          name: e.branch_name,
                                                                                     }))}
                                                                                     value={order?.branch_id ? order.branch_id : null}
                                                                                     require={true}
                                                                                     md={6}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                <Field
                                                                                     handleChangeAutocomplete={this.handleChangeAutocompleteSeller}
                                                                                     type={"autocomplete"}
                                                                                     title={"พนักงานขาย"}
                                                                                     placeholder={
                                                                                          order?.saler_name
                                                                                               ? this.state.seller.find(
                                                                                                      (el) => el.user_id === Number(order?.saler_name)
                                                                                                 )?.name
                                                                                               : "กรุณากรอก ชื่อ-สกุล"
                                                                                     }
                                                                                     validated={validated}
                                                                                     value={
                                                                                          order?.saler_name
                                                                                               ? this.state.seller.find(
                                                                                                      (el) => el.user_id === Number(order?.saler_name)
                                                                                                 )?.name
                                                                                               : ""
                                                                                     }
                                                                                     require={true}
                                                                                     md={6}
                                                                                     option={
                                                                                          this.state.seller_set &&
                                                                                          this.state.seller_set.map((el) => el.name)
                                                                                     }
                                                                                />
                                                                                <Field
                                                                                     // id={"field-tel"}
                                                                                     type={"text"}
                                                                                     title={"หมายเลขโทรศัพท์พนักงานขาย"}
                                                                                     name={"seller_phone"}
                                                                                     value={order?.seller_phone ? order.seller_phone : ""}
                                                                                     placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                                                                     require={true}
                                                                                     md={6}
                                                                                     // format="###-###-####"
                                                                                     // mask="X"
                                                                                     // typeInput={"tel"}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                <Field
                                                                                     id={"field-get-car-date"}
                                                                                     type={"date"}
                                                                                     title={"วันที่คาดว่าจะได้รับรถ"}
                                                                                     name={"getcar_date"}
                                                                                     value={
                                                                                          this.state.dateGetCar ? dayjs(this.state.dateGetCar) : null
                                                                                     }
                                                                                     validated={validated}
                                                                                     placeholder={"วว/ดด/ปปปป"}
                                                                                     formatDate={"DD/MM/YYYY"}
                                                                                     require={true}
                                                                                     md={6}
                                                                                     handleSelectDate={this.handleSelectDate}
                                                                                />
                                                                                <Field
                                                                                     id={"field-get-car-date"}
                                                                                     type={"date"}
                                                                                     title={"วันที่สร้างใบจอง"}
                                                                                     name={"created_at"}
                                                                                     value={
                                                                                          this.state.created_at ? dayjs(this.state.created_at) : null
                                                                                     }
                                                                                     validated={validated}
                                                                                     // disabled={true}
                                                                                     placeholder={"วว/ดด/ปปปป"}
                                                                                     formatDate={"DD/MM/YYYY"}
                                                                                     md={6}
                                                                                     handleSelectDate={this.handleSelectCreate}
                                                                                     disabled={
                                                                                          getToken().position_id === 25 ||
                                                                                          getToken().position_id === 11
                                                                                               ? false
                                                                                               : true
                                                                                     }
                                                                                />
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"เลขที่จากใบจอง Manual และ GMS (ถ้ามี)"}
                                                                                     name={"order_manual_code"}
                                                                                     value={order.order_manual_code && order.order_manual_code}
                                                                                     placeholder={"กรุณากรอก เลขที่จากใบจอง Manual และ GMS (ถ้ามี)"}
                                                                                     require={false}
                                                                                     md={6}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                           </Form.Row>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/* Owner Detail */}
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>รายละเอียดผู้จดทะเบียน</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      {/* Show Room Field */}
                                                                      <div>
                                                                           <LabelBar
                                                                                type={"checkbox"}
                                                                                title={"ข้อมูลผู้จดทะเบียน :"}
                                                                                label={[{ value: 0, name: "ใช้ข้อมูลเดียวกับผู้จอง" }]}
                                                                                // arrChecked={[0]}
                                                                                name={"sameOwner"}
                                                                                handleChangeText={(e) => {
                                                                                     if (previous_path === "edit") {
                                                                                          this.setState({ fristSameOwn: false });
                                                                                     }
                                                                                     this.handleChangeCheckbox(e);
                                                                                }}
                                                                           />
                                                                           <Form.Row>
                                                                                {this.state.type_customer === "นิติบุคคล" ? (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"ชื่อ ห้าง/ร้าน/บริษัท (พร้อมสาขา)"}
                                                                                               name={"owner_name"}
                                                                                               value={order?.owner_name && order.owner_name}
                                                                                               placeholder={
                                                                                                    "กรุณากรอก ชื่อ ห้าง/ร้าน/บริษัท (พร้อมสาขา)"
                                                                                               }
                                                                                               require={true}
                                                                                               md={6}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                               disabled={
                                                                                                    order &&
                                                                                                    order.orp_code &&
                                                                                                    (getToken().position_id === 25 ||
                                                                                                    getToken().position_id === 11
                                                                                                         ? false
                                                                                                         : true)
                                                                                               }
                                                                                          />
                                                                                     </>
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"คำนำหน้า"}
                                                                                               name={"owner_prefix"}
                                                                                               value={order?.owner_prefix && order?.owner_prefix}
                                                                                               placeholder={"กรอกคำนำหน้า"}
                                                                                               require={true}
                                                                                               md={2}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                               disabled={
                                                                                                    order &&
                                                                                                    order.orp_code &&
                                                                                                    (getToken().position_id === 25 ||
                                                                                                    getToken().position_id === 11
                                                                                                         ? false
                                                                                                         : true)
                                                                                               }
                                                                                          />
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"ชื่อ-นามสกุล ผู้สั่งจอง"}
                                                                                               name={"owner_name"}
                                                                                               value={order?.owner_name && order.owner_name}
                                                                                               placeholder={"กรุณากรอก ชื่อ-นามสกุล ผู้สั่งจอง"}
                                                                                               require={true}
                                                                                               md={4}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                               disabled={
                                                                                                    order &&
                                                                                                    order.orp_code &&
                                                                                                    (getToken().position_id === 25 ||
                                                                                                    getToken().position_id === 11
                                                                                                         ? false
                                                                                                         : true)
                                                                                               }
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                                {this.state.type_customer === "นิติบุคคล" ? (
                                                                                     <Field
                                                                                          id={"field-id-card"}
                                                                                          type={"number"}
                                                                                          title={"หมายเลขประจำตัวผู้เสียภาษี"}
                                                                                          name={"owner_idcard"}
                                                                                          value={order?.owner_idcard}
                                                                                          placeholder={"กรุณากรอกหมายเลขประจำตัวผู้เสียภาษี"}
                                                                                          require={true}
                                                                                          format="#-####-#####-##-#"
                                                                                          mask="X"
                                                                                          typeInput={"id"}
                                                                                          handleChangeNumber={this.handleChangeIdCard}
                                                                                     />
                                                                                ) : (
                                                                                     <Field
                                                                                          id={"owner_id_card"}
                                                                                          // type={"number"}
                                                                                          type={"text"}
                                                                                          title={"หมายเลขประจำตัว"}
                                                                                          name={"owner_idcard"}
                                                                                          placeholder={"กรุณากรอกหมายเลขประจำตัว"}
                                                                                          require={true}
                                                                                          value={order.owner_idcard}
                                                                                          md={3}
                                                                                          // format="#-####-#####-##-#"
                                                                                          // mask="X"
                                                                                          // typeInput={"id"}
                                                                                          // handleChangeNumber={this.handleChangeIdCardOwner}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                )}
                                                                                {this.state.type_customer !== "นิติบุคคล" && (
                                                                                     <Field
                                                                                          type={"select"}
                                                                                          title={"ความสัมพันธ์กับผู้จอง"}
                                                                                          name={"relationship"}
                                                                                          placeholder={"กรุณาเลือกความสัมพันธ์กับผู้จอง"}
                                                                                          option={this.state.relation_booking_data?.map((e) => ({
                                                                                               value: e,
                                                                                               name: e,
                                                                                          }))}
                                                                                          disabled={
                                                                                               order.userinfo_idcard === order.owner_idcard ||
                                                                                               this.state.sameOwn
                                                                                                    ? true
                                                                                                    : false
                                                                                          }
                                                                                          value={order.relationship}
                                                                                          require={true}
                                                                                          md={3}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                )}
                                                                           </Form.Row>
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"ที่อยู่"}
                                                                                     name={"owner_address"}
                                                                                     placeholder={"กรุณากรอกที่อยู่"}
                                                                                     require={true}
                                                                                     value={order.owner_address}
                                                                                     md={6}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                {previous_path === "add" ? (
                                                                                     this.state.sameOwner && order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"ถนน (อำเภอ/เขต)"}
                                                                                                    name={"owner_district"}
                                                                                                    placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                                    value={
                                                                                                         order?.owner_district
                                                                                                              ? order.owner_district
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"เมือง (จังหวัด)"}
                                                                                                    name={"owner_province"}
                                                                                                    placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                                    value={
                                                                                                         order?.owner_province
                                                                                                              ? order.owner_province
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"จังหวัด"}
                                                                                                    name={"owner_province"}
                                                                                                    placeholder={"กรุณาเลือกจังหวัด"}
                                                                                                    option={this.state.provice?.map((e) => ({
                                                                                                         value: e.province,
                                                                                                         name: e.province,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_province
                                                                                                              ? order.owner_province
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"อำเภอ/เขต"}
                                                                                                    name={"owner_district"}
                                                                                                    placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                                    option={this.state.amphuresOwner?.map((e) => ({
                                                                                                         value: e.district,
                                                                                                         name: e.district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_district
                                                                                                              ? order.owner_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.fristSameOwn ? (
                                                                                     order.owner_sub_district === "-" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"ถนน (อำเภอ/เขต)"}
                                                                                                    name={"owner_district"}
                                                                                                    placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                                    value={
                                                                                                         order?.owner_district
                                                                                                              ? order.owner_district
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"เมือง (จังหวัด)"}
                                                                                                    name={"owner_province"}
                                                                                                    placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                                    value={
                                                                                                         order?.owner_province
                                                                                                              ? order.owner_province
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"จังหวัด"}
                                                                                                    name={"owner_province"}
                                                                                                    placeholder={"กรุณาเลือกจังหวัด"}
                                                                                                    option={this.state.provice?.map((e) => ({
                                                                                                         value: e.province,
                                                                                                         name: e.province,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_province
                                                                                                              ? order.owner_province
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"อำเภอ/เขต"}
                                                                                                    name={"owner_district"}
                                                                                                    placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                                    option={this.state.amphuresOwner?.map((e) => ({
                                                                                                         value: e.district,
                                                                                                         name: e.district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_district
                                                                                                              ? order.owner_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.sameOwner ? (
                                                                                     order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"ถนน (อำเภอ/เขต)"}
                                                                                                    name={"owner_district"}
                                                                                                    placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                                                    value={
                                                                                                         order?.owner_district
                                                                                                              ? order.owner_district
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"เมือง (จังหวัด)"}
                                                                                                    name={"owner_province"}
                                                                                                    placeholder={"กรุณาเลือกเมือง (จังหวัด)"}
                                                                                                    value={
                                                                                                         order?.owner_province
                                                                                                              ? order.owner_province
                                                                                                              : ""
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"จังหวัด"}
                                                                                                    name={"owner_province"}
                                                                                                    placeholder={"กรุณาเลือกจังหวัด"}
                                                                                                    option={this.state.provice?.map((e) => ({
                                                                                                         value: e.province,
                                                                                                         name: e.province,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_province
                                                                                                              ? order.owner_province
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"อำเภอ/เขต"}
                                                                                                    name={"owner_district"}
                                                                                                    placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                                    option={this.state.amphuresOwner?.map((e) => ({
                                                                                                         value: e.district,
                                                                                                         name: e.district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_district
                                                                                                              ? order.owner_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"จังหวัด"}
                                                                                               name={"owner_province"}
                                                                                               placeholder={"กรุณาเลือกจังหวัด"}
                                                                                               option={this.state.provice?.map((e) => ({
                                                                                                    value: e.province,
                                                                                                    name: e.province,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.owner_province
                                                                                                         ? order.owner_province
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"อำเภอ/เขต"}
                                                                                               name={"owner_district"}
                                                                                               placeholder={"กรุณากรอกอำเภอ/เขต"}
                                                                                               option={this.state.amphuresOwner?.map((e) => ({
                                                                                                    value: e.district,
                                                                                                    name: e.district,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.owner_district
                                                                                                         ? order.owner_district
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                           <Form.Row>
                                                                                {previous_path === "add" ? (
                                                                                     this.state.sameOwner && order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"owner_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    value={
                                                                                                         order?.owner_zipcode
                                                                                                              ? order.owner_zipcode
                                                                                                              : ""
                                                                                                    }
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"ตำบล/แขวง"}
                                                                                                    name={"owner_sub_district"}
                                                                                                    placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                                    option={this.state.subdistrictOwner?.map((e) => ({
                                                                                                         value: e.sub_district,
                                                                                                         name: e.sub_district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_sub_district
                                                                                                              ? order.owner_sub_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"owner_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    option={this.state.zipCodeOwner?.map((e) => ({
                                                                                                         value: e,
                                                                                                         name: e,
                                                                                                    }))}
                                                                                                    value={this.state.order?.owner_zipcode}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.fristSameOwn ? (
                                                                                     order.owner_sub_district === "-" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"owner_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    value={
                                                                                                         order?.owner_zipcode
                                                                                                              ? order.owner_zipcode
                                                                                                              : ""
                                                                                                    }
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"ตำบล/แขวง"}
                                                                                                    name={"owner_sub_district"}
                                                                                                    placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                                    option={this.state.subdistrictOwner?.map((e) => ({
                                                                                                         value: e.sub_district,
                                                                                                         name: e.sub_district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_sub_district
                                                                                                              ? order.owner_sub_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"owner_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    option={this.state.zipCodeOwner?.map((e) => ({
                                                                                                         value: e,
                                                                                                         name: e,
                                                                                                    }))}
                                                                                                    value={this.state.order?.owner_zipcode}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : this.state.sameOwner ? (
                                                                                     order.abroad === "นอก" ? (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"text"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"owner_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    value={
                                                                                                         order?.owner_zipcode
                                                                                                              ? order.owner_zipcode
                                                                                                              : ""
                                                                                                    }
                                                                                                    handleChangeText={this.handleChangeText}
                                                                                               />
                                                                                          </>
                                                                                     ) : (
                                                                                          <>
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"ตำบล/แขวง"}
                                                                                                    name={"owner_sub_district"}
                                                                                                    placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                                    option={this.state.subdistrictOwner?.map((e) => ({
                                                                                                         value: e.sub_district,
                                                                                                         name: e.sub_district,
                                                                                                    }))}
                                                                                                    value={
                                                                                                         order?.owner_sub_district
                                                                                                              ? order.owner_sub_district
                                                                                                              : null
                                                                                                    }
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                               <Field
                                                                                                    type={"select"}
                                                                                                    title={"รหัสไปรษณีย์"}
                                                                                                    name={"owner_zipcode"}
                                                                                                    placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                                    option={this.state.zipCodeOwner?.map((e) => ({
                                                                                                         value: e,
                                                                                                         name: e,
                                                                                                    }))}
                                                                                                    value={this.state.order?.owner_zipcode}
                                                                                                    require={true}
                                                                                                    md={3}
                                                                                                    handleChangeText={this.handleChangeAddress}
                                                                                               />
                                                                                          </>
                                                                                     )
                                                                                ) : (
                                                                                     <>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"ตำบล/แขวง"}
                                                                                               name={"owner_sub_district"}
                                                                                               placeholder={"กรุณากรอกตำบล/แขวง"}
                                                                                               option={this.state.subdistrictOwner?.map((e) => ({
                                                                                                    value: e.sub_district,
                                                                                                    name: e.sub_district,
                                                                                               }))}
                                                                                               value={
                                                                                                    order?.owner_sub_district
                                                                                                         ? order.owner_sub_district
                                                                                                         : null
                                                                                               }
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"รหัสไปรษณีย์"}
                                                                                               name={"owner_zipcode"}
                                                                                               placeholder={"กรุณากรอกรหัสไปรษณีย์"}
                                                                                               option={this.state.zipCodeOwner?.map((e) => ({
                                                                                                    value: e,
                                                                                                    name: e,
                                                                                               }))}
                                                                                               value={this.state.order?.owner_zipcode}
                                                                                               require={true}
                                                                                               md={3}
                                                                                               handleChangeText={this.handleChangeAddress}
                                                                                          />
                                                                                     </>
                                                                                )}
                                                                           </Form.Row>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>

                                                       {previous_path === "edit" &&
                                                            getToken().position_id !== 16 &&
                                                            getToken().position_id !== 18 && (
                                                                 <div className="input-field">
                                                                      <div className="-header">
                                                                           <div>หมายเหตุ</div>
                                                                      </div>
                                                                      <div className="-body">
                                                                           <div className="content">
                                                                                <div>
                                                                                     <Form.Check
                                                                                          id="checkbox-assi"
                                                                                          type="checkbox"
                                                                                          checked={this.state.order?.assi}
                                                                                          // value={el.value}
                                                                                          label="Assignment Schedule"
                                                                                          name="assi"
                                                                                          onChange={(e) => {
                                                                                               let { order } = this.state;
                                                                                               order[e.target.name] = e.target.checked ? 1 : 0;
                                                                                               this.setState({ order });
                                                                                          }}
                                                                                     />
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"หมายเหตุ"}
                                                                                               name={"note"}
                                                                                               value={order?.note}
                                                                                               require={false}
                                                                                               as={"textarea"}
                                                                                               textarea_rows={5}
                                                                                               md={12}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </Form.Row>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                       {/* {previous_path === "edit" && (
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>รายละเอียดเงินรับชั่วคราว</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      <div>
                                                                           <Table
                                                                                type={"custom"}
                                                                                add={"text"}
                                                                                column={renameReceiptTable.column}
                                                                                data={renameReceiptTable.data}
                                                                                keyField={renameReceiptTable.keyField}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  )} */}
                                                  </>
                                             ) : null}
                                             {type_edit === "money" || previous_path === "add" ? (
                                                  <>
                                                       {/* Car Series */}
                                                       {!editSeries ? (
                                                            <div className="input-field">
                                                                 <div className="-header">
                                                                      <div>รายละเอียดรถยนต์</div>
                                                                 </div>
                                                                 <div className="-body">
                                                                      <div className="content">
                                                                           <div>
                                                                                <Form.Row>
                                                                                     <Field
                                                                                          type={"select"}
                                                                                          title={"Serie"}
                                                                                          name={"series_id"}
                                                                                          placeholder={"กรุณาเลือก Serie"}
                                                                                          option={this.state.series.map((e) => ({
                                                                                               value: e.series_id,
                                                                                               name: e.series_name,
                                                                                          }))}
                                                                                          value={order?.series_id}
                                                                                          require={true}
                                                                                          md={6}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                     <Field
                                                                                          type={"select"}
                                                                                          title={"Model"}
                                                                                          name={"model_id"}
                                                                                          placeholder={"กรุณาเลือก Model"}
                                                                                          option={this.state.model?.map((e) => ({
                                                                                               value: e.model_id,
                                                                                               name: e.model_name,
                                                                                          }))}
                                                                                          value={order?.model_id}
                                                                                          require={true}
                                                                                          md={6}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                </Form.Row>
                                                                                <Form.Row>
                                                                                     <Field
                                                                                          type={"select"}
                                                                                          title={"สีที่ 1"}
                                                                                          name={"color_id"}
                                                                                          placeholder={"กรุณาเลือกสีที่ 1"}
                                                                                          option={this.state.color.map((e) => ({
                                                                                               value: e.color_id,
                                                                                               name: e.color_name,
                                                                                          }))}
                                                                                          value={order?.color_id}
                                                                                          require={true}
                                                                                          md={6}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                     <Field
                                                                                          type={"text"}
                                                                                          title={"Model Code"}
                                                                                          name={"model_code"}
                                                                                          placeholder={"กรุณากรอก Model Code"}
                                                                                          value={order?.model_code}
                                                                                          require={true}
                                                                                          md={6}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                </Form.Row>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       ) : null}

                                                       {/* Payment */}
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>การชำระเงิน</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      <div className="">
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"วิธีชำระเงิน"}
                                                                                name={"pay_choice"}
                                                                                style_id="primary-field"
                                                                                label={["เงินสด", "ผ่อนชำระ"]}
                                                                                require={true}
                                                                                value={order?.pay_choice}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           {order?.pay_choice === "เงินสด" ? (
                                                                                <>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"จำนวนวันที่ให้เครดิต"}
                                                                                               name={"day_credit"}
                                                                                               placeholder={"วัน"}
                                                                                               typeInput={"text"}
                                                                                               require={false}
                                                                                               unit={"วัน"}
                                                                                               value={order?.day_credit}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "day_credit")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-car-price"}
                                                                                               type={"number"}
                                                                                               title={"ราคารถยนต์"}
                                                                                               name="start_price"
                                                                                               placeholder={"0.00"}
                                                                                               unit={"บาท"}
                                                                                               value={order?.start_price}
                                                                                               decimalScale={2}
                                                                                               disabled={true}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "start_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-car-price-additional"}
                                                                                               type={"number"}
                                                                                               title={"บวกราคาตัวรถเพิ่ม"}
                                                                                               name={"addition_price"}
                                                                                               value={order?.addition_price}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               disabled={order?.start_price ? false : true}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "addition_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-margin"}
                                                                                               type={"number"}
                                                                                               title={"Margin"}
                                                                                               name={"margin"}
                                                                                               value={order?.margin}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "margin")
                                                                                               }
                                                                                               disabled={
                                                                                                    (getToken().position_id < 10 ||
                                                                                                         getToken().position_id > 12) &&
                                                                                                    getToken().position_id !== 25
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-discount"}
                                                                                               type={"number"}
                                                                                               title={"ส่วนลดตัวรถ"}
                                                                                               name={"discount_price"}
                                                                                               value={order?.discount_price}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               // disabled={
                                                                                               //      order?.start_price && !order.sub_down ? false : true
                                                                                               // }
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "discount_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-vat"}
                                                                                               type={"number"}
                                                                                               title={"Vat"}
                                                                                               name={"vat"}
                                                                                               value={order?.vat}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               decimalScale={2}
                                                                                               disabled={true}
                                                                                               unit={"บาท"}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "vat")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row className="input-auto">
                                                                                          <Field
                                                                                               id={"field-real-price"}
                                                                                               type={"number"}
                                                                                               title={"ราคาซื้อขายจริง"}
                                                                                               name={"real_price"}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               value={order?.real_price}
                                                                                               decimalScale={2}
                                                                                               disabled={true}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "real_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-subdown"}
                                                                                               type={"number"}
                                                                                               title={"ซัพดาวน์ (Subdown)"}
                                                                                               name={"sub_down"}
                                                                                               value={order?.sub_down}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               // disabled={order.discount_price ? true : false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "sub_down")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-recommend-price"}
                                                                                               type={"number"}
                                                                                               title={"ค่าแนะนำ"}
                                                                                               name={"recommend_price"}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               // disabled={
                                                                                               //      this.state.type_customer === "นิติบุคคล"
                                                                                               //           ? false
                                                                                               //           : true
                                                                                               // }
                                                                                               unit={"บาท"}
                                                                                               value={order?.recommend_price}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "recommend_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     {getToken().position_id !== 18 && (
                                                                                          <Form.Row>
                                                                                               <Field
                                                                                                    id={"field-other-pay-price"}
                                                                                                    type={"number"}
                                                                                                    title="ค่าอื่น ๆ"
                                                                                                    name={"other_pay_price"}
                                                                                                    placeholder="0.00"
                                                                                                    require={false}
                                                                                                    unit="บาท"
                                                                                                    value={order?.other_pay_price}
                                                                                                    disabled={!order?.other_pay}
                                                                                                    decimalScale={2}
                                                                                                    style_id={"__inline"}
                                                                                                    remark={{
                                                                                                         type: "field",
                                                                                                         value: order?.other_pay,
                                                                                                         name: "other_pay",
                                                                                                         placeholder: "ระบุ",
                                                                                                         require: order?.other_pay,
                                                                                                         handleChangeText: this.handleChangeText,
                                                                                                    }}
                                                                                                    handleChangeNumber={(e) =>
                                                                                                         this.handleChangeNumber(e, "other_pay_price")
                                                                                                    }
                                                                                               />
                                                                                          </Form.Row>
                                                                                     )}

                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               id={"field-note"}
                                                                                               title={"หมายเหตุ"}
                                                                                               name={"other_note"}
                                                                                               placeholder={"กรอกหมายเหตุ"}
                                                                                               require={false}
                                                                                               value={order?.other_note}
                                                                                               style_id={"__inline"}
                                                                                               xl={6}
                                                                                               md={12}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </Form.Row>
                                                                                </>
                                                                           ) : (
                                                                                <>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"บริษัท Leasing"}
                                                                                               name={"leasing_down_id"}
                                                                                               placeholder={"เลือกบริษัท Leasing"}
                                                                                               option={this.state.supplier
                                                                                                    ?.filter((e) => {
                                                                                                         if (e.supplier_type === "Leasing") {
                                                                                                              return e;
                                                                                                         }
                                                                                                    })
                                                                                                    .map((e) => ({
                                                                                                         value: e.supplier_id,
                                                                                                         name: e.supplier_name,
                                                                                                    }))}
                                                                                               value={order?.leasing_down_id}
                                                                                               style_id={"__inline"}
                                                                                               xl={6}
                                                                                               // require={order?.leasing_down_id}
                                                                                               require={true}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"ตัวแทน(AO)"}
                                                                                               name={"leasing_down_item_id"}
                                                                                               placeholder={"เลือกตัวแทน"}
                                                                                               option={
                                                                                                    this.state.agentLeasing
                                                                                                         ? this.state.agentLeasing.map((e) => ({
                                                                                                                value: e.items_id,
                                                                                                                name: e.items_name,
                                                                                                           }))
                                                                                                         : []
                                                                                               }
                                                                                               value={order?.leasing_down_item_id}
                                                                                               style_id={"__inline"}
                                                                                               xl={6}
                                                                                               // require={true}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"select"}
                                                                                               title={"ระยะเวลาผ่อนชำระ"}
                                                                                               name={"leasing_down_leasing_id"}
                                                                                               placeholder={"เลือกระยะเวลาผ่อนชำระ"}
                                                                                               option={
                                                                                                    this.state.typeLeasing
                                                                                                         ? this.state.typeLeasing.map((e) => ({
                                                                                                                value: e.leasinge_id,
                                                                                                                //   name: `${e.amount_year} ปี (${e.leasing_type})`,
                                                                                                                name: `${e.leasing_type} ดอกเบี้ย ${e.leasing_interest}`,
                                                                                                           }))
                                                                                                         : []
                                                                                               }
                                                                                               value={order?.leasing_down_leasing_id}
                                                                                               style_id={"__inline"}
                                                                                               xl={6}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"ราคาเริ่มต้น"}
                                                                                               name={"start_price"}
                                                                                               value={order?.start_price}
                                                                                               placeholder={"0.00"}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               disabled={true}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "start_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"บวกราคาตัวรถเพิ่ม"}
                                                                                               name={"addition_price"}
                                                                                               value={order?.addition_price}
                                                                                               placeholder={"0.00"}
                                                                                               disabled={order?.start_price ? false : true}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "addition_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-margin"}
                                                                                               type={"number"}
                                                                                               title={"Margin"}
                                                                                               name={"margin"}
                                                                                               value={order?.margin}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "margin")
                                                                                               }
                                                                                               disabled={
                                                                                                    (getToken().position_id < 10 ||
                                                                                                         getToken().position_id > 12) &&
                                                                                                    getToken().position_id !== 25
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               id={"field-discount"}
                                                                                               type={"number"}
                                                                                               title={"ส่วนลดตัวรถ"}
                                                                                               name={"discount_price"}
                                                                                               value={order?.discount_price}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               // disabled={
                                                                                               //      order?.start_price && !order.sub_down ? false : true
                                                                                               // }
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "discount_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"Vat"}
                                                                                               name={"vat"}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               disabled={true}
                                                                                               unit={"บาท"}
                                                                                               value={order?.vat}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "vat")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row className="input-auto">
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"ราคาซื้อขายจริง"}
                                                                                               name={"real_price"}
                                                                                               value={order?.real_price}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               disabled={true}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "real_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"เงินดาวน์"}
                                                                                               name={"down"}
                                                                                               value={order?.down}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "down")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"ค่าเงินงวดแรก"}
                                                                                               name={"first_down"}
                                                                                               value={order?.first_down}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "first_down")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"ซัพดาวน์ (Subdown)"}
                                                                                               name={"sub_down"}
                                                                                               value={order?.sub_down}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               // disabled={order.discount_price ? true : false}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "sub_down")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"Subsidy"}
                                                                                               name={"subsidy"}
                                                                                               value={order?.subsidy}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "subsidy")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"ค่าแนะนำ"}
                                                                                               name={"recommend_price"}
                                                                                               value={order?.recommend_price}
                                                                                               placeholder={"0.00"}
                                                                                               require={false}
                                                                                               // disabled={
                                                                                               //      this.state.type_customer === "นิติบุคคล"
                                                                                               //           ? false
                                                                                               //           : true
                                                                                               // }
                                                                                               unit={"บาท"}
                                                                                               decimalScale={2}
                                                                                               style_id={"__inline__space"}
                                                                                               space={{ span: 3, offset: 0 }}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.handleChangeNumber(e, "recommend_price")
                                                                                               }
                                                                                          />
                                                                                     </Form.Row>
                                                                                     {getToken().position_id !== 18 && (
                                                                                          <Form.Row>
                                                                                               <Field
                                                                                                    type={"number"}
                                                                                                    title="ค่าอื่น ๆ"
                                                                                                    name={"other_pay_price"}
                                                                                                    placeholder="0.00"
                                                                                                    require={false}
                                                                                                    unit="บาท"
                                                                                                    value={order?.other_pay_price}
                                                                                                    disabled={!order?.other_pay}
                                                                                                    decimalScale={2}
                                                                                                    style_id={"__inline"}
                                                                                                    remark={{
                                                                                                         type: "field",
                                                                                                         value: order?.other_pay,
                                                                                                         name: "other_pay",
                                                                                                         placeholder: "ระบุ",
                                                                                                         require: order?.other_pay,
                                                                                                         handleChangeText: this.handleChangeText,
                                                                                                    }}
                                                                                                    handleChangeNumber={(e) =>
                                                                                                         this.handleChangeNumber(e, "other_pay_price")
                                                                                                    }
                                                                                               />
                                                                                          </Form.Row>
                                                                                     )}
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"หมายเหตุ"}
                                                                                               name={"other_note"}
                                                                                               placeholder={"กรอกหมายเหตุ"}
                                                                                               require={false}
                                                                                               value={order?.other_note}
                                                                                               style_id={"__inline"}
                                                                                               xl={6}
                                                                                               md={12}
                                                                                               handleChangeText={this.handleChangeText}
                                                                                          />
                                                                                     </Form.Row>
                                                                                </>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/* Insurance */}
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>การประกันภัย</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      <div>
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"ต้องการทำประกันภัย"}
                                                                                name={"insurance"}
                                                                                require={true}
                                                                                style_id="primary-field"
                                                                                label={["Yes", "No"]}
                                                                                value={order?.insurance}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"select"}
                                                                                     title={"บริษัท"}
                                                                                     name={"supplier_insurance_id"}
                                                                                     placeholder={"เลือกบริษัทประกัน"}
                                                                                     option={this.state.supplier
                                                                                          ?.filter((e) => {
                                                                                               if (e.supplier_type === "ประกัน") {
                                                                                                    return e;
                                                                                               }
                                                                                          })
                                                                                          .map((e) => ({
                                                                                               value: e.supplier_id,
                                                                                               name: e.supplier_name,
                                                                                          }))}
                                                                                     value={order?.supplier_insurance_id}
                                                                                     require={order?.insurance === "Yes"}
                                                                                     md={6}
                                                                                     disabled={order?.insurance === "No"}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                                {/* <Field
                                                                                type={"select"}
                                                                                title={"ผู้ติดต่อ"}
                                                                                name={"item_insurance_id"}
                                                                                placeholder={"เลือกผู้ติดต่อ"}
                                                                                option={this.state.itemInsurance.map((e) => ({
                                                                                     value: e.items_id,
                                                                                     name: e.items_name,
                                                                                }))}
                                                                                value={order?.item_insurance_id}
                                                                                require={order?.insurance === "Yes"}
                                                                                md={6}
                                                                                disabled={order?.insurance === "No"}
                                                                                handleChangeText={this.handleChangeText}
                                                                           /> */}
                                                                                <Field
                                                                                     type={"select"}
                                                                                     title={"ประเภทของการประกัน"}
                                                                                     name={"type_insurance_id"}
                                                                                     placeholder={"เลือกบริษัทประกัน"}
                                                                                     option={
                                                                                          this.state.typeInsurance
                                                                                               ? this.state.typeInsurance
                                                                                                      .filter(
                                                                                                           (el, i) =>
                                                                                                                this.state.typeInsurance
                                                                                                                     .map((ele) => ele.insurance_type)
                                                                                                                     .indexOf(el.insurance_type) === i
                                                                                                      )
                                                                                                      .map((e) => ({
                                                                                                           value: e.insurance_id,
                                                                                                           name: e.insurance_type,
                                                                                                           hidden: e.status === "ไม่ได้ใช้งาน",
                                                                                                      }))
                                                                                               : []
                                                                                     }
                                                                                     value={order?.type_insurance_id}
                                                                                     require={order?.insurance === "Yes"}
                                                                                     md={6}
                                                                                     disabled={order?.insurance === "No"}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                     hidden={true}
                                                                                />
                                                                                <Field
                                                                                     type={"number"}
                                                                                     title={"เบี้ยประกัน"}
                                                                                     warning_title={"(โปรดเช็คเบี้ยก่อนกรอก)"}
                                                                                     name={"insurance_premium"}
                                                                                     placeholder={"0.00"}
                                                                                     // value={order.insurance_premium}
                                                                                     value={order.insurance_premium ? order.insurance_premium : 0}
                                                                                     require={order?.insurance === "Yes"}
                                                                                     md={6}
                                                                                     disabled={order?.insurance === "No"}
                                                                                     decimalScale={2}
                                                                                     handleChangeNumber={(e) =>
                                                                                          this.handleChangeNumber(e, "insurance_premium")
                                                                                     }
                                                                                />
                                                                           </Form.Row>
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"ผู้ชำระเงิน"}
                                                                                style_id="primary-field"
                                                                                name={"insurance_pay"}
                                                                                label={["ลูกค้า", "บริษัท"]}
                                                                                require={order?.insurance === "Yes"}
                                                                                value={order?.insurance_pay}
                                                                                disabled={order?.insurance === "No"}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/* ACT */}
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>พ.ร.บ.</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      <div>
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"ต้องการทำ พ.ร.บ."}
                                                                                style_id="primary-field"
                                                                                name={"act"}
                                                                                label={["Yes", "No"]}
                                                                                require={true}
                                                                                value={order?.act}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"select"}
                                                                                     title={"บริษัท"}
                                                                                     name={"act_company"}
                                                                                     placeholder={"เลือกบริษัท"}
                                                                                     option={this.state.supplier
                                                                                          ?.filter((e) => {
                                                                                               if (e.supplier_type === "ประกัน") {
                                                                                                    return e;
                                                                                               }
                                                                                          })
                                                                                          .map((e) => ({
                                                                                               value: e.supplier_name,
                                                                                               name: e.supplier_name,
                                                                                          }))}
                                                                                     value={order?.act_company}
                                                                                     require={order?.act === "Yes"}
                                                                                     md={6}
                                                                                     disabled={order?.act === "No"}
                                                                                     handleChangeText={(e) => {
                                                                                          let order = this.state.order;
                                                                                          order.act_company = e.target.value;
                                                                                          this.setState({ order });
                                                                                     }}
                                                                                />
                                                                                <Field
                                                                                     type={"select"}
                                                                                     title={"ประเภท พ.ร.บ."}
                                                                                     name={"act_name"}
                                                                                     placeholder={"เลือกประเภท พ.ร.บ."}
                                                                                     option={
                                                                                          this.state.act_type
                                                                                               ? this.state.act_type.map((e) => ({
                                                                                                      //   value: e.act_premium,
                                                                                                      //   name: e.act_name,
                                                                                                      value: e.act_name,
                                                                                                      name: e.act_name,
                                                                                                 }))
                                                                                               : []
                                                                                     }
                                                                                     value={order?.act_name}
                                                                                     require={order?.act === "Yes"}
                                                                                     md={6}
                                                                                     disabled={order?.act === "No"}
                                                                                     handleChangeText={(e) => {
                                                                                          let order = this.state.order;
                                                                                          order.act_name = e.target.value;
                                                                                          order.act_premium = this.state.act_type.find(
                                                                                               (el) => el.act_name === e.target.value
                                                                                          ).act_premium;
                                                                                          this.setState({ order });
                                                                                     }}
                                                                                />
                                                                                <Field
                                                                                     type={"number"}
                                                                                     title={"เบี้ย พ.ร.บ."}
                                                                                     name={"act_premium"}
                                                                                     placeholder={"0.00"}
                                                                                     value={order?.act_premium}
                                                                                     require={order?.act === "Yes"}
                                                                                     md={6}
                                                                                     disabled={true}
                                                                                     decimalScale={2}
                                                                                     handleChangeNumber={(e) =>
                                                                                          this.handleChangeNumber(e, "act_premium")
                                                                                     }
                                                                                />
                                                                           </Form.Row>
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"ผู้ชำระเงิน"}
                                                                                style_id="primary-field"
                                                                                name={"act_pay"}
                                                                                label={["ลูกค้า"]}
                                                                                value={order?.act_pay}
                                                                                require={order?.act === "Yes"}
                                                                                disabled={order?.act === "No"}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/* Register */}
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>การจดทะเบียน</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      <div>
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"ต้องการให้จดทะเบียน"}
                                                                                style_id="primary-field"
                                                                                name={"register"}
                                                                                label={["Yes", "No"]}
                                                                                require={true}
                                                                                value={order?.register}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"number"}
                                                                                     title={"ค่าจดทะเบียน"}
                                                                                     name={"register_price"}
                                                                                     placeholder={"0.00"}
                                                                                     value={order?.register_price}
                                                                                     require={order?.register === "Yes"}
                                                                                     // disabled={order?.register === "No"}
                                                                                     disabled={
                                                                                          (getToken().position_id < 10 ||
                                                                                               getToken().position_id > 12) &&
                                                                                          getToken().position_id !== 25
                                                                                     }
                                                                                     unit={"บาท"}
                                                                                     decimalScale={2}
                                                                                     lg={6}
                                                                                     handleChangeNumber={(e) =>
                                                                                          this.handleChangeNumber(e, "register_price")
                                                                                     }
                                                                                />
                                                                           </Form.Row>
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                title={"ผู้ชำระเงิน"}
                                                                                style_id="primary-field"
                                                                                name={"register_pay"}
                                                                                label={["ลูกค้า", "บริษัท"]}
                                                                                value={order?.register_pay}
                                                                                require={order?.register === "Yes"}
                                                                                disabled={order?.register === "No"}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {!editItem ? (
                                                            <>
                                                                 {/* ป้ายแดง */}
                                                                 <div className="input-field">
                                                                      <div className="-header">
                                                                           <div>ป้ายแดง</div>
                                                                      </div>
                                                                      <div className="-body">
                                                                           <div className="content">
                                                                                <div>
                                                                                     <LabelBar
                                                                                          type={"radio"}
                                                                                          title={"ต้องการป้ายแดง"}
                                                                                          style_id="primary-field"
                                                                                          name={"red_label"}
                                                                                          label={["Yes", "No"]}
                                                                                          require={true}
                                                                                          value={order?.red_label}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                     {/* <Form.Row>
                          <Field
                            type={'select'}
                            title={'เลือกเลขทะเบียน'}
                            name={'discount_id'}
                            placeholder={'กรุณาเลือกเลือกเลขทะเบียน'}
                            option={this.state.discount?.map((e) => ({
                              value: e.discount_id,
                              name: e.discount_name,
                            }))}
                            require={order?.red_label === 'Yes'}
                            disabled={order?.red_label === 'No'}
                            lg={6}
                            handleChangeText={this.handleChangeText}
                          />
                        </Form.Row> */}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 {/* Promotion Set */}
                                                                 <div className="input-field">
                                                                      <div className="-header">
                                                                           <div>Promotion Set</div>
                                                                      </div>
                                                                      <div className="-body">
                                                                           <div className="content">
                                                                                <div>
                                                                                     <Table
                                                                                          button_id={"add_promotion_set"}
                                                                                          type={"add"}
                                                                                          add={"text"}
                                                                                          column={promotion.column}
                                                                                          data={promotion.data}
                                                                                          keyField={promotion.keyField}
                                                                                          handleClick={this.onHidePro}
                                                                                     />
                                                                                     {showPromotion ? (
                                                                                          <ModalPanel
                                                                                               type={"select"}
                                                                                               title={"Promotion Set"}
                                                                                               show={showPromotion}
                                                                                               table_data={tableSlectPromotion}
                                                                                               onHide={this.onHidePro}
                                                                                               button={[
                                                                                                    {
                                                                                                         id: "add",
                                                                                                         name: "+ Add",
                                                                                                         variant: "primary",
                                                                                                         onClick: this.onAddPromotion,
                                                                                                    },
                                                                                                    {
                                                                                                         id: "cancel",
                                                                                                         name: "Cancel",
                                                                                                         variant: "danger",
                                                                                                         onClick: this.onHidePro,
                                                                                                    },
                                                                                               ]}
                                                                                          />
                                                                                     ) : null}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 {/* Car Accessories */}
                                                                 <div className="input-field">
                                                                      <div className="-header">
                                                                           <div>Car Accessories (แถม)</div>
                                                                      </div>
                                                                      <div className="-body">
                                                                           <div className="content">
                                                                                <div>
                                                                                     <Form.Row>
                                                                                          <Field
                                                                                               type={"text"}
                                                                                               title={"ชื่อของแถม"}
                                                                                               name={"name_item"}
                                                                                               placeholder={"กรอกชื่อของแถม"}
                                                                                               value={this.state.name_item}
                                                                                               lg={4}
                                                                                               handleChangeText={(e) =>
                                                                                                    this.setState({ [e.target.name]: e.target.value })
                                                                                               }
                                                                                               require={false}
                                                                                          />
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"จำนวน"}
                                                                                               name={"amount_item"}
                                                                                               placeholder={"0.00"}
                                                                                               value={this.state.amount_item}
                                                                                               decimalScale={2}
                                                                                               lg={4}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.setState({
                                                                                                         amount_item: Number(e.floatValue),
                                                                                                    })
                                                                                               }
                                                                                               require={false}
                                                                                          />
                                                                                          <Field
                                                                                               type={"number"}
                                                                                               title={"ราคา"}
                                                                                               name={"price_item"}
                                                                                               placeholder={"0.00"}
                                                                                               value={this.state.price_item}
                                                                                               unit={"button"}
                                                                                               decimalScale={2}
                                                                                               lg={4}
                                                                                               handleChangeNumber={(e) =>
                                                                                                    this.setState({
                                                                                                         price_item: Number(e.floatValue),
                                                                                                    })
                                                                                               }
                                                                                               accAddBonus={this.setAccAddBonus}
                                                                                               require={false}
                                                                                          />
                                                                                     </Form.Row>
                                                                                     <Table
                                                                                          button_id={"add_car_accesories_free"}
                                                                                          type={"add"}
                                                                                          add={"text"}
                                                                                          column={acc_bonus.column}
                                                                                          data={acc_bonus.data}
                                                                                          keyField={acc_bonus.keyField}
                                                                                          handleClick={this.onHideAccBonus}
                                                                                     />
                                                                                     {showAccBonus ? (
                                                                                          <ModalPanel
                                                                                               type={"select_search"}
                                                                                               title={"Car Accessories (แถม)"}
                                                                                               show={showAccBonus}
                                                                                               onChangeText={this.handleChangeTextSearchAcc}
                                                                                               nameText={"accbonus_text"}
                                                                                               table_data={tableSlectAccBonus}
                                                                                               onHide={this.onHideAccBonus}
                                                                                               button={[
                                                                                                    {
                                                                                                         id: "add",
                                                                                                         name: "+ Add",
                                                                                                         variant: "primary",
                                                                                                         onClick: this.onAddAccBonus,
                                                                                                    },
                                                                                                    {
                                                                                                         id: "cancel",
                                                                                                         name: "Cancel",
                                                                                                         variant: "danger",
                                                                                                         onClick: this.onHideAccBonus,
                                                                                                    },
                                                                                               ]}
                                                                                          />
                                                                                     ) : null}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>

                                                                 {/* Car Accessories (ลูกค้าซื้อเอง) */}
                                                                 <div className="input-field">
                                                                      <div className="-header">
                                                                           <div>Car Accessories (ลูกค้าซื้อเอง)</div>
                                                                      </div>
                                                                      <div className="-body">
                                                                           <div className="content">
                                                                                <div>
                                                                                     <Table
                                                                                          button_id={"add_car_accesories_buy"}
                                                                                          type={"add"}
                                                                                          add={"text"}
                                                                                          column={acc_buy?.column}
                                                                                          data={acc_buy?.data}
                                                                                          keyField={acc_buy?.keyField}
                                                                                          handleClick={this.onHideAccBuy}
                                                                                     />
                                                                                     {showAccBuy ? (
                                                                                          <ModalPanel
                                                                                               type={"select_search"}
                                                                                               title={"Car Accessories (ลูกค้าซื้อเอง)"}
                                                                                               show={showAccBuy}
                                                                                               onChangeText={this.handleChangeTextSearchAcc}
                                                                                               table_data={tableSlectAccBuy}
                                                                                               nameText={"accbuy_text"}
                                                                                               onHide={this.onHideAccBuy}
                                                                                               button={[
                                                                                                    {
                                                                                                         id: "add",
                                                                                                         name: "+ Add",
                                                                                                         variant: "primary",
                                                                                                         onClick: this.onAddAccBuy,
                                                                                                    },
                                                                                                    {
                                                                                                         id: "cancel",
                                                                                                         name: "Cancel",
                                                                                                         variant: "danger",
                                                                                                         onClick: this.onHideAccBuy,
                                                                                                    },
                                                                                               ]}
                                                                                          />
                                                                                     ) : null}
                                                                                     <ModalPanel
                                                                                          type={"image"}
                                                                                          title={"Promotion Set"}
                                                                                          show={this.state.openModalImg}
                                                                                          image={ip + this.state.imgPath}
                                                                                          onHide={() => this.setState({ openModalImg: false })}
                                                                                     />
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>

                                                                 <div className="input-field">
                                                                      <div className="-header">
                                                                           <div>Trade-In</div>
                                                                      </div>
                                                                      <div className="-body">
                                                                           <div className="content">
                                                                                <div>
                                                                                     <LabelBar
                                                                                          type={"radio"}
                                                                                          title={"ต้องการเทิร์นสินค้า"}
                                                                                          style_id="primary-field"
                                                                                          name={"turn_acc"}
                                                                                          label={["ไม่ต้องการ", "ต้องการ"]}
                                                                                          require={true}
                                                                                          value={order?.turn_acc}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     />
                                                                                     <LabelBar
                                                                                          type={"checkbox"}
                                                                                          title={"ประเภทสินค้า"}
                                                                                          style_id="primary-field"
                                                                                          name={"type_turn"}
                                                                                          label={[
                                                                                               { value: 1, name: "อุปกรณ์ตกแต่ง" },
                                                                                               { value: 2, name: "รถยนต์" },
                                                                                          ]}
                                                                                          disabled={order?.turn_acc === "ไม่ต้องการ"}
                                                                                          arrChecked={[order.accTurnCheck, order.carTurnCheck]}
                                                                                          handleChangeText={(e) => this.handleChangeCheckbox(e)}
                                                                                     />
                                                                                     {order.accTurnCheck && order.turn_acc === "ต้องการ" ? (
                                                                                          <>
                                                                                               <Form.Row>
                                                                                                    <Field
                                                                                                         type={"select"}
                                                                                                         title={"Supplier"}
                                                                                                         name={"supplier_turn_id"}
                                                                                                         placeholder={"เลือก"}
                                                                                                         option={this.state.supplierSelect || []}
                                                                                                         value={order?.supplier_turn_id}
                                                                                                         lg={6}
                                                                                                         handleChangeText={this.handleChangeText}
                                                                                                    />
                                                                                                    <Field
                                                                                                         type={"select"}
                                                                                                         title={"สินค้า"}
                                                                                                         name={"item_turn_id"}
                                                                                                         placeholder={"เลือก"}
                                                                                                         option={
                                                                                                              this.state.itemAcc?.map((e) => ({
                                                                                                                   value: e.items_id,
                                                                                                                   name: e.items_name,
                                                                                                              })) || []
                                                                                                         }
                                                                                                         value={order?.item_turn_id}
                                                                                                         lg={6}
                                                                                                         handleChangeText={this.handleChangeText}
                                                                                                    />
                                                                                                    <Field
                                                                                                         type={"number"}
                                                                                                         title={"จำนวน"}
                                                                                                         name={"turn_amount"}
                                                                                                         placeholder={"0.00"}
                                                                                                         value={order?.turn_amount}
                                                                                                         decimalScale={2}
                                                                                                         lg={6}
                                                                                                         handleChangeNumber={(e) =>
                                                                                                              this.handleChangeNumber(
                                                                                                                   e,
                                                                                                                   "turn_amount"
                                                                                                              )
                                                                                                         }
                                                                                                    />
                                                                                                    <Field
                                                                                                         type={"number"}
                                                                                                         title={"ราคา"}
                                                                                                         name={"turn_price"}
                                                                                                         placeholder={"0.00"}
                                                                                                         value={order?.turn_price}
                                                                                                         decimalScale={2}
                                                                                                         disabled={true}
                                                                                                         lg={6}
                                                                                                         handleChangeNumber={(e) =>
                                                                                                              this.handleChangeNumber(e, "turn_price")
                                                                                                         }
                                                                                                    />
                                                                                                    {/* <LabelBar
                                                                                          type={"radio"}
                                                                                          title={"ใช้เป็นส่วนลด"}
                                                                                          style_id="primary-field"
                                                                                          name={"turn_discount"}
                                                                                          label={["Yes", "No"]}
                                                                                          require={order?.turn_acc === "ต้องการ"}
                                                                                          value={order?.turn_discount}
                                                                                          handleChangeText={this.handleChangeText}
                                                                                     /> */}
                                                                                               </Form.Row>
                                                                                               <Table
                                                                                                    type={"add"}
                                                                                                    add={"text"}
                                                                                                    column={turn_table?.column}
                                                                                                    data={turn_table?.data}
                                                                                                    disabled={
                                                                                                         !order.supplier_turn_id ||
                                                                                                         !order.item_turn_id ||
                                                                                                         !order.turn_amount ||
                                                                                                         order.turn_acc === "ไม่ต้องการ"
                                                                                                    }
                                                                                                    keyField={turn_table?.keyField}
                                                                                                    handleClick={this.handleAddTurn}
                                                                                               />
                                                                                          </>
                                                                                     ) : null}
                                                                                     {order.carTurnCheck && order.turn_acc === "ต้องการ" ? (
                                                                                          <>
                                                                                               <Form.Row>
                                                                                                    <Field
                                                                                                         type={"text"}
                                                                                                         title={"Brand รถ"}
                                                                                                         name={"brand"}
                                                                                                         placeholder={"กรอก Brand รถ"}
                                                                                                         value={order?.brand}
                                                                                                         lg={6}
                                                                                                         handleChangeText={this.handleChangeText}
                                                                                                    />
                                                                                                    <Field
                                                                                                         type={"text"}
                                                                                                         title={"รุ่นรถตาม Brand"}
                                                                                                         name={"series_brand"}
                                                                                                         placeholder={"กรอกรุ่นรถตาม Brand"}
                                                                                                         value={order?.series_brand}
                                                                                                         lg={6}
                                                                                                         handleChangeText={this.handleChangeText}
                                                                                                    />
                                                                                                    <Field
                                                                                                         type={"text"}
                                                                                                         title={"ปีรถ"}
                                                                                                         name={"year"}
                                                                                                         placeholder={"กรอกปีรถ"}
                                                                                                         value={order?.year}
                                                                                                         lg={6}
                                                                                                         handleChangeText={this.handleChangeText}
                                                                                                    />
                                                                                                    <Field
                                                                                                         type={"number"}
                                                                                                         title={"ราคาประเมิน"}
                                                                                                         name={"car_turn_price"}
                                                                                                         placeholder={"0.00"}
                                                                                                         value={order?.car_turn_price}
                                                                                                         decimalScale={2}
                                                                                                         lg={6}
                                                                                                         handleChangeNumber={(e) =>
                                                                                                              this.handleChangeNumber(
                                                                                                                   e,
                                                                                                                   "car_turn_price"
                                                                                                              )
                                                                                                         }
                                                                                                    />
                                                                                               </Form.Row>
                                                                                          </>
                                                                                     ) : null}

                                                                                     {/* {show ? (
                            <ModalPanel
                              type={'select'}
                              title={'Promotion Set'}
                              show={show}
                              table_data={tableSlectPromotion}
                              onHide={this.onHide}
                              button={[
                                {
                                  name: '+ Add',
                                  variant: 'primary',
                                  onClick: this.onHide,
                                },
                                {
                                  name: 'Cancel',
                                  variant: 'danger',
                                  onClick: this.onHide,
                                },
                              ]}
                            />
                          ) : null} */}
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </div>

                                                                 {/* customer turn */}
                                                                 <Modal
                                                                      show={this.state.modalShow}
                                                                      onHide={() => this.setState({ modalShow: false })}
                                                                      size="lg"
                                                                      aria-labelledby="contained-modal-title-vcenter"
                                                                      centered
                                                                 >
                                                                      <Modal.Header closeButton>
                                                                           <Modal.Title id="contained-modal-title-vcenter">รูปภาพประกอบ</Modal.Title>
                                                                      </Modal.Header>
                                                                      <Modal.Body style={{ textAlign: "center" }}>
                                                                           <Image
                                                                                width={450}
                                                                                height={450}
                                                                                src={ip + this.state.acc_image + `?time=${new Date()}`}
                                                                           />
                                                                      </Modal.Body>
                                                                 </Modal>
                                                            </>
                                                       ) : null}
                                                       {/* customer_pay */}
                                                       {/* <div className="input-field">
                                                       <div className="-header">
                                                            <div>ส่วนต่างที่ลูกค้าจ่ายเอง</div>
                                                       </div>
                                                       <div className="-body">
                                                            <div className="content">
                                                                 <div>
                                                                      <Form.Row>
                                                                           <Field
                                                                                type={'number'}
                                                                                title={'ส่วนต่างที่ลูกค้าจ่ายเอง'}
                                                                                name={'customer_pay'}
                                                                                placeholder={'0.00'}
                                                                                value={order?.customer_pay}
                                                                                require={false}
                                                                                unit={'บาท'}
                                                                                decimalScale={2}
                                                                                lg={4}
                                                                                handleChangeNumber={(e) => this.handleChangeNumber(e, 'customer_pay')}
                                                                           />
                                                                      </Form.Row>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div> */}
                                                       {/* order price */}
                                                       {!editItem ? (
                                                            <div className="input-field">
                                                                 <div className="-header">
                                                                      <div>เงินจอง</div>
                                                                 </div>
                                                                 <div className="-body">
                                                                      <div className="content">
                                                                           <div>
                                                                                <Form.Row>
                                                                                     <Field
                                                                                          type={"number"}
                                                                                          title={"เงินจอง"}
                                                                                          name={"order_price"}
                                                                                          value={order?.order_price}
                                                                                          placeholder={"0.00"}
                                                                                          require={true}
                                                                                          unit={"บาท"}
                                                                                          decimalScale={2}
                                                                                          disabled={order.orp_code ? true : false}
                                                                                          lg={4}
                                                                                          handleChangeNumber={(e) =>
                                                                                               this.handleChangeNumber(e, "order_price")
                                                                                          }
                                                                                     />
                                                                                </Form.Row>
                                                                                <Form.Row>
                                                                                     <Field
                                                                                          type={"number"}
                                                                                          title={"Vat เงินจอง"}
                                                                                          name={"order_vat"}
                                                                                          value={order?.order_vat}
                                                                                          placeholder={"0.00"}
                                                                                          require={true}
                                                                                          disabled={true}
                                                                                          unit={"บาท"}
                                                                                          decimalScale={2}
                                                                                          lg={4}
                                                                                          handleChangeNumber={(e) =>
                                                                                               this.handleChangeNumber(e, "order_vat")
                                                                                          }
                                                                                     />
                                                                                </Form.Row>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       ) : null}

                                                       {/* รายละเอียดเงินรับชั่วคราว */}
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>รายละเอียดเงินรับชั่วคราว</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content" id="table-content">
                                                                      <div className="-padding" style={{ minWidth: 650 }}>
                                                                           {this.state.previous_path && this.state.previous_path === "add" ? (
                                                                                <>
                                                                                     {this.TableReciept()}
                                                                                     {/* <Table
                                                                                     type={"custom"}
                                                                                     column={receipt?.column}
                                                                                     data={receipt?.data}
                                                                                     keyField={receipt?.keyField}
                                                                                /> */}
                                                                                </>
                                                                           ) : (
                                                                                <>
                                                                                     {selectReceipt.data.map((el, i) => (
                                                                                          <Row key={i + 1}>
                                                                                               <Col xs={3}>{el.receipt_method}</Col>
                                                                                               <Col xs={3}>{el.receipt_code || "-"}</Col>
                                                                                               {getToken().position_id === 11 ||
                                                                                               getToken().position_id === 25 ? (
                                                                                                    <Col xs={3}>
                                                                                                         <Form.Row>
                                                                                                              <Field
                                                                                                                   type={"number"}
                                                                                                                   value={el.receipt_price}
                                                                                                                   placeholder={"0.00"}
                                                                                                                   decimalScale={2}
                                                                                                                   lg={12}
                                                                                                                   disabled={
                                                                                                                        el.receipt_method ===
                                                                                                                        "ใบรับเงินส่วนต่างมาจิ้น"
                                                                                                                             ? true
                                                                                                                             : !el.receipt_id
                                                                                                                   }
                                                                                                                   handleChangeNumber={(ele) =>
                                                                                                                        this.onChangeReceipt(
                                                                                                                             ele.floatValue,
                                                                                                                             el.receipt_id
                                                                                                                        )
                                                                                                                   }
                                                                                                              />
                                                                                                         </Form.Row>
                                                                                                    </Col>
                                                                                               ) : (
                                                                                                    <Col xs={3}>
                                                                                                         <Form.Row>
                                                                                                              <Field
                                                                                                                   type={"number"}
                                                                                                                   value={el.receipt_price}
                                                                                                                   placeholder={"0.00"}
                                                                                                                   decimalScale={2}
                                                                                                                   lg={12}
                                                                                                                   disabled={
                                                                                                                        el.receipt_method ===
                                                                                                                        "ใบรับเงินส่วนต่างมาจิ้น"
                                                                                                                             ? true
                                                                                                                             : !el.receipt_id ||
                                                                                                                               order.acept_status ===
                                                                                                                                    "ชำระเงินแล้ว" ||
                                                                                                                               order.acept_status ===
                                                                                                                                    "ส่งมอบรถแล้ว" ||
                                                                                                                               order.acept_status ===
                                                                                                                                    "ปิดการขาย"
                                                                                                                   }
                                                                                                                   handleChangeNumber={(ele) =>
                                                                                                                        this.onChangeReceipt(
                                                                                                                             ele.floatValue,
                                                                                                                             el.receipt_id
                                                                                                                        )
                                                                                                                   }
                                                                                                              />
                                                                                                         </Form.Row>
                                                                                                    </Col>
                                                                                               )}
                                                                                               {getToken().position_id === 11 ||
                                                                                               getToken().position_id === 25 ? (
                                                                                                    <Col xs={3}>
                                                                                                         <Button
                                                                                                              onClick={() =>
                                                                                                                   this.onRemoveReceipt(el, i)
                                                                                                              }
                                                                                                              variant="danger"
                                                                                                         >
                                                                                                              Remove
                                                                                                         </Button>
                                                                                                    </Col>
                                                                                               ) : (
                                                                                                    <Col xs={3}>
                                                                                                         <Button
                                                                                                              onClick={() =>
                                                                                                                   this.onRemoveReceipt(el, i)
                                                                                                              }
                                                                                                              variant="danger"
                                                                                                              disabled={
                                                                                                                   order.acept_status ===
                                                                                                                        "ชำระเงินแล้ว" ||
                                                                                                                   order.acept_status ===
                                                                                                                        "ส่งมอบรถแล้ว" ||
                                                                                                                   order.acept_status === "ปิดการขาย"
                                                                                                              }
                                                                                                         >
                                                                                                              Remove
                                                                                                         </Button>
                                                                                                    </Col>
                                                                                               )}
                                                                                          </Row>
                                                                                     ))}
                                                                                     <div className="-btn mt-3 text-center">
                                                                                          <Button
                                                                                               className="w-25"
                                                                                               variant="success"
                                                                                               onClick={this.onHideReciept}
                                                                                          >
                                                                                               + เพิ่ม
                                                                                          </Button>
                                                                                     </div>
                                                                                </>
                                                                           )}
                                                                           {/* <Table
                                                                                type={"add"}
                                                                                add={"text"}
                                                                                column={selectReceipt.column}
                                                                                data={selectReceipt.data}
                                                                                keyField={selectReceipt.keyField}
                                                                                handleClick={this.onHideReciept}
                                                                           /> */}
                                                                           {this.state.showReciept ? (
                                                                                <ModalPanel
                                                                                     type={"select"}
                                                                                     title={"รายละเอียดเงินรับชั่วคราว"}
                                                                                     show={this.state.showReciept}
                                                                                     table_data={receiptModal}
                                                                                     onHide={this.onHideReciept}
                                                                                     button={[
                                                                                          {
                                                                                               name: "+ Add",
                                                                                               variant: "primary",
                                                                                               onClick: this.onAddReceipt,
                                                                                          },
                                                                                          {
                                                                                               name: "Cancel",
                                                                                               variant: "danger",
                                                                                               onClick: this.onHideReciept,
                                                                                          },
                                                                                     ]}
                                                                                />
                                                                           ) : null}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/* note */}
                                                       {/* {previous_path === "add" && (
                                                       <div className="input-field">
                                                            <div className="-header">
                                                                 <div>หมายเหตุ</div>
                                                            </div>
                                                            <div className="-body">
                                                                 <div className="content">
                                                                      <div>
                                                                           <Form.Row>
                                                                                <Field
                                                                                     type={"text"}
                                                                                     title={"หมายเหตุ"}
                                                                                     name={"note"}
                                                                                     value={order?.note}
                                                                                     require={false}
                                                                                     as={"textarea"}
                                                                                     textarea_rows={5}
                                                                                     md={12}
                                                                                     handleChangeText={this.handleChangeText}
                                                                                />
                                                                           </Form.Row>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  )} */}
                                                  </>
                                             ) : null}
                                             {/* Calculate Button  */}
                                             <div className="button-group __onPanel row">
                                                  {type_edit === "money" || previous_path === "add" ? (
                                                       <>
                                                            <Col xl={3} md={4}>
                                                                 <Button id={"calc_final"} onClick={this.CalculateFinalPriceButton}>
                                                                      คำนวณราคาขายทั้งหมด
                                                                 </Button>
                                                            </Col>
                                                            <Col xl={3} md={4}>
                                                                 <Button id={"calc_margin"} onClick={() => this.calculateMarginButton(totalMargin)}>
                                                                      คำนวณ Margin ทั้งหมด
                                                                 </Button>
                                                            </Col>
                                                       </>
                                                  ) : null}
                                                  {previous_path === "add" && (
                                                       <Col xl={3} md={4}>
                                                            <Button
                                                                 id={"btn_proceed"}
                                                                 variant="outline-primary"
                                                                 onClick={() => this.handleSave(totalMargin)}
                                                            >
                                                                 save
                                                            </Button>
                                                       </Col>
                                                  )}
                                             </div>
                                             {/* Submit group */}
                                             <div className="button-group __submit row">
                                                  <Col xs={12}>
                                                       <Button
                                                            onClick={() => this.setState({ totalMargin })}
                                                            id={"btn_proceed"}
                                                            type="submit"
                                                            disabled={this.state.loadingData}
                                                       >
                                                            ดำเนินการต่อ
                                                       </Button>
                                                  </Col>
                                                  {/* <Col xs={6}>
                                                       <Button id={"btn_reset"} variant="danger" onClick={this.handleReset}>
                                                            Reset
                                                       </Button>
                                                  </Col> */}
                                             </div>
                                        </div>
                                   )}
                              </Form>
                         </div>
                    );
          }
     }
}
