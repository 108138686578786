import { GET, POST } from "api";
import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

export default class Payment extends Component {
     constructor(props) {
          super(props);

          this.state = {
               title: "",
               show: false,
               leasing: { branch: [] },
               error_data: "",
          };
     }

     componentDidMount = async () => {
          // /ps/slect_suppliers
          try {
               let getBranch = await GET("/ps/get_branchs");
               let getSupplier = await GET("/ps/slect_suppliers");
               let leasing = getSupplier.filter((el) => el.supplier_type === "Leasing");
               let data = leasing.map((el) => ({ value: el.supplier_id, name: el.supplier_name }));
               //  data.unshift({ value: "all", name: "พนักงานขายทุกคน" });
               this.setState({ branch: getBranch, getLeasing: data, leasing: { branch: getBranch.map((el) => el.branch_id) } });
          } catch (error) {
               console.log("error", error);
          }
     };

     /*------------ onClick ------------------ */

     showModal = (title, id) => {
          let { leasing } = this.state;
          leasing.id = id;
          this.setState({
               title: title,
               show: !this.state.show,
               leasing,
          });
     };

     closeReport = async () => {
          let { leasing, branch } = this.state;
          try {
               if (leasing.date_start && leasing.date_end && leasing.branch.length > 0) {
                    let type_excel = "";

                    switch (leasing.id) {
                         case 1:
                              type_excel = "รายงานรายจ่ายเงิน";
                              break;

                         default:
                              break;
                    }
                    // if (leasing.branch === "สำนักงานใหญ่ ธัญบุรี คลอง 2") {
                    //      leasing.branch_id = 1;
                    // } else if (leasing.branch === "ลำลูกกา") {
                    //      leasing.branch_id = 2;
                    // }
                    let dataBranch = leasing.branch.map((el) => ({
                         branch_name: branch.find((e) => e.branch_id == el).branch_name,
                         branch_id: branch.find((e) => e.branch_id == el).branch_id,
                    }));
                    let excel = await POST("/ps/excel_payment_report", { ...leasing, branch: dataBranch, type_excel });
                    window.open(excel);
                    this.setState({ show: !this.state.show, error_data: "" });
               } else {
                    this.setState({ error_data: "*กรอกข้อมูลให้ครบ*" });
               }
          } catch (error) {
               // console.log("error", error);
               this.setState({ error_data: error });
          }
     };

     /* ---------- formatter ----------------- */

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <a onClick={() => this.showModal(row.report, row.id)}>ทำรายการ</a>;
     };

     // reportFormatter = (cell, row, rowIndex, formatExtraData) => {
     //      if (row.report === "รายงานใบเสร็จรับเงิน") {
     //           return (
     //                <div>
     //                     <span>{cell}</span>
     //                     <span style={{ color: "red", marginLeft: "40px" }}>ไม่มีรูปแบบรายงาน</span>
     //                </div>
     //           );
     //      } else {
     //           return <span>{cell}</span>;
     //      }
     // };

     handleChangeText = ({ target: { name, value, checked } }) => {
          let { leasing } = this.state;
          leasing[name] = value;
          this.setState({ leasing });
     };

     handleChangeDate = (date, dateString, name) => {
          let { leasing } = this.state;
          leasing[name] = dateString;
          this.setState({ leasing });
     };

     handleCloseModal = () => {
          let { leasing } = this.state;
          this.setState({
               show: false,
               // leasing: { ...leasing, branch: [] },
               error_data: "",
          });
     };
     onChangeCheckbox = ({ target: { name, value, checked } }) => {
          let { leasing } = this.state;
          if (checked) {
               leasing[name].push(Number(value));
               leasing[name] = leasing[name].sort((a, z) => a - z);
               this.setState({ leasing });
          } else {
               let index = leasing[name].findIndex((el) => el === Number(value));
               leasing[name].splice(index, 1);
               leasing[name] = leasing[name].sort((a, z) => a - z);
               this.setState({ leasing });
          }
     };
     render() {
          let { title, show, leasing, error_data } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         formatter: this.reportFormatter,
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "list",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [{ report: "รายงานรายจ่ายเงิน", id: 1 }],
               keyField: "on",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานรายจ่ายเงิน" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                                   <Modal show={show} centered size="lg" onHide={this.handleCloseModal}>
                                        <Modal.Header>
                                             <Modal.Title className="text-center">
                                                  <strong>{title}</strong>
                                                  {error_data && (
                                                       <p className="text-center">
                                                            <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                                       </p>
                                                  )}
                                             </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                             <Row>
                                                  <Field
                                                       type={"date"}
                                                       placeholder={leasing.date_start ? leasing.date_start : "เลือกวันที่"}
                                                       title={"ประจำวันที่"}
                                                       name={"date_start"}
                                                       md={6}
                                                       // value={leasing?.date_start}
                                                       handleSelectDate={this.handleChangeDate}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       placeholder={leasing.date_end ? leasing.date_end : "เลือกวันที่"}
                                                       title={"ถึงวันที่"}
                                                       name={"date_end"}
                                                       // value={leasing?.date_end}
                                                       md={6}
                                                       handleSelectDate={this.handleChangeDate}
                                                  />
                                             </Row>
                                             {/* <Field
                                                  type="select"
                                                  title={"Leasing"}
                                                  option={this.state.getLeasing}
                                                  name={"leasing"}
                                                  value={leasing?.leasing}
                                                  handleChangeText={this.handleChangeText}
                                             /> */}
                                             <LabelBar
                                                  type={"checkbox"}
                                                  title={"สาขา"}
                                                  name={"branch"}
                                                  md={12}
                                                  label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                  // value={pay?.branch}
                                                  arrChecked={leasing?.branch}
                                                  handleChangeText={this.onChangeCheckbox}
                                             />
                                             {/* <LabelBar
                                                  type={"radio"}
                                                  title={"สาขา"}
                                                  name={"branch"}
                                                  md={12}
                                                  label={this.state.branch?.map((el) => el.branch_name)}
                                                  value={leasing?.branch}
                                                  // arrChecked={permission_yes}
                                                  handleChangeText={this.handleChangeText}
                                             /> */}
                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       <Button onClick={this.closeReport}>แสดงรายงาน</Button>
                                                  </Col>
                                             </Row>
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
