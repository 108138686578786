import React, { Component } from "react";
import { Breadcrumbs, Table, Search } from "components/index";
import { GET, POST } from "../../../../api/api";

export default class supplier_setting extends Component {
     constructor(props) {
          super(props);
          this.state = {
               suppliers: [],
               suppliers_backup: [],
               search: [],
          };
     }
     componentDidMount = () => {
          this.getLoglist();
     };
     // getSupplier = async () => {
     //   try {
     //     let suppliers = await GET('/ps/get_suppliers');
     //     this.setState({ suppliers });
     //   } catch (error) {
     //     console.log(error);
     //   }
     // };

     getLoglist = async () => {
          try {
               let suppliers = [...(await POST("/ps/confirm_list", { log_type: "supplier" }))];
               suppliers.sort((end, start) => parseFloat(start.log_id) - parseFloat(end.log_id)).sort((end, start) => parseFloat(start.log_id) - parseFloat(end.log_id));
               this.setState({
                    suppliers,
                    suppliers_backup: suppliers,
               });
          } catch (error) {
               console.log(error);
          }
     };

     linearSearch = (value) => {
          const supplier_type = [
               { value: "TMT", name: "TMT" },
               { value: "Dealer ซื้อรถ", name: "Dealer ซื้อรถ" },
               { value: "อุปกรณ์ตกแต่ง(TOYOTA)", name: "อุปกรณ์ตกแต่ง(TOYOTA)" },
               { value: "อุปกรณ์ตกแต่ง(Out Source Supplier)", name: "อุปกรณ์ตกแต่ง(Out Source Supplier)" },
               { value: "ประกัน", name: "ประกัน" },
               { value: "Leasing", name: "Leasing" },
               { value: "ตัวแทนจดทะเบียน", name: "ตัวแทนจดทะเบียน" },
          ];
          for (let i = 0; i < supplier_type.length; i++) {
               if (supplier_type[i].value === value) {
                    return true;
               }
          }
          return false;
     };

     onSearch = () => {
          let { suppliers_backup, search } = this.state;
          let suppliers = suppliers_backup.filter(
               (e) =>
                    (search.supplier_code ? String(e.supplier_code).includes(String(search.supplier_code)) : e) &&
                    (search.supplier_name ? String(e.supplier_name).toLowerCase().includes(String(search.supplier_name).toLowerCase()) : e) &&
                    (search.supplier_type === "อื่น ๆ"
                         ? !this.linearSearch(e.supplier_type)
                              ? e
                              : null
                         : search.supplier_type
                         ? String(e.supplier_type).toLowerCase() === String(search.supplier_type).toLowerCase() ||
                           String(search.supplier_type).toLowerCase() === String("ทั้งหมด")
                         : e) &&
                    (search.manage_type
                         ? String(e.manage_type).toLowerCase() === String(search.manage_type).toLowerCase() ||
                           String(search.manage_type).toLowerCase() === String("ทั้งหมด")
                         : e)
          );
          this.setState({ suppliers });
     };
     handleChangeText = (e) => {
          // handle by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     handleChangeOption = (e) => {
          // handle option by name
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/supplier/setting/approve/${row.log_id}`)}>ทำรายการ</a>;
     }
     render() {
          const column = [
               {
                    dataField: "detail",
                    text: "ทำรายการ",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "supplier_code",
                    text: "Supplier Code",
                    // headerClasses: "header-custom __left",
                    formatter: (e) => (e ? (e === "null" ? "-" : e) : "-"),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "supplier_name",
                    text: "Supplier Name",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "supplier_type",
                    text: "Supplier Type",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "log_do",
                    text: "ประเภทรายการ",
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "log_status",
                    text: "การอนุมัติ",
                    headerClasses: "header-custom __right __btn-ss",
               },
          ];
          let { search } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs
                         // title={["การอนุมัติ เพิ่ม/ลบ/แก้ไข Supplier"]}
                         title={[{ title: "การอนุมัติ เพิ่ม/ลบ/แก้ไข Supplier" }]}
                         active={0}
                         button={[]}
                         onClick={() => this.props.history.goBack()}
                    />
                    <div className="content __main-panel listsupplier">
                         <Search
                              type={"supplier-setting"}
                              searchOptions={search}
                              onSearch={this.onSearch}
                              handleChangeText={this.handleChangeText}
                              handleChange={this.handleChangeOption}
                         />
                         <Table
                              type={"index-fixhead"}
                              column={column}
                              data={this.state.suppliers.map((el) => ({ ...el, supplier_code: el.supplier_code ? el.supplier_code : "-" }))}
                              keyField={"username"}
                         />
                    </div>
               </div>
          );
     }
}
