import { GET, POST } from "api";
import { Breadcrumbs, LabelBar, Field, ModalPanel } from "components/index";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Row, Button, Col } from "react-bootstrap";
import swal from "sweetalert";

export default class AddEditListLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               type: this.props.match.params.type,
               label_id: this.props.match.params.label_id,
               // empty_status: this.props.match.params.empty_status,
               label_no: "",
               show_add: false,
               order: {
                    status: "ใช้งาน",
               },
               provice: [],
               branch: [],
          };
     }
     componentDidMount = () => {
          let { type, order } = this.state;
          if (type === "edit") {
               this.getDetailLabel();
          } else {
               order.label_buy_date = dayjs(new Date());
               this.setState({ order });
          }
          this.getProvice();
          this.getBranch();
     };

     /* --------------------------------- Get ------------------------------------------- */

     getDetailLabel = async () => {
          let { label_id } = this.state;
          try {
               let order = await POST("/car/this_label", { label_id });
               delete order.labelhis;
               delete order.branch_name;
               delete order.created_at;
               delete order.free;
               if (order.label_buy_date) order.label_buy_date = dayjs(order.label_buy_date);

               this.setState({ order: { ...order }, label_no: order.label_no });
               // console.log("order", this.state.order);
          } catch (err) {
               console.log(err);
          }
     };

     getProvice = async () => {
          let province_option = [];
          try {
               let getprovice = await GET("/address/getprovince");
               let arr = [...getprovice];
               for (let i = 0; i < arr.length; i++) {
                    province_option.push({ name: arr[i].name_th, value: arr[i].name_th, id: arr[i].id });
               }
               this.setState({
                    provice: province_option,
               });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getBranch = async () => {
          let branch_option = [];
          try {
               let getBranch = await GET("/ps/get_branchs");

               for (let i = 0; i < getBranch.length; i++) {
                    branch_option.push({ name: getBranch[i].branch_name, value: getBranch[i].branch_id });
               }
               this.setState({
                    branch: branch_option,
               });
          } catch (error) {
               // console.log(error);
          }
     };

     addLabel = async () => {
          let { order } = this.state;
          order.label_buy_date = dayjs(order.label_buy_date).format("YYYY-MM-DD");
          try {
               let result = await POST("/car/add_label", order);
               // console.log(result);
               if (result.label_id) {
                    swal({
                         text: "เพิมป้ายแดงเรียบร้อยแล้ว",
                         icon: "success",
                         buttons: { submit: "เสร็จสิ้น" },
                    }).then((value) => {
                         window.location.assign(`/admin/label/list/${result.label_id}`);
                    });

                    this.setState({ label_id: result.label_id });
               }
          } catch (err) {
               console.log(err);
          }
     };

     editLabel = async () => {
          let { order } = this.state;
          order.delete = 0;
          order.label_buy_date = dayjs(order.label_buy_date).format("YYYY-MM-DD");
          // console.log("edit", order);

          try {
               let result = await POST("/car/edit_label", order);
               // console.log(result);
               swal({
                    icon: "success",
                    text: "แก้ไขป้ายแดงแล้ว",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               });
               //  .then(() => {
               //       window.location.reload();
               //  });
          } catch (err) {
               console.log(err);
          }
     };

     deleteLabel = async () => {
          let { order } = this.state;
          order.delete = 1;
          // console.log("delete", order);
          try {
               let result = await POST("/car/edit_label", order);
               // console.log(result);
               // alert("ลบป้ายแดงแล้ว");
               swal({
                    icon: "success",
                    text: "ลบป้ายแดงแล้ว",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               });
          } catch (err) {
               console.log(err);
          }
     };

     /* ----------------- onChange ----------------------- */
     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
          // console.log(this.state.order);
     };
     handleChangeSelect = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ order });
          // console.log(this.state.order);
     };
     handleChangeDate = (date, dateString, name) => {
          let { order } = this.state;
          order[name] = date;
          this.setState({ order });
          // console.log(this.state.order);
     };
     handleChangeRadio = (e) => {
          let { order } = this.state;
          if (e.target.checked) {
               order[e.target.name] = e.target.value;
          }
          this.setState({ order });
          // console.log(this.state.order);
     };
     render() {
          let { type, registra_id, empty_status, label_id, order, provice, district, branch } = this.state;
          // console.log("order", order);
          switch (type) {
               case "add":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รายการป้ายแดง`,
                                             onClick: () => window.location.assign("/admin/label/list"),
                                        },
                                        { title: `เพิ่มป้ายแดง` },
                                   ]}
                                   active={1}
                                   button={[]}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ข้อมูลป้ายแดง"} />
                                                  <>
                                                       <Row className="noMargin">
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขทะเบียน"}
                                                                      name="label_no"
                                                                      placeholder={"กรอกเลขทะเบียน"}
                                                                      require={false}
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                 />
                                                            </Col>
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"จังหวัด"}
                                                                      name={"label_province"}
                                                                      value={order?.label_province}
                                                                      // name={"labelhis_provinces"}
                                                                      placeholder={"เลือก"}
                                                                      option={provice}
                                                                      handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 />
                                                            </Col>
                                                       </Row>

                                                       <Row className="noMargin">
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขที่ใบอนุญาต"}
                                                                      name={"label_permit"}
                                                                      placeholder={"กรอกเลขที่ใบอนุญาต"}
                                                                      require={false}
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                 />
                                                            </Col>
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขที่เล่ม"}
                                                                      name="label_book"
                                                                      placeholder={"กรอกเลขที่เล่ม"}
                                                                      require={false}
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                 />
                                                            </Col>
                                                       </Row>

                                                       <Row className="noMargin">
                                                            <Col xl={3}>
                                                                 <Field
                                                                      type={"date"}
                                                                      title={"วันที่ซื้อป้ายแดง"}
                                                                      name={"label_buy_date"}
                                                                      placeholder={order?.label_buy_date ? order?.label_buy_date : "เลือกวันที่"}
                                                                      require={false}
                                                                      formatDate={"DD/MM/YYYY"}
                                                                      value={order?.label_buy_date}
                                                                      handleSelectDate={this.handleChangeDate}
                                                                 />
                                                            </Col>
                                                            <Col xl={3}>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"สาขา"}
                                                                      name="branch_id"
                                                                      value={order?.branch_id}
                                                                      placeholder={"เลือกสาขา"}
                                                                      option={branch}
                                                                      handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 />
                                                            </Col>
                                                            <LabelBar
                                                                 handleChangeText={(e) => this.handleChangeRadio(e)}
                                                                 name="status"
                                                                 type={"radio"}
                                                                 title={"สถานะ"}
                                                                 value={order?.status}
                                                                 style_id="__inline"
                                                                 xl={6}
                                                                 label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                            />
                                                       </Row>
                                                       {/* Submit group */}
                                                       <div className="button-group __submit row">
                                                            <Col xs={6}>
                                                                 <Button onClick={this.addLabel}>เพิ่มป้ายแดง</Button>
                                                            </Col>
                                                       </div>
                                                  </>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );
               case "edit":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รายการป้ายแดง`,
                                             onClick: () => window.location.assign("/admin/label/list"),
                                        },
                                        {
                                             title: `ป้ายแดง (${this.state.label_no})`,
                                             onClick: () => window.location.assign(`/admin/label/list/${label_id}`),
                                        },
                                        { title: `แก้ไข` },
                                   ]}
                                   active={2}
                                   button={[]}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content __padding">
                                                  <LabelBar type={"title"} title={"ข้อมูลป้ายแดง"} />
                                                  <>
                                                       <Row className="noMargin">
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เลขทะเบียน"}
                                                                      name="label_no"
                                                                      value={order?.label_no}
                                                                      placeholder={"กรอกเลขทะเบียน"}
                                                                      require={false}
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                 />
                                                            </Col>
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"จังหวัด"}
                                                                      name="label_province"
                                                                      value={order?.label_province}
                                                                      placeholder={"เลือก"}
                                                                      option={provice}
                                                                      handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 />
                                                            </Col>
                                                       </Row>
                                                       <Row className="noMargin">
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      name={"label_permit"}
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                      type={"text"}
                                                                      title={"เลขที่ใบอนุญาต"}
                                                                      value={order?.label_permit}
                                                                      placeholder={"กรอกเลขที่ใบอนุญาต"}
                                                                      require={false}
                                                                 />
                                                            </Col>
                                                            <Col xl={6} md={6}>
                                                                 <Field
                                                                      name={"label_book"}
                                                                      handleChangeText={(e) => this.handleChangeText(e)}
                                                                      type={"text"}
                                                                      title={"เลขที่เล่ม"}
                                                                      value={order?.label_book}
                                                                      placeholder={"กรอกเลขที่เล่ม"}
                                                                      require={false}
                                                                 />
                                                            </Col>
                                                       </Row>
                                                       <Row className="noMargin">
                                                            <Col xl={3}>
                                                                 <Field
                                                                      type={"date"}
                                                                      title={"วันที่ซื้อป้ายแดง"}
                                                                      name={"label_buy_date"}
                                                                      placeholder={order?.label_buy_date ? order?.label_buy_date : "เลือกวันที่"}
                                                                      require={false}
                                                                      formatDate={"DD/MM/YYYY"}
                                                                      value={order?.label_buy_date}
                                                                      handleSelectDate={this.handleChangeDate}
                                                                 />
                                                            </Col>
                                                            <Col xl={3}>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"สาขา"}
                                                                      name="branch_id"
                                                                      value={order?.branch_id}
                                                                      placeholder={"เลือกสาขา"}
                                                                      option={branch}
                                                                      handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 />
                                                            </Col>
                                                            <LabelBar
                                                                 handleChangeText={(e) => this.handleChangeRadio(e)}
                                                                 name="status"
                                                                 type={"radio"}
                                                                 title={"สถานะ"}
                                                                 value={order?.status}
                                                                 style_id="__inline"
                                                                 xl={6}
                                                                 label={["ใช้งาน", "ไม่ได้ใช้งาน"]}
                                                            />
                                                       </Row>
                                                       {/* Submit group */}
                                                       <div className="button-group __submit row">
                                                            <Col xs={6}>
                                                                 <Button onClick={this.editLabel}>บันทึกการแก้ไข</Button>
                                                            </Col>
                                                       </div>

                                                       {/* <Row className='noMargin' md={8}>
                                                            <Col md={5}></Col>
                                                            <Col>
                                                                 <Button>บันทึกการแก้ไข</Button>
                                                            </Col>
                                                            <Col md={5}></Col>
                                                       </Row> */}
                                                  </>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ลบป้าย"} />
                                                  <Row>
                                                       <Col md={2}>
                                                            <Button onClick={this.deleteLabel} variant="danger">
                                                                 ลบป้าย
                                                            </Button>
                                                       </Col>
                                                  </Row>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );
          }
     }
}
