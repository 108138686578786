import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import { Button, Image, Form, Modal, Col, Row } from "react-bootstrap";
import "./navigation.scss";
import { GET, POST } from "api/index";
import { LabelBar } from "components/index";
import Field from "../components/field-control";
import { getToken_permission_page, getToken } from "../static/function";
import { field_text } from "../static/Static";
//routes
import PrivateRoute from "./private-route";

//sidebar
import { Sidebar, Loader } from "components/index";

//import admin page
import { Login, Dashboard, Other, Locate, PSCommission } from "../containers/index";
import swal from "sweetalert";
//-------
import line_page from "../line/line_page";
import dayjs from "dayjs";
export default class NavigationRouter extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: true,
               authen: false,
               role: null,
               path: window.location.pathname,
               form: {},
               onShow: false,
               permission_page: [],
          };
     }

     componentDidMount = async () => {
          this.checkTimeOut();
          let token = getToken_permission_page();
          let permiss = token ? JSON.parse(token.permission_page) : [];
          // console.log('permiss', permiss)
          let permission = [];
          for (let index = 0; index < permiss.length; index++) {
               const el = permiss[index];
               let find = field_text.permission.filter((e) => e.path === el.path);
               if (find[0]) {
                    permission = permission.concat(find[0].arr);
               }
          }
          this.setState({ permission_page: permission });
          this.checkAuth();
     };
     checkTimeOut = async () => {
          let time_out = localStorage.getItem("time_out");
          let path = window.location.pathname;
          if (!time_out && path !== "/" && path !== "/login") {
               try {
                    await GET("/authen/logout");
                    localStorage.removeItem("token");
                    localStorage.removeItem("username");
                    localStorage.removeItem("permission_page");
                    localStorage.removeItem("time_out");
                    window.location.assign("/login");
               } catch (error) {
                    console.log(error);
               }
          } else {
               if (time_out) {
                    let time = dayjs(new Date()).diff(dayjs(time_out), "hour");
                    // console.log("time", time);
                    if (time >= 6 && path !== "/" && path !== "/login") {
                         try {
                              await GET("/authen/logout");
                              localStorage.removeItem("token");
                              localStorage.removeItem("username");
                              localStorage.removeItem("permission_page");
                              localStorage.removeItem("time_out");
                              window.location.assign("/login");
                         } catch (error) {
                              console.log(error);
                         }
                    }
               }
          }
     };
     onLogOut = () => {};
     checkAuth = async () => {
          try {
               await GET("/authen/check");
               this.setState({
                    role: true,
                    loading: false,
                    authen: true,
               });
          } catch (error) {
               this.setState({ role: "", loading: false, authen: false });
          }
     };

     onLogout = async () => {
          swal("ต้องการจะออกจากระบบหรือไม่ ?", {
               buttons: {
                    cancel: "ยกเลิก",
                    submit: {
                         text: "ตกลง",
                         value: "submit",
                    },
               },
          }).then(async (value) => {
               if (value === "submit") {
                    try {
                         await GET("/authen/logout");
                         localStorage.removeItem("token");
                         localStorage.removeItem("username");
                         localStorage.removeItem("permission_page");
                         localStorage.removeItem("time_out");
                         window.location.assign("/login");
                    } catch (error) {
                         console.log(error);
                    }
               }
          });
     };

     handleChangeText = (e) => {
          // handle by name
          let { form } = this.state;
          form[e.target.name] = e.target.value;
          this.setState({ ...form });
     };

     onChangePassword = async () => {
          let { form } = this.state;
          try {
               let username = localStorage.getItem("username");
               let res = await POST("/authen/change_password", { ...form, password: form._password, username: username });
               swal({
                    icon: "success",
                    title: "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
                    button: "เสร็จสิ้น",
                    closeOnClickOutside: false,
               }).then(() => {
                    this.onShow();
               });
          } catch (e) {
               swal({
                    icon: "error",
                    title: "เปลี่ยนรหัสผ่านไม่สำเร็จ",
                    text: e.message,
                    button: "เสร็จสิ้น",
                    closeOnClickOutside: false,
               });
          }
     };

     onShow = () => {
          let { onShow } = this.state;
          this.setState({ onShow: !onShow, form: {} });
     };

     changePasswordModal = () => {
          let { onShow } = this.state;
          return (
               <Modal show={onShow} size="sm" centered>
                    <div style={{ paddingVertical: "0.5rem", paddingBottom: "0.6rem" }}>
                         <Modal.Header closeButton={true} onClick={this.onShow}>
                              <Modal.Title>
                                   <strong>เปลี่ยนรหัสผ่าน</strong>
                              </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                              <div className="-body">
                                   <div>
                                        <Form.Row>
                                             <Field
                                                  type={"text"}
                                                  title={"รหัสผ่านปัจจุบัน"}
                                                  typeInput={"password"}
                                                  name={"_password"}
                                                  placeholder={"กรุณากรอกรหัสผ่าน"}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                        </Form.Row>
                                        <div
                                             style={{
                                                  background: "#214097",
                                                  height: "0.1rem",
                                                  width: "100%",
                                                  marginTop: "-0.1rem",
                                                  marginBottom: "1rem",
                                             }}
                                        />
                                        <Form.Row>
                                             <Field
                                                  type={"text"}
                                                  title={"รหัสผ่านใหม่"}
                                                  typeInput={"password"}
                                                  name={"newPassword"}
                                                  placeholder={"กรุณากรอกรหัสผ่านใหม่"}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                        </Form.Row>
                                        <Form.Row>
                                             <Field
                                                  type={"text"}
                                                  title={"ยืนยันรหัสผ่านใหม่"}
                                                  typeInput={"password"}
                                                  name={"renewPassword"}
                                                  placeholder={"กรุณากรอกรหัสผ่านใหม่อีกครั้ง"}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                        </Form.Row>
                                   </div>
                              </div>
                         </Modal.Body>
                         <Modal.Footer style={{ marginTop: "-1rem" }}>
                              <Button onClick={this.onChangePassword} size={"md"} variant={"primary"} id={"submit"}>
                                   เปลี่ยนรหัสผ่าน
                              </Button>
                         </Modal.Footer>
                    </div>
               </Modal>
          );
     };

     render() {
          let { authen, role, path, onShow, permission_page } = this.state;
          if (this.state.loading) {
               return <Loader />;
          } else {
               return (
                    <Router>
                         {/* <Navbar role={this.state.role} /> */}
                         {authen && path !== "/admin/dashboard" && path !== "/" && !path.includes("pscom") ? (
                              <Sidebar role={role} onLogout={this.onLogout} onShow={this.onShow} />
                         ) : null}
                         <Suspense fallback={<Loader />}>
                              <div
                                   className={`${
                                        role && authen && path !== "/admin/dashboard" && path !== "/" && !path.includes("pscom")
                                             ? "navigation -addsidebar"
                                             : "navigation"
                                   }`}
                              >
                                   <Switch>
                                        {/* --------- another ------------ */}
                                        <Route exact path="/other/:text?/:link?" component={Other} />
                                        <Route exact path="/locate/:link?/:branch/:array" component={Locate} />
                                        <Route exact path="/line/notification/:id" component={line_page} />
                                        <Route exact path="/" render={() => (role ? <Redirect to="/admin/dashboard" /> : <Redirect to="/login" />)} />
                                        <Route
                                             exact
                                             path="/login"
                                             render={(routeProps) => (!role ? <Login {...routeProps} /> : <Redirect to="/admin/order/car" />)}
                                        />
                                        <Route
                                             exact
                                             path="/login/:link?"
                                             render={(routeProps) => (!role ? <Login {...routeProps} /> : <Redirect to="/admin/order/car" />)}
                                        />
                                        {/* <Route path='*' component={NotFound} /> */}
                                        {/* --------- user ------------- */}
                                        {/* --------- admin ------------ */}
                                        <PrivateRoute component={Dashboard} path={"/admin/dashboard"} authed={authen} exact />
                                        <PrivateRoute component={PSCommission} path={"/pscom/:id"} authed={authen} exact />
                                        {/* ---------------------------- */}
                                        {/* ---------------------------- */}
                                        {/* -------- start check ------- */}
                                        {/* ---------------------------- */}
                                        {/* ---------------------------- */}
                                        {permission_page &&
                                             permission_page.length > 0 &&
                                             permission_page.map((e) => <PrivateRoute component={e.c} path={e.p} authed={authen} exact />)}
                                        {/* <PrivateRoute component={LsApp} path={"/admin/payment/ls-app"} authed={authen} exact /> */}
                                        {/* <PrivateRoute component={GenerateMatch} path={"/admin/match/:match_type/:series:model:color?"} authed={authen} exact /> */}
                                        <Redirect to="/login/:link?" />
                                   </Switch>
                              </div>
                         </Suspense>
                         <this.changePasswordModal />
                         {/* <Footer role={this.state.role} /> */}
                    </Router>
               );
          }
     }
}
