import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";

import "./label-bar.scss";

export default class cLabelBar extends Component {
     render() {
          let {
               type,
               title,
               label,
               handleChangeText,
               name,
               disabled,
               require,
               value,
               xl,
               md,
               arrChecked,
               style_id,
               onClickLabelBar,
               placeholder,
               option,
               textHeader_style,
               textStyle,
               cell,
               percent,
               space,
               checked,
               check_status,
               inTable,
               link,
               onClickLink,
               color
          } = this.props;
          let label_span,
               control_span = {};
          let input_span = "";
          let padding_style = "noPadding";

          if (style_id?.includes("inline")) {
               /* Field inline */
               label_span = 3;
               padding_style = "pr-2 pl-0";
               input_span = xl;
          }
          /* Field primary */

          switch (type) {
               case "title":
                    return (
                         <Form.Group id="title" as={Col}>
                              <Form.Label>
                                   <strong style={color ? { color } : {}}>{title}</strong>
                                   {link ? (
                                        <span onClick={!disabled && onClickLink} className="link">
                                             {link}
                                        </span>
                                   ) : null}
                              </Form.Label>
                         </Form.Group>
                    );
               case "checkbox":
                    return (
                         <Form.Group id="check" as={Col}>
                              <Form.Label>
                                   <strong>{title}</strong>
                              </Form.Label>
                              <Row id={"row-check"}>
                                   {label.map((el, i) => (
                                        // console.log(el, i)
                                        <Col key={i} md={md ? md : 6}>
                                             {check_status ? (
                                                  <Form.Check
                                                       id={el.id}
                                                       type="checkbox"
                                                       // checked={arrChecked && arrChecked.some((item) => item === el.value) ? el.value : null}
                                                       // อย่ายุ่ง
                                                       checked={arrChecked && arrChecked[i] === 1 ? true : null}
                                                       value={el.value}
                                                       label={el.name}
                                                       name={name ? name : el.id}
                                                       disabled={disabled}
                                                       onChange={handleChangeText}
                                                  />
                                             ) : (
                                                  <Form.Check
                                                       id={el.id}
                                                       type="checkbox"
                                                       // checked={arrChecked && arrChecked.some((item) => item === el.value) ? el.value : null}
                                                       checked={arrChecked && arrChecked.some((e) => e === el.value) ? true : null}
                                                       value={el.value}
                                                       label={el.name}
                                                       name={name ? name : el.id}
                                                       disabled={disabled}
                                                       onChange={handleChangeText}
                                                  />
                                             )}
                                        </Col>
                                   ))}
                              </Row>
                         </Form.Group>
                    );
               case "checkbox-check":
                    return (
                         <Form.Group id="check" as={Col}>
                              <Form.Label>
                                   <strong>{title}</strong>
                              </Form.Label>
                              <Row id={"row-check"}>
                                   {label.map((el, i) => (
                                        // console.log(el, i)
                                        <Col key={i} md={md ? md : 6}>
                                             <Form.Check
                                                  id={el.id}
                                                  type="checkbox"
                                                  checked={checked ? true : null}
                                                  value={el.value}
                                                  label={el.name}
                                                  name={name ? name : el.id}
                                                  disabled={disabled}
                                                  onChange={handleChangeText}
                                                  require={require}
                                             />
                                        </Col>
                                   ))}
                              </Row>
                         </Form.Group>
                    );
               case "radio":
                    return (
                         <Form.Group id={style_id ? `radio-label${style_id}` : "radio-label"} as={Col} xl={xl} md={md}>
                              {title ? (
                                   <Form.Label as={Col} xl={label_span} md className={padding_style}>
                                        <strong>{title}</strong>
                                        {require && <strong style={{ color: "red" }}>*</strong>}
                                        <strong> : </strong>
                                   </Form.Label>
                              ) : null}
                              {label.map((el, i) => (
                                   <Col xl md key={i}>
                                        <Form.Check
                                             type="radio"
                                             label={el}
                                             name={name}
                                             checked={el === value}
                                             value={el}
                                             onChange={handleChangeText}
                                             disabled={disabled}
                                        />
                                   </Col>
                              ))}
                         </Form.Group>
                    );
               case "textheader":
                    return (
                         <Form.Group id="textheader" as={Col} md={md} className={textStyle ? `${textStyle}` : ""}>
                              <div className="d-flex">
                                   <Form.Label>
                                        <Col className="noPadding pr-2">
                                             <strong>{title.name}</strong>
                                        </Col>
                                        <Col className="noPadding">
                                             <span
                                                  className={textHeader_style === "" ? "detail" : `detail ${textHeader_style}`}
                                                  onClick={() => {
                                                       if (onClickLabelBar) {
                                                            onClickLabelBar();
                                                       }
                                                  }}
                                             >
                                                  {title.detail}
                                             </span>
                                        </Col>
                                   </Form.Label>
                              </div>
                         </Form.Group>
                    );
               case "textheader-genpayment":
                    return (
                         <Form.Group id="textheader" as={Col} md={md} xs={12} sm={12} className={textStyle ? `${textStyle}` : ""}>
                              <div className="d-flex">
                                   <Form.Label>
                                        <Col
                                             className="noPadding pr-2"
                                             md={title.name === "เลขประจำตัวผู้เสียภาษี : " || title.name === "พนักงานขาย : " ? 6 : 4}
                                             xs={title.name === "ที่อยู่ :" ? 6 : 7}
                                             sm={5}
                                        >
                                             <strong>{title.name}</strong>
                                        </Col>
                                        <Col className="noPadding">
                                             <span
                                                  className={textHeader_style === "" ? "detail" : `detail ${textHeader_style}`}
                                                  onClick={() => {
                                                       if (onClickLabelBar) {
                                                            onClickLabelBar();
                                                       }
                                                  }}
                                             >
                                                  {title.detail}
                                             </span>
                                        </Col>
                                   </Form.Label>
                              </div>
                         </Form.Group>
                    );
               case "underline":
                    return (
                         <Col md={md} style={{ padding: "0" }}>
                              <span>{title.name}</span>
                              <span style={{ marginBottom: "32px" }} className={`underline-0`}>
                                   {title.detail}
                              </span>
                         </Col>
                    );
               default:
                    return <React.Fragment></React.Fragment>;
          }
     }
}
