import React, { Component } from "react";
import { Breadcrumbs, LabelBar, Field, ModalPanel } from "components";
import { Row, Col, Button } from "react-bootstrap";
import { Input } from "antd";
import InputPanel from "components/input-panel/input-panel";
import { POST, ip } from "api/index";
import axios from "axios";
import swal from "sweetalert";

export default class GenerateRegisterLabel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order_id: this.props.match.params.id,
               orderdeliveryPdf: "",
               order: {
                    doc_1: 0,
                    doc_2: 0,
                    doc_3: 0,
                    doc_4: 0,
                    doc_5: 0,
                    doc_6: 0,
                    doc_7: 0,
                    doc_8: 0,
                    doc_9: 0,
                    doc_10: 0,
                    doc_11: 0,
               },
               detail: {},
               doc_status: {},
               hidden: {},
               supplier: [],
          };
     }

     componentDidMount = async () => {
          await this.setHiddenDocument();
          this.getOrderDeliveryPdf();
          await this.getOrderDetail();
          this.getDocumentStatus();
          this.getSupplierByType();
     };

     getSupplierByType = async () => {
          try {
               let sup = await POST("/ps/select_supplier_by_type", { supplier_type: "ตัวแทนจดทะเบียน" });
               this.setState({ supplier: sup });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     setHiddenDocument = () => {
          let { hidden } = this.state;
          for (let i = 1; i <= 16; i++) {
               hidden[`h${i}`] = false;
          }

          this.setState({ hidden });
          // console.log(this.state.hidden);
     };

     getOrderDetail = async () => {
          let { order_id, order, hidden } = this.state;
          try {
               let result = await POST("/order/this", { order_id: order_id });
               // console.log('result', result)
               if (result.comfinace_date !== "0") {
                    hidden.h5 = true;
               }

               if (result.reduce_tax_date) {
                    hidden.h6 = true;
               }
               if (result.recive_date) {
                    hidden.h11 = true;
               }

               // if (result.orcp_status === "ชำระเงินแล้ว") {
               //      hidden.h15 = true;
               // }
               if (result.register_status === "จดทะเบียนแล้ว") {
                    hidden.h12 = true;
               }

               if (result.oder_turnpay_status === "ออกใบกำกับภาษีแล้ว") {
                    hidden.h16 = true;
               }

               Object.keys(hidden).forEach((v, i) => {
                    if (result.acept_status === "ใบจองรออนุมัติ") {
                         hidden.h1 = true;
                    } else if (result.acept_status === "ใบจองอนุมัติแล้ว") {
                         hidden.h1 = true;
                         hidden.h2 = true;
                    } else if (result.acept_status === "รับเงินจองแล้ว") {
                         hidden.h1 = true;
                         hidden.h2 = true;
                         hidden.h3 = true;
                    } else if (result.acept_status === "Leasing อนุมัติแล้ว") {
                         if (result.pay_choice === "เงินสด") {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                         } else {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h4 = true;
                         }
                    } else if (result.acept_status === "ยืนยันข้อมูล") {
                         if (result.pay_choice === "เงินสด") {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                         } else {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h4 = true;
                         }
                    } else if (result.acept_status === "จับคู่รถแล้ว") {
                         if (result.pay_choice === "เงินสด") {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                         } else {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h4 = true;
                         }
                    } else if (result.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
                         if (result.pay_choice === "เงินสด") {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                         } else {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h4 = true;
                         }
                    } else if (result.acept_status === "ชำระเงินแล้ว") {
                         if (result.pay_choice === "เงินสด") {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h8 = true;
                              hidden.h10 = true;
                         } else {
                              // console.log("ok");
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h4 = true;
                              hidden.h8 = true;
                              hidden.h10 = true;
                         }
                    } else if (result.acept_status === "ส่งมอบรถแล้ว") {
                         if (result.pay_choice === "เงินสด") {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h7 = true;
                              hidden.h8 = true;
                              hidden.h9 = true;
                              hidden.h10 = true;
                              hidden.h13 = true;
                         } else {
                              hidden.h1 = true;
                              hidden.h2 = true;
                              hidden.h3 = true;
                              hidden.h4 = true;
                              hidden.h7 = true;
                              hidden.h8 = true;
                              hidden.h9 = true;
                              hidden.h10 = true;
                              hidden.h13 = true;
                         }
                    }
               });
               await this.setState({ detail: { ...result }, hidden });
               this.getPrice(result.series_id, result.model_id, result.color_id, result.car_price_list);
          } catch (err) {
               console.log(err);
          }
     };

     //-----------------getPrice------------------

     getPrice = async (series_id, model_id, color_id, price_list) => {
          let { detail, order } = this.state;
          try {
               // let res = await POST("/aos/get_price", { series_id: Number(series_id), model_id: Number(model_id), color_id: Number(color_id) });
               let price = price_list && price_list.length > 2 ? JSON.parse(price_list) : null;
               // console.log("price", price);
               let regis_need, regis_real;
               if (detail.userinfo_customer_type === "บุคคลทั่วไป") {
                    // regis_need = res.newData[0].price_list.register_fun_normal_to_company;
                    // regis_real = res.newData[0].price_list.register_fun_normal_to_delivery;
                    regis_need = price ? price.register_fun_normal_to_company || 0 : 0;
                    regis_real = price ? price.register_fun_normal_to_delivery || 0 : 0;
               } else {
                    // regis_need = res.newData[0].price_list.register_fun_coporation_to_company;
                    // regis_real = res.newData[0].price_list.register_fun_coporation_to_delivery;
                    regis_need = price ? price.register_fun_coporation_to_company || 0 : 0;
                    regis_real = price ? price.register_fun_coporation_to_delivery || 0 : 0;
               }
               this.setState({ regis_real, regis_need, register_price: detail.register_price });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     getDocumentStatus = async () => {
          let { order, order_id, detail } = this.state;
          order.order_id = parseInt(order_id);
          try {
               let result = await POST("/order/check_document", { order_id: this.state.order_id });
               Object.keys(order).forEach((el) => {
                    order[`${el}`] = result[`${el}`];
               });
               order.ordercheck_note = result.ordercheck_note;
               order.register_name = result.register_name ? result.register_name : "-";

               order["register_price_need"] = detail.register_price_need;
               order["register_price_real"] = detail.register_price_real;

               if (result.suppllier_register_id) {
                    order.suppllier_register_id = result.suppllier_register_id;
               }
               this.setState({ doc_status: { ...result }, ...order });
          } catch (err) {
               console.log(err);
          }
     };

     getOrderDeliveryPdf = async () => {
          let { order_id } = this.state;
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/delivery_pdf`,
                         { order_id: order_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         this.setState({
                              orderdeliveryPdf: fileURL,
                              // spinner: false,
                              // show_modal_pdf: true
                         });
                    });
          } catch (error) {}
     };

     /* ----------------- onClick --------------------- */
     handleClickSubmit = async (item) => {
          let { order, detail, register_price, regis_real, regis_need } = this.state;
          try {
               if (!item && !order.suppllier_register_id) {
                    swal({
                         icon: "warning",
                         text: `กรุณาเลือกตัวแทนจดทะเบียน`,
                         buttons: {
                              danger: "ยกเลิก",
                         },
                    });
               } else {
                    let result = await POST("/order/submit_check_register", {
                         ...order,
                         branch_id: detail.branch_id,
                         save: item ? item : false,
                         register_price,
                         register_price_need: regis_need,
                         register_price_real: regis_real,
                         register_pay: detail.register_pay,
                    });
                    this.setState({ show: !this.state.show });
                    swal({
                         icon: "success",
                         text: "บันทึกข้อมูลแล้ว",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then((value) => {
                         if (item) {
                              window.location.reload();
                         } else {
                              window.location.assign("/admin/label/register");
                         }
                    });
               }
          } catch (err) {
               // console.log("err", err);
               swal({
                    icon: "warning",
                    text: err,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
          }
     };

     /* ----------------- onChange --------------------- */
     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;

          this.setState({ order });
     };

     handleChangeNumberPrice = (e, name) => {
          let { order } = this.state;
          order[name] = e.floatValue;
          this.setState({ order });
     };

     handleChangeCheckBox = (checked, e) => {
          let { order, doc_status } = this.state;

          if (checked) {
               order[`doc_${e.target.value}`] = 1;
          } else {
               order[`doc_${e.target.value}`] = 0;
               doc_status[`doc_${e.target.value}`] = 0;
          }

          this.setState({ order, doc_status });
          // console.log(order);
     };

     render() {
          let { orderdeliveryPdf, order, detail, doc_status, hidden, regis_real, regis_need, register_price } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `การจดทะเบียน`,
                                   onClick: () => window.location.assign("/admin/label/register"),
                              },
                              {
                                   title: `ตรวจสอบเอกสารใบจอง (${detail?.order_code_id})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${detail?.order_id}`),
                              },
                         ]}
                         active={2}
                         button={[]}
                    />

                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* <button onClick={this.download} >download</button> */}
                                        {orderdeliveryPdf && <embed src={orderdeliveryPdf} type="application/pdf" height="1150px" width="100%" />}
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ตรวจสอบเอกสาร"} />
                                        <div className="checkbox-no-margin">
                                             <LabelBar
                                                  check_status={true}
                                                  name={"test"}
                                                  type={"checkbox"}
                                                  label={[
                                                       { name: "ใบแจ้งจำหน่าย", value: 1 },
                                                       { name: "ใบกำกับภาษีค่ารถ", value: 2 },
                                                       { name: "ใบเสร็จรับเงินค่ารถ", value: 3 },
                                                       { name: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์", value: 4 },
                                                       { name: "หนังสือมอบอำนาจ / แบบขอจดทะเบียน", value: 5 },
                                                       { name: "หาง พรบ. ประกันภัย", value: 6 },
                                                       { name: "สำเนาทะเบียนบ้าน", value: 7 },
                                                       { name: "สำเนาบัตรประชาชน", value: 8 },
                                                       { name: "ลอกลายตัวเลขเครื่องตัวถัง", value: 9 },
                                                       { name: "หนังสือรับรองบริษัท", value: 10 },
                                                       { name: "บันทึกรับทราบ", value: 11 },
                                                  ]}
                                                  arrChecked={[
                                                       doc_status.doc_1,
                                                       doc_status.doc_2,
                                                       doc_status.doc_3,
                                                       doc_status.doc_4,
                                                       doc_status.doc_5,
                                                       doc_status.doc_6,
                                                       doc_status.doc_7,
                                                       doc_status.doc_8,
                                                       doc_status.doc_9,
                                                       doc_status.doc_10,
                                                       doc_status.doc_11,
                                                  ]}
                                                  handleChangeText={(e) => this.handleChangeCheckBox(e.target.checked, e)}
                                                  md={12}
                                             />
                                        </div>
                                        <div className="button-group __submit row">
                                             <Col xs={6}>
                                                  <Button onClick={() => this.handleClickSubmit(true)}>Save</Button>
                                             </Col>
                                        </div>
                                        <LabelBar type={"title"} title={"เอกสารที่ฝ่ายบัญชีออกแล้ว"} />
                                        <div className="-padding">
                                             {/* {console.log(`hidden`, hidden)} */}
                                             <p hidden={!hidden.h1}>- ใบรับจองรถยนต์</p>
                                             <p hidden={!hidden.h2}>- ใบอนุมัติการลดเกิน Margin / ขอเครดิต</p>
                                             <p hidden={!hidden.h3}>- ใบกำกับภาษีเงินจอง</p>
                                             <p hidden={!hidden.h4}>- ใบยืนยันลีสซิ่ง</p>
                                             <p hidden={!hidden.h5}>- ใบกรอกค่าคอมมิชชั่นไฟแนนซ์</p>
                                             <p hidden={!hidden.h6}>- ใบลดหนี้เงินจอง</p>
                                             <p hidden={!hidden.h7}>- ใบส่งมอบรถ</p>
                                             <p hidden={!hidden.h8}>- ใบกำกับภาษีค่ารถ</p>
                                             <p hidden={!hidden.h9}>- ใบอนุญาตนำรถออก</p>
                                             <p hidden={!hidden.h10}>- ใบเสร็จรับเงิน</p>
                                             <p hidden={!hidden.h11}>- ใบรับเงินชั่วคราวป้ายแดง</p>
                                             <p hidden={!hidden.h12}>- ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์</p>
                                             <p hidden={!hidden.h13}>- สรุปรายการรับเงินวันส่งมอบรถ</p>
                                             <p hidden={!hidden.h16}>- ใบกำกับภาษีเทิร์นสินค้า</p>
                                        </div>
                                        <LabelBar type={"title"} title={"หมายเหตุ"} />
                                        <div className="-padding">
                                             <InputPanel
                                                  name={"ordercheck_note"}
                                                  handleChange={(e) => this.handleChangeText(e)}
                                                  value={order?.ordercheck_note}
                                                  type={"textarea"}
                                                  rows={3}
                                             />
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ค่าจดทะเบียน"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ผู้จดทะเบียน : ",
                                                            detail: `${detail?.register_pay}`,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ลักษณะรถ : ",
                                                            detail: `${detail?.series_name} + ${detail?.model_name} + ${detail?.color_name}`,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อผู้จดทะเบียน : ",
                                                            detail: order?.register_name || "-",
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ค่าจดที่เรียกเก็บ : ",
                                                            detail: Number(detail?.register_price || register_price).toLocaleString(undefined, {
                                                                 minimumFractionDigits: 2,
                                                                 maximumFractionDigits: 2,
                                                            }),
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ค่าจดที่ผู้จดเรียกเก็บ : ",
                                                            detail: Number(order?.register_price_need || regis_need).toLocaleString(undefined, {
                                                                 minimumFractionDigits: 2,
                                                                 maximumFractionDigits: 2,
                                                            }),
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ค่าจดที่จ่ายจริง : ",
                                                            detail: Number(order?.register_price_real || regis_real).toLocaleString(undefined, {
                                                                 minimumFractionDigits: 2,
                                                                 maximumFractionDigits: 2,
                                                            }),
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             {/* <Field
                                                  handleChangeNumber={(e) => {
                                                       this.handleChangeNumberPrice(e, "register_price_need");
                                                  }}
                                                  type={"number"}
                                                  title={"ค่าจดที่ผู้จดเรียกเก็บ : "}
                                                  placeholder={"0.00"}
                                                  require={false}
                                                  value={order?.register_price_need || regis_need}
                                                  style_id="__inline"
                                                  md={8}
                                                  decimalScale={2}
                                             />
                                             <Field
                                                  handleChangeNumber={(e) => {
                                                       this.handleChangeNumberPrice(e, "register_price_real");
                                                  }}
                                                  type={"number"}
                                                  value={order?.register_price_real || regis_real}
                                                  title={"ค่าจดที่จ่ายจริง : "}
                                                  placeholder={"0.00"}
                                                  require={false}
                                                  decimalScale={2}
                                                  style_id="__inline"
                                                  md={8}
                                             /> */}
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลผู้จดทะเบียน"} />
                                        <Field
                                             title={"ตัวแทนจดทะเบียน"}
                                             type={"select"}
                                             option={this.state.supplier?.map((el) => ({ value: el.supplier_id, name: el.supplier_name }))}
                                             md={6}
                                             name={"suppllier_register_id"}
                                             value={order?.suppllier_register_id}
                                             handleChangeText={(e) => this.handleChangeText(e)}
                                        />
                                   </div>
                              </div>
                         </div>
                         <div className="button-group __submit row">
                              <Col xs={6}>
                                   <Button onClick={() => this.handleClickSubmit(false)}>Submit</Button>
                              </Col>
                         </div>
                    </div>
               </div>
          );
     }
}
