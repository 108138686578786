import { GET } from "api/index";
import { Breadcrumbs, Search, Skeleton, Table } from "components";
import dayjs from "dayjs";
import React, { Component } from "react";
import { getToken } from "static/function";

export default class LeasingPaymentList extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {
                    orcp_status: "รอรับเงินจาก Leasing",
               },
               branch: [],
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getCarPayment();
          this.getBranch();
     };
     getCarPayment = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/get_prder_car_payment");
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => el.orcp_status === "ชำระเงินแล้ว")
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el))
                    .filter((el) => el.pay_choice === "ผ่อนชำระ" || el.order_fleet)
                    .map((el) => ({ ...el, orcp_status: el.orcp_code ? "ชำระเงินแล้ว" : el.order_fleet ? "Fleet" : "รอรับเงินจาก Leasing" }));
               let order = order_search.filter((el) => el.orcp_status === "รอรับเงินจาก Leasing" || el.orcp_status === "Fleet");
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               getBranch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error", error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/payment/leasing/${row.order_id}`)}>ทำรายการ</a>;
     }
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
          // console.log("search", search);
     };
     handleClickSearch = () => {
          let { order_search, search, branch } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.orcp_status ? (search.orcp_status === "ทั้งหมด" ? el : String(el.orcp_status) === String(search.orcp_status)) : el)
                    // (search.pay_choice ? (search.pay_choice === 'ทั้งหมด' ? el : String(el.pay_choice) === String(search.pay_choice)) : el) &&
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el) &&
                         (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
               );
          this.setState({ newSearch });
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };
     render() {
          let { order, newSearch, search, branch } = this.state;
          const table = {
               column: [
                    {
                         dataField: "payment_list",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         formatter: this.orderFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "orcp_status",
                         text: "การชำระเงิน",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "pay_choice",
                         text: "วิธีชำระเงิน",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __byn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "preemption_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "รับเงินจากไฟแนนซ์" }]} active={0} button={""} />
                    <div className="content __main-panel leasing-payment">
                         <Search
                              type={"carpayment-leasing"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   branchs: branch,
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
