import React, { Component } from "react";
import { GET } from "api/index";
import { Breadcrumbs, Table, Search, Skeleton, Image } from "components/index";
import dayjs from "dayjs";
import { getToken } from "../../../../../../static/function";

export default class CarPayment extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {
                    orcp_status: "รอชำระเงิน",
               },
               branch: [],
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getCarPayment();
          this.getBranch();
     };
     getCarPayment = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/get_prder_car_payment");
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => el.pay_choice === "เงินสด");
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search.filter((el) => el.orcp_status === "รอชำระเงิน" || el.orcp_status === "รอรับเงินจาก Leasing");
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               getBranch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error", error);
          }
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return (
               <a
                    onClick={() =>
                         window.location.assign(
                              // `/admin/payment/product/car/${row.orcp_status}/${row.order_id}`
                              `/admin/payment/product/car/${row.order_id}`
                         )
                    }
               >
                    ทำรายการ
               </a>
          );
     }
     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({
               search,
          });
          // console.log(search);
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search, branch } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.orcp_status
                              ? search.orcp_status === "ทั้งหมด"
                                   ? el
                                   : search.orcp_status === "รับเงินป้ายแดง"
                                   ? el.car_label
                                   : search.orcp_status === "รอชำระเงิน"
                                   ? el.orcp_status === "รอชำระเงิน" || el.orcp_status === "รอรับเงินจาก Leasing"
                                   : String(el.orcp_status) === String(search.orcp_status)
                              : el)
                    // (search.pay_choice ? (search.pay_choice === "ทั้งหมด" ? el : String(el.pay_choice) === String(search.pay_choice)) : el) &&
               )
               .filter(
                    (el) =>
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el) &&
                         (search.userinfo_name ? String(el.userinfo_name).indexOf(String(search.userinfo_name)) > -1 : el) &&
                         (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     // lableFormatter = (cell, row, rowIndex, formatExtraData) => {
     //      console.log('cell', cell)
     // }

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };
     render() {
          let { order, newSearch, search, branch } = this.state;
          // console.log("search", search);
          const table = {
               column: [
                    {
                         dataField: "payment_list",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "orcp_status",
                         text: "การชำระเงิน",
                         formatter: (e) => (e ? (e === "รอรับเงินจาก Leasing" ? "รอชำระเงิน" : e) : "-"),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "userinfo_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "pay_choice",
                         text: "วิธีชำระเงิน",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __byn-ss",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "preemption_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "รับชำระเงินค่ารถ" }]} active={0} button={""} />
                    <div className="content __main-panel ordercar-payment">
                         <Search
                              type={"carpayment"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   branchs: branch,
                                   value: search,
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
