import React, { Component } from "react";
import { Breadcrumbs, LabelBar, Table } from "components/index";
import { Row, Button } from "react-bootstrap";
import swal from "sweetalert";
import { POST } from "api";
import dayjs from "dayjs";

function ScrollToTopOnMount() {
     React.useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class DetailLeasing extends Component {
     constructor(props) {
          super(props);
          this.state = {
               file: [],
               id: this.props.match.params.id,
               previous_path: this.props.match.params.manage,
               leadsing: {},
               title: [],
          };
     }

     componentDidMount = () => {
          this.getLeadsing();
          this.setPath();
     };

     getLeadsing = async () => {
          try {
               let leasing = await POST("/ps/this_leasing", {
                    leasinge_id: this.state.id,
               });
               leasing.files.forEach((element, i) => {
                    element.no = i + 1;
               });
               this.setState({ leadsing: { ...leasing }, file: leasing.files });
          } catch (error) {}
     };

     handleClick = () => {
          let { id } = this.state;
          window.location.assign(`/admin/leasing/detail/edit/${id}`);
     };

     handleClickRemove = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         let id = this.props.match.params.id;
                         await POST("/aos/del_lesing", { id });
                         swal({
                              text: "ทำรายการสำเร็จ",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => this.props.history.push("/admin/leasing"));
                    }
               });
          } catch (error) {}
     };

     /* Formatter */
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          console.log(cell);
          return (
               <a href={row.file} download>
                    <Button>ดูเอกสาร </Button>
               </a>
          );
     }

     setPath = async () => {
          let { title } = this.state;

          title = [
               {
                    title: "Leasing",
                    onClick: () => window.location.assign("/admin/leasing"),
               },
               {
                    title: "ข้อมูล",
                    // onClick: () => this.handleNext(false),
               },
          ];

          title = this.setState({ title: title });
     };

     render() {
          let { leadsing, title } = this.state;
          const column = [
               {
                    dataField: "leasinge_file_id",
                    text: "ลำดับ",
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "name",
                    text: "ไฟล์เอกสาร",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "detail",
                    text: "ดูเอกสาร",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __right __btn-ss",
               },
          ];
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={title}
                         active={title.length - 1}
                         button={[
                              {
                                   name: "แก้ไขข้อมูล",
                                   icon: "fas fa-pen-square",
                                   handleClick: this.handleClick,
                                   variant: "primary",
                              },
                              // {
                              //      name: "ลบข้อมูล",
                              //      icon: "fas fa-trash alt",
                              //      handleClick: this.handleClickRemove,
                              //      variant: "danger",
                              // },
                         ]}
                         onClick={() => this.props.history.goBack()}
                    />

                    <div className="content __input-panel">
                         {/* addresscus Detail */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* ข้อมูลการสร้าง */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Create : ",
                                                            detail: leadsing?.nameuser_create || "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Update ล่าสุด :",
                                                            detail: leadsing?.nameuser_update || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Create : ",
                                                            detail: leadsing?.created_at ? dateFormatter(leadsing?.created_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Update ล่าสุด : ",
                                                            detail: leadsing?.updated_at ? dateFormatter(leadsing?.updated_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        {/* ข้อมูลประเภท Leasing  */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูลประเภท Leasing "} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "บริษัท Leasing  : ",
                                                            detail: leadsing?.supplier_name || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Series  : ",
                                                            detail: leadsing?.series_name || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ประเภท Leasing  : ",
                                                            detail: leadsing?.leasing_type || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "จำนวนปีผ่อนชำระ  : ",
                                                            detail: `${leadsing?.amount_year || "-"} ปี`,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "รายละเอียก Leasing  : ",
                                                            detail: leadsing?.leasing_text || "-",
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                        <div>
                                             <LabelBar type={"title"} title={"อัตราดอกเบี้ย"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "อัตราดอกเบี้ย :",
                                                            detail: `${leadsing?.leasing_interest || "-"} %`,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                        <div>
                                             <LabelBar type={"title"} title={"อัตราดอกเบี้ย"} />
                                             <div className="content" id="table-content">
                                                  <Table type={"custom"} keyField={"no"} column={column} data={this.state.file} />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
