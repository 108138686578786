import { Breadcrumbs, Search, Table, Skeleton } from "../../../../../components";
import React, { Component } from "react";
import dayjs from "dayjs";
import { GET } from "api/index";
import { getToken } from "../../../../../static/function";
export default class OrderLeasing extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {
                    leasing_open_credit_status: "ขอสินเชื่อ",
               },
               branch: [],
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getOrder();
          this.getBranch();
     };

     getOrder = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/get_order_leasing");
               // console.log("res", res);
               let order_search = res
                    .sort((end, start) => parseFloat(start.order_id) - parseFloat(end.order_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search.filter((el) => el.leasing_open_credit_status === "ขอสินเชื่อ");
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log("error ", error);
          }
     };
     getBranch = async () => {
          try {
               let branch = await await GET("/ps/get_branchs");
               branch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branch });
          } catch (error) {
               console.log("error", error);
          }
     };

     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          // console.log("search", search);
          this.setState({ ...search });
     };
     handleSelectDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({ ...search });
          // console.log("search", search);
     };

     /*----------------------Search Data--------------------------*/
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search
               .filter(
                    (el) =>
                         (search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el) &&
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.leasing_open_credit_status
                              ? search.leasing_open_credit_status === "ทั้งหมด"
                                   ? el
                                   : String(el.leasing_open_credit_status) === String(search.leasing_open_credit_status)
                              : el)
               )
               .filter(
                    (el) =>
                         (search.userinfo_a_card ? String(el.userinfo_a_card).indexOf(String(search.userinfo_a_card)) > -1 : el) &&
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el) &&
                         (search.saler_name_string ? String(el.saler_name_string).indexOf(String(search.saler_name_string)) > -1 : el)
               );
          this.setState({ newSearch });
     };
     /* Formatter */
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/order/leasing/${row.order_id}`)}>ทำรายการ</a>;
     }

     dateFormatter(cell, row, rowIndex, formatExtraData) {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     }

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };

     render() {
          let { order, branch, search, newSearch } = this.state;
          let table = {
               column: [
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "order_code_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "userinfo_a_card",
                         text: "A-Card No.",
                         headerClasses: "header-custom __btn-s",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "branch_name",
                         text: "โชว์รูม",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่จอง",
                         headerClasses: "header-custom __btn-s",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "leasing_open_credit_status",
                         text: "สถานะสินเชื่อ",
                         headerClasses: "header-custom __btn-s",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "status",
                         text: "การอนุมัติ",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "preemtion_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "การอนุมัติ Leasing" }]} active={0} button={[]} />
                    <div className="content __main-panel orderleasing">
                         <Search
                              type={"leasing-order"}
                              handleChangeText={this.handleChangeText}
                              handleSelectDate={this.handleSelectDate}
                              handleClickSearch={this.handleClickSearch}
                              option={{ value: search, branch: branch }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={"no"} />
                         )}
                    </div>
               </div>
          );
     }
}
