import { Breadcrumbs, ModalPanel, Table, Field, LabelBar } from "components/index";
import React, { Component } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import dayjs from "dayjs";
import { POST, ip } from "api/index";
import axios from "axios";
import swal from "sweetalert";
import { getToken } from "static/function";

export default class GenerateFormDeliver extends Component {
     constructor(props) {
          super(props);
          this.state = {
               preemtionp_id: this.props.match.params.id,
               showInput: false,
               showSelect: false,
               order: {},
               document: [],
               show: {},
               doc_active: {},
               modal_select: false,
               turn_type_pdf: null,
               modal_acc_turn: false,
               sup_order: {
                    order_id: this.props.match.params.id,
               },
               supplierTurn: [],
               users: {},
          };
     }

     componentDidMount = async () => {
          await this.setHideDocStatus();
          await this.setState({ users: getToken() });
          this.getOrder();
     };

     setHideDocStatus = () => {
          let { show } = this.state;
          for (let i = 1; i <= 11; i++) {
               show[`s${i}`] = false;
          }

          this.setState({ show });
     };

     getOrder = async () => {
          let { document, show, doc_active, users } = this.state;
          let doc = [];
          try {
               let result = await POST("/order/this", { order_id: this.props.match.params.id });
               let setReceip = result.receipt;

               if (result.accturn.length > 0) {
                    this.getSupplierTurn(this.props.match.params.id);
               }
               //-----------------------------setReciept------------------------------
               let PRBreceipt = setReceip && setReceip.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่า พ.ร.บ.");
               let insuranceReceipt = setReceip && setReceip.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย");
               let pledgeRegister = setReceip && setReceip.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน");
               let taxACCReceipt = setReceip && setReceip.find((el) => el.receipt_method === "ใบเสร็จรับเงิน / ใบกำกับภาษีค่าอุปกรณ์ตกแต่ง");
               let financeReceipt = setReceip && setReceip.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่าไฟแนนซ์");
               if (PRBreceipt) {
                    doc_active.b1 = true;
               }
               if (insuranceReceipt) {
                    doc_active.b2 = true;
               }
               if (pledgeRegister) {
                    doc_active.b3 = true;
               }
               if (taxACCReceipt) {
                    doc_active.b4 = true;
               }
               if (financeReceipt) {
                    doc_active.b5 = true;
               }

               //Receipt Document
               // if (result.orcp_leasing_payment_method) {
               //      let pay = JSON.parse(result.orcp_leasing_payment_method);
               //      let a = pay.filter((el) => el.check === true);
               //      let p = 0;
               //      for (let i in a) {
               //           p += Number(a[i].price);
               //      }

               //      result.receipt.push({
               //           receipt_method: "ใบเสร็จรับเงินไฟแนนซ์",
               //           receipt_price: p,
               //           check: true,
               //           code: "KO",
               //           file: "/tax_leasing_pdf",
               //      });
               // }

               // let receipt = result.receipt;

               if (result.comfinace_date !== "0") {
                    doc_active.a5 = true;
               }
               if (result.reduce_tax_date && users?.position_id === 19 && !result.reducetax) {
                    doc_active.a6 = true;
               }
               if (
                    result.reduce_tax_date &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a6 = true;
               }
               if (result.recive_date) {
                    doc_active.a11 = true;
               }
               if (result.register_status === "จดทะเบียนแล้ว") {
                    doc_active.a12 = true;
               }

               // if (result.orcp_status === "ชำระเงินแล้ว") {
               //      doc_active.a15 = true;
               // }
               if (result.confirm_date && users?.position_id === 19 && (!result.taxleasing || !result.tax)) {
                    doc_active.a15 = true;
               }
               if (
                    result.confirm_date &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a15 = true;
               }
               // if (result.oder_turnpay_status === "ออกใบกำกับภาษีแล้ว") {
               doc_active.a16 = true;
               // }

               if (result.orcp_date || result.orcp_leasing_date) {
                    doc_active.a10 = true;
               }

               if (result.reduce_tax_label_code && users?.position_id === 19 && !result.reducetaxlabel) {
                    doc_active.a17 = true;
               }
               if (
                    result.reduce_tax_label_code &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a17 = true;
               }

               if (result.reduce_register_tax_date && users?.position_id === 19 && !result.reducetaxregister) {
                    doc_active.a18 = true;
               }
               if (
                    result.reduce_register_tax_date &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a18 = true;
               }
               // In Flow Status
               // if (result.acept_status === "ใบจองรออนุมัติ") {

               //      doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //      doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               // } else if (result.acept_status === "ใบจองอนุมัติแล้ว") {
               //      doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //      doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //      doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               // } else if (result.acept_status === "รับเงินจองแล้ว") {
               //      doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //      doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //      doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //      doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               // } else if (
               //      result.acept_status === "Leasing อนุมัติแล้ว" ||
               //      result.acept_status === "ยืนยันข้อมูล" ||
               //      result.acept_status === "จับคู่รถแล้ว" ||
               //      result.acept_status === "ติดตั้งอุปกรณ์แล้ว"
               // ) {
               //      if (result.pay_choice === "เงินสด") {
               //           doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //           doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               //      } else {
               //           doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //           doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบยืนยันลีสซิ่ง", action: "พิมพ์", file: "/order/get_payment_leasing_pdf", type: "current" });
               //      }
               // }

               // // Out Flow Status
               // if (result.comfinace_date !== "0") {
               //      doc.push({ receipt_method: "กรอกค่าคอมไฟแนนซ์", action: "พิมพ์", file: "/order/commission_finance_pdf", type: "current" });
               // }
               // if (result.reduce_tax_date) {
               //      doc.push({ receipt_method: "พิมพ์ใบลดหนี้ค่าจอง", action: "พิมพ์", file: "/order/reduce_tax_pdf", type: "current" });
               // }

               // // In Flow Status
               // if (result.acept_status === "ชำระเงินแล้ว") {
               //      if (result.pay_choice === "เงินสด") {
               //           doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //           doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบกำกับภาษีรถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ต้นฉบับใบเสร็จรับเงินค่ารถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //      } else {
               //           doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //           doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบยืนยันลีสซิ่ง", action: "พิมพ์", file: "/order/get_payment_leasing_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบกำกับภาษีรถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ต้นฉบับใบเสร็จรับเงินค่ารถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //      }
               // } else if (result.acept_status === "ส่งมอบรถแล้ว") {
               //      if (result.pay_choice === "เงินสด") {
               //           doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //           doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบกำกับภาษีรถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ต้นฉบับใบเสร็จรับเงินค่ารถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบส่งมอบรถ", action: "พิมพ์", file: "/order/delivery_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบอนุญาตนำรถออก", action: "พิมพ์", file: "/order/car_out_pdf", type: "current" });
               //           doc.push({ receipt_method: "สรุปรายการรับเงินวันส่งมอบ", action: "พิมพ์", file: "/order/summary_delivery_car", type: "current" });
               //      } else {
               //           doc.push({ receipt_method: "ใบจองรถยนต์", action: "พิมพ์", file: "/order/order_leadsing_pdf", type: "current" });
               //           doc.push({ receipt_method: "สัญญาจองรถยนต์ (สคบ.)", action: "ต้นฉบับ2", file: "/order/reservation_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต", action: "พิมพ์", file: "/order/order_margin_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบกำกับภาษีค่าจอง", action: "พิมพ์", file: "/order/order_payment_tax_pdf", type: "current" });
               //           doc.push({ receipt_method: "ใบยืนยันลีสซิ่ง", action: "พิมพ์", file: "/order/get_payment_leasing_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบกำกับภาษีรถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ต้นฉบับใบเสร็จรับเงินค่ารถ", action: "ต้นฉบับ", file: "/order/car_pay_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบส่งมอบรถ", action: "พิมพ์", file: "/order/delivery_pdf", type: "current" });
               //           doc.push({ receipt_method: "พิมพ์ใบอนุญาตนำรถออก", action: "พิมพ์", file: "/order/car_out_pdf", type: "current" });
               //           doc.push({ receipt_method: "สรุปรายการรับเงินวันส่งมอบ", action: "พิมพ์", file: "/order/summary_delivery_car", type: "current" });
               //      }
               // }
               if (result.acept_status === "ใบจองรออนุมัติ") {
                    doc_active.a1 = true;
               } else if (result.acept_status === "ใบจองอนุมัติแล้ว") {
                    doc_active.a1 = true;
                    doc_active.a2 = true;
               } else if (result.acept_status === "รับเงินจองแล้ว") {
                    doc_active.a1 = true;
                    doc_active.a2 = true;
                    if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                    if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                         doc_active.a3 = true;
               } else if (result.acept_status === "Leasing อนุมัติแล้ว") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                    }
               } else if (result.acept_status === "ยืนยันข้อมูล") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                    }
               } else if (result.acept_status === "จับคู่รถแล้ว") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                    }
               } else if (result.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                    }
               } else if (result.acept_status === "ชำระเงินแล้ว") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a8 = true;
                         // doc_active.a10 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                         doc_active.a8 = true;
                         // doc_active.a10 = true;
                    }
               } else if (result.acept_status === "ส่งมอบรถแล้ว") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a7 = true;
                         doc_active.a8 = true;
                         doc_active.a9 = true;
                         // doc_active.a10 = true;
                         doc_active.a13 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                         doc_active.a7 = true;
                         doc_active.a8 = true;
                         doc_active.a9 = true;
                         // doc_active.a10 = true;
                         doc_active.a13 = true;
                    }
               } else if (result.acept_status === "ปิดการขาย") {
                    if (result.pay_choice === "เงินสด") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a7 = true;
                         doc_active.a8 = true;
                         doc_active.a9 = true;
                         // doc_active.a10 = true;
                         doc_active.a13 = true;
                    } else {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                         doc_active.a4 = true;
                         doc_active.a7 = true;
                         doc_active.a8 = true;
                         doc_active.a9 = true;
                         // doc_active.a10 = true;
                         doc_active.a13 = true;
                    }
               }

               // for (let i in receipt) {
               //      if (receipt[i].receipt_method === "ใบกำกับภาษี / ใบแจ้งหนี้ ไฟแนนซ์") {
               //           receipt.splice(i, 1);
               //      } else if (receipt[i].receipt_method === "ใบเสร็จรับเงินไฟแนนซ์") {
               //           receipt.splice(i, 1);
               //      } else if (receipt[i].receipt_method === "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์") {
               //           receipt.splice(i, 1);
               //      } else {
               //           receipt[i].active = true;
               //           receipt[i].type = "receipt";
               //      }
               // }

               // result.receipt = receipt;

               // console.log(doc);
               // let i = 1;
               // while (i <= 16) {
               //      doc_active[`a${i}`] = true;
               //      i++;
               // }

               this.setState({
                    order: { ...result },
                    document: doc,
                    doc_active,
                    setReceip,
               });
          } catch (error) {}
     };

     getSupplierTurn = async (order_id) => {
          try {
               this.setState({ supplierTurn: [...(await POST("/order/supplier_turn", { order_id }))] });
          } catch (error) {
               console.log(error);
          }
     };

     downloadFileOld = async (row, type) => {
          try {
               this.setState({ spinner: true });
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}${row.file}`,
                         { order_id: this.state.preemtionp_id, type: type },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
               this.setState({ spinner: false });
          } catch (error) {
               this.setState({ spinner: false });
               console.log(`error`, error);
          }
     };

     downloadFileReceipt = async (row) => {
          try {
               this.setState({ spinner: true });
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/template_pdf`,
                         {
                              order_id: this.state.preemtionp_id,
                              namepayment: row.receipt_method,
                              pricePayment: row.receipt_price,
                              code: row.receipt_code,
                              branch_id: this.state.order.branch_id,
                              name_pay: row.name_pay,
                         },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
               this.setState({ spinner: false });
          } catch (error) {
               this.setState({ spinner: false });
               console.log(`error`, error);
          }
     };

     downloadPdf1 = async (row, type) => {
          // console.log(row);
          try {
               this.setState({ spinner: true });
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/reservation1_pdf`,
                         row.type === "current"
                              ? { order_id: this.state.preemtionp_id, type: "สำเนา1" }
                              : {
                                     order_id: this.state.preemtionp_id,
                                     namepayment: row.receipt_method,
                                     pricePayment: row.receipt_price,
                                     code: row.receipt_code,
                                     branch_id: this.state.order.branch_id,
                                },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
               this.setState({ spinner: false });
          } catch (error) {
               this.setState({ spinner: false });
               console.log(`error`, error);
          }
     };

     downloadPdf2 = async (row, type) => {
          // console.log(row);
          try {
               this.setState({ spinner: true });
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/reservation2_pdf`,
                         row.type === "current"
                              ? { order_id: this.state.preemtionp_id, type: "สำเนา2" }
                              : {
                                     order_id: this.state.preemtionp_id,
                                     namepayment: row.receipt_method,
                                     pricePayment: row.receipt_price,
                                     code: row.receipt_code,
                                     branch_id: this.state.order.branch_id,
                                },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         this.setState({ spinner: false });
                    });
          } catch (error) {
               this.setState({ spinner: false });
               console.log(`error`, error);
          }
     };

     openSupPDF = async () => {
          let { sup_order } = this.state;
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(`${ip}/order/turn_tax_pdf`, sup_order, {
                         responseType: "blob",
                         headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              token,
                         },
                    })
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         this.setState({ modal_select: false });
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     loadCarTurnPDF = async () => {
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/turn_tax_car_pdf`,
                         { order_id: this.state.preemtionp_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: "ยังไม่ได้ออกใบกำกับภาษี",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     /* ------------------------ Handle ------------------------- */
     handleChangeNumber = (e, name) => {
          let { order } = this.state;
          order[name] = e.floatValue;
          this.setState({ ...order });
     };

     handleSelectDate = (date, dateString, name) => {
          let { order } = this.state;
          order[name] = dateString;
          this.setState({ ...order });
     };

     handleChangeText = (e) => {
          let { order } = this.state;
          order[e.target.name] = e.target.value;
          this.setState({ ...order });
     };

     handleChangeSelectSupplier = (e) => {
          let { sup_order } = this.state;
          sup_order[e.target.name] = e.target.value;
          this.setState({ sup_order });
     };

     /* -------------------------- On --------------------------- */
     onOpenInput = () => {
          let { showInput } = this.state;
          this.setState({ showInput: !showInput });
     };

     onOpenSelect = () => {
          let { showSelect } = this.state;
          this.setState({ showSelect: !showSelect });
     };
     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (cell) {
               return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
          } else {
               return <p>-</p>;
          }
     };
     noFormatter(cell, row, rowIndex, formatExtraData) {
          return <div className="body-custom __center">{rowIndex + 1}</div>;
     }

     // detailFormatter = (cell, row, rowIndex, formatExtraData) => {
     //      switch (formatExtraData) {
     //           case "action":
     //                if (row.file) {
     //                     if (row.action === "กรอกข้อมูล") {
     //                          return <a onClick={this.onOpenInput}>{row.action}</a>;
     //                     } else if (row.action === "เลือก") {
     //                          return <a onClick={this.onOpenSelect}>{row.action}</a>;
     //                     } else if (row.action === "ต้นฉบับ2") {
     //                          return <a onClick={() => this.downloadPdf(row, "ต้นฉบับ")}>ต้นฉบับ</a>;
     //                     } else {
     //                          return <a onClick={() => this.downloadPdf(row, "ต้นฉบับ")}>{row.action}</a>;
     //                     }
     //                } else {
     //                     return <span className="doc">-</span>;
     //                }
     //           case "copy":
     //                if (row.action === "ต้นฉบับ") {
     //                     return <a onClick={() => this.downloadPdf(row, "สำเนา")}>สำเนา</a>;
     //                } else if (row.action === "ต้นฉบับ2") {
     //                     return <a onClick={() => this.downloadPdf1(row, "สำเนา1")}>สำเนา1</a>;
     //                } else {
     //                     return <span className="doc"></span>;
     //                }
     //           case "copy2":
     //                if (row.action === "ต้นฉบับ2") {
     //                     return <a onClick={() => this.downloadPdf2(row, "สำเนา2")}>สำเนา2</a>;
     //                } else {
     //                     return <span className="doc"></span>;
     //                }
     //           case "log_status":
     //                if (row.log_status === "ดำเนินการ") {
     //                     return <span className="success">{cell}</span>;
     //                } else {
     //                     return <span>{cell}</span>;
     //                }
     //      }
     // };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "action":
                    if (row.active) {
                         if (row.type === "current") {
                              if (row.receipt_method === "ใบกำกับภาษีเทิร์นสินค้า") {
                                   return <i className="fas fa-print icon __btn active" onClick={() => this.setState({ modal_select: true })}></i>;
                              } else {
                                   return <i className="fas fa-print icon __btn active" onClick={() => this.downloadFileOld(row, "ต้นฉบับ")}></i>;
                              }
                         } else {
                              return <i className="fas fa-print icon __btn active" onClick={() => this.downloadFileReceipt(row)}></i>;
                         }
                    } else {
                         return <i className="fas fa-print icon "></i>;
                    }

               case "copy":
                    if (row.copy) {
                         if (row.active) {
                              if (row.receipt_method === "สัญญาจองรถยนต์ (สคบ.)") {
                                   return <a onClick={() => this.downloadPdf1(row, "")}>สำเนา</a>;
                              } else {
                                   return <a onClick={() => this.downloadFileOld(row, "สำเนา")}>สำเนา</a>;
                              }
                         } else {
                              if (row.receipt_method === "สัญญาจองรถยนต์ (สคบ.)") {
                                   return "สำเนา";
                              } else {
                                   return "สำเนา";
                              }
                         }
                    } else {
                         return "";
                    }

               case "copy2":
                    if (row.copy2) {
                         if (row.active) {
                              return <a onClick={() => this.downloadPdf2(row, "")}>สำเนา 2</a>;
                         } else {
                              return "สำเนา 2";
                         }
                    } else {
                         return "";
                    }
          }
     };

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/car/detail${row.order_id}`)}>{cell}</a>;
     };

     // ------------------ Modal ---------------------

     modalSelectSupplier = () => {
          let { order } = this.state;
          return (
               <div style={{ padding: "0 10%" }}>
                    <LabelBar
                         title={"ใบกำกับภาษี"}
                         type={"radio"}
                         label={order.carturn === 0 ? ["อุปกรณ์ตกแต่ง"] : ["รถยนต์", "อุปกรณ์ตกแต่ง"]}
                         value={this.state.turn_type_pdf}
                         handleChangeText={(e) => {
                              this.setState({ turn_type_pdf: e.target.value });
                         }}
                    />
               </div>
          );
     };

     openLoadPdfTurn = () => {
          let { turn_type_pdf, order } = this.state;
          if (turn_type_pdf === "รถยนต์") {
               this.loadCarTurnPDF();
          } else if (turn_type_pdf === "อุปกรณ์ตกแต่ง") {
               if (order.accturn.length > 0) {
                    this.setState({ modal_acc_turn: true, modal_select: false });
               } else {
                    swal({
                         icon: "warning",
                         text: "ยังไม่ได้ออกใบกำกับภาษี",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
          } else {
               swal({
                    icon: "warning",
                    text: "กรุณาเลือก",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     modalViewSelectSupplier = () => {
          let { supplier_acc, sup_order, supplierTurn } = this.state;
          let supplier_option = [];
          for (let i in supplierTurn) {
               supplier_option.push({ value: supplierTurn[i].supplier_id, name: supplierTurn[i].supplier_name });
          }

          return (
               <div style={{ padding: "0 15%" }}>
                    <Field
                         value={sup_order?.supplier_id}
                         type="select"
                         name={"supplier_id"}
                         title={"Supplier"}
                         option={supplier_option}
                         handleChangeText={(e) => this.handleChangeSelectSupplier(e)}
                    />
               </div>
          );
     };

     render() {
          let { preemtionp_id, showInput, showSelect, order, document, doc_active, setReceip } = this.state;
          let finance_comm = [
               { name: "Serie", value: order.series_name ? order.series_name : "-" },
               { name: "Model", value: order.model_name ? order.model_name : "-" },
               { name: "สี", value: order.color_name ? order.color_name : "-" },
               { name: "หมายเลขเครื่องยนต์", value: order.car_engine ? order.car_engine : "-" },
               { name: "หมายเลขตัวถัง", value: order.car_tank ? order.car_tank : "-" },
               { name: "ราคาทั้งหมด", value: order.real_price ? order.real_price : "-" },
               { name: "ผู้ซื้อ", value: order.userinfo_name ? order.userinfo_name : "-" },
          ];
          let input_finance = [
               {
                    type: "number",
                    title: "ค่าคอมไฟแนนซ์",
                    placeholder: "0.00",
                    decimalScale: 2,
                    // action: { handleChangeNumber: (e) => this.handleChangeNumber(e, "comfinace_price") },
                    handleChangeNumber: (e) => this.handleChangeNumber(e, "comfinace_price"),
                    value: order?.comfinace_price,
               },
               {
                    type: "number",
                    value: order?.comfinace_vat,
                    title: "VAT",
                    placeholder: "0.00",
                    decimalScale: 2,
                    handleChangeNumber: (e) => this.handleChangeNumber(e, "comfinace_vat"),
               },
               {
                    type: "number",
                    value: order?.comfinace_sum,
                    title: "ทั้งหมด",
                    placeholder: "0.00",
                    decimalScale: 2,
                    handleChangeNumber: (e) => this.handleChangeNumber(e, "comfinace_sum"),
               },
               {
                    type: "date",
                    title: "ประจำวันที่",
                    placeholder: `${order?.comfinace_date}`,
                    name: "comfinace_date",
                    handleSelectDate: this.handleSelectDate,
               },
               {
                    type: "text",
                    value: order?.comfinace_time,
                    title: "งวดที่",
                    placeholder: "กรอกงวดที่",
                    name: "comfinace_time",
                    handleChangeText: (e) => this.handleChangeText(e),
               },
          ];
          let reciept_list = [
               "ใบลดหนี้ค่าจอง",
               "ใบรับเงินชั่วคราวค่าเงินดาวน์",
               "ใบรับเงินชั่วคราวค่า พรบ.",
               "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
               "ใบรับเงินชั่วคราวค่าธรรมเนียมไฟแนนซ์",
               "ใบรับเงินชั่วคราวค่าประกันสินเชื่อ",
               "ใบรับเงินชั่วคราวค่าธรรมเนียมรูดบัตรเช็ค ตจว.",
               "ใบรับเงินชั่วคราวค่าต่อเติม",
               "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
               "ใบรับเงินชั่วคราวค่าธรรมเนียมชุดจดทะเบียน",
               "ใบรับเงินชั่วคราวค่าแจ้งย้าย",
               "รายการอื่น ๆ ที่ระบุมา",
          ];
          const inputFinance = (
               <Row className="noMargin">
                    <Col lg={6}>
                         {finance_comm.map((item, i) => (
                              <Row className="noMargin pt-1 pb-1" key={i}>
                                   <Col className="noPadding" xl={12}>
                                        <span className="info">{item.name}</span>
                                   </Col>
                                   <Col className="noPadding" xl={12}>
                                        <span>{item.value}</span>
                                   </Col>
                              </Row>
                         ))}
                    </Col>
                    <Col lg={6}>
                         {input_finance.map((item, i) => (
                              <Row>
                                   <Field {...item} />
                              </Row>
                         ))}
                    </Col>
               </Row>
          );
          const selectReciept = (
               <Row className="noMargin">
                    <Col xl={12}>
                         <p>ใบรับเงินชั่วคราว</p>
                    </Col>
                    <Form className="m-2 ml-4 mr-4">
                         {reciept_list.map((item, i) => (
                              <Col xl={12} key={i}>
                                   <Form.Check type="checkbox" label={item} />
                              </Col>
                         ))}
                    </Form>
               </Row>
          );
          const table = {
               column: [
                    {
                         dataField: "preemptionp_id",
                         text: "เลขที่ใบจอง",
                         headerClasses: "header-custom __left",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "date",
                         text: "วันที่จอง",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "serie",
                         text: "Serie",
                    },
                    {
                         dataField: "model",
                         text: "Model",
                    },
                    {
                         dataField: "color",
                         text: "สี",
                    },
                    {
                         dataField: "customer_name",
                         text: "ชื่อลูกค้า",
                    },
                    {
                         dataField: "seller_name",
                         text: "พนักงานขาย",
                         headerClasses: "header-custom __btn",
                    },
                    {
                         dataField: "status",
                         text: "สถานะใบจอง",
                         headerClasses: "header-custom __right",
                    },
               ],
               data: [
                    {
                         order_id: order?.order_id,
                         preemptionp_id: order?.order_code_id,
                         date: order?.created_at,
                         serie: order?.series_name,
                         model: order?.model_name,
                         color: order?.color_name,
                         customer_name: order?.userinfo_name,
                         seller_name: order?.saler_name_string,
                         status: order?.acept_status,
                    },
               ],
               keyField: "preemtionp_id",
          };
          let PRBreceipt = order.receipt && order.receipt.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่า พ.ร.บ.");
          let insuranceReceipt = order.receipt && order.receipt.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย");
          let pledgeRegister = order.receipt && order.receipt.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน");
          let taxACCReceipt = order.receipt && order.receipt.find((el) => el.receipt_method === "ใบเสร็จรับเงิน / ใบกำกับภาษีค่าอุปกรณ์ตกแต่ง");
          let financeReceipt = order.receipt && order.receipt.find((el) => el.receipt_method === "ใบรับเงินชั่วคราวค่าไฟแนนซ์");
          setReceip &&
               setReceip.map((element) => {
                    if (
                         element.receipt_method === "ใบรับเงินชั่วคราวค่า พ.ร.บ." ||
                         element.receipt_method === "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย" ||
                         element.receipt_method === "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน" ||
                         element.receipt_method === "ใบรับเงินชั่วคราวค่าไฟแนนซ์" ||
                         element.receipt_method === "ใบเสร็จรับเงิน / ใบกำกับภาษีค่าอุปกรณ์ตกแต่ง"
                    ) {
                         setReceip.splice(
                              setReceip.findIndex((ele) => ele.receipt_method === element.receipt_method),
                              1
                         );
                    }
               });

          if (order && order.receipt && order.receipt.length > 0 && order.receipt.some((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ")) {
               order.receipt.splice(
                    order.receipt.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ"),
                    1
               );
          }
          const table_report = {
               column: [
                    {
                         dataField: "id",
                         text: "ลำดับ",
                         formatter: this.noFormatter,
                         headerClasses: "header-custom __left __no",
                    },
                    {
                         dataField: "receipt_method",
                         text: "รายงาน",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "action",
                         text: "ต้นฉบับ",
                         headerClasses: "header-custom",
                         formatter: this.detailFormatter,
                         formatExtraData: "action",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "copy",
                         text: "สำเนา",
                         headerClasses: "header-custom __btn-ss",
                         formatter: this.detailFormatter,
                         formatExtraData: "copy",
                    },
                    {
                         dataField: "copy2",
                         text: "",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                         formatExtraData: "copy2",
                    },
               ],
               // data: document,
               data: [
                    {
                         active: doc_active?.a1,
                         receipt_method: "ใบรับจองรถยนต์",
                         file: "/order/order_leadsing_pdf",
                         type: "current",
                    },
                    {
                         active: doc_active?.a1,
                         receipt_method: "สัญญาจองรถยนต์ (สคบ.)",
                         file: "/order/reservation_pdf",
                         copy: true,
                         // copy2: true,
                         type: "current",
                    },
                    // {
                    //      active: doc_active?.a2,
                    //      receipt_method: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต",
                    //      file: "/order/order_margin_pdf",
                    //      type: "current",
                    // },
                    {
                         active: doc_active?.a3,
                         // receipt_method: "ใบกำกับภาษีเงินจอง",
                         receipt_method: "ใบเสร็จรับเงิน / ใบกำกับภาษีค่าจอง",
                         file: "/order/order_payment_tax_pdf",
                         type: "current",
                         copy: true,
                    },

                    // {
                    //      active: doc_active?.a6,
                    //      receipt_method: 'ใบลดหนี้ / ใบกำกับภาษีค่าจอง',
                    //      file: '/order/reduce_tax_pdf',
                    //      type: 'current',
                    //      copy: true,
                    // },
                    {
                         active: doc_active?.a4,
                         receipt_method: "ใบยืนยันลีสซิ่ง",
                         file: "/order/get_payment_leasing_pdf",
                         type: "current",
                    },
                    // {
                    //      active: doc_active?.b1,
                    //      receipt_method: "ใบรับเงินชั่วคราวค่า พ.ร.บ.",
                    //      receipt_code: PRBreceipt && PRBreceipt.receipt_code,
                    //      receipt_price: PRBreceipt && PRBreceipt.receipt_price,
                    //      receipt_id: PRBreceipt && PRBreceipt.receipt_id,
                    //      file: "",
                    //      type: PRBreceipt && PRBreceipt.type,
                    // },
                    // {
                    //      active: doc_active.b2,
                    //      receipt_method: "ใบรับเงินชั่วคราวค่าเบี้ยประกันภัย",
                    //      receipt_code: insuranceReceipt && insuranceReceipt.receipt_code,
                    //      receipt_price: insuranceReceipt && insuranceReceipt.receipt_price,
                    //      receipt_id: insuranceReceipt && insuranceReceipt.receipt_id,
                    //      file: "",
                    //      type: insuranceReceipt && insuranceReceipt.type,
                    // },
                    {
                         active: doc_active?.a12,
                         receipt_method: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                         file: "/order/tempory_register_pdf",
                         type: "current",
                    },
                    {
                         active: doc_active?.a11,
                         receipt_method: "ใบเสร็จรับเงินชั่วคราวค่ามัดจำป้ายแดง",
                         file: "/order/tempory_label_pdf",
                         type: "current",
                    },
                    {
                         active: doc_active.b3,
                         receipt_method: "ใบรับเงินชั่วคราวค่ามัดจำชุดจดทะเบียน",
                         receipt_code: pledgeRegister && pledgeRegister.receipt_code,
                         receipt_price: pledgeRegister && pledgeRegister.receipt_price,
                         receipt_id: pledgeRegister && pledgeRegister.receipt_id,
                         file: (pledgeRegister && pledgeRegister.type) || "",
                         type: pledgeRegister && pledgeRegister.type,
                    },
                    {
                         active: doc_active.b4,
                         receipt_method: "ใบเสร็จรับเงิน / ใบกำกับภาษีค่าอุปกรณ์ตกแต่ง",
                         receipt_code: taxACCReceipt && taxACCReceipt.receipt_code,
                         receipt_price: taxACCReceipt && taxACCReceipt.receipt_price,
                         receipt_id: taxACCReceipt && taxACCReceipt.receipt_id,
                         file: (taxACCReceipt && taxACCReceipt.type) || "",
                         copy: true,
                         type: taxACCReceipt && taxACCReceipt.type,
                    },
                    {
                         active: doc_active.b5,
                         receipt_method: "ใบรับเงินชั่วคราวค่าไฟแนนซ์",
                         receipt_code: financeReceipt && financeReceipt.receipt_code,
                         receipt_price: financeReceipt && financeReceipt.receipt_price,
                         receipt_id: financeReceipt && financeReceipt.receipt_id,
                         file: (financeReceipt && financeReceipt.type) || "",
                         type: financeReceipt && financeReceipt.type,
                    },
                    {
                         file: order?.pay_choice === "เงินสด" ? "/order/tax_pdf" : "/order/tax_leasing_pdf",
                         // file: "/order/tax_pdf",
                         receipt_method: "ใบแจ้งหนี้ / ใบกำกับภาษีค่ารถยนต์",
                         type: "current",
                         active: doc_active.a15,
                         copy: true,
                    },
                    {
                         active: doc_active?.a7,
                         receipt_method: "ใบส่งมอบรถยนต์",
                         file: "/order/delivery_pdf",
                         type: "current",
                    },
                    {
                         active: doc_active?.a9,
                         receipt_method: "ใบอนุญาตนำรถออก",
                         file: "/order/car_out_pdf",
                         type: "current",
                    },

                    {
                         active: doc_active?.a10,
                         receipt_method: "ใบเสร็จรับเงินค่ารถยนต์",
                         file: order?.pay_choice === "เงินสด" ? "/order/car_pay_pdf" : "/order/car_pay_leasing_pdf",
                         copy: true,
                         type: "current",
                    },

                    {
                         file: "/order/reduce_tax_register_pdf",
                         receipt_method: "ใบคืนเงินค่ามัดจำชุดทะเบียน",
                         active: doc_active.a18,
                         type: "current",
                    },
                    {
                         file: "/order/reduce_tax_label_pdf",
                         receipt_method: "ใบคืนเงินค่ามัดจำป้ายแดง",
                         active: doc_active?.a17,
                         type: "current",
                    },
                    {
                         active: doc_active?.a13,
                         receipt_method: "สรุปรายการรับเงินวันส่งมอบรถ",
                         file: "/order/summary_delivery_car",
                         type: "current",
                    },
                    // {
                    //      active: doc_active?.a5,
                    //      receipt_method: "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์",
                    //      file: "/order/commission_finance_pdf",
                    //      type: "current",
                    // },
                    // {
                    //      active: doc_active?.a8,
                    //      // receipt_method: "ใบกำกับภาษีค่ารถ",
                    //      receipt_method: "ใบกำกับภาษี / ใบแจ้งหนี้ ค่ารถ",
                    //      file: "/order/car_pay_pdf",
                    //      copy: true,
                    //      type: "current",
                    // },

                    // {
                    //      active: doc_active?.a16,
                    //      receipt_method: "ใบกำกับภาษีเทิร์นสินค้า",
                    //      file: "/order/turn_tax_pdf",
                    //      type: "current",
                    // },
                    // ...(order.receipt ? order.receipt : []).map((el) => ({
                    //      active: el.active,
                    //      receipt_method: el.receipt_method,
                    //      type: el.type,
                    //      receipt_code: el.receipt_code,
                    //      receipt_price: el.receipt_price,
                    //      receipt_id: el.receipt_id,
                    //      file: el?.file || "",
                    // })),
               ],
               data_two: [
                    ...(order.receipt ? order.receipt : []).map((el) => ({
                         active: true,
                         receipt_method: el.receipt_method,
                         type: "receipt",
                         receipt_code: el.receipt_code,
                         receipt_price: el.receipt_price,
                         receipt_id: el.receipt_id,
                         file: el?.file || "",
                         name_pay: el.name_pay || "",
                    })),
               ],
               keyField: "id",
          };
          // if (order.register !== "Yes") {
          //      table_report.data.splice(8, 1);
          // }
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `แบบฟอร์มสำหรับส่งมอบ`,
                                   onClick: () => window.location.assign("/admin/payment/form"),
                              },
                              {
                                   title: `ใบจองรถยนต์ (${order?.order_code_id})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order?.order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content __auto noMargin" id="table-content">
                                        <Table type={"summary-no-padding"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {this.state.spinner && (
                                             <Spinner
                                                  variant="primary"
                                                  size="md"
                                                  style={{ position: "fixed", top: "50%", left: "50%" }}
                                                  animation="border"
                                                  role="status"
                                             >
                                                  <span className="sr-only">Loading...</span>
                                             </Spinner>
                                        )}
                                        <LabelBar type={"title"} title={"เอกสารทั่วไป"} />
                                        <div className="content" id="table-content">
                                             <Table
                                                  type={"custom"}
                                                  column={table_report.column}
                                                  data={table_report.data}
                                                  keyField={table_report.keyField}
                                             />
                                        </div>
                                        <LabelBar type={"title"} title={"รายละเอียดเงินรับชั่วคราว"} />
                                        <div className="content" id="table-content">
                                             <Table
                                                  type={"custom"}
                                                  column={table_report.column}
                                                  data={table_report.data_two}
                                                  keyField={table_report.keyField}
                                             />
                                        </div>
                                        {showInput && (
                                             <ModalPanel
                                                  type="input"
                                                  show={showInput}
                                                  title={"การกรอกค่าคอมไฟแนนซ์"}
                                                  onHide={this.onOpenInput}
                                                  body={inputFinance}
                                                  button={[{ name: "Submit", variant: "primary", onClick: () => alert("yes") }]}
                                             />
                                        )}
                                        {showSelect && (
                                             <ModalPanel
                                                  type="input"
                                                  show={showSelect}
                                                  title={"การกรอกค่าคอมไฟแนนซ์"}
                                                  onHide={this.onOpenSelect}
                                                  body={selectReciept}
                                                  button={[{ name: "Submit", variant: "primary", onClick: () => alert("yes") }]}
                                             />
                                        )}

                                        <ModalPanel
                                             show={this.state.modal_select}
                                             title={"เลือกประเภทใบกำกับภาษี"}
                                             type={"input"}
                                             body={this.modalSelectSupplier()}
                                             onHide={() => this.setState({ modal_select: false })}
                                             button={[{ onClick: this.openLoadPdfTurn, name: "ตกลง", variant: "primary" }]}
                                        />

                                        <ModalPanel
                                             show={this.state.modal_acc_turn}
                                             title={"เลือก Supplier สำหรับอุปกรณ์ตกแต่ง"}
                                             type={"input"}
                                             body={this.modalViewSelectSupplier()}
                                             onHide={() => this.setState({ modal_acc_turn: false })}
                                             button={[{ onClick: this.openSupPDF, name: "ตกลง", variant: "primary" }]}
                                        />
                                   </div>
                              </div>
                         </div>
                         <br />
                    </div>
               </div>
          );
     }
}
