import { Breadcrumbs, LabelBar, FormTable, Field, Table, ModalPanel, Skeleton } from "components/index";
import React, { Component } from "react";
import { Row, Button, Col, Form, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { POST, ip } from "api/index";
import dayjs from "dayjs";
import axios from "axios";
import printJS from "print-js";
import { getToken } from "static/function";

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class generateLeasing extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               showmodal_sign: false,
               disabled: false,
               order: {
                    addAccbonus: [],
                    addAccbuy: [],
                    addAccturn: [],
                    addReceipt: [],
                    promotions: [],
                    addPromotions: [],
                    deletePromotion: [],
                    accbonus: [],
                    deleteAccbonus: [],
                    accbuy: [],
                    deleteAccbuy: [],
                    accturn: [],
                    deleteAccturn: [],
                    receipt: [],
                    deletereceipt: [],
               },
               issue: {
                    order_id: this.props.match.params.id,
               },
               rowIndex: 0,
               disabled: false,
               receiptData: [
                    {
                         receipt_method: "ใบเสร็จรับเงินค่ารถ",
                         receipt_price: 0,
                         check: true,
                    },
                    {
                         receipt_method: "อื่น ๆ",
                         receipt_price: 0,
                         check: true,
                    },
                    {
                         receipt_method: "อื่น ๆ",
                         receipt_price: 0,
                         check: true,
                    },
                    {
                         receipt_method: "อื่น ๆ",
                         receipt_price: 0,
                         check: true,
                    },
                    {
                         receipt_method: "อื่น ๆ",
                         receipt_price: 0,
                         check: true,
                    },
                    // {
                    //      receipt_method: 'ใบรับเงินค่าคอมไฟแนนซ์',
                    //      receipt_price: 0,
                    //      check: true,
                    // },
               ],
               recieptPay: [
                    {
                         receipt_method: "ใบเสร็จรับเงินค่ารถ",
                         receipt_price: 0,
                         check: true,
                    },
               ],
               modalreceiptData: [],
               slectPay: [
                    {
                         payment_method: "เงินสด",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "เงินโอน",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "เช็ค",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "บัตรเครดิต",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         payment_method: "รับชำระโดย อื่น ๆ",
                         detail: "",
                         price: 0,
                         file: "",
                         orderleasingPdf: "",
                    },
               ],
               slectPay2: [
                    {
                         payment_method: "เงินสด",
                         detail: "",
                         price: 0,
                         file: "",
                         page: "รับเงินจากไฟแนนซ์",
                    },
                    {
                         payment_method: "เงินโอน",
                         detail: "",
                         price: 0,
                         file: "",
                         page: "รับเงินจากไฟแนนซ์",
                    },
                    {
                         payment_method: "เช็ค",
                         detail: "",
                         price: 0,
                         file: "",
                         page: "รับเงินจากไฟแนนซ์",
                    },
                    {
                         payment_method: "บัตรเครดิต",
                         detail: "",
                         price: 0,
                         file: "",
                         page: "รับเงินจากไฟแนนซ์",
                    },
                    {
                         payment_method: "รับชำระโดย อื่น ๆ",
                         detail: "",
                         price: 0,
                         file: "",
                         orderleasingPdf: "",
                         page: "รับเงินจากไฟแนนซ์",
                    },
               ],
               openModal: false,
               placeholder: {
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    n0: "หลักฐานการชำระเงิน",
                    n1: "หลักฐานการชำระเงิน",
                    n2: "หลักฐานการชำระเงิน",
                    n3: "หลักฐานการชำระเงิน",
                    n4: "หลักฐานการชำระเงิน",
               },
               placeholder2: {
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    n0: "หลักฐานการชำระเงิน",
                    n1: "หลักฐานการชำระเงิน",
                    n2: "หลักฐานการชำระเงิน",
                    n3: "หลักฐานการชำระเงิน",
                    n4: "หลักฐานการชำระเงิน",
               },
               row: {},
               showmodal_file: false,
               total: 0,
               total2: 0,
               insu_placeholder: "เลือกไฟล์",
               insu: {},
               insurance_file_path: false,
               loading: false,
               loadingPayment: false,
               save_loading: false,
               payed: false,
          };
     }

     componentDidMount = async () => {
          this.initial();
          await this.getOrder();
          this.getLogPayment();
          this.calMargin();
          this.setState({ role: getToken().position_id });
     };

     initial = () => {
          let { payment_status, disabled } = this.state;

          if (payment_status === "ชำระเงินแล้ว") {
               disabled = true;

               this.setState({
                    disabled,
               });
          }
     };

     getOrder = async () => {
          let { receiptData, slectPay, slectPay2, issue, recieptPay } = this.state;
          try {
               this.setState({ loading: true });
               let result = await POST("/order/this", { order_id: this.state.id });
               let accbuy_price = 0;
               // result.accturn = [];
               // result.promotions = [];
               // result.deleteAccbonus = [];
               // result.deleteAccbuy = [];
               // result.deletePromotion = [];
               // result.deleteAccturn = [];
               // result.deletereceipt = [];
               // result.insurance_pay = result.insurance_pay === 'ลูกค้า' ? 'ซื้อ' : 'แถม';
               // result.finace_need = Number(result?.start_price || 0) - Number(result?.down || 0);
               recieptPay[0].receipt_price = result.real_price;
               receiptData.splice(
                    receiptData.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ"),
                    1
               );
               slectPay2[0].price = result.real_price;
               let totalPriceSum = result.real_price;
               result.finace_need = result.real_price;
               // result.receipt.map((el) => {
               //      if (el.receipt_method === "ใบเสร็จรับเงินค่ารถ") {
               //           totalPriceSum = 0;
               //           slectPay2[0].price = 0;
               //           result.finace_need = 0;
               //           recieptPay.splice(
               //                recieptPay.findIndex((ele) => ele.receipt_method === "ใบเสร็จรับเงินค่ารถ"),
               //                1
               //           );
               //           recieptPay.push(el);
               //      } else if (el.receipt_method === "ใบรับเงินค่าคอมไฟแนนซ์") {
               //           recieptPay.push(el);
               //           receiptData.splice(
               //                receiptData.findIndex((ele) => ele.receipt_method === "ใบรับเงินค่าคอมไฟแนนซ์"),
               //                1
               //           );
               //      }
               // });
               // result.receipt.forEach((element) => {
               //      let index = receiptData
               //           .map(function (e) {
               //                return e.receipt_method;
               //           })
               //           .indexOf(element.receipt_method);
               //      receiptData.splice(index, 1);
               // });

               result.accbuy.forEach((ele) => {
                    accbuy_price += ele.order_ac_amount * Number(JSON.parse(ele.price_list).price);
               });

               result.addition_acc_price = accbuy_price;

               //Cal Margin

               let accbonus_price = 0;
               let margin = 0;

               let discount_price = result?.discount_price ? result.discount_price : 0;
               let insurance_premium = result.insurance === "Yes" && result.insurance_pay === "บริษัท" ? Number(result?.insurance_premium) : 0;
               let act_premium = result.act === "Yes" && result.act_pay === "บริษัท" ? Number(result?.act_premium) : 0;
               let register_price = result.register === "Yes" && result.register_pay === "บริษัท" ? Number(result?.register_price) : 0;
               let recommend_price = result?.recommend_price ? Number(result.recommend_price) : 0;
               let other_pay_price = result?.other_pay_price ? Number(result.other_pay_price) : 0;

               result.accbonus.forEach((ele) => {
                    accbonus_price += ele.order_ac_amount * JSON.parse(ele.price_list).price_add_old;
               });

               margin = accbonus_price + discount_price + insurance_premium + act_premium + register_price + recommend_price + other_pay_price;

               let sum =
                    Number(result.start_price ? result.start_price : 0) +
                    Number(result?.addition_price || 0) +
                    Number(result.down ? result.down : 0) +
                    Number(result.insurance_premium ? result.insurance_premium : 0) +
                    Number(result.act_premium ? result.act_premium : 0) +
                    Number(result.register_price ? result.register_price : 0) +
                    Number(result.labelhis_price ? result.labelhis_price : 0) +
                    Number(result.first_pay_price ? result.first_pay_price : 0) +
                    Number(result.addition_acc_price ? result.addition_acc_price : 0) +
                    Number(result.finance ? result.finance : 0) +
                    Number(result.etc1_price ? result.etc1_price : 0) +
                    Number(result.etc2_price ? result.etc2_price : 0) +
                    Number(result.etc3_price ? result.etc3_price : 0) +
                    Number(result.etc4_price ? result.etc4_price : 0) +
                    Number(result.cut_order_price ? result.cut_order_price : 0) +
                    Number(result?.customer_pay || 0);

               if (result.pay_choice === "ผ่อนชำระ") {
                    sum = sum - Number(result.start_price ? result.start_price : 0);
                    if (!result.orcp_leasing_payment_method && !result.orcp_payment_method) {
                         result.receipt.push({
                              receipt_method: "ใบแจ้งหนี้ / ใบกำกับภาษี",
                              receipt_price:
                                   Number(result?.start_price || 0) - Number(result?.discount_price || 0) + Number(result?.addition_price || 0),
                              check: true,
                              code: "OK",
                              file: "",
                         });
                    }
               }

               // slectPay.map((el, i) => {
               //      slectPay[0].price = sum;
               //      slectPay[i].check = false;
               // });

               // slectPay2.map((el, i) => {
               //      slectPay2[0].price = Number(result.start_price) - Number(result.down);
               //      slectPay2[i].check = false;
               // });

               let detailPayment = [];
               let total = 0;
               let detailPayment2 = [];
               let total2 = 0;

               if (result.orcp_payment_method) {
                    for (let e of JSON.parse(result.orcp_payment_method)) {
                         if (e.check) {
                              detailPayment.push(e);
                              total += Number(e.price);
                         }
                    }
               }

               if (result.orcp_leasing_payment_method) {
                    for (let e of JSON.parse(result.orcp_leasing_payment_method)) {
                         if (e.check) {
                              detailPayment2.push(e);
                              total2 += Number(e.price);
                         }
                    }

                    let pay = JSON.parse(result.orcp_leasing_payment_method);
                    let a = pay.filter((el) => el.check === true);
                    let p = 0;
                    for (let i in a) {
                         p += Number(a[i].price);
                    }

                    result.receipt.push({
                         receipt_method: "ใบเสร็จรับเงิน",
                         receipt_price: Number(result?.start_price || 0) - Number(result?.discount_price || 0) + Number(result?.addition_price || 0),
                         check: true,
                         code: "KO",
                         file: "/car_pay_leasing_pdf",
                    });
               }

               // if (!result.orcp_leasing_payment_method) {
               //      issue.orcp_leasing_date = dayjs(new Date());
               // }

               this.setState({
                    order: result,
                    receiptData,
                    disabled: true,
                    slectPay,
                    detailPayment,
                    total: total,
                    slectPay2,
                    detailPayment2,
                    total2: total2,
                    issue,
                    loading: false,
                    totalPriceSum,
                    recieptPay,
               });
          } catch (error) {
               console.log(error);
          }
     };

     getLogPayment = async () => {
          const { order } = this.state;
          try {
               let res = await POST("/order/log_car_payment", { order_id: order.order_id });
               let newRes = res.map((element) => {
                    let obj = {
                         ...element,
                         orcp_payment_method: element.orcp_payment_method
                              ? JSON.parse(element.orcp_payment_method)
                                     .filter((el) => el.check === true)
                                     .filter((el) => el.page === "รับเงินจากไฟแนนซ์")
                              : [],
                    };
                    return obj;
               });
               // console.log("newRes", newRes);
               if (newRes.length > 0) {
                    await this.setState({ payment_log: newRes, payed: newRes[0].orcp_payment_method && newRes[0].orcp_payment_method.length > 0 });
               }
          } catch (error) {
               console.log(`error`, error);
          }
     };

     getOrderLeasingPdf = async () => {
          try {
               await axios
                    .post(
                         `${ip}/order/order_leadsing_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         window.open(fileURL);
                    });
          } catch (error) {}
     };

     getOrderCarPayPdf = async (type) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/car_pay_pdf`,
                         { order_id: this.state.id, type },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {}
     };

     calMargin = () => {
          let { order } = this.state;
          let accbonus_price = 0;
          let margin = 0;

          let discount_price = order?.discount_price ? order.discount_price : 0;
          let insurance_premium = order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0;
          let act_premium = order.act === "Yes" && order.act_pay === "บริษัท" ? Number(order?.act_premium) : 0;
          let register_price = order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0;
          let recommend_price = order?.recommend_price ? Number(order.recommend_price) : 0;
          let other_pay_price = order?.other_pay_price ? Number(order.other_pay_price) : 0;

          order.accbonus.forEach((ele) => {
               accbonus_price += ele.order_ac_amount * JSON.parse(ele.price_list).price_add_old;
          });

          margin = accbonus_price + discount_price + insurance_premium + act_premium + register_price + recommend_price + other_pay_price;

          this.setState({ margin: margin });
     };

     viewFile = (row) => {
          this.setState({ showmodal_file: true, row: row });
     };

     sumPrice = () => {
          let { order } = this.state;
          let sum = Number(order.real_price);
          let final = sum;
          return `${final.toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          })}`;
     };

     /* ------------------------ onClick ----------------------------- */
     onOpenModal = () => {
          let { openModal } = this.state;
          this.setState({ openModal: !openModal });
     };

     onAddReceipt = async () => {
          let { modalreceiptData, order, receiptData, recieptPay, totalPriceSum, slectPay2 } = this.state;
          let id = await POST("/aos/create_receipt", {
               data: {
                    order_id: order.order_id,
                    receipt_method: modalreceiptData[0].receipt_method,
                    receipt_price: modalreceiptData[0].receipt_price,
               },
          });
          receiptData.map((element) => {
               if (modalreceiptData.find((e) => e.receipt_method === element.receipt_method)) {
                    order.receipt.unshift({ ...element, check: true, receipt_id: id });
                    recieptPay.unshift({ ...element, check: true, receipt_id: id });
                    totalPriceSum += element.receipt_price;
                    order.finace_need = totalPriceSum;
               }
          });
          modalreceiptData.forEach((element) => {
               let index = receiptData
                    .map(function (e) {
                         return e.receipt_method;
                    })
                    .indexOf(element.receipt_method);
               receiptData.splice(index, 1);
          });
          slectPay2[0].price = totalPriceSum;
          this.setState({ order, openModal: false, modalreceiptData: [], totalPriceSum, slectPay2 }, () => this.totalPay2());
     };

     onChangeCheckboxAddReceipt = async (checked, row) => {
          let { modalreceiptData } = this.state;
          if (checked) {
               modalreceiptData.push(row);
          } else {
               modalreceiptData.splice(
                    modalreceiptData.findIndex((el) => el.receipt_method === row.receipt_method),
                    1
               );
          }
          this.setState({ modalreceiptData });
     };

     showPdf = async (row) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order/template_pdf`,
                         {
                              order_id: this.state.id,
                              namepayment: row.receipt_method,
                              pricePayment: row.receipt_price,
                              code: row.receipt_code,
                              branch_id: this.state.order.branch_id,
                              name_pay: row.name_pay,
                         },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {}
     };
     /* ------------------------ onChange ----------------------------- */
     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          let { issue, order, slectPay, file } = this.state;
          issue.order_id = order.order_id;
          issue.oder_turnpay_status = "ออกใบกำกับภาษีแล้ว";
          if (checked) {
               issue.order_id = order.order_id;
               issue.orcp_payment_method = row.payment_method;
               issue.orcp_detail = slectPay[rowIndex].detail;
               issue.orcp_price = Number(slectPay[rowIndex].price);
               file = slectPay[rowIndex].file;
               this.setState({ issue, rowIndex: rowIndex, file });
          }
     };

     onChangeCheckboxPay = (checked, row, rowIndex) => {
          let { order, slectPay } = this.state;
          ///check
          if (checked) {
               slectPay[rowIndex].check = true;
               this.setState({ order, rowIndex: rowIndex, slectPay });
          } else {
               slectPay[rowIndex].check = false;
               this.setState({ slectPay });
          }
     };

     onChangeCheckboxPay2 = (checked, row, rowIndex) => {
          let { order, slectPay2 } = this.state;

          if (checked) {
               slectPay2[rowIndex].check = true;
               this.setState({ order, rowIndex2: rowIndex, slectPay2 });
          } else {
               slectPay2[rowIndex].check = false;
               this.setState({ slectPay2 });
          }
     };

     onChangeModalPrice = (e, rowIndex) => {
          let { receiptData } = this.state;
          receiptData[rowIndex].receipt_price = e.floatValue;
          this.setState({ receiptData });
     };
     /* ------------------------ handle ----------------------------- */
     handleSubmit = () => {
          let { id } = this.state;

          swal({
               text: "รับเงินค่ารถเรียบร้อยแล้ว",
               icon: "success",
               buttons: {
                    submit: "เสร็จสิ้น",
               },
          }).then((value) => {
               window.location.assign(`/admin/payment/car`);
          });
     };

     onSubmit2 = async () => {
          let { id, show_popup, issue, file, order, slectPay2, slectPay, loadingPayment } = this.state;
          // console.log(`slectPay2`, slectPay2, slectPay);
          let addReceipt = [],
               receipt = [];
          order.receipt.map((el) => {
               if (!el.receipt_id) {
                    addReceipt.push(el);
               } else if (el.receipt_id) {
                    receipt.push(el);
               }
          });

          order.receipt = [...receipt];
          order.addReceipt = [...addReceipt];
          order.addAccbonus = [];
          order.addAccbuy = [];
          order.addAccturn = [];
          order.insurance_pay = order.insurance_pay === "ซื้อ" ? "ลูกค้า" : "บริษัท";

          let check_status2 = slectPay2.find((el) => el.check === true);
          let price_status2 = !slectPay2.find((el) => el.check === true && (el.price === 0 || el.price === ""));
          issue.branch_id = order.branch_id;
          issue.receiptData = this.state.recieptPay;
          let setDate = issue.orcp_date ? dayjs(issue.orcp_date).format("YYYY-MM-DD") : dayjs(new Date()).format("YYYY-MM-DD");
          issue.orcp_date = setDate;
          issue.orcp_payment_method = JSON.stringify(slectPay2);
          // console.log(`issue.orcp_payment_method`, issue.orcp_payment_method);
          try {
               if (check_status2) {
                    if (price_status2) {
                         if (slectPay2[1].check && slectPay2[1].file === "") {
                              swal({
                                   text: "กรณีเงินโอน กรุณาแนบหลักฐานการชำระเงิน",
                                   icon: "warning",
                                   button: "ตกลง",
                                   dangerMode: true,
                              });
                         } else {
                              swal({
                                   text: "ต้องการบันทึกการรับเงินค่ารถหรือไม่",
                                   icon: "warning",
                                   buttons: {
                                        submit: "ยืนยัน",
                                        cancel: "ไม่ยืนยัน",
                                   },
                              }).then(async (value) => {
                                   if (value === "submit") {
                                        this.setState({ loadingPayment: true, save_loading: true });
                                        await POST("/order/add_other_carpay", { ...issue, path: "leasing" });
                                        let res = await POST("/order/log_car_payment", { order_id: order.order_id });
                                        if (slectPay2[1].file && slectPay2[1].check) {
                                             let formData1 = new FormData();
                                             formData1.append("cp_log_id", res[res.length - 1].cp_log_id);
                                             formData1.append("file", slectPay2[1].file);
                                             formData1.append("index", 1);
                                             formData1.append("pay_choice", order.pay_choice);
                                             await POST("/order/file_other_car_payment", formData1, true);
                                        }
                                        if (slectPay2[3].file && slectPay2[3].check) {
                                             let formData3 = new FormData();
                                             formData3.append("cp_log_id", res[res.length - 1].cp_log_id);
                                             formData3.append("file", slectPay2[3].file);
                                             formData3.append("index", 3);
                                             formData3.append("pay_choice", order.pay_choice);
                                             await POST("/order/file_other_car_payment", formData3, true);
                                        }
                                        if (slectPay2[4].file && slectPay2[4].check) {
                                             let formData4 = new FormData();
                                             formData4.append("cp_log_id", res[res.length - 1].cp_log_id);
                                             formData4.append("file", slectPay2[4].file);
                                             formData4.append("index", 4);
                                             formData4.append("pay_choice", order.pay_choice);
                                             await POST("/order/file_other_car_payment", formData4, true);
                                        }
                                        swal({
                                             text: "รับเงินค่ารถเรียบร้อย",
                                             icon: "success",
                                             button: "ตกลง",
                                        }).then(() => {
                                             this.setState({ loadingPayment: false, save_loading: false });
                                             window.location.reload();
                                        });
                                        //-----------------------------------------------------------------//
                                        // if (submitFirst) {
                                        //      await POST('/order/add_car_payment', issue);
                                        //      if (slectPay2[1].file && slectPay2[1].check) {
                                        //           let formData1 = new FormData();
                                        //           formData1.append('order_id', id);
                                        //           formData1.append('file', slectPay2[1].file);
                                        //           formData1.append('index', 1);
                                        //           await POST('/order/fille_car_payment', formData1, true);
                                        //      }
                                        //      if (slectPay2[3].file && slectPay2[3].check) {
                                        //           let formData3 = new FormData();
                                        //           formData3.append('order_id', id);
                                        //           formData3.append('file', slectPay2[3].file);
                                        //           formData3.append('index', 3);
                                        //           await POST('/order/fille_car_payment', formData3, true);
                                        //      }
                                        //      if (slectPay2[4].file && slectPay2[4].check) {
                                        //           let formData4 = new FormData();
                                        //           formData4.append('order_id', id);
                                        //           formData4.append('file', slectPay2[4].file);
                                        //           formData4.append('index', 4);
                                        //           await POST('/order/fille_car_payment', formData4, true);
                                        //      }
                                        //      this.setState({ loadingPayment: false });
                                        //      swal({
                                        //           text: 'รับเงินค่ารถเรียบร้อย',
                                        //           icon: 'success',
                                        //           button: 'ตกลง',
                                        //      }).then(() => {
                                        //           window.location.reload();
                                        //      });
                                        // } else {
                                        // }
                                   } else return null;
                              });
                         }
                    } else {
                         swal({
                              text: "กรุณากรอกจำนวนเงินให้ครบ",
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    }
               } else {
                    swal({
                         text: "กรุณาเลือกวิธีชำระเงิน",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } catch (error) {
               this.setState({ loadingPayment: false, save_loading: false });
               // console.log("error", error);
               swal({
                    text: "กรุณาทำรายการใหม่ในภายหลัง",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     showPdfLeasing = async (name) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}/order${name === "ใบแจ้งหนี้ / ใบกำกับภาษี" ? "/tax_leasing_pdf" : "/car_pay_leasing_pdf"}`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {
               console.log("error", error);
          }
     };

     onSaveInsurance = async () => {
          let { insu, order } = this.state;
          let formData = new FormData();
          try {
               if (insu.file && order.insurance_recive_no) {
                    formData.append("order_id", this.state.id);
                    formData.append("file", insu.file);
                    formData.append("insurance_recive_no", order.insurance_recive_no);

                    await POST("/order/edit_insurance", formData, true);

                    swal({
                         text: "บันทึกข้อมูลประกันภัยแล้ว",
                         icon: "success",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    });
               } else {
                    swal({
                         text: "กรอกให้ครบ",
                         icon: "warning",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
               this.getOrder();
          } catch (error) {
               console.log(`error`, error);
          }
     };

     handleChangeFileInsurance = (e) => {
          let { insu } = this.state;
          if (e.target.files[0]) {
               let file = e.target.files[0];
               insu.file = file;
               this.setState({
                    insu,
                    insu_placeholder: file.name,
               });
          }
     };
     handleChangeTextInsurance = (e) => {
          let { insu, order } = this.state;
          insu[e.target.name] = e.target.value;
          order[e.target.name] = e.target.value;
          this.setState({ insu, order });
     };

     handleChangeCheck = ({ target: { name, checked } }, title) => {
          let { order } = this.state;
          if (checked) {
               order[title] = name;
          }
          this.setState({ order });
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { order } = this.state;

          order[name] = value;

          this.setState({ order });
     };

     handleChangeNumber = (e, name) => {
          let { order } = this.state;
          let value = e.value;
          order[name] = value;
          this.setState({ order });
     };

     onChangeNumber = (e, name) => {
          let { order } = this.state;
          order[name] = e.floatValue;
          if (name === "down") {
               order.finace_need = Number(order?.start_price || 0) - Number(order?.down || 0);
          }

          if (name === "finace_need") {
          }
          this.setState({ order });
     };

     handleChangeNumberPay = (value, rowIndex) => {
          let { slectPay, order } = this.state;
          slectPay[rowIndex].price = value.value;
          this.setState({ slectPay });
     };
     handleChangeNumberPay2 = (value, rowIndex) => {
          let { slectPay2, order } = this.state;
          slectPay2[rowIndex].price = value.value;
          this.setState({ slectPay2 });
     };

     onRemoveReceipt = (row, rowIndex) => {
          swal({
               text: "ต้องการลบรายการนี้หรือไม่",
               icon: "warning",
               buttons: {
                    submit: "ใช่",
                    cancel: "ไม่",
               },
          }).then(async (value) => {
               if (value === "submit") {
                    let { order, receiptData, totalPriceSum, slectPay2, recieptPay } = this.state;
                    receiptData.push({ receipt_method: row.receipt_method, receipt_price: 0, check: false });
                    // order.deletereceipt.push(row.receipt_id);
                    recieptPay.splice(rowIndex, 1);
                    totalPriceSum -= row.receipt_price;
                    order.finace_need = totalPriceSum;
                    slectPay2[0].price = totalPriceSum;
                    await POST("/aos/del_receipt", { receipt_id: row.receipt_id });
                    this.setState({ order, receiptData, totalPriceSum, slectPay2 }, () => this.totalPay2());
               }
          });
     };
     totalPay = () => {
          let { slectPay, totalPriceSum } = this.state;
          let total = 0;
          for (let i in slectPay) {
               if (slectPay[i].check) total += Number(slectPay[i].price);
          }
          total = (totalPriceSum - total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return total;
     };

     totalPay2 = () => {
          let { slectPay2, totalPriceSum } = this.state;
          let total = 0;
          for (let i in slectPay2) {
               if (slectPay2[i].check) total += Number(slectPay2[i].price);
          }
          total = (totalPriceSum - total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          if (total == "-0.00") {
               total = "0.00";
          }
          return total;
     };

     /* ------------------------ Formatter ---------------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     checkBoxFormatterModal = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ value: rowIndex, name: null }]}
                    handleChangeText={(e) => this.onChangeCheckboxAddReceipt(e.target.checked, row)}
               />
          );
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <LabelBar
                    type={"checkbox"}
                    label={[{ name: "", value: rowIndex }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row)}
               />
          );
     };

     inputFormFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.payment_method === "เช็ค") {
               return <Field type={"text"} name={row.payment_method} placeholder={"เลขที่"} require={true} value={null} />;
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return <Field type={"text"} name={row.payment_method} placeholder={"ระบุ"} require={true} value={null} />;
          } else {
               return <>-</>;
          }
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { receiptData } = this.state;
          return (
               <Field
                    type={"number"}
                    name={receiptData[rowIndex].payment_method}
                    placeholder={"0.00"}
                    require={true}
                    decimalScale={2}
                    value={null}
                    handleChangeNumber={(e) => this.onChangeModalPrice(e, rowIndex)}
               />
          );
     };
     inputFormFormatterOther = (cell, row, rowIndex, formatExtraData) => {
          if (row.receipt_method === "อื่น ๆ") {
               return (
                    <Field
                         id={`field-temp-other-${rowIndex}`}
                         type={"text"}
                         title={row.receipt_method}
                         placeholder={"ระบุ"}
                         value={null}
                         style_id={"__inline__space"}
                         space={{ span: 1, offset: 0 }}
                         handleChangeText={(e) => this.handleChangNameReceipt(e, rowIndex)}
                    />
               );
          } else {
               return <>{row.receipt_method}</>;
          }
     };
     handleChangNameReceipt = (e, rowIndex) => {
          let { receiptData } = this.state;
          receiptData[rowIndex].receipt_method = e.target.value;
          this.setState({ receiptData });
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "preview":
                    if (row.receipt_method === "ใบแจ้งหนี้ / ใบกำกับภาษี" || row.receipt_method === "ใบเสร็จรับเงิน") {
                         return <Button onClick={() => this.showPdfLeasing(row.receipt_method)}>ตัวอย่าง</Button>;
                    } else if (row.receipt_method === "ใบเสร็จรับเงินค่ารถ") {
                         return <Button onClick={() => this.getOrderCarPayPdf("สำเนา")}>พิมพ์</Button>;
                    } else {
                         return <Button onClick={() => this.showPdf(row)}>ตัวอย่าง</Button>;
                    }

               case "print":
                    if (
                         row.receipt_method === "ใบแจ้งหนี้ / ใบกำกับภาษี" ||
                         row.receipt_method === "ใบเสร็จรับเงิน" ||
                         row.receipt_method === "ใบเสร็จรับเงินค่ารถ"
                    ) {
                         return <Button onClick={() => this.showPdfLeasing(row.receipt_method)}>พิมพ์</Button>;
                    } else {
                         return <Button onClick={() => this.showPdf(row)}>พิมพ์</Button>;
                    }
          }
     };

     removeReceipt = (cell, row, rowIndex, formatExtraData) => {
          if (!row.receipt_code) {
               return (
                    <Button onClick={() => this.onRemoveReceipt(row, rowIndex)} variant="danger">
                         Remove
                    </Button>
               );
          } else {
               if (row.receipt_method === "ใบเสร็จรับเงินค่ารถ") {
                    return (
                         <Button onClick={() => this.getOrderCarPayPdf("สำเนา")} disabled={row.receipt_code ? false : false}>
                              พิมพ์
                         </Button>
                    );
               } else {
                    return <Button onClick={() => this.showPdf(row)}>พิมพ์</Button>;
               }
          }
     };

     checkBoxFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          let { order } = this.state;
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: order.orcp_payment_method }]}
                    handleChangeText={(e) => this.onChangeCheckboxPay(e.target.checked, row, rowIndex)}
               />
          );
     };
     checkBoxFormatterPay2 = (cell, row, rowIndex, formatExtraData) => {
          let { order } = this.state;
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: order.orcp_leasing_payment_method }]}
                    handleChangeText={(e) => this.onChangeCheckboxPay2(e.target.checked, row, rowIndex)}
               />
          );
     };

     inputFormFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          if (row.payment_method === "เช็ค") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"เลขที่"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"ระบุ"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          } else {
               return <>-</>;
          }
     };
     inputFormFormatterPay2 = (cell, row, rowIndex, formatExtraData) => {
          if (row.payment_method === "เช็ค") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"เลขที่"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect2(e, row, rowIndex)}
                    />
               );
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"ระบุ"}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect2(e, row, rowIndex)}
                    />
               );
          } else {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         placeholder={"หมายเหตุ"}
                         // require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect2(e, row, rowIndex)}
                    />
               );
          }
     };

     handleChangeTextSelect = (e, row, rowIndex) => {
          let { issue, id, slectPay } = this.state;
          slectPay[rowIndex].detail = e.target.value;
          if (rowIndex === this.state.rowIndex) {
               issue.orcp_detail = slectPay[rowIndex].detail;
          }
          this.setState({ ...slectPay, ...issue });
     };

     handleChangeTextSelect2 = (e, row, rowIndex) => {
          let { issue, id, slectPay2 } = this.state;
          slectPay2[rowIndex].detail = e.target.value;
          // if (rowIndex === this.state.rowIndex) {
          //      issue.orcp_detail = slectPay2[rowIndex].detail;
          // }
          this.setState({ ...slectPay2 });
     };

     inputAmountFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={row.payment_method}
                    placeholder={"0.00"}
                    value={formatExtraData[rowIndex]}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangePrice(e, rowIndex)}
                    require={false}
               />
          );
     };

     inputAmountFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={"orcp_price"}
                    value={row?.price}
                    placeholder={"0.00"}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangeNumberPay(e, rowIndex)}
                    require={false}
               />
          );
     };
     inputAmountFormatterPay2 = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={"orcp_price"}
                    value={row?.price}
                    placeholder={"0.00"}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangeNumberPay2(e, rowIndex)}
                    require={false}
               />
          );
     };

     handleChangePrice = (e, rowIndex) => {
          let { issue, id, slectPay } = this.state;
          slectPay[rowIndex].price = e.floatValue;
          if (rowIndex === this.state.rowIndex) {
               issue.orcp_price = Number(slectPay[rowIndex].price);
          }
     };

     inputFileFormatterPay = (cell, row, rowIndex, formatExtraData) => {
          if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"file"}
                         name={row.payment_method}
                         placeholder={row.file !== "" ? row.file.name : "หลักฐานการชำระเงิน"}
                         require={true}
                         value={null}
                         button={"แนบไฟล์"}
                         style_id={"custom-btn __beside-btn"}
                         handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                    />
               );
          } else if (row.payment_method === "เช็ค") {
               return (
                    <span className="warning __info">ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว</span>
               );
          } else {
               return <></>;
          }
     };

     inputFileFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { placeholder } = this.state;

          switch (row.payment_method) {
               case "เงินโอน":
                    if (!formatExtraData[1] === formatExtraData[1]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n1}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "บัตรเครดิต":
                    if (!formatExtraData[3] === formatExtraData[3]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n3}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "รับชำระโดย อื่น ๆ":
                    if (!formatExtraData[4] === formatExtraData[4]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n4}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               default:
                    if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                         return (
                              <Field
                                   type={"file"}
                                   // name={row.payment_method}
                                   placeholder={"หลักฐานการชำระเงิน"}
                                   value={null}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __beside-btn"}
                                   handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                   accept={".png,.jpg,.pdf"}
                              />
                         );
                    } else if (row.payment_method === "เช็ค") {
                         return (
                              <span className="warning __info">
                                   ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                              </span>
                         );
                    } else {
                         return <></>;
                    }
          }
     };
     inputFileFormatter2 = (cell, row, rowIndex, formatExtraData) => {
          let { placeholder2 } = this.state;

          switch (row.payment_method) {
               case "เงินโอน":
                    if (!formatExtraData[1] === formatExtraData[1]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder2.n1}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "บัตรเครดิต":
                    if (!formatExtraData[3] === formatExtraData[3]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder2.n3}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "รับชำระโดย อื่น ๆ":
                    if (!formatExtraData[4] === formatExtraData[4]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder2.n4}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               default:
                    if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                         return (
                              <Field
                                   type={"file"}
                                   // name={row.payment_method}
                                   placeholder={"หลักฐานการชำระเงิน"}
                                   value={null}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __beside-btn"}
                                   handleChange={(e) => this.handleChangeFile2(e, rowIndex)}
                                   accept={".png,.jpg,.pdf"}
                              />
                         );
                    } else if (row.payment_method === "เช็ค") {
                         return (
                              <span className="warning __info">
                                   ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                              </span>
                         );
                    } else {
                         return <></>;
                    }
          }
     };

     viewFileFormatter = (cell, row) => {
          if (row.file && row.file.length > 0) {
               let arr = row.file.split(".")[1];
               if (cell) {
                    if (arr === "pdf") {
                         return <a onClick={() => window.open(`${ip}/static/${row.file}`)}>หลักฐานการชำระเงิน</a>;
                    } else {
                         return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
                    }
               } else {
                    return <span>-</span>;
               }
          } else return <span>-</span>;
     };
     totalLog = (cell, row) => {
          let total = 0;
          cell.map((el) => (total += Number(el)));

          return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
     };

     // handleChangeFile = (e, rowIndex) => {
     //      let { slectPay, placeholder } = this.state;
     //      const fileUploaded = e.target.files[0];
     //      slectPay[rowIndex].file = fileUploaded;
     //      placeholder[`n${rowIndex}`] = e.target.files[0].name;
     //      placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

     //      this.setState({
     //           slectPay,
     //           placeholder,
     //      });
     // };

     handleChangeFile = (e, rowIndex) => {
          let { slectPay, file, placeholder } = this.state;
          if (e.target.files.length !== 0) {
               const fileUploaded = e.target.files[0];
               slectPay[rowIndex].file = fileUploaded;

               if (rowIndex === this.state.rowIndex) {
                    file = slectPay[rowIndex].file;
               }

               placeholder[`n${rowIndex}`] = e.target.files[0].name;
               placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

               this.setState({ ...slectPay, file, placeholder });
          }
     };

     handleChangeFile2 = (e, rowIndex) => {
          let { slectPay2, file2, placeholder2 } = this.state;
          if (e.target.files.length !== 0) {
               const fileUploaded = e.target.files[0];
               slectPay2[rowIndex].file = fileUploaded;

               if (rowIndex === this.state.rowIndex2) {
                    file2 = slectPay2[rowIndex].file;
               }

               placeholder2[`n${rowIndex}`] = e.target.files[0].name;
               placeholder2[`p${rowIndex}`] = !placeholder2[`p${rowIndex}`];

               this.setState({ ...slectPay2, file2, placeholder2 });
          }
     };

     handleSelectDate = (date, dateString, name) => {
          let { issue, id, order } = this.state;
          issue[name] = date;
          issue.orcp_status = "ออกใบกำกับภาษีแล้ว";
          issue.orcp_sign = "";
          this.setState({ ...issue, datePay: date });
     };
     render() {
          let {
               issue,
               payment_status,
               id,
               order,
               show_popup,
               receiptData,
               openModal,
               slectPay,
               slectPay2,
               placeholder,
               placeholder2,
               margin,
               disabled,
               row,
               detailPayment,
               detailPayment2,
               payment_log,
               save_loading,
               payed,
          } = this.state;

          // console.log(this.sumPrice() >= this.totalPay());
          let sum_price = "";
          let total_price = "";

          for (let i of this.sumPrice().split(",")) {
               sum_price = sum_price.concat(i);
          }

          for (let i of this.totalPay().split(",")) {
               total_price = total_price.concat(i);
          }

          sum_price = Number(sum_price);
          total_price = Number(total_price);

          let sum_price2 = order?.finace_need;
          let total_price2 = "";

          for (let i of this.totalPay2().split(",")) {
               total_price2 = total_price2.concat(i);
          }

          // sum_price2 = parseFloat(Number(sum_price2)).toFixed(2);
          total_price2 = Number(total_price2);

          let table2 = {
               column: [
                    {
                         text: "เลือก",
                         formatter: this.checkBoxFormatterPay2,
                         headerClasses: "header-custom __left __no",
                         footer: "",
                    },
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "กรอกข้อมูล",
                         formatter: this.inputFormFormatterPay2,
                         // formatExtraData: order?.payment_method,
                         footer: "ยอดคงเหลือจ่าย",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatterPay2,
                         formatExtraData: this.state,
                         footer: this.totalPay2,
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.inputFileFormatter2,
                         headerClasses: "header-custom __right",
                         formatExtraData: [placeholder2.p0, placeholder2.p1, placeholder2.p2, placeholder2.p3, placeholder2.p4],
                         footer: "บาท",
                    },
               ],
               data: slectPay2,
               keyField: "payment_method",
          };
          let table_detail_payment_log = {
               column: [
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "detail",
                         text: "รายละเอียด",
                         formatter: (e) => (e ? e : "-"),
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
                         footer: this.totalLog,
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.viewFileFormatter,
                         headerClasses: "header-custom __right",
                         footer: "บาท",
                         footerClasses: "summary-custom",
                    },
               ],
               keyField: "payment_method",
          };
          let detail_pay = {
               column: [
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => e.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    },
                    {
                         dataField: "preview",
                         text: "ดูตัวอย่าง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preview",
                         headerClasses: "header-custom __right __btn",
                    },
                    // {
                    //      dataField: 'remove',
                    //      text: 'Remove',
                    //      formatter: this.removeReceipt,
                    //      headerClasses: 'header-custom __right __btn',
                    // },
               ],
               keyField: "list",
          };

          let detail_pay_modal = {
               column: [
                    {
                         dataField: "check",
                         text: "เลือก",
                         formatter: this.checkBoxFormatterModal,
                         headerClasses: "header-custom __left __icon",
                    },
                    {
                         dataField: "receipt_method",
                         text: "รายการ",
                         formatter: this.inputFormFormatterOther,
                    },
                    {
                         dataField: "receipt_price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatter,
                    },
                    {
                         dataField: "preview",
                         text: "ดูตัวอย่าง",
                         formatter: this.detailFormatter,
                         formatExtraData: "preview",
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: receiptData,
               keyField: "list",
          };

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รับเงินจากไฟแนนซ์`,
                                   onClick: () => window.location.assign("/admin/payment/leasing"),
                              },
                              {
                                   title: `การชำระเงินไฟแนนซ์(${order?.order_code_id || ""})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order.order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         {/* รายการ */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"รายการ"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "ชื่อลูกค้า : ",
                                                            detail:
                                                                 (order.userinfo_prefix ? order.userinfo_prefix : "") + order?.userinfo_name || "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "เลขประจำตัวผู้เสียภาษี : ",
                                                            detail: order?.userinfo_idcard || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "ที่อยู่ :",
                                                            detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ :",
                                                            detail: order.userinfo_tel ? this.telFormat(order.userinfo_tel) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "เลขที่ใบจอง : ",
                                                            detail: order?.order_code_id || "-",
                                                       }}
                                                       textHeader_style={"underline"}
                                                       onClickLabelBar={this.getOrderLeasingPdf}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader-genpayment"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: order?.saler_name_string || "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                   </div>
                                   <div className="content" id="table-content">
                                        <FormTable order={order} type="car-payment" />
                                   </div>
                              </div>
                         </div>
                         {order?.insurance === "Yes" && (
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content">
                                             <LabelBar type={"title"} title={"ข้อมูลประกันภัย"} />
                                             <Field
                                                  type={"text"}
                                                  title={"เลขที่รับแจ้งประกันภัย"}
                                                  name={"insurance_recive_no"}
                                                  placeholder={"กรอกเลขที่รับแจ้งประกันภัย"}
                                                  handleChangeText={(e) => this.handleChangeTextInsurance(e)}
                                                  value={order?.insurance_recive_no}
                                                  md={6}
                                             />
                                             <Field
                                                  type={"file"}
                                                  title={{
                                                       name: "ข้อมูลประกันภัย",
                                                  }}
                                                  name={"insurance_file"}
                                                  placeholder={this.state.insu_placeholder}
                                                  md={6}
                                                  handleChange={(e) => this.handleChangeFileInsurance(e, "file")}
                                                  accept={".png,.jpg,.pdf"}
                                             />
                                             <ModalPanel
                                                  show={this.state.insurance_file_path}
                                                  title={"ข้อมูลประกันภัย"}
                                                  type={"image"}
                                                  image={order?.insurance_file_path}
                                                  onHide={() => this.setState({ insurance_file_path: false })}
                                             />
                                             <div className="button-group __submit row">
                                                  {order?.insurance_file_path && (
                                                       <Col xs={6}>
                                                            <Button
                                                                 variant="outline-primary"
                                                                 onClick={() => this.setState({ insurance_file_path: true })}
                                                            >
                                                                 ดูไฟล์
                                                            </Button>
                                                       </Col>
                                                  )}

                                                  <Col xs={6}>
                                                       {order?.insurance_file_path ? (
                                                            <Button disabled={order?.acept_status === "ชำระเงินแล้ว"} onClick={this.onSaveInsurance}>
                                                                 แก้ไข
                                                            </Button>
                                                       ) : (
                                                            <Button disabled={order?.acept_status === "ชำระเงินแล้ว"} onClick={this.onSaveInsurance}>
                                                                 บันทึก
                                                            </Button>
                                                       )}
                                                  </Col>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         )}
                         {/* สรุปค่าใช้จ่าย */}
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"สรุปค่าใช้จ่าย"} />
                                        <div className="content" id="table-content">
                                             {!this.state.loading && (
                                                  <Table
                                                       type={"summary"}
                                                       add={"text"}
                                                       column={detail_pay.column}
                                                       data={this.state.recieptPay}
                                                       keyField={detail_pay.keyField}
                                                       handleClick={this.onOpenModal}
                                                  />
                                             )}
                                        </div>
                                        <Row className="input-auto">
                                             <Col xl={2} md>
                                                  <strong>รวมจ่ายเงิน :</strong>
                                             </Col>
                                             <Col xl={{ span: 2, offset: 3 }} md>
                                                  <span className="summary-value">
                                                       {this.state.totalPriceSum && this.state.totalPriceSum > 0
                                                            ? this.state.totalPriceSum.toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })
                                                            : "0.00"}
                                                  </span>
                                             </Col>
                                             <Col xl={1} md>
                                                  บาท
                                             </Col>
                                        </Row>

                                        {openModal ? (
                                             <ModalPanel
                                                  type={"select"}
                                                  title={"เพิ่มรายละเอียดเงินรับชั่วคราว"}
                                                  show={openModal}
                                                  table_data={detail_pay_modal}
                                                  onHide={this.onOpenModal}
                                                  button={[
                                                       {
                                                            name: "+ Add",
                                                            variant: "primary",
                                                            onClick: () => this.onAddReceipt(),
                                                       },
                                                       {
                                                            name: "Cancel",
                                                            variant: "danger",
                                                            onClick: () => this.setState({ openModal: false }),
                                                       },
                                                  ]}
                                             />
                                        ) : null}
                                   </div>
                              </div>
                         </div>
                         <>
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content">
                                             {payment_log &&
                                                  payment_log.length > 0 &&
                                                  payment_log.map((el) => {
                                                       if (el.orcp_payment_method.length !== 0) {
                                                            return (
                                                                 <>
                                                                      <LabelBar type={"title"} title={"ประวัติการรับเงิน"} />
                                                                      <Row>
                                                                           <LabelBar
                                                                                type={"textheader-genpayment"}
                                                                                title={{
                                                                                     name: "วันที่รับชำระเงิน :",
                                                                                     detail: el?.orcp_date ? dateFormatter(el?.orcp_date) : "-",
                                                                                }}
                                                                                md={12}
                                                                           />
                                                                      </Row>
                                                                      <ModalPanel
                                                                           show={this.state.showmodal_file}
                                                                           title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                                           type={"image"}
                                                                           image={`${ip}/static/${row.file}`}
                                                                           onHide={() => this.setState({ showmodal_file: false })}
                                                                      />
                                                                      <div id="table-content" style={{ padding: "0 15px" }}>
                                                                           <div className="content" id="table-content">
                                                                                <Table
                                                                                     type={"summary"}
                                                                                     column={table_detail_payment_log.column}
                                                                                     data={el.orcp_payment_method}
                                                                                     keyField={table_detail_payment_log.keyField}
                                                                                />
                                                                           </div>
                                                                      </div>
                                                                      <ModalPanel
                                                                           show={this.state.showmodal_sign}
                                                                           title={"หลักฐานการชำระเงิน"}
                                                                           type={"image"}
                                                                           image={el?.orcp_file}
                                                                           onHide={() => this.setState({ showmodal_sign: false })}
                                                                      />
                                                                 </>
                                                            );
                                                       } else return null;
                                                  })}
                                             {this.state.totalPriceSum >= 0 && !payed && (
                                                  <>
                                                       <LabelBar type={"title"} title={"การรับเงิน"} />
                                                       {/* <div className="-padding">
                                                                      <Field
                                                                           type={'number'}
                                                                           md={6}
                                                                           title="จำนวนเงินที่ได้รับจากไฟแนนซ์"
                                                                           name="finace_need"
                                                                           placeholder="0.00"
                                                                           unit="บาท"
                                                                           value={order?.finace_need}
                                                                           decimalScale={2}
                                                                           handleChangeNumber={(e) => this.onChangeNumber(e, 'finace_need')}
                                                                           disabled={false}
                                                                      />
                                                                 </div> */}
                                                       <Field
                                                            type={"date"}
                                                            title={"วันที่ชำระเงิน"}
                                                            name={"orcp_date"}
                                                            placeholder={"เลือกวันที่"}
                                                            md={6}
                                                            formatDate={"DD/MM/YYYY"}
                                                            value={this.state.datePay || dayjs(new Date())}
                                                            require={false}
                                                            handleSelectDate={this.handleSelectDate}
                                                       />
                                                       <div>
                                                            <div className="content" id="table-content">
                                                                 <div className="-padding">
                                                                      {this.state.loadingPayment ? (
                                                                           <Skeleton type={"document"} column={table2.column} />
                                                                      ) : (
                                                                           <Table
                                                                                type={"summary"}
                                                                                column={table2.column}
                                                                                data={table2.data}
                                                                                keyField={table2.keyField}
                                                                           />
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="button-group __submit row">
                                                            {/* {console.log(`this.totalPay2() >> `, this.totalPay2(), "sum_price2 >> ", sum_price2)} */}
                                                            <Col xs={6}>
                                                                 {save_loading ? (
                                                                      <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                           <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                           />
                                                                           Loading...
                                                                      </Button>
                                                                 ) : (
                                                                      <Button disabled={this.totalPay2() !== "0.00"} onClick={this.onSubmit2}>
                                                                           ยืนยันการชำระเงิน
                                                                      </Button>
                                                                 )}
                                                            </Col>
                                                       </div>
                                                  </>
                                             )}
                                        </div>
                                   </div>
                              </div>
                              {/* ) : (
                                        <>
                                             <div className="input-field">
                                                  <div className="-body">
                                                       <div className="content">
                                                            <LabelBar type={'title'} title={'การรับเงินจากไฟแนนซ์'} />
                                                            <div className="-padding">
                                                                 <Row>
                                                                      <LabelBar
                                                                           type={'textheader-genpayment'}
                                                                           title={{
                                                                                name: 'วันที่ออกใบเสร็จรับเงิน :',
                                                                                detail: order?.orcp_leasing_date ? dateFormatter(order?.orcp_leasing_date) : '-',
                                                                           }}
                                                                           md={12}
                                                                      />
                                                                 </Row>
                                                            </div>
                                                       </div>
                                                       <div className="content checkcenter" id="table-content" style={{ padding: '0 15px' }}>
                                                            {this.renderTable()}
                                                            <Table
                                                                 type={'summary'}
                                                                 column={table_detail_payment2.column}
                                                                 data={table_detail_payment2.data}
                                                                 keyField={table_detail_payment2.keyField}
                                                            />
                                                       </div>
                                                       <ModalPanel
                                                            show={this.state.showmodal_file}
                                                            title={`หลักฐานการชำระเงิน (${row?.payment_method || 'ไม่ระบุ'})`}
                                                            type={'image'}
                                                            image={`${ip}/static/${row.file}`}
                                                            onHide={() => this.setState({ showmodal_file: false })}
                                                       />
                                                  </div>
                                             </div>
                                        </>
                                   )} */}
                         </>
                         {/* {order?.orcp_status === 'ชำระเงินแล้ว' && (
                              <Breadcrumbs
                                   under={true}
                                   type={'document'}
                                   title={'รับเงินค่ารถแล้ว'}
                                   detail={'พิมพ์ใบเสร็จรับเงินค่ารถ'}
                                   button={{
                                        btn1: { onClick: () => this.getOrderCarPayPdf('ต้นฉบับ'), name: 'ต้นฉบับ', variant: 'outline-primary' },
                                        btn2: { onClick: () => this.getOrderCarPayPdf('สำเนา'), name: 'สำเนา', variant: 'outline-primary' },
                                   }}
                              />
                         )} */}
                    </div>
               </div>
          );
     }
}
