import { ip, POST } from "api";
import { Breadcrumbs, LabelBar, Step, Table, Img, ModalPanel } from "components";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import axios from "axios";
import { getToken } from "../../../../static/function";

function dateFormatter(cell, row, rowIndex, formatExtraData) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class GenerateDeliverAcc extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order_id: this.props.match.params.id,
               order: {},
               step_status: {
                    a1: false,
                    a2: false,
                    a3: false,
                    a4: false,
                    a5: false,
               },
               show_modal: false,
               doc_active: false,
          };
     }

     componentDidMount = () => {
          this.getDetailAcc();
     };

     getDetailAcc = async () => {
          let { step_status, doc_active } = this.state;
          try {
               let order = await POST("/order/this_acc_order", { acorder_id: this.state.order_id });
               Object.keys(step_status).forEach((v, i) => {
                    if (order.acept_status === "สั่งซื้อ") {
                         i < 1 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.payment = true;
                    } else if (order.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
                         i < 2 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.install = false;
                         step_status.payment = true;
                         doc_active = true;
                    } else if (order.acept_status === "ชำระแล้ว") {
                         i < 3 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.install = false;
                         step_status.payment = false;
                         step_status.deliver = true;
                         doc_active = true;
                    } else if (order.acept_status === "ส่งมอบรถแล้ว") {
                         i < 4 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.payment = false;
                         step_status.install = false;
                         step_status.deliver = false;
                         doc_active = true;
                    } else if (order.acept_status === "ปิดการขาย") {
                         i < 5 ? (step_status[v] = true) : (step_status[v] = false);
                         step_status.payment = false;
                         step_status.install = false;
                         step_status.deliver = false;
                         doc_active = true;
                    }
               });

               this.setState({
                    order: { ...order },
                    step_status,
                    doc_active,
               });
          } catch (error) {
               console.log("error", error);
          }
     };

     downloadPdf = async (row) => {
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(
                         `${ip}${row.file}`,
                         { acorder_id: this.state.order_id, type: "ต้นฉบับ" },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {}
     };

     openModal = () => {
          this.setState({ show_modal: true });
     };

     saveSign = async (sigCanvas) => {
          let base64 = await sigCanvas.toDataURL("image/png");
          try {
               await POST("/order/delivery_ac_order", { acorder_id: this.state.order_id, delivery_sign: base64 });
               window.location.reload();
          } catch (error) {
               console.log("error", error);
          }
     };

     clear = (sigCanvas) => {
          sigCanvas.clear();
     };

     /* --------------- formatter ---------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.active) {
               if (cell) {
                    return <div style={{ color: "#000" }}>{dateFormatter(cell)}</div>;
               } else {
                    return <p>-</p>;
               }
          } else {
               return <p>-</p>;
          }
     };

     docIconFormatter = (cell, row) => {
          if (row.active) {
               return (
                    <>
                         <Image className="icon" src={Img.files_active} />
                         {cell}
                    </>
               );
          } else {
               return (
                    <>
                         <Image className="icon" src={Img.files} />
                         {cell}
                    </>
               );
          }
     };

     printFormatter = (cell, row) => {
          if (row.active) {
               return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdf(row)}></i>;
          } else {
               return <i className="fas fa-print icon"></i>;
          }
     };
     render() {
          let { order_id, order, step_status, show_modal, doc_active } = this.state;

          let step = [
               {
                    title: "สั่งซื้อ",
                    active: step_status.a1,
               },
               {
                    title: "ติดตั้ง",
                    active: step_status.a2,
               },
               {
                    title: "ชำระเงินแล้ว",
                    active: step_status.a3,
               },

               {
                    title: "ส่งมอบ",
                    active: step_status.a4,
               },
               {
                    title: "ปิดการขาย",
                    active: step_status.a5,
                    activeBtn: order?.acept_status === "ส่งมอบรถแล้ว" ? true : false,
                    btn: () => window.location.assign(`/admin/order/acc/detail${order_id}`),
               },
          ];

          let table = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการเอกสาร",
                         formatter: this.docIconFormatter,
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "create_date",
                         text: "วันที่ออกเอกสาร",
                         formatter: this.dateFormatter,
                    },
                    {
                         dataField: "print",
                         text: "พิมพ์",
                         formatter: this.printFormatter,
                         headerClasses: "header-custom __right __icon",
                    },
               ],
               data: [
                    {
                         file: "/order/acc_order_pdf",
                         title: "ดูใบสั่งขายอุปกรณ์",
                         create_date: order?.created_at,
                         active: true,
                    },
                    {
                         file: "/order/acc_order_install_pdf",
                         title: "ใบสั่งติดตั้งอุปกรณ์แยก",
                         create_date: order?.created_at,
                         active: true,
                    },
                    {
                         file: "/order/acc_order_payment",
                         title: "ใบกำกับภาษีอุปกรณ์",
                         create_date: order?.acc_order_payment_updated,
                         active: doc_active,
                    },
               ],
               keyField: "no",
          };

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `ส่งมอบอุปกรณ์รถยนต์`,
                                   onClick: () => window.location.assign("/admin/deliver/car"),
                              },
                              {
                                   title: `ใบสั่งขายอุปกรณ์ (${order?.acorder_code_id || ""})`,
                                   onClick: () => window.location.assign(`/admin/order/acc/detail${order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"สถานะการขาย"} />
                                        <Step step={step} />
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลโชว์รูม"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "โชว์รูม : ",
                                                            detail: order?.branch_name || "-",
                                                       }}
                                                       md={8}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบสั่งขายอุปกรณ์ : ",
                                                            detail: order?.acorder_code_id || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: order?.saler_name_string || "-",
                                                       }}
                                                       md={8}
                                                  />
                                                  {/* <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ออกใบจอง : ",
                                                            detail: order.do_credit_date ? dayjs(order?.do_credit_date).add(543, "year").format("DD/MM/YYYY") : "-",
                                                       }}
                                                       md={8}
                                                  /> */}
                                             </Row>
                                             <Row>
                                                  {/* <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่คาดว่าจะได้รับรถ : ",
                                                            detail: order.getcar_date ? dayjs(order.getcar_date).add(543, "year").format("DD/MM/YYYY") : "-",
                                                       }}
                                                       md={8}
                                                  /> */}
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายละเอียดผู้สั่งจอง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "A-Card No. : ",
                                                            detail: order?.acorder_acard || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                            detail: order?.acorder_name || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: order?.acorder_idcard || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: order?.acorder_tel ? this.telFormat(String(order?.acorder_tel)) : "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ID LINE : ",
                                                            detail: order?.userinfo_line || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันเกิด : ",
                                                            detail: order?.userinfo_birth_date ? dateFormatter(order?.userinfo_birth_date) : "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${order?.acorder_address || "-"}  
                                                            ตำบล/แขวง ${order?.acorder_sub_district || "-"} 
                                                            อำเภอ/เขต ${order?.acorder_district || "-"} 
                                                            จังหวัด ${order?.acorder_province || "-"} 
                                                            ${order?.acorder_zipcode || "-"}`,
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ที่ใช้ติดต่อ : ",
                                                            detail: `${order?.acorder_address || "-"}  
                                                            ตำบล/แขวง ${order?.acorder_sub_district || "-"} 
                                                            อำเภอ/เขต ${order?.acorder_district || "-"} 
                                                            จังหวัด ${order?.acorder_province || "-"} 
                                                            ${order?.acorder_zipcode || "-"}`,
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"รายละเอียดผู้ถือครอง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อ-นามสกุล ผู้ถือครอง : ",
                                                            detail: order?.acorder_name || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: order?.acorder_idcard || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${order?.acorder_address || "-"}  
                                                            ตำบล/แขวง ${order?.acorder_sub_district || "-"} 
                                                            อำเภอ/เขต ${order?.acorder_district || "-"} 
                                                            จังหวัด ${order?.acorder_province || "-"} 
                                                            ${order?.acorder_zipcode || "-"}`,
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                        </div>
                                        <LabelBar type={"title"} title={"เลือกรุ่นรถยนต์"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Serie : ",
                                                            detail: order?.series_name || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model : ",
                                                            detail: order?.model_name || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สีที่ 1 : ",
                                                            detail: order?.color_name || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สีที่ 2 : ",
                                                            detail: `-`,
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model Code : ",
                                                            detail: order?.model_code || "-",
                                                       }}
                                                       md={8}
                                                  />
                                             </Row>
                                        </div>
                                        {(getToken().position_id === 19 || getToken().position_id === 20 || getToken().position_id === 25) && (
                                             <>
                                                  <LabelBar type={"title"} title={"ตรวจสอบเอกสาร"} />
                                                  <Table type="document" column={table.column} data={table.data} keyField={table.keyField} />
                                             </>
                                        )}
                                        <Row>
                                             <Col md={5}></Col>
                                             <Col>
                                                  {order?.acept_status === "ชำระแล้ว" && (
                                                       <Button onClick={this.openModal}>ยืนยันการรับอุปกรณ์</Button>
                                                  )}
                                             </Col>
                                             <Col md={5}></Col>
                                        </Row>

                                        {order?.acept_status === "ส่งมอบรถแล้ว" && (
                                             <div>
                                                  <Breadcrumbs
                                                       under={true}
                                                       type={"success"}
                                                       title={"ส่งมอบอุปกรณ์แล้ว"}
                                                       // button={
                                                       //     { onClick: () => { }, name: 'พิมพ์ใบยืนยันส่งมอบรถ', variant: 'outline-primary' }
                                                       // }
                                                  />
                                                  <div style={{ height: "80px" }}></div>
                                             </div>
                                        )}

                                        <ModalPanel
                                             show={show_modal}
                                             type={"sign"}
                                             save={this.saveSign}
                                             clear={this.clear}
                                             title={"ยืนยันการรับอุปกรณ์"}
                                             sub_title={"ลายเซ็นลูกค้า"}
                                             // onClick={this.openPopup}
                                             onHide={() => this.setState({ show_modal: false })}
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
