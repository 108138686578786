import { Breadcrumbs, Field, FormTable, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { POST } from "../../../../api/api";
import { tofieds, add } from "../../../../static/function";

export default class DetailCommission extends Component {
     constructor(props) {
          super(props);
          this.state = {
               dataTable: [],
               commission: [],
               money_special: 0,
          };
     }
     componentDidMount = async () => {
          // console.log("this.props.location.state", this.props.location);
          try {
               let { user_id, date_start, date_end } = this.props.location.state;
               let res = await POST("/aos/get_order_by_status", { date_start, date_end, saler_name: Number(user_id) });
               // console.log("res", res);
               this.setState({ dataTable: res, commission: res.map((e) => ({ s: 0, t: 0, u: 0, w: 0 })) });
          } catch (error) {}
     };

     handleChangeNumber = (value, name, index) => {
          let { commission, dataTable } = this.state;
          let number = value ? value : 0;
          commission[index][name] = number;
          dataTable[index][name] = number;
          this.setState({ commission, dataTable });
     };
     report = async () => {
          try {
               let { branch_name, team_name, username, date_start } = this.props.location.state;
               let { dataTable, money_special } = this.state;
               let res = await POST("/aos/report_commission", { branch_name, team_name, username, date_start, dataTable, money_special });
               if (dataTable.length > 0) {
                    // console.log("res", res);
                    window.open(res);
               }
          } catch (error) {}
     };
     render() {
          let state = this.props.location.state;
          let { dataTable, commission, money_special } = this.state;
          // console.log('commission', commission)
          let total = dataTable.map((el, i) => ({
               sum: el.total_sales + el.s + el.w - (el.t + el.u),
          }));
          let table_input = {
               data: [
                    {
                         col1: "รวมยอดเงินสุทธิ",
                         col2: `${tofieds(total.reduce(add("sum"), 0))} บาท`,
                    },
                    {
                         col1: "บวก ค่าครองชีพ",
                         col2: `${tofieds(dataTable[0] ? dataTable[0].cost_of_living : 0)} บาท`,
                    },
                    {
                         col1: "หัก ค่ารักษาการขาย",
                         col2: `${tofieds(dataTable[0] ? dataTable[0].sales_fee : 0)} บาท`,
                    },
                    {
                         col1: "จำนวนเงินพิเศษ",
                         col2: (
                              <Field
                                   id={"field-subdown"}
                                   type={"number"}
                                   value={money_special}
                                   placeholder={"0.00"}
                                   require={false}
                                   unit={"บาท"}
                                   decimalScale={2}
                                   style_id={"__inline__space"}
                                   handleChangeNumber={(e) => {
                                        let value = e.floatValue;
                                        let number = value ? value : 0;
                                        this.setState({ money_special: number });
                                   }}
                                   md={12}
                              />
                         ),
                    },
                    {
                         col1: "รับสุทธิ",
                         col2: `${tofieds(
                              total.reduce(add("sum"), 0) +
                                   (dataTable[0] ? dataTable[0].cost_of_living : 0) -
                                   (dataTable[0] ? dataTable[0].sales_fee : 0) +
                                   money_special
                         )} บาท`,
                    },
               ],
          };

          let formtable = {
               header1: "รายละเอียดการขายรถยนต์",
               header2: "รายละเอียดการใช้ Margin",
               header3: "รายละเอียดการคำนวณ Margin สำหรับพนักงานขาย",
               header4: "รายละเอียด Commission สำหรับพนักงานขาย",
               header5: "รายการหัก Commission",
               header6: "รายการเพิ่ม Commission",
               header7: "ยอดเงินรับสุทธิ สำหรับพนักงานขาย",
               th1: [
                    "ลำดับ",
                    "เลขที่ใบจอง",
                    "วันส่งมอบรถยนต์",
                    "ลูกค้า",
                    "รุ่นรถ (Series)",
                    "โมเดล (Model)",
                    "Margin มาตรฐาน",
                    "Margin ที่ได้รับอนุมัติ",
                    "บวกราคาตัวรถเพิ่ม",
               ],
               th2: ["ซัพดาวน์ (Subdown)", "Subsidy", "ค่าแนะนำ", "ส่วนลดตัวรถ", "อุปกรณ์ของแถม", "ค่าประกันภัย", "ค่าจดทะเบียน"],
               th3: ["Margin คงเหลือ", "10% Margin สำหรับ PSE", "90% Margin สำหรับ พนักงานขาย", "Margin สุทธิสำหรับพนักงานขาย"],
               th4: ["Commission รายคัน", "Extra Commission เพื่อผลักดันการขาย", "Commission พิเศษ"],
               th5: ["ค่าอุปกรณ์ตกแต่ง", "Commission", "15% ของบวกราคาตัวรถเพิ่มเพื่อซัพดาวน์", "ค่าเคลื่อนย้ายรถ"],
               th6: [""],
               th7: [""],
               data: [],
          };

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              { title: "รายงานค่า Commission", onClick: () => window.location.assign("/admin/commission") },
                              { title: "ผลการค้นหา" },
                         ]}
                         active={1}
                         button={""}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"ข้อมูลการค้นหา"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ค่าคอมมิชชั่นเดือน :",
                                                       detail:
                                                            dayjs(state.date_start).format("MMM-") +
                                                            String(dayjs(state.date_start).year() + 543).slice(2, 4),
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "สาขา :",
                                                       detail: state.branch_name,
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ทีม :",
                                                       detail: state.team_name,
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ชื่อพนักงานขาย :",
                                                       detail: state.username,
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "จำนวนส่งมอบ :",
                                                       detail: dataTable.length.toLocaleString(),
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <div className="content" id="table-content" style={{ margin: "0 0 40px 0", width: "100%" }}>
                                             <FormTable
                                                  type="commission"
                                                  formdata={formtable}
                                                  data={dataTable}
                                                  handleChangeNumber={this.handleChangeNumber}
                                                  order={commission}
                                             />
                                        </div>
                                        <Row>
                                             {table_input.data.map((el) => (
                                                  <Col xs={12} className="mb-2">
                                                       <Row>
                                                            <Col xs={6} sm={4}>
                                                                 {el.col1}
                                                            </Col>
                                                            <Col xs={6} sm={8}>
                                                                 <strong>{el.col2}</strong>
                                                            </Col>
                                                       </Row>
                                                  </Col>
                                             ))}
                                        </Row>
                                        <Row className="button-group __submit">
                                             <Col lg={6}>
                                                  <Button onClick={this.report}>ออกรายงาน</Button>
                                             </Col>
                                        </Row>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
