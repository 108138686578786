import React, { Component } from "react";
import { Breadcrumbs, Table, Search } from "../../../../components/index";
import { Accordion, Form, Button, Row, Col } from "react-bootstrap";
import { POST, GET } from "../../../../api/api";
import dayjs from "dayjs";

export default class branchList extends Component {
     constructor(props) {
          super(props);

          this.state = {
               dataTable: [],
               searchData: [],
               search: {},
               branch: [],
          };
     }

     handleClick = () => {
          window.location.assign("/admin/branch/manage");
     };

     userFormatter = (cell) => {
          return (
               <p>
                    <i className="fas fa-user mr-1" />
                    {cell}
               </p>
          );
     };
     componentDidMount = async () => {
          this.getData();
          this.getBranch();
     };
     getData = async () => {
          try {
               let res = await GET("/aos/get_branch_list");
               this.setState({ dataTable: res, searchData: res });
          } catch (error) {}
     };
     getBranch = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               getBranch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({ branch: [...getBranch] });
          } catch (error) {
               console.log("error", error);
          }
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { searchData, search } = this.state;
          let newSearch = searchData.filter((el) =>
               search.branch_id ? (search.branch_id === "ทั้งหมด" ? el : Number(el.branch_id) === Number(search.branch_id)) : el
          );

          this.setState({ dataTable: newSearch });
     };
     editFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <i
                    style={{ cursor: "pointer" }}
                    className="fas fa-pen-square"
                    onClick={() => window.location.assign(`/admin/branch/manage/${row.branch_id}`)}
               ></i>
          );
     };
     render() {
          let { dataTable, branch, search } = this.state;
          const column = [
               {
                    dataField: "edit",
                    text: "แก้ไข",
                    formatter: this.editFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "branch_name",
                    text: "รายการสาขา",
                    // headerClasses: "header-custom __left",
               },
               {
                    dataField: "amount",
                    text: "จำนวน User",
                    formatter: this.userFormatter,
                    headerClasses: "header-custom __right",
               },
          ];
          return (
               // <div className="panel hide-scroll">
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "รายการสาขา" }]}
                         active={0}
                         button={[
                              {
                                   icon: "fas fa-users -icon",
                                   name: "เพิ่มสาขา",
                                   handleClick: this.handleClick,
                              },
                         ]}
                         onClick={() => this.props.history.goBack()}
                    />
                    <div className="content __main-panel">
                         <Search
                              type={"branch_list"}
                              handleChangeText={this.handleChangeText}
                              onSearch={this.handleClickSearch}
                              option={{
                                   branchs: branch,
                                   value: search,
                              }}
                         />
                         <Table type={"index-fixhead"} column={column} data={dataTable} keyField={"username"} />
                    </div>
               </div>
          );
     }
}
