import { POST } from "api";
import { Breadcrumbs, FormTable, LabelBar, ModalPanel, Table } from "components";
import React, { Component } from "react";
import { Row, Button, Col } from "react-bootstrap";

export default class InstallPreemption extends Component {
     constructor(props) {
          super(props);
          this.state = {
               type: this.props.match.params.preemption_type,
               id: this.props.match.params.preemption_id,
               sup_id: this.props.match.params.sup_id,
               total: 0,
               order: {
                    ac: [],
               },
               price: {},
          };
     }

     componentDidMount = async () => {
          await this.getOrder();
     };

     getOrder = async () => {
          let { price } = this.state;
          try {
               let order = await POST("/order/this_order_instal_ac", { order_id: this.state.id });

               let total_fun = 0;
               let total_price = 0;

               for (let i in order?.ac) {
                    total_fun += order.ac[i] ? order.ac[i].items_fun : 0;
                    total_price += order.ac[i] ? order.ac[i].items_price : 0;

                    if (order?.ac[i].order_ac_type === "bonus") {
                         order.ac[i]["order_ac_type"] = "แถม";
                    } else if (order?.ac[i].order_ac_type === "buy") {
                         order.ac[i]["order_ac_type"] = "ลูกค้าซื้อเอง";
                    }
               }

               price["total_fun"] = total_fun;
               price["total_price"] = total_price;
               price["total"] = total_price + (total_price * 7) / 100;
               order.ac.push({
                    install: "",
                    supplier_name: " ",
                    items_name: "",
                    order_ac_type: "รวมราคาสินค้า",
                    items_fun: "total",
                    items_price: "total",
                    field: "summary-detail",
               });
               order.ac.push({
                    install: "",
                    supplier_name: " ",
                    items_name: "",
                    order_ac_type: "ภาษีมูลค่าเพิ่ม 7 %",
                    items_fun: "none",
                    items_price: "vat",
                    field: "summary-detail",
               });
               this.setState({ order: { ...order }, price });
          } catch (error) {
               console.log(error);
          }
     };

     /* ----------------------- Formatter --------------------------- */
     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     parseFloatFormatter = (cell, row, rowIndex, formatExtraData) => {
          let new_cell = Number(cell).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          switch (row.items_fun) {
               case "none":
                    return null;
               case "total":
                    return Number(formatExtraData).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
               default:
                    if (row.field === "summary-total") {
                         return <span className="text">{new_cell}</span>;
                    } else {
                         if (cell) {
                              return new_cell;
                         } else {
                              return null;
                         }
                    }
          }
     };
     parseFloatFormatter2 = (cell, row, rowIndex, formatExtraData) => {
          let new_cell = Number(cell).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

          switch (row.items_price) {
               case "none":
                    return null;
               case "total":
                    return Number(formatExtraData).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
               case "vat":
                    let vat = (formatExtraData * 7) / 100;
                    return Number(vat).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
               default:
                    if (row.field === "summary-total") {
                         return <span className="text">{new_cell}</span>;
                    } else {
                         if (cell) {
                              return new_cell;
                         } else {
                              return null;
                         }
                    }
          }
     };

     unitFormatter() {
          return <p>บาท</p>;
     }

     noFormatter(cell, row, rowIndex, formatExtraData) {
          return <p>{rowIndex + 1}</p>;
     }

     detailFormatter(cell, row, rowIndex, formatExtraData) {
          switch (formatExtraData) {
               case "status":
                    if (row.status === "ติดตั้งแล้ว") {
                         return <span style={{ color: "green" }}>{row.install}</span>;
                    } else {
                         return <span style={{ color: "grey" }}>{row.install}</span>;
                    }
          }
     }
     render() {
          let { type, show_popup, order } = this.state;

          const table_pending = {
               column: [
                    {
                         dataField: "install",
                         text: "การติดตั้ง",
                         footer: "",
                         formatter: this.detailFormatter,
                         footerClasses: "summary-custom",
                         formatExtraData: "status",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_type",
                         text: "ประเภท",
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาซื้อ (บาท)",
                         footer: "",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom",
                         formatExtraData: this.state.price.total_fun,
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footer: Number(this.state.price.total).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                         formatter: this.parseFloatFormatter2,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right",
                         formatExtraData: this.state.price.total_price,
                    },
               ],
               data: order?.ac,
               keyField: "supplier_name",
          };

          const table_installed = {
               column: [
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         footer: "",
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         footer: "",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_type",
                         text: "ประเภท",
                         footer: "รวมทั้งสิ้น",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาซื้อ (บาท)",
                         footer: "",
                         formatter: this.parseFloatFormatter,
                         footerClasses: "summary-custom",
                         formatExtraData: this.state.price.total_fun,
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footer: Number(this.state.price.total).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                         formatter: this.parseFloatFormatter2,
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __right",
                         formatExtraData: this.state.price.total_price,
                    },
               ],
               data: order?.ac,
               keyField: "supplier_name",
          };
          switch (type) {
               case "pending":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รายงานติดตั้งอุปกรณ์`,
                                             onClick: () => window.location.assign("/admin/install/report"),
                                        },
                                        {
                                             title: `Supplier Name`,
                                             onClick: () => window.location.assign(`/admin/install/report/${this.state.sup_id}`),
                                        },
                                        { title: `รายการใบจอง (${order?.order_code_id || ""})` },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ข้อมูลใบจอง"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: order?.order_code_id || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โชว์รูม : ",
                                                                      detail: order?.branch_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                                      detail: order?.userinfo_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขประจำตัว : ",
                                                                      detail: order?.userinfo_idcard || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขโทรศัพท์ : ",
                                                                      detail: order.userinfo_tel ? this.telFormat(String(order.userinfo_tel)) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ : ",
                                                                      detail: `${order?.userinfo_address}  ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Series : ",
                                                                      detail: order?.series_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Model : ",
                                                                      detail: order?.model_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Color : ",
                                                                      detail: order?.color_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขตัวถัง : ",
                                                                      detail: order?.car_tank || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขเครื่องยนต์ : ",
                                                                      detail: order?.car_engine || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการ Car Accessories"} />
                                                  {/* ตารางแบบที่ 1 */}
                                                  <Table
                                                       type="summary-custom-padding"
                                                       column={table_pending.column}
                                                       data={table_pending.data}
                                                       keyField={table_pending.keyField}
                                                  />
                                                  {/* ตารางแบบที่ 2
                                        <FormTable
                                            type={'preemption_pending'}
                                            column={table_pending.column}
                                            data={table_pending.data}
                                        /> */}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );
               case "installed":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `ติดตั้งอุปกรณ์`,
                                             onClick: () => window.location.assign("/admin/install/report"),
                                        },
                                        {
                                             title: `Supplier Name`,
                                             onClick: () => window.location.assign(`/admin/install/report/${this.state.sup_id}}`),
                                        },
                                        { title: `รายการใบจอง (${order.order_code_id})` },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ข้อมูลใบจอง"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: order?.order_code_id || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โชว์รูม : ",
                                                                      detail: order?.branch_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                                      detail: order?.userinfo_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขประจำตัว : ",
                                                                      detail: order?.userinfo_idcard || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขโทรศัพท์ : ",
                                                                      detail: order.userinfo_tel ? this.telFormat(String(order.userinfo_tel)) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ : ",
                                                                      detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Series : ",
                                                                      detail: order?.series_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Model : ",
                                                                      detail: order?.model_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Color : ",
                                                                      detail: order?.color_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขตัวถัง : ",
                                                                      detail: order?.car_tank || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขเครื่องยนต์ : ",
                                                                      detail: order?.car_engine || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการ Car Accessories"} />
                                                  <Table
                                                       type="summary-custom-padding"
                                                       column={table_installed.column}
                                                       data={table_installed.data}
                                                       keyField={table_installed.keyField}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );
          }
     }
}
