import React, { Component } from 'react';
import { Breadcrumb, Button, Col, Image, Row } from 'react-bootstrap';
import IcCorrect from '../../assets/images/001-correct.png';
// import './breadcrumb.scss';

export default class Breadcrumbs extends Component {
     render() {
          let { title, active, button, type, under, detail, id } = this.props;
          if (under) {
               switch (type) {
                    case 'success':
                         return (
                              <Breadcrumb className="label">
                                   <div className="color __success">
                                        <Image src={IcCorrect} className="__image" roundedCircle />
                                        <b>{title}</b>
                                   </div>
                                   <div className="__right">
                                        {button ? (
                                             <Button className="btn-color __default" onClick={button.onClick} variant={button.variant}>
                                                  <i className="fas fa-print icon " />
                                                  {button.name}
                                             </Button>
                                        ) : null}
                                   </div>
                              </Breadcrumb>
                         );
                    case 'document':
                         return (
                              <Breadcrumb className="label">
                                   <div className="color __success">
                                        <Image src={IcCorrect} className="__image" roundedCircle />
                                        <b>{title}</b>
                                   </div>
                                   <div className="__row-right">
                                        {button ? (
                                             <Row>
                                                  <Col>{detail ? detail : null}</Col>

                                                  <Col>
                                                       <Button className="btn-color __default" onClick={button.btn1.onClick} variant={button.btn1.variant}>
                                                            <i className="fas fa-print icon " />
                                                            {button.btn1.name}
                                                       </Button>
                                                  </Col>
                                                  <Col>
                                                       <Button className="btn-color __default" onClick={button.btn2.onClick} variant={button.btn2.variant}>
                                                            <i className="fas fa-print icon " />
                                                            {button.btn2.name}
                                                       </Button>
                                                  </Col>
                                             </Row>
                                        ) : null}
                                   </div>
                              </Breadcrumb>
                         );
                    case 'order':
                         return (
                              <Breadcrumb className="order">
                                   <div className="color __primary">
                                        {/* <Image src={IcCorrect} className="__image" roundedCircle /> */}
                                        <span>{`! กรุณายืนยันข้อมูลการจองกับลูกค้า เมื่อได้รับเงินจอง (กรณีเงินสด) หรือหลังจาก Leasing อนุมัติแล้ว (กรณีผ่อนชำระ)`}</span>
                                   </div>
                                   {/* <div className="__right">
                                        {button ? (
                                             <Button className="btn-color __default" onClick={button.onClick} variant={button.variant}>
                                                  <i className="fas fa-print icon " />
                                                  {button.name}
                                             </Button>
                                        ) : null}
                                   </div> */}
                              </Breadcrumb>
                         );
                    default:
                         return null;
               }
          } else {
               return (
                    <Breadcrumb className="row">
                         <div className="-b__item">
                              {title.map((item, i) => (
                                   <Breadcrumb.Item key={i} active={i === active} onClick={i !== active ? item.onClick : null}>
                                        {item.title}
                                   </Breadcrumb.Item>
                              ))}
                         </div>
                         <div className="-b__button">
                              {button !== ''
                                   ? button.map((btn, i) => {
                                        if (btn.disabled === true) {
                                             return null;
                                        } else {
                                             return (
                                                  <Button style={{ backgroundColor: btn.color_btn }} key={i} onClick={btn.handleClick} variant={btn.variant} id={id}>
                                                       <i className={btn.icon + ' mr-1'} />
                                                       <span>{btn.name}</span>
                                                  </Button>
                                             );
                                        }
                                   })
                                   : null}
                         </div>
                         <div className="-b__button-md">
                              {button !== ''
                                   ? button.map((btn, i) => {
                                        if (btn.disabled === true) {
                                             return null;
                                        } else {
                                             return (
                                                  <Button key={i} className="btn-radius" variant={btn.variant} onClick={btn.handleClick}>
                                                       <i className={btn.icon} />
                                                  </Button>
                                             );
                                        }
                                   })
                                   : null}
                         </div>
                    </Breadcrumb>
               );
          }
     }
}
