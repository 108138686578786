import React, { Component } from "react";
import { Form, Col, InputGroup, Button, Image, Row, FormControl } from "react-bootstrap";
import { ConfigProvider, DatePicker } from "antd";
import NumberFormat from "react-number-format";
import { Typeahead } from "react-bootstrap-typeahead";
import "moment/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";
import dayjs from "dayjs";
import "./css.css";
/* If field pimary = vertical align (block)
  If field inline = Horizontal align (flex)
  iF field inline with remark = Horizon */
export default class Feild extends Component {
     render() {
          let {
               type,
               title,
               warning_title,
               name,
               placeholder,
               option,
               require,
               md,
               lg,
               xl,
               handleChangeText,
               handleSelectDate,
               handleChangeNumber,
               handleChange,
               handleChangeAutocomplete,
               handleOnInputChange,
               typeInput,
               value,
               disabled,
               unit,
               button,
               style_id,
               as,
               textarea_rows,
               decimalScale,
               format,
               mask,
               suffix,
               picker,
               formatDate,
               sum_name,
               sum_no,
               checked,
               rowIndex,
               handleChangeRadio,
               provice,
               isHide,
               togglePassword,
               remark,
               space,
               id,
               accept,
               validated,
               reciept,
               all,
               xs,
               accAddBonus,
               placeholdText,
               setMultiSelections,
               multiSelections,
               disDate,
               disabledDate,
               handleEnter,
               hidden,
          } = this.props;
          const disabledFixDate = (current) => {
               // Can not select days before today and today
               return (current && dayjs(current).year() < 2022) || (current && dayjs(current).year() > dayjs(new Date()).year());
          };
          let label_span,
               control_span,
               control_span_md = {};
          let input_span = "";
          let padding_style = "noPadding";
          /* Field inline with remark */
          if (style_id && remark?.type === "field") {
               label_span = 2;
               control_span = { span: 3, offset: 1 };
               control_span_md = { span: 5, offset: 1 };
               input_span = 9;
               padding_style = "noPadding";
               /* Field inline with RADIO remark */
          } else if (style_id && remark?.type === "radio") {
               label_span = 2;
               control_span = { span: 3, offset: 0 };
               input_span = 9;
               padding_style = "noPadding";
          } else if (style_id?.includes("space") && style_id?.includes("inline")) {
               /* Field inline with space but no remark */
               label_span = 2;
               // control_span = { span: '', offset: 5 };
               control_span = space;
               padding_style = "noPadding";
          } else if (style_id?.includes("inline")) {
               /* Field inline */
               label_span = 2;
               padding_style = "pr-2 pl-0";
               input_span = xl;
               lg = null;
          }
          /* Field primary */

          switch (type) {
               case "text":
                    return (
                         <Form.Group id={style_id ? `text-field${style_id}` : "text-field"} as={Col} xs={xs} lg={lg} md={md}>
                              {title ? (
                                   <Form.Label as={Col} lg={label_span} md={label_span} className={padding_style}>
                                        <strong>{title}</strong>
                                        {require && <strong style={{ color: "red" }}>*</strong>}
                                        <span className="warning">{warning_title}</span>
                                   </Form.Label>
                              ) : null}

                              <InputGroup>
                                   <Col className="noPadding" xl={input_span} md>
                                        <Form.Control
                                             value={value}
                                             type={typeInput}
                                             name={name}
                                             required={require}
                                             placeholder={placeholder}
                                             onChange={handleChangeText}
                                             disabled={disabled}
                                             as={as}
                                             rows={textarea_rows}
                                             onKeyPress={handleEnter}
                                        />
                                   </Col>
                              </InputGroup>

                              {name === "password" ? (
                                   <span className="password-trigger" onClick={togglePassword}>
                                        {isHide === false ? "ซ่อนรหัสผ่าน" : "แสดงรหัสผ่าน"}
                                   </span>
                              ) : null}
                              <Form.Control.Feedback type="invalid">{placeholder}</Form.Control.Feedback>
                         </Form.Group>
                    );
               case "select":
                    return (
                         <Form.Group id={style_id ? `select-field${style_id}` : "select-field"} as={Col} lg={lg} md={md}>
                              {title ? (
                                   <Form.Label as={Col} lg={label_span} md={label_span} className={padding_style}>
                                        <strong>{title}</strong>
                                        {require && <strong style={{ color: "red" }}>*</strong>}
                                   </Form.Label>
                              ) : null}
                              <InputGroup>
                                   <Col className="noPadding" xl={input_span} md>
                                        <Form.Control
                                             as="select"
                                             name={name}
                                             onChange={handleChangeText}
                                             // defaultValue={value ? value : ''}
                                             value={value ? value : all ? "all" : ""}
                                             required={require}
                                             disabled={disabled}
                                             onKeyPress={handleEnter}
                                        >
                                             <option disabled value={""}>{`กรุณาเลือก ${title}`}</option>

                                             {/* <option disabled value={''}>{`กรุณาเลือก ${title}`}</option> */}
                                             {option.map((item, i) => (
                                                  <option value={item.value} key={i + 1} hidden={hidden ? item.hidden : null}>
                                                       {item.name}
                                                  </option>
                                             ))}
                                        </Form.Control>
                                   </Col>
                              </InputGroup>
                         </Form.Group>
                    );
               case "typeahead":
                    return (
                         <Form.Group id={style_id ? `select-field${style_id}` : "select-field"} as={Col} lg={lg} md={md}>
                              {title ? (
                                   <Form.Label as={Col} lg={label_span} md={label_span} className={padding_style}>
                                        <strong>{title}</strong>
                                        {require && <strong style={{ color: "red" }}>*</strong>}
                                   </Form.Label>
                              ) : null}
                              <InputGroup>
                                   <Col className="noPadding" xl={input_span} md>
                                        <Typeahead
                                             id="basic-typeahead-multiple"
                                             labelKey="name"
                                             multiple
                                             required={require}
                                             onChange={setMultiSelections}
                                             options={option}
                                             placeholder={placeholdText}
                                             selected={multiSelections}
                                        />
                                   </Col>
                              </InputGroup>
                         </Form.Group>
                    );
               case "file":
                    return (
                         <Form.Group as={Col} md={md} className={style_id}>
                              {title ? (
                                   <Form.Label as={Col} lg={label_span} md={label_span} className={padding_style}>
                                        {title.icon ? <Image className="icon" src={title.icon} /> : null}
                                        <strong>{title.name}</strong>
                                   </Form.Label>
                              ) : null}
                              <Form.File custom>
                                   {accept ? (
                                        <Form.File.Input onChange={handleChange} required={true} accept={accept} />
                                   ) : (
                                        <Form.File.Input onChange={handleChange} />
                                   )}

                                   <Form.File.Label data-browse={button}>{placeholder}</Form.File.Label>
                              </Form.File>
                         </Form.Group>
                    );
               case "date":
                    return (
                         <Form.Group as={Col} md={md}>
                              {title === "" ? (
                                   <></>
                              ) : (
                                   <Form.Label as={Col} lg={label_span} md={label_span} className={padding_style}>
                                        <strong>{title}</strong>
                                        {require && <strong style={{ color: "red" }}>*</strong>}
                                   </Form.Label>
                              )}
                              {handleSelectDate ? (
                                   <DatePicker
                                        // defaultValue={dayjs("2563")}
                                        className={validated ? (value && validated ? "date green ant-picker" : "date red ant-picker") : ""}
                                        name={name}
                                        placeholder={placeholder}
                                        onChange={(date, dateString) => {
                                             handleSelectDate(date, dateString, name);
                                        }}
                                        format={formatDate}
                                        required={require}
                                        locale={locale}
                                        disabled={disabled}
                                        picker={picker}
                                        value={value}
                                        disabledDate={disabledDate ? disabledDate : disDate ? disabledFixDate : null}
                                   />
                              ) : (
                                   <DatePicker
                                        name={name}
                                        placeholder={placeholder}
                                        onChange={handleSelectDate}
                                        format={formatDate}
                                        required={require}
                                        disabled={disabled}
                                        locale={locale}
                                        picker={picker}
                                        value={value}
                                   />
                              )}
                         </Form.Group>
                    );
               case "number":
                    return (
                         <Form.Group id={style_id ? `number-field${style_id}` : "number-field"} className={padding_style} as={Col} lg={lg} md={md}>
                              {title ? (
                                   <Form.Label as={Col} lg={label_span} md={12} className={padding_style}>
                                        <strong>{title}</strong>
                                        {require && <strong style={{ color: "red" }}>*</strong>}
                                        <span className="warning">{warning_title}</span>
                                   </Form.Label>
                              ) : null}

                              <InputGroup>
                                   {remark?.type === "radio" &&
                                        remark.label.map((el, i) => (
                                             <Col key={i} lg={2} md={6} className="noPadding">
                                                  <Form.Check
                                                       id={id}
                                                       name={el.text ? el.el.text : name}
                                                       type="radio"
                                                       label={el.text}
                                                       checked={remark.check === el.text}
                                                       // name={el.text}
                                                       onChange={remark.handleChangeCheck}
                                                       disabled={remark.disabled}
                                                  />
                                             </Col>
                                        ))}
                                   {remark?.type === "field" && (
                                        <Col className="noPadding" xl={3} lg={3} md={6}>
                                             <Form.Control
                                                  id={id}
                                                  name={remark.name ? remark.name : name}
                                                  // name={remark.name}
                                                  required={remark.require}
                                                  placeholder={remark.placeholder}
                                                  onChange={remark.handleChangeText}
                                                  value={remark.value}
                                                  disabled={remark.disabled}
                                             />
                                        </Col>
                                   )}

                                   {remark && <></>}
                                   {reciept ? (
                                        <>
                                             <Col className="noPadding d-flex" xl={control_span} lg={control_span} md={control_span_md}>
                                                  <>
                                                       <NumberFormat
                                                            placeholder={placeholder}
                                                            value={value}
                                                            format={format}
                                                            mask={mask}
                                                            type={typeInput}
                                                            onValueChange={handleChangeNumber}
                                                            thousandSeparator={!typeInput}
                                                            customInput={Form.Control}
                                                            decimalScale={decimalScale}
                                                            fixedDecimalScale={decimalScale}
                                                            suffix={suffix}
                                                            disabled={disabled}
                                                            required={require}
                                                       />
                                                       {unit && unit !== "button" && (
                                                            <InputGroup.Prepend>
                                                                 <InputGroup.Text>{unit}</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                       )}
                                                       {unit && unit === "button" && (
                                                            <InputGroup.Prepend>
                                                                 <Button id="accAddBonus" onClick={accAddBonus} className="btn-button-add-order">
                                                                      +
                                                                 </Button>
                                                            </InputGroup.Prepend>
                                                       )}
                                                  </>
                                             </Col>
                                        </>
                                   ) : (
                                        <>
                                             <Col className="noPadding d-flex" xl={control_span} lg={control_span} md={control_span_md}>
                                                  <>
                                                       <NumberFormat
                                                            placeholder={placeholder}
                                                            value={value}
                                                            format={format}
                                                            mask={mask}
                                                            type={typeInput}
                                                            onValueChange={handleChangeNumber}
                                                            thousandSeparator={!typeInput}
                                                            customInput={Form.Control}
                                                            decimalScale={decimalScale}
                                                            fixedDecimalScale={decimalScale}
                                                            suffix={suffix}
                                                            disabled={disabled}
                                                            required={require}
                                                       />
                                                       {unit && unit !== "button" && (
                                                            <InputGroup.Prepend>
                                                                 <InputGroup.Text>{unit}</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                       )}
                                                       {unit && unit === "button" && (
                                                            <InputGroup.Prepend>
                                                                 <Button id="accAddBonus" onClick={accAddBonus} className="btn-button-add-order">
                                                                      +
                                                                 </Button>
                                                            </InputGroup.Prepend>
                                                       )}
                                                  </>
                                             </Col>
                                        </>
                                   )}
                                   <Form.Control.Feedback type="invalid">{placeholder}</Form.Control.Feedback>
                              </InputGroup>
                         </Form.Group>
                    );
               /* Add data */
               case "add":
                    return (
                         <Form.Group as={Col} lg={lg} md={md} className="noPadding">
                              <Form.Label as={Col} lg={label_span} md={12} className={padding_style}>
                                   <strong>{title}</strong>
                              </Form.Label>

                              <InputGroup>
                                   <Form.Control
                                        value={value}
                                        name={name}
                                        required={require}
                                        placeholder={placeholder}
                                        onChange={handleChange}
                                        disabled={disabled}
                                   />
                                   <InputGroup.Prepend>
                                        <Button className="-small" variant={button.variant} onClick={button.onClick}>
                                             {button.name}
                                        </Button>
                                   </InputGroup.Prepend>
                              </InputGroup>
                         </Form.Group>
                    );
               case "summary":
                    return (
                         <Form.Group as={Col} md={md} id={"summary-field"}>
                              <Row className="noMargin">
                                   <Col xs={8} className="noPadding">
                                        <span>{sum_name}</span>
                                   </Col>
                                   <Col xs={2} id="item-number">
                                        <span>{sum_no}</span>
                                   </Col>
                                   <Col xs={2} className="noPadding">
                                        <span>รายการ</span>
                                   </Col>
                              </Row>
                         </Form.Group>
                    );
               // case 'radio':
               //      return (
               //           <Form.Group as={Col} lg={lg} md={md}>
               //                {title ? <Form.Label>{title}</Form.Label> : null}
               //                {option.map((e, i) => (
               //                     <InputGroup.Prepend>
               //                          <InputGroup.Radio name={name} value={e.name} onChange={handleChangeRadio} disabled={disabled} checked={e.name === value} />
               //                          {e.name}
               //                     </InputGroup.Prepend>
               //                ))}
               //           </Form.Group>
               //      );
               // case 'checkbox':
               //      return (
               //           <Form.Group as={Col} lg={lg} md={md}>
               //                {title ? <Form.Label>{title}</Form.Label> : null}

               //                {option ? (
               //                     option.map((e, i) => (
               //                          <InputGroup.Prepend>
               //                               <InputGroup.Checkbox onChange={handleChange} name={name} value={e.value} checked={checked} disabled={disabled} />
               //                               {e.name}
               //                          </InputGroup.Prepend>
               //                     ))
               //                ) : (
               //                     <InputGroup.Prepend>
               //                          <InputGroup.Checkbox
               //                               // onChange={handleChange ? (e) => handleChange(e, name, value) : null}
               //                               onChange={(checked, row) => {
               //                                    handleChange(checked, row, rowIndex);
               //                               }}
               //                               name={name}
               //                               value={value}
               //                               checked={checked}
               //                               disabled={disabled}
               //                          />
               //                     </InputGroup.Prepend>
               //                )}
               //           </Form.Group>
               //      );
               case "autocomplete":
                    return (
                         <Form.Group as={Col} lg={lg} md={md}>
                              <Form.Label as={Col}>
                                   <strong>{title}</strong>
                                   {require && <strong style={{ color: "red" }}>*</strong>}
                                   <span className="warning">{warning_title}</span>
                              </Form.Label>
                              <Typeahead
                                   className={
                                        validated
                                             ? validated && value
                                                  ? "type green was-validated form-control:valid"
                                                  : "type red was-validated form-control:valid"
                                             : ""
                                   }
                                   id={name}
                                   options={option ? option : []}
                                   placeholder={placeholder}
                                   onChange={handleChangeAutocomplete}
                                   onInputChange={handleOnInputChange}
                                   defaultInputValue={value}
                              />
                              {/* <Form.Control.Feedback type="invalid" required={require}>{placeholder}</Form.Control.Feedback> */}
                         </Form.Group>
                    );
               default:
                    return <React.Fragment></React.Fragment>;
          }
     }
}
