import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Img, Step, Skeleton, Loader } from "components/index";
import { Form, Button, Col, Row, Tabs, Tab, Image } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import { POST, GET, ip } from "api/index";
import { useEffect } from "react";
import axios from "axios";
import InputPanel from "components/input-panel/input-panel";
import { getToken, getToken_permission_page, add } from "../../../../../static/function";
import Spinner from "react-bootstrap/Spinner";

// import { algo } from "crypto-js";

function ScrollToTopOnMount() {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

async function removeFile(id) {
     try {
          // await POST("/order/delete_file", { id: Number(id) });
          window.location.reload();
     } catch (err) {
          console.log(err);
     }
}

/* ----------------------- Formatter --------------------------- */
function totalPrice(columnData) {
     let total = columnData.reduce((acc, item) => acc + item, 0);
     total = parseFloat(total).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     return total;
}

function parseFloatFormatter(cell, row) {
     let new_cell = parseFloat(cell).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
     });
     if (row.field === "summary-total") {
          return <span className="text">{new_cell}</span>;
     } else {
          return new_cell;
     }
}

function unitFormatter() {
     return <p>บาท</p>;
}

// function noteFormatter(cell, row, rowIndex, formatExtraData) {
//      if (row.price && row.title !== 'รวม Margin ที่ใช้ทั้งสิ้น') {
//           return <p>ของแถม</p>;
//      } else if (row.price === 0 && row.title !== 'รวม Margin ที่ใช้ทั้งสิ้น') {
//           return <p>ลูกค้าซื้อเอง</p>;
//      } else {
//           return null;
//      }
// }

function noFormatter(cell, row, rowIndex, formatExtraData) {
     return <p>{rowIndex + 1}</p>;
}

function dateFormatter(cell, row, rowIndex, formatExtraData) {
     if (cell) {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     } else {
          return <p>-</p>;
     }
}

function printFormatter(cell, row, rowIndex, formatExtraData) {
     switch (formatExtraData) {
          case "file":
               if (row.file) {
                    return <i className="fas fa-print icon __btn active" onClick={() => window.open("https://www.google.com")}></i>;
               } else {
                    return <i className="fas fa-print icon"></i>;
               }

          default:
               if (row.file) {
                    return <i className="fas fa-print icon __btn active" onClick={() => window.location.assign(`/admin/print/acc/${row.title}`)}></i>;
               } else {
                    return <i className="fas fa-print icon"></i>;
               }
     }
}

function removeBtnFormatter(cell, row) {
     return <i className="fas fa-trash-alt icon __btn __danger" onClick={() => removeFile(row.id)}></i>;
}

function docFormatter(cell, row) {
     if (row.file) {
          return (
               <span className="doc active">
                    <i className="far fa-file-alt icon active"></i>
                    {row.title}
               </span>
          );
     } else {
          return (
               <span className="doc">
                    <i className="far fa-file-alt icon"></i>
                    {row.title}
               </span>
          );
     }
}

function detailFormatter(cell, row, rowIndex, formatExtraData) {
     switch (formatExtraData) {
          case "add_data":
               if (!row.file) {
                    return <a>กรอกข้อมูล</a>;
               } else {
                    return <span className="doc">-</span>;
               }
          case "edit_history":
               if (row.log_do === "เพิ่ม") {
                    return null;
               } else {
                    return <Button>ดูการแก้ไข</Button>;
               }
          case "log_status":
               if (row.log_status === "ดำเนินการ") {
                    return <span className="success">{cell}</span>;
               } else {
                    return <span>{cell}</span>;
               }
          case "document":
               if (row.detail === "กรอกข้อมูล") {
                    return <a>{"กรอกข้อมูล"}</a>;
               } else {
                    return <span>{"-"}</span>;
               }
          case "workflow":
               return <span>{cell}</span>;
     }
}

function workflowFormatter(cell, row) {
     if (row.file) {
          return <a>{cell}</a>;
     } else {
          return <span className="doc">-</span>;
     }
}

function fieldNumber(row, margin_total, order, dataFun, margin_over, handleChangeNumber, position_id_data) {
     const fieldMargin = (disabled) => {
          return (
               <Field
                    type={"number"}
                    placeholder={"ส่วนเกิน Margin ที่อนุมัติได้"}
                    value={order.acept_margin ? order.acept_margin : margin_total || 0}
                    disabled={disabled}
                    handleChangeNumber={(e) => handleChangeNumber(e, "acept_margin")}
               />
          );
     };
     if (!order.orderMargin.find((el) => el.event === "ไม่อนุมัติ") && order.orderMargin.length > 0) {
          let disabled = false;
          if (order.lvl_margin === 1 && row.function_id === 1 && position_id_data === 17) {
               if (order.orderMargin[0].event === "อนุมัติ" || order.orderMargin[0].event === "ไม่อนุมัติ") {
                    disabled = true;
               }
               if (margin_over <= row.max) {
                    return fieldMargin(disabled);
               }
          } else if (order.lvl_margin === 2 && row.function_id === 2 && position_id_data === 9) {
               if (order.orderMargin[1].event === "อนุมัติ" || order.orderMargin[1].event === "ไม่อนุมัติ") {
                    disabled = true;
               }
               if (margin_over <= row.max) {
                    return fieldMargin(disabled);
               }
          } else if (order.lvl_margin === 3 && row.function_id === 3 && position_id_data === 2) {
               if (order.orderMargin[2].event === "อนุมัติ" || order.orderMargin[2].event === "ไม่อนุมัติ") {
                    disabled = true;
               }
               if (margin_over <= row.max) {
                    return fieldMargin(disabled);
               }
          } else if (order.lvl_margin === 4 && row.function_id === 4 && position_id_data === 1) {
               if (order.orderMargin[3].event === "อนุมัติ" || order.orderMargin[3].event === "ไม่อนุมัติ") {
                    disabled = true;
               }
               if (margin_over <= row.max) {
                    return fieldMargin(disabled);
               }
          }
     }
}

/* ข้อมูลการจองรถ */
const Detail = ({
     order,
     id,
     onClick,
     show_popup,
     btn_acept,
     saveSign,
     clearSign,
     onHide,
     show_history,
     closeSellButton,
     onClickCancel,
     status_check,
     imageViewFormatter,
     dataFun,
     btnApprove,
     loading,
     onClickApprove,
     typeLeasing,
     openModalApp,
     noteModalApprove,
     onHideModal,
     onClickSubmitApprove,
}) => {
     function marginOver(columnData, overSubdown, strSubNum) {
          let total = columnData[columnData.length - 1] + columnData[columnData.length - 2] - columnData[columnData.length - 3];
          total = parseFloat(Math.abs(total)).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
          });
          return overSubdown ? `${strSubNum}\n${total}` : `${total}`;
     }
     const acc_service = {
          column: [
               {
                    dataField: "no",
                    text: "ลำดับ",
                    formatter: noFormatter,
                    align: "center",
                    headerClasses: "header-custom __left __no",
               },
               {
                    dataField: "items_name",
                    text: "รายการ Car accessories",
               },
               {
                    dataField: "items_price",
                    text: "ราคา(บาท)",
                    formatter: parseFloatFormatter,
                    headerClasses: "header-custom __right",
               },
          ],
          data: order?.accbonus,
          keyField: "no",
     };
     const conf_payment = {
          column: [
               {
                    dataField: "title",
                    text: "รายการ ",
                    headerClasses: "header-custom __left",
               },
               {
                    dataField: "price",
                    text: "ราคา",
                    headerClasses: "header-custom __btn",
                    formatter: parseFloatFormatter,
               },
               {
                    dataField: "unit",
                    text: "หน่วย",
                    formatter: unitFormatter,
                    headerClasses: "header-custom __right __btn",
               },
          ],
          data: [
               {
                    title: "ราคาเริ่มต้น",
                    price: order?.start_price ? order?.start_price : "0.00",
               },
               {
                    title: "บวกราคาตัวรถเพิ่ม",
                    price: order?.addition_price ? order?.addition_price : "0.00",
               },
               {
                    title: "ส่วนลดตัวรถ",
                    price: order?.discount_price ? order?.discount_price : "0.00",
               },
               {
                    title: "เงินจอง",
                    price: order?.order_price ? order.order_price : "0.00",
               },
               {
                    title: "ราคาซื้อขายจริง",
                    price: order?.real_price ? order?.real_price : "0.00",
               },
               {
                    title: "เงินดาวน์",
                    price: order?.down ? order?.down : "0.00",
               },
               {
                    title: "ซัพดาวน์",
                    price: order?.sub_down ? order?.sub_down : "0.00",
               },
               {
                    title: "ค่าแนะนำ",
                    price: order?.recommend_price ? order?.recommend_price : "0.00",
               },
               {
                    title: "ค่าอื่น ๆ ที่ระบุไว้",
                    price: order?.other_pay_price ? order?.other_pay_price : "0.00",
               },
          ],
          keyField: "title",
     };
     const conf_payment_leas = {
          column: [
               {
                    dataField: "title",
                    text: "รายการ ",
                    headerClasses: "header-custom __left",
               },
               {
                    dataField: "price",
                    text: "ราคา",
                    headerClasses: "header-custom __btn",
                    formatter: parseFloatFormatter,
               },
               {
                    dataField: "unit",
                    text: "หน่วย",
                    formatter: unitFormatter,
                    headerClasses: "header-custom __right __btn",
               },
          ],
          data: [
               {
                    title: "ราคาเริ่มต้น",
                    price: order?.start_price,
               },
               {
                    title: "ส่วนลด",
                    price: order?.discount_price ? Number(order.discount_price) : 0,
               },
               {
                    title: "เงินจอง",
                    price: order?.order_price ? Number(order.order_price) : 0,
               },
               {
                    title: "ราคาซื้อขายจริง",
                    price: order?.real_price,
               },
               {
                    title: "ดาวน์",
                    price: order?.down ? Number(order.down) : 0,
               },
               {
                    title: "ค่าเงินงวดแรก",
                    price: order?.first_down ? Number(order.first_down) : 0,
               },
               {
                    title: "ซัพดาวน์ (Subdown)",
                    price: order?.sub_down ? Number(order.sub_down) : 0,
               },
               {
                    title: "ค่าแนะนำ",
                    price: order?.recommend_price ? Number(order.recommend_price) : 0,
               },
               {
                    title: "ค่าอื่น ๆ ที่ระบุไว้",
                    price: order?.other_pay_price ? Number(order.other_pay_price) : 0,
               },
          ],
          keyField: "title",
     };
     const conf_promo = {
          column: [
               {
                    dataField: "no",
                    text: "ลำดับ ",
                    formatter: noFormatter,
                    align: "center",
                    headerClasses: "header-custom __left __no",
               },
               {
                    dataField: "promotion_name",
                    text: "รายการ Promotion",
                    headerClasses: "header-custom __right",
               },
          ],
          data: order?.promotions,
          keyField: "no",
     };
     const conf_acc_bonus = {
          column: [
               {
                    dataField: "no",
                    text: "ลำดับ ",
                    footer: "",
                    formatter: noFormatter,
                    align: "center",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __left __no",
               },
               {
                    dataField: "items_code",
                    text: "รหัสสินค้า",
                    footer: "",
                    headerClasses: "header-custom __btn-ss",
                    footerClasses: "summary-custom",
               },
               {
                    dataField: "items_name",
                    text: "รายการของแถม",
                    footer: "รวม",
                    headerClasses: "header-custom __btn-ml",
                    footerClasses: "summary-custom",
               },
               {
                    dataField: "order_ac_amount",
                    text: "จำนวน",
                    // footer: this.totalPrice,
                    footer: () => "",
                    headerClasses: "header-custom __btn",
                    // formatter: this.parseFloatFormatter,
                    footerClasses: "summary-custom __number-blue",
               },
               // {
               //      dataField: "price",
               //      text: "ราคา",
               //      footer: totalPrice,
               //      headerClasses: "header-custom __btn",
               //      formatter: parseFloatFormatter,
               //      headerClasses: "header-custom __btn-s",
               //      footerClasses: "summary-custom __number-blue",
               // },
               {
                    dataField: "order_ac_price",
                    text: "ราคา",
                    // footer: this.totalPrice,
                    footer: () =>
                         order.accbonus
                              .map((ele) => ({ sum: ele.order_ac_amount * ele.order_ac_price }))
                              .reduce(add("sum"), 0)
                              .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    headerClasses: "header-custom __btn",
                    // formatter: this.parseFloatFormatter,
                    formatter: (e, r) => Number(e).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    footerClasses: "summary-custom __number-blue",
               },
               {
                    dataField: "install",
                    text: "สถานะ",
                    footer: "บาท",
                    // formatter: unitFormatter,
                    headerClasses: "header-custom __btn-ss",
                    footerClasses: "summary-custom",
               },
               {
                    dataField: "acc_image",
                    text: "รูปภาพ",
                    footer: "",
                    formatter: imageViewFormatter,
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __right __btn-m",
               },
          ],
          data: order?.accbonus.map((ele) => ({ ...ele, price: ele.order_ac_amount * ele.order_ac_price })),
          keyField: "no",
     };
     const conf_acc_buy = {
          column: [
               {
                    dataField: "no",
                    text: "ลำดับ ",
                    footer: "",
                    formatter: noFormatter,
                    align: "center",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __left __no",
               },
               {
                    dataField: "items_code",
                    text: "รหัสสินค้า",
                    footer: "",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "items_name",
                    text: "รายการ",
                    footer: "รวม",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "order_ac_amount",
                    text: "จำนวน",
                    // footer: this.totalPrice,
                    footer: () => "",
                    headerClasses: "header-custom __btn",
                    // formatter: this.parseFloatFormatter,
                    footerClasses: "summary-custom __number-blue",
               },
               // {
               //      dataField: "price",
               //      text: "ราคา",
               //      footer: totalPrice,
               //      headerClasses: "header-custom __btn",
               //      formatter: parseFloatFormatter,
               //      footerClasses: "summary-custom __number-blue",
               //      headerClasses: "header-custom __btn-s",
               // },
               {
                    dataField: "order_ac_price",
                    text: "ราคา",
                    // footer: this.totalPrice,
                    footer: () =>
                         order.accbuy
                              .map((ele) => ({ sum: ele.order_ac_amount * ele.order_ac_price }))
                              .reduce(add("sum"), 0)
                              .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    headerClasses: "header-custom __btn",
                    // formatter: this.parseFloatFormatter,
                    formatter: (e, r) => Number(e).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    footerClasses: "summary-custom __number-blue",
               },
               {
                    dataField: "install",
                    text: "สถานะ",
                    footer: "บาท",
                    // formatter: unitFormatter,
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "acc_image",
                    text: "รูปภาพ",
                    footer: "",
                    formatter: imageViewFormatter,
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __right __btn-m",
               },
          ],
          data: order?.accbuy.map((ele) => ({ ...ele, price: ele.order_ac_amount * ele.order_ac_price })),
          keyField: "no",
     };
     const conf_acc_turn = {
          column: [
               {
                    dataField: "no",
                    text: "ลำดับ ",
                    footer: "",
                    formatter: noFormatter,
                    align: "center",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __left __no",
               },
               {
                    dataField: "items_name",
                    text: "รายการเทิร์นสินค้า",
                    footer: "รวม (นำไปเป็นส่วนลด)",
                    footerClasses: "summary-custom",
               },
               {
                    dataField: "supplier_name",
                    text: "Supplier",
                    footer: "",
                    footerClasses: "summary-custom",
               },
               {
                    dataField: "order_ac_price",
                    text: "ราคา",
                    footer: totalPrice,
                    headerClasses: "header-custom __btn",
                    formatter: parseFloatFormatter,
                    footerClasses: "summary-custom __number-blue",
               },
               {
                    dataField: "unit",
                    text: "ราคาทุน (บาท)",
                    footer: "บาท",
                    formatter: unitFormatter,
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __right  __btn",
               },
          ],
          data: order?.accturn,
          keyField: "no",
     };
     const con_receipt = {
          column: [
               {
                    dataField: "receipt_method",
                    text: "รายการ",
                    footer: "รวม",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __left",
               },
               {
                    dataField: "receipt_price",
                    text: "จำนวนเงิน (บาท)",
                    footer: () => (
                         <strong style={{ fontSize: "1.25em", color: "#1d419b" }}>
                              {order.receipt.length
                                   ? order.receipt
                                          .reduce(add("receipt_price"), 0)
                                          .toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                                   : 0}
                         </strong>
                    ),
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __btn",
                    formatter: parseFloatFormatter,
               },
               {
                    text: "หน่วย",
                    formatter: unitFormatter,
                    footer: "บาท",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __right  __btn",
               },
          ],
          data: order.receipt.length ? order.receipt : [],
          keyField: "no",
     };
     const conf_calc = {
          column: [
               {
                    dataField: "title",
                    text: "รายการ",
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "price",
                    text: "ราคาขาย",
                    headerClasses: "header-custom __btn-ss",
                    formatter: parseFloatFormatter,
               },
               {
                    dataField: "unit",
                    text: "หน่วย",
                    formatter: unitFormatter,
                    headerClasses: "header-custom __right __btn-ss",
               },
          ],
          data: [
               { title: "ราคาตัวรถ", price: order?.start_price },
               { title: "ราคาตัวรถเพิ่ม", price: order?.addition_price ? Number(order.addition_price) : 0.0 },
               { title: "Margin", price: order?.margin ? Number(order.margin) : 0.0 },
          ],
          keyField: "title",
     };

     let sumAccBonus = 0;
     conf_acc_bonus.data.forEach((element) => {
          sumAccBonus += Number(element.price);
     });
     let sumAccTurn = 0;
     conf_acc_turn.data.forEach((ele) => {
          sumAccTurn += Number(ele.order_ac_price);
     });
     let turnAcc = order.accturn.map((el) => {
          return el.order_ac_price;
     });
     let totalMargin =
          Number(sumAccBonus || 0) -
          Number(sumAccTurn || 0) +
          (order?.discount_price ? order.discount_price : 0) +
          (order?.recommend_price ? Number(order.recommend_price) : 0) +
          (order?.other_pay_price ? Number(order.other_pay_price) : 0) +
          (order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0) +
          (order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0) +
          (order?.sub_down ? order.sub_down : 0) +
          (order?.subsidy ? order.subsidy : 0);

     let SubdownAndAccBonus = Number(sumAccBonus) - Number(sumAccTurn || 0) + (order?.sub_down ? order.sub_down : 0);

     let overAllMargin = order.addition_price + order.margin;
     let strMargin;
     let margin_over = 0;
     let marginColor;
     if (totalMargin <= overAllMargin) {
          strMargin = "คงเหลือ Margin";
          margin_over = 0;
          marginColor = "__number-green";
     } else {
          strMargin = "ส่วนเกิน Margin";
          margin_over = Math.abs(overAllMargin - totalMargin);
          marginColor = "__number-danger";
     }
     let overSubdown = Number(SubdownAndAccBonus) > Number(overAllMargin) - Number(totalMargin - SubdownAndAccBonus);
     let strSub;
     let strSubNum;
     if (overSubdown) {
          strSub = "ซัพดาวน์เกิน Margin";
          strSubNum = Number(Number(SubdownAndAccBonus) - (Number(overAllMargin) - Number(totalMargin - SubdownAndAccBonus))).toLocaleString(
               undefined,
               {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
               }
          );
     }
     const conf_calc_serv = {
          column: [
               {
                    dataField: "title",
                    text: "รายการของแถม",
                    footer: overSubdown ? `${strSub}\n${strMargin}` : `${strMargin}`,
                    footerClasses: "summary-custom __yellow",
                    headerClasses: "header-custom __left",
               },
               {
                    dataField: "price",
                    text: "ราคาขาย",
                    footer: (e) => marginOver(e, overSubdown, strSubNum),
                    headerClasses: "header-custom __btn",
                    formatter: parseFloatFormatter,
                    footerClasses: `summary-custom __yellow ${marginColor}`,
               },
               {
                    dataField: "unit",
                    text: "หน่วย",
                    footer: overSubdown ? "บาท\nบาท" : "บาท",
                    formatter: unitFormatter,
                    footerClasses: "summary-custom __yellow",
               },
               // {
               //      dataField: 'note',
               //      text: 'หมายเหตุ',
               //      formatter: noteFormatter,
               //      footerClasses: 'summary-custom __yellow',
               //      footer: '',
               //      headerClasses: 'header-custom __right  __btn',
               // },
          ],
          data: [
               ...conf_acc_bonus.data.map((el) => ({ field: "summary", title: el.items_name, price: el.price })),
               { field: "summary", title: "รวมราคาอุปกรณ์", price: sumAccBonus },
               ...conf_acc_turn.data.map((el) => ({ field: "summary", title: el.items_name, price: Number(el.order_ac_price) || 0 })),
               { field: "summary", title: "รวมราคาเทิร์นอุปกรณ์ (ล้อ)", price: sumAccTurn || 0 },
               { field: "summary", title: "ส่วนลดตัวรถ", price: order?.discount_price ? order.discount_price : 0 },
               { field: "summary", title: "ซัพดาวน์", price: order?.sub_down ? order.sub_down : 0 },
               { field: "summary", title: "Subsidy", price: order?.subsidy ? order.subsidy : 0 },
               {
                    field: "summary",
                    title: "ประกัน (แถม)",
                    price: order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0,
                    payer: order.insurance_pay,
               },
               {
                    field: "summary",
                    title: "ค่าจดทะเบียน (แถม)",
                    price: order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0,
                    payer: order.register_pay,
               },
               { field: "summary", title: "ค่าแนะนำ (แถม)", price: order?.recommend_price ? Number(order.recommend_price) : 0 },
               { field: "summary", title: "ค่าอื่นๆ (แถม)", price: order?.other_pay_price ? Number(order.other_pay_price) : 0 },
               {
                    field: "summary-total",
                    title: "ใช้ไปทั้งสิ้น",
                    price: Number(totalMargin),
               },
               { field: "summary-total", title: "หักราคาตัวรถที่บวกเพิ่ม", price: order?.addition_price ? Number(order.addition_price) : 0 },
               { field: "summary-total", title: "หัก margin", price: order?.margin ? Number(order.margin) : 0 },
          ],
          keyField: "title",
     };
     let sumAccBBuy = 0;
     conf_acc_buy.data.forEach((element) => {
          sumAccBBuy += element.price ? Number(element.price) : 0;
     });
     const conf_final_price = {
          column: [
               {
                    dataField: "no",
                    text: "ลำดับ ",
                    footer: "",
                    formatter: noFormatter,
                    align: "center",
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __left __no",
               },
               {
                    dataField: "title",
                    text: "รายการ",
                    footer: "Final Price",
                    footerClasses: "summary-custom",
               },
               {
                    dataField: "price",
                    text: "ราคา",
                    footer: totalPrice,
                    headerClasses: "header-custom __btn",
                    formatter: parseFloatFormatter,
                    footerClasses: "summary-custom __number-blue",
               },
               {
                    dataField: "unit",
                    text: "หน่วย",
                    footer: "บาท",
                    formatter: unitFormatter,
                    footerClasses: "summary-custom",
                    headerClasses: "header-custom __right  __btn",
               },
          ],
          data: [
               {
                    title: "ราคารถ",
                    price: Number(order?.start_price),
               },
               {
                    title: "ราคาที่บวกเพิ่ม",
                    price: order?.addition_price ? Number(order.addition_price) : 0,
               },
               {
                    title: "ส่วนลดตัวรถ",
                    price: order?.discount_price ? Number(-order.discount_price) : 0,
               },
               {
                    title: "ประกัน",
                    price: order.insurance === "Yes" && order.insurance_pay === "ลูกค้า" ? Number(order?.insurance_premium) : 0,
               },
               {
                    title: "พ.ร.บ.",
                    price: order.act === "Yes" && order.act_pay === "ลูกค้า" ? Number(order?.act_premium) : 0,
               },
               {
                    title: "ค่าจดทะเบียน",
                    price: order.register === "Yes" && order.register_pay === "ลูกค้า" ? Number(order?.register_price) : 0,
               },
               {
                    title: "ค่าอุปกรณ์ซื้อเอง",
                    price: sumAccBBuy,
               },
               // {
               //      title: 'ส่วนต่างที่ลูกค้าจ่าย',
               //      price: order?.customer_pay ? Number(order.customer_pay) : 0,
               // },
               // { title: 'ราคาประเมินเทิร์นรถยนต์', price: order?.car_turn_price ? -Number(order.car_turn_price) : 0 },
               // {
               //      title: 'ราคาเทิร์นอุปกรณ์',
               //      price: turnAcc.length > 0 ? -Number(turnAcc.reduce((accumulator, currentValue) => accumulator + currentValue)) : 0,
               // },
               { title: "ค่าอื่นๆ ที่ระบุไว้", price: order?.other_pay_price ? Number(order.other_pay_price) : 0 },
               // { title: "รวม Margin ที่ใช้", price: -totalMargin },
          ],
          keyField: "no",
     };
     const app = {
          column: [
               { dataField: "no", text: "No.", headerClasses: "header-custom __left __no", formatter: noFormatter },
               {
                    dataFileld: "btn",
                    text: "จัดการ",
                    formatter: (cell, row, rowIndex) => btnApprove(row, margin_over, onClickApprove, order, dataFun, totalMargin, rowIndex),
                    // headerClasses: "header-custom __right",
               },
               { dataField: "function_name", text: "รายการ" },
               {
                    dataField: "min",
                    text: "ค่าต่ำสุด",
                    formatter: (e) => e.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
               },
               {
                    dataField: "max",
                    text: "ค่าสูงสุด",
                    formatter: (e) => e.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    headerClasses: "header-custom __right",
               },
               // {
               //      dataField: "acept_margin",
               //      text: "",
               //      formatter: (cell, row, rowIndex) => fieldNumber(row, margin_total, order, dataFun, margin_over, handleChangeNumber, position_id_data),
               // },
               // {
               //      dataFileld: "btn",
               //      text: "จัดการ",
               //      formatter: (cell, row, rowIndex) => btnApprove(row, margin_over, onClickApprove, order, dataFun, totalMargin, rowIndex),
               //      headerClasses: "header-custom __right",
               // },
          ],
          data: dataFun ? dataFun : [],
          keyField: "Function",
     };
     const table_acc = {
          column: [
               {
                    dataField: "index",
                    text: "ลำดับที่",
                    headerClasses: "header-custom __left __btn-ss",
               },
               {
                    dataField: "items_name",
                    text: "รายการ",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "items_type",
                    text: "ประเภท",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "supplier_name",
                    text: "บริษัท",
                    headerClasses: "header-custom __right __btn-m",
               },
          ],
          data2: order.acc_installed ? JSON.parse(order.acc_installed).map((e, i) => ({ ...e, index: i + 1, items_type: "-" })) : [],
          keyField: "index",
     };
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    {/* <div className="content"> */}
                    {/* ข้อมูลโชว์รูม */}
                    <div className="content">
                         <LabelBar type={"title"} title={"ข้อมูลโชว์รูม"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "โชว์รูม : ",
                                             detail: order?.branch_name,
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เลขที่ใบจอง :",
                                             detail: order?.order_code_id,
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "พนักขาย : ",
                                             detail: order.saler_name_string ? order.saler_name_string : "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่สร้างใบจอง :",
                                             detail: order.created_at ? dateFormatter(order.created_at) : "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "หมายเลขโทรศัพท์ :",
                                             detail: order?.seller_phone || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             // name: "วันที่คาดว่าจะได้รับรถ : ",
                                             name: "วันส่งมอบรถยนต์ : ",
                                             detail: order.makeup_date ? dateFormatter(order?.makeup_date) : "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              {order.cancel_order_date ? (
                                   <Row>
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "วันที่ยกเลิกใบจอง : ",
                                                  detail: order.cancel_order_date ? dateFormatter(order.cancel_order_date) : "-",
                                             }}
                                             md={6}
                                        />
                                   </Row>
                              ) : null}
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เลขที่จากใบจอง Manual และ GMS (ถ้ามี) :",
                                             detail: order.order_manual_code || "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* รายละเอียดผู้ซื้อ */}
                    <div className="content">
                         <LabelBar type={"title"} title={"รายละเอียดผู้ซื้อ"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "A-Card No. : ",
                                             detail: order?.userinfo_a_card,
                                        }}
                                        md={12}
                                   />
                                   {order.userinfo_customer_type === "นิติบุคคล" ? (
                                        <>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ชื่อ ห้าง/ร้าน/บริษัท :",
                                                       detail: order?.userinfo_name,
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเลขประจำตัวผู้เสียภาษี :",
                                                       detail: order?.userinfo_idcard,
                                                  }}
                                                  md={12}
                                             />
                                        </>
                                   ) : (
                                        <>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ชื่อ-นามสกุล ผู้สั่งจอง :",
                                                       detail: (order.userinfo_prefix ? order.userinfo_prefix : "") + order?.userinfo_name,
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเลขประจำตัว :",
                                                       detail: order?.userinfo_idcard,
                                                  }}
                                                  md={12}
                                             />
                                        </>
                                   )}
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "หมายเลขโทรศัพท์ :",
                                             detail: order?.userinfo_tel,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ID LINE :",
                                             detail: order?.userinfo_line,
                                        }}
                                        md={12}
                                   />
                                   {/* {order.userinfo_customer_type !== "นิติบุคคล" && ( */}
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: order.userinfo_customer_type !== "นิติบุคคล" ? "วันเกิด :" : "วันจดทะเบียนพาณิชย์",
                                             detail: order.userinfo_birth_date ? dateFormatter(order?.userinfo_birth_date) : "-",
                                        }}
                                        md={12}
                                   />
                                   {/* )} */}

                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ที่อยู่ :",
                                             detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ที่อยู่ที่ใช้ติดต่อ :",
                                             detail: `${order?.contact_address} ตำบล/แขวง ${order?.contact_sub_district} อำเภอ/เขต ${order?.contact_district} จังหวัด ${order?.contact_province} ${order?.contact_zipcode}`,
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* Owner Detail */}
                    <div className="content">
                         <LabelBar type={"title"} title={"รายละเอียดผู้ถือครอง"} />
                         <div className="-padding">
                              <Row>
                                   {order.userinfo_customer_type === "นิติบุคคล" ? (
                                        <>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ชื่อ ห้าง/ร้าน/บริษัท :",
                                                       detail: order?.owner_name,
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเลขประจำตัวผู้เสียภาษี :",
                                                       detail: order?.owner_idcard,
                                                  }}
                                                  md={12}
                                             />
                                        </>
                                   ) : (
                                        <>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ชื่อ-นามสกุล ผู้ถือครอง : ",
                                                       detail: (order.owner_prefix ? order.owner_prefix : "") + order?.owner_name,
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเลขประจำตัว : ",
                                                       detail: order?.owner_idcard,
                                                  }}
                                                  md={12}
                                             />
                                        </>
                                   )}
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ที่อยู่ : ",
                                             detail: `${order?.owner_address} ตำบล/แขวง ${order?.owner_sub_district} อำเภอ/เขต ${order?.owner_district} จังหวัด ${order?.owner_province} ${order?.owner_zipcode}`,
                                        }}
                                        md={12}
                                   />
                                   {order.userinfo_customer_type !== "นิติบุคคล" && (
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "ความสัมพันธ์กับผู้จอง : ",
                                                  detail: order?.relationship || "-",
                                             }}
                                             md={12}
                                        />
                                   )}
                              </Row>
                         </div>
                    </div>
                    {/* Car Series */}
                    <div className="content">
                         <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Serie : ",
                                             detail: order?.series_name,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Model : ",
                                             detail: order?.model_name,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "สี : ",
                                             detail: order?.color_name,
                                        }}
                                        md={12}
                                   />
                                   {/* <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "สีที่ 2 : ",
                                             detail: order?.color_id_2 ? order?.color_id_2 : "-",
                                        }}
                                        md={12}
                                   /> */}
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Model Code : ",
                                             detail: order?.model_code,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เลขเครื่อง : ",
                                             detail: order?.car_engine || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เลขถัง : ",
                                             detail: order?.car_tank || "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* Payment */}
                    {order?.pay_choice === "เงินสด" ? (
                         <>
                              <div className="content">
                                   <LabelBar type={"title"} title={"การชำระเงิน"} />
                                   <div className="-padding">
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วิธีชำระเงิน : ",
                                                       detail: order?.pay_choice || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "จำนวนวันที่ให้เครดิต : ",
                                                       detail: `${order?.day_credit ? `${order.day_credit} วัน` : "-"} `,
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                   </div>
                              </div>
                              <div className="content" id="table-content">
                                   <div className="-padding">
                                        <Table
                                             type="summary"
                                             column={conf_payment.column}
                                             data={conf_payment.data}
                                             keyField={conf_payment.keyField}
                                        />
                                   </div>
                              </div>
                              <div className="content">
                                   <div className="-padding">
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเหตุ : ",
                                                       detail: order?.other_note ? order?.other_note : "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                   </div>
                              </div>
                         </>
                    ) : (
                         <>
                              <div className="content">
                                   <LabelBar type={"title"} title={"การชำระเงิน"} />
                                   <Row>
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "วิธีชำระเงิน : ",
                                                  detail: order?.pay_choice || "-",
                                             }}
                                             md={6}
                                        />
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "บริษัท Leasing : ",
                                                  detail: order?.leasing_down_name || "-",
                                             }}
                                             md={6}
                                        />
                                   </Row>
                                   <Row>
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "ตัวแทน : ",
                                                  detail: order?.leasing_down_item_name || "-",
                                             }}
                                             md={6}
                                        />
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "Leasing : ",
                                                  detail:
                                                       order.leasing_down_item_id && typeLeasing
                                                            ? `${
                                                                   typeLeasing.find(
                                                                        (e) => Number(e.leasinge_id) === Number(order.leasing_down_leasing_id)
                                                                   )?.leasing_type
                                                              } ดอกเบี้ย ${
                                                                   typeLeasing.find(
                                                                        (e) => Number(e.leasinge_id) === Number(order.leasing_down_leasing_id)
                                                                   )?.leasing_interest
                                                              }`
                                                            : "-",
                                             }}
                                             md={6}
                                        />
                                   </Row>
                              </div>
                              <div className="content" id="table-content">
                                   <Table
                                        type="summary"
                                        column={conf_payment_leas.column}
                                        data={conf_payment_leas.data}
                                        keyField={conf_payment_leas.keyField}
                                   />
                              </div>
                              <div className="content">
                                   <Row>
                                        <LabelBar
                                             type={"textheader"}
                                             title={{
                                                  name: "หมายเหตุ : ",
                                                  detail: order?.other_note ? order.other_note : "-",
                                             }}
                                             md={6}
                                        />
                                   </Row>
                              </div>
                         </>
                    )}
                    {/* Insurance */}
                    <div className="content">
                         <LabelBar type={"title"} title={"การประกันภัย"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ต้องการทำประกันภัย : ",
                                             detail: order?.insurance,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "บริษัทประกันภัย : ",
                                             detail: order?.insurance !== "No" ? order?.supplier_insurance_name : "-",
                                        }}
                                        md={12}
                                   />
                                   {/* <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ผู้ติดต่อ : ",
                                             detail: order?.insurance !== "No" ? order?.item_insurance_name : "-",
                                        }}
                                        md={12}
                                   /> */}
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ประเภทของการประกัน : ",
                                             detail: order?.insurance !== "No" ? (order.insurance_type ? order.insurance_type : "-") : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เบี้ยประกัน : ",
                                             detail: `${
                                                  order?.insurance !== "No" && order.insurance_premium
                                                       ? order?.insurance_premium.toLocaleString(undefined, {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                         })
                                                       : "0.00"
                                             } บาท`,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ผู้ชำระเงิน : ",
                                             detail: order?.insurance !== "No" ? order?.insurance_pay : "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* ACT */}
                    <div className="content">
                         <LabelBar type={"title"} title={"พ.ร.บ."} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ต้องการทำ พ.ร.บ. : ",
                                             detail: order?.act,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "บริษัทประกันภัย : ",
                                             detail: order?.act !== "No" ? order.act_company || "-" : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ประเภท พ.ร.บ. : ",
                                             detail:
                                                  order?.act !== "No"
                                                       ? order.act_name
                                                            ? order.act_name
                                                            : order.act_premium
                                                            ? order.act_type &&
                                                              order.act_type.find((e) => e.act_premium === order.act_premium).act_name
                                                            : "-"
                                                       : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เบี้ย พ.ร.บ. : ",
                                             detail: `${
                                                  order?.act !== "No" && order.act_premium
                                                       ? order?.act_premium.toLocaleString(undefined, {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                         })
                                                       : "0.00"
                                             }  บาท`,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ผู้ชำระเงิน : ",
                                             detail: order?.act !== "No" ? order?.act_pay : "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* Register */}
                    <div className="content">
                         <LabelBar type={"title"} title={"การจดทะเบียน"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ต้องการให้จดทะเบียน : ",
                                             detail: order?.register,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ค่าจดทะเบียน : ",
                                             detail: `${
                                                  order?.register !== "No" && order?.register_price
                                                       ? order?.register_price.toLocaleString(undefined, {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                         })
                                                       : "0.00"
                                             }  บาท`,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ผู้ชำระเงิน : ",
                                             detail: order?.register !== "No" ? order?.register_pay : "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* ป้ายแดง */}
                    <div className="content">
                         <LabelBar type={"title"} title={"ป้ายแดง"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ต้องการป้ายแดง : ",
                                             detail: order?.red_label,
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เลือกเลขทะเบียน : ",
                                             detail: (order.label_no && order.label_no) || "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* Promotion Set */}
                    <div className="content">
                         <LabelBar type={"title"} title={"Promotion Set"} />
                    </div>
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type={"custom"} column={conf_promo.column} data={conf_promo.data} keyField={conf_promo.keyField} />
                         </div>
                    </div>
                    {table_acc.data2.length
                         ? order.acept_status !== "ติดตั้งอุปกรณ์แล้ว" &&
                           order.acept_status !== "ชำระเงินแล้ว" &&
                           order.acept_status !== "ส่งมอบรถแล้ว" &&
                           order.acept_status !== "ปิดการขาย" && (
                                <>
                                     {/* Car Acced */}
                                     <div className="content">
                                          <LabelBar type={"title"} title={"Car Accessories (ติดมากับตัวรถ)"} color="red" />
                                     </div>
                                     <div className="content" id="table-content">
                                          <div className="-padding">
                                               <Table
                                                    type={"summary-no-padding"}
                                                    column={table_acc.column}
                                                    data={table_acc.data2}
                                                    keyField={table_acc.keyField}
                                               />
                                          </div>
                                     </div>
                                </>
                           )
                         : null}

                    {/* Car Accessories bunus */}
                    <div className="content">
                         <LabelBar type={"title"} title={"Car Accessories (แถม)"} />
                    </div>
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type={"summary"} column={conf_acc_bonus.column} data={conf_acc_bonus.data} keyField={conf_acc_bonus.keyField} />
                         </div>
                    </div>
                    {/* Car Accessories buy */}
                    <div className="content">
                         <LabelBar type={"title"} title={"Car Accessories (ลูกค้าซื้อเอง)"} />
                    </div>
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type={"summary"} column={conf_acc_buy.column} data={conf_acc_buy.data} keyField={conf_acc_buy.keyField} />
                         </div>
                    </div>
                    {/* turn */}
                    <>
                         <div className="content">
                              <LabelBar type={"title"} title={"เทิร์นสินค้า"} />
                         </div>
                         <div className="content" id="table-content">
                              <div className="-padding">
                                   {order.brand && order.series_brand && order.year && order.car_turn_price && (
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Brand รถ : ",
                                                       detail: order?.brand || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รุ่นรถตาม Brand : ",
                                                       detail: order?.series_brand || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ปีรถ : ",
                                                       detail: order?.year || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ราคาประเมิน : ",
                                                       detail:
                                                            Number(order?.car_turn_price).toLocaleString(undefined, {
                                                                 maximumFractionDigits: 2,
                                                                 minimumFractionDigits: 2,
                                                            }) || "-",
                                                  }}
                                                  md={12}
                                             />
                                        </Row>
                                   )}
                                   {order.accturn && order.accturn.length > 0 && (
                                        <Table
                                             type={"summary"}
                                             column={conf_acc_turn.column}
                                             data={conf_acc_turn.data}
                                             keyField={conf_acc_turn.keyField}
                                        />
                                   )}
                              </div>
                         </div>
                    </>
                    {/* ส่วนต่างที่ลูกจ่ายเอง */}
                    {/* <div className="content">
                         <LabelBar type={'title'} title={'ส่วนต่างที่ลูกค้าจ่ายเอง'} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={'textheader'}
                                        title={{
                                             name: 'ส่วนต่างที่ลูกค้าจ่ายเอง : ',
                                             detail: `${order?.customer_pay
                                                       ? order.customer_pay.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                       })
                                                       : '0.00'
                                                  }  บาท`,
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div> */}
                    {/* เงินจอง */}
                    <div className="content">
                         <LabelBar type={"title"} title={"เงินจอง"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "เงินจอง ",
                                             detail: `${
                                                  order?.order_price
                                                       ? order.order_price.toLocaleString(undefined, {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                         })
                                                       : "0.00"
                                             }  บาท`,
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "สถานะเงินจอง : ",
                                             detail: order?.orp_status ? order?.orp_status : "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   {/* <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Vat เงินจอง : ",
                                             detail: `${
                                                  order?.order_vat
                                                       ? order.order_vat.toLocaleString(undefined, {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                         })
                                                       : "0.00"
                                             }  บาท`,
                                        }}
                                        md={6}
                                   /> */}
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ชำระค่าจอง : ",
                                             detail: order?.orp_date ? order?.orp_date : "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                         </div>
                    </div>
                    {/* turn1 */}
                    <>
                         <div className="content">
                              <LabelBar type={"title"} title={"รายละเอียดเงินรับชั่วคราว"} />
                         </div>
                         <div className="content" id="table-content">
                              <div className="-padding">
                                   <Table type={"summary"} column={con_receipt.column} data={con_receipt.data} keyField={con_receipt.keyField} />
                              </div>
                         </div>
                    </>
                    {/* หมายเหตุ */}
                    <div className="content">
                         <LabelBar type={"title"} title={"หมายเหตุ"} />
                         <Row className="noMargin">
                              <Col xl={12}>{order?.note ? order.note : "-"}</Col>
                         </Row>
                    </div>
                    {/* Calculate Margin */}
                    <div className="content">
                         <LabelBar type={"title"} title={"Calculate Margin"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar type={"textheader"} title={{ name: "สถานะการอนุมัติ : ", detail: order?.acept_status }} md={12} />
                              </Row>
                         </div>
                    </div>
                    <ModalPanel
                         show={openModalApp}
                         title={"อนุมัติ Margin"}
                         type={"input"}
                         body={
                              <>
                                   <Field
                                        type={"text"}
                                        title={"หมายเหตุสำหรับพนักงานขาย"}
                                        name={"note"}
                                        value={null}
                                        require={false}
                                        as={"textarea"}
                                        textarea_rows={5}
                                        // require={true}
                                        handleChangeText={noteModalApprove}
                                   />
                                   <Field
                                        type={"text"}
                                        title={"หมายเหตุสำหรับผู้อนุมัติ"}
                                        name={"note_manager"}
                                        value={null}
                                        require={false}
                                        as={"textarea"}
                                        textarea_rows={5}
                                        // require={true}
                                        handleChangeText={noteModalApprove}
                                   />
                              </>
                         }
                         onHide={onHideModal}
                         button={[{ onClick: onClickSubmitApprove, name: "ตกลง", variant: "primary" }]}
                    />
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type={"summary"} column={conf_calc.column} data={conf_calc.data} keyField={conf_calc.keyField} />
                         </div>
                    </div>
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type={"summary"} column={conf_calc_serv.column} data={conf_calc_serv.data} keyField={conf_calc_serv.keyField} />
                         </div>
                    </div>
                    <div className="content">
                         <LabelBar type={"title"} title={"การอนุมัติ Margin"} />
                         <div className="-padding">
                              <LabelBar
                                   type={"textheader"}
                                   title={{
                                        name: "Over Margin : ",
                                        detail:
                                             margin_over > 0
                                                  ? Number(margin_over).toLocaleString(undefined, {
                                                         minimumFractionDigits: 2,
                                                         maximumFractionDigits: 2,
                                                    })
                                                  : "0.00",
                                   }}
                              />
                         </div>
                         {loading && <Skeleton type={"document"} column={app.column} />}
                         {!loading && (
                              <div className="content" id="table-content">
                                   <Table type="summary" column={app.column} data={app.data} keyField={app.keyField} />
                              </div>
                         )}
                    </div>
                    {/* Final Price */}
                    <div className="content">
                         <LabelBar type={"title"} title={"Final Price"} />
                         <div className="-padding">
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Status Approve Margin : ",
                                             detail: order?.acept_status,
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </div>
                    </div>
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table
                                   type={"summary"}
                                   column={conf_final_price.column}
                                   data={conf_final_price.data}
                                   keyField={conf_final_price.keyField}
                              />
                         </div>
                    </div>
                    {/* Button */}
                    {/* <Row className="button-group __submit">
                         {!show_history && order?.lvl_margin === 0 && (
                              <Col lg={6}>
                                   {btn_acept ? (
                                        <Button onClick={onClick}>ยืนยันข้อมูลการจอง</Button>
                                   ) : (
                                        <>
                                             {order.acept_status !== "ปิดการขาย" && status_check ? (
                                                  <Button variant={"danger"} onClick={onClickCancel}>
                                                       ยกเลิกข้อมูลการจอง
                                                  </Button>
                                             ) : (
                                                  <Button disabled={true} onClick={onClick}>
                                                       ยืนยันข้อมูลการจอง
                                                  </Button>
                                             )}
                                        </>
                                   )}
                              </Col>
                         )}
                    </Row> */}
                    {/* {order?.acept_status === "ส่งมอบรถแล้ว" && order?.lvl_margin === 0 && (
                         <>
                              {order?.register === "No" ? (
                                   <Row className="button-group __submit">
                                        <Col lg={6}>
                                             <Button disabled={false} onClick={closeSellButton}>
                                                  ปิดการขาย
                                             </Button>
                                        </Col>
                                   </Row>
                              ) : (
                                   <>
                                        {order?.orcp_status === "ชำระเงินแล้ว" && (
                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       <Button disabled={false} onClick={closeSellButton}>
                                                            ปิดการขาย
                                                       </Button>
                                                  </Col>
                                             </Row>
                                        )}
                                   </>
                              )}
                         </>
                    )} */}

                    <ModalPanel
                         save={saveSign}
                         onHide={onHide}
                         clear={clearSign}
                         show={show_popup}
                         type={"sign"}
                         title={"ยืนยันข้อมูลการจอง"}
                         sub_title={"ลายเซ็นผู้ยืนยัน"}
                    />
                    {/* </div> */}
               </div>
          </div>
     );
};

/* การดำเนินการ */
const Workflow = (data) => {
     let order = data.data;

     let workflow = [
          { log_do: "การอนุมัติใบจอง", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "การรับเงินจอง", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "การอนุมัติ Leasing", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "ยืนยันข้อมูลการจอง", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "การจับคู่รถ", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "การชำระเงิน", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "การรับป้ายแดง", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "การจดทะเบียน", log_status: "", nameuser_update: "", updated_at: "" },
          { log_do: "ยกเลิกการจอง", log_status: "", nameuser_update: "", updated_at: "" },
     ];
     if (order.acept_status === "ใบจองรออนุมัติ") {
          workflow[0].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "ใบจองอนุมัติแล้ว") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "รับเงินจองแล้ว") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "Leasing อนุมัติแล้ว") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "ยืนยันข้อมูล") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "ดำเนินการแล้ว";
          workflow[3].nameuser_update = order?.name_confirm;
          workflow[3].updated_at = order?.confirm_date;
          workflow[4].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "จับคู่รถแล้ว" || order.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "ดำเนินการแล้ว";
          workflow[3].nameuser_update = order?.name_confirm;
          workflow[3].updated_at = order?.confirm_date;
          workflow[4].log_status = "ดำเนินการแล้ว";
          workflow[4].nameuser_update = order?.name_match;
          workflow[4].updated_at = order?.confirm_match_date;
          workflow[5].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "ชำระเงินแล้ว") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "ดำเนินการแล้ว";
          workflow[3].nameuser_update = order?.name_confirm;
          workflow[3].updated_at = order?.confirm_date;
          workflow[4].log_status = "ดำเนินการแล้ว";
          workflow[4].nameuser_update = order?.name_match;
          workflow[4].updated_at = order?.confirm_match_date;
          workflow[5].log_status = "ดำเนินการแล้ว";
          workflow[5].nameuser_update = order?.user_acept_car_pay_name;
          workflow[5].updated_at = order?.orcp_updated_at;
          workflow[6].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "ยกเลิกใบจอง") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "ดำเนินการแล้ว";
          workflow[3].nameuser_update = order?.name_confirm;
          workflow[3].updated_at = order?.confirm_date;
          workflow[4].log_status = "ดำเนินการแล้ว";
          workflow[4].nameuser_update = order?.name_match;
          workflow[4].updated_at = order?.confirm_match_date;
          workflow[5].log_status = "ดำเนินการแล้ว";
          workflow[5].nameuser_update = order?.user_acept_car_pay_name;
          workflow[5].updated_at = order?.orcp_updated_at;
          workflow[8].log_status = "ยกเลิกการจองแล้ว";
          workflow[8].nameuser_update = order?.name_ordercancel;
          workflow[8].updated_at = order?.cancel_order_date;
     } else if (order.acept_status === "ส่งมอบรถแล้ว") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "ดำเนินการแล้ว";
          workflow[3].nameuser_update = order?.name_confirm;
          workflow[3].updated_at = order?.confirm_date;
          workflow[4].log_status = "ดำเนินการแล้ว";
          workflow[4].nameuser_update = order?.name_match;
          workflow[4].updated_at = order?.confirm_match_date;
          workflow[5].log_status = "ดำเนินการแล้ว";
          workflow[5].nameuser_update = order?.user_acept_car_pay_name;
          workflow[5].updated_at = order?.orcp_updated_at;
          workflow[6].log_status = "รอดำเนินการ";
     } else if (order.acept_status === "ปิดการขาย") {
          workflow[0].log_status = "ดำเนินการแล้ว";
          workflow[0].nameuser_update = order?.name_margin_acept;
          workflow[0].updated_at = order?.margin_acept_date;
          workflow[1].log_status = "ดำเนินการแล้ว";
          workflow[1].nameuser_update = order?.user_acept_payment_name;
          workflow[1].updated_at = order?.orp_updated_at;
          workflow[2].log_status = "ดำเนินการแล้ว";
          workflow[2].nameuser_update = order?.user_do_name;
          workflow[2].updated_at = order?.leasing_acecpt_date;
          workflow[3].log_status = "ดำเนินการแล้ว";
          workflow[3].nameuser_update = order?.name_confirm;
          workflow[3].updated_at = order?.confirm_date;
          workflow[4].log_status = "ดำเนินการแล้ว";
          workflow[4].nameuser_update = order?.name_match;
          workflow[4].updated_at = order?.confirm_match_date;
          workflow[5].log_status = "ดำเนินการแล้ว";
          workflow[5].nameuser_update = order?.user_acept_car_pay_name;
          workflow[5].updated_at = order?.orcp_updated_at;
          workflow[6].log_status = "รอดำเนินการ";
     }

     if (order.checklabel_status !== "รอดำเนินการ") {
          workflow[6].log_status = "รับป้ายแดงแล้ว";
          workflow[6].nameuser_update = order?.name_check_label;
          workflow[6].updated_at = order?.checklabel_date;
          workflow[7].log_status = "รอดำเนินการ";
     }

     if (order.register_status !== "รอดำเนินการ") {
          workflow[7].log_status = "จดทะเบียนแล้ว";
          workflow[7].nameuser_update = order?.register_name;
          workflow[7].updated_at = order?.register_date;
          if (order.acept_status === "ยกเลิกใบจอง") {
               workflow[8].log_status = "ยกเลิกใบจองแล้ว";
          } else {
               workflow[8].log_status = "รอดำเนินการ";
          }
     }
     const doc = {
          column: [
               {
                    dataField: "log_do",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-m",
               },
               {
                    dataField: "log_status",
                    text: "สถานะ",
                    formatter: detailFormatter,
                    formatExtraData: "log_status",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "nameuser_update",
                    text: "ผู้ดำเนินการ",
                    // formatter: workflowFormatter,
                    // formatExtraData: "workflow",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: dateFormatter,
                    headerClasses: "header-custom __right __btn-m",
               },
          ],
          data: workflow,
          // data: [
          //      {
          //           log_do: "การอนุมัติใบจอง",
          //           log_status: order?.status,
          //           nameuser_update: order?.name_margin_acept,
          //           updated_at: order?.margin_acept_date,
          //      },
          //      {
          //           log_do: "การรับเงินจอง",
          //           log_status: order?.acept_status,
          //           nameuser_update: order?.user_acept_payment_name,
          //           updated_at: order?.orp_updated_at,
          //      },
          //      {
          //           log_do: "การอนุมัติ Leasing",
          //           log_status: order?.acept_status,
          //           nameuser_update: order?.user_do_name,
          //           updated_at: order?.leasing_acecpt_date,
          //      },
          //      {
          //           log_do: "ยืนยันข้อมูลการจอง",
          //           log_status: order?.acept_status,
          //           nameuser_update: order?.name_confirm,
          //           updated_at: order?.confirm_date,
          //      },
          //      {
          //           log_do: "การจับคู่รถ",
          //           log_status: order?.acept_status,
          //           nameuser_update: order?.name_match,
          //           updated_at: order?.confirm_match_date,
          //      },
          //      {
          //           log_do: "การชำระเงิน",
          //           log_status: order?.acept_status,
          //           nameuser_update: order?.user_acept_car_pay_name,
          //           updated_at: order?.orcp_updated_at,
          //      },
          //      {
          //           log_do: "การรับป้ายแดง",
          //           log_status: order?.checklabel_status,
          //           nameuser_update: order?.name_check_label,
          //           updated_at: order?.checklabel_date,
          //      },
          //      {
          //           log_do: "การจดทะเบียน",
          //           log_status: order?.register_status,
          //           nameuser_update: order?.register_name,
          //           updated_at: order?.register_date,
          //      },
          //      {
          //           log_do: "ยกเลิกการจอง",
          //           log_status: order?.acept_status,
          //           nameuser_update: order?.name_ordercancel,
          //           updated_at: order?.cancel_order_date,
          //      },
          // ],
          keyField: "log_do",
     };
     const logs = {
          column: [
               {
                    dataField: "action",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-m",
               },
               {
                    dataField: "status",
                    text: "สถานะ",
                    formatter: (e) => (e ? e : "-"),
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "name",
                    text: "ผู้ดำเนินการ",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "created_at",
                    text: "วันที่ดำเนินการ",
                    formatter: (cell) => dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543) + " " + dayjs(cell).format("HH:mm:ss"),
                    headerClasses: "header-custom __right __btn-m",
               },
          ],
          data: order.orderLog && order.orderLog.length ? order.orderLog : [],
          keyField: "action",
     };
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                         </div>
                    </div>
               </div>
               {order.orderLog && order.orderLog.length ? (
                    <div className="-body">
                         <div className="content" id="table-content">
                              <div className="-padding">
                                   <Table type="document" column={logs.column} data={logs.data} keyField={logs.keyField} />
                              </div>
                         </div>
                    </div>
               ) : null}
          </div>
     );
};

/* เอกสารการขาย */
const Document = () => {
     const doc = {
          column: [
               {
                    dataField: "title",
                    text: "รายการเอกสาร",
                    formatter: docFormatter,
                    headerClasses: "header-custom __left",
               },
               {
                    dataField: "create_date",
                    text: "วันที่ออกเอกสาร",
                    formatter: dateFormatter,
                    headerClasses: "header-custom  __btn",
               },
               {
                    dataField: "add",
                    text: "กรอกข้อมูล",
                    formatter: detailFormatter,
                    formatExtraData: "document",
                    headerClasses: "header-custom  __btn",
               },
               {
                    dataField: "sign",
                    text: "Sign on Mobile",
                    formatter: workflowFormatter,
                    headerClasses: "header-custom  __btn",
               },
               {
                    dataField: "file",
                    text: "พิมพ์",
                    formatter: printFormatter,
                    headerClasses: "header-custom __right __icon __btn-s",
               },
          ],
          data: [
               {
                    file: "xxx",
                    title: "ใบรับจองรถยนต์",
                    create_date: Date(),
                    detail: "-",
                    sign: "Sign on Mobile",
               },
               {
                    file: "",
                    title: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต",
                    create_date: "",
                    detail: "-",
                    sign: "",
               },
               {
                    file: "",
                    title: "ใบกำกับภาษีเงินจอง",
                    create_date: "",
                    detail: "-",
                    sign: "",
               },
               {
                    file: "",
                    title: "ใบยืนยันลีสซิ่ง",
                    create_date: "",
                    detail: "-",
                    sign: "",
               },
               {
                    file: "",
                    title: "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์",
                    create_date: "",
                    detail: "กรอกข้อมูล",
                    sign: "",
               },
               // {
               //      file: "",
               //      title: "ใบลดหนี้เงินจอง",
               //      create_date: "",
               //      detail: "กรอกข้อมูล",
               //      sign: "",
               // },
               {
                    file: "xxx",
                    title: "ใบส่งมอบรถ",
                    create_date: "",
                    detail: "-",
                    sign: "Sign on Mobile",
               },
               {
                    file: "",
                    title: "ใบกำกับภาษี/ใบแจ้งหนี้",
                    create_date: "",
                    detail: "-",
                    sign: "-",
               },
               {
                    file: "",
                    title: "ใบอนุญาตนำรถออก",
                    create_date: "",
                    detail: "-",
                    sign: "-",
               },
               {
                    file: "",
                    title: "ใบเสร็จรับเงิน",
                    create_date: "",
                    detail: "-",
                    sign: "-",
               },
               {
                    file: "",
                    title: "ใบรับเงินชั่วคราวป้ายแดง",
                    create_date: "",
                    detail: "-",
                    sign: "-",
               },
               {
                    file: "",
                    title: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                    create_date: "",
                    detail: "-",
                    sign: "-",
               },
               {
                    file: "",
                    title: "สรุปรายการรับเงินวันส่งมอบรถ",
                    create_date: "",
                    detail: "-",
                    sign: "-",
               },
          ],
          keyField: "title",
     };
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    <div className="content">
                         <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                    </div>
               </div>
          </div>
     );
};

/* แนบไฟล์ */
const File = ({ handleChange, handleUpload, file, filepreview, order }) => {
     const doc = {
          column: [
               {
                    dataField: "file_name",
                    text: "ไฟล์",
                    headerClasses: "header-custom __left",
               },
               {
                    dataField: "created_at",
                    text: "วันที่อัปโหลด",
                    formatter: dateFormatter,
               },
               {
                    dataField: "doc",
                    text: "พิมพ์",
                    formatter: printFormatter,
                    headerClasses: "header-custom __icon ",
                    formatExtraData: "file",
               },
               {
                    dataField: "remove",
                    text: "Remove",
                    formatter: removeBtnFormatter,
                    headerClasses: "header-custom __right __icon",
                    formatExtraData: "file",
               },
          ],
          data: order ? order : [],
          keyField: "file_name",
     };
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    <div className="content">
                         <>
                              <Form.Row>
                                   <Field
                                        type={"file"}
                                        title={{
                                             name: null,
                                             icon: null,
                                        }}
                                        require={true}
                                        md={4}
                                        placeholder={filepreview.name === "" ? "ไฟล์.xlsx" : filepreview.name}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={handleChange}
                                   />
                                   <Col xl={2} md={2} className="align-self-center">
                                        <Button className="-small" onClick={handleUpload} disabled={!file && true}>
                                             อัปโหลด
                                        </Button>
                                   </Col>
                              </Form.Row>
                              <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                         </>
                    </div>
               </div>
          </div>
     );
};

/* จับคู่รถ */
const Matching = (order) => {
     let carorder = order.order;
     const doc = {
          column: [
               {
                    dataField: "series_name",
                    text: "Serie",
                    headerClasses: "header-custom __left __btn-m",
               },
               {
                    dataField: "model_name",
                    text: "Model",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "color_name",
                    text: "สี",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "car_tank",
                    text: "เลขตัวถัง",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "car_engine",
                    text: "เลขเครื่องยนต์",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "car_match_date",
                    text: "วันที่จับคู่",
                    formatter: dateFormatter,
                    headerClasses: "header-custom __right",
                    headerClasses: "header-custom __btn-ss",
               },
          ],
          data: [
               {
                    series_name: carorder?.series_name,
                    model_name: carorder?.model_name,
                    color_name: carorder?.color_name,
                    car_tank: carorder?.car_tank,
                    car_engine: carorder?.car_engine,
                    car_match_date: carorder?.car_match_date,
               },
          ],
          keyField: "series_id",
     };
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                         </div>
                    </div>
               </div>
          </div>
     );
};

/* ประวิติการแก้ไข */
const History = (data) => {
     const doc = {
          column: [
               {
                    dataField: "log_do",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-s",
               },
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: dateFormatter,
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "log_status",
                    text: "การอนุมัติ",
                    headerClasses: "header-custom __right __btn-s",
               },
               {
                    dataField: "nameuser_update",
                    text: "ผู้ดำเนินการ",
                    headerClasses: "header-custom __right __btn-ss",
               },
               {
                    dataField: "history",
                    text: "ดูการแก้ไข",
                    formatter: data.buttonFormatter,
                    formatExtraData: "edit_history",
                    headerClasses: "header-custom __right __btn-s",
               },
          ],
          data: data.logs ? data.logs : [],
          keyField: "no",
     };
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    <div className="content" id="table-content">
                         <div className="-padding">
                              <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                         </div>
                    </div>
               </div>
          </div>
     );
};

const AppMargin = ({
     order,
     dataFun,
     onClick,
     log_app,
     handleChangeNumber,
     loading,
     noteModalApprove,
     onClickSubmitApprove,
     openModalApp,
     position_id_data,
     closeSellButton,
     btn_acept,
     show_history,
     saveSign,
     onHide,
     show_popup,
     openPopup,
     clearSign,
     onClickCancel,
     onClickConfirm,
     status_check,
     onHideModal,
     role,
     downloadPdf,
}) => {
     let log_approve;
     if (log_app) {
          log_approve = log_app;
     }
     let accbonus_price = 0;
     order.accbonus.map((el) => {
          accbonus_price += Number(JSON.parse(el.price_list).price_add_old) * el.order_ac_amount;
     });
     let sumAccTurn = 0;
     order.accturn.map((el) => {
          sumAccTurn += Number(el.order_ac_price);
     });
     let margin = order.margin + order.addition_price;
     let margin_total =
          Number(accbonus_price || 0) +
          (order?.discount_price ? order.discount_price : 0) +
          (order?.recommend_price ? Number(order.recommend_price) : 0) +
          (order?.other_pay_price ? Number(order.other_pay_price) : 0) +
          (order.insurance === "Yes" && order.insurance_pay === "บริษัท" ? Number(order?.insurance_premium) : 0) +
          (order.register === "Yes" && order.register_pay === "บริษัท" ? Number(order?.register_price) : 0) +
          (order?.sub_down ? order.sub_down : 0) +
          (order?.subsidy ? order.subsidy : 0) +
          Number(sumAccTurn || 0);
     let margin_over = Math.abs(margin - margin_total);
     const log = {
          column: [
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: (el) => {
                         return dayjs(el).format("DD/MM/") + (dayjs(el).year() + 543);
                    },
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "event",
                    text: "การดำเนินการ",
                    formatter: (el) => {
                         let color;
                         if (el === "อนุมัติ") {
                              color = "green";
                         } else if (el === "ไม่อนุมัติ") {
                              color = "red";
                         }
                         return <p style={{ color: color }}>---{el}---</p>;
                    },
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "note",
                    text: "หมายเหตุสำหรับพนักงานขาย",
                    headerClasses: "header-custom __btn-ml",
               },
               { dataField: "user_do_name", text: "ผู้ดำเนินการ", headerClasses: "header-custom __btn-ml" },
          ],
          column_manager: [
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: (el) => {
                         return dayjs(el).format("DD/MM/") + (dayjs(el).year() + 543);
                    },
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "event",
                    text: "การดำเนินการ",
                    formatter: (el) => {
                         let color;
                         if (el === "อนุมัติ") {
                              color = "green";
                         } else if (el === "ไม่อนุมัติ") {
                              color = "red";
                         }
                         return <p style={{ color: color }}>---{el}---</p>;
                    },
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "note",
                    text: "หมายเหตุสำหรับพนักงานขาย",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "note_manager",
                    text: "หมายเหตุสำหรับผู้อนุมัติ",
                    headerClasses: "header-custom __btn-ml",
               },
               { dataField: "user_do_name", text: "ผู้ดำเนินการ", headerClasses: "header-custom __btn-ml" },
          ],
          data: log_approve ? log_approve : [],
          keyField: "Function",
     };
     // เช็คสิทธิ์
     // console.log(`dataFun`, dataFun)
     let log_approve_id = dataFun && dataFun.length && dataFun.some((el) => el.position_id === position_id_data);
     let token = getToken_permission_page();
     let per = token ? JSON.parse(token.permission_page) : [];
     let addBtn = per.filter((el) => el.path.includes("/admin/order/car"))[0].approve || false;
     return (
          // <div className="content" id="table-content">
          //                <div className="-padding">
          //                     <Table type={'custom'} column={conf_promo.column} data={conf_promo.data} keyField={conf_promo.keyField} />
          //                </div>
          //           </div>
          <div className="input-field __tab-body mb-5">
               <div className="-body">
                    <div style={{ padding: 10 }}>
                         <LabelBar type={"title"} title={"ประวัติการทำรายการ"} />
                    </div>
                    {log_approve && log_approve.length > 0 && (
                         <div className="content" id="table-content">
                              <div className="-padding">
                                   <Table
                                        type="document"
                                        column={log_approve_id || role === 25 ? log.column_manager : log.column}
                                        data={log.data}
                                        keyField={log.keyField}
                                   />
                              </div>
                         </div>
                    )}
                    {/* <div style={{ width: '100%', border: '1px solid #214097' }}></div> */}
                    <Row className="button-group __submit">
                         <Col lg={12} className="mb-3">
                              <Button onClick={() => downloadPdf({ file: "/order/order_leadsing_pdf", title: "ใบรับจองรถยนต์" }, "ต้นฉบับ")}>
                                   พิมพ์ใบรับจองรถยนต์
                              </Button>
                         </Col>
                         {order.car_match_status > 0 ? (
                              <Col lg={12} className="mb-3">
                                   <Button onClick={() => downloadPdf({ file: "/order/acc_pdf", title: "ใบติดตั้งอุปกรณ์" }, "ต้นฉบับ")}>
                                        พิมพ์ใบติดตั้งอุปกรณ์
                                   </Button>
                              </Col>
                         ) : null}
                         {order.acept_status === "ส่งมอบรถแล้ว" || order.acept_status === "ปิดการขาย" ? (
                              <Col lg={12} className="mb-3">
                                   <Button onClick={() => downloadPdf({ file: "/order/delivery_pdf", title: "ใบส่งมอบ" }, "ต้นฉบับ")}>
                                        พิมพ์ใบส่งมอบ
                                   </Button>
                              </Col>
                         ) : null}

                         {/* {!show_history && addBtn && (
                              <Col lg={12}>
                                   {btn_acept ? (
                                        <>
                                             <Button variant={"success"} onClick={openPopup}>
                                                  ยืนยันข้อมูลการจอง
                                             </Button>
                                        </>
                                   ) : (
                                        <>
                                             {order.acept_status !== "ปิดการขาย" && status_check ? (
                                                  order.acept_status === "ส่งมอบรถแล้ว" || order.acept_status === "ปิดการขาย" ? null : (
                                                       <Button
                                                            variant={"danger"}
                                                            onClick={onClickConfirm}
                                                            disabled={
                                                                 order.acept_status === "จับคู่รถแล้ว" ||
                                                                 order.acept_status === "ติดตั้งอุปกรณ์แล้ว" ||
                                                                 order.acept_status === "ชำระเงินแล้ว" ||
                                                                 order.acept_status === "ส่งมอบรถแล้ว" ||
                                                                 order.acept_status === "ปิดการขาย" ||
                                                                 order.car_match_status >= 1
                                                            }
                                                       >
                                                            ยกเลิกยืนยันข้อมูลการจอง
                                                       </Button>
                                                  )
                                             ) : (
                                                  <Button variant={"success"} disabled={true} onClick={openPopup}>
                                                       ยืนยันข้อมูลการจอง
                                                  </Button>
                                             )}
                                        </>
                                   )}
                              </Col>
                         )} */}
                         {/* <Col lg={12}>
                              {order.acept_status === "Leasing อนุมัติแล้ว" || order.acept_status === "รับเงินจองแล้ว" ? (
                                   <Button variant={"success"} onClick={openPopup}>
                                        ยืนยันข้อมูลเพื่อจับคู่รถ
                                   </Button>
                              ) : order.acept_status === "ยืนยันข้อมูล" && order.car_match_status === 0 ? (
                                   <Button variant={"danger"} onClick={onClickConfirm}>
                                        ยกเลิกยืนยันข้อมูล
                                   </Button>
                              ) : null}
                         </Col> */}
                         {order.acept_status === "Leasing อนุมัติแล้ว" || order.acept_status === "รับเงินจองแล้ว" ? (
                              <Col lg={12}>
                                   <Button variant={"success"} onClick={openPopup}>
                                        ยืนยันข้อมูลเพื่อจับคู่รถ
                                   </Button>
                              </Col>
                         ) : null}
                    </Row>
                    {/*================================ ปิดการขาย =================================*/}
                    {order?.acept_status === "ส่งมอบรถแล้ว" && order?.register === "No" ? (
                         <Row className="button-group __submit">
                              <Col lg={12}>
                                   <Button variant="success" onClick={closeSellButton}>
                                        ปิดการขาย
                                   </Button>
                              </Col>
                         </Row>
                    ) : null}
                    {/*===========================================================================*/}
                    <ModalPanel
                         show={openModalApp}
                         title={"อนุมัติ Margin"}
                         type={"input"}
                         body={
                              <>
                                   <Field
                                        type={"text"}
                                        title={"หมายเหตุสำหรับพนักงานขาย"}
                                        name={"note"}
                                        value={null}
                                        require={false}
                                        as={"textarea"}
                                        textarea_rows={5}
                                        // require={true}
                                        handleChangeText={noteModalApprove}
                                   />
                                   <Field
                                        type={"text"}
                                        title={"หมายเหตุสำหรับผู้อนุมัติ"}
                                        name={"note_manager"}
                                        value={null}
                                        require={false}
                                        as={"textarea"}
                                        textarea_rows={5}
                                        // require={true}
                                        handleChangeText={noteModalApprove}
                                   />
                              </>
                         }
                         onHide={onHideModal}
                         button={[{ onClick: onClickSubmitApprove, name: "ตกลง", variant: "primary" }]}
                    />
                    <ModalPanel
                         save={saveSign}
                         onHide={onHide}
                         clear={clearSign}
                         show={show_popup}
                         type={"sign"}
                         title={"ยืนยันข้อมูลการจอง"}
                         sub_title={"ลายเซ็นผู้ยืนยัน"}
                    />
               </div>
          </div>
     );
};

const Note = ({ handleChange, onClick, note }) => {
     return (
          <div className="input-field __tab-body">
               <div className="-body">
                    <div className="content">
                         <>
                              <Field
                                   type={"text"}
                                   title={"หมายเหตุ"}
                                   name={"cancel_order_note"}
                                   as={"textarea"}
                                   textarea_rows={5}
                                   md={12}
                                   value={note}
                                   handleChangeText={handleChange}
                              />
                              <Col md={{ span: "3", offset: "9" }}>
                                   {this.state.loading_makeup ? (
                                        <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                             <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                             Loading...
                                        </Button>
                                   ) : (
                                        <Button onClick={onClick} variant={"danger"}>
                                             ยกเลิกใบจอง
                                        </Button>
                                   )}
                              </Col>
                         </>
                    </div>
               </div>
          </div>
     );
};

export default class DetailCarOrder extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               title: [],
               key: "detail",
               order: {
                    files: [],
               },
               file: "",
               filepreview: {
                    name: "",
                    img: null,
               },
               files: [],
               carorder: {
                    promotions: [],
                    accbonus: [],
                    accbuy: [],
                    accturn: [],
                    receipt: [],
               },
               show_popup: false,
               step_status: {
                    a1: false,
                    a2: false,
                    a3: false,
                    a4: false,
                    a5: false,
                    a6: false,
                    a7: false,
                    a8: false,
                    a9: false,
                    a10: false,
                    approve_booking: false,
                    receipt_booking: false,
                    approve_leasing: false,
                    submit_info: false,
                    coupled_car: false,
                    installed_acc: false,
                    payed: false,
                    sent_car: false,
                    close_order: false,
               },
               btn_acept: false,
               cancel_order: {
                    order_id: this.props.match.params.id,
                    cancel_id: null,
               },
               workflow_order: {},
               supplier_acc: [],
               showmodal_sup: false,
               comfinaceModal: false,
               sup_order: {
                    order_id: this.props.match.params.id,
               },
               showmodal_input: false,
               showmodal_makeup: false,
               loading_makeup: false,
               order_doc: {},
               order_doc_tax: {},
               showmodal_creditnote: false,
               supplierTurn: [],
               doc_active: {},
               show_breadcrumbs: false,
               show_history: false,
               showmodal_sign: false,
               sign: 0,
               loading: true,
               scroll: this.props.location.state,
               edit_status: false,
               edit_common: false,
               modal_skb: false,
               reduce_status: false,
               turn_type_pdf: null,
               modal_acc_turn: false,
               modal_reduce_regis: false,
               reduce_regis: {},
               reduce_regis_status: false,
               showModalCancel: false,
               users: {},
               save_reduce: {},
          };
     }

     openPopup = () => {
          this.setState({
               show_popup: !this.state.show_popup,
          });
     };

     componentDidMount = async () => {
          this.generateDocumentACtive();
          await this.setState({ users: getToken() });
          this.getCarOrder();
          this.getSupplier();
          this.getLogHistory();
          this.getFunction();
          this.getPosition();
          this.getLogApprove();
          this.setState({ position_id_data: getToken().position_id });
          this.setState({ role: getToken().position_id });
          await this.getTypeLeasing();
     };

     getTypeLeasing = async (id) => {
          try {
               this.setState({ typeLeasing: [...(await GET("/ps/get_leasing"))] });
          } catch (error) {
               console.log("error", error);
          }
     };

     getPosition = async () => {
          try {
               let res = await GET("/ps/position");
               this.setState({ position: res });
          } catch (error) {
               console.log("error", error);
          }
     };

     getFunction = async () => {
          try {
               let get_function = await GET("/aos/get_function");
               this.setState({ dataFun: [...get_function] });
          } catch (error) {
               console.log("error", error);
          }
     };

     getLogApprove = async () => {
          let { id } = this.state;
          try {
               let get_log_app = await POST("/order/log_margin", { order_id: id });
               this.setState({ log_app_data: get_log_app });
          } catch (error) {
               console.log("error", error);
          }
     };

     generateDocumentACtive = async () => {
          let { doc_active } = this.state;
          for (let i = 1; i <= 16; i++) {
               doc_active[`a${i}`] = false;
          }
          await this.setState({ doc_active });
     };

     getSupplier = async () => {
          let { supplier_acc } = this.state;
          try {
               let supplier = await GET("/ps/get_suppliers");
               for (let i in supplier) {
                    if (supplier[i].supplier_type === "อุปกรณ์ตกแต่ง") {
                         supplier_acc.push(supplier[i]);
                    }
               }

               this.setState({ supplier_acc });
          } catch (err) {
               console.log(err);
          }
     };

     getLogHistory = async () => {
          let { id } = this.state;
          let res = await POST("/ps/this_log", { id_do: id, log_type: "order" });
          this.setState({ logs: res });
     };

     getLogDetail = async (id) => {
          let res = await POST("/order/log_detail_order", { log_id: id });
          console.log("res", res.log_detail.addReceipt);
          this.setState({ log_info: res.log_detail.addReceipt });
     };

     getCarOrder = async () => {
          let {
               id,
               step_status,
               btn_acept,
               order_doc,
               order_doc_tax,
               doc_active,
               show_breadcrumbs,
               edit_status,
               edit_common,
               reduce_status,
               reduce_regis,
               reduce_regis_status,
               users,
               save_reduce,
          } = this.state;
          try {
               let result = await POST("/order/this", { order_id: id });
               if (result.accturn.length > 0) {
                    this.getSupplierTurn(id);
               }

               show_breadcrumbs = true;
               // Set order Comfinance Document
               order_doc.comfinace_price = result.comfinace_price;
               order_doc.comfinace_vat = result.comfinace_vat;
               order_doc.comfinace_sum = result.comfinace_sum;
               order_doc.comfinace_date = result.comfinace_date;
               order_doc.comfinace_time = result.comfinace_time;

               order_doc_tax.reduce_tax_note = result.reduce_tax_note;
               order_doc_tax.reduce_tax_date = result.reduce_tax_date;

               reduce_regis.reduce_register_tax_date = result.reduce_register_tax_date ? result.reduce_register_tax_date : dayjs(new Date());
               reduce_regis.reduce_register_tax_note = result.reduce_register_tax_note;

               //Receipt Document
               // if (result.orcp_leasing_payment_method) {
               //      let pay = JSON.parse(result.orcp_leasing_payment_method);
               //      let a = pay.filter((el) => el.check === true);
               //      let p = 0;
               //      for (let i in a) {
               //           p += Number(a[i].price);
               //      }

               //      result.receipt.push({
               //           receipt_method: "ใบเสร็จรับเงินไฟแนนซ์",
               //           receipt_price: p,
               //           check: true,
               //           code: "KO",
               //           file: "/tax_leasing_pdf",
               //      });
               // }

               // let receipt = result.receipt;

               // for (let i in receipt) {
               //      if (receipt[i].receipt_method === 'ใบกำกับภาษี / ใบแจ้งหนี้ ไฟแนนซ์') {
               //           receipt.splice(i, 1);
               //      } else if (receipt[i].receipt_method === 'ใบเสร็จรับเงินไฟแนนซ์') {
               //           receipt.splice(i, 1);
               //      } else if (receipt[i].receipt_method === 'ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์') {
               //           receipt.splice(i, 1);
               //      } else {
               //           receipt[i].active = true;
               //           receipt[i].type = 'receipt';
               //      }
               // }

               // result.receipt = receipt;

               // End Receipt Document
               if (
                    result.comfinace_date !== "0" &&
                    (users?.position_id === 19 ||
                         users?.position_id === 20 ||
                         users?.position_id === 25 ||
                         users?.position_id === 11 ||
                         users?.position_id === 10)
               ) {
                    doc_active.a5 = true;
               }
               // if (result.comfinace_date !== '0' && (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)) {
               //      doc_active.a5 = true;
               // }

               if (result.reduce_tax_date && users?.position_id === 19 && !result.reducetax) {
                    doc_active.a6 = true;
               }
               if (
                    result.reduce_tax_date &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a6 = true;
               }

               if (result.recive_date) {
                    doc_active.a11 = true;
               }
               if (result.register_status === "จดทะเบียนแล้ว") {
                    doc_active.a12 = true;
               }

               // if (result.orcp_status === "ชำระเงินแล้ว") {
               //      doc_active.a15 = true;
               // }
               // if (result.confirm_date && users?.position_id === 19 && (!result.taxleasing || !result.tax)) {
               //      doc_active.a15 = true;
               // }
               // if (
               //      result.confirm_date &&
               //      (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               // ) {
               //      doc_active.a15 = true;
               // }
               if (result.orcp_tax_code) {
                    doc_active.a15 = true;
               }

               if (result.orcp_date || result.orcp_leasing_date) {
                    doc_active.a10 = true;
               }

               if (
                    users?.position_id === 19 &&
                    (!result.paymentturnacc || !result.paymentturncar) &&
                    (result?.accturn.length > 0 || result?.carturn === 1)
               ) {
                    doc_active.a16 = true;
               }
               if (
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11) &&
                    (result?.accturn.length > 0 || result?.carturn === 1)
               ) {
                    doc_active.a16 = true;
               }

               // if (result.reduce_tax_label_code && users?.position_id === 19 && !result.reducetaxlabel) {
               //      doc_active.a17 = true;
               // }
               // console.log("result", result);
               if (
                    result.orderLabel.length > 0 &&
                    (users?.position_id === 19 ||
                         users?.position_id === 20 ||
                         users?.position_id === 25 ||
                         users?.position_id === 10 ||
                         users?.position_id === 11)
               ) {
                    doc_active.a17 = true;
               }

               if (result.reduce_register_tax_date && users?.position_id === 19 && !result.reducetaxregister) {
                    doc_active.a18 = true;
               }
               if (
                    result.reduce_register_tax_date &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a18 = true;
               }
               Object.keys(step_status).forEach((v, i) => {
                    if (result.acept_status === "ใบจองรออนุมัติ") {
                         i < 1 ? (step_status[v] = true) : (step_status[v] = false);
                         doc_active.a1 = true;
                         step_status.approve_booking = true;
                         // edit_status = true;
                    } else if (result.acept_status === "ใบจองอนุมัติแล้ว") {
                         i < 2 ? (step_status[v] = true) : (step_status[v] = false);
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         step_status.receipt_booking = true;
                    } else if (result.acept_status === "รับเงินจองแล้ว") {
                         i < 3 ? (step_status[v] = true) : (step_status[v] = false);
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;

                         reduce_status = true;
                         btn_acept = true;
                         result.pay_choice === "เงินสด" ? (step_status.submit_info = true) : (step_status.approve_leasing = true);
                    } else if (result.acept_status === "Leasing อนุมัติแล้ว") {
                         i < 4 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         btn_acept = true;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                         step_status.submit_info = true;
                         reduce_status = true;
                    } else if (result.acept_status === "ยืนยันข้อมูล") {
                         if (result.car_match_status > 0) {
                              i < 6 ? (step_status[v] = true) : (step_status[v] = false);
                              step_status.coupled_car = false;
                         } else {
                              i < 5 ? (step_status[v] = true) : (step_status[v] = false);
                              step_status.coupled_car = true;
                         }

                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         show_breadcrumbs = false;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                         if (result.makeup) {
                              edit_status = true;
                         }
                         // edit_status = true;
                         reduce_status = true;
                    } else if (result.acept_status === "จับคู่รถแล้ว") {
                         i < 6 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         show_breadcrumbs = false;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                         step_status.installed_acc = true;
                         edit_status = true;
                         reduce_status = true;
                    } else if (result.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
                         i < 7 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         show_breadcrumbs = false;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                         step_status.payed = true;
                         if (users?.position_id === 16) {
                              edit_status = false;
                         } else {
                              edit_status = true;
                         }
                         reduce_status = true;
                    } else if (result.acept_status === "ชำระเงินแล้ว") {
                         i < 8 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         show_breadcrumbs = false;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a8 = true;
                              // doc_active.a10 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                              doc_active.a8 = true;
                              // doc_active.a10 = true;
                         }
                         step_status.sent_car = true;
                         edit_status = true;
                         reduce_status = true;
                         reduce_regis_status = true;
                         edit_common = true;
                    } else if (result.acept_status === "ส่งมอบรถแล้ว") {
                         i < 9 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         show_breadcrumbs = false;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                         }
                         edit_status = true;
                         edit_common = true;
                         reduce_status = true;
                         reduce_regis_status = true;
                         step_status.close_order = true;
                    } else if (result.acept_status === "ปิดการขาย") {
                         i < 10 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         show_breadcrumbs = false;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                         }
                         edit_status = true;
                         edit_common = true;
                         reduce_status = true;
                         reduce_regis_status = true;
                    } else if (result.acept_status === "ยกเลิกใบจอง") {
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         doc_active.a3 = true;
                         doc_active.a4 = true;
                    }
               });

               save_reduce["branch_id"] = result.branch_id;
               save_reduce["receipt_id"] = result.receipt_id;
               if (result.accReduce.length) {
                    let data = result.accReduce[result.accReduce.length - 1];
                    save_reduce["reduce_price"] = data.reduce_price;
                    save_reduce["reduce_vat"] = data.reduce_vat;
                    save_reduce["reduce_note"] = data.reduce_note;
                    save_reduce["reduce_date"] = data.reduce_date;
                    save_reduce["reduce_time"] = data.reduce_time;
               }
               if (result.series_id && result.model_id && result.color_id && result.order_id && result.orp_status) {
                    let get_price = await POST("/aos/get_price", {
                         series_id: Number(result.series_id),
                         model_id: Number(result.model_id),
                         color_id: Number(result.color_id),
                         page: "add",
                         order_id: result.order_id,
                         orp_status: result.orp_status,
                    });
                    result.act_type = get_price.act_type;
               }
               this.setState({
                    carorder: { ...result },
                    step_status,
                    btn_acept,
                    order_doc,
                    order_doc_tax,
                    doc_active,
                    show_breadcrumbs,
                    edit_status,
                    reduce_status,
                    reduce_regis,
                    reduce_regis_status,
                    edit_common,
                    loading: false,
               });
               // console.log(`result`, result);
          } catch (error) {
               console.log(error);
          }
     };

     getSupplierTurn = async (order_id) => {
          try {
               this.setState({ supplierTurn: [...(await POST("/order/supplier_turn", { order_id }))] });
          } catch (error) {
               console.log(error);
          }
     };
     /* -------------------------- Set ---------------------------- */
     setKey = (k) => {
          this.setState({ key: k });
     };
     /* ------------------------ Handle ------------------------ */
     handleChangeFile = (event) => {
          let { filepreview, file, order } = this.state;

          if (event.target.files.length !== 0) {
               let files = new FormData();

               files.append("order_id", this.state.id);
               files.append("file", event.target.files[0]);

               filepreview.name = event.target.files[0].name;
               filepreview.img = URL.createObjectURL(event.target.files[0]);

               this.setState({
                    file: files,
                    filepreview,
               });
          }
     };
     handleUpload = async () => {
          let { file, order, carorder } = this.state;
          // Create mocking file object
          let file_obj = {};
          file_obj = {
               file_name: file.get("file").name,
               create_date: Date(),
               file: file.get("file"),
          };
          try {
               let result = await POST("/order/add_file", file, true);
               order.files.push(file_obj);
               carorder.file.push(file_obj);
          } catch (err) {
               console.log(err);
          }
          this.setState({
               order,
               // carorder,
               carorder: await POST("/order/this", { order_id: this.state.id }),
          });
     };
     handleChangeText = ({ target: { name, value } }) => {
          let { order, order_doc_tax, reduce_regis } = this.state;

          if (name === "reduce_tax_note") {
               order_doc_tax[name] = value;
               this.setState({ order_doc_tax });
          } else if (name === "reduce_register_tax_note") {
               reduce_regis[name] = value;
               this.setState({ reduce_regis });
          } else {
               order[name] = value;
               this.setState({ order });
               console.log("order", order);
          }
     };
     handleChangeTextCom = (e) => {
          let { order_doc } = this.state;
          order_doc[e.target.name] = e.target.value;
          this.setState({ order_doc });
     };

     handleChangeTextCancel = ({ target: { name, value } }) => {
          let { cancel_order } = this.state;
          cancel_order[name] = value;
          this.setState({ ...cancel_order });
     };

     handleClick = () => {
          const { id } = this.state;
          this.props.history.push({ pathname: "/admin/order/car/detail/edit/", state: { order_id: id, type: "common" } });
     };
     handleClickEditMoney = () => {
          const { id } = this.state;
          this.props.history.push({ pathname: "/admin/order/car/detail/edit/", state: { order_id: id, type: "money" } });
     };

     handleChangeSelectSupplier = (e) => {
          let { sup_order } = this.state;
          sup_order[e.target.name] = e.target.value;
          this.setState({ sup_order });
     };
     handleChangeNumber = (e, name) => {
          let { order_doc } = this.state;
          if (name === "acept_margin") {
               let acept_margin = e.floatValue;
               this.setState({ acept_margin: acept_margin });
          }
          if (name === "comfinace_price") {
               order_doc.comfinace_vat = e.floatValue * (7 / 100);
          }
          order_doc[name] = e.floatValue;
          this.setState({ order_doc });
     };
     handleSelectDate = (date, dateString, name) => {
          let { order_doc, order_doc_tax, reduce_regis } = this.state;
          if (name === "reduce_tax_date") {
               order_doc_tax[name] = dateString;
               this.setState({ order_doc_tax });
          } else if (name === "reduce_register_tax_date") {
               reduce_regis[name] = date;
               this.setState({ reduce_regis });
          } else {
               order_doc[name] = dateString;
               this.setState({ order_doc });
          }
     };

     /* ------------------ onClick ------------------------ */
     downloadPdf = async (row, type) => {
          // console.log("first", row, type);
          let { carorder } = this.state;
          try {
               let token = localStorage.getItem("token");
               if (row.title === "ใบยืมป้ายแดง") {
                    await axios
                         .post(
                              `${ip}${row.file}`,
                              { labelhis_id: carorder?.labelhis_id, type: "ต้นฉบับ" },
                              {
                                   responseType: "blob",
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        token,
                                   },
                              }
                         )
                         .then(async (res) => {
                              const pdfBlob = new Blob([res.data], {
                                   type: "application/pdf",
                              });
                              const fileURL = URL.createObjectURL(pdfBlob);
                              // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                              window.open(fileURL);
                              // this.setState({
                              //     fileURL: fileURL,
                              //     // spinner: false,
                              //     // show_modal_pdf: true
                              // });
                         });
               } else {
                    await axios
                         .post(
                              `${ip}${row.file}`,
                              { order_id: this.state.id, type: type },
                              {
                                   responseType: "blob",
                                   headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        token,
                                   },
                              }
                         )
                         .then(async (res) => {
                              const pdfBlob = new Blob([res.data], {
                                   type: "application/pdf",
                              });
                              const fileURL = URL.createObjectURL(pdfBlob);
                              // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                              window.open(fileURL);
                              // this.setState({
                              //     fileURL: fileURL,
                              //     // spinner: false,
                              //     // show_modal_pdf: true
                              // });
                         });
               }
          } catch (error) {
               console.log("error", error);
          }
     };

     downloadPdfReceipt = async (row) => {
          try {
               // console.log(`row`, row)
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/template_pdf`,
                         {
                              order_id: this.state.id,
                              namepayment: row.receipt_method,
                              pricePayment: row.receipt_price,
                              code: row.receipt_code,
                              branch_id: this.state.carorder.branch_id,
                              name_pay: row.name_pay,
                         },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     onClickCancelOrder = async () => {
          this.setState({ loading_makeup: true });
          this.onClickCancelModal();
          let { cancel_order } = this.state;
          let cancel_select = [
               { id: 1, label: "ไม่มีรถส่งมอบ" },
               { id: 2, label: "ไม่ผ่านไฟแนนซ์" },
               { id: 3, label: "ไม่พร้อมเรื่องเงิน" },
               { id: 4, label: "คีย์เกิน90วัน" },
               { id: 5, label: "ลูกค้ายังไม่พร้อม" },
          ];
          if (cancel_order.cancel_id) {
               try {
                    let cancelID = cancel_select.find((el) => el.label === cancel_order.cancel_id).id;
                    let result = await POST("/order/cancel_order", { ...cancel_order, cancel_id: cancelID });
                    swal({
                         icon: "success",
                         text: "ยกเลิกใบจองแล้ว",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then(() => {
                         window.location.reload();
                    });
               } catch (err) {
                    this.setState({ loading_makeup: false });
                    swal({
                         icon: "error",
                         text: "ผิดพลาด",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then(() => {
                         // window.location.reload();
                    });
                    // console.log(err);
               }
          } else {
               this.setState({ loading_makeup: false });
               swal({
                    icon: "warning",
                    text: "กรุณาเลือกสาเหตุการยกเลิก",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               });
          }
     };
     onClickCancelModal = () => {
          this.setState({ showModalCancel: !this.state.showModalCancel });
     };

     onClickCloseSell = async () => {
          let { id, carorder } = this.state;
          // console.log("carorder.register", carorder.register, carorder.checklabel_status);
          if (carorder.register === "Yes" && carorder.checklabel_status === "รอดำเนินการ") {
               swal({
                    icon: "warning",
                    text: "กรุณารอได้ป้ายขาวก่อนปิดการขาย",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          } else {
               swal({
                    icon: "warning",
                    text: "ยืนยันปิดการขาย",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         try {
                              await POST("/order/close", { order_id: id });
                              swal({
                                   icon: "success",
                                   text: "ปิดการขายแล้ว",
                                   buttons: {
                                        submit: "เสร็จสิ้น",
                                   },
                              }).then(() => {
                                   window.location.reload();
                              });
                         } catch (err) {
                              console.log("/order/close >>", err);
                         }
                    }
               });
          }
     };

     saveSign = async (sigCanvas) => {
          let { id, step_status, btn_acept, carorder } = this.state;
          let base64 = await sigCanvas.toDataURL("image/png");
          try {
               let result = await POST("/order/confirm", { order_id: id, order_sign: base64 });
               step_status.a5 = true;
               if (carorder.pay_choice === "เงินสด") {
                    step_status.a4 = false;
               }
               // this.setState({
               //      show_popup: !this.state.show_popup,
               //      step_status,
               //      btn_acept: false,
               //      show_breadcrumbs: false,
               //      carorder: await POST("/order/this", { order_id: id }),
               // });
               swal({
                    icon: "success",
                    title: "ยืนยันข้อมูลสำเร็จ",
                    text: `${result}`,
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => window.location.reload());
          } catch (err) {
               swal({
                    icon: "error",
                    title: "ยืนยันข้อมูลไม่สำเร็จ",
                    text: `${err}`,
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => window.location.reload());
          }
     };
     clearSign = (sigCanvas) => {
          sigCanvas.clear();
     };

     saveSignSkb = async (sigCanvas) => {
          let base64 = await sigCanvas.toDataURL("image/png");
          try {
               await POST("/order/sign", { order_id: this.state.id, reservation_sign: base64 });
               swal({
                    icon: "success",
                    text: "ยืนยันข้อมูลสำเร็จ",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => {
                    window.location.reload();
               });
          } catch (err) {
               console.log(err);
          }
     };

     clearSignSkb = (sigCanvas) => {
          sigCanvas.clear();
     };

     addCommissionFinance = async () => {
          this.setState({ com_loading: true });
          let { order_doc, carorder } = this.state;
          order_doc.order_id = carorder.order_id;
          order_doc.branch_id = carorder.branch_id;
          if (!order_doc.comfinace_date || order_doc.comfinace_date == 0) {
               order_doc.comfinace_date = dayjs(new Date()).format("YYYY-MM-DD");
          }
          // console.log('order_doc', order_doc)
          try {
               let result = await POST("/order/save_comfinace", order_doc);
               // console.log(result);
               this.setState({ showmodal_input: false, com_loading: false });
               swal({
                    icon: "success",
                    text: "ทำรายการสำเร็จ",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => {
                    // this.getCarOrder();
                    window.location.reload();
               });
          } catch (err) {
               this.setState({ showmodal_input: false, com_loading: false });
               swal({
                    icon: "error",
                    text: err,
                    buttons: {
                         submit: "ลองใหม่อีกครั้ง",
                    },
               }).then(() => {
                    this.getCarOrder();
               });
               console.log("error : ", err);
          }
     };

     addCreditNote = async () => {
          let { order_doc_tax, carorder } = this.state;
          order_doc_tax.order_id = carorder.order_id;
          order_doc_tax.branch_id = carorder.branch_id;

          try {
               let result = await POST("/order/save_reduce_tax", order_doc_tax);
               // console.log(result);
               this.setState({ showmodal_creditnote: false });
               // this.getCarOrder();
               swal({
                    icon: "success",
                    text: "ทำรายการสำเร็จ",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => {
                    window.location.reload();
               });
          } catch (err) {
               console.log("error : ", err);
          }
     };

     removeFile = async (id, rowIndex) => {
          try {
               await POST("/order/delete_file", { id: id });
               this.setState({ carorder: await POST("/order/this", { order_id: this.state.id }) });
          } catch (err) {
               console.log(err);
          }
     };

     openModalSupplier = () => {
          this.setState({ showmodal_sup: !this.state.showmodal_sup });
     };
     closeModalSupplier = () => {
          this.setState({ showmodal_sup: false, showmodal_input: false, showmodal_creditnote: false });
     };
     openModalInput = () => {
          this.setState({ showmodal_input: !this.state.showmodal_input });
     };
     openModalCreditNote = () => {
          this.setState({ showmodal_creditnote: !this.state.showmodal_creditnote });
     };

     /* ----------------------- Formatter --------------------------- */
     totalPrice = (columnData) => {
          let total = columnData.reduce((acc, item) => acc + item, 0);
          total = parseFloat(total).toFixed(2);
          return total;
     };

     parseFloatFormatter = (cell, row) => {
          let new_cell = parseFloat(cell).toFixed(2);
          if (row.field === "summary-total") {
               return <span className="text">{new_cell}</span>;
          } else {
               return new_cell;
          }
     };

     unitFormatter = () => {
          return <p>บาท</p>;
     };

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <p>{rowIndex + 1}</p>;
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "file":
                    return <span style={{ color: "#000" }}>{dateFormatter(cell)}</span>;
               default:
                    if (row.active) {
                         if (cell) {
                              return <span style={{ color: "#000" }}>{dateFormatter(cell)}</span>;
                         } else {
                              return <span>-</span>;
                         }
                    } else {
                         return <span>-</span>;
                    }
          }
     };

     printFormatter = (cell, row, rowIndex, formatExtraData) => {
          // เช็คสิทธิ์
          let token = getToken_permission_page();
          let per = token ? JSON.parse(token.permission_page) : [];
          let addBtn = per.filter((el) => el.path.includes("/admin/order/car"))[0].report || false;

          switch (formatExtraData) {
               case "file":
                    if (row.file) {
                         return <i className="fas fa-print icon __btn active" onClick={() => window.open(row.file)}></i>;
                    } else {
                         return <i className="fas fa-print icon __btn"></i>;
                    }
               case "copy":
                    if (cell) {
                         if (row.active && addBtn) {
                              return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdf(row, "สำเนา")}></i>;
                         } else {
                              return <i className="fas fa-print icon"></i>;
                         }
                    } else {
                         return "";
                    }

               default:
                    if (row.active && addBtn) {
                         if (row.title === "ใบกำกับภาษีเทิร์นสินค้า") {
                              return <i className="fas fa-print icon __btn active" onClick={() => this.openModalSupplier()}></i>;
                         } else if (row.title === "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์") {
                              return (
                                   <i
                                        className="fas fa-print icon __btn active"
                                        onClick={() => this.setState({ comfinaceModal: true, typeModal: "com" })}
                                   ></i>
                              );
                         } else if (row.title === "ใบเสร็จคืนเงินป้ายแดง") {
                              return (
                                   <i
                                        className="fas fa-print icon __btn active"
                                        onClick={() => this.setState({ comfinaceModal: true, typeModal: "label" })}
                                   ></i>
                              );
                         } else if (row.title === "ใบลดหนี้ค่าอุปกรณ์") {
                              return <i className="fas fa-print icon __btn active" onClick={() => this.setState({ modal_print: true })}></i>;
                         } else {
                              if (row.type === "receipt") {
                                   return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdfReceipt(row)}></i>;
                              } else {
                                   return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdf(row, "ต้นฉบับ")}></i>;
                              }
                         }
                    } else {
                         return <i className="fas fa-print icon"></i>;
                    }
          }
     };

     removeBtnFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "file":
                    return <i className="fas fa-trash-alt icon __btn __danger" onClick={() => this.removeFile(row.id, rowIndex)}></i>;
               default:
                    return <i className="fas fa-trash-alt icon __btn __danger"></i>;
          }
     };

     docFormatter = (cell, row) => {
          if (row.active) {
               return (
                    <span className="doc active">
                         <i className="far fa-file-alt icon active"></i>
                         {row.title}
                    </span>
               );
          } else {
               return (
                    <span className="doc">
                         <i className="far fa-file-alt icon"></i>
                         {row.title}
                    </span>
               );
          }
     };
     updateLabelHis = async () => {
          let { carorder } = this.state;
          try {
               let res = await POST("/car/update_label_his", {
                    status: "คืนป้ายแล้ว",
                    gen_code: true,
                    branch_id: carorder.branch_id,
                    labelhis_id: carorder.orderLabel.filter((el) => !el.reduce_tax_label_code)[0].labelhis_id,
               });

               if (res === "add success") {
                    this.setState({ showModalLbelID: false });
                    swal({
                         icon: "success",
                         text: "คืนค่ามัดจำป้ายแดงเสร็จสิ้น",
                         buttons: false,
                    });
                    window.location.reload();
               } else {
                    this.setState({ showModalLbelID: false });
                    swal({
                         icon: "warning",
                         text: "คืนเงินไม่สำเร็จ! กรุณาลองใหม่ในภายหลัง",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
          } catch (error) {
               this.setState({ showModalLbelID: false });
               swal({
                    icon: "warning",
                    text: "คืนเงินไม่สำเร็จ! กรุณาลองใหม่ในภายหลัง",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { carorder } = this.state;
          // console.log("carorder", carorder);
          // เช็คสิทธิ์
          let token = getToken_permission_page();
          let per = token ? JSON.parse(token.permission_page) : [];
          let addBtn = per.filter((el) => el.path.includes("/admin/order/car"))[0].report || false;
          if (row.active) {
               if (row.detail === "กรอกข้อมูล") {
                    if (row.title === "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์") {
                         return (
                              <a onClick={this.openModalInput} disabled={!addBtn}>
                                   {"กรอกข้อมูล"}
                              </a>
                         );
                    }
                    // if (row.title === "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์") {
                    //      if (carorder.comfinace_date !== "0") {
                    //           return <span>{"กรอกข้อมูล"}</span>;
                    //      } else {
                    //           return <a onClick={this.openModalInput}>{"กรอกข้อมูล"}</a>;
                    //      }

                    // }
                    else if (row.title === "ใบลดหนี้เงินจอง") {
                         if (this.state.reduce_status) {
                              return (
                                   <a onClick={this.openModalCreditNote} disabled={!addBtn}>
                                        {"กรอกข้อมูล"}
                                   </a>
                              );
                         } else {
                              return <span>{"กรอกข้อมูล"}</span>;
                         }
                    } else if (row.title === "สัญญาจองรถยนต์ (สคบ.)" && !carorder?.reservation_sign) {
                         return (
                              <a disabled={!addBtn} onClick={() => this.setState({ modal_skb: true })}>
                                   {"กรอกข้อมูล"}
                              </a>
                         );
                    } else if (row.title === "ใบคืนเงินค่ามัดจำชุดจดทะเบียน") {
                         if (this.state.reduce_regis_status) {
                              return (
                                   <a disabled={!addBtn} onClick={() => this.setState({ modal_reduce_regis: true })}>
                                        {"กรอกข้อมูล"}
                                   </a>
                              );
                         } else {
                              return <span>{"กรอกข้อมูล"}</span>;
                         }
                    } else if (row.title === "ใบลดหนี้ค่าอุปกรณ์") {
                         return <a onClick={() => this.setState({ modal_reduce_acc: true })}>{"กรอกข้อมูล"}</a>;
                    } else {
                         return <span>{"กรอกข้อมูล"}</span>;
                    }
               } else if (row.detail === "คืนเงิน") {
                    // console.log("row", row);
                    if (row.title === "ใบเสร็จคืนเงินป้ายแดง") {
                         if (carorder.orderLabel.filter((el) => !el.reduce_tax_label_code).length > 0) {
                              return <a onClick={() => this.setState({ showModalLbelID: true })}>คืนค่ามัดจำ</a>;
                         } else {
                              return <span>คืนค่ามัดจำแล้ว</span>;
                         }
                    } else {
                         return <span>{"-"}</span>;
                    }
               } else {
                    return <span>{"-"}</span>;
               }
          } else {
               if (row.detail === "กรอกข้อมูล") {
                    // if (row.title === "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์") {
                    //      if (carorder.comfinace_date !== "0") {
                    //           return <span>{"กรอกข้อมูล"}</span>;
                    //      } else {
                    //           return <a onClick={this.openModalInput}>{"กรอกข้อมูล"}</a>;
                    //      }

                    // }
                    if (row.title === "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์") {
                         return (
                              <a disabled={!addBtn} onClick={this.openModalInput}>
                                   {"กรอกข้อมูล"}
                              </a>
                         );
                    } else if (row.title === "ใบลดหนี้เงินจอง") {
                         // return <span>{"กรอกข้อมูล"}</span>;
                         if (this.state.reduce_status) {
                              return (
                                   <a disabled={!addBtn} onClick={this.openModalCreditNote}>
                                        {"กรอกข้อมูล"}
                                   </a>
                              );
                         } else {
                              return <span>{"กรอกข้อมูล"}</span>;
                         }
                    } else if (row.title === "สัญญาจองรถยนต์ (สคบ.)") {
                         return <span>{"กรอกข้อมูล"}</span>;
                    } else if (row.title === "ใบคืนเงินค่ามัดจำชุดจดทะเบียน") {
                         if (this.state.reduce_regis_status) {
                              return (
                                   <a disabled={!addBtn} onClick={() => this.setState({ modal_reduce_regis: true })}>
                                        {"กรอกข้อมูล"}
                                   </a>
                              );
                         } else {
                              return <span>{"กรอกข้อมูล"}</span>;
                         }
                    } else if (row.title === "ใบลดหนี้ค่าอุปกรณ์") {
                         return <span>{"กรอกข้อมูล"}</span>;
                    } else {
                         return <span>{"-"}</span>;
                    }
               } else {
                    return <span>{"-"}</span>;
               }
          }
     };

     workflowFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.active) {
               if (row.show) {
                    if (row.title === "ใบรับจองรถยนต์") {
                         return <a onClick={() => this.setState({ showmodal_sign: true, sign: 0 })}>{cell}</a>;
                    } else if (row.title === "ใบกำกับภาษีเงินจอง") {
                         return <a onClick={() => this.setState({ showmodal_sign: true, sign: 1 })}>{cell}</a>;
                    } else if (row.title === "ใบส่งมอบรถ") {
                         return <a onClick={() => this.setState({ showmodal_sign: true, sign: 2 })}>{cell}</a>;
                    } else if (row.title === "สัญญาจองรถยนต์ (สคบ.)") {
                         return <a onClick={() => this.setState({ showmodal_sign: true, sign: 3 })}>{cell}</a>;
                    }
               } else {
                    return <a onClick={() => {}}>{cell}</a>;
               }
          } else {
               return <span>{cell}</span>;
          }
     };

     loadCarTurnPDF = async () => {
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/turn_tax_car_pdf`,
                         { order_id: this.state.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         console.log(`res`, res);
                         window.open(fileURL);
                    });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: "ยังไม่ได้ออกใบกำกับภาษี",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     openLoadPdfTurn = () => {
          let { turn_type_pdf, carorder, users } = this.state;
          if (turn_type_pdf === "รถยนต์") {
               this.loadCarTurnPDF();
          } else if (turn_type_pdf === "อุปกรณ์ตกแต่ง") {
               if (carorder.accturn.length > 0) {
                    this.setState({ modal_acc_turn: true, showmodal_sup: false });
               } else {
                    swal({
                         icon: "warning",
                         text: "ยังไม่ได้ออกใบกำกับภาษี",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
          } else {
               swal({
                    icon: "warning",
                    text: "กรุณาเลือก",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     modalSelectSupplier = () => {
          let { carorder } = this.state;
          return (
               <div style={{ padding: "0 10%" }}>
                    <LabelBar
                         title={"ใบกำกับภาษี"}
                         type={"radio"}
                         label={carorder.carturn === 0 ? ["อุปกรณ์ตกแต่ง"] : ["รถยนต์", "อุปกรณ์ตกแต่ง"]}
                         value={this.state.turn_type_pdf}
                         handleChangeText={(e) => {
                              this.setState({ turn_type_pdf: e.target.value });
                         }}
                    />
               </div>
          );
     };
     modalComfinace = () => {
          let { carorder, typeModal } = this.state;
          let arr_col_com = [
               {
                    dataField: "comfinace_code",
                    text: "เลขที่ TC",
                    formatter: (e, r) => (
                         <i className="fas fa-print icon __btn active" onClick={() => this.printOrderCom(r.comfinace_time)}>
                              {e}
                         </i>
                    ),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "comfinace_date",
                    text: "ประจำวันที่",
                    formatter: (e) => dayjs(e).format("DD/MM/") + (dayjs(e).year() + 543),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "reduce_code",
                    text: "เลขที่ CTC",
                    formatter: (e, r) =>
                         e ? (
                              <i className="fas fa-print icon __btn active" onClick={() => this.printReduceCom(r.comfinace_time)}>
                                   {e}
                              </i>
                         ) : (
                              <a onClick={() => this.setState({ showmodal_reduce_com: true, order_reduce_com: r })}>ลดหนี้</a>
                         ),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "reduce_date",
                    text: "วันที่ลดหนี้",
                    formatter: (e) => (e ? dayjs(e).format("DD/MM/") + (dayjs(e).year() + 543) : "-"),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "comfinace_time",
                    text: "งวดที่",
                    headerClasses: "header-custom __btn-s",
               },
               // {
               //      dataField: "comfinace_price",
               //      text: "ค่าคอมไฟแนนซ์",
               //      headerClasses: "header-custom __btn-m",
               // },
               // {
               //      dataField: "comfinace_vat",
               //      text: "VAT",
               //      headerClasses: "header-custom __btn-s",
               // },
               {
                    dataField: "comfinace_sum",
                    text: "ค่าคอม",
                    formatter: (e) =>
                         Number(e).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "reduce_sum",
                    text: "จำนวนลดหนี้",
                    formatter: (e) =>
                         Number(e).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                    headerClasses: "header-custom __btn-s",
               },
               // {
               //      dataField: "comfinace_time",
               //      text: "พิมพ์(ค่าคอม,ลดหนี้)",
               //      formatter: (e) => <i className="fas fa-print icon __btn active" onClick={() => this.printOrderCom(e, "ต้นฉบับ")}></i>,
               //      headerClasses: "header-custom __btn-s",
               // },
          ];
          let arr_col_label = [
               {
                    dataField: "checklabel_code",
                    text: "เลขที่ RP",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_tax_label_code",
                    text: "เลขที่ CP",
                    formatter: (e) => (e ? e : "-"),
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "label_return_date",
                    text: "วันที่คืนป้าย",
                    formatter: (e) => (e ? dayjs(e).format("DD/MM/") + (dayjs(e).year() + 543) : "-"),
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "labelhis_id",
                    text: "พิมพ์",
                    formatter: (e) => <i className="fas fa-print icon __btn active" onClick={() => this.printOrderCom(e)}></i>,
                    headerClasses: "header-custom __btn-s",
               },
          ];
          return (
               <div style={{ overflow: "auto" }}>
                    <Table
                         type="document"
                         column={typeModal === "com" ? arr_col_com : arr_col_label}
                         data={typeModal === "com" ? (carorder.orderCom ? carorder.orderCom : []) : carorder.orderLabel ? carorder.orderLabel : []}
                         keyField="comfinace_code"
                    />
                    <ModalPanel
                         show={this.state.showmodal_reduce_com}
                         title={"กรอกลดหนี้ค่าคอมไฟแนนซ์"}
                         type={"input"}
                         body={this.modalViewReduceCom()}
                         onHide={() => this.setState({ showmodal_reduce_com: false, order_reduce_com: undefined })}
                         // button={[{ onClick: this.saveReduceCom, name: "Submit", variant: "primary" }]}
                    />
               </div>
          );
     };
     modalViewReduceCom = () => {
          let { order_reduce_com } = this.state;
          // console.log("order_reduce_com", order_reduce_com);
          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col xs={12}>
                              <Field
                                   value={order_reduce_com ? order_reduce_com.reduce_price : null}
                                   type="number"
                                   title="จำนวนลดหนี้"
                                   placeholder="0.00"
                                   handleChangeNumber={(e) => {
                                        let { order_reduce_com } = this.state;
                                        order_reduce_com.reduce_price = e.floatValue;
                                        order_reduce_com.reduce_vat = e.floatValue * 0.07;
                                        this.setState({ order_reduce_com });
                                   }}
                                   decimalScale={2}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={order_reduce_com ? order_reduce_com.reduce_vat : null}
                                   type="number"
                                   title="VAT"
                                   placeholder="0.00"
                                   handleChangeNumber={(e) => {
                                        let { order_reduce_com } = this.state;
                                        order_reduce_com.reduce_vat = e.floatValue;
                                        this.setState({ order_reduce_com });
                                   }}
                                   decimalScale={2}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={order_reduce_com ? order_reduce_com.reduce_note : null}
                                   type="text"
                                   title="สาเหตุ"
                                   placeholder="กรอกสาเหตุการลดหนี้"
                                   handleChangeText={(el) => {
                                        let { order_reduce_com } = this.state;
                                        order_reduce_com.reduce_note = el.target.value;
                                        this.setState({ order_reduce_com });
                                   }}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   type="date"
                                   title="วันที่"
                                   placeholder={
                                        order_reduce_com && order_reduce_com.reduce_date
                                             ? dayjs(order_reduce_com.reduce_date).format("YYYY-MM-DD")
                                             : "เลือกวันที่"
                                   }
                                   handleSelectDate={(date) => {
                                        let { order_reduce_com } = this.state;
                                        order_reduce_com.reduce_date = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                                        this.setState({ order_reduce_com });
                                   }}
                                   formatDate="YYYY-MM-DD"
                              />
                         </Col>
                         <Col xs={12}>
                              <Field value={order_reduce_com ? order_reduce_com.comfinace_time : null} type="text" title="งวดที่" disabled={true} />
                         </Col>
                         <Col xs={12} className="mt-5 text-center">
                              {this.state.com_loading ? (
                                   <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        Loading...
                                   </Button>
                              ) : (
                                   <Button onClick={this.saveReduceCom}>Submit</Button>
                              )}
                         </Col>
                    </Row>
               </div>
          );
     };
     saveReduceCom = async () => {
          this.setState({ com_loading: true });
          let r = this.state.order_reduce_com;
          if (r.reduce_date && r.reduce_note && r.reduce_price && r.reduce_vat) {
               let { carorder } = this.state;
               r.branch_id = carorder.branch_id;
               try {
                    // this.setState({ comfinaceModal: false, showmodal_reduce_com: false });
                    await POST("/order/save_reduce_comfinace", r);
                    swal({
                         icon: "success",
                         text: "ทำรายการสำเร็จ",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then(() => {
                         this.setState({ comfinaceModal: false, showmodal_reduce_com: false, com_loading: false });
                         window.location.reload();
                    });
               } catch (err) {
                    this.setState({ comfinaceModal: false, showmodal_reduce_com: false, com_loading: false });
                    swal({
                         icon: "error",
                         text: err,
                         buttons: {
                              submit: "ลองใหม่อีกครั้ง",
                         },
                    }).then(() => {
                         this.getCarOrder();
                    });
                    console.log("error : ", err);
               }
          } else {
               this.setState({ com_loading: false });
               swal({
                    icon: "warning",
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     modalMakeUp = () => {
          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col xs={12}>
                              <Field
                                   type="date"
                                   title="กำหนดวันส่งมอบ"
                                   placeholder={this.state.makeup_date ? dayjs(this.state.makeup_date).format("YYYY-MM-DD") : "เลือกวันที่"}
                                   handleSelectDate={(date) => this.setState({ makeup_date: dayjs(date).format("YYYY-MM-DD HH:mm:ss") })}
                                   formatDate="YYYY-MM-DD"
                                   disabledDate={this.disabledDateMakeUp}
                              />
                         </Col>
                         <Col xs={12} className="my-3">
                              {this.state.loading_makeup ? (
                                   <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        Loading...
                                   </Button>
                              ) : (
                                   <Button onClick={this.setMakeUp}>Submit</Button>
                              )}
                         </Col>
                    </Row>
               </div>
          );
     };
     disabledDateMakeUp = (current) => {
          // console.log("current", dayjs(current).hour());
          // if (dayjs(current).hour() <= 9) {
          //      return current && current < dayjs().startOf("day");
          // } else {
          return current && current < dayjs().add(3, "day").startOf("day");
          // }
     };
     setMakeUp = async () => {
          this.setState({ loading_makeup: true });
          let { makeup_date, carorder } = this.state;
          // console.log("first", carorder.order_id);
          if (makeup_date) {
               try {
                    await POST("/car/set_makeup", { order_id: carorder.order_id, makeup_date });
                    swal({
                         icon: "success",
                         text: "ส่งติดตั้งอุปกรณ์เสร็จสิ้น",
                         buttons: {
                              submit: "ตกลง",
                         },
                    }).then(() => window.location.reload());
                    // this.setState({ loading_makeup: false, showmodal_makeup: false });
               } catch (error) {
                    this.setState({ loading_makeup: false });
               }
          } else {
               this.setState({ loading_makeup: false });
               swal({
                    icon: "warning",
                    text: "กรุณากรอกข้อมูลให้ครบ",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };
     printOrderCom = async (e) => {
          // console.log("e", e);
          let { typeModal } = this.state;
          let api = typeModal === "com" ? "commission_finance_pdf" : "reduce_tax_label_pdf";
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/${api}`,
                         { order_id: this.state.id, type: "ต้นฉบับ", time: e, id: e },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               console.log("error", error);
          }
     };
     printReduceCom = async (e) => {
          let api = "reduce_commission_finance_pdf";
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/${api}`,
                         { order_id: this.state.id, type: "ต้นฉบับ", time: e, id: e },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               console.log("error", error);
          }
     };
     modalViewSelectSupplier = () => {
          let { supplier_acc, sup_order, supplierTurn } = this.state;
          let supplier_option = [];
          for (let i in supplierTurn) {
               supplier_option.push({ value: supplierTurn[i].supplier_id, name: supplierTurn[i].supplier_name });
          }

          return (
               <div style={{ padding: "0 15%" }}>
                    <Field
                         value={sup_order?.supplier_id}
                         type="select"
                         name={"supplier_id"}
                         title={"Supplier"}
                         option={supplier_option}
                         handleChangeText={(e) => this.handleChangeSelectSupplier(e)}
                    />
               </div>
          );
     };

     modalViewInput = () => {
          let { supplier_acc, sup_order, carorder, order_doc } = this.state;
          let total = 0;
          total = order_doc.comfinace_price + order_doc.comfinace_vat;
          order_doc.comfinace_sum = total;
          // console.log('order_doc', order_doc)
          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">Series</span>
                              </Row>
                              <Row>
                                   <span>{carorder?.series_name}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   type="number"
                                   name={"comfinace_price"}
                                   value={order_doc?.comfinace_price}
                                   title={"ค่าคอมไฟแนนซ์"}
                                   placeholder={"0.00"}
                                   decimalScale={2}
                                   handleChangeNumber={(e) => this.handleChangeNumber(e, "comfinace_price")}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">Model</span>
                              </Row>
                              <Row>
                                   <span>{carorder?.model_name}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   type="number"
                                   name={"comfinace_vat"}
                                   value={order_doc?.comfinace_vat}
                                   title={"VAT"}
                                   placeholder={"0.00"}
                                   decimalScale={2}
                                   handleChangeNumber={(e) => this.handleChangeNumber(e, "comfinace_vat")}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">สี</span>
                              </Row>
                              <Row>
                                   <span>{carorder?.color_name}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   type="number"
                                   name={"comfinace_sum"}
                                   value={order_doc?.comfinace_sum}
                                   title={"ทั้งหมด"}
                                   placeholder={"0.00"}
                                   decimalScale={2}
                                   handleChangeNumber={(e) => this.handleChangeNumber(e, "comfinace_sum")}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   {/* <span className="gray">หมายเลขเครื่องยนต์</span> */}
                                   <span className="gray">เลขถัง</span>
                              </Row>
                              <Row>
                                   <span>{carorder?.car_tank}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="date"
                                   name={"comfinace_date"}
                                   title={"ประจำวันที่"}
                                   placeholder={
                                        order_doc.comfinace_date && order_doc.comfinace_date != 0
                                             ? dayjs(order_doc.comfinace_date).format("YYYY-MM-DD")
                                             : dayjs(new Date()).format("YYYY-MM-DD")
                                   }
                                   handleSelectDate={this.handleSelectDate}
                                   // disabled={true}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">ราคาทั้งหมด</span>
                              </Row>
                              <Row>
                                   <span>
                                        {(carorder?.real_price || 0).toLocaleString(undefined, {
                                             minimumFractionDigits: 2,
                                             maximumFractionDigits: 2,
                                        })}
                                   </span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="text"
                                   name={"comfinace_time"}
                                   value={order_doc?.comfinace_time}
                                   title={"งวดที่"}
                                   placeholder={"กรอกงวดที่"}
                                   handleChangeText={(e) => this.handleChangeTextCom(e)}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">ผู้ซื้อ</span>
                              </Row>
                              <Row>
                                   <span>{carorder?.userinfo_name}</span>
                              </Row>
                         </Col>
                         <Col></Col>
                    </Row>
                    <Row className="mt-5 text-center">
                         <Col xs={12}>
                              {this.state.com_loading ? (
                                   <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        Loading...
                                   </Button>
                              ) : (
                                   <Button onClick={this.addCommissionFinance}>Submit</Button>
                              )}
                         </Col>
                    </Row>
               </div>
          );
     };

     modalViewCreditNote = () => {
          let { order_doc_tax } = this.state;
          // console.log("order", carorder);

          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col>
                              <InputPanel
                                   type={"textarea"}
                                   name={"reduce_tax_note"}
                                   rows={3}
                                   value={order_doc_tax?.reduce_tax_note}
                                   title={"สาเหตุการลดหนี้"}
                                   placeholder={"กรอกสาเหตุการลดหนี้"}
                                   handleChange={(e) => this.handleChangeText(e)}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="date"
                                   name={"reduce_tax_date"}
                                   title={"ประจำวันที่"}
                                   placeholder={order_doc_tax.reduce_tax_date ? order_doc_tax.reduce_tax_date : "เลือกวันที่"}
                                   handleSelectDate={this.handleSelectDate}
                              />
                         </Col>
                         <Col></Col>
                    </Row>
               </div>
          );
     };

     addReduceRegis = async () => {
          let { reduce_regis, carorder } = this.state;
          reduce_regis.order_id = carorder.order_id;
          reduce_regis.branch_id = carorder.branch_id;

          try {
               reduce_regis.reduce_register_tax_date = dayjs(reduce_regis.reduce_register_tax_date).format("YYYY-MM-DD");
               await POST("/order/save_reduce_register_tax", reduce_regis);
               reduce_regis.reduce_register_tax_date = dayjs(reduce_regis.reduce_register_tax_date);

               this.setState({ modal_reduce_regis: false });
               this.getCarOrder();
          } catch (error) {
               console.log(`error`, error);
          }
     };
     modalReduceRegis = () => {
          let { reduce_regis } = this.state;
          // console.log("order", carorder);

          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col>
                              <InputPanel
                                   type={"textarea"}
                                   name={"reduce_register_tax_note"}
                                   rows={3}
                                   value={reduce_regis?.reduce_register_tax_note}
                                   title={"สาเหตุการลดหนี้"}
                                   placeholder={"กรอกสาเหตุการลดหนี้"}
                                   handleChange={(e) => this.handleChangeText(e)}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="date"
                                   name={"reduce_register_tax_date"}
                                   title={"ประจำวันที่"}
                                   formatDate={"DD/MM/YYYY"}
                                   placeholder={reduce_regis.reduce_register_tax_date ? reduce_regis.reduce_register_tax_date : "เลือกวันที่"}
                                   value={reduce_regis.reduce_register_tax_date ? dayjs(reduce_regis.reduce_register_tax_date) : dayjs(new Date())}
                                   handleSelectDate={this.handleSelectDate}
                              />
                         </Col>
                         <Col></Col>
                    </Row>
               </div>
          );
     };

     openSupPDF = async () => {
          let { sup_order, users, carorder } = this.state;
          try {
               let token = localStorage.getItem("token");

               await axios
                    .post(`${ip}/order/turn_tax_pdf`, sup_order, {
                         responseType: "blob",
                         headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              token,
                         },
                    })
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         this.setState({ showmodal_sup: false });
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: "ยังไม่ได้ออกใบกำกับภาษี",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };
     btnApprove = (row, margin_over, onClickApprove, order, dataFun, margin_total, rowIndex) => {
          let { position_id_data, carorder } = this.state;
          let margin_lv = carorder.lvl_margin;
          let apprv, color_status;
          this.setState({ margin_total });
          if (carorder.acept_status && carorder.acept_status !== "ยกเลิกใบจอง" && carorder.orderMargin.length > 0) {
               const approve = (event) => {
                    if (event === "approve") {
                         apprv = "อนุมัติ";
                    } else if (event === "pass") {
                         apprv = "ส่งต่อ";
                    } else if (event === "app") {
                         apprv = "-อนุมัติ-";
                         color_status = "green";
                    } else if (event === "reject") {
                         apprv = "-ไม่อนุมัติ-";
                         color_status = "red";
                    } else {
                         apprv = "-ผิดพลาด-";
                         color_status = "red";
                    }
                    return (
                         <>
                              {apprv === "-ผิดพลาด-" || apprv === "-อนุมัติ-" || apprv === "-ไม่อนุมัติ-" ? (
                                   <>
                                        <p style={{ color: color_status }}>{`--${apprv}--`}</p>
                                   </>
                              ) : (
                                   <>
                                        <Button
                                             style={{ width: "45%", marginRight: 5, alignSelf: "center" }}
                                             variant={"success"}
                                             onClick={() => onClickApprove(carorder, apprv, row.function_id, margin_total)}
                                        >
                                             {/* <i class="fas fa-check" /> */}อนุมัติ
                                        </Button>
                                        <Button
                                             style={{ width: "45%", marginRight: 5 }}
                                             variant={"danger"}
                                             onClick={() => onClickApprove(carorder, "ไม่อนุมัติ", row.function_id)}
                                        >
                                             {/* <i class="fas fa-times" /> */}ไม่อนุมัติ
                                        </Button>
                                   </>
                              )}
                         </>
                    );
               };
               // console.log(`carorder.orderMargin`, carorder.orderMargin);
               // console.log("margin_over", margin_over);
               // if (position_id_data === 17 && carorder.orderMargin.length > 0) {
               if (getToken().position_id === 16 && margin_lv === 1 && margin_over < 1) {
                    if (carorder.orderMargin.length > 0) {
                         if (margin_lv === 1 && row.function_id === 1) {
                              if (carorder.orderMargin[0].event === "รออนุมัติ") {
                                   if (margin_over <= dataFun[0].max) {
                                        return approve("approve");
                                   } else if (margin_over > dataFun[0].max) {
                                        return approve("pass");
                                   }
                              } else if (carorder.orderMargin[0].event === "อนุมัติ") {
                                   return approve("app");
                              }
                         }
                         // } else if (position_id_data === 9 && carorder.orderMargin.length > 0) {
                    }
               } else {
                    if (position_id_data === row.position_id && carorder.orderMargin.length > 0) {
                         if (margin_lv === 1 && row.function_id === 1) {
                              if (carorder.orderMargin[0].event === "รออนุมัติ") {
                                   if (margin_over <= dataFun[0].max) {
                                        return approve("approve");
                                   } else if (margin_over > dataFun[0].max) {
                                        return approve("pass");
                                   }
                              } else if (carorder.orderMargin[0].event === "อนุมัติ") {
                                   return approve("app");
                              }
                         }
                         // } else if (position_id_data === 9 && carorder.orderMargin.length > 0) {
                    }
                    if (position_id_data === row.position_id && carorder.orderMargin.length > 0) {
                         // console.log(`position_id_data`, position_id_data, row, margin_lv);
                         if (margin_lv === 2 && row.function_id === 2) {
                              if (carorder.orderMargin[1].event === "รออนุมัติ") {
                                   if (margin_over <= dataFun[1].max) {
                                        return approve("approve");
                                   } else if (margin_over > dataFun[1].max) {
                                        return approve("pass");
                                   }
                              } else if (carorder.orderMargin[1].event === "อนุมัติ") {
                                   return approve("app");
                              }
                         }
                         // } else if (position_id_data === 2 && carorder.orderMargin.length > 0) {
                    }
                    if (position_id_data === row.position_id && carorder.orderMargin.length > 0) {
                         if (margin_lv === 3 && row.function_id === 3) {
                              if (carorder.orderMargin[2].event === "รออนุมัติ") {
                                   if (margin_over <= dataFun[2].max) {
                                        return approve("approve");
                                   } else if (margin_over > dataFun[2].max) {
                                        return approve("pass");
                                   }
                              } else if (carorder.orderMargin[2].event === "อนุมัติ") {
                                   return approve("app");
                              }
                         }
                         // } else if (position_id_data === 1 && carorder.orderMargin.length > 0) {
                    }
                    if (position_id_data === row.position_id && carorder.orderMargin.length > 0) {
                         if (margin_lv === 4 && row.function_id === 4) {
                              if (carorder.orderMargin[3].event === "รออนุมัติ") {
                                   if (margin_over <= dataFun[3].max) {
                                        return approve("approve");
                                   } else if (margin_over > dataFun[3].max) {
                                        return approve("approve");
                                   }
                              } else if (carorder.orderMargin[3].event === "อนุมัติ") {
                                   return approve("app");
                              }
                         }
                    }
               }

               for (let i = 0; i < dataFun.length; i++) {
                    if (row.function_id === i + 1 && carorder.orderMargin[i]?.event) {
                         if (margin_lv === i + 1 && row.function_id === i + 1 && carorder.orderMargin[i].event === "รออนุมัติ") {
                              return <p style={{ color: "#ffbc00" }}>---{carorder.orderMargin[i]?.event}---</p>;
                         }
                         if (carorder.orderMargin[i]?.event === "อนุมัติ") {
                              return <p style={{ color: "green" }}>---{carorder.orderMargin[i]?.event}---</p>;
                         } else if (carorder.orderMargin[i]?.event === "ไม่อนุมัติ") {
                              this.setState({ title_step_app: "ปฏิเสธการอนุมัติ" });
                              return <p style={{ color: "red" }}>---{carorder.orderMargin[i]?.event}---</p>;
                         } else if (
                              carorder.orderMargin[i]?.event === "รออนุมัติ" &&
                              !carorder.acept_margin &&
                              !carorder.orderMargin.find((el) => el.event === "ไม่อนุมัติ") &&
                              carorder.acept_status === "ใบจองรออนุมัติ"
                         ) {
                              return <p style={{ color: "blue" }}>---{carorder.orderMargin[i]?.event}---</p>;
                         }
                    }
                    //  else {
                    //      return <p style={{ color: "red" }}>---ผิดพลาด---</p>;
                    // }
               }
          } else {
               return null;
          }
     };
     //-----------------------Modal Approve------------------------
     noteModalApprove = ({ target: { name, value } }) => {
          // let note = value;
          this.setState({ [name]: value });
     };
     onClickApprove = (order, event, post) => {
          this.setState({ openModalApp: true, order, event, post });
     };
     onClickSubmitApprove = () => {
          let { order, event, post, note, note_manager } = this.state;
          this.onClickMailMargin(order, event, post, note, note_manager);
          this.setState({ openModalApp: false });
     };
     onHideModal = () => {
          this.setState({ openModalApp: false });
     };

     //----------------------POST approve----------------------
     onClickMailMargin = async (order, event, post, note, note_manager) => {
          let { position, acept_margin, margin_total, dataFun } = this.state;
          let position_id, next_lvl, current_lvl;
          let text = "Margin เกิน";
          if (!acept_margin) {
               acept_margin = margin_total;
          }
          if (post === 1) {
               if (event === "ส่งต่อ") {
                    next_lvl = 2;
                    position_id = dataFun.find((el) => el.function_id === next_lvl).position_id;
                    current_lvl = 1;
               }
               if (event === "อนุมัติ") {
                    current_lvl = 1;
                    position_id = dataFun.find((el) => el.function_id === current_lvl).position_id;
                    text = "กรุณาชำระเงินจอง";
               } else if (event === "ไม่อนุมัติ") {
                    position_id = null;
                    current_lvl = 1;
                    text = "ไม่อนุมัติ Margin";
               }
          } else if (post === 2) {
               if (event === "ส่งต่อ") {
                    next_lvl = 3;
                    position_id = dataFun.find((el) => el.function_id === next_lvl).position_id;
                    current_lvl = 2;
               } else if (event === "อนุมัติ") {
                    current_lvl = 2;
                    position_id = dataFun.find((el) => el.function_id === current_lvl).position_id;
                    text = "กรุณาชำระเงินจอง";
               } else if (event === "ไม่อนุมัติ") {
                    position_id = null;
                    current_lvl = 2;
                    text = "ไม่อนุมัติ Margin";
               }
          } else if (post === 3) {
               if (event === "ส่งต่อ") {
                    next_lvl = 4;
                    position_id = dataFun.find((el) => el.function_id === next_lvl).position_id;
                    current_lvl = 3;
               } else if (event === "อนุมัติ") {
                    current_lvl = 3;
                    position_id = dataFun.find((el) => el.function_id === current_lvl).position_id;
                    text = "กรุณาชำระเงินจอง";
               } else if (event === "ไม่อนุมัติ") {
                    position_id = null;
                    current_lvl = 3;
                    text = "ไม่อนุมัติ Margin";
               }
          } else if (post === 4) {
               if (event === "อนุมัติ") {
                    current_lvl = 4;
                    position_id = dataFun.find((el) => el.function_id === current_lvl).position_id;
                    text = "กรุณาชำระเงินจอง";
               } else if (event === "ไม่อนุมัติ") {
                    position_id = null;
                    current_lvl = 4;
                    text = "ไม่อนุมัติ Margin";
               }
          }
          try {
               this.setState({ loading: true });
               await POST("/order/mail_margin", {
                    position_id: position_id,
                    order_id: order.order_id,
                    text: text,
                    margin: order.margin || 0,
                    current_lvl: current_lvl,
                    next_lvl: next_lvl,
                    event: event,
                    acept_margin: acept_margin,
                    note: note,
                    note_manager: note_manager,
               });
               await this.setState({ loading: false });
               window.location.reload();
          } catch (error) {
               console.log("error", error);
          }
     };

     onClickcancelConfirm = async () => {
          let { carorder } = this.state;
          let status;
          if (carorder.pay_choice === "ผ่อนชำระ") {
               status = "Leasing อนุมัติแล้ว";
          } else if (carorder.pay_choice === "เงินสด") {
               status = "รับเงินจองแล้ว";
          }
          try {
               this.setState({ showModalConfirm: false });
               let res = await POST("/order/cancel_confirm", { order_id: carorder.order_id, acept_status: status });

               swal({
                    icon: "success",
                    text: "ยกเลิกข้อมูลการจองสำเร็จ",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               swal({
                    icon: "error",
                    text: `${error}`,
                    title: "ไม่สามารถยกเลิกการจองได้่",
                    buttons: {
                         danger: "ลองใหม่อีกครั้ง",
                    },
               });
          }
     };

     /*------------------log_do-------------------*/
     showDetailHistory = async (row) => {
          await this.getLogDetail(row.log_id);
          this.setState({ show_history: true });
     };

     buttonFormatter = (cell, row) => {
          return row.log_do === "แก้ไข" ? <Button onClick={() => this.showDetailHistory(row)}>ดูการแก้ไข</Button> : null;
     };
     showButtonBack = () => {
          this.setState({ show_history: false });
     };

     imageViewFormatter = (cell, row) => {
          if (row.acc_image) {
               return <a onClick={() => this.setState({ openModalImg: true, imgPath: cell })}>View</a>;
          } else {
               return null;
          }
     };

     //----------------- Acc Reduce ----------------//
     modalReduce = () => {
          let { save_reduce } = this.state;
          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_price"]}
                                   type="number"
                                   title="จำนวนลดหนี้"
                                   placeholder="0.00"
                                   handleChangeNumber={(e) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_price"] = e.floatValue;
                                        save_reduce["reduce_vat"] = e.floatValue * 0.07;
                                        this.setState({ save_reduce });
                                   }}
                                   decimalScale={2}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_vat"]}
                                   type="number"
                                   title="VAT"
                                   placeholder="0.00"
                                   handleChangeNumber={(e) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_vat"] = e.floatValue;
                                        this.setState({ save_reduce });
                                   }}
                                   decimalScale={2}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_note"]}
                                   type="text"
                                   title="สาเหตุ"
                                   placeholder="กรอกสาเหตุการลดหนี้"
                                   handleChangeText={(el) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_note"] = el.target.value;
                                        this.setState({ save_reduce });
                                   }}
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   type="date"
                                   title="วันที่"
                                   placeholder={save_reduce["reduce_date"] ? dayjs(save_reduce["reduce_date"]).format("YYYY-MM-DD") : "เลือกวันที่"}
                                   handleSelectDate={(date) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_date"] = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                                        this.setState({ save_reduce });
                                   }}
                                   formatDate="YYYY-MM-DD"
                              />
                         </Col>
                         <Col xs={12}>
                              <Field
                                   value={save_reduce["reduce_time"]}
                                   type="number"
                                   title="ครั้งที่"
                                   placeholder="0"
                                   handleChangeNumber={(e) => {
                                        let { save_reduce } = this.state;
                                        save_reduce["reduce_time"] = e.floatValue;
                                        this.setState({ save_reduce });
                                   }}
                              />
                         </Col>
                    </Row>
               </div>
          );
     };
     saveReduce = async () => {
          let { save_reduce } = this.state;
          if (save_reduce.reduce_price && save_reduce.reduce_vat && save_reduce.reduce_note && save_reduce.reduce_date && save_reduce.reduce_time) {
               try {
                    await POST("/order/save_reduce_acc_2", save_reduce);
                    // console.log(result);
                    this.setState({ modal_reduce_acc: false });
                    swal({
                         icon: "success",
                         text: "ทำรายการสำเร็จ",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                    }).then(() => {
                         this.getCarOrder();
                    });
               } catch (err) {
                    this.setState({ modal_reduce_acc: false });
                    swal({
                         icon: "warning",
                         text: err,
                         buttons: {
                              submit: "ลองใหม่อีกครั้ง",
                         },
                    }).then(() => {
                         this.getCarOrder();
                    });
                    console.log("error : ", err);
               }
          } else {
               swal({
                    icon: "warning",
                    text: "กรอกข้อมูลให้ครบทุกช่อง",
                    buttons: {
                         submit: "ลองใหม่อีกครั้ง",
                    },
               });
          }
     };
     modalReducePrint = () => {
          let arr_col_com = [
               {
                    dataField: "reduce_code",
                    text: "เลขที่ CT",
                    formatter: (e, r) => (
                         <i className="fas fa-print icon __btn active" onClick={() => this.printAccReduce(r)}>
                              {e}
                         </i>
                    ),
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_date",
                    text: "ประจำวันที่",
                    formatter: (e) => dayjs(e).format("DD/MM/") + (dayjs(e).year() + 543),
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_time",
                    text: "งวดที่",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "reduce_sum",
                    text: "จำนวนลดหนี้ (รวมภาษี)",
                    formatter: (e) =>
                         Number(e).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                    headerClasses: "header-custom __btn-m",
               },
          ];
          return (
               <div style={{ overflow: "auto" }}>
                    <Table type="document" column={arr_col_com} data={this.state.carorder.accReduce} keyField="comfinace_code" />
               </div>
          );
     };
     printAccReduce = async (e) => {
          let api = "reduce_acc_pdf_2";
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/${api}`,
                         { order_id: this.state.carorder.order_id, id: e.id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               console.log("error", error);
          }
     };
     render() {
          let {
               id,
               key,
               order,
               file,
               filepreview,
               carorder,
               show_popup,
               step_status,
               btn_acept,
               workflow_order,
               supplier_acc,
               showmodal_sup,
               comfinaceModal,
               doc_active,
               show_breadcrumbs,
               show_history,
               logs,
               log_info,
               sign,
               dataFun,
               title_step_app = "ใบจองอนุมัติแล้ว",
               modal_skb,
               typeModal,
          } = this.state;
          let step = [
               {
                    title: "ใบจองรออนุมัติ",
                    active: step_status.a1,
               },
               {
                    title: title_step_app,
                    active: step_status.a2,
                    activeBtn: step_status.approve_booking,
                    btn: () => window.location.assign(`/admin/discount/${id}`),
               },
               {
                    title: "รับเงินจองแล้ว",
                    active: step_status.a3,
                    activeBtn: step_status.receipt_booking,
                    btn: () => window.location.assign(`/admin/payment/order/${id}`),
               },
               {
                    title: "Leasing อนุมัติแล้ว",
                    active: step_status.a4,
                    activeBtn: step_status.approve_leasing,
                    btn: () => window.location.assign(`/admin/order/leasing/${id}`),
               },
               {
                    title: "ยืนยันข้อมูล",
                    active: step_status.a5,
                    activeBtn: step_status.submit_info,
                    btn: () => this.setState({ scroll_close: true }),
               },
               {
                    title: "จับคู่รถแล้ว",
                    active: step_status.a6,
                    activeBtn: step_status.coupled_car,
                    btn: () => window.location.assign(`/admin/match/car/${carorder.series_id}/${carorder.model_id}/${carorder.color_id}`),
               },
               {
                    title: "ติดตั้งอุปกรณ์แล้ว",
                    active: step_status.a7,
                    activeBtn: step_status.installed_acc,
                    btn: () => window.location.assign(`/admin/install/list/car/${id}`),
               },
               {
                    title: "ชำระเงินแล้ว",
                    active: step_status.a8,
                    activeBtn: step_status.payed,
                    btn: () => window.location.assign(`/admin/payment/product/car/${id}`),
               },
               {
                    title: "ส่งมอบรถแล้ว",
                    active: step_status.a9,
                    activeBtn: step_status.sent_car,
                    btn: () => window.location.assign(`/admin/deliver/car/car/${id}`),
               },
               {
                    title: "ปิดการขาย",
                    active: step_status.a10,
                    activeBtn: step_status.close_order,
                    btn: () => this.setState({ scroll_close: true }),
               },
          ];
          // เอกสารการขายก
          // console.log(`object`, carorder);
          if (
               carorder.pay_choice === "ผ่อนชำระ" &&
               carorder.receipt &&
               carorder.receipt.length > 0 &&
               carorder.receipt.some((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ")
          ) {
               carorder.receipt.splice(
                    carorder.receipt.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ"),
                    1
               );
          }
          let doc = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการเอกสาร",
                         formatter: this.docFormatter,
                         headerClasses: "header-custom __left __btn-ml",
                    },
                    {
                         dataField: "create_date",
                         text: "วันที่ออกเอกสาร",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "add",
                         text: "กรอกข้อมูล",
                         formatter: this.detailFormatter,
                         formatExtraData: "document",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "sign",
                         text: "Sign on Mobile",
                         formatter: this.workflowFormatter,
                         headerClasses: "header-custom  __btn-s",
                    },
                    {
                         dataField: "file",
                         text: "พิมพ์",
                         formatter: this.printFormatter,
                         headerClasses: "header-custom  __icon __btn-ss",
                    },
                    // {
                    //      dataField: "copy",
                    //      text: "สำเนา",
                    //      formatter: this.printFormatter,
                    //      headerClasses: "header-custom __right __icon __btn-ss",
                    //      formatExtraData: "copy",
                    // },
               ],
               data: [
                    {
                         file: "/order/order_leadsing_pdf",
                         title: "ใบรับจองรถยนต์",
                         create_date: carorder?.created_at,
                         detail: "-",
                         sign: "Sign on Mobile",
                         active: doc_active.a1,
                         show: "xxx",
                    },
                    {
                         file: "/order/reservation_pdf",
                         title: "สัญญาจองรถยนต์ (สคบ.)",
                         create_date: carorder?.created_at,
                         detail: "กรอกข้อมูล",
                         sign: "Sign on Mobile",
                         active: doc_active.a1,
                         show: "xxx",
                    },
                    {
                         file: "/order/order_margin_pdf",
                         title: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต",
                         create_date: carorder?.margin_acept_date,
                         detail: "-",
                         sign: "",
                         active: doc_active.a2,
                    },
                    {
                         file: "/order/order_payment_tax_pdf",
                         title: "ใบกำกับภาษีเงินจอง",
                         create_date: carorder?.orp_updated_at,
                         detail: "-",
                         sign: "Sign on Mobile",
                         active: doc_active.a3,
                         show: "xxx",
                         // copy: true,
                    },
                    {
                         file: "/order/get_payment_leasing_pdf",
                         title: "ใบยืนยันลีสซิ่ง",
                         create_date: carorder?.leasing_acecpt_date,
                         detail: "-",
                         sign: "",
                         active: doc_active.a4,
                    },
                    {
                         file: carorder?.pay_choice === "เงินสด" ? "/order/tax_pdf" : "/order/tax_leasing_pdf",
                         title: "ใบแจ้งหนี้",
                         create_date: carorder?.delivery_date,
                         detail: "-",
                         sign: "",
                         active: doc_active.a15,
                    },
                    {
                         file: "/order/commission_finance_pdf",
                         title: "ใบกรอกค่าคอมมิชชั่นไฟแนนซ์",
                         create_date: carorder?.comfinace_saveDate,
                         detail: "กรอกข้อมูล",
                         sign: "",
                         active: doc_active.a5,
                    },
                    // {
                    //      file: "/order/reduce_tax_pdf",
                    //      title: "ใบลดหนี้เงินจอง",
                    //      create_date: carorder?.reduce_tax_saveDate,
                    //      detail: "กรอกข้อมูล",
                    //      sign: "",
                    //      active: doc_active.a6,
                    //      copy: true,
                    // },
                    {
                         file: "/order/reduce_tax_register_pdf",
                         title: "ใบคืนเงินค่ามัดจำชุดจดทะเบียน",
                         create_date: carorder?.reduce_register_tax_date,
                         detail: "กรอกข้อมูล",
                         sign: "",
                         active: doc_active.a18,
                    },

                    {
                         file: "/order/delivery_pdf",
                         title: "ใบส่งมอบรถ",
                         create_date: carorder?.delivery_date,
                         detail: "-",
                         sign: "Sign on Mobile",
                         active: doc_active.a7,
                         show: "xxx",
                    },
                    {
                         file: "/order/car_out_pdf",
                         title: "ใบอนุญาตนำรถออก",
                         create_date: carorder?.delivery_date,
                         detail: "-",
                         sign: "",
                         active: doc_active.a9,
                    },
                    {
                         file: "/order/car_pay_pdf",
                         // title: "ใบกำกับภาษี / ใบแจ้งหนี้ ค่ารถ",
                         // title: "ใบกำกับภาษีค่ารถ",
                         title: "ใบเสร็จรับเงิน",
                         // create_date: carorder?.orcp_updated_at,
                         create_date: carorder?.orcp_leasing_date,
                         detail: "-",
                         sign: "",
                         active: doc_active.a8,
                    },
                    // {
                    //      file: carorder?.pay_choice === "เงินสด" ? "/order/car_pay_pdf" : "/order/car_pay_leasing_pdf",
                    //      title: "ใบเสร็จรับเงิน",
                    //      // create_date: carorder?.orcp_updated_at,
                    //      create_date: carorder?.pay_choice === "เงินสด" ? carorder?.orcp_date : carorder?.orcp_leasing_date,
                    //      detail: "-",
                    //      sign: "",
                    //      active: doc_active.a10,
                    // },
                    // {
                    //      file: "/order/tempory_label_pdf",
                    //      title: "ใบรับเงินชั่วคราวป้ายแดง",
                    //      // create_date: carorder?.recive_date,
                    //      create_date: "",
                    //      detail: "-",
                    //      sign: "",
                    //      // active: doc_active.a11,
                    //      active: false,
                    // },
                    {
                         file: "/order/reduce_tax_label_pdf",
                         title: "ใบเสร็จคืนเงินป้ายแดง",
                         // create_date: carorder?.label_return_date,
                         create_date: null,
                         detail: "คืนเงิน",
                         sign: "",
                         active: doc_active.a17,
                    },
                    // {
                    //      file: "/order/tempory_register_pdf",
                    //      title: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                    //      create_date: carorder?.orcp_updated_at,
                    //      detail: "-",
                    //      sign: "",
                    //      active: doc_active.a12,
                    // },
                    {
                         file: "/order/summary_delivery_car",
                         title: "สรุปรายการรับเงินวันส่งมอบรถ",
                         create_date: carorder?.delivery_date,
                         detail: "-",
                         sign: "",
                         active: doc_active.a13,
                    },
                    //  เก็บไว้ก่อน
                    // {
                    //      file: "/car/borrow_label_pdf",
                    //      title: "ใบยืมป้ายแดง",
                    //      create_date: carorder?.checklabel_date,
                    //      detail: "-",
                    //      sign: "-",
                    //      active: doc_active.a14,
                    // },
                    // {
                    //      file: "/order/tax_pdf",
                    //      title: "ใบแจ้งหนี้",
                    //      create_date: carorder?.confirm_date,
                    //      detail: "-",
                    //      sign: "",
                    //      active: doc_active.a15,
                    // },
                    {
                         file: "/order/turn_tax_pdf",
                         title: "ใบกำกับภาษีเทิร์นสินค้า",
                         create_date: carorder?.order_turn_payment_updated_at,
                         detail: "-",
                         sign: "",
                         active: doc_active.a16,
                    },
                    // ...(carorder.receipt ? carorder.receipt : []).map((el) => ({
                    //      file: el?.file,
                    //      title: el?.receipt_method,
                    //      // create_date: dayjs(new Date()).format("YYYY-MM-DD"),
                    //      create_date: el?.date,
                    //      detail: "-",
                    //      sign: "",
                    //      active: el?.active,
                    //      type: el?.type,
                    //      receipt_code: el?.receipt_code,
                    //      receipt_id: el?.receipt_id,
                    //      receipt_price: el?.receipt_price,
                    //      receipt_method: el?.receipt_method,
                    // })),
                    {
                         // file: "/order/reduce_acc_pdf",
                         title: "ใบลดหนี้ค่าอุปกรณ์",
                         create_date: false,
                         detail: "กรอกข้อมูล",
                         sign: "",
                         active:
                              carorder.receipt &&
                              carorder.receipt.filter((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี").length &&
                              carorder.receipt.filter((el) => el.receipt_method === "ใบเสร็จรับเงิน/ใบกำกับภาษี")[0].receipt_code,
                    },
               ],
               data_two: [
                    ...(carorder.receipt ? carorder.receipt : []).map((el) => ({
                         file: el?.file,
                         title: el?.receipt_method,
                         // create_date: dayjs(new Date()).format("YYYY-MM-DD"),
                         create_date: el?.ordate,
                         detail: "-",
                         sign: "",
                         active: true,
                         type: "receipt",
                         receipt_code: el?.receipt_code,
                         receipt_id: el?.receipt_id,
                         receipt_price: el?.receipt_price,
                         receipt_method: el?.receipt_method,
                         name_pay: el?.name_pay,
                    })),
               ],
               keyField: "title",
          };
          // if (carorder.register !== "Yes") {
          //      doc.data.splice(8, 1);
          // }

          //แนบไฟล์
          let check_print = [1, 2, 10, 11, 12, 16, 17, 21, 25, 27];
          let file_table = {
               column: [
                    {
                         dataField: "file_name",
                         text: "ไฟล์",
                         headerClasses: "header-custom __left __btn-m",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่อัปโหลด",
                         formatter: this.dateFormatter,
                         formatExtraData: "file",
                         headerClasses: "header-custom __btn-s",
                    },
                    check_print.includes(getToken().position_id)
                         ? {
                                dataField: "doc",
                                text: "พิมพ์",
                                formatter: this.printFormatter,
                                headerClasses: "header-custom __icon ",
                                formatExtraData: "file",
                                headerClasses: "header-custom __btn-ss",
                           }
                         : { headerClasses: "header-custom __btn-ss" },

                    {
                         dataField: "remove",
                         text: "Remove",
                         formatter: this.removeBtnFormatter,
                         headerClasses: "header-custom __right __icon",
                         formatExtraData: "file",
                         headerClasses: "header-custom __btn-ss",
                    },
               ],
               data: carorder.file ? carorder.file : [],
               keyField: "file_name",
               column_leasing: [
                    {
                         dataField: "file_name",
                         text: "ไฟล์",
                         headerClasses: "header-custom __left __btn-m",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่อัปโหลด",
                         formatExtraData: "file",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "doc",
                         text: "พิมพ์",
                         formatter: this.printFormatter,
                         headerClasses: "header-custom __icon ",
                         formatExtraData: "file",
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "remove",
                         text: "Remove",
                         headerClasses: "header-custom __right __icon",
                         formatExtraData: "file",
                         headerClasses: "header-custom __btn-ss",
                    },
               ],
               data_leasing: [
                    { file_name: "1", created_at: "-", doc: carorder.credit_ex_file, file: carorder.credit_ex_file, remove: "-" },
                    { file_name: "2", created_at: "-", doc: carorder.credit_acept_file, file: carorder.credit_acept_file, remove: "-" },
               ],
          };

          {
               if (carorder.pay_choice === "เงินสด") {
                    step.splice(
                         step.findIndex((el) => el.title === "Leasing อนุมัติแล้ว"),
                         1
                    );
               }
               if (carorder.accbonus && carorder.accbonus.length === 0 && carorder.accbuy && carorder.accbuy.length === 0) {
                    step.splice(
                         step.findIndex((el) => el.title === "ติดตั้งอุปกรณ์แล้ว"),
                         1
                    );
               }
          }
          // เช็คสิทธิ์
          let token = getToken_permission_page();
          let per = token ? JSON.parse(token.permission_page) : [];
          let addBtn = per.filter((el) => el.path.includes("/admin/order/car"))[0].edit || false;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: "การจองรถยนต์",
                                   onClick: () => window.location.assign("/admin/order/car"),
                              },
                              { title: `ใบจองรถยนต์ (${carorder.order_code_id})` },
                         ]}
                         active={1}
                         button={
                              addBtn
                                   ? [
                                          {
                                               icon: "fas fa-pen-square -icon",
                                               name: "แก้ไขข้อมูลทั่วไป",
                                               handleClick: this.handleClick,
                                               disabled:
                                                    getToken().position_id === 25 || getToken().position_id === 11 ? false : this.state.edit_common,
                                          },
                                          {
                                               icon: "fas fa-coins",
                                               color_btn: "#096dd9",
                                               name: "แก้ไขข้อมูลการเงิน",
                                               handleClick: this.handleClickEditMoney,
                                               disabled:
                                                    getToken().position_id === 25 || getToken().position_id === 11
                                                         ? false
                                                         : carorder.acept_status === "ยกเลิกใบจอง"
                                                         ? true
                                                         : this.state.edit_status,
                                          },
                                     ]
                                   : []
                         }
                    />
                    {this.state.loading ? (
                         <Loader />
                    ) : (
                         <div className="content __input-panel">
                              <div className="input-field">
                                   <div className="-body">
                                        <div className="content step-center">
                                             <LabelBar type={"title"} title={"สถานะของใบจอง"} />
                                             {carorder.acept_status === "ยกเลิกใบจอง" ? (
                                                  <Button style={{ background: "red", cursor: "none" }}>ยกเลิกใบจองแล้ว</Button>
                                             ) : (
                                                  <Step step={step} />
                                             )}
                                        </div>
                                   </div>
                              </div>

                              {carorder.acept_status === "ยืนยันข้อมูล" && carorder.car_match_status >= 1 && carorder.makeup === 0 ? (
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content step-center">
                                                  <Button onClick={() => this.setState({ showmodal_makeup: true })}>ส่งติดตั้งอุปกรณ์</Button>
                                             </div>
                                        </div>
                                        <ModalPanel
                                             show={this.state.showmodal_makeup}
                                             title={"ส่งติดตั้งอุปกรณ์"}
                                             type={"input"}
                                             body={this.modalMakeUp()}
                                             onHide={() => this.setState({ showmodal_makeup: false })}
                                        />
                                   </div>
                              ) : null}

                              <Tabs activeKey={key} onSelect={(k) => this.setKey(k)}>
                                   <Tab eventKey="detail" title="ข้อมูลการจองรถ">
                                        {this.state.scroll && carorder?.lvl_margin > 0 && window.scrollTo({ top: 5400, behavior: "smooth" })}
                                        {this.state.scroll_close && window.scrollTo({ top: 7350, behavior: "smooth" })}
                                        <Detail
                                             saveSign={this.saveSign}
                                             clearSign={this.clearSign}
                                             order={carorder}
                                             id={id}
                                             onClick={this.openPopup}
                                             show_popup={show_popup}
                                             btn_acept={btn_acept}
                                             show_breadcrumbs={show_breadcrumbs}
                                             onHide={() => this.setState({ show_popup: false })}
                                             closeSellButton={this.onClickCloseSell}
                                             // onClickCancel={this.onClickcancelConfirm}
                                             status_check={this.state.edit_status}
                                             imageViewFormatter={this.imageViewFormatter}
                                             dataFun={dataFun}
                                             btnApprove={this.btnApprove}
                                             loading={this.state.loading}
                                             onClickApprove={this.onClickApprove}
                                             typeLeasing={this.state.typeLeasing}
                                             openModalApp={this.state.openModalApp}
                                             noteModalApprove={this.noteModalApprove}
                                             onHideModal={this.onHideModal}
                                             onClickSubmitApprove={this.onClickSubmitApprove}
                                        />
                                        {carorder?.lvl_margin > 0 && (
                                             <AppMargin
                                                  order={carorder}
                                                  dataFun={dataFun}
                                                  onClick={this.onClickApprove}
                                                  log_app={this.state.log_app_data}
                                                  handleChangeNumber={this.handleChangeNumber}
                                                  loading={this.state.loading}
                                                  noteModalApprove={this.noteModalApprove}
                                                  onClickSubmitApprove={this.onClickSubmitApprove}
                                                  openModalApp={this.state.openModalApp}
                                                  position_id_data={this.state.position_id_data}
                                                  closeSellButton={this.onClickCloseSell}
                                                  btn_acept={btn_acept}
                                                  show_history={show_history}
                                                  saveSign={this.saveSign}
                                                  onHide={() => this.setState({ show_popup: false })}
                                                  show_popup={show_popup}
                                                  openPopup={this.openPopup}
                                                  clearSign={this.clearSign}
                                                  onClickCancel={this.onClickCancelModal}
                                                  onClickConfirm={() => this.setState({ showModalConfirm: true })}
                                                  status_check={this.state.edit_status}
                                                  onHideModal={this.onHideModal}
                                                  role={this.state.role}
                                                  downloadPdf={this.downloadPdf}
                                             />
                                        )}
                                        <ModalPanel
                                             show={this.state.showModalConfirm}
                                             title={"ยกเลิกยืนยันข้อมูลการจองหรือไม่"}
                                             type={"default"}
                                             onHide={() => this.setState({ showModalConfirm: false })}
                                             button={[
                                                  { onClick: () => this.onClickcancelConfirm(), name: "ตกลง", variant: "primary" },
                                                  {
                                                       onClick: () => this.setState({ showModalConfirm: false }),
                                                       name: "ยกเลิก",
                                                       variant: "outline-primary",
                                                  },
                                             ]}
                                        />
                                   </Tab>
                                   <Tab eventKey="workflow" title="การดำเนินการ">
                                        <Workflow data={carorder} />
                                   </Tab>
                                   {/* {(getToken().position_id === 19 || getToken().position_id === 20 || getToken().position_id === 25) && ( */}
                                   {getToken().position_id == 10 ||
                                   getToken().position_id == 11 ||
                                   getToken().position_id == 19 ||
                                   getToken().position_id == 20 ||
                                   getToken().position_id == 25 ? (
                                        <Tab eventKey="document" title="เอกสารการขาย">
                                             {/* <Document /> */}
                                             <div className="input-field __tab-body">
                                                  <div className="-body">
                                                       <div className="content" id="table-content">
                                                            <div className="-padding">
                                                                 <LabelBar type={"title"} title={"เอกสารทั่วไป"} />
                                                                 {/* <div className="-padding"> */}
                                                                 <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                                                                 {/* </div> */}
                                                                 <br />
                                                                 <LabelBar type={"title"} title={"รายละเอียดเงินรับชั่วคราว"} />
                                                                 {/* <div className="-padding"> */}
                                                                 <Table
                                                                      type="document"
                                                                      column={doc.column}
                                                                      data={doc.data_two}
                                                                      keyField={doc.keyField}
                                                                 />
                                                            </div>
                                                       </div>
                                                       <ModalPanel
                                                            show={this.state.modal_acc_turn}
                                                            title={"เลือก Supplier สำหรับอุปกรณ์ตกแต่ง"}
                                                            type={"input"}
                                                            body={this.modalViewSelectSupplier()}
                                                            onHide={() => this.setState({ modal_acc_turn: false })}
                                                            button={[{ onClick: this.openSupPDF, name: "ตกลง", variant: "primary" }]}
                                                       />

                                                       <ModalPanel
                                                            show={showmodal_sup}
                                                            title={"เลือกประเภทใบกำกับภาษี"}
                                                            type={"input"}
                                                            // body={this.modalViewSelectSupplier()}
                                                            body={this.modalSelectSupplier()}
                                                            onHide={this.closeModalSupplier}
                                                            button={[{ onClick: this.openLoadPdfTurn, name: "ตกลง", variant: "primary" }]}
                                                       />
                                                       <ModalPanel
                                                            show={comfinaceModal}
                                                            title={
                                                                 typeModal === "com"
                                                                      ? "พิมพ์เอกสารค่าคอมมิชชั่นไฟแนนซ์"
                                                                      : "พิมพ์เอกสารใบเสร็จคืนเงินป้ายแดง"
                                                            }
                                                            type={"input"}
                                                            body={this.modalComfinace()}
                                                            onHide={() => this.setState({ comfinaceModal: false })}
                                                            button={[]}
                                                            size="xl"
                                                       />
                                                       <ModalPanel
                                                            show={this.state.showModalLbelID}
                                                            title={"ต้องการคืนค่ามัดจำป้ายแดงหรือไม่"}
                                                            type={"default"}
                                                            onHide={() => this.setState({ showModalLbelID: false })}
                                                            button={[
                                                                 { onClick: () => this.updateLabelHis(), name: "ตกลง", variant: "primary" },
                                                                 {
                                                                      onClick: () => this.setState({ showModalLbelID: false }),
                                                                      name: "ยกเลิก",
                                                                      variant: "outline-primary",
                                                                 },
                                                            ]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.showmodal_input}
                                                            title={"กรอกค่าคอมไฟแนนซ์"}
                                                            type={"input"}
                                                            body={this.modalViewInput()}
                                                            onHide={this.closeModalSupplier}
                                                            // button={[{ onClick: this.addCommissionFinance, name: "Submit", variant: "primary" }]}
                                                            button={[]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.showmodal_creditnote}
                                                            type={"input"}
                                                            body={this.modalViewCreditNote()}
                                                            onHide={this.closeModalSupplier}
                                                            button={[
                                                                 { onClick: this.addCreditNote, name: "ตกลง", variant: "primary" },
                                                                 { onClick: () => {}, name: "Reset", variant: "danger" },
                                                            ]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.modal_reduce_regis}
                                                            type={"input"}
                                                            body={this.modalReduceRegis()}
                                                            onHide={() => this.setState({ modal_reduce_regis: false })}
                                                            button={[
                                                                 { onClick: this.addReduceRegis, name: "ตกลง", variant: "primary" },
                                                                 { onClick: () => {}, name: "Reset", variant: "danger" },
                                                            ]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.showmodal_sign}
                                                            title={"Sign"}
                                                            type={"image"}
                                                            image={
                                                                 (sign === 0 && carorder?.order_sign) ||
                                                                 (sign === 1 && carorder?.orp_sign) ||
                                                                 (sign === 2 && carorder?.delivery_sign) ||
                                                                 (sign === 3 && carorder?.reservation_sign)
                                                            }
                                                            onHide={() => this.setState({ showmodal_sign: false })}
                                                       />
                                                       {/* showImage */}
                                                       <ModalPanel
                                                            type={"image"}
                                                            title={"Promotion Set"}
                                                            show={this.state.openModalImg}
                                                            image={ip + this.state.imgPath}
                                                            onHide={() => this.setState({ openModalImg: false })}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.modal_reduce_acc}
                                                            title={"กรอกลดหนี้ค่าอุปกรณ์"}
                                                            type={"input"}
                                                            body={this.modalReduce()}
                                                            onHide={() => this.setState({ modal_reduce_acc: false })}
                                                            button={[{ onClick: this.saveReduce, name: "Submit", variant: "primary" }]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.modal_print}
                                                            title={"พิมพ์เอกสารใบลดหนี้ค่าอุปกรณ์"}
                                                            type={"input"}
                                                            body={this.modalReducePrint()}
                                                            onHide={() => this.setState({ modal_print: false })}
                                                            button={[]}
                                                            size="xl"
                                                       />
                                                  </div>
                                             </div>
                                        </Tab>
                                   ) : null}
                                   <Tab eventKey="file" title="แนบไฟล์">
                                        {/* <File
                                        handleChange={(e) => this.handleChangeFile(e)}
                                        handleUpload={this.handleUpload}
                                        file={file}
                                        filepreview={filepreview}
                                        order={carorder?.file}
                                   /> */}
                                        <div className="input-field __tab-body">
                                             <div className="-body">
                                                  <div className="content">
                                                       <Form.Row>
                                                            <Field
                                                                 type={"file"}
                                                                 title={{
                                                                      name: null,
                                                                      icon: null,
                                                                 }}
                                                                 require={true}
                                                                 md={4}
                                                                 placeholder={filepreview.name === "" ? "ไฟล์.xlsx" : filepreview.name}
                                                                 button={"แนบไฟล์"}
                                                                 style_id={"custom-btn __beside-btn"}
                                                                 handleChange={(e) => this.handleChangeFile(e)}
                                                            />
                                                            <Col xl={2} md={2} className="align-self-center">
                                                                 <Button className="-small" onClick={this.handleUpload} disabled={!file && true}>
                                                                      อัปโหลด
                                                                 </Button>
                                                            </Col>
                                                       </Form.Row>
                                                       <strong className="mt-5">แนบไฟล์</strong>
                                                       <div className="content" id="table-content">
                                                            <div className="-padding">
                                                                 <Table
                                                                      type="document"
                                                                      column={file_table.column}
                                                                      data={file_table.data}
                                                                      keyField={file_table.keyField}
                                                                 />
                                                            </div>
                                                       </div>
                                                       {carorder.credit_acept_file || carorder.credit_ex_file ? (
                                                            <>
                                                                 <strong className="mt-3">ไฟล์ Leasing</strong>
                                                                 <div className="content" id="table-content">
                                                                      <div className="-padding">
                                                                           <Table
                                                                                type="document"
                                                                                column={file_table.column_leasing}
                                                                                data={file_table.data_leasing}
                                                                                keyField={file_table.keyField}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                            </>
                                                       ) : null}
                                                  </div>
                                             </div>
                                        </div>
                                   </Tab>
                                   <Tab eventKey="matching" title="จับคู่รถ">
                                        <Matching order={carorder} />
                                   </Tab>
                                   <Tab eventKey="history" title="ประวัติการแก้ไข">
                                        {!show_history ? <History logs={logs} buttonFormatter={this.buttonFormatter} /> : null}
                                        {show_history ? (
                                             <a>
                                                  <Button style={{ marginTop: 10, marginBottom: 10 }} onClick={this.showButtonBack}>
                                                       กลับ
                                                  </Button>
                                                  <Detail
                                                       saveSign={this.saveSign}
                                                       clearSign={this.clearSign}
                                                       order={log_info}
                                                       id={id}
                                                       onClick={this.openPopup}
                                                       show_popup={show_popup}
                                                       btn_acept={btn_acept}
                                                       show_breadcrumbs={show_breadcrumbs}
                                                       show_history={show_history}
                                                  />
                                             </a>
                                        ) : null}
                                   </Tab>
                                   {(carorder.acept_status === "ใบจองรออนุมัติ" ||
                                        carorder.acept_status === "ใบจองอนุมัติแล้ว" ||
                                        carorder.acept_status === "รับเงินจองแล้ว" ||
                                        carorder.acept_status === "Leasing อนุมัติแล้ว" ||
                                        carorder.acept_status === "ยืนยันข้อมูล") &&
                                        carorder.car_match_status === 0 &&
                                        (getToken().position_id === 10 ||
                                             getToken().position_id === 11 ||
                                             getToken().position_id === 19 ||
                                             getToken().position_id === 25) && (
                                             <Tab eventKey="cancel" title="ยกเลิกใบจอง">
                                                  {/* <Note
                                                  onClick={this.onClickCancelModal}
                                                  handleChange={this.handleChangeTextCancel}
                                                  note={carorder.cancel_order_note}
                                             /> */}
                                                  <div className="input-field __tab-body">
                                                       <div className="-body">
                                                            <div className="content">
                                                                 <>
                                                                      <Field
                                                                           type={"text"}
                                                                           title={"หมายเหตุ"}
                                                                           name={"cancel_order_note"}
                                                                           as={"textarea"}
                                                                           textarea_rows={5}
                                                                           md={12}
                                                                           value={carorder.cancel_order_note}
                                                                           handleChangeText={this.handleChangeTextCancel}
                                                                      />
                                                                      <Col md={{ span: "3", offset: "9" }}>
                                                                           {this.state.loading_makeup ? (
                                                                                <Button
                                                                                     variant="outline-primary"
                                                                                     disabled
                                                                                     style={{ backgroundColor: "#fff" }}
                                                                                >
                                                                                     <Spinner
                                                                                          as="span"
                                                                                          animation="grow"
                                                                                          size="sm"
                                                                                          role="status"
                                                                                          aria-hidden="true"
                                                                                     />
                                                                                     Loading...
                                                                                </Button>
                                                                           ) : (
                                                                                <Button onClick={this.onClickCancelModal} variant={"danger"}>
                                                                                     ยกเลิกใบจอง
                                                                                </Button>
                                                                           )}
                                                                      </Col>
                                                                 </>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <ModalPanel
                                                       show={this.state.showModalCancel}
                                                       title={"ต้องการยกเลิกใบจองหรือไม่"}
                                                       type={"cancel_order"}
                                                       onHide={() => this.onClickCancelModal()}
                                                       button={[
                                                            { onClick: () => this.onClickCancelOrder(), name: "ตกลง", variant: "primary" },
                                                            { onClick: () => this.onClickCancelModal(), name: "ยกเลิก", variant: "outline-primary" },
                                                       ]}
                                                       onChangeText={this.handleChangeTextCancel}
                                                       id={this.state.cancel_order}
                                                  />
                                             </Tab>
                                        )}
                                   {(carorder.acept_status === "ชำระเงินแล้ว" ||
                                        carorder.acept_status === "ส่งมอบรถแล้ว" ||
                                        carorder.acept_status === "ปิดการขาย") &&
                                        (getToken().position_id === 11 || getToken().position_id === 25) && (
                                             <Tab eventKey="cancel" title="ยกเลิกใบจอง">
                                                  {/* <Note
                                                  onClick={this.onClickCancelModal}
                                                  handleChange={this.handleChangeTextCancel}
                                                  note={carorder.cancel_order_note}
                                             /> */}
                                                  <div className="input-field __tab-body">
                                                       <div className="-body">
                                                            <div className="content">
                                                                 <>
                                                                      <Field
                                                                           type={"text"}
                                                                           title={"หมายเหตุ"}
                                                                           name={"cancel_order_note"}
                                                                           as={"textarea"}
                                                                           textarea_rows={5}
                                                                           md={12}
                                                                           value={carorder.cancel_order_note}
                                                                           handleChangeText={this.handleChangeTextCancel}
                                                                      />
                                                                      <Col md={{ span: "3", offset: "9" }}>
                                                                           {this.state.loading_makeup ? (
                                                                                <Button
                                                                                     variant="outline-primary"
                                                                                     disabled
                                                                                     style={{ backgroundColor: "#fff" }}
                                                                                >
                                                                                     <Spinner
                                                                                          as="span"
                                                                                          animation="grow"
                                                                                          size="sm"
                                                                                          role="status"
                                                                                          aria-hidden="true"
                                                                                     />
                                                                                     Loading...
                                                                                </Button>
                                                                           ) : (
                                                                                <Button onClick={this.onClickCancelModal} variant={"danger"}>
                                                                                     ยกเลิกใบจอง
                                                                                </Button>
                                                                           )}
                                                                      </Col>
                                                                 </>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <ModalPanel
                                                       show={this.state.showModalCancel}
                                                       title={"ต้องการยกเลิกใบจองหรือไม่"}
                                                       type={"cancel_order"}
                                                       onHide={() => this.onClickCancelModal()}
                                                       button={[
                                                            { onClick: () => this.onClickCancelOrder(), name: "ตกลง", variant: "primary" },
                                                            { onClick: () => this.onClickCancelModal(), name: "ยกเลิก", variant: "outline-primary" },
                                                       ]}
                                                       onChangeText={this.handleChangeTextCancel}
                                                       id={this.state.cancel_order}
                                                  />
                                             </Tab>
                                        )}
                                   {/* {(carorder.acept_status === "ใบจองรออนุมัติ" || carorder.acept_status === "ใบจองอนุมัติแล้ว") && (
                                   <Tab eventKey="cancel" title="ยกเลิกใบจอง">
                                        <Note
                                             onClick={this.onClickCancelModal}
                                             handleChange={this.handleChangeTextCancel}
                                             note={carorder.cancel_order_note}
                                        />
                                        <ModalPanel
                                             show={this.state.showModalCancel}
                                             title={"ต้องการยกเลิกใบจองหรือไม่"}
                                             type={"default"}
                                             onHide={() => this.onClickCancelModal()}
                                             button={[
                                                  { onClick: () => this.onClickCancelOrder(), name: "ตกลง", variant: "primary" },
                                                  { onClick: () => this.onClickCancelModal(), name: "ยกเลิก", variant: "outline-primary" },
                                             ]}
                                        />
                                   </Tab>
                              )} */}
                              </Tabs>
                              {show_breadcrumbs && <Breadcrumbs under={true} type={"order"} />}
                              {modal_skb && (
                                   <ModalPanel
                                        save={this.saveSignSkb}
                                        onHide={() => this.setState({ modal_skb: false })}
                                        clear={this.clearSignSkb}
                                        show={modal_skb}
                                        type={"sign"}
                                        title={"สัญญาจองรถยนต์ (สคบ.)"}
                                        sub_title={"ลายเซ็นผู้ยืนยัน"}
                                   />
                              )}
                         </div>
                    )}
               </div>
          );
     }
}
