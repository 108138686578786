import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Img } from "components/index";
import { Form, Button, Col, Row, Tabs, Tab, Image } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import { POST, GET } from "api/index";

function dateFormatter(cell, row, rowIndex, formatExtraData) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

const Ls = ({ item, oldItems, handleApprove, mockAdditem }) => {
     const ls = {
          column: [
               {
                    dataField: "leasing_type",
                    text: "ประเภท Leasing",
                    headerClasses: "header-custom __left ",
               },
               {
                    dataField: "leasing_interest",
                    text: "อัตราดอกเบี้ย",
                    headerClasses: "header-custom __right",
               },
          ],
          keyField: "leasing_interest",
     };
     return (
          <div className="input-field">
               <div className="-header">
                    <div>ข้อมูลปัจจุบัน</div>
               </div>
               <div className="-body">
                    <div className="content">
                         <>
                              <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "User Create : ",
                                             detail: oldItems?.nameuser_create || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "User Update ล่าสุด :",
                                             detail: oldItems?.nameuser_update || "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ Create : ",
                                             detail: dateFormatter(oldItems?.created_at) || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ Update ล่าสุด : ",
                                             detail: dateFormatter(oldItems?.update_at) || "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "การอนุมัติ : ",
                                             detail: oldItems?.acept_status || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ประเภท Item : ",
                                             detail: "Leasing",
                                        }}
                                        md={6}
                                   />
                              </Row>
                         </>
                         <>
                              <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Code : ",
                                             detail: oldItems?.items_code || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Name : ",
                                             detail: oldItems?.items_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Supplier : ",
                                             detail: oldItems?.supplier_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "สถานะ : ",
                                             detail: oldItems?.status || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Account Code : ",
                                             detail: oldItems?.items_account || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "รหัสภาษีซื้อ : ",
                                             detail: oldItems?.items_taxbuy || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "รหัสภาษีหักณ ที่จ่าย : ",
                                             detail: oldItems?.items_taxpay || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "หมายเลขโทรศัพท์ตัวแทน : ",
                                             detail: oldItems?.items_phone || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "E-Mail : ",
                                             detail: oldItems?.items_mail || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ID LINE : ",
                                             detail: oldItems?.items_line || "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </>
                         <>
                              <LabelBar type={"title"} title={"ประเภท Leasing"} />
                              <Table type={"custom"} keyField={ls.keyField} column={ls.column} data={oldItems.addTypeItems} />
                         </>
                    </div>
               </div>

               {item?.log_do === "แก้ไข" && (
                    <div className="input-field">
                         <div className="-header">
                              <div>ข้อมูลแก้ไข</div>
                         </div>
                         <div className="-body">
                              <div className="content">
                                   <>
                                        <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "User Create : ",
                                                       detail: item?.nameuser_create || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "User Update ล่าสุด :",
                                                       detail: item?.nameuser_update || "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วันที่ Create : ",
                                                       detail: dateFormatter(item?.created_at) || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วันที่ Update ล่าสุด : ",
                                                       detail: dateFormatter(item?.update_at) || "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "การอนุมัติ : ",
                                                       detail: item?.acept_status || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ประเภท Item : ",
                                                       detail: "Leasing",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                   </>
                                   <>
                                        <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Code : ",
                                                       detail: item?.items_code || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Name : ",
                                                       detail: item?.items_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Supplier : ",
                                                       detail: item?.supplier_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "สถานะ : ",
                                                       detail: item?.status || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Account Code : ",
                                                       detail: item?.items_account || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รหัสภาษีซื้อ : ",
                                                       detail: item?.items_taxbuy || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รหัสภาษีหักณ ที่จ่าย : ",
                                                       detail: item?.items_taxpay || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเลขโทรศัพท์ตัวแทน : ",
                                                       detail: item?.items_phone || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "E-Mail : ",
                                                       detail: item?.items_mail || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ID LINE : ",
                                                       detail: item?.items_line || "-",
                                                  }}
                                                  md={12}
                                             />
                                        </Row>
                                   </>
                                   <>
                                        <LabelBar type={"title"} title={"ประเภท Leasing"} />
                                        <Table type={"custom"} keyField={ls.keyField} column={ls.column} data={mockAdditem} />
                                   </>
                              </div>
                         </div>
                    </div>
               )}

               <div className="input-field">
                    <div className="button-group __submit row">
                         <Col xs={12} md={6}>
                              <Button type="submit" onClick={() => handleApprove("อนุมัติ")}>
                                   อนุมัติ
                              </Button>
                         </Col>
                         <Col xs={12} md={6}>
                              <Button variant="danger" onClick={() => handleApprove("ไม่อนุมัติ")}>
                                   ไม่อนุมัติ
                              </Button>
                         </Col>
                    </div>
               </div>
          </div>
     );
};

const Acc = ({ item, oldItems, handleApprove }) => {
     return (
          <div className="input-field">
               <div className="-header">
                    <div>ข้อมูลปัจจุบัน</div>
               </div>
               <div className="-body">
                    <div className="content">
                         <>
                              <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "User Create : ",
                                             detail: oldItems?.nameuser_create || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "User Update ล่าสุด :",
                                             detail: oldItems?.nameuser_update || "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ Create : ",
                                             detail: dateFormatter(oldItems?.created_at) || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ Update ล่าสุด : ",
                                             detail: dateFormatter(oldItems?.update_at) || "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "การอนุมัติ : ",
                                             detail: oldItems?.acept_status || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ประเภท Item : ",
                                             detail: "อุปกรณ์ตกแต่ง",
                                        }}
                                        md={6}
                                   />
                              </Row>
                         </>
                         <>
                              <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Code : ",
                                             detail: oldItems?.items_code || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Name : ",
                                             detail: oldItems?.items_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Name (สคบ.) : ",
                                             detail: oldItems?.name_reserve || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Supplier : ",
                                             detail: oldItems?.supplier_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "สถานะ : ",
                                             detail: oldItems?.status || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Account Code : ",
                                             detail: oldItems?.items_account || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "รหัสภาษีซื้อ : ",
                                             detail: oldItems?.items_taxbuy || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "รหัสภาษีหักณ ที่จ่าย : ",
                                             detail: oldItems?.items_taxpay || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Series : ",
                                             detail: oldItems?.series_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Model : ",
                                             detail: oldItems?.model_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Color : ",
                                             detail: oldItems?.color_name || "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </>
                         <>
                              <LabelBar type={"title"} title={"ราคา"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ต้นทุน :",
                                             detail: item?.price_list
                                                  ? `${Number(item?.price_list.cost).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ต้นทุนรวมภาษีมูลค่าเพิ่ม :",
                                             detail: item?.price_list.cost_vat
                                                  ? `${Number(item?.price_list.cost_vat).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ราคาแถมเก่า :",
                                             detail: item?.price_list.price_add_old
                                                  ? `${Number(item?.price_list.price_add_old).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ราคาแถมใหม่ :",
                                             detail: item?.price_list.price_add_new
                                                  ? `${Number(item?.price_list.price_add_new).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ส่วนต่างราคาแถมกับต้นทุน :",
                                             detail: item?.price_list.difference_add_and_cost
                                                  ? `${Number(item?.price_list.difference_add_and_cost).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "คอมมิชชั่นราคาแถมสำหรับพนักงานขาย :",
                                             detail: item?.price_list.commission_add_sale
                                                  ? `${Number(item?.price_list.commission_add_sale).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "คอมมิชชั่นราคาแถมสำหรับบริษัท :",
                                             detail: item?.price_list.commission_add_company
                                                  ? `${Number(item?.price_list.commission_add_company).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ราคาขาย :",
                                             detail: item?.price_list.price
                                                  ? `${Number(item?.price_list.price).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ส่วนต่างราคาขายกับต้นทุน :",
                                             detail: item?.price_list.difference_price_and_cost
                                                  ? `${Number(item?.price_list.difference_price_and_cost).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "คอมมิชชั่นราคาขายสำหรับพนักงานขาย :",
                                             detail: item?.price_list.commission_price_sale
                                                  ? `${Number(item?.price_list.commission_price_sale).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "คอมมิชชั่นราคาขายสำหรับบริษัท :",
                                             detail: item?.price_list.commission_price_company
                                                  ? `${Number(item?.price_list.commission_price_company).toLocaleString(undefined, {
                                                         maximumFractionDigits: 2,
                                                         minimumFractionDigits: 2,
                                                    })} บาท`
                                                  : "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </>
                    </div>
               </div>

               {item.log_do === "แก้ไข" && (
                    <div className="input-field">
                         <div className="-header">
                              <div>การแก้ไข</div>
                         </div>
                         <div className="-body">
                              <div className="content">
                                   <>
                                        <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "User Create : ",
                                                       detail: item?.nameuser_create || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "User Update ล่าสุด :",
                                                       detail: item?.nameuser_update || "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วันที่ Create : ",
                                                       detail: dateFormatter(item?.created_at) || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วันที่ Update ล่าสุด : ",
                                                       detail: dateFormatter(item?.update_at) || "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "การอนุมัติ : ",
                                                       detail: item?.acept_status || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ประเภท Item : ",
                                                       detail: "อุปกรณ์ตกแต่ง",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                   </>
                                   <>
                                        <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Code : ",
                                                       detail: item?.items_code || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Name : ",
                                                       detail: item?.items_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Name (สคบ.) : ",
                                                       detail: item?.name_reserve || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Supplier : ",
                                                       detail: item?.supplier_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "สถานะ : ",
                                                       detail: item?.status || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Account Code : ",
                                                       detail: item?.items_account || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รหัสภาษีซื้อ : ",
                                                       detail: item?.items_taxbuy || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รหัสภาษีหักณ ที่จ่าย : ",
                                                       detail: item?.items_taxpay || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Series : ",
                                                       detail: item?.series_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Model : ",
                                                       detail: item?.model_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Color : ",
                                                       detail: item?.color_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                        </Row>
                                   </>
                                   <>
                                        <LabelBar type={"title"} title={"ราคา"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ต้นทุน :",
                                                       detail: item?.price_list.cost
                                                            ? `${Number(item?.price_list.cost).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ต้นทุนรวมภาษีมูลค่าเพิ่ม :",
                                                       detail: item?.price_list.cost_vat
                                                            ? `${Number(item?.price_list.cost_vat).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ราคาแถมเก่า :",
                                                       detail: item?.price_list.price_add_old
                                                            ? `${Number(item?.price_list.price_add_old).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ราคาแถมใหม่ :",
                                                       detail: item?.price_list.price_add_new
                                                            ? `${Number(item?.price_list.price_add_new).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ส่วนต่างราคาแถมกับต้นทุน :",
                                                       detail: item?.price_list.difference_add_and_cost
                                                            ? `${Number(item?.price_list.difference_add_and_cost).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "คอมมิชชั่นราคาแถมสำหรับพนักงานขาย :",
                                                       detail: item?.price_list.commission_add_sale
                                                            ? `${Number(item?.price_list.commission_add_sale).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "คอมมิชชั่นราคาแถมสำหรับบริษัท :",
                                                       detail: item?.price_list.commission_add_company
                                                            ? `${Number(item?.price_list.commission_add_company).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ราคาขาย :",
                                                       detail: item?.price_list.price
                                                            ? `${Number(item?.price_list.price).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ส่วนต่างราคาขายกับต้นทุน :",
                                                       detail: item?.price_list.difference_price_and_cost
                                                            ? `${Number(item?.price_list.difference_price_and_cost).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "คอมมิชชั่นราคาขายสำหรับพนักงานขาย :",
                                                       detail: item?.price_list.commission_price_sale
                                                            ? `${Number(item?.price_list.commission_price_sale).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "คอมมิชชั่นราคาขายสำหรับบริษัท :",
                                                       detail: item?.price_list.commission_price_company
                                                            ? `${Number(item?.price_list.commission_price_company).toLocaleString(undefined, {
                                                                   minimumFractionDigits: 2,
                                                                   maximumFractionDigits: 2,
                                                              })} บาท`
                                                            : "-",
                                                  }}
                                                  md={12}
                                             />
                                        </Row>
                                   </>
                              </div>
                         </div>
                    </div>
               )}
               <div className="input-field">
                    <div className="button-group __submit row">
                         <Col xs={12} md={6}>
                              <Button type="submit" onClick={() => handleApprove("อนุมัติ")}>
                                   อนุมัติ
                              </Button>
                         </Col>
                         <Col xs={12} md={6}>
                              <Button variant="danger" onClick={() => handleApprove("ไม่อนุมัติ")}>
                                   ไม่อนุมัติ
                              </Button>
                         </Col>
                    </div>
               </div>
          </div>
     );
};

const Ins = ({ item, oldItems, handleApprove, mockAdditem }) => {
     const ins = {
          column: [
               {
                    dataField: "insurance_type",
                    text: "ประเภทประกัน",
                    headerClasses: "header-custom __left __right",
               },
          ],
          keyField: "no",
     };
     return (
          <div className="input-field">
               <div className="-header">
                    <div>ข้อมูลปัจจุบัน</div>
               </div>
               <div className="-body">
                    <div className="content">
                         <>
                              <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "User Create : ",
                                             detail: oldItems?.nameuser_create || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "User Update ล่าสุด :",
                                             detail: oldItems?.nameuser_update || "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ Create : ",
                                             detail: dateFormatter(oldItems?.created_at) || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "วันที่ Update ล่าสุด : ",
                                             detail: dateFormatter(oldItems?.update_at) || "-",
                                        }}
                                        md={6}
                                   />
                              </Row>
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "การอนุมัติ : ",
                                             detail: oldItems?.acept_status || "-",
                                        }}
                                        md={6}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ประเภท Item : ",
                                             detail: "ประกัน",
                                        }}
                                        md={6}
                                   />
                              </Row>
                         </>
                         <>
                              <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                              <Row>
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Code : ",
                                             detail: oldItems?.items_code || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Item Name : ",
                                             detail: oldItems?.items_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "ตำแหน่ง : ",
                                             detail: oldItems?.items_position || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "Supplier : ",
                                             detail: oldItems?.supplier_name || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "สถานะ : ",
                                             detail: oldItems?.status || "-",
                                        }}
                                        md={12}
                                   />

                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "รหัสภาษีซื้อ : ",
                                             detail: oldItems?.items_taxbuy || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "รหัสภาษีหักณ ที่จ่าย : ",
                                             detail: oldItems?.items_taxpay || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "หมายเลขโทรศัพท์ผู้ติดต่อ : ",
                                             detail: oldItems?.items_phone || "-",
                                        }}
                                        md={12}
                                   />
                                   <LabelBar
                                        type={"textheader"}
                                        title={{
                                             name: "E-Mail : ",
                                             detail: oldItems?.items_mail || "-",
                                        }}
                                        md={12}
                                   />
                              </Row>
                         </>
                         <>
                              <LabelBar type={"title"} title={"ประเภทประกัน"} />
                              <Table type={"custom"} keyField={ins.keyField} column={ins.column} data={oldItems.addTypeItems} />
                         </>
                    </div>
               </div>
               {item?.log_do === "แก้ไข" && (
                    <div className="input-field">
                         <div className="-header">
                              <div>การแก้ไข</div>
                         </div>
                         <div className="-body">
                              <div className="content">
                                   <>
                                        <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "User Create : ",
                                                       detail: item?.nameuser_create || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "User Update ล่าสุด :",
                                                       detail: item?.nameuser_update || "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วันที่ Create : ",
                                                       detail: dateFormatter(item?.created_at) || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "วันที่ Update ล่าสุด : ",
                                                       detail: dateFormatter(item?.update_at) || "-",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "การอนุมัติ : ",
                                                       detail: item?.acept_status || "-",
                                                  }}
                                                  md={6}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ประเภท Item : ",
                                                       detail: "ประกัน",
                                                  }}
                                                  md={6}
                                             />
                                        </Row>
                                   </>
                                   <>
                                        <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                                        <Row>
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Code : ",
                                                       detail: item?.items_code || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Item Name : ",
                                                       detail: item?.items_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "ตำแหน่ง : ",
                                                       detail: item?.items_position || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "Supplier : ",
                                                       detail: item?.supplier_name || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "สถานะ : ",
                                                       detail: item?.status || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รหัสภาษีซื้อ : ",
                                                       detail: item?.items_taxbuy || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "รหัสภาษีหักณ ที่จ่าย : ",
                                                       detail: item?.items_taxpay || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "หมายเลขโทรศัพท์ผู้ติดต่อ : ",
                                                       detail: item?.items_phone || "-",
                                                  }}
                                                  md={12}
                                             />
                                             <LabelBar
                                                  type={"textheader"}
                                                  title={{
                                                       name: "E-Mail : ",
                                                       detail: item?.items_mail || "-",
                                                  }}
                                                  md={12}
                                             />
                                        </Row>
                                   </>
                                   <>
                                        <LabelBar type={"title"} title={"ประเภทประกัน"} />
                                        <Table type={"custom"} keyField={ins.keyField} column={ins.column} data={mockAdditem} />
                                   </>
                              </div>
                         </div>
                    </div>
               )}
               <div className="input-field">
                    <div className="button-group __submit row">
                         <Col xs={12} md={6}>
                              <Button type="submit" onClick={() => handleApprove("อนุมัติ")}>
                                   อนุมัติ
                              </Button>
                         </Col>
                         <Col xs={12} md={6}>
                              <Button variant="danger" onClick={() => handleApprove("ไม่อนุมัติ")}>
                                   ไม่อนุมัติ
                              </Button>
                         </Col>
                    </div>
               </div>
          </div>
     );
};
export default class DetailItemApprove extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               detail_type: "",
               title: [{ title: "รายการ Item", onClick: () => this.props.history.goBack() }],
               item: {},
               oldItems: {},
               mockAdditem: [],
          };
     }

     componentDidMount = () => {
          this.setPath();
     };

     /* ----------------------------- SET -------------------------- */
     setPath = async () => {
          let { id, detail_type, title, mockAdditem } = this.state;

          let items = await POST("/ps/log_detail", { log_id: id });
          let oldItems = await POST("/item/this", { items_id: items.id_do });

          mockAdditem = [];

          if (items.items_type === "Leasing" && items.log_do === "แก้ไข") {
               mockAdditem = items.addTypeItems;
               items.addtype && items.addtype.forEach((el) => mockAdditem.push(el));
               if (items.deleteTypeItem) {
                    items.deleteTypeItem.forEach((el) => {
                         if (this.search(el, mockAdditem)) {
                              mockAdditem.splice(
                                   mockAdditem.findIndex((ele) => ele.items_type_id === el),
                                   1
                              );
                         }
                    });
               }
               items.addTypeItems = mockAdditem;
          } else if (items.items_type === "ประกัน" && items.log_do === "แก้ไข") {
               mockAdditem = items.addTypeItems;
               items.addtype && items.addtype.forEach((el) => mockAdditem.push(el));
               if (items.deleteTypeItem) {
                    items.deleteTypeItem.forEach((el) => {
                         if (this.search(el, mockAdditem)) {
                              mockAdditem.splice(
                                   mockAdditem.findIndex((ele) => ele.items_type_id === el),
                                   1
                              );
                         }
                    });
               }
               items.addTypeItems = mockAdditem;
          }
          if (items.items_type.includes("ประกัน")) {
               detail_type = "ins";
               title.push({ title: "ประกัน" });
          } else if (items.items_type.includes("อุปกรณ์ตกแต่ง (Out Source Supplier)")) {
               detail_type = "acc";
               title.push({ title: "อุปกรณ์ตกแต่ง (Out Source Supplier)" });
          } else if (items.items_type.includes("อุปกรณ์ตกแต่ง (TOYOTA)")) {
               detail_type = "acc";
               title.push({ title: "อุปกรณ์ตกแต่ง (TOYOTA)" });
          } else if (items.items_type.includes("Leasing")) {
               detail_type = "ls";
               title.push({ title: "Leasing" });
          }
          this.setState({
               detail_type,
               title,
               item: { ...items, price_list: items.price_list ? JSON.parse(items.price_list) : {} },
               oldItems: { ...oldItems },
               mockAdditem,
          });
     };

     search = (key, arr) => {
          for (var i = 0; i < arr.length; i++) {
               if (arr[i].items_type_id === key) {
                    return true;
               }
          }
     };
     /* -------------------------- Handle ------------------------- */
     handleClickRemove = () => {
          swal({
               text: "ทำรายการสำเร็จ",
               icon: "success",
          });
     };

     /* Approve insurance */
     handleApprove = async (status) => {
          try {
               let { item, id } = this.state;
               item["model_id"] = item.model_id ? item.model_id : null;
               item["color_id"] = item.color_id ? item.color_id : null;
               await POST("/item/confirm_edit", {
                    ...item,
                    items_id: item.id_do,
                    acept_log_status: status,
                    log_id: id,
                    edit: item.log_do === "เพิ่ม" ? false : true,
               });

               swal({
                    text: "อนุมัติเรียบร้อบแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => {
                    window.location.assign(`/admin/item/approve`);
               });
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     handleClick = () => {
          let { id } = this.state;
          window.location.assign(`/admin/item/detail/edit/${id}`);
     };
     render() {
          let { detail_type, title, item, oldItems, mockAdditem } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs title={title} active={1} button={[]} />
                    <div className="content __input-panel">
                         {detail_type === "ins" && <Ins item={item} oldItems={oldItems} mockAdditem={mockAdditem} handleApprove={this.handleApprove} />}
                         {detail_type === "acc" && <Acc item={item} oldItems={oldItems} mockAdditem={mockAdditem} handleApprove={this.handleApprove} />}
                         {detail_type === "ls" && <Ls item={item} oldItems={oldItems} mockAdditem={mockAdditem} handleApprove={this.handleApprove} />}
                    </div>
               </div>
          );
     }
}
