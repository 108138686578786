import { Breadcrumbs, Search, Skeleton, Table } from "components";
import React, { Component } from "react";
import { GET, POST } from "api";
import { getToken } from "../../../../../static/function";

export default class MatchCar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               order_search: [],
               search: {},
               series: [],
               model: [],
               color: [],
               loading: false,
          };
     }
     componentDidMount() {
          this.getMatchCar();
          this.getSeries();
     }
     getMatchCar = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/car/to_match");
               // console.log("res", res);
               let order_search = res
                    .sort((end, start) => parseFloat(start.ordercount) - parseFloat(end.ordercount))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let order = order_search;
               this.setState({ order, order_search, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     handleChangeText = (e) => {
          let { search } = this.state;
          // console.log("e.target.value", e.target.value);
          search[e.target.name] = e.target.value;
          if (e.target.name === "series_name" && e.target.value === "ทั้งหมด") {
               search.model_name = "";
               search.color_name = "";
               // this.setState({ search });
          }
          if (e.target.name === "model_name" && e.target.value === "ทั้งหมด") {
               search.color_name = "";
               // this.setState({ search });
          }

          if (e.target.name === "series_name") {
               this.getModel(Number(e.target.value));
               search.model_name = "";
          }
          if (e.target.name === "model_name") {
               this.getColors(Number(e.target.value));
               search.color_name = "";
          }
          this.setState({
               search,
          });
     };
     handleClickSearch = () => {
          let { order_search, search } = this.state;
          let newSearch = order_search.filter(
               (el) =>
                    (search.series_name ? String(el.series_id) === String(search.series_name) : el) &&
                    (search.model_name ? String(el.model_id) === String(search.model_name) : el) &&
                    (search.color_name ? String(el.color_id) === String(search.color_name) : el)
          );
          this.setState({ newSearch });
     };
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          // console.log('row', row)
          return <a onClick={() => window.location.assign(`/admin/match/car/${row.series_id}/${row.model_id}/${row.color_id}`)}>ทำรายการ</a>;
     }
     getSeries = async () => {
          try {
               let series = await GET("/car/series");
               this.setState({ series: [...series] });
          } catch (error) {
               console.log(error);
          }
     };

     getModel = async (series_id) => {
          let { car, search } = this.state;
          try {
               let model = await POST("/car/model", { series_id });
               //  console.log("model", model);
               this.setState({ model: model });
          } catch (error) {
               console.log(error);
          }
     };

     getColors = async (model_id) => {
          try {
               let color = await POST("/car/color", { model_id });
               this.setState({ color });
          } catch (error) {
               console.log(error);
          }
     };
     render() {
          let { order, newSearch, search, series, model, color } = this.state;
          const table = {
               column: [
                    {
                         dataField: "match_car",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "series_name",
                         text: "Serie",
                         // headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "carcount",
                         text: "จำนวนในสต๊อก",
                         // formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "ordercount",
                         text: "ใบจองรอจับคู่",
                         // formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "no",
          };
          // let series = order
          //      .filter((el, i) => order.map((ele) => ele.series_name).indexOf(el.series_name) === i)
          //      .map((el) => ({ value: el.series_name, name: el.series_name }));
          series = series.map((el) => ({ value: el.series_id, name: el.series_name }));
          // series.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          // let model = order
          //      .filter((el, i) => order.map((ele) => ele.model_name).indexOf(el.model_name) === i)
          //      .filter((el) =>
          //           search.series_name ? (search.series_name === "ทั้งหมด" ? el : String(el.series_name) === String(search.series_name)) : el
          //      )
          //      .map((el) => ({ value: el.model_name, name: el.model_name }));
          model = model.map((el) => ({ value: el.model_id, name: el.model_name }));
          // model.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          // let color = order
          //      .filter((el, i) => order.map((ele) => ele.color_name).indexOf(el.color_name) === i)
          //      .filter((el) =>
          //           search.model_name ? (search.model_name === "ทั้งหมด" ? el : String(el.model_name) === String(search.model_name)) : el
          //      )
          //      .map((el) => ({ value: el.color_name, name: el.color_name }));
          color = color.map((el) => ({ value: el.color_id, name: el.color_name }));
          // color.unshift({ value: "ทั้งหมด", name: "ทั้งหมด" });
          return (
               <div className="panel">
                    <Breadcrumbs title={[{ title: "จับคู่รถให้ลูกค้า" }]} active={0} button={""} />
                    <div className="content __main-panel matchcar">
                         <Search
                              type={"match-car"}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                              option={{
                                   value: {
                                        series,
                                        model,
                                        color,
                                        series_name: search.series_name,
                                        model_name: search.model_name,
                                        color_name: search.color_name,
                                   },
                              }}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={table.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={table.column} data={table.data} keyField={table.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
