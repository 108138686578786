import React, { Component } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Breadcrumbs, Table, Field, LabelBar } from 'components/index';
import { GET } from 'api/api';

const data = [
  {
    supplier_type: 'Type ประกัน - 001',
    status: 'ใช้งาน',
  },
  {
    supplier_type: 'Type ประกัน - 002',
    status: 'ใช้งาน',
  },
];
const leasing_data = [
  {
    leasing_type: 'Type ประกัน - 001',
    status: 'ใช้งาน',
  },
  {
    leasing_type: 'Type ประกัน - 002',
    status: 'ไม่ได้ใช้งาน',
  },
  {
    leasing_type: 'Type ประกัน - 003',
    status: 'ไม่ได้ใช้งาน',
  },
];

export default class SettingIns extends Component {
  /* Formatter */
  radioCheckFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Form>
        <LabelBar
          type={'radio'}
          name={'status'}
          title={'สถานะ : '}
          label={['ใช้งาน', 'ไม่ได้ใช้งาน']}
          value={cell}
          handleChangeText={function (e) {
            console.log(e.target.value);
          }}
        />
      </Form>
    );
  }
  removeBtnFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <Button className='btn-small' variant='danger'>
        Remove
      </Button>
    );
  }
  render() {
    const column_supplier_type = [
      {
        dataField: 'supplier_type',
        text: '',
        headerClasses: 'header-custom __left',
      },

      {
        dataField: 'status',
        text: '',
        formatter: this.radioCheckFormatter,
      },
      {
        dataField: 'detail',
        text: '',
        formatter: this.removeBtnFormatter,
        headerClasses: 'header-custom __right __btn',
      },
    ];
    const column_leasing_type = [
      {
        dataField: 'leasing_type',
        text: '',
        headerClasses: 'header-custom __left',
      },

      {
        dataField: 'status',
        text: '',
        formatter: this.radioCheckFormatter,
      },
      {
        dataField: 'detail',
        text: '',
        formatter: this.removeBtnFormatter,
        headerClasses: 'header-custom __right __btn',
      },
    ];
    return (
      <div className='panel'>
        <Breadcrumbs title={['ตั้งค่าประกัน']} active={0} button={''} />
        {/* Supplier Type ประกัน */}
        <div className='content __input-panel'>
          <div className='input-field'>
            <div className='-header'>
              <div>Supplier Type ประกัน</div>
            </div>
            <div className='-body'>
              <div className='content'>
                <div>
                  <Field
                    type={'add-text'}
                    title={'เพิ่ม Supplier Type ประกัน'}
                    name={'supplier_type'}
                    placeholder={'กรุณากรอก Supplier Type ประกัน'}
                    md={12}
                    handleChangeText={this.handleChangeText}
                    button={'+ เพิ่ม'}
                  />
                  <Table
                    type={'index-list'}
                    column={column_supplier_type}
                    data={data}
                    keyField={'company'}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ประเภทประกัน */}
          <div className='input-field'>
            <div className='-header'>
              <div>ประเภทประกัน</div>
            </div>
            <div className='-body'>
              <div className='content'>
                <div>
                  <Field
                    type={'add-text'}
                    title={'เพิ่มประเภทประกัน'}
                    name={'supplier_type'}
                    placeholder={'กรุณากรอกประเภทประกัน'}
                    md={12}
                    handleChangeText={this.handleChangeText}
                    button={'+ เพิ่ม'}
                  />
                  <Table
                    type={'index-list'}
                    column={column_leasing_type}
                    data={leasing_data}
                    keyField={'company'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='input-field'>
            <div className='button-group __submit row'>
              <Col md={{ span: 6, offset: 3 }} xs={12}>
                <Button type='submit'>บันทึกการแก้ไข</Button>
              </Col>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
