import React, { Component } from "react";
import { Table, Sign, Field, LabelBar } from "components/index";
import { Modal, Button, Col, Row, Image } from "react-bootstrap";
import IcCorrect from "../../assets/images/001-correct.png";

export default class ModalPanel extends Component {
     render() {
          let {
               type,
               title,
               sub_title,
               show,
               table_data,
               button,
               type_sign,
               onClick,
               save,
               clear,
               img,
               link,
               md,
               onHide,
               total,
               unit,
               body,
               id,
               image,
               btn_xs,
               onChangeText,
               nameText,
               view,
               error_data,
               size,
          } = this.props;
          // console.log(id)
          let cancel_select = [
               { id: 1, label: "ไม่มีรถส่งมอบ" },
               { id: 2, label: "ไม่ผ่านไฟแนนซ์" },
               { id: 3, label: "ไม่พร้อมเรื่องเงิน" },
               { id: 4, label: "คีย์เกิน90วัน" },
               { id: 5, label: "ลูกค้ายังไม่พร้อม" },
          ];
          switch (type) {
               case "select":
                    return (
                         <Modal show={show} size="lg" centered onHide={onHide} id={id}>
                              <Modal.Header closeButton={false}>
                                   <Modal.Title>
                                        <strong>{title}</strong>
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <Table
                                        id={id}
                                        type={view ? "custom-fixHead-modal" : "custom-fixHead"}
                                        column={table_data.column}
                                        data={table_data.data}
                                        keyField={table_data.keyField}
                                   />
                              </Modal.Body>
                              <Modal.Footer>
                                   <div className={view ? "button-group __submit row __modal-btn-select" : "button-group __submit row"}>
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={6}>
                                                  <Button onClick={btn.onClick} variant={btn.variant} id={btn.id}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Footer>
                         </Modal>
                    );
               case "select_search":
                    return (
                         <Modal show={show} size="xl" centered onHide={onHide} id={id}>
                              <Modal.Header closeButton={false}>
                                   <Modal.Title>
                                        <strong>{title}</strong>
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <Field
                                        type={"text"}
                                        title={"ค้นหาอุปกรณ์"}
                                        name={nameText}
                                        require={false}
                                        md={6}
                                        handleChangeText={onChangeText}
                                   />
                                   <Table
                                        id={id}
                                        type={"custom-fixHead"}
                                        column={table_data.column}
                                        data={table_data.data}
                                        keyField={table_data.keyField}
                                   />
                              </Modal.Body>
                              <Modal.Footer>
                                   <div className="button-group __submit row">
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={6}>
                                                  <Button onClick={btn.onClick} variant={btn.variant} id={btn.id}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Footer>
                         </Modal>
                    );
               case "sign":
                    return (
                         <Modal show={show} size="lg" onHide={onHide} centered>
                              <Modal.Header closeButton={false}>
                                   <Modal.Title>
                                        <strong>{title}</strong>
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <div style={{ textAlign: "center" }}>
                                        {sub_title}
                                        <div>
                                             <Sign save={save} clear={clear} onClick={onClick} type={"default"} color={"black"} />
                                        </div>
                                   </div>
                              </Modal.Body>
                         </Modal>
                    );
               case "default":
                    return (
                         <Modal show={show} size="md" centered onHide={onHide}>
                              <Modal.Header closeButton={false}>
                                   <Modal.Title>
                                        <strong>{title}</strong>
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <div className="button-group __submit row">
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={6}>
                                                  <Button onClick={btn.onClick} variant={btn.variant}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Body>
                         </Modal>
                    );
               case "icon":
                    return (
                         <Modal show={show} size="md" centered onHide={onHide}>
                              <Modal.Header closeButton={false}>
                                   {/* icon */}
                                   <Modal.Title>
                                        {img ? (
                                             <div>
                                                  <Row>
                                                       <Col xs={12} md={12} style={{ textAlign: "center" }}>
                                                            <Image src={IcCorrect} width="150px" height="150px" roundedCircle />
                                                       </Col>
                                                  </Row>
                                                  <Row>
                                                       <Col xs={12} md={12} style={{ textAlign: "center" }}>
                                                            <strong>{title}</strong>
                                                       </Col>
                                                  </Row>
                                                  {link ? (
                                                       <Row>
                                                            <Col xs={12} md={12} style={{ textAlign: "center", fontSize: "16px" }}>
                                                                 <a
                                                                      style={{ textDecoration: "underline", color: "blue" }}
                                                                      onClick={() => {
                                                                           window.location.assign(`${link.to}`);
                                                                      }}
                                                                 >
                                                                      <i className="fas fa-print icon __btn active" />
                                                                      {link.name}
                                                                 </a>
                                                            </Col>
                                                       </Row>
                                                  ) : (
                                                       <Row>
                                                            <Col xs={12} md={12} style={{ textAlign: "center", fontSize: "16px" }}>
                                                                 <span>{sub_title}</span>
                                                            </Col>
                                                       </Row>
                                                  )}
                                             </div>
                                        ) : (
                                             <div>
                                                  <strong>{title}</strong>
                                             </div>
                                        )}
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <div className="button-group __submit_modal row">
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={12} md={md}>
                                                  <Button onClick={btn.onClick} variant={btn.variant}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Body>
                         </Modal>
                    );
               case "input":
                    return (
                         <Modal show={show} size={size ? size : "lg"} centered onHide={onHide}>
                              <Modal.Header closeButton={true}>
                                   <Modal.Title className="text-center">
                                        <strong>{title}</strong>
                                        {error_data && (
                                             <p>
                                                  <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                             </p>
                                        )}
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>{body}</Modal.Body>
                              {button ? (
                                   <Modal.Footer>
                                        <div className="button-group __submit row">
                                             {button.map((btn, i) => (
                                                  <Col key={i} xs={btn_xs ? btn_xs : 6}>
                                                       <Button onClick={btn.onClick} variant={btn.variant}>
                                                            {btn.name}
                                                       </Button>
                                                  </Col>
                                             ))}
                                        </div>
                                   </Modal.Footer>
                              ) : null}
                         </Modal>
                    );
               case "input-check-box":
                    return (
                         <Modal show={show} size="lg" centered onHide={onHide}>
                              <Modal.Header closeButton={true}>
                                   <Modal.Title className="text-center">
                                        <strong>{title}</strong>
                                        {error_data && (
                                             <p>
                                                  <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                             </p>
                                        )}
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>{body}</Modal.Body>
                              <Modal.Footer>
                                   <div className="button-group __submit row">
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={btn_xs ? btn_xs : 6}>
                                                  <Button onClick={btn.onClick} variant={btn.variant}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Footer>
                         </Modal>
                    );
               case "image":
                    return (
                         <Modal show={show} size="lg" centered onHide={onHide}>
                              <Modal.Header closeButton={true}>
                                   <Modal.Title>
                                        <strong>{title}</strong>
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <Row>
                                        <Col style={{ textAlign: "center" }}>
                                             {image ? (
                                                  <div
                                                       style={{
                                                            textAlign: "center",
                                                            border: "1px solid #000",
                                                            borderRadius: "10px",
                                                            margin: "0 20px",
                                                       }}
                                                  >
                                                       <Image src={image} width="100%" height="100%" />
                                                  </div>
                                             ) : (
                                                  <div
                                                       style={{
                                                            textAlign: "center",
                                                            border: "1px solid #000",
                                                            borderRadius: "10px",
                                                            margin: "0 20px",
                                                            color: "gray",
                                                       }}
                                                  >
                                                       File Empty.
                                                  </div>
                                             )}
                                        </Col>
                                   </Row>
                              </Modal.Body>
                              {/* <Modal.Footer>
                                   <div className="button-group __submit row">
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={6}>
                                                  <Button onClick={btn.onClick} variant={btn.variant}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Footer> */}
                         </Modal>
                    );
               case "cancel_order":
                    return (
                         <Modal show={show} size="md" centered onHide={onHide}>
                              <Modal.Header closeButton={false}>
                                   <Modal.Title>
                                        <strong>{title}</strong>
                                   </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                   <div className="text-center mb-3">
                                        <strong>เลือกสาเหตุการยกเลิก</strong>
                                   </div>
                                   <div className="mb-5" style={{ paddingLeft: "155px" }}>
                                        <LabelBar
                                             type={"radio"}
                                             name={"cancel_id"}
                                             title={""}
                                             label={cancel_select.map((el) => el.label)}
                                             value={id.cancel_id}
                                             handleChangeText={onChangeText}
                                        />
                                   </div>
                                   <div className="button-group __submit row">
                                        {button.map((btn, i) => (
                                             <Col key={i} xs={6}>
                                                  <Button onClick={btn.onClick} variant={btn.variant}>
                                                       {btn.name}
                                                  </Button>
                                             </Col>
                                        ))}
                                   </div>
                              </Modal.Body>
                         </Modal>
                    );
               default:
                    return <React.Fragment></React.Fragment>;
          }
     }
}
