import { Breadcrumbs, Field, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { POST } from "api";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";

export default class StockReport extends Component {
     constructor(props) {
          super(props);

          this.state = {
               modal: {
                    title: "",
                    type: 0,
               },
               stock: {},
               show: false,
               error_data: "",
               loading: false,
          };
     }

     /*------------ onClick ------------------ */
     closeReport = async () => {
          this.setState({ loading: true });
          let { stock } = this.state;
          try {
               // console.log("stock", stock);
               if (stock.id === 1 && !stock.date_start) {
                    this.setState({ loading: false });
                    return swal({
                         button: "เสร็จสิ้น",
                         text: "กรุณากรอกวันที่",
                         icon: "warning",
                    });
               }
               if (stock.id === 2 && (!stock.date_start || !stock.date_end)) {
                    this.setState({ loading: false });
                    return swal({
                         button: "เสร็จสิ้น",
                         text: "กรุณากรอกวันที่",
                         icon: "warning",
                    });
               }
               if (stock.id === 4 && !stock.year) {
                    this.setState({ loading: false });
                    return swal({
                         button: "เสร็จสิ้น",
                         text: "กรุณากรอกปี",
                         icon: "warning",
                    });
               }
               if (stock.id === 5 && !stock.month) {
                    this.setState({ loading: false });
                    return swal({
                         button: "เสร็จสิ้น",
                         text: "กรุณากรอกเดือน",
                         icon: "warning",
                    });
               }
               let type_excel = "";
               switch (stock.id) {
                    case 1:
                         type_excel = "รายงานสินค้าคงเหลือทั้งหมด";
                         break;
                    case 2:
                         type_excel = "รายงานสินค้าที่ซื้อเข้า";
                         break;
                    case 3:
                         type_excel = "รายงานการเคลื่อนไหวของสินค้า";
                         break;
                    case 4:
                         type_excel = "รายงานการขายรถยนต์/ซื้อสินค้า/สินค้าคงเหลือรายปี";
                         break;
                    case 5:
                         type_excel = "รายงานRUNDOWN";
                         break;

                    default:
                         break;
               }
               if (stock.branch === "สำนักงานใหญ่ ธัญบุรี คลอง 2") {
                    stock.branch_id = 1;
               } else if (stock.branch === "ลำลูกกา") {
                    stock.branch_id = 2;
               }
               let excel = await POST("/ps/report_excel_stock", { ...stock, type_excel });
               window.open(excel);
               this.setState({ loading: false, show: false, error_data: "" });
          } catch (error) {
               // console.log("error", error);
               this.setState({ loading: false, error_data: error });
          }
     };

     /* ---------- formatter ----------------- */
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <a
                    onClick={() => {
                         // if (row.id === 1) {
                         //      this.setState({ stock: { id: 1 } }, () => this.closeReport());
                         // } else {
                         this.showModal(row.report, row.type, row.id);
                         // }
                    }}
               >
                    ทำรายการ
               </a>
          );
     };
     showModal = (title, type, id) => {
          let { modal, stock } = this.state;

          stock.id = id;

          modal.type = type;
          modal.title = title;
          this.setState({
               ...modal,
               show: !this.state.show,
               stock,
               error_data: "",
          });
     };
     reportFormatter = (cell, row, rowIndex, formatExtraData) => {
          // if (row.report === "รายงาน RUNDOWN") {
          //      return (
          //           <div>
          //                <span>{cell}</span>
          //                <span style={{ color: "red", marginLeft: "40px" }}>ไม่มีรูปแบบรายงาน</span>
          //           </div>
          //      );
          // } else {
          return <span>{cell}</span>;
          // }
     };

     handleChangeDate = (date, dateString, name) => {
          let { stock } = this.state;
          stock[name] = dateString;
          this.setState({ stock });
     };

     handleCloseModal = () => {
          this.setState({
               show: false,
               error: "",
          });
     };

     render() {
          let {
               modal: { type, title },
               show,
               stock,
               error_data,
          } = this.state;
          // console.log(stock);
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         formatter: this.reportFormatter,
                         headerClasses: "header-custom  __btn-l",
                    },
                    {
                         dataField: "list",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    { report: "รายงานสินค้าคงเหลือทั้งหมด", type: 1, id: 1 },
                    { report: "รายงานสินค้าที่ซื้อเข้า", type: 1, id: 2 },
                    // { report: "รายงานการเคลื่อนไหวของสินค้า", type: 1, id: 3 },
                    { report: " รายงานการขายรถยนต์/ซื้อสินค้า/สินค้าคงเหลือรายปี", type: 2, id: 4 },
                    { report: "รายงาน RUNDOWN", type: 1, id: 5 },
               ],
               keyField: "on",
          };
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานสต็อก" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                                   <Modal show={show} centered size="lg" onHide={this.handleCloseModal}>
                                        <Modal.Header>
                                             <Modal.Title className="text-center">
                                                  <strong>{title}</strong>
                                                  {error_data && (
                                                       <p className="text-center">
                                                            <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                                       </p>
                                                  )}
                                             </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                             {stock.id === 1 ? (
                                                  <Row style={{ padding: "0 33%" }}>
                                                       <Field
                                                            type={"date"}
                                                            placeholder={stock.date_start ? stock.date_start : "เลือกวันที่"}
                                                            title={"ณ. วันที่"}
                                                            name={"date_start"}
                                                            md={12}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                  </Row>
                                             ) : stock.id === 2 ? (
                                                  <Row>
                                                       <Field
                                                            type={"date"}
                                                            placeholder={stock.date_start ? stock.date_start : "เลือกวันที่"}
                                                            title={"ประจำวันที่"}
                                                            name={"date_start"}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            placeholder={stock.date_end ? stock.date_end : "เลือกวันที่"}
                                                            title={"ถึงวันที่"}
                                                            name={"date_end"}
                                                            md={6}
                                                            handleSelectDate={this.handleChangeDate}
                                                       />
                                                  </Row>
                                             ) : (
                                                  <Row style={{ padding: "0 33%" }}>
                                                       <Field
                                                            type={"date"}
                                                            placeholder={
                                                                 stock.id === 4
                                                                      ? stock.year
                                                                           ? stock.year
                                                                           : "เลือกปี"
                                                                      : stock.month
                                                                      ? stock.month
                                                                      : "เลือกเดือน"
                                                            }
                                                            title={stock.id === 4 ? "เลือกปีที่ต้องการ" : "เลือกเดือนที่ต้องการ"}
                                                            picker={stock.id === 4 ? "year" : "month"}
                                                            md={12}
                                                            name={stock.id === 4 ? "year" : "month"}
                                                            handleSelectDate={this.handleChangeDate}
                                                            disDate={stock.id === 4}
                                                       />
                                                  </Row>
                                             )}

                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       {this.state.loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button onClick={this.closeReport}>แสดงรายงาน</Button>
                                                       )}
                                                  </Col>
                                             </Row>
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
