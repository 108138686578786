import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Img } from "components/index";
import { Form, Button, Col, Row, Tabs, Tab, Image } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import { POST, ip } from "api/index";

function dateFormatter(cell, row, rowIndex, formatExtraData) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

const Ls = ({ item, detailFormatter, previewInfo, setOldInfo, logs }) => {
     const ls = {
          column: [
               {
                    dataField: "leasing_type",
                    text: "ประเภท Leasing",
                    headerClasses: "header-custom __left ",
               },
               {
                    dataField: "leasing_interest",
                    text: "อัตราดอกเบี้ย",
                    headerClasses: "header-custom __right",
               },
          ],
          data: item.addTypeItems,
          keyField: "leasing_interest",
     };
     const history = {
          column: [
               {
                    dataField: "log_do",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-s",
               },
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: dateFormatter,
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "log_status",
                    text: "สถานะ",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "nameuser_update",
                    text: "ผู้ดำเนินการ",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "detail",
                    text: "ดูการแก้ไข",
                    formatter: detailFormatter,
                    headerClasses: "header-custom __right __btn-s",
               },
          ],
          data: logs,
          keyField: "updated_at",
     };
     return (
          <div className="content">
               <>
                    <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                    {previewInfo === true && <Button onClick={setOldInfo}>ดูข้อมูลเดิม</Button>}
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "User Create : ",
                                   detail: item?.nameuser_create ? item?.nameuser_create : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "User Update ล่าสุด :",
                                   detail: item?.nameuser_update ? item?.nameuser_update : "-",
                              }}
                              md={6}
                         />
                    </Row>
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "วันที่ Create : ",
                                   detail: item?.created_at ? dateFormatter(item?.created_at) : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "วันที่ Update ล่าสุด : ",
                                   detail: item?.update_at ? dateFormatter(item?.update_at) : "-",
                              }}
                              md={6}
                         />
                    </Row>
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "การอนุมัติ : ",
                                   detail: item?.acept_status ? item?.acept_status : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ประเภท Item : ",
                                   detail: "Leasing",
                              }}
                              md={6}
                         />
                    </Row>
               </>
               <>
                    <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Code : ",
                                   detail: item?.items_code ? item?.items_code : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Name : ",
                                   detail: item?.items_name ? item?.items_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Supplier : ",
                                   detail: item?.supplier_name ? item?.supplier_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "สถานะ : ",
                                   detail: item?.status ? item?.status : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Account Code : ",
                                   detail: item?.items_account ? item?.items_account : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "รหัสภาษีซื้อ : ",
                                   detail: item?.items_taxbuy ? item?.items_taxbuy : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "รหัสภาษีหักณ ที่จ่าย : ",
                                   detail: item?.items_taxpay ? item?.items_taxpay : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "หมายเลขโทรศัพท์ตัวแทน : ",
                                   detail: item?.items_phone ? item?.items_phone : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "E-Mail : ",
                                   detail: item?.items_mail ? item?.items_mail : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ID-LINE : ",
                                   detail: item?.items_line ? item?.items_line : "-",
                              }}
                              md={12}
                         />
                    </Row>
               </>
               <>
                    <LabelBar type={"title"} title={"ประเภท Leasing"} />
                    <Table type={"custom"} keyField={ls.keyField} column={ls.column} data={ls.data} />
               </>
               <>
                    <LabelBar type={"title"} title={"ประวัติการแก้ไข"} />
                    <div className="content" id="table-content">
                         <Table type={"custom"} keyField={history.keyField} column={history.column} data={history.data} />
                    </div>
               </>
          </div>
     );
};

const Acc = ({ item, logs, detailFormatter, previewInfo, setOldInfo }) => {
     const history = {
          column: [
               {
                    dataField: "log_do",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-s",
               },
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: dateFormatter,
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "log_status",
                    text: "สถานะ",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "nameuser_update",
                    text: "ผู้ดำเนินการ",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "detail",
                    text: "ดูการแก้ไข",
                    formatter: detailFormatter,
                    headerClasses: "header-custom __right __btn-s",
               },
          ],
          data: logs,
          keyField: "updated_at",
     };
     return (
          <div className="content">
               <>
                    <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                    {previewInfo === true && <Button onClick={setOldInfo}>ดูข้อมูลเดิม</Button>}
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "User Create : ",
                                   detail: item?.nameuser_create ? item?.nameuser_create : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "User Update ล่าสุด :",
                                   detail: item?.nameuser_update ? item?.nameuser_update : "-",
                              }}
                              md={6}
                         />
                    </Row>
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "วันที่ Create : ",
                                   detail: item?.created_at ? dateFormatter(item?.created_at) : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "วันที่ Update ล่าสุด : ",
                                   detail: item?.update_at ? dateFormatter(item?.update_at) : "-",
                              }}
                              md={6}
                         />
                    </Row>
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "การอนุมัติ : ",
                                   detail: item?.acept_status ? item?.acept_status : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ประเภท Item : ",
                                   detail: item?.items_type ? item?.items_type : "-",
                              }}
                              md={6}
                         />
                    </Row>
               </>
               <>
                    <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Code : ",
                                   detail: item?.items_code ? item?.items_code : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Name : ",
                                   detail: item?.items_name ? item?.items_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Name (สคบ.) : ",
                                   detail: item?.name_reserve ? item?.name_reserve : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Supplier : ",
                                   detail: item?.supplier_name ? item?.supplier_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "สถานะ : ",
                                   detail: item?.status ? item?.status : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Account Code : ",
                                   detail: item?.items_account ? item?.items_account : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "รหัสภาษีซื้อ : ",
                                   detail: item?.items_taxbuy ? item?.items_taxbuy : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "รหัสภาษีหักณ ที่จ่าย : ",
                                   detail: item?.items_taxpay ? item?.items_taxpay : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Series : ",
                                   detail: item?.series_name ? item?.series_name + ` ( ${item?.series_code} )` : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Model : ",
                                   detail: item?.model_name ? item?.model_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Color : ",
                                   detail: item?.color_name ? item?.color_name : "-",
                              }}
                              md={12}
                         />
                    </Row>
                    {item.acc_image && item.acc_image && (
                         <>
                              <LabelBar type={"title"} title={"รูปภาพอุปกรณ์ตกแต่ง"} />
                              <Row>
                                   <Image
                                        width={300}
                                        height={300}
                                        style={{ marginTop: 10, marginBottom: 30, marginLeft: 20 }}
                                        src={ip + item.acc_image + `?time=${new Date()}`}
                                   />
                              </Row>
                         </>
                    )}
               </>
               <>
                    <LabelBar type={"title"} title={"ราคา"} />
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ต้นทุน :",
                                   detail: item?.price_list
                                        ? `${Number(item?.price_list.cost).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ต้นทุนรวมภาษีมูลค่าเพิ่ม :",
                                   detail: item?.price_list.cost_vat
                                        ? `${Number(item?.price_list.cost_vat).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ราคาแถมเก่า :",
                                   detail: item?.price_list.price_add_old
                                        ? `${Number(item?.price_list.price_add_old).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ราคาแถมใหม่ :",
                                   detail: item?.price_list.price_add_new
                                        ? `${Number(item?.price_list.price_add_new).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ส่วนต่างราคาแถมกับต้นทุน :",
                                   detail: item?.price_list.difference_add_and_cost
                                        ? `${Number(item?.price_list.difference_add_and_cost).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "คอมมิชชั่นราคาแถมสำหรับพนักงานขาย :",
                                   detail: item?.price_list.commission_add_sale
                                        ? `${Number(item?.price_list.commission_add_sale).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "คอมมิชชั่นราคาแถมสำหรับบริษัท :",
                                   detail: item?.price_list.commission_add_company
                                        ? `${Number(item?.price_list.commission_add_company).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ราคาขาย :",
                                   detail: item?.price_list.price
                                        ? `${Number(item?.price_list.price).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ส่วนต่างราคาขายกับต้นทุน :",
                                   detail: item?.price_list.difference_price_and_cost
                                        ? `${Number(item?.price_list.difference_price_and_cost).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "คอมมิชชั่นราคาขายสำหรับพนักงานขาย :",
                                   detail: item?.price_list.commission_price_sale
                                        ? `${Number(item?.price_list.commission_price_sale).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "คอมมิชชั่นราคาขายสำหรับบริษัท :",
                                   detail: item?.price_list.commission_price_company
                                        ? `${Number(item?.price_list.commission_price_company).toLocaleString(undefined, {
                                               maximumFractionDigits: 2,
                                               minimumFractionDigits: 2,
                                          })} บาท`
                                        : "-",
                              }}
                              md={12}
                         />
                    </Row>
               </>
               <>
                    <LabelBar type={"title"} title={"ประวัติการแก้ไข"} />
                    <div className="content" id="table-content">
                         <Table type={"custom"} keyField={history.keyField} column={history.column} data={history.data} />
                    </div>
               </>
          </div>
     );
};

const Ins = ({ item, detailFormatter, previewInfo, setOldInfo, logs }) => {
     const ins = {
          column: [
               {
                    dataField: "insurance_type",
                    text: "ประเภทประกัน",
                    headerClasses: "header-custom __left __right",
               },
          ],
          keyField: "no",
     };
     const history = {
          column: [
               {
                    dataField: "log_do",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-s",
               },
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: dateFormatter,
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "log_status",
                    text: "สถานะ",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "nameuser_update",
                    text: "ผู้ดำเนินการ",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "detail",
                    text: "ดูการแก้ไข",
                    formatter: detailFormatter,
                    headerClasses: "header-custom __right __btn-s",
               },
          ],
          data: logs,
          keyField: "updated_at",
     };
     return (
          <div className="content">
               <>
                    <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                    {previewInfo === true && <Button onClick={setOldInfo}>ดูข้อมูลเดิม</Button>}
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "User Create : ",
                                   detail: item?.nameuser_create ? item?.nameuser_create : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "User Update ล่าสุด :",
                                   detail: item?.nameuser_update ? item?.nameuser_update : "-",
                              }}
                              md={6}
                         />
                    </Row>
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "วันที่ Create : ",
                                   detail: item?.created_at ? dateFormatter(item?.created_at) : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "วันที่ Update ล่าสุด : ",
                                   detail: item?.update_at ? dateFormatter(item?.update_at) : "-",
                              }}
                              md={6}
                         />
                    </Row>
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "การอนุมัติ : ",
                                   detail: item?.acept_status ? item?.acept_status : "-",
                              }}
                              md={6}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ประเภท Item : ",
                                   detail: "ประกัน",
                              }}
                              md={6}
                         />
                    </Row>
               </>
               <>
                    <LabelBar type={"title"} title={"ข้อมูลอุปกรณ์ตกแต่ง"} />
                    <Row>
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Code : ",
                                   detail: item?.items_code ? item?.items_code : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Item Name : ",
                                   detail: item?.items_name ? item?.items_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Supplier : ",
                                   detail: item?.supplier_name ? item?.supplier_name : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "สถานะ : ",
                                   detail: item?.status ? item?.status : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "Account Code : ",
                                   detail: item?.items_account ? item?.items_account : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "ตำแหน่ง : ",
                                   detail: item?.items_position ? item?.items_position : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "รหัสภาษีซื้อ : ",
                                   detail: item?.items_taxbuy ? item?.items_taxbuy : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "รหัสภาษีหักณ ที่จ่าย : ",
                                   detail: item?.items_taxpay ? item?.items_taxpay : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "หมายเลขโทรศัพท์ตัวแทน : ",
                                   detail: item?.items_phone ? item?.items_phone : "-",
                              }}
                              md={12}
                         />
                         <LabelBar
                              type={"textheader"}
                              title={{
                                   name: "E-Mail : ",
                                   detail: item?.items_mail ? item?.items_mail : "-",
                              }}
                              md={12}
                         />
                    </Row>
               </>
               <>
                    <LabelBar type={"title"} title={"ประเภทประกัน"} />
                    <Table type={"custom"} keyField={ins.keyField} column={ins.column} data={item.addTypeItems} />
               </>
               <>
                    <LabelBar type={"title"} title={"ประวัติการแก้ไข"} />
                    <div className="content" id="table-content">
                         <Table type={"custom"} keyField={history.keyField} column={history.column} data={history.data} />
                    </div>
               </>
          </div>
     );
};

export default class DetailItem extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               detail_type: "",
               title: [{ title: "รายการ Item", onClick: () => window.location.assign("/admin/item") }],
               item: {},
               oldItem: {},
               logs: [],
               preview: [],
               previewInfo: false,
          };
     }

     componentDidMount = () => {
          this.setPath();
          this.getItem();
          this.getLog();
     };

     /* ----------------------------- SET -------------------------- */
     setPath = () => {
          let { id, detail_type, title } = this.state;
          if (id.includes("ประกัน")) {
               detail_type = "ins";
               title.push({ title: "ประกัน" });
          } else if (id.includes("อุปกรณ์ตกแต่ง")) {
               detail_type = "acc";
               title.push({ title: "อุปกรณ์ตกแต่ง" });
          } else if (id.includes("Leasing")) {
               detail_type = "ls";
               title.push({ title: "Leasing" });
          }
          this.setState({
               detail_type,
               title,
          });
     };

     getItem = async () => {
          try {
               let result = await POST("/item/this", { items_id: this.state.id });

               let { detail_type, title } = this.state;
               if (result.items_type.includes("ประกัน")) {
                    detail_type = "ins";
                    title.push({ title: "ประกัน" });
               } else if (result.items_type.includes("อุปกรณ์ตกแต่ง (Out Source Supplier)")) {
                    detail_type = "acc";
                    title.push({ title: "อุปกรณ์ตกแต่ง (Out Source Supplier)" });
               } else if (result.items_type.includes("อุปกรณ์ตกแต่ง (TOYOTA)")) {
                    detail_type = "acc";
                    title.push({ title: "อุปกรณ์ตกแต่ง (TOYOTA)" });
               } else if (result.items_type.includes("อุปกรณ์ตกแต่ง")) {
                    detail_type = "acc";
                    title.push({ title: "อุปกรณ์ตกแต่ง" });
               } else if (result.items_type.includes("Leasing")) {
                    detail_type = "ls";
                    title.push({ title: "Leasing" });
               }
               this.setState({
                    item: { ...result },
                    oldItem: { ...result },
                    detail_type,
                    title,
               });
          } catch (error) {
               console.log(error);
          }
     };

     getLog = async () => {
          try {
               let logs = await POST("/ps/this_log", { id_do: this.state.id, log_type: "item" });
               this.setState({ logs });
          } catch (error) {
               console.log(error);
          }
     };
     search = (key, arr) => {
          for (var i = 0; i < arr.length; i++) {
               if (arr[i].insurance_id === key) {
                    return true;
               }
               if (arr[i].leasinge_id === key) {
                    return true;
               }
               if (arr[i].items_type_id === key) {
                    return true;
               }
          }
     };

     previewEdit = (row) => {
          let { item, oldItem, preview } = this.state;
          item = { ...row };
          item.nameuser_create = oldItem.nameuser_create;
          preview = item.addTypeItems;
          let log = item.addtype;
          let logDelete = item.deleteTypeItem;

          if (logDelete != undefined) {
               item.deleteTypeItem.forEach((el) => {
                    if (this.search(el, preview)) {
                         preview.splice(
                              preview.findIndex((ele) => ele.items_type_id === el),
                              1
                         );
                    }
               });
          }
          if (log != undefined) {
               item.addtype.forEach((el) => {
                    if (!this.search(el.insurance_id, preview)) {
                         preview.push(el);
                    }
                    if (!this.search(el.leasinge_id, preview)) {
                         preview.push(el);
                    }
               });
          }
          item.addTypeItems = preview;
          this.setState({ previewInfo: true, item: { ...item, price_list: JSON.parse(item.price_list) }, preview: [] });
     };
     /* -------------------------- Handle ------------------------- */
     handleClickRemove = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         await POST("/item/delete", { delete: 1, items_id: this.state.id });
                         swal({
                              text: "ลบเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              window.location.assign(`/admin/item`);
                         });
                    }
               });
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     handleClick = () => {
          let { id } = this.state;
          window.location.assign(`/admin/item/detail/edit/${id}`);
     };

     /* Formatter */
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return row.log_do === "แก้ไข" ? <Button onClick={() => this.previewEdit(row)}>ดูการแก้ไข</Button> : <></>;
     };

     setOldInfo = () => {
          console.log("old");
          this.setState({
               item: { ...this.state.oldItem },
               previewInfo: false,
          });
     };

     render() {
          let { detail_type, title, item } = this.state;

          return (
               <div className="panel">
                    <Breadcrumbs
                         title={title}
                         active={1}
                         button={[
                              {
                                   name: "แก้ไขข้อมูล",
                                   icon: "fas fa-pen-square",
                                   handleClick: this.handleClick,
                                   variant: "primary",
                              },
                              {
                                   name: "ลบข้อมูล",
                                   icon: "fas fa-trash alt",
                                   handleClick: this.handleClickRemove,
                                   variant: "danger",
                              },
                         ]}
                    />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   {detail_type === "ins" && (
                                        <Ins
                                             item={item}
                                             logs={this.state.logs}
                                             detailFormatter={this.detailFormatter}
                                             previewInfo={this.state.previewInfo}
                                             setOldInfo={this.setOldInfo}
                                        />
                                   )}
                                   {detail_type === "acc" && (
                                        <Acc
                                             item={item}
                                             logs={this.state.logs}
                                             detailFormatter={this.detailFormatter}
                                             previewInfo={this.state.previewInfo}
                                             setOldInfo={this.setOldInfo}
                                        />
                                   )}
                                   {detail_type === "ls" && (
                                        <Ls
                                             item={item}
                                             logs={this.state.logs}
                                             detailFormatter={this.detailFormatter}
                                             previewInfo={this.state.previewInfo}
                                             setOldInfo={this.setOldInfo}
                                        />
                                   )}
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
