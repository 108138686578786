import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Skeleton } from "../../../../../components/index";
import { GET } from "../../../../../api/api";
import dayjs from "dayjs";
import { getToken } from "../../../../../static/function";

// function printFormatter(cell, row, rowIndex, formatExtraData) {
//   return (
//     <i
//       className='fas fa-print icon'
//       onClick={() => window.location.assign('/admin/print/acc/0')}
//     ></i>
//   );
// }

export default class Acc extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order: [],
               search: {
                    status: "สั่งซื้อ",
               },
               loading: false,
          };
     }
     componentDidMount = () => {
          this.getOrder();
     };

     /* ---------------------------------------------------------------- */
     getOrder = async () => {
          try {
               this.setState({ loading: true });
               let user = getToken();
               let res = await GET("/order/acc_order");
               // console.log('res', res)
               let order = res
                    .sort((end, start) => parseFloat(start.acorder_id) - parseFloat(end.acorder_id))
                    .filter((el) => (Number(user.branch_id) !== 99 ? el.branch_id === Number(user.branch_id) : el));
               // .filter((el) => (user.position_id === 17 ? el.team_id === user.team_id : el));
               let newSearch = order.filter((el) => el.acept_status === "สั่งซื้อ");
               this.setState({ order: [...order], newSearch: newSearch, loading: false });
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };

     handleClick = () => {
          window.location.assign("/admin/order/acc/manage/add");
     };

     handleChangeText = (e) => {
          let { search } = this.state;
          search[e.target.name] = e.target.value;
          this.setState({ ...search });
          // console.log(search);
     };

     handleChangeDate = (date, dateString, name) => {
          let { search } = this.state;
          search[name] = dateString;
          this.setState({ ...search });
     };

     /*----------------------Search Data--------------------------*/
     handleClickSearch = () => {
          let { order, search } = this.state;
          let newSearch = order
               .filter(
                    (el) =>
                         (search.created_at ? dayjs(el.created_at).format("DD/MM/YYYY") === search.created_at : el) &&
                         (search.status ? (search.status === "ทั้งหมด" ? el : String(el.acept_status) === String(search.status)) : el)
               )
               .filter(
                    (el) =>
                         (search.acorder_name ? String(el.acorder_name).indexOf(String(search.acorder_name)) > -1 : el) &&
                         (search.acorder_saler_name ? String(el.saler_name_string).indexOf(String(search.acorder_saler_name)) > -1 : el) &&
                         (search.acorder_id ? String(el.acorder_code_id).indexOf(String(search.acorder_id)) > -1 : el)
               );
          this.setState({ newSearch });
     };

     /* ----------------------- Formatter ------------------------------ */
     detailFormatter(cell, row, rowIndex, formatExtraData) {
          return <a onClick={() => window.location.assign(`/admin/order/acc/detail${row.acorder_id}`)}>ทำรายการ</a>;
     }

     dateFormatter(cell, row, rowIndex, formatExtraData) {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     }

     orderFormatter = (cell, row) => {
          return <a onClick={() => window.location.assign(`/admin/order/acc/detail${row.acorder_id}`)}>{cell}</a>;
     };

     render() {
          let { order, newSearch, search } = this.state;
          const acc = {
               column: [
                    {
                         dataField: "detail",
                         text: "ทำรายการ",
                         formatter: this.detailFormatter,
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "acorder_code_id",
                         text: "เลขที่ใบสั่งขายอุปกรณ์",
                         // headerClasses: "header-custom __left",
                         formatter: this.orderFormatter,
                    },
                    {
                         dataField: "acept_status",
                         text: "สถานะใบสั่งขายอุปกรณ์",
                    },
                    {
                         dataField: "created_at",
                         text: "วันที่ซื้อ",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-ss",
                    },
                    {
                         dataField: "acorder_name",
                         text: "ชื่อลูกค้า",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "saler_name_string",
                         text: "ชื่อผู้ขาย",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "series_name",
                         text: "Series",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "model_name",
                         text: "Model",
                         headerClasses: "header-custom __btn-ml",
                         formatter: (e) => (e ? e : "-"),
                    },
                    {
                         dataField: "color_name",
                         text: "สี",
                         formatter: (e) => (e ? e : "-"),
                         headerClasses: "header-custom __right __btn-s",
                    },
               ],
               data: newSearch ? newSearch : order,
               keyField: "acorder_id",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "การขายอุปกรณ์" }]}
                         active={0}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "เพิ่มใบสั่งขายอุปกรณ์",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __main-panel accorder">
                         <Search
                              option={{
                                   value: {
                                        status: search.status,
                                   },
                              }}
                              type={"acc"}
                              handleSelectDate={this.handleChangeDate}
                              handleChangeText={this.handleChangeText}
                              handleClickSearch={this.handleClickSearch}
                         />
                         {this.state.loading ? (
                              <Skeleton type={"index"} column={acc.column} />
                         ) : (
                              <Table type={"index-fixhead"} column={acc.column} data={acc.data} keyField={acc.keyField} />
                         )}
                    </div>
               </div>
          );
     }
}
