export default {
     toyota_bg: require("../assets/images/toyota_bg.png"),
     toyota_logo: require("../assets/images/toyota_logo.png"),
     toyota_ps_logo: require("../assets/images/toyota_ps_logo.png"),
     list_car: require("../assets/images/list-car.png"),
     excel: require("../assets/images/001-excel.png"),
     files: require("../assets/images/001-files.png"),
     files_active: require("../assets/images/001-files-active.png"),
     sale: require("../assets/images/sale.png"),
     finance_bg: require("../assets/images/finance_bg.png"),
     finance_bg_2: require("../assets/images/finance_bg_2.png"),
     finance_calculator: require("../assets/images/finance_calculator.png"),
     finance_pp: require("../assets/images/finance_pp.png"),
     maintenance: require("../assets/images/maintenance.png"),
     service: require("../assets/images/service.png"),
     user_ac: require("../assets/images/user_ac.png"),
     car_ac: require("../assets/images/car_ac.png"),
     warm_car_ac: require("../assets/images/warm_car_ac.png"),
     cool_car_ac: require("../assets/images/cool_car_ac.png"),
};
