import { Breadcrumbs, Table } from "components";
import { Button, Col } from "react-bootstrap";
import React, { Component } from "react";
import { GET, POST } from "api";
import swal from "sweetalert";

const title = [{ title: "รายการรถยนต์", onClick: () => window.location.replace("/admin/car") }, { title: "เพิ่มรถยนต์" }];

export default class ItemsUpload extends Component {
     constructor(props) {
          super(props);
          this.state = {
               carList: [],
          };
     }

     componentDidMount = async () => {
          this.getCarUpload();
     };

     getCarUpload = async () => {
          // try {
          //      let res = await GET("/aos/get_car_master");
          //      // console.log(res);
          //      this.setState({ carList: res });
          // } catch (error) {
          //      console.log(error);
          // }
     };
     /*-------------------Handle-------------------*/
     onDeleteCar = (row, rowIndex) => {
          console.log(row);
          let { carList } = this.state;
          carList.splice(
               carList.findIndex((el) => el === row),
               1
          );
          console.log(carList);
          this.setState(carList);
     };
     onSubmit = async () => {
          // let { carList, showModal } = this.state;
          // try {
          //      let res = await POST("/aos/create_car_master", { arr: carList });
          //      if (res === "create_car_master success") {
          //           swal({
          //                text: "บันทึกสำเร็จ",
          //                icon: "success",
          //                button: "ตกลง",
          //           }).then((value) => {
          //                window.location.assign("car/search/list/car");
          //           });
          //      }
          // } catch (error) {
          //      swal({
          //           text: "ไม่สามารถบันทึกได้",
          //           icon: "warning",
          //           button: "ตกลง",
          //           dangerMode: true,
          //      });
          //      console.log("error", error);
          // }
     };

     /*------------------Formatter--------------------*/
     carFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <span style={{ color: row.duplicate ? "green" : "red" }}>{cell}</span>;
     };
     carModelFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <span style={{ color: row.news ? "green" : "red" }}>{cell}</span>;
     };
     deleteFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <a style={{ color: "red", textAlign: "center" }} onClick={() => this.onDeleteCar(row, rowIndex)}>
                    ลบ
               </a>
          );
     };
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return `${rowIndex + 1}`;
     };
     render() {
          const { carList } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left  __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "series_code",
                         text: "รหัสรุ่น",
                    },
                    {
                         dataField: "series_name",
                         text: "ชื่อรุ่น",
                    },
                    {
                         dataField: "model_code",
                         text: "รหัสโมเดล",
                         formatter: this.carModelFormatter,
                    },
                    {
                         dataField: "model_name",
                         text: "ชื่อโมเดล",
                    },
                    {
                         dataField: "car_cc",
                         text: "เครื่องยนต์",
                    },
                    {
                         dataField: "color_code",
                         text: "รหัสสี",
                    },
                    {
                         dataField: "color_tmt",
                         text: "ชื่อสีตาม TMT",
                    },
                    {
                         dataField: "color_name",
                         text: "ชื่อสีตามกรมขนส่ง",
                    },
                    {
                         dataField: "car_tank",
                         text: "เลขตัวถัง",
                         formatter: this.carFormatter,
                    },
                    {
                         dataField: "car_engine",
                         text: "เลขเครื่องยนต์",
                         formatter: this.carFormatter,
                    },
                    {
                         dataField: "car_fun",
                         text: "ราคาต้นทุน",
                    },
                    {
                         dataField: "car_price",
                         text: "ราคาขาย",
                    },
                    {
                         dataField: "generate",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right  __no",
                         formatter: this.deleteFormatter,
                    },
               ],
               data: carList,
               keyField: "no",
          };
          // console.log("carList", carList);
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={title}
                         active={title.length - 1}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "บันทึก",
                                   handleClick: this.onSubmit,
                              },
                         ]}
                    />
                    <div className="content __main-panel uploadcar">
                         <Table
                              type={"summary"}
                              column={table.column}
                              data={table.data}
                              keyField={table.keyField}
                              amount={carList.length}
                              used={carList.filter((el) => el.news).length}
                              duplicate={carList.filter((el) => !el.duplicate).length}
                         />
                    </div>
                    {/* <div className="input-field">
                         <div className="button-group __submit row">
                              <Col md={{ span: 5, offset: 11 }}>
                                   <Button onClick={this.onSubmit}>บันทึก</Button>
                              </Col>
                         </div>
                    </div> */}
               </div>
          );
     }
}
