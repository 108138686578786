import { POST, ip } from "api";
import { Breadcrumbs, LabelBar, Field, FormTable, Table, ModalPanel } from "components/index";
import dayjs from "dayjs";
import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import swal from "@sweetalert/with-react";
function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}
export default class GenerateTax extends Component {
     constructor(props) {
          super(props);
          this.state = {
               tax_status: this.props.match.params.tax_status,
               id: this.props.match.params.id,
               supplier_id: this.props.match.params.supplier_id,
               car_turn_id: this.props.match.params.car_turn_id,
               fileURL: "",
               order: {
                    item: [],
               },
               issue: {
                    oder_turnpay_payment_method: "เงินสด",
               },
               file: "",
               rowIndex: 0,
               slectPay: [
                    {
                         check: false,
                         payment_method: "เงินสด",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "เงินโอน",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "เช็ค",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "บัตรเครดิต",
                         detail: "",
                         price: 0,
                         file: "",
                    },
                    {
                         check: false,
                         payment_method: "รับชำระโดย อื่น ๆ",
                         detail: "",
                         price: 0,
                         file: "",
                         orderleasingPdf: "",
                    },
               ],
               placeholder: {
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    n0: "หลักฐานการชำระเงิน",
                    n1: "หลักฐานการชำระเงิน",
                    n2: "หลักฐานการชำระเงิน",
                    n3: "หลักฐานการชำระเงิน",
                    n4: "หลักฐานการชำระเงิน",
               },
               total: 0,
               row: {},
          };
     }

     componentDidMount = () => {
          if (this.state.car_turn_id) {
               this.getCarTurn();
          } else {
               this.getTaxDetail();
          }

          // this.getOrderTurnTaxPdf()
     };
     getCarTurn = async () => {
          let { order, slectPay } = this.state;
          try {
               let result = await POST("/order/this_order_turn_car", { order_id: this.state.id });
               order = { ...result };
               order.item = [
                    {
                         items_name: `เทิร์นรถยนต์ ${result.brand} ซีรี่ ${result.series_brand} ปี ${result.year}`,
                         order_ac_price: result.car_turn_price,
                         order_ac_amount: 1,
                    },
               ];
               let total = 0;
               total = (result.car_turn_price * 7) / 100 + result.car_turn_price;
               slectPay[0].price = total;

               let detailPayment = [];
               let total_success = 0;

               if (result.oder_turnpay_status === "ออกใบกำกับภาษีแล้ว") {
                    if (result.oder_turnpay_payment_method !== "เงินสด" && result.oder_turnpay_payment_method !== "ผ่อนชำระ") {
                         for (let e of JSON.parse(result.oder_turnpay_payment_method)) {
                              if (e.check) {
                                   detailPayment.push(e);
                                   total_success += Number(e.price);
                              }
                         }
                    }
               }

               this.setState({ order: order, total: total, detailPayment, total_success });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     getTaxDetail = async () => {
          let { id, supplier_id, issue, slectPay, car_turn_id } = this.state;
          try {
               let result = await POST("/order/this_order_turn", { order_id: id, supplier_id: supplier_id });
               issue.oder_turnpay_date = result.oder_turnpay_date ? dayjs(result.oder_turnpay_date) : dayjs(new Date());

               let total = 0;
               for (let el of result.item) {
                    total += Number(el.order_ac_price);
               }

               total = total + (total * 7) / 100;

               slectPay[0].price = total;

               let detailPayment = [];
               let total_success = 0;

               if (result.oder_turnpay_status === "ออกใบกำกับภาษีแล้ว") {
                    if (result.oder_turnpay_payment_method !== "เงินสด" && result.oder_turnpay_payment_method !== "ผ่อนชำระ") {
                         for (let e of JSON.parse(result.oder_turnpay_payment_method)) {
                              if (e.check) {
                                   detailPayment.push(e);
                                   total_success += Number(e.price);
                              }
                         }
                    }
               }

               this.setState({ order: { ...result }, issue, slectPay, total: total, total_success, detailPayment });
          } catch (err) {
               console.log(err);
          }
     };

     getOrderTurnTaxPdf = async (type) => {
          try {
               let { order } = this.state;
               let token = localStorage.getItem("token");
               let path;
               if (order.car_turn_id !== 0) {
                    path = "order/turn_tax_car_pdf";
               } else {
                    path = "order/turn_tax_pdf";
               }

               await axios
                    .post(
                         `${ip}/${path}`,
                         { order_id: order.order_id, supplier_id: order.supplier_id, type },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {}
     };

     handleSubmit = async () => {
          let { id, supplier_id, issue, file, order, slectPay } = this.state;
          // console.log(`issue`, issue);

          let check_status = slectPay.find((el) => el.check === true);
          let price_status = !slectPay.find((el) => el.check === true && (el.price === 0 || el.price === ""));

          let payment = JSON.stringify(slectPay);

          try {
               if (check_status) {
                    if (price_status) {
                         if (slectPay[1].check && slectPay[1].file === "") {
                              swal({
                                   text: "กรณีเงินโอน กรุณาแนบหลักฐานการชำระเงิน",
                                   icon: "warning",
                                   button: "ตกลง",
                                   dangerMode: true,
                              });
                         } else {
                              //OK

                              issue.oder_turnpay_date = dayjs(issue.oder_turnpay_date).format("YYYY-MM-DD");
                              issue.oder_turnpay_payment_method = payment;
                              issue.oder_turnpay_id = order?.oder_turnpay_id;
                              issue.oder_turnpay_status = "ออกใบกำกับภาษีแล้ว";
                              issue.oder_turnpay_sign = "";

                              await POST("/order/add_turn_payment", { ...issue, branch_id: order.branch_id });

                              if (slectPay[1].file && slectPay[1].check) {
                                   let formData1 = new FormData();
                                   formData1.append("oder_turnpay_id", issue.oder_turnpay_id);
                                   formData1.append("file", slectPay[1].file);
                                   formData1.append("index", 1);
                                   await POST("/order/file_turn_payment", formData1, true);
                              }
                              if (slectPay[3].file && slectPay[3].check) {
                                   let formData3 = new FormData();
                                   formData3.append("oder_turnpay_id", issue.oder_turnpay_id);
                                   formData3.append("file", slectPay[3].file);
                                   formData3.append("index", 3);
                                   await POST("/order/file_turn_payment", formData3, true);
                              }
                              if (slectPay[4].file && slectPay[4].check) {
                                   let formData4 = new FormData();
                                   formData4.append("oder_turnpay_id", issue.oder_turnpay_id);
                                   formData4.append("file", slectPay[4].file);
                                   formData4.append("index", 4);
                                   await POST("/order/file_turn_payment", formData4, true);
                              }
                              swal({
                                   text: "ออกใบกำกับภาษีแล้ว",
                                   icon: "success",
                                   button: "ตกลง",
                                   // dangerMode: true,
                              }).then(() => {
                                   window.location.reload();
                              });
                         }
                    } else {
                         swal({
                              text: "กรุณากรอกจำนวนเงินให้ครบ",
                              icon: "warning",
                              button: "ตกลง",
                              dangerMode: true,
                         });
                    }
               } else {
                    swal({
                         text: "กรุณาเลือกวิธีชำระเงิน",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    });
               }
          } catch (error) {
               console.log("error", error);
          }

          // try {

          // await POST("/order/add_turn_payment", { ...issue, branch_id: order.branch_id });
          // if (file) {
          //      let form = new FormData();
          //      form.append("oder_turnpay_id", issue.oder_turnpay_id);
          //      form.append("file", file);
          //      let fileresult = await POST("/order/file_turn_payment", form, true);
          //      console.log(fileresult);
          //      swal({
          //           icon: "success",
          //           text: "ออกใบกำกับภาษีแล้ว",
          //           buttons: {
          //                submit: "เสร็จสิ้น",
          //           },
          //      }).then((value) => {
          //           window.location.assign(`/admin/payment/tax/ออกใบกำกับภาษีแล้ว/${supplier_id}/${id}`);
          //      });
          // } else {
          //      swal({
          //           icon: "warning",
          //           text: "ไม่พบไฟล์",
          //           buttons: {
          //                danger: "ยกเลิก",
          //           },
          //      });
          // }
          // } catch (err) {
          //      swal({
          //           icon: "warning",
          //           text: "กรุณากรอกข้อมูล",
          //           content: <span className="info">{err}</span>,
          //           buttons: {
          //                danger: "ยกเลิก",
          //           },
          //      });
          // }
     };

     downloadFile = () => {
          let { order } = this.state;
          window.open(`${order?.oder_turnpay_file}`);
     };

     handleSelectDate = (date, dateString, name) => {
          let { issue, id, order } = this.state;
          issue[name] = date;
          // issue.oder_turnpay_id = order?.oder_turnpay_id;
          // issue.oder_turnpay_status = "ออกใบกำกับภาษีแล้ว";
          // issue.oder_turnpay_sign = "";
          this.setState({ ...issue });
          // console.log(this.state.issue);
     };

     handleChangeNumber = (value, rowIndex) => {
          let { slectPay, order } = this.state;
          slectPay[rowIndex].price = value.value;
          this.setState({ slectPay });
     };

     handleChangeTextSelect = (e, row, rowIndex) => {
          // let { issue, id, slectPay } = this.state;
          // slectPay[rowIndex].detail = e.target.value;
          // if (rowIndex === this.state.rowIndex) {
          //      issue.oder_turnpay_detail = slectPay[rowIndex].detail;
          // }
          // console.log(slectPay);
          // this.setState({ ...slectPay, ...issue });
          // console.log(this.state.issue);
          let { slectPay } = this.state;
          slectPay[rowIndex].detail = e.target.value;
          this.setState({ slectPay });
     };
     // handleChangePrice = (e, row, rowIndex) => {
     //      let { issue, id, slectPay } = this.state;
     //      slectPay[rowIndex].price = e.target.value;
     //      if (rowIndex === this.state.rowIndex) {
     //           issue.oder_turnpay_price = Number(slectPay[rowIndex].price);
     //      }
     //      console.log(slectPay);
     //      this.setState({ ...slectPay, ...issue });
     //      console.log(this.state.issue);
     // };

     // handleChangeFile = (e, rowIndex) => {
     //      let { slectPay, issue, file, placeholder } = this.state;

     //      if (e.target.files.length !== 0) {
     //           const fileUploaded = e.target.files[0];
     //           slectPay[rowIndex].file = fileUploaded;

     //           if (rowIndex === this.state.rowIndex) {
     //                file = slectPay[rowIndex].file;
     //           }

     //           placeholder[`n${rowIndex}`] = e.target.files[0].name;
     //           placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

     //           console.log(slectPay);
     //           this.setState({ ...slectPay, file, placeholder });
     //           console.log(file);
     //      }
     // };

     handleChangeFile = (e, rowIndex) => {
          let { slectPay, placeholder } = this.state;

          if (e.target.files.length !== 0) {
               const fileUploaded = e.target.files[0];
               slectPay[rowIndex].file = fileUploaded;
               placeholder[`n${rowIndex}`] = e.target.files[0].name;
               placeholder[`p${rowIndex}`] = !placeholder[`p${rowIndex}`];

               this.setState({
                    slectPay,
                    placeholder,
               });
          }
     };

     /* ------------------------ onChange ----------------------------- */
     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          let { issue, order, slectPay, file } = this.state;
          // issue.oder_turnpay_id = order?.oder_turnpay_id;
          // issue.oder_turnpay_status = "ออกใบกำกับภาษีแล้ว";
          // if (checked) {
          //      issue.oder_turnpay_payment_method = row.payment_method;
          //      issue.oder_turnpay_detail = slectPay[rowIndex].detail;
          //      issue.oder_turnpay_price = Number(slectPay[rowIndex].price);
          //      file = slectPay[rowIndex].file;
          //      this.setState({ issue, rowIndex: rowIndex, file });
          //      console.log(this.state.issue);
          //      console.log(file);
          // }
          if (checked) {
               // order.payment_method = row.payment_method;
               slectPay[rowIndex].check = true;
               this.setState({ order, rowIndex: rowIndex, slectPay });
          } else {
               slectPay[rowIndex].check = false;
               this.setState({ slectPay });
          }
     };
     /* ------------------------ Formatter ---------------------------- */
     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { issue } = this.state;
          return (
               <LabelBar
                    type={"checkbox-check"}
                    label={[{ value: row.payment_method }]}
                    handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
               />

               // <Form.Check
               //      type="radio"
               //      name={"payment_method"}
               //      value={issue.oder_turnpay_payment_method}
               //      require={true}
               //      onChange={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
               // />
          );
     };

     inputFormFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { slectPay } = this.state;
          if (row.payment_method === "เช็ค") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         // placeholder={"เลขที่"}
                         placeholder={slectPay[rowIndex].detail === "" ? "เลขที่" : slectPay[rowIndex].detail}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          } else if (row.payment_method === "รับชำระโดย อื่น ๆ") {
               return (
                    <Field
                         type={"text"}
                         name={row.payment_method}
                         // placeholder={"ระบุ"}
                         placeholder={slectPay[rowIndex].detail === "" ? "ระบุ" : slectPay[rowIndex].detail}
                         require={true}
                         value={null}
                         handleChangeText={(e) => this.handleChangeTextSelect(e, row, rowIndex)}
                    />
               );
          } else {
               return <>-</>;
          }
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={"turnpay_price"}
                    placeholder={"0.00"}
                    decimalScale={2}
                    require={false}
                    value={row?.price || null}
                    handleChangeNumber={(e) => this.handleChangeNumber(e, rowIndex)}
               />
          );
     };

     // inputFileFormatter = (cell, row, rowIndex, formatExtraData) => {
     //     if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
     //         return (
     //             <Field
     //                 type={'file'}
     //                 name={row.payment_method}
     //                 placeholder={row.file !== '' ? row.file.name : 'หลักฐานการชำระเงิน'}
     //                 require={true}
     //                 value={null}
     //                 button={'แนบไฟล์'}
     //                 style_id={'custom-btn __beside-btn'}
     //                 handleChange={e => this.handleChangeFile(e, rowIndex)}
     //             />
     //         )
     //     } else if (row.payment_method === "เช็ค") {
     //         return <span className="warning __info">ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ
     //                     ตามเช็คได้เรียบร้อยแล้ว</span>
     //     } else {
     //         return (
     //             <></>
     //         )
     //     }
     // }

     inputFileFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { placeholder } = this.state;

          switch (row.payment_method) {
               case "เงินโอน":
                    if (!formatExtraData[1] === formatExtraData[1]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n1}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "บัตรเครดิต":
                    if (!formatExtraData[3] === formatExtraData[3]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n3}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               case "รับชำระโดย อื่น ๆ":
                    if (!formatExtraData[4] === formatExtraData[4]) {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={"หลักฐานการชำระเงิน"}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    } else {
                         if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                              return (
                                   <Field
                                        type={"file"}
                                        // name={row.payment_method}
                                        placeholder={`${placeholder.n4}`}
                                        value={null}
                                        button={"แนบไฟล์"}
                                        style_id={"custom-btn __beside-btn"}
                                        handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                        accept={".png,.jpg,.pdf"}
                                   />
                              );
                         } else if (row.payment_method === "เช็ค") {
                              return (
                                   <span className="warning __info">
                                        ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                                   </span>
                              );
                         } else {
                              return <></>;
                         }
                    }
               default:
                    if (row.payment_method === "เงินโอน" || row.payment_method === "บัตรเครดิต" || row.payment_method === "รับชำระโดย อื่น ๆ") {
                         return (
                              <Field
                                   type={"file"}
                                   // name={row.payment_method}
                                   placeholder={"หลักฐานการชำระเงิน"}
                                   value={null}
                                   button={"แนบไฟล์"}
                                   style_id={"custom-btn __beside-btn"}
                                   handleChange={(e) => this.handleChangeFile(e, rowIndex)}
                                   accept={".png,.jpg,.pdf"}
                              />
                         );
                    } else if (row.payment_method === "เช็ค") {
                         return (
                              <span className="warning __info">
                                   ในการชำระเงินด้วยเช็ค ใบเสร็จรับเงินนี้จะสมบูรณ์ต่อเมื่อเรียกเก็บ ตามเช็คได้เรียบร้อยแล้ว
                              </span>
                         );
                    } else {
                         return <></>;
                    }
          }
     };

     viewFile = (row) => {
          this.setState({ showmodal_file: true, row: row });
     };

     viewFileFormatter = (cell, row) => {
          let arr = row.file.split(".")[1];
          if (cell) {
               if (arr === "pdf") {
                    return <a onClick={() => window.open(`${ip}/static/${row.file}`)}>หลักฐานการชำระเงิน</a>;
               } else {
                    return <a onClick={() => this.viewFile(row)}>หลักฐานการชำระเงิน</a>;
               }
          } else {
               return <span>-</span>;
          }
     };

     totalPrice = () => {
          let { slectPay } = this.state;
          let total = 0;
          slectPay.map((el, i) => {
               if (el.check) {
                    total = total + Number(slectPay[i].price);
               }
          });
          total = total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          return total;
     };

     render() {
          let { tax_status, id, order, slectPay, placeholder, issue, detailPayment, total_success, row } = this.state;
          const supplier = [
               { value: 0, name: "เลือก 0" },
               { value: 1, name: "เลือก 1" },
               { value: 2, name: "เลือก 2" },
          ];

          let total_price = this.state.total;
          let total_pay = 0;

          this.state.slectPay.map((el) => {
               if (el.check) {
                    total_pay += Number(el.price);
               }
          });

          let table_tax = {
               column: [
                    {
                         text: "เลือก",
                         formatter: this.checkBoxFormatter,
                         headerClasses: "header-custom __left __no",
                         footer: "",
                    },
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "กรอกข้อมูล",
                         formatter: this.inputFormFormatter,
                         footer: "รวม",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: this.inputAmountFormatter,
                         footer: this.totalPrice,
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.inputFileFormatter,
                         headerClasses: "header-custom __right",
                         formatExtraData: [placeholder.p0, placeholder.p1, placeholder.p2, placeholder.p3, placeholder.p4],
                         footer: "บาท",
                    },
               ],
               data: slectPay,
               keyField: "payment_method",
          };

          let table_detail_payment = {
               column: [
                    {
                         dataField: "payment_method",
                         text: "รับชำระโดย",
                         headerClasses: "header-custom __left",
                         formatter: (e) => (e ? e : "-"),
                         footer: "",
                    },
                    {
                         dataField: "detail",
                         text: "รายละเอียด",
                         formatter: (e) => (e ? e : "-"),
                         footer: "รวมทั้งสิ้น",
                    },
                    {
                         dataField: "price",
                         text: "จำนวนเงิน (บาท)",
                         formatter: (e) => (e ? Number(e).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"),
                         footer: Number(total_success ? total_success : 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                         }),
                    },
                    {
                         dataField: "file",
                         text: "หลักฐานการชำระเงิน",
                         formatter: this.viewFileFormatter,
                         headerClasses: "header-custom __right",
                         footer: "บาท",
                    },
               ],
               data: detailPayment ? detailPayment : [],
               keyField: "payment_method",
          };

          switch (order?.oder_turnpay_status) {
               case "ออกใบกำกับภาษีแล้ว":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `ใบกำกับภาษีกรณีเทิร์นสินค้า`,
                                             onClick: () => window.location.assign("/admin/payment/tax"),
                                        },
                                        {
                                             title: `ทำรายการใบจอง (${order.order_code_id})`,
                                             onClick: () => window.location.assign(`/admin/order/car/detail${this.state.id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"Supplier"} />
                                                  {/* <Field
                                            type={"select"}
                                            title={"Supplier"}
                                            option={supplier}
                                            md={6}
                                        /> */}
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Supplier : ",
                                                                      detail: order.car_turn_id
                                                                           ? "บริษัท โซลทรัค ลักกี้ จัดกัด(สำนักงานใหญ๋)"
                                                                           : order?.supplier_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการ"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อลูกค้า : ",
                                                                      detail: order?.userinfo_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: order?.userinfo_idcard,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ :",
                                                                      // detail: `${accpayment?.userinfo_address} ถ.XXXXXXXX ตำบล/แขวง ${accpayment?.userinfo_sub_district} อำเภอ/เขต ${accpayment?.userinfo_district} จังหวัด ${accpayment?.userinfo_province} ${accpayment?.userinfo_zipcode}`,
                                                                      detail: `${order?.userinfo_address} ${
                                                                           order?.street ? `ถ.${order?.street}` : ""
                                                                      } ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${
                                                                           order?.userinfo_province
                                                                      } ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โทรศัพท์ :",
                                                                      detail: order?.userinfo_tel,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: `${order.order_code_id}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                             </div>
                                             <div className="content" id="table-content">
                                                  <FormTable type="tax" item={order?.item} />
                                             </div>
                                        </div>
                                   </div>

                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ออกใบกำกับภาษี"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "วันที่ออกใบกำกับภาษี :",
                                                                      detail: order?.oder_turnpay_date
                                                                           ? dateFormatter(order?.oder_turnpay_date)
                                                                           : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                       <Table
                                                            type={"summary"}
                                                            column={table_detail_payment.column}
                                                            data={table_detail_payment.data}
                                                            keyField={table_detail_payment.keyField}
                                                       />
                                                       {/* <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชำระโดย :",
                                                                      detail: order?.oder_turnpay_payment_method,
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หลักฐานการชำระเงิน :",
                                                                      detail: "ไฟล์หลักฐานการชำระเงิน Pdf/JPG/PNG",
                                                                 }}
                                                                 md={12}
                                                                 onClickLabelBar={this.downloadFile}
                                                                 textHeader_style={"underline"}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "จำนวนเงิน :",
                                                                      detail: `${order?.oder_turnpay_price} บาท`,
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row> */}
                                                       <ModalPanel
                                                            show={this.state.showmodal_file}
                                                            title={`หลักฐานการชำระเงิน (${row?.payment_method || "ไม่ระบุ"})`}
                                                            type={"image"}
                                                            image={`${ip}/static/${row.file}`}
                                                            onHide={() => this.setState({ showmodal_file: false })}
                                                       />
                                                       <div>
                                                            <Breadcrumbs
                                                                 under={true}
                                                                 type={"document"}
                                                                 title={"ออกใบกำกับภาษีแล้ว"}
                                                                 detail={"พิมพ์ใบกำกับภาษี"}
                                                                 button={{
                                                                      btn1: {
                                                                           onClick: () => this.getOrderTurnTaxPdf("ต้นฉบับ"),
                                                                           name: "ต้นฉบับ",
                                                                           variant: "outline-primary",
                                                                      },
                                                                      btn2: {
                                                                           onClick: () => this.getOrderTurnTaxPdf("สำเนา"),
                                                                           name: "สำเนา",
                                                                           variant: "outline-primary",
                                                                      },
                                                                 }}
                                                            />
                                                            <div style={{ height: "80px" }}></div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );
               case "ยังไม่ออกใบกำกับภาษี":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `ใบกำกับภาษีกรณีเทิร์นสินค้า`,
                                             onClick: () => window.location.assign("/admin/payment/tax"),
                                        },
                                        {
                                             title: `ทำรายการใบจอง (${order.order_code_id})`,
                                             onClick: () => window.location.assign(`/admin/order/car/detail${this.state.id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"Supplier"} />
                                                  {/* <Field
                                            type={"select"}
                                            title={"Supplier"}
                                            option={supplier}
                                            md={6}
                                        /> */}
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Supplier : ",
                                                                      detail: order.car_turn_id
                                                                           ? "บริษัท โซลทรัค ลักกี้ จัดกัด(สำนักงานใหญ๋)"
                                                                           : order?.supplier_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการ"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อลูกค้า : ",
                                                                      detail: order?.userinfo_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: order?.userinfo_idcard,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ :",
                                                                      // detail: `${accpayment?.userinfo_address} ถ.XXXXXXXX ตำบล/แขวง ${accpayment?.userinfo_sub_district} อำเภอ/เขต ${accpayment?.userinfo_district} จังหวัด ${accpayment?.userinfo_province} ${accpayment?.userinfo_zipcode}`,
                                                                      detail: `${order?.userinfo_address} ${
                                                                           order?.street ? `ถ.${order?.street}` : ""
                                                                      } ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${
                                                                           order?.userinfo_province
                                                                      } ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โทรศัพท์ :",
                                                                      detail: order?.userinfo_tel || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: `${order.order_code_id}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                             </div>
                                             <div className="content" id="table-content">
                                                  <FormTable type="tax" item={order?.item} />
                                             </div>
                                        </div>
                                   </div>

                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ออกใบเสร็จรับเงิน"} />
                                                  <Field
                                                       type={"date"}
                                                       title={"วันที่ออกใบเสร็จ"}
                                                       name={"oder_turnpay_date"}
                                                       placeholder={issue.oder_turnpay_date ? issue.oder_turnpay_date : "เลือกวันที่"}
                                                       md={6}
                                                       formatDate={"DD/MM/YYYY"}
                                                       require={false}
                                                       value={issue?.oder_turnpay_date}
                                                       handleSelectDate={this.handleSelectDate}
                                                  />
                                             </div>
                                             <div className="content checkcenter" id="table-content">
                                                  <Col xl={12}>
                                                       <Table
                                                            type={"summary"}
                                                            column={table_tax.column}
                                                            data={table_tax.data}
                                                            keyField={table_tax.keyField}
                                                       />
                                                  </Col>
                                             </div>
                                             {/* Button panel */}
                                             <div className="button-group __submit row">
                                                  <Col xs={6}>
                                                       <Button disabled={!(total_pay >= total_price)} onClick={this.handleSubmit}>
                                                            ออกใบกำกับภาษี
                                                       </Button>
                                                  </Col>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    );

               default:
                    return <div></div>;
          }
     }
}
