import React, { Component } from 'react'
import { GET } from 'api/index';
import { Breadcrumbs, Table, Search, Image } from 'components/index';

export default class LsApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LsApp: [],
        };
    }
    componentDidMount = () => {
        this.getLsApp();
    };
    getLsApp = async () => {
        try {
            let result = await GET('');
            this.setState({ LsApp: result });
        } catch (error) {
            console.log(error)
        }
    };
    detailFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <a
                // onClick={() =>
                //     window.location.assign(
                //         `/admin/payment/ls-App${row.item_type}${row.preemption_id}`
                //     )
                // }
            >
                ทำรายการ
            </a>
        );
    }
    render() {
        const table = {
            column: [
                {
                    dataField: 'preemption_id',
                    text: 'เลขที่ใบจอง',
                    headerClasses: "header-custom __left",
                },
                {
                    dataField: 'a_card',
                    text: 'A-Card No.',
                },
                {
                    dataField: 'showroom_type',
                    text: 'โชว์รูม',
                },
                {
                    dataField: 'saller_name',
                    text: 'ชื่อผู้ขาย',
                },
                {
                    dataField: 'date',
                    text: 'วันที่จอง',
                    formatter: this.dateFormatter,
                },
                {
                    dataField: 'order_status',
                    text: 'การอนุมัติ',
                },
                {
                    dataField: 'credit_status',
                    text: 'สถานะสินเชื่อ',
                },
                {
                    dataField: 'list_item',
                    text: 'ทำรายการ',
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __right",
                },
            ],
            data: [
                {
                    preemption_id: 'A100000000',
                    a_card: '00000',
                    showroom_type: 'Toyota Ps',
                    saller_name: 'ชื่อผู้ขาย',
                    date: 'วว/ดด/ปป',
                    order_status: "รออนุมัติ",
                    credit_status: "ขอสินเชื่อ",
                },
                {
                    preemption_id: 'A100000000',
                    a_card: '00000',
                    showroom_type: 'Toyota Ps',
                    saller_name: 'ชื่อผู้ขาย',
                    date: 'วว/ดด/ปป',
                    order_status: "รออนุมัติ",
                    credit_status: "ขอสินเชื่อ",
                },
                {
                    preemption_id: 'A100000000',
                    a_card: '00000',
                    showroom_type: 'Toyota Ps',
                    saller_name: 'ชื่อผู้ขาย',
                    date: 'วว/ดด/ปป',
                    order_status: "รออนุมัติ",
                    credit_status: "ขอสินเชื่อ",
                },
            ],
            keyField: "preemption_id"
        }
        return (
            <div className='panel'>
                <Breadcrumbs
                    title={[{ title: 'การอนุมัติ Leasing' }]}
                    active={0}
                    button={''}
                />
                <div className='content __main-panel'>
                    <Search type={'ls-app'} />
                    <Table
                        type={'index'}
                        column={table.column}
                        data={table.data}
                        keyField={table.keyField}
                    />
                </div>
            </div>
        )  
    }
}
