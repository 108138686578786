import swal from "sweetalert";
import { POST } from "api";
import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";

export default class DetailPriceCarModel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               serie: {
                    status: "ใช้งาน",
               },
               price_list: {},
          };
     }

     componentDidMount = () => {
          this.getSeries();
     };

     getSeries = async () => {
          try {
               let serie = await POST("/car/this_price_car", { id: this.state.id });
               // console.log('serie', serie)
               this.setState({
                    serie: { ...serie },
                    price_list: { ...serie.price_list },
               });
          } catch (error) {
               console.log(error);
          }
     };

     editPrice = async () => {
          let { serie } = this.state;
          serie.price_list = JSON.stringify(this.state.price_list);

          try {
               let result = await POST("/car/edit_price_car", serie);
               swal({
                    icon: "success",
                    title: "แก้ไขข้อมูลเรียบร้อยแล้ว",
                    button: "เสร็จสิ้น",
                    closeOnClickOutside: false,
               }).then(() => {
                    window.location.reload();
               });
          } catch (err) {
               console.log(err);
          }
     };

     /* --------- onchange --------- */
     handleChangeNumber = (value, rowIndex, name) => {
          let { price_list } = this.state;
          price_list[name] = value.floatValue;
     };

     /* Handle Data input change */
     handleChangeText = ({ target: { name, value } }) => {
          let { serie } = this.state;
          serie[name] = value;
          this.setState({ serie });
          // console.log(serie);
     };

     /* --------- formatter --------- */
     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Col style={{ textAlign: "center" }}>
                    <span>{`${rowIndex + 1}.`}</span>
               </Col>
          );
     };

     inputAmountFormatter = (cell, row, rowIndex, formatExtraData) => {
          return (
               <Field
                    type={"number"}
                    name={`${row.name}`}
                    value={row.price}
                    placeholder={"0.00"}
                    decimalScale={2}
                    handleChangeNumber={(e) => this.handleChangeNumber(e, rowIndex, row.name)}
               />
          );
     };
     render() {
          let { serie, price_list } = this.state;
          let typeCars = ["Pick-up", "MPV", "PPV", "SUV", "CUV", "HEV", "VAN", "Eco Car", "Compact Car", "Mid-size Car", "Full-size Car"];
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "No.",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "list",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "price",
                         text: "จำนวน (บาท)",
                         formatter: this.inputAmountFormatter,
                    },
                    {},
               ],
               data: [
                    {
                         list: "ต้นทุนรถยนต์",
                         price: price_list?.car_cost,
                         name: "car_cost",
                    },
                    {
                         list: "ต้นทุนเครื่องปรับอากาศ",
                         price: price_list?.air_conditioner_cost,
                         name: "air_conditioner_cost",
                    },
                    {
                         list: "ต้นทุนก่อนภาษีมูลค่าเพิ่ม",
                         price: price_list?.cost_before_vat,
                         name: "cost_before_vat",
                    },
                    {
                         list: "ภาษีมูลค่าเพิ่มของต้นทุน",
                         price: price_list?.cost_vat,
                         name: "cost_vat",
                    },
                    {
                         list: "ต้นทุนหลังภาษีมูลค่าเพิ่ม",
                         price: price_list?.cost_after_vat,
                         name: "cost_after_vat",
                    },
                    {
                         list: "ต้นทุนอุปกรณ์",
                         price: price_list?.equipment_cost,
                         name: "equipment_cost",
                    },
                    {
                         list: "ภาษีมูลค่าเพิ่มต้นทุนอุปกรณ์",
                         price: price_list?.equipment_cost_vat,
                         name: "equipment_cost_vat",
                    },
                    {
                         list: "ต้นทุนอุปกรณ์หลังภาษีมูลค่าเพิ่ม",
                         price: price_list?.equipment_cost_after_vat,
                         name: "equipment_cost_after_vat",
                    },
                    {
                         list: "รวมต้นทุนทั้งหมด",
                         price: price_list?.total_cost,
                         name: "total_cost",
                    },
                    {
                         list: "ราคาขายรถยนต์",
                         price: price_list?.car_sale_price,
                         name: "car_sale_price",
                    },
                    {
                         list: "ราคาขายเครื่องปรับอากาศ",
                         price: price_list?.price_of_air_conditioner,
                         name: "price_of_air_conditioner",
                    },
                    {
                         list: "ราคาขายก่อนภาษีมูลค่าเพิ่ม",
                         price: price_list?.car_sale_price_air_before_vat,
                         name: "car_sale_price_air_before_vat",
                    },
                    {
                         list: "ภาษีมูลค่าเพิ่มของราคาขาย",
                         price: price_list?.price_of_air_conditioner_vat,
                         name: "price_of_air_conditioner_vat",
                    },
                    {
                         list: "ราคาขายหลังภาษีมูลค่าเพิ่ม",
                         price: price_list?.car_sale_price_air_after_vat,
                         name: "car_sale_price_air_after_vat",
                    },
                    {
                         list: "ราคาขายอุปกรณ์",
                         price: price_list?.price_of_equipment,
                         name: "price_of_equipment",
                    },
                    {
                         list: "ภาษีมูลค่าราคาขายเพิ่มอุปกรณ์",
                         price: price_list?.price_of_equipment_vat,
                         name: "price_of_equipment_vat",
                    },
                    {
                         list: "ราคาขายอุปกรณ์หลังภาษีมูลค่าเพิ่ม",
                         price: price_list?.price_of_equipment_after_vat,
                         name: "price_of_equipment_after_vat",
                    },
                    {
                         list: "รวมราคาขายทั้งหมด",
                         price: price_list?.all_sales_prices,
                         name: "all_sales_prices",
                    },
                    {
                         list: "กำไรรถยนต์",
                         price: price_list?.car_profit,
                         name: "car_profit",
                    },
                    {
                         list: "กำไรเครื่องปรับอากาศ",
                         price: price_list?.air_conditioning_profit,
                         name: "air_conditioning_profit",
                    },
                    {
                         list: "กำไรอุปกรณ์",
                         price: price_list?.equipment_profit,
                         name: "equipment_profit",
                    },
                    {
                         list: "Margin มาตรฐาน",
                         price: price_list?.margin_cost,
                         name: "margin_cost",
                    },
                    // {
                    //      list: "ค่า พรบ.",
                    //      price: price_list?.act_premium,
                    //      name: "act_premium",
                    // },
                    // {
                    //      list: "ค่าจดทะเบียน",
                    //      price: price_list?.register_price,
                    //      name: "register_price",
                    // },
                    {
                         list: "คอมมิชชั่น Extra",
                         price: price_list?.commission_extra,
                         name: "commission_extra",
                    },
                    {
                         list: "ค่า พ.ร.บ. บุคคลธรรมดา",
                         price: price_list?.act_person_general_price,
                         name: "act_person_general_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. บุคคลไม่เกิน 15 ที่นั่ง",
                         price: price_list?.act_person_notmore15_price,
                         name: "act_person_notmore15_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. บุคคลเกิน 15 ที่นั่ง",
                         price: price_list?.act_person_more15_price,
                         name: "act_person_more15_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์ไม่เกิน 7 ที่นั่ง",
                         price: price_list?.act_commerce_notmore7_price,
                         name: "act_commerce_notmore7_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์ไม่เกิน 15 ที่นั่ง",
                         price: price_list?.act_commerce_notmore15_price,
                         name: "act_commerce_notmore15_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์ไม่เกิน 20 ที่นั่ง",
                         price: price_list?.act_commerce_notmore20_price,
                         name: "act_commerce_notmore20_price",
                    },
                    {
                         list: "ค่า พ.ร.บ. เชิงพาณิชย์เกิน 20 ที่นั่ง",
                         price: price_list?.act_commerce_more20_price,
                         name: "act_commerce_more20_price",
                    },
                    {
                         list: "ค่าจดทะเบียนบุคคลธรรมดา",
                         price: price_list?.register_normal,
                         name: "register_normal",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนบุคคลธรรมดาที่จ่ายตัวแทน",
                         price: price_list?.register_fun_normal_to_company,
                         name: "register_fun_normal_to_company",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนบุคคลธรรมดาที่จ่ายขนส่ง",
                         price: price_list?.register_fun_normal_to_delivery,
                         name: "register_fun_normal_to_delivery",
                    },
                    {
                         list: "ค่าจดทะเบียนนิติบุคคล",
                         price: price_list?.coporation,
                         name: "coporation",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนนิติบุคคลที่จ่ายตัวแทน",
                         price: price_list?.register_fun_coporation_to_company,
                         name: "register_fun_coporation_to_company",
                    },
                    {
                         list: "ทุนค่าจดทะเบียนนิติบุคคลที่จ่ายขนส่ง",
                         price: price_list?.register_fun_coporation_to_delivery,
                         name: "register_fun_coporation_to_delivery",
                    },
                    {
                         list: "INNER",
                         price: price_list?.inner,
                         name: "inner",
                    },
               ],
               keyField: "no",
          };
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `รุ่นรถต์`,
                                   onClick: () => window.location.assign("/admin/car-model"),
                              },
                              {
                                   title: `แก้ไข`,
                                   onClick: () => window.location.assign(`/admin/car-model/detail/edit/${serie?.series_id}`),
                              },
                              { title: `กำหนดราคา` },
                         ]}
                         active={2}
                         button={""}
                    />
                    <div className="content __input-panel">
                         {/* รายละเอียดผู้ซื้อ */}
                         <div className="input-field">
                              <div className="-header">
                                   <div>กำหนดราคา</div>
                              </div>
                              <div className="-body">
                                   <div className="content">
                                        <Row className="noMargin">
                                             <Field
                                                  type={"text"}
                                                  title={"Model Code"}
                                                  name={"model_code"}
                                                  value={serie?.model_code}
                                                  placeholder={"กรุณากรอก Model Code"}
                                                  md={3}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Field
                                                  type={"text"}
                                                  title={"Model Name"}
                                                  name={"model_name"}
                                                  value={serie?.model_name}
                                                  placeholder={"กรุณากรอกชื่อ Model Name"}
                                                  md={3}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Field
                                                  type={"text"}
                                                  title={"เครื่องยนต์"}
                                                  name={"car_cc"}
                                                  value={serie?.car_cc}
                                                  placeholder={"กรุณากรอกเครื่องยนต์"}
                                                  md={3}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             {/* ------------ Not Show ------------- */}
                                             <div style={{ visibility: "hidden" }}>
                                                  <Field type={"text"} md={3} />
                                             </div>
                                             {/* ----------------------------------- */}

                                             <Field
                                                  type={"text"}
                                                  title={"Color Code"}
                                                  name={"color_code"}
                                                  value={serie?.color_code}
                                                  placeholder={"กรุณากรอก Color Code"}
                                                  md={3}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Field
                                                  type={"text"}
                                                  title={"Color TMT"}
                                                  name={"color_tmt"}
                                                  value={serie?.color_tmt}
                                                  placeholder={"กรุณากรอก Color TMT"}
                                                  md={3}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                             <Field
                                                  type={"text"}
                                                  title={"Color Name"}
                                                  name={"color_name"}
                                                  value={serie?.color_name}
                                                  placeholder={"กรุณากรอก Color Name"}
                                                  md={3}
                                                  handleChangeText={this.handleChangeText}
                                             />
                                        </Row>
                                        <Table type={"document"} column={table.column} data={table.data} keyField={table.keyField} />

                                        <Row className="button-group __submit">
                                             <Col lg={6}>
                                                  <Button onClick={this.editPrice}>บันทึกการแก้ไข</Button>
                                             </Col>
                                        </Row>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
