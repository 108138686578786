import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Field } from "components/index";
import { Image, Row, Col, Button } from "react-bootstrap";
import { GET, POST } from "api/index";
import dayjs from "dayjs";
import { getToken } from "../../../../static/function";

export default class ACard extends Component {
     constructor(props) {
          super(props);
          this.state = {
               file: {},
               fileTextHolder: "",
               search: [],
               branch: [],
               province: [],
               dateSelect: {},
               // SummaryAcard: [],
          };
     }
     componentDidMount = async () => {
          await this.setState({ user: getToken() });
          this.getData();
          this.getAcardSum();
     };

     /*----------------------API---------------------------*/
     getData = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               let getProvince = await POST("/aos/search_address");
               // console.log(getSummaryAcard);
               getBranch.unshift({ branch_id: "ทั้งหมด", branch_name: "ทั้งหมด" });
               this.setState({
                    branch: getBranch,
                    province: getProvince.array,
               });
          } catch (error) {
               // console.log(error);
          }
     };

     getAcardSum = async (date_from, date_to) => {
          let getSummaryAcard = await POST("/aos/summary_acard", { date_from, date_to });
          this.setState({
               summaryAcard: getSummaryAcard,
          });
     };

     /* ------------------ Handle ------------------------- */
     handleClick = () => {
          window.location.assign("/admin/a_card/manage/add");
     };

     handleChangeFile = (event) => {
          const fileUploaded = event.target.files[0];
          this.setState({
               file: fileUploaded,
               fileTextHolder: fileUploaded.name,
          });
     };
     handleClickSearch = () => {
          const { search, user } = this.state;
          if (user.position_name === "พนักงานขาย") {
               search.acard_sales = user.firstname;
          }
          this.props.history.push({
               pathname: `/admin/a_card/list`,
               state: {
                    acard_no: search.acard_no,
                    acard_sales: search.acard_sales,
                    branch_id: search.branch_id,
                    acard_name: search.acard_name,
                    acard_customer_type: search.acard_customer_type,
                    acard_tel: search.acard_tel,
                    line_id: search.line_id,
                    acard_zipcode: search.acard_zipcode,
                    status: search.status,
                    acard_create: search.acard_create,
               },
          });
     };
     handleChangeText = ({ target: { name, value } }) => {
          let { search } = this.state;
          search[name] = value;
          this.setState({ search });
     };
     handleSelectDate = (date, dateString, name) => {
          let { search } = this.state;
          if (date) {
               search[name] = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
               this.setState({ search });
          } else {
               search[name] = "";
               this.setState({ search });
          }
     };
     handleSelectDateStartStop = (date, dateString, name) => {
          let { dateSelect } = this.state;
          dateSelect[name] = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
          if (dateSelect.date_from && dateSelect.date_to) {
               this.getAcardSum(dateSelect.date_from, dateSelect.date_to);
          }
     };

     render() {
          const { search, province, branch, summaryAcard } = this.state;
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "A-Card" }]}
                         active={0}
                         button={[
                              {
                                   icon: "far fa-plus-square -icon",
                                   name: "เพิ่ม A-Card",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />
                    <div className="content __input-panel">
                         <Row className="noMargin">
                              <div className="content __ac-panel">
                                   <div className="input-field" id="field-summary">
                                        <div className="-body">
                                             <Row className="row-date">
                                                  <Field
                                                       type={"date"}
                                                       name={"date_from"}
                                                       placeholder={"วันเริ่มต้น"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       md={6}
                                                       handleSelectDate={this.handleSelectDateStartStop}
                                                  />
                                                  <Field
                                                       type={"date"}
                                                       name={"date_to"}
                                                       placeholder={"วันสิ้นสุด"}
                                                       formatDate={"DD/MM/YYYY"}
                                                       md={6}
                                                       handleSelectDate={this.handleSelectDateStartStop}
                                                  />
                                             </Row>
                                        </div>
                                   </div>
                              </div>
                         </Row>
                         <Row className="banner noMargin">
                              <Col xl={3} lg={6} xs={12} className="banner-cell" id="cell-1">
                                   <Row className="banner-cell-content">
                                        <Col xs={9} className="-text">
                                             <div>
                                                  <h4>{summaryAcard?.all}</h4>
                                                  <span className="acard"> จำนวน A-Card ทั้งหมด</span>
                                             </div>
                                        </Col>
                                        <Col xs={3} className="-img noPadding">
                                             <Image src={Img.user_ac} />
                                        </Col>
                                   </Row>
                              </Col>
                              <Col xl={3} lg={6} xs={12} className="banner-cell" id="cell-2">
                                   <Row className="banner-cell-content">
                                        <Col xs={8} className="-text">
                                             <div>
                                                  <h4>{summaryAcard?.hot}</h4>
                                                  <span className="acard">จำนวน Hot A-Card</span>
                                             </div>
                                        </Col>
                                        <Col xs={4} className="-img noPadding">
                                             <Image src={Img.car_ac} />
                                        </Col>
                                   </Row>
                              </Col>
                              <Col xl={3} lg={6} xs={12} className="banner-cell" id="cell-3">
                                   <Row className="banner-cell-content">
                                        <Col xs={8} className="-text">
                                             <div>
                                                  <h4>{summaryAcard?.warm}</h4>
                                                  <span className="acard">จำนวน Warm A-Card</span>
                                             </div>
                                        </Col>
                                        <Col xs={4} className="-img noPadding">
                                             <Image src={Img.warm_car_ac} />
                                        </Col>
                                   </Row>
                              </Col>
                              <Col xl={3} lg={6} xs={12} className="banner-cell" id="cell-4">
                                   <Row className="banner-cell-content">
                                        <Col xs={9} className="-text">
                                             <div>
                                                  <h4>{summaryAcard?.cool}</h4>
                                                  <span className="acard">จำนวน Cool A-Card</span>
                                             </div>
                                        </Col>
                                        <Col xs={3} className="-img noPadding">
                                             <Image src={Img.cool_car_ac} />
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                         <Row className="noMargin">
                              <Col md={6} className="search-bar" id="bar-ac">
                                   <Search
                                        type={"a-card"}
                                        onClick={this.handleClickSearch}
                                        handleChangeText={this.handleChangeText}
                                        handleSelectDate={this.handleSelectDate}
                                        option={{
                                             branch: branch,
                                             province: province,
                                             value: {
                                                  branch_name: search.branch_id,
                                                  acard_customer_type: search.acard_customer_type,
                                                  status: search.status,
                                                  acard_province: search.acard_province,
                                                  acard_create: search.acard_create,
                                             },
                                        }}
                                   />
                              </Col>
                              <Col md={6} className="search-bar" id="bar-ac">
                                   <div className="content __ac-panel">
                                        <div className="input-field" id="field-summary">
                                             <div className="-body">
                                                  <div className="content">
                                                       <strong>Summary</strong>
                                                       <Field
                                                            type={"summary"}
                                                            sum_name="A C-Card ที่สามารถ Convert to Booking ได้"
                                                            sum_no={summaryAcard?.booking}
                                                       />
                                                       <Field
                                                            type={"summary"}
                                                            sum_name="A-Card ที่ระบุวันที่ส่งมอบรถแล้ว"
                                                            sum_no={summaryAcard?.date_send}
                                                       />
                                                       <Field
                                                            type={"summary"}
                                                            sum_name="A-Card ที่มีการติดต่อลูกค้าในแต่ละวัน"
                                                            sum_no={summaryAcard?.contact_day}
                                                       />
                                                       <Field type={"summary"} sum_name="A-Card ที่มีการทดลองขับ" sum_no={summaryAcard?.test_drive} />
                                                  </div>
                                             </div>
                                        </div>
                                        {/* <div className="input-field" id="field-summary">
                                             <div className="-body">
                                                  <div className="content">
                                                       <Field
                                                            name={"customer_master"}
                                                            value={""}
                                                            type={"file"}
                                                            title={{
                                                                 name: "อัปโหลด Customer Master (Excel Flie)",
                                                                 icon: Img.excel,
                                                            }}
                                                            placeholder={"ไฟล์.csv"}
                                                            button={"แนบไฟล์"}
                                                            handleChang={this.handleChangeFile}
                                                            style_id={"custom-btn __beside-btn noPadding"}
                                                       />
                                                       <Col lg={{span: 4, offset: 4}}>
                                                            <Button>อัปโหลด</Button>
                                                       </Col>
                                                  </div>
                                             </div>
                                        </div> */}
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          );
     }
}
