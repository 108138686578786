import { Breadcrumbs, Field, LabelBar, Table } from "components";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { GET, POST } from "api";

export default class PayReport extends Component {
     constructor(props) {
          super(props);

          this.state = {
               modal: {
                    title: "",
                    type: 0,
               },
               pay: { branch: [], saler_name: "all" },
               show: false,
               error_data: "",
               loading: false,
          };
     }

     componentDidMount = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               let getSale = await GET("/aos/get_sale");
               let data = getSale.map((el) => ({ value: el.user_id, name: el.name }));
               data.unshift({ value: "all", name: "พนักงานขายทุกคน" });
               this.setState({ branch: getBranch, getSale: data, pay: { branch: getBranch.map((el) => el.branch_id) } });
          } catch (error) {
               console.log("error", error);
          }
     };

     /*------------ onClick ------------------ */

     showModal = (title, type, id) => {
          let { modal, pay } = this.state;

          pay.id = id;

          modal.type = type;
          modal.title = title;

          this.setState({
               ...modal,
               show: !this.state.show,
               pay,
          });
     };

     closeReport = async () => {
          this.setState({ loading: true });
          let { pay, branch, type } = this.state;
          try {
               if (!pay.date_start || !pay.date_end || !pay.branch.length) {
                    this.setState({ error_data: "กรอกข้อมูลให้ครบ" });
               } else {
                    let type_excel = "";
                    switch (parseInt(pay.id)) {
                         case 1:
                              type_excel = "รายงานรับเงินค่าจอง";
                              break;
                         case 2:
                              type_excel = "รายงานรับเงินค่ารถ";
                              break;
                         case 3:
                              type_excel = "รายงานใบกำกับภาษีส่วนอื่นๆ";
                              break;
                         case 4:
                              type_excel = "รายงานใบลดหนี้ค่าจอง";
                              break;
                         case 5:
                              type_excel = "รายงานใบกำกับภาษีส่วนซื้ออุปกรณ์เพิ่มเติม";
                              break;
                         case 6:
                              type_excel = "รายงานการรับเงินประจำวัน";
                              break;
                         case 7:
                              type_excel = "รายงานการับเงินลูกค้าซื้อสด รถแลก";
                              break;
                         case 8:
                              type_excel = "รายงานใบเสร็จรับเงิน TR";
                              break;
                         case 9:
                              type_excel = "รายงานประกันภัยบริษัทแถมลูกค้า";
                              break;
                         case 10:
                              type_excel = "รายงานประกันภัยลูกค้าซื้อ";
                              break;
                         default:
                              break;
                    }
                    if (pay.saler_name != null) {
                         if (pay.saler_name != "all") {
                              pay.seller_id = parseInt(pay.saler_name);
                         }
                    }
                    let dataBranch = pay.branch.map((el) => ({
                         branch_name: branch.find((e) => e.branch_id == el).branch_name,
                         branch_id: branch.find((e) => e.branch_id == el).branch_id,
                    }));
                    let excel = await POST("/ps/report_excel_earning", { ...pay, branch: dataBranch, type_excel });
                    window.open(excel);
                    this.setState({ show: !this.state.show, error_data: "", loading: false });
               }
          } catch (error) {
               // console.log("error", error);
               this.setState({ error_data: error, loading: false });
          }
     };

     /* ---------- formatter ----------------- */

     noFormatter = (cell, row, rowIndex, formatExtraData) => {
          return <div style={{ textAlign: "center" }}>{`${rowIndex + 1}`}</div>;
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          // console.log(row)
          return (
               <a
                    onClick={() => {
                         this.showModal(row.report, row.type, row.id);
                    }}
               >
                    ทำรายการ
               </a>
          );
     };

     reportFormatter = (cell, row, rowIndex, formatExtraData) => {
          // if (row.report === "รายงานใบเสร็จรับเงิน TR") {
          //      return (
          //           <div>
          //                <span>{cell}</span>
          //                <span style={{ color: "red", marginLeft: "40px" }}>ไม่มีรูปแบบรายงาน</span>
          //           </div>
          //      );
          // } else {
          return <span>{cell}</span>;
          // }
     };

     handleChangeText = ({ target: { name, value, checked } }) => {
          let { pay } = this.state;
          if (name === "branch") {
               // console.log(name + " " + value, checked);
               if (checked) {
                    pay[name].push(Number(value));
                    pay[name] = pay[name].sort((a, z) => a - z);
                    this.setState({ pay });
               } else {
                    let index = pay[name].findIndex((el) => el === Number(value));
                    pay[name].splice(index, 1);
                    pay[name] = pay[name].sort((a, z) => a - z);
                    this.setState({ pay });
               }
          } else {
               pay[name] = value;
               // console.log(name + " " + value);
               this.setState({ pay });
          }
     };

     handleChangeDate = (date, dateString, name) => {
          let { pay } = this.state;
          pay[name] = dateString;
          this.setState({ pay });
     };

     handleCloseModal = () => {
          let { pay } = this.state;
          this.setState({
               show: false,
               // pay: { ...pay, branch: [] },
               error_data: "",
          });
     };

     render() {
          let {
               modal: { type, title },
               show,
               pay,
               error_data,
          } = this.state;
          let table = {
               column: [
                    {
                         dataField: "no",
                         text: "ลำดับ",
                         headerClasses: "header-custom __left __no",
                         formatter: this.noFormatter,
                    },
                    {
                         dataField: "report",
                         text: "รายงาน",
                         formatter: this.reportFormatter,
                         headerClasses: "header-custom __btn-l",
                    },
                    {
                         dataField: "list",
                         text: "ทำรายการ",
                         headerClasses: "header-custom __right __btn-ss",
                         formatter: this.detailFormatter,
                    },
               ],
               data: [
                    { report: "รายงานรับเงินค่าจอง", type: 1, id: 1 },
                    { report: "รายงานรับเงินค่ารถ", type: 1, id: 2 },
                    { report: "รายงานใบกำกับภาษีส่วนอื่นๆ", type: 1, id: 3 },
                    { report: "รายงานใบลดหนี้ค่าจอง", type: 1, id: 4 },
                    { report: "รายงานใบกำกับภาษีส่วนซื้ออุปกรณ์เพิ่มเติม", type: 1, id: 5 },
                    { report: "รายงานการรับเงินประจำวัน", type: 1, id: 6 },
                    { report: "รายงานการับเงินลูกค้าซื้อสด รถแลก", type: 1, id: 7 },
                    { report: "รายงานใบเสร็จรับเงิน TR", type: 4, id: 8 },
                    { report: "รายงานประกันภัยบริษัทแถมลูกค้า", type: 1, id: 9 },
                    { report: "รายงานประกันภัยลูกค้าซื้อ", type: 1, id: 10 },
               ],
               keyField: "on",
          };

          // console.log("pay ", pay);
          return (
               <div className="panel hide-scroll">
                    <Breadcrumbs title={[{ title: "รายงานรับเงิน" }]} active={0} button={""} />
                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content listreport" id="table-content">
                                        <Table type={"custom"} column={table.column} data={table.data} keyField={table.keyField} />
                                   </div>
                                   <Modal show={show} centered size="lg" onHide={this.handleCloseModal}>
                                        <Modal.Header>
                                             <Modal.Title className="text-center">
                                                  <strong>{title || ""}</strong>
                                                  {error_data && (
                                                       <p className="text-center">
                                                            <strong style={{ color: "red" }}>{error_data || ""}</strong>
                                                       </p>
                                                  )}
                                             </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                             {type === 1 && (
                                                  <>
                                                       <Row>
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={pay.date_start ? pay.date_start : "เลือกวันที่"}
                                                                 title={"ประจำวันที่"}
                                                                 name={"date_start"}
                                                                 md={6}
                                                                 handleSelectDate={this.handleChangeDate}
                                                            />
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={pay.date_end ? pay.date_end : "เลือกวันที่"}
                                                                 title={"ถึงวันที่"}
                                                                 name={"date_end"}
                                                                 md={6}
                                                                 handleSelectDate={this.handleChangeDate}
                                                            />
                                                       </Row>
                                                       <LabelBar
                                                            type={"checkbox"}
                                                            title={"สาขา"}
                                                            name={"branch"}
                                                            md={12}
                                                            label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            // value={pay?.branch}
                                                            arrChecked={pay?.branch}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </>
                                             )}

                                             {type === 2 && (
                                                  <>
                                                       <Row>
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={pay.date_start ? pay.date_start : "เลือกวันที่"}
                                                                 title={"ประจำวันที่"}
                                                                 name={"date_start"}
                                                                 md={6}
                                                                 handleSelectDate={this.handleChangeDate}
                                                            />
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={pay.date_end ? pay.date_end : "เลือกวันที่"}
                                                                 title={"ถึงวันที่"}
                                                                 name={"date_end"}
                                                                 md={6}
                                                                 handleSelectDate={this.handleChangeDate}
                                                            />
                                                       </Row>
                                                       <Field
                                                            type="select"
                                                            title={"ชื่อพนักงานขาย"}
                                                            name={"saler_name"}
                                                            option={this.state.getSale}
                                                            handleChangeText={this.handleChangeText}
                                                            value={pay?.saler_name}
                                                            all={true}
                                                       />
                                                       <LabelBar
                                                            type={"checkbox"}
                                                            title={"สาขา"}
                                                            name={"branch"}
                                                            md={12}
                                                            label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            // value={pay?.branch}
                                                            arrChecked={pay?.branch}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </>
                                             )}
                                             {type === 3 && (
                                                  <>
                                                       <LabelBar
                                                            type={"checkbox"}
                                                            title={"สาขา"}
                                                            name={"branch"}
                                                            md={12}
                                                            label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            // value={pay?.branch}
                                                            arrChecked={pay?.branch}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </>
                                             )}
                                             {type === 4 && (
                                                  <>
                                                       {/* <Field
                                                            value={pay?.id}
                                                            name={"id"}
                                                            handleChangeText={this.handleChangeText}
                                                            type="select"
                                                            title={"ประเภทใบเสร็จรับเงิน"}
                                                            option={[
                                                                 { value: 12, name: "รายงานใบเสร็จรับเงินค่าจดทะเบียน" },
                                                                 { value: 13, name: "รายงานใบเสร็จรับเงินค่าพรบ" },
                                                                 { value: 14, name: "รายงานใบเสร็จรับเงินค่าประกัน" },
                                                                 { value: 15, name: "รายงานใบเสร็จรับเงินค่าเงินดาวน์" },
                                                                 { value: 16, name: "รายงานใบเสร็จรับเงินค่ารถ" },
                                                                 { value: 17, name: "รายงานใบเสร็จรับเงินชั่วคราวค่าป้ายแดง" },
                                                                 { value: 18, name: "รายงานใบเสร็จรับเงินค่าอื่นๆ" },
                                                                 { value: 19, name: "รายงานใบเสร็จรับเงินชั่วคราวค่าอื่นๆ" },
                                                            ]}
                                                       /> */}

                                                       <Row>
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={pay.date_start ? pay.date_start : "เลือกวันที่"}
                                                                 title={"ประจำวันที่"}
                                                                 name={"date_start"}
                                                                 md={6}
                                                                 handleSelectDate={this.handleChangeDate}
                                                            />
                                                            <Field
                                                                 type={"date"}
                                                                 placeholder={pay.date_end ? pay.date_end : "เลือกวันที่"}
                                                                 title={"ถึงวันที่"}
                                                                 name={"date_end"}
                                                                 md={6}
                                                                 handleSelectDate={this.handleChangeDate}
                                                            />
                                                       </Row>
                                                       <LabelBar
                                                            type={"checkbox"}
                                                            title={"สาขา"}
                                                            name={"branch"}
                                                            md={12}
                                                            label={this.state.branch?.map((el) => ({ value: el.branch_id, name: el.branch_name }))}
                                                            // value={pay?.branch}
                                                            arrChecked={pay?.branch}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </>
                                             )}

                                             <Row className="button-group __submit">
                                                  <Col lg={6}>
                                                       {this.state.loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button onClick={this.closeReport}>แสดงรายงาน</Button>
                                                       )}
                                                  </Col>
                                             </Row>
                                        </Modal.Body>
                                   </Modal>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
